import http, { setHeaders } from "../http-common";
import Auth from "../utils/auth";

setHeaders(Auth.getInstance().getAuthToken());

const getDealsByStatus = () => {
  return http.get("/dashboard/analytic/status");
};

const getDealsByUpcomingClosings = () => {
  return http.get("/dashboard/analytic/upcoming");
};

const getDealsByYTD = () => {
  return http.get("/dashboard/analytic/ytd");
};

const getDealsByOffice = () => {
  return http.get("/dashboard/analytic/office");
};

const getDealsByBroker = () => {
  return http.get("/dashboard/analytic/broker");
};

const getListingsByStatus = () => {
  return http.get("/dashboard/analytic/listings/status");
};

const getListingsByOffice = () => {
  return http.get("/dashboard/analytic/listings/office");
};

const getListingsByBroker = () => {
  return http.get("/dashboard/analytic/listings/broker");
};

const DashboardService = {
  getDealsByStatus,
  getDealsByUpcomingClosings,
  getDealsByYTD,
  getDealsByOffice,
  getDealsByBroker,
  getListingsByStatus,
  getListingsByOffice,
  getListingsByBroker,
};

export default DashboardService;
