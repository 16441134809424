import { Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const Loading = (props) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1000 }}
      open={props.loading}
    >
      <CircularProgress color="inherit" />
      {props.message && <Typography position='absolute' className="loader-text">{props.message}</Typography>}
    </Backdrop>
  );
};

export default Loading;
