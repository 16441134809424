const types = `Open Motorboat
Cabin Motorboat
Auxiliary Sailboat
Inflatable
Houseboat
Pontoon
Airboat
Sailboat
Personal Watercraft
Canoe
`;

export default types;