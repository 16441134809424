import { useDispatch, useSelector } from 'react-redux';
import { Switch, Redirect, useHistory, Route } from 'react-router-dom';
import React, { Fragment, useState, useEffect } from 'react';

import PermissionRoute from './permission_route';

import Header from '../components/layouts/Header';
import Footer from '../components/layouts/Footer';
import HeaderPlaceHolder from '../components/layouts/HeaderPlaceHolder';
import SidebarBroker from '../components/layouts/SidebarBroker';
import Verify from '../components/layouts/Verify';

import ClientPage from '../components/clients';
import CompanyPage from '../components/companies';
import VesselPage from '../components/vessels';
import OfficePage from '../components/offices';
import MyOfficeModal from '../components/offices/MyOfficeModal';
import DealPage from '../components/deals';
import DocumentTaskPage from '../components/documents_tasks';
import UsersPage from '../components/users';
import ProfilePage from '../components/profile';
import DashboardPage from '../components/dashboard';
import ListingAgreementPage from '../components/listing_agreement';
import ReportsDealsPage from '../components/reports/Deals';
import ReportsListingAgreementsPage from '../components/reports/ListingAgreements';
import ReportsBrokersPage from '../components/reports/Brokers';
import ReportsOfficesPage from '../components/reports/Offices';
import EDocsPage from '../components/edocs';
import BrokeragePage from '../components/brokerage';
// import AssociationsPage from '../components/associations';
import ESignTemplatesPage from '../components/templates';
import DocumentTEmplatePage from '../components/prefillable';
import { getMLSFilters } from "../redux/actions/vessels_toys";

import { Roles } from '../utils/role';
import { getChecklist } from '../redux/actions/document_tasks';
import VerificationForm from '../components/auth/forms/verification';
import { PreviewDocument } from './PreviewDocument';
import { isGuestAgent, isParticipant } from '../utils/permission';
import InvitationDealPage from '../components/invitation_deals';
import Tutorials from '../components/tutorials/Index';
import ActivityLog from '../components/activity_log';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { manageAppVersionWithRefresh } from '../utils/functions';
import FormLibraryManager from '../components/library_manager';
import UploadFileManager from '../components/upload_file_manager';
import { getTutorials } from '../redux/actions/tutorials';

const MID_WIDTH = 960;

const MainLayout = () => {

  const dispatch = useDispatch();
  // const location = useLocation();
  const cognitoUser = useSelector(state => state.users.cognitoUser);
  // let history = useHistory();
  // if (!cognitoUser.email_verified) {
  //   history.push("/auth/verification")
  // }
  const [sidebarVisibility, setSidebarVisibility] = useState(true);
  const [show, setShow] = useState(false);
  const toggleSidebarVisibility = () => {
    setSidebarVisibility(!sidebarVisibility);
  }
  const handleSizeChange = () => {
    if (window.innerWidth >= MID_WIDTH) {
      setSidebarVisibility(true);
    } else {
      setSidebarVisibility(false);
    }
  }
  const handleScroll = () => {
    if (window.innerWidth < MID_WIDTH) {
      setSidebarVisibility(false);
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleSizeChange);
    window.addEventListener('scroll', handleScroll);
    window.dispatchEvent(new Event('resize'));
    return () => {
      window.removeEventListener("resize", handleSizeChange);
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  // useEffect(() => {
  //   manageAppVersionWithRefresh();
  // }, [location]);


  //get mls filters on app load for use of vessel forms
  useEffect(() => {
    dispatch(getMLSFilters());
  }, [])

  //Get Upload list and task list on app load
  useEffect(() => {
    dispatch(getChecklist())
  }, [])

   //Get tutorials
   useEffect(() => {
    dispatch(getTutorials())
  }, [])

  const renderModals = () => {
    return (
      <MyOfficeModal show={show} onClose={() => setShow(false)} />
    );
  }

  return (
    <Fragment>
      <Header sidebarVisibility={sidebarVisibility} onSidebarToggle={toggleSidebarVisibility} />
      <HeaderPlaceHolder />
      {!cognitoUser.email_verified && (
        <VerificationForm />
      )}
      {
        cognitoUser.email_verified &&
        <div className='pt-md-0 pt-5 mt-md-0 mt-5'>
          {!!sidebarVisibility ? <SidebarBroker onSidebarToggle={toggleSidebarVisibility} showMyOffice={setShow} /> : null}
          <Switch>
            <PermissionRoute path="/dashboard" component={DashboardPage} roles={[Roles.boatdoxAdmin, Roles.companyAdmin, Roles.agent]} />
            <PermissionRoute path="/clients" roles={[Roles.boatdoxAdmin, Roles.companyAdmin, Roles.agent]} component={ClientPage} />
            <PermissionRoute path="/users" roles={[Roles.boatdoxAdmin, Roles.companyAdmin]} component={UsersPage} />
            <PermissionRoute path="/companies" roles={[Roles.boatdoxAdmin]} component={CompanyPage} />
            {/* <PermissionRoute path="/associations" roles={[Roles.boatdoxAdmin]} component={AssociationsPage} /> */}
            <PermissionRoute path="/vessels" roles={[Roles.boatdoxAdmin, Roles.companyAdmin, Roles.agent]} component={VesselPage} />
            <PermissionRoute path="/offices" roles={[Roles.boatdoxAdmin, Roles.companyAdmin]} component={OfficePage} />
            <PermissionRoute path="/listing-agreement" roles={[Roles.boatdoxAdmin, Roles.companyAdmin, Roles.agent]} component={ListingAgreementPage} />
            <PermissionRoute path="/deals" component={DealPage} />
            <PermissionRoute path="/company-document-task" roles={[Roles.companyAdmin]} component={DocumentTaskPage} />
            <PermissionRoute path="/iyba-document-task" roles={[Roles.boatdoxAdmin]} component={DocumentTaskPage} />
            <PermissionRoute path="/profile" component={ProfilePage} />
            <PermissionRoute path="/reports/deals" roles={[Roles.boatdoxAdmin, Roles.companyAdmin, Roles.agent]} component={ReportsDealsPage} />
            <PermissionRoute path="/reports/listing-agreements" roles={[Roles.boatdoxAdmin, Roles.companyAdmin, Roles.agent]} component={ReportsListingAgreementsPage} />
            <PermissionRoute path="/reports/brokers" roles={[Roles.boatdoxAdmin, Roles.companyAdmin, Roles.agent]} component={ReportsBrokersPage} />
            <PermissionRoute path="/reports/offices" roles={[Roles.boatdoxAdmin, Roles.companyAdmin, Roles.agent]} component={ReportsOfficesPage} />
            <PermissionRoute path="/edoc-templates" roles={[Roles.boatdoxAdmin]} component={EDocsPage} />
            <PermissionRoute path="/esign-templates" roles={[Roles.boatdoxAdmin]} component={ESignTemplatesPage} />
            <PermissionRoute path="/tutorials" roles={[Roles.boatdoxAdmin]} component={Tutorials} />
            <PermissionRoute path="/activity-log" roles={[Roles.boatdoxAdmin, Roles.companyAdmin]} component={ActivityLog} />
            <PermissionRoute path="/document-templates" roles={[Roles.boatdoxAdmin]} component={DocumentTEmplatePage} />
            <PermissionRoute path="/brokerage" roles={[Roles.companyAdmin]} component={BrokeragePage} />
            <PermissionRoute path="/invitation-deals" roles={[Roles.agent, Roles.guestAgent, Roles.companyAdmin]} component={InvitationDealPage} />
            <PermissionRoute path="/form-library-manager" roles={[Roles.companyAdmin]} component={FormLibraryManager} />
            <PermissionRoute path="/upload-file-manager" roles={[Roles.boatdoxAdmin, Roles.companyAdmin]} component={UploadFileManager} />
            <Route path="/preview/:id" component={PreviewDocument} />
            <Redirect to={!isParticipant() ? !isGuestAgent() ? "/dashboard" : "/invitation-deals" : "/deals"} />
          </Switch>
          {renderModals()}
          <Footer />
        </div>
      }
    </Fragment>
  );
}

export default MainLayout;