import { useState, useEffect } from 'react';
import { AutoComplete } from "antd";
import { useDebounce } from '../../utils/hooks';

const AutoCompleteSearch = ({ options, onSelect, onSearch=() => {}, placeholder = 'Search...' ,isClear=false}) => {
  const [searchTerm, setSearchTerm] = useState(null);
  const debounceSearchTerm = useDebounce(searchTerm, 300);
  useEffect(() => {
    if (debounceSearchTerm) {
      onSearch(debounceSearchTerm);
    }
  }, [debounceSearchTerm]);
  return (
    <AutoComplete
      style={{
        width: 400,
        marginBottom: '1rem'
      }}
      options={options}
      placeholder={placeholder}
      filterOption={(inputValue, option) => {
        return option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
      }}
      allowClear
      onSelect={(value, option) => !isClear && onSelect(value, option)}
      onSearch={value => setSearchTerm(value)}
      onChange={(value, option) =>isClear && onSelect(value, option)}
    />
  );
};

export default AutoCompleteSearch;
