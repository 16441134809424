import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setLoadingStatus } from "../../../../../redux/actions/loading";
import {
  eSignCommissionAgreementSeller,
  getTransactionEdocById,
} from "../../../../../redux/actions/transaction";
import {
  CORPORATE_OWNED,
  JOINT_OWNER,
  LEGAL_OWNER,
  LLC_OWNED,
  OWNER_OF_RECORD,
  RECORD_OWNER,
  TRUST_OWNED,
} from "../../../../../utils/signer";
import FormInputWrapper from "../../../../common/FormInputWapper";
import FormRow from "../../../../common/FormRow";

import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment-timezone";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { toast } from "react-toastify";
import Auth from "../../../../../utils/auth";
import { momentDateWithTimezone } from "../../../../../utils/common";
import {
  convertFirstCharIntoSmallCase,
  isSigned,
  renderMaskedValue,
  unauthorizedErrorToast,
} from "../../../../../utils/functions";
import HandSignUploadModal from "../../../../common/HandSignUploadModal";
import SignatureMessageModal from "../../../../common/SignatureMessageModal";
import { ViewDocumentsModal } from "../../../../common/ViewDocumentsModal";
import CustomActionButtons from "./CustomActionButtons";
import CustomRenderStatus from "./CustomRenderStatus";

const useStyles = makeStyles({
  previewSendDiv: {
    width: "250px !important",
  },
  handSignedDiv: {
    marginTop: "-4px",
  },
});

/**
 * @param {Function} updateDocumentObj // It is used to update payload object
 * @param {String} selectedRole //It is used to check which role is selected from signature tab
 * @param {Object} payload //Prepared object for the back-end
 * @param {Function} makeDropDownSigners // Send signers array to the signature tab for displaying signer drop-down
 * @returns
 */
const EsignCommissionAgreementSeller = ({
  transactionId,
  makeDropDownSigners,
  updateDocumentObj,
  selectedRole,
  payload,
  roles,
  menuType,
}) => {
  const dispatch = useDispatch();

  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [showAdditionalModal, setShowAdditionalModal] = useState(false);
  const [emailDetailsToSend, setEmailDetailsToSend] = useState(null);
  const [showHandsignModal, setShowHandsignModal] = useState(false);
  const [handSignDetails, setHandSignDetails] = useState(null);
  const [showDocument, setShowDocument] = useState(false);
  const [document, setDocument] = useState(null);
  const loginEmail = Auth.getInstance().getUserData().email;

  //Get logged User
  const user = Auth.getInstance().getUserData();
  const classes = useStyles();

  const deals = useSelector((state) => state.transactions);

  const dataTransaction = deals?.transaction || {};
  const dataBroker = deals.transaction ? deals.transaction.broker : null;
  const dataSeller = deals.transaction ? deals.transaction.seller : null;
  const dataCoSeller = deals.transaction ? deals.transaction.co_seller : null;
  const dataCoBroker = dataTransaction.co_broker;
  const dataVessel = deals.transaction ? deals.transaction.t_vessel : null;
  const requiredFields = deals ? deals.requiredOptionFields : [];
  const breadcrumb = deals.transaction
    ? `/deals/view/${deals.transaction.id}/main`
    : "#";
  const eDoc = deals.transaction
    ? deals.transaction.t_edocuments.find(
        (doc) => doc.task_type === "commission-agreement-(seller)"
      )
    : null;
  const signers =
    eDoc && eDoc.request_signature ? eDoc.request_signature.signers : [];

  //Define links for this page
  const links = [
    { label: "Deals", link: `/deals/view/${transactionId}/main` },
    { label: "Commission Agreement (Seller)", link: null },
  ];

  /**
   * useEffect to generate the array of signers which is available
   * for this document only
   */
  useEffect(() => {
    if (!updateDocumentObj || !isValidSend()) {
      return;
    }

    let signerArr = [];

    if (
      dataCoBroker &&
      (dataCoBroker.broker_type == "listing" ||
        dataCoBroker.broker_type == "both") &&
      dataTransaction?.employing_broker &&
      !isSigned(signers, "listing_employing_broker")
    ) {
      signerArr.push("listing_employing_broker");
    } else if (
      dataCoBroker &&
      !isSigned(signers, "listing_broker") &&
      dataCoBroker.listing_broker_email
    ) {
      signerArr.push("listing_broker");
    }

    if (dataTransaction?.co_seller && !isSigned(signers, "co_seller")) {
      signerArr.push("co_seller");
    }

    if (dataTransaction?.seller && !isSigned(signers, "seller")) {
      signerArr.push("seller");
    }

    makeDropDownSigners(signerArr);
  }, [dataTransaction, signers.length]);

  //Apply use Effect when role is changed from the signature tab
  useEffect(() => {
    /**
     * Check selected role is included for this document &
     * Check the selected role's status is not equal to signed &
     * Check this document has proper data for the sign this document.
     */
    if (
      selectedRole &&
      roles.includes(selectedRole) &&
      signers &&
      signers.find(
        (signer) =>
          convertFirstCharIntoSmallCase(signer.request_signer.role) ===
          selectedRole
      )?.status != "Signed" &&
      dataTransaction &&
      isValidSend()
    ) {
      switch (selectedRole) {
        case "listing_employing_broker":
          if (!dataTransaction?.employing_broker) {
            return;
          }
          break;
        case "listing_broker":
          if (!dataCoBroker?.selling_broker_email) {
            return;
          }
          break;
        case "co_seller":
          if (!dataTransaction?.co_seller) {
            return;
          }
          break;
        default:
          break;
      }

      //Prepare object for the payload
      let obj = {};
      obj[selectedRole] = {
        document_name: "commission-agreement-(seller)",
        id: dataTransaction?.id,
      };

      //Update payload object
      updateDocumentObj(obj, selectedRole);
      return;
    }
  }, [selectedRole]);

  //Define vessel name to show
  const vesselName =
    `${dataVessel?.length || ""}' ${dataVessel?.make || ""} ${
      dataVessel?.model || ""
    } ${dataVessel?.year || ""}` !== "'   "
      ? `${dataVessel?.length || ""}' ${dataVessel?.make || ""} ${
          dataVessel?.model || ""
        } ${dataVessel?.year || ""}`
      : "N/A";

  //Define co seller name
  const coSellerName = dataCoSeller
    ? `${dataCoSeller.first_name || ""} ${dataCoSeller.last_name || ""}` === " "
      ? "N/A"
      : `${dataCoSeller.first_name || ""} ${dataCoSeller.last_name || ""}`
    : "N/A";

  /**
   * @param {Object} evt // Event Object
   * @param {String} role
   */
  const previewByStep = (evt, role) => {
    dispatch(setLoadingStatus(true));
    dispatch(
      eSignCommissionAgreementSeller(dataTransaction.id, "preview", role)
    )
      .then((res) => {
        dispatch(setLoadingStatus(false));
        if (res.status !== 200) {
          throw res;
        } else {
          window.open(res.data.url + "?tm=" + new Date().getTime(), "_blank");
        }
      })
      .catch((err) => {
        dispatch(setLoadingStatus(false));
        switch (err.status) {
          case 400:
            setMessage(`${err.data.error.message}`);
            break;
          case 401:
            toast.error("You are not authorized to access this action.");
            break;
          default:
            setMessage(`${err.data.message}`);
            break;
        }
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 5000);
      });
  };

  /**
   * @param {Object} evt // Event Object
   * @param {String} mode //It can be "send" or "hand-signed"
   * @param {*} role
   * @param {*} data // selected date for the hand signed
   */
  const sendByStep = (mode, role, data = null) => {
    let obj = {};
    if (data) {
      if (mode == "signed") {
        obj["signed_date"] = data.signed_date;
        obj["signed_document"] = JSON.stringify(data.documents);
      } else {
        obj["additional_email_content"] = data.additional_email_content || "";
      }
      obj["selected_recipients"] = data.selectedUser;
    }
    obj["is_email_send"] = data.is_email_send ? "yes" : "no";
    obj["requested_by"] = user.id;
    dispatch(setLoadingStatus(true));
    dispatch(
      eSignCommissionAgreementSeller(dataTransaction.id, mode, role, obj)
    ).then((res) => {
      dispatch(setLoadingStatus(false));
      if (res.status === 200) {
        dispatch(
          getTransactionEdocById(
            deals.transaction.id,
            menuType === "invitation",
            dataTransaction?.company_id
          )
        );
        if (data.signed_date) {
          setMessage("Signed Date saved successfully");
          toast.success(`Hand signed documents uploaded successfully.`);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(undefined);
          }, 3000);
        } else {
          if (!data.is_email_send) {
            setShowAdditionalModal(false);
            toast.success("E-Sign Link generated successfully.");
          } else {
            setMessage("Document sent for eSignature successfully.");
            setSuccess(true);
            setTimeout(() => {
              setSuccess(undefined);
            }, 3000);
          }
        }
      } else {
        switch (res.status) {
          case 400:
            setMessage(`${res.data.error.message}`);
            break;
          case 401:
            unauthorizedErrorToast();
            break;
          default:
            toast.error(
              `Something went wrong while upload hand signed documents.`
            );
            setMessage(`${res.data.message}`);
            break;
        }
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 5000);
      }
    });
  };

  const onAdditionalMessageModalOpen = (mode, role, data = null) => {
    const selectedRecipients =
      eDoc.request_signature?.signers?.find(
        (signer) => signer.request_signer?.role === role
      ) || {};
    setEmailDetailsToSend({
      mode: mode,
      role: role,
      data: data,
      selectedRecipients: selectedRecipients.selected_recipients || [],
    });
    setShowAdditionalModal(true);
  };

  const onCloseAdditionalEmailModal = (
    isEmailSend,
    emailAdditionalContent = null
  ) => {
    setShowAdditionalModal(false);
    if (isEmailSend) {
      sendByStep(emailAdditionalContent.mode, emailAdditionalContent.role, {
        ...emailAdditionalContent.data,
        is_email_send: true,
        selectedUser: emailAdditionalContent.data.selected_recipients,
      });
    }
  };

  const onGenerateEsignatureLink = (emailAdditionalContent) => {
    const signatureLink =
      signers.find(
        (signer) => signer.request_signer.role === emailAdditionalContent.role
      )?.doc_signer_link || "";
    if (signatureLink) {
      setShowAdditionalModal(false);
      toast.success("E-Sign link generated successfully.");
    } else {
      sendByStep(emailAdditionalContent.mode, emailAdditionalContent.role, {
        ...emailAdditionalContent.data,
        is_email_send: false,
        selectedUser: emailAdditionalContent.data.selected_recipients,
      });
    }
  };

  /**
   * On open Hand Sign document selection modal after selecting date
   * @param {*} role
   * @param {*} signDate
   */
  const onHandSignUploadModalOpen = (role, signDate) => {
    const selectedRecipients =
      eDoc.request_signature?.signers?.find(
        (signer) => signer.request_signer?.role === role
      ) || {};
    const client = role.includes("Employing")
      ? dataTransaction?.employing_broker
      : role.includes("Listing") || role.includes("Selling")
      ? dataCoBroker
      : dataTransaction[role.toLowerCase().replace("-", "_")];
    setHandSignDetails({
      documentName: eDoc.t_esign_template?.template?.template_name,
      signDate: signDate,
      role: role,
      client: client,
      selectedRecipients: selectedRecipients.selected_recipients || [],
    });
    if (signDate && signDate != null) {
      setShowHandsignModal(true);
    }
  };

  /**
   * On close Hand Sign modal and submit uploaded document to sendByStep() function
   * @param {*} isUploaded
   * @param {*} documentUploadFiles
   */
  const onCloseHandsignModal = (
    isUploaded,
    documentUploadFiles = null,
    selectedUser = []
  ) => {
    setShowHandsignModal(false);
    if (isUploaded) {
      sendByStep("signed", handSignDetails.role, {
        signed_date: handSignDetails.signDate,
        documents: documentUploadFiles,
        selectedUser: selectedUser,
      });
    }
    setHandSignDetails(null);
  };

  /**
   * On open view hand signed uploaded modal
   * @param {*} document
   */
  const handleOpenViewDocumentModal = (document) => {
    setDocument(document);
    setShowDocument(true);
  };

  /**
   * On close view hand signed uploaded modal
   */
  const handleCloseModal = () => {
    setDocument(null);
    setShowDocument(false);
  };

  /**
   * Check this document is valid for preview or not
   * @returns True or False
   */
  const isValidPreview = () => {
    return (
      !deals.requiredFields.buyer.some((obj) =>
        Object.keys(obj).includes("email")
      ) &&
      !deals.requiredFields.seller.some((obj) =>
        Object.keys(obj).includes("email")
      )
    );
  };

  /**
   * Check this document is valid for send or not
   * @returns True or False
   */
  const isValidSend = () => {
    let valid = !deals.requiredFields.seller.some((obj) =>
      Object.keys(obj).includes("email")
    );

    // if (dataTransaction.co_buyer_contact_id) {
    //   valid = valid && !deals.requiredFields.co_buyer.some(obj => Object.keys(obj).includes('email'))
    // }

    if (dataTransaction.co_seller_contact_id) {
      valid =
        valid &&
        !deals.requiredFields.co_seller.some((obj) =>
          Object.keys(obj).includes("email")
        );
    }

    return valid;
  };

  /**
   * This function is used to show a signed date of document.
   * @param {String} role
   * @param {Date} signedDate
   * @returns Date
   */
  const handleSignedDate = (role, signedDate) => {
    if (signedDate) {
      return moment(signedDate ? signedDate : null)
        .utc()
        .format("MM-DD-yyyy");
    }

    if (!signedDate) {
      if (
        eDoc.request_signature.signers.find(
          (signer) => signer.request_signer.role === role
        )?.status === "Signed"
      ) {
        const timezone = moment.tz(moment.tz.guess()).zoneAbbr();
        return `${moment(
          eDoc.request_signature.signers.find(
            (signer) => signer.request_signer.role === role
          )?.esigned_date || null
        )
          .local()
          .format("MM-DD-yyyy hh:mm A")} ${timezone}`;
      }
    }

    return null;
  };

  /**
   * Provides the eSign link for email send for signature
   * @param {*} role
   * @returns
   */
  const getEsignLink = (role) => {
    return (
      signers.find((signer) => signer.request_signer.role === role)
        ?.doc_signer_link || ""
    );
  };

  const [tooltipText, setTooltipText] = useState("Copy E-Sign Link");

  /**
   * Renders the button for eSign Link according to role of client
   * @param {*} role
   * @returns Button for Link
   */
  const renderLinkButtons = (role) => {
    if (role.includes("broker") || role.includes("Broker")) {
      return (
        <button
          style={{ height: "26px" }}
          className="uk-button uk-button-primary"
          onClick={() => {
            window.open(getEsignLink(role), "_blank");
          }}
        >
          Sign{" "}
        </button>
      );
    }

    return (
      <Tooltip title={tooltipText} arrow>
        <button
          style={{ height: "26px", padding: "0 10px 0" }}
          className="uk-button uk-button-primary"
          onClick={() => {
            navigator.clipboard.writeText(getEsignLink(role));
            setTooltipText("E-Sign Link Copied..");
            setTimeout(() => {
              setTooltipText("Copy E-Sign Link");
            }, 800);
          }}
        >
          Copy E-Sign Link{" "}
        </button>
      </Tooltip>
    );
  };

  /**
   * This fucntion is used to change the payload
   * as per user manually checks the checkbox of particular checkbox
   * @param {Object} e //Event Object
   * @param {String} role
   * @returns
   */
  const checkBoxandler = (e, role) => {
    let obj = {};
    obj[role] = {
      document_name: "commission-agreement-(seller)",
      id: dataTransaction?.id,
    };
    updateDocumentObj(obj, role, !e.target.checked, true);
  };

  const renderAction = (role) => {
    return (
      <CustomActionButtons
        role={role}
        signers={signers}
        isValidSend={isValidSend}
        handleOpenViewDocumentModal={handleOpenViewDocumentModal}
        previewByStep={previewByStep}
        onAdditionalMessageModalOpen={onAdditionalMessageModalOpen}
        onHandSignUploadModalOpen={onHandSignUploadModalOpen}
        dataTransaction={dataTransaction}
        deals={deals}
        menuType={menuType}
      />
    );
  };

  const renderStatus = (role) => {
    return (
      <CustomRenderStatus
        role={role}
        signedDate={
          signers.find((signer) => signer.request_signer.role === role)
            ?.signed_date
        }
        signers={signers}
        isValidSend={isValidSend}
        handleOpenViewDocumentModal={handleOpenViewDocumentModal}
        previewByStep={previewByStep}
        onAdditionalMessageModalOpen={onAdditionalMessageModalOpen}
        onHandSignUploadModalOpen={onHandSignUploadModalOpen}
        dataTransaction={dataTransaction}
        deals={deals}
        eDoc={eDoc}
        getEsignLink={getEsignLink}
        renderLinkButtons={renderLinkButtons}
        handleSignedDate={handleSignedDate}
      />
    );
  };

  /**
   * Function that used for Display Broker Name and Email Address as per the status
   * If status is not Sent or Signed then display current Broker data otherwise display Signer data instead
   * @param {*} brokerType
   * @returns Broker Name and Email Address
   */
  const getBrokerNameAndEmail = (brokerType) => {
    if (brokerType.includes("employing-broker")) {
      return !["Sent", "Signed"].includes(
        signers.find((signer) => signer.request_signer.role === `${brokerType}`)
          ?.status
      )
        ? {
            name: `${dataTransaction.employing_broker?.first_name || "N/A"} ${
              dataTransaction.employing_broker?.last_name || "N/A"
            }`,
            email: dataTransaction?.employing_broker?.email || "N/A",
          }
        : {
            name: (
              signers &&
              signers.find(
                (signer) => signer.request_signer.role === `${brokerType}`
              )
            )?.request_signer?.name,
            email: (
              signers &&
              signers.find(
                (signer) => signer.request_signer.role === `${brokerType}`
              )
            )?.request_signer?.email_address,
          };
    }

    return !["Sent", "Signed"].includes(
      signers.find(
        (signer) =>
          convertFirstCharIntoSmallCase(signer.request_signer.role) ===
          `${brokerType}_broker`
      )?.status
    )
      ? {
          name: dataCoBroker[`${brokerType}_brokerage_broker`],
          email: dataCoBroker[`${brokerType}_broker_email`],
        }
      : {
          name: (
            signers &&
            signers.find(
              (signer) =>
                convertFirstCharIntoSmallCase(signer.request_signer.role) ===
                `${brokerType}_broker`
            )
          )?.request_signer?.name,
          email: (
            signers &&
            signers.find(
              (signer) =>
                convertFirstCharIntoSmallCase(signer.request_signer.role) ===
                `${brokerType}_broker`
            )
          )?.request_signer?.email_address,
        };
  };

  return (
    <>
      <div className="uk-container uk-container-small uk-position-relative m-0">
        <div className="scroll-div">
          <div className="form-container scroll-fix-div">
            <div className="esign-send listing-agreement">
              {!updateDocumentObj && (
                <>
                  {isSuccess ? (
                    <div className="uk-alert uk-alert-primary" data-uk-alert>
                      <p>{message}</p>
                    </div>
                  ) : isError ? (
                    <div className="uk-alert uk-alert-danger" data-uk-alert>
                      <p>{message}</p>
                    </div>
                  ) : null}
                </>
              )}
              <h4>
                <b>{eDoc?.t_esign_template?.template?.template_name}</b>
              </h4>

              <FormRow>
                <hr />
              </FormRow>
              <FormRow>
                <FormInputWrapper label="Vessel">{vesselName}</FormInputWrapper>
              </FormRow>
              <FormRow>
                <hr />
              </FormRow>

              {
                // menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail ? <></> :
                <>
                  <div className="client-row">
                    <FormInputWrapper
                      label="Seller"
                      className="signer-name-block"
                    >
                      {[LEGAL_OWNER, JOINT_OWNER].indexOf(
                        dataTransaction?.seller_type
                      ) > -1 &&
                        ((menuType === "invitation" &&
                          dataTransaction?.co_broker?.selling_broker_email ===
                            loginEmail) ||
                        (menuType !== "invitation" &&
                          dataTransaction.is_seller_hide_by_invited_broker)
                          ? renderMaskedValue(
                              `${dataSeller?.first_name} ${dataSeller?.last_name}`
                                .length
                            )
                          : `${dataSeller?.first_name} ${dataSeller?.last_name}`)}

                      {[OWNER_OF_RECORD].indexOf(dataTransaction?.seller_type) >
                        -1 &&
                        ((menuType === "invitation" &&
                          dataTransaction?.co_broker?.selling_broker_email ===
                            loginEmail) ||
                        (menuType !== "invitation" &&
                          dataTransaction.is_seller_hide_by_invited_broker)
                          ? renderMaskedValue(`Owner of Record`.length)
                          : `Owner of Record`)}
                      {[TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(
                        dataTransaction?.seller_type
                      ) > -1 &&
                        ((menuType === "invitation" &&
                          dataTransaction?.co_broker?.selling_broker_email ===
                            loginEmail) ||
                        (menuType !== "invitation" &&
                          dataTransaction.is_seller_hide_by_invited_broker)
                          ? renderMaskedValue(
                              `${dataSeller?.trust_name}`.length
                            )
                          : `${dataSeller?.trust_name}`)}
                      {!(
                        [
                          LEGAL_OWNER,
                          TRUST_OWNED,
                          JOINT_OWNER,
                          RECORD_OWNER,
                          LLC_OWNED,
                          CORPORATE_OWNED,
                        ].indexOf(dataTransaction?.seller_type) > -1
                      ) && `N/A`}
                    </FormInputWrapper>
                    <FormInputWrapper
                      label="Seller's Email"
                      className="signer-email-block"
                    >
                      {[LEGAL_OWNER, JOINT_OWNER, RECORD_OWNER].indexOf(
                        dataTransaction?.seller_type
                      ) > -1 &&
                        ((menuType === "invitation" &&
                          dataTransaction?.co_broker?.selling_broker_email ===
                            loginEmail) ||
                        (menuType !== "invitation" &&
                          dataTransaction.is_seller_hide_by_invited_broker)
                          ? renderMaskedValue(`${dataSeller?.email}`.length)
                          : `${dataSeller?.email}`)}
                      {[TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(
                        dataTransaction?.seller_type
                      ) > -1 &&
                        ((menuType === "invitation" &&
                          dataTransaction?.co_broker?.selling_broker_email ===
                            loginEmail) ||
                        (menuType !== "invitation" &&
                          dataTransaction.is_seller_hide_by_invited_broker)
                          ? renderMaskedValue(
                              `${dataSeller?.trust_signer_email}`.length
                            )
                          : `${dataSeller?.trust_signer_email}`)}
                      {!(
                        [
                          LEGAL_OWNER,
                          TRUST_OWNED,
                          JOINT_OWNER,
                          RECORD_OWNER,
                          LLC_OWNED,
                          CORPORATE_OWNED,
                        ].indexOf(dataTransaction?.seller_type) > -1
                      ) && ` N/A`}
                    </FormInputWrapper>
                    <div className={!updateDocumentObj && "signers_div"}>
                      {signers &&
                      signers.find(
                        (signer) => signer.request_signer.role === "Seller"
                      )?.status
                        ? renderStatus("Seller")
                        : renderAction("Seller")}
                    </div>
                    {eDoc &&
                      updateDocumentObj &&
                      !isSigned(signers, "Seller") && (
                        <div className="col-1">
                          <input
                            type="checkbox"
                            className="signatures_checkbox"
                            onChange={(e) => {
                              checkBoxandler(e, "seller");
                            }}
                            checked={
                              payload?.seller?.find((obj) =>
                                obj.document_name == eDoc.task_type
                                  ? true
                                  : false
                              ) || false
                            }
                            disabled={
                              !dataTransaction ||
                              !isValidSend(deals.requiredFields) ||
                              (selectedRole && selectedRole != "seller") ||
                              (menuType === "invitation" &&
                                dataTransaction?.co_broker
                                  ?.selling_broker_email === loginEmail) ||
                              (menuType !== "invitation" &&
                                dataTransaction.is_seller_hide_by_invited_broker)
                            }
                          />
                        </div>
                      )}
                  </div>
                  <FormRow>
                    <hr />
                  </FormRow>
                </>
              }

              {dataCoSeller && (
                // menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail ? <></> :
                <>
                  <div className="client-row">
                    <FormInputWrapper
                      label="Co-Seller"
                      className="signer-name-block"
                    >
                      {(menuType === "invitation" &&
                        dataTransaction?.co_broker?.selling_broker_email ===
                          loginEmail) ||
                      (menuType !== "invitation" &&
                        dataTransaction.is_co_seller_hide_by_invited_broker)
                        ? renderMaskedValue(`${coSellerName}`.length)
                        : `${coSellerName}`}
                    </FormInputWrapper>
                    <FormInputWrapper
                      label="Co-Seller's Email"
                      className="signer-email-block"
                    >
                      {(menuType === "invitation" &&
                        dataTransaction?.co_broker?.selling_broker_email ===
                          loginEmail) ||
                      (menuType !== "invitation" &&
                        dataTransaction.is_co_seller_hide_by_invited_broker)
                        ? renderMaskedValue(`${dataCoSeller?.email}`.length)
                        : `${dataCoSeller?.email || "N/A"}`}
                    </FormInputWrapper>
                    <div className={!updateDocumentObj && "signers_div"}>
                      {signers &&
                        (signers.find(
                          (signer) => signer.request_signer.role === "Seller"
                        ) ||
                          updateDocumentObj) &&
                        (signers.find(
                          (signer) => signer.request_signer.role === "Co-Seller"
                        )?.status
                          ? renderStatus("Co-Seller")
                          : renderAction("Co-Seller"))}
                    </div>
                    {eDoc &&
                      updateDocumentObj &&
                      !isSigned(signers, "Co-Seller") && (
                        <div className="col-1">
                          <input
                            type="checkbox"
                            className="signatures_checkbox"
                            onChange={(e) => {
                              checkBoxandler(e, "co_seller");
                            }}
                            checked={
                              payload?.co_seller?.find((obj) =>
                                obj.document_name == eDoc.task_type
                                  ? true
                                  : false
                              ) || false
                            }
                            disabled={
                              !dataTransaction ||
                              !isValidSend(deals.requiredFields) ||
                              (selectedRole && selectedRole != "co_seller") ||
                              (menuType === "invitation" &&
                                dataTransaction?.co_broker
                                  ?.selling_broker_email === loginEmail) ||
                              (menuType !== "invitation" &&
                                dataTransaction.is_co_seller_hide_by_invited_broker)
                            }
                          />
                        </div>
                      )}
                  </div>
                  <FormRow>
                    <hr />
                  </FormRow>
                </>
              )}
              {
                // menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail
                // ? <></>
                //   :
                dataCoBroker?.listing_broker_email ||
                ["listing"].includes(dataCoBroker.broker_type) ? (
                  (["Sent", "Signed"].includes(
                    signers.find(
                      (signer) =>
                        convertFirstCharIntoSmallCase(
                          signer.request_signer.role
                        ) === `listing_employing_broker`
                    )?.status
                  ) ||
                    (dataTransaction?.employing_broker &&
                      ["listing"].includes(dataCoBroker.broker_type))) &&
                  !["Sent", "Signed"].includes(
                    signers.find(
                      (signer) =>
                        convertFirstCharIntoSmallCase(
                          signer.request_signer.role
                        ) === `listing_broker`
                    )?.status
                  ) ? (
                    <>
                      <div className="client-row">
                        <FormInputWrapper
                          label="Listing Employing Broker"
                          className="signer-name-block"
                        >
                          {
                            getBrokerNameAndEmail("listing-employing-broker")
                              .name
                          }
                        </FormInputWrapper>
                        <FormInputWrapper
                          label="Listing Employing Broker's Email"
                          className="signer-email-block"
                        >
                          {
                            getBrokerNameAndEmail("listing-employing-broker")
                              .email
                          }
                        </FormInputWrapper>
                        <div className={!updateDocumentObj && "signers_div"}>
                          {signers &&
                          signers.find(
                            (signer) =>
                              signer.request_signer.role ===
                              "listing-employing-broker"
                          )
                            ? renderStatus("listing-employing-broker")
                            : renderAction("listing-employing-broker")}
                        </div>
                        {eDoc &&
                          updateDocumentObj &&
                          !isSigned(signers, "listing-employing-broker") && (
                            <div className="col-1">
                              <input
                                type="checkbox"
                                className="signatures_checkbox"
                                onChange={(e) => {
                                  checkBoxandler(e, "listing_employing_broker");
                                }}
                                checked={
                                  payload?.listing_employing_broker?.find(
                                    (obj) =>
                                      obj.document_name == eDoc.task_type
                                        ? true
                                        : false
                                  ) || false
                                }
                                disabled={
                                  !dataTransaction ||
                                  !isValidSend(deals.requiredFields) ||
                                  (selectedRole &&
                                    selectedRole !=
                                      "listing_employing_broker") ||
                                  (menuType === "invitation" &&
                                    dataTransaction?.co_broker
                                      ?.selling_broker_email === loginEmail)
                                }
                              />
                            </div>
                          )}
                      </div>
                      <FormRow>
                        <hr />
                      </FormRow>
                    </>
                  ) : !["both"].includes(dataCoBroker.broker_type) ? (
                    <>
                      <div className="client-row">
                        <FormInputWrapper
                          label="Listing Broker"
                          className="signer-name-block"
                        >
                          {getBrokerNameAndEmail("listing").name || "N/A"}
                        </FormInputWrapper>
                        <FormInputWrapper
                          label="Listing Broker's Email"
                          className="signer-email-block"
                        >
                          {getBrokerNameAndEmail("listing").email || "N/A"}
                        </FormInputWrapper>
                        {eDoc && (
                          <div className={!updateDocumentObj && "signers_div"}>
                            {signers &&
                            signers.find(
                              (signer) =>
                                signer.request_signer.role === "Listing-Broker"
                            )?.status
                              ? renderStatus("Broker")
                              : renderAction("Broker")}
                          </div>
                        )}
                        {eDoc &&
                          updateDocumentObj &&
                          !isSigned(signers, "Listing-Broker") && (
                            <div className="col-1">
                              <input
                                type="checkbox"
                                className="signatures_checkbox"
                                onChange={(e) => {
                                  checkBoxandler(e, "listing_broker");
                                }}
                                checked={
                                  payload?.listing_broker?.find((obj) =>
                                    obj.document_name == eDoc.task_type
                                      ? true
                                      : false
                                  ) || false
                                }
                                disabled={
                                  !dataTransaction ||
                                  !isValidSend(deals.requiredFields) ||
                                  (selectedRole &&
                                    selectedRole != "listing_broker") ||
                                  (menuType === "invitation" &&
                                    dataTransaction?.co_broker
                                      ?.selling_broker_email === loginEmail)
                                }
                              />
                            </div>
                          )}
                      </div>
                      <FormRow>
                        <hr />
                      </FormRow>
                    </>
                  ) : (
                    ["both"].includes(dataCoBroker.broker_type) && (
                      <>
                        <div className="client-row">
                          <FormInputWrapper
                            label="Listing-Employing-Broker"
                            className="signer-name-block"
                          >
                            {getBrokerNameAndEmail("listing-employing-broker").name ||
                              "N/A"}
                          </FormInputWrapper>
                          <FormInputWrapper
                            label="Listing-Employing-Broker's Email"
                            className="signer-email-block"
                          >
                            {getBrokerNameAndEmail("listing-employing-broker").email ||
                              "N/A"}
                          </FormInputWrapper>
                          {eDoc && (
                            <div
                              className={!updateDocumentObj && "signers_div"}
                            >
                              {signers &&
                              signers.find(
                                (signer) =>
                                  signer.request_signer.role ===
                                  "Listing-Broker"
                              )?.status
                                ? renderStatus("listing-employing-broker")
                                : renderAction("listing-employing-broker")}
                            </div>
                          )}
                          {eDoc &&
                            updateDocumentObj &&
                            !isSigned(signers, "Listing-Employing-Broker") && (
                              <div className="col-1">
                                <input
                                  type="checkbox"
                                  className="signatures_checkbox"
                                  onChange={(e) => {
                                    checkBoxandler(e, "listing-employing-broker");
                                  }}
                                  checked={
                                    payload?.listing_employing_broker?.find((obj) =>
                                      obj.document_name == eDoc.task_type
                                        ? true
                                        : false
                                    ) || false
                                  }
                                  disabled={
                                    !dataTransaction ||
                                    !isValidSend(deals.requiredFields) ||
                                    (selectedRole &&
                                      selectedRole != "listing_employing_broker") ||
                                    (menuType === "invitation" &&
                                      dataTransaction?.co_broker
                                        ?.selling_broker_email === loginEmail)
                                  }
                                />
                              </div>
                            )}
                        </div>
                        <FormRow>
                          <hr />
                        </FormRow>
                      </>
                    )
                  )
                ) : (
                  <></>
                )
              }
              <br />

              {deals && requiredFields.length > 0 && (
                <>
                  <p>Required fields for Transaction Details.</p>
                  <div className="form-row uk-padding-small">
                    <div>
                      {requiredFields.map((obj, key) => {
                        return key === requiredFields.length - 1
                          ? `${Object.values(obj)[0]}`
                          : `${Object.values(obj)[0]}, `;
                      })}
                    </div>
                  </div>
                  {dataTransaction?.seller_type !== RECORD_OWNER &&
                    deals.requiredFields.seller &&
                    deals.requiredFields.seller.some((obj) =>
                      Object.keys(obj).includes("email")
                    ) && (
                      <div className="form-row uk-margin-small">
                        <label className="uk-form-label" htmlFor="name">
                          Seller (Send)
                        </label>
                        <div>
                          {deals.requiredFields.seller.map((obj, key) => {
                            return Object.values(obj)[0] == "Email"
                              ? `${Object.values(obj)[0]}`
                              : ``;
                          })}
                        </div>
                      </div>
                    )}
                  {dataTransaction.owner_or_legal === 1 &&
                    dataCoSeller &&
                    deals.requiredFields.co_seller.some((obj) =>
                      Object.keys(obj).includes("email")
                    ) && (
                      <div className="form-row uk-margin-small">
                        <label className="uk-form-label" htmlFor="name">
                          Co-Seller (Send)
                        </label>
                        <div>
                          {deals.requiredFields.co_seller.map((obj, key) => {
                            return Object.values(obj)[0] == "Email"
                              ? `${Object.values(obj)[0]}`
                              : ``;
                          })}
                        </div>
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
        </div>

        <br />
      </div>
      <SignatureMessageModal
        show={showAdditionalModal}
        onClose={(isEmailSend, emailAdditionalContent = null) =>
          onCloseAdditionalEmailModal(isEmailSend, emailAdditionalContent)
        }
        emailDetail={emailDetailsToSend}
        onGenerateLink={onGenerateEsignatureLink}
      />
      {showHandsignModal && (
        <HandSignUploadModal
          show={showHandsignModal}
          onClose={onCloseHandsignModal}
          signData={handSignDetails}
        />
      )}
      {showDocument && (
        <ViewDocumentsModal
          documentTask={document}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default EsignCommissionAgreementSeller;
