import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import DataTable from "react-data-table-component";
import { DELETE_MODAL_MESSAGE, DELETE_MODAL_TITLE } from "../../../../../constants/Alert";
import datatableStyles from "../../../../../utils/datatable-style";
import { getTransactionInitialById, removeToyFromTransaction } from "../../../../../redux/actions/transaction";
import TrailerService from "../../../../../services/trailer";
import { toast } from "react-toastify";
import { getPrice } from "../../../../../utils/currency";

const TrailerTable = ({ taskType, fromPanel = false, onEdit = null }) => {
    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataListing = dataTransaction || {};
    const data = dataListing.t_trailer || [];
    const dispatch = useDispatch();

    const onDelete = (id) => {
        confirmAlert({
            title: DELETE_MODAL_TITLE,
            message: DELETE_MODAL_MESSAGE,
            overlayClassName: "title-overlay-custom-class",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        TrailerService.deleteTrailerById(id)
                            .then((res) => {
                                dispatch(getTransactionInitialById(dataListing.id));
                                toast.info("Deleted successfully", {
                                    autoClose: 2000,
                                });
                            })
                            .catch((e) => {
                                toast.error("Error occur. Please try again", {
                                    autoClose: 2000,
                                });
                            });
                    },
                },
                {
                    label: "No",
                    onClick: () => { },
                },
            ],
        });
    };

    const totalPurchasePrice = () => {
        const additional_price = data.reduce(
            (total, trailer) => total + parseFloat(trailer.price),
            0
        );
        return additional_price;
    }

    const columns = [
        {
            name: "Make",
            selector: "make",
            cell: (row) => {
                return (
                    <div data-tag="allowRowEvents">
                        <div>{row?.make || "N/A"}</div>
                    </div>
                );
            },
        },
        {
            name: "VIN",
            selector: "vin",
            cell: (row) => {
                return (
                    <div data-tab="allowRowEvents">
                        <div>{row?.vin || "N/A"}</div>
                    </div>
                );
            },
        },
        {
            name: "Plate No.",
            selector: "plate_no",
            cell: (row) => {
                return (
                    <div data-tab="allowRowEvents">
                        <div>{row?.plate_no || "N/A"}</div>
                    </div>
                );
            },
        },
        {
            name: "Reg No.",
            selector: "reg_no",
            cell: (row) => {
                return (
                    <div data-tab="allowRowEvents">
                        <div>{row?.reg_no || "N/A"}</div>
                    </div>
                );
            },
        },
        {
            name: "Title No.",
            selector: "title_no",
            cell: (row) => {
                return (
                    <div data-tab="allowRowEvents">
                        <div>{row?.title_no || "N/A"}</div>
                    </div>
                );
            },
        },
        {
            name: "Price",
            selector: "price",
            right: true,
            width: '100px',
            cell: (row) => {
                return (
                    <div data-tab="allowRowEvents">
                        <div>{row?.price ? getPrice(row.price) : "N/A"}</div>
                    </div>
                );
            },
        },
        {
            name: "Action",
            center: true,
            textAlign: "right",
            width: '120px',
            cell: (row) => {
                return (
                    <div>
                        {fromPanel
                            ? <img src="/icons/edit.svg" onClick={() => onEdit(row.id)} className="svg uk-margin-right" alt="" />
                            : <Link
                                to={{
                                    pathname: `/${taskType}/add/${dataListing.id}/trailer/edit`,
                                    state: { id: row.id },
                                }}> <img src="/icons/edit.svg" className="svg uk-margin-right" alt="" />
                            </Link>}
                        <img
                            src="/icons/delete.svg"
                            className="svg"
                            onClick={() => onDelete(row.id)}
                            alt=""
                        />
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <DataTable
                title="Trailer"
                columns={columns}
                data={data}
                striped={true}
                highlightOnHover={true}
                pointerOnHover={true}
                center={true}
                noHeader={true}
                fixedHeader={true}
                customStyles={datatableStyles}
                refresh={true}
            />
            {(fromPanel && totalPurchasePrice() > 0) &&
                <div className="text-right">
                    <div style={{ color: '#092540', fontSize: '17px', fontWeight: 500 }}>Total Price: {getPrice(totalPurchasePrice())}</div>
                </div>
            }
        </>
    );
};

export default TrailerTable;
