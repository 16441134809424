
import http, { setHeaders } from '../http-common';
import Auth from '../utils/auth';

setHeaders(Auth.getInstance().getAuthToken());

const getTrailerById = (trailerId) => {
    return http.get(`/transactions/trailer/${trailerId}`);
}

const updateTrailerById = (trailerId, data) => {
    return http.put(`/transactions/trailer/${trailerId}`, data);
}

const deleteTrailerById = (trailerId) => {
    return http.delete(`/transactions/trailer/${trailerId}`);
}

const TrailerService = {
    getTrailerById,
    updateTrailerById,
    deleteTrailerById
};

export default TrailerService;
