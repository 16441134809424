import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import {
    getTransactionEdocById,
    getTransactionEsignById,
    upsertTransaction,
} from "../../../../../redux/actions/transaction";
import { getFormattedData, getPlainTransactionData } from "../../../../../utils/stepFilter";

import * as yup from "yup";
import { setLoadingStatus } from '../../../../../redux/actions/loading';

const PanelFormListingAgreementAddendum = ({ closePanel, addendumId }) => {

    const schema = yup.object().shape({
        ammendment: yup.string(),
    });

    /* Form Validation */
    const { register, handleSubmit, setValue, getValues } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    const dispatch = useDispatch();

    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataListing = dataTransaction || {};
    const dataAddendum = dataListing.t_purchase_addendum;

    const keys = ["ammendment_1", "ammendment_2", "ammendment_3", "ammendment_4", "ammendment_5"];
    const doc_id = addendumId;

    useEffect(() => {
        const ammendment = dataAddendum?.find(el => el.t_edocument_id === doc_id);
        if (ammendment) {
            ammendment.ammendment.split("/:/").forEach((str, index) => {
                setValue(`ammendment_${index + 1}`, str);
            });
            setValue('addendum_no', ammendment.addendum_no);
        }
    }, [dataAddendum]);

    const handleRegisteration = (ammendment) => {
        dispatch(setLoadingStatus(true));
        let dataStr = "";
        ammendment = getFormattedData(ammendment);
        keys.forEach((key) => {
            dataStr = dataStr.concat(ammendment[key] || "", "/:/");
        });
        dataStr = dataStr.slice(0, -3);

        let req_data = {};
        req_data.ammendment = dataStr;
        req_data.addendum_no = getValues('addendum_no');
        if (doc_id) {
            req_data.id = doc_id
        }
        dispatch(
            upsertTransaction({
                step: {
                    db: "listing_esign_addendum",
                    main: req_data,
                },
                transaction: {
                    isBelonged: false,
                    main: { ...getPlainTransactionData(dataListing), task_type: "deal" },
                },
            },"Listing Esign Addendum")
        ).then((data) => {
            dispatch(setLoadingStatus(false));
            dispatch(getTransactionEdocById(data.id));
            dispatch(getTransactionEsignById(data.id));
            closePanel(true);
        }).catch((error) => {
            dispatch(setLoadingStatus(false));
            console.log(error);
        });
    };

    const handleError = (error) => {
        console.log(error);
    };

    return (
        <div className="form-container panel-form">
            <Form onSubmit={handleSubmit(handleRegisteration, handleError)}>
                <FormRow>
                    <FormInputWrapper label="Addendum Number *">
                        <input className="uk-input" type="text" {...register("addendum_no")} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <p>This Listing Agreement is subject to the following additional provisions:</p>
                    <FormInputWrapper label="Provision #1" size="two">
                        <textarea type="text" className="uk-input" {...register("ammendment_1")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Provision #2" size="two">
                        <textarea type="text" className="uk-input" {...register("ammendment_2")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Provision #3" size="two">
                        <textarea type="text" className="uk-input" {...register("ammendment_3")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Provision #4" size="two">
                        <textarea type="text" className="uk-input" {...register("ammendment_4")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Provision #5" size="two">
                        <textarea type="text" className="uk-input" {...register("ammendment_5")} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow style={{ marginTop: "16px" }}>
                    <button type="submit" className='uk-button uk-button-primary pull-right'>Save</button>
                    <button type="button" className='uk-button uk-button-orange pull-right' onClick={() => closePanel(false)}>Close</button>
                </FormRow>
            </Form>
        </div>
    )
}

export default PanelFormListingAgreementAddendum;