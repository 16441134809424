const PageSidebarContainer = ({ children, type, ...props }) => {
  if (!type) {
    type = "";
  }

  return (
    <div className={`tm-sidebar-left uk-visible@m ${type}`} {...props}>
      {children}
    </div>
  );
};

export default PageSidebarContainer;
