const datatableStyles = {
  rows: {
    style: {
      // minHeight: '72px', // override the row height
    },
  },
  headCells: {
    style: {
      // paddingLeft: '8px', // override the cell padding for head cells
      // paddingRight: '8px',
      backgroundColor: "#f2f2f2",
      fontSize: "18px",
    },
  },
  cells: {
    style: {
      paddingTop: "16px",
      // paddingRight: '12px',
      paddingBottom: "16px",
      // paddingLeft: '12px',
      fontWeight: "bold",
      fontSize: "15px",
    },
  },
};

export default datatableStyles;
