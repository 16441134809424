import React, { useEffect } from 'react'
import './construction.css'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const UnderConstruction = () => {
  return (
    <div style={{ height: '100vh' }}>
      <main className='mobile-container'>
        <div className="uk-navbar-item mb-4">
          <img width='150px' src={process.env.PUBLIC_URL + "/images/boatdox-logo-blue.png"} alt="" />
        </div>
        <h1>Mobile <br /> Under Construction</h1>
        <img src='/icons/under-construction.svg' alt='' height={100} width={100} className='gear-logo my-2' />
        <h3>To optimize your experience, please use a desktop computer to access BoatDox.</h3>

      </main>
    </div>
  )
}

export default UnderConstruction