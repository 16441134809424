export const Events = {
  'signature_request_viewed': 'Signature Request Viewed',
  'signature_request_signed': 'Signature Request Signed',
  'signature_request_downloadable': 'Signature Request Downloadable',
  'signature_request_sent': 'Signature Request Sent',
  'signature_request_declined': 'Signature Request Declined',
  'signature_request_reassigned': 'Signature Request Reassigned',
  'signature_request_remind': 'Signature Request Remind',
  'signature_request_all_signed': 'Signature Request All Signed',
  'signature_request_email_bounce': 'Signature Request Email Bounce',
  'signature_request_invalid': 'Signature Request Invalid',
  'signature_request_canceled': 'Signature Request Canceled',
  'signature_request_prepared': 'Signature Request Prepared',
  'file_error': 'File Error',
  'unknown_error': 'Unknown Error',
  'sign_url_invalid': 'Sign URL Invalid',
  "signature_hand_signed": "Signature Hand Signed",
  "document_added": "Upload Added",
  "document_status_changed": "Upload Status Changed",
  "document_deleted": "Upload Deleted",
  "upload_document_added": "Upload Document Added",
  "upload_document_deleted": "Upload Document Deleted",
  "task_added": "Task Added",
  "task_deleted": "Task Deleted",
  "task_status_changed": "Task Status Changed",
  "form_added": "Form Added",
  "form_deleted": "Form Deleted",
  "reset_document": "Reset Document",
  "deal_created": "Deal Created",
  "listing_created": "Listing Created",
  "pdf_edited": "PDF Edited",
  "pdf_reset": "Reset Edited PDF",
  "reset_and_counter_offer": "Reset and Counter Offer",
  "esign_link_generated": "E-Sign Link Generated"
}
