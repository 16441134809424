import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Helmet } from "react-helmet";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PageMainContainer from "../common/PageMainContainer";

import FirstPageIcon from '@mui/icons-material/FirstPage';
import { Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { setLoadingStatus } from "../../redux/actions/loading";
import { retrieveOffices } from "../../redux/actions/offices";
import {
  CUSTOM,
  LAST_MONTH,
  LAST_QUARTER,
  LAST_YEAR,
  setDateFilter,
  THIS_MONTH,
  THIS_QUARTER,
  THIS_YEAR,
  YEAR_TO_DATE
} from '../../redux/actions/report';
import {
  clearAllTransactions,
  getAllByFilterOptions,
} from "../../redux/actions/transaction";
import { retrieveRelated } from "../../redux/actions/users";
import Auth from "../../utils/auth";
import { getPrice } from "../../utils/currency";
import datatableStyles from "../../utils/datatable-style";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { DatePicker } from 'antd';
import dayjs from "dayjs";
import { dateRangePickerPresets } from '../../utils/AppConstants';

const { RangePicker } = DatePicker;


const useStyles = makeStyles({
  back_icon: {
    marginBottom: "3px",
    marginRight: "1px"
  },
  back_icon_span: {
    marginRight: "75px",
    fontSize: "larger"
  }
});

const ReportsDealsPage = () => {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataDeals = useSelector((state) => state.transactions.all);
  const dataOffices = useSelector((state) => state.offices);
  const dataBrokers = useSelector((state) => state.users.related);
  const filter = useSelector(state => state.report.filter);
  const isLoading = useSelector((state) => state.loading.status);

  const loggedUser = Auth.getInstance().getUserData();


  const [options, setOptions] = useState({
    office: "all",
    broker: "all",
    status: "all",
    sortby: 'date',
    ...filter
  });
  const [count, setCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  const sortedDeals = useMemo(() => dataDeals?.sort((a, b) => {
    switch (options.sortBy) {
      case 'date':
      default:
        return (moment(a.t_offers?.closing_date) < moment(b.t_offers?.closing_date) ? 1 : -1);
    }
  }), [dataDeals]);

  const [loadCount, setLoadCount] = useState(0);
  useEffect(() => {
    if (loadCount === 0) {
      dispatch(setLoadingStatus(false));
      setLoadCount(0);
    }
  }, [loadCount]);

  useEffect(() => {
    dispatch(setLoadingStatus(true));
    setLoadCount((prev) => prev + 1);
    dispatch(retrieveOffices());
    dispatch(retrieveRelated(loggedUser.id)).then(() => {
      // dispatch(setLoadingStatus(false));
      setLoadCount((prev) => prev - 1);
    }).catch(() => {
      dispatch(setLoadingStatus(false));
      setLoadCount((prev) => prev - 1);
    });

    return () => {
      dispatch(clearAllTransactions());
    };
  }, []);

  useEffect(() => {
    dispatch(setLoadingStatus(true));
    setLoadCount((prev) => prev + 1);
    dispatch(getAllByFilterOptions("deal", options, pageNo, 10)).then((res) => {
      setCount(res.count);
      // dispatch(setLoadingStatus(false));
      setLoadCount((prev) => prev - 1);
    }).catch((err) => {
      dispatch(setLoadingStatus(false));
      setLoadCount((prev) => prev - 1);
    });
  }, [options, pageNo]);

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      ...filter
    }));
  }, [filter])

  // function for change dateRange in payload for api calling
  const handleDateRange = (dateRange) => {
    setOptions((prev) => {
      return {
        ...prev,
        range: [dateRange[0], dateRange[1]]
      }
    })
  }

  const handleSetOptions = (field) => (e) => {
    let value = e.target.value;
    if (field === "dateRange") {
      let range = [];
      if (value === CUSTOM) {
        range = [
          moment().format('MM-DD-YYYY'),
          moment().format('MM-DD-YYYY')
        ];
        dispatch(setDateFilter(value, range));
      } else {
        dispatch(setDateFilter(value));
      }
    } else {
      setOptions((prev) => {
        return {
          ...prev,
          [field]: value,
        };
      });
    }
  };

  const statusToShow = {
    progress: "In process",
    cancelled: "Cancelled",
    draft: "Draft",
    completed: "Sold",
    active: "Active",
    rejected: "Rejected"
  };

  const columns = [
    {
      name: "Vessel",
      selector: "vessel",
      cell: (row) => {
        let vessel = row.t_vessel || {};
        let vessel_title = `${vessel.length || ""} ${vessel.make || ""} ${vessel.model || ""
          } ${vessel.year || ""}`;
        vessel_title = vessel_title === "   " ? "N/A" : vessel_title;
        return <div>{vessel_title}</div>;
      },
    },
    {
      name: "Purchase Price",
      selector: "purchase_price",
      cell: (row) => {
        let offer = row.t_offers || {};
        return <div>{getPrice(offer.purchase_price) || "N/A"}</div>;
      },
    },
    {
      name: "Gross Commission",
      selector: "gross_commission",
      cell: (row) => {
        let cobroker = row.co_broker || {};
        let offer = row.t_offers || {};
        let gross =
          offer &&
            cobroker &&
            cobroker.gross_commission_amount &&
            cobroker.gross_commission_percent
            ? `${getPrice(cobroker.gross_commission_amount)} (${cobroker.gross_commission_percent
            }%)`
            : "N/A";
        return <div>{gross}</div>;
      },
    },
    {
      name: "Offer Date",
      selector: "offer_date",
      cell: (row) => {
        let offer = row.t_offers || {};
        return (
          <div>
            <Moment date={offer.offer_date || ""} format="MM-DD-YYYY" />
          </div>
        );
      },
    },
    {
      name: "Closing Date",
      selector: "closing_date",
      cell: (row) => {
        let offer = row.t_offers || {};
        return (
          <div>
            <Moment date={offer.closing_date || ""} format="MM-DD-YYYY" />
          </div>
        );
      },
    },
    {
      name: "Status",
      selector: "status",
      cell: (row) => {
        let status = row.status || "draft";
        return <div>{statusToShow[status]}</div>;
      },
    },
    {
      name: "Broker",
      selector: "broker",
      cell: (row) => {
        let broker = row.broker || {};
        let name = `${broker.first_name || ""} ${broker.last_name || ""}`;
        name = name === " " ? "N/A" : name;
        return <div>{name}</div>;
      },
    },
    {
      name: "Office",
      selector: "office",
      cell: (row) => {
        let office = row.t_company_office || {};
        return <div>{office.office_name || "N/A"}</div>;
      },
    },
  ];
  const downloadExcel = () => {
    var exportData = [["Vessel", "Purchase Price", "Gross Commission", "Offer Date", "Closing Date", "Status", "Broker", "Office"]]

    const getVessel = (data) => {
      const vessel = data.t_vessel || {};
      let vessel_title = `${vessel.length || ""} ${vessel.make || ""
        } ${vessel.model || ""} ${vessel.year || ""}`;
      vessel_title = vessel_title === "   " ? "N/A" : vessel_title;
      return vessel_title;
    }

    const getAskPrice = (data) => {
      const offer = data.t_offers || {};
      return offer.purchase_price || "N/A";
    }

    const getCommissions = (data) => {
      const cobroker = data.co_broker || {};
      let offer = data.t_offers || {};
      let gross =
        offer && cobroker && cobroker.gross_commission_amount
          ? `${getPrice(cobroker.gross_commission_amount)} (${cobroker.gross_commission_percent}%)` : "N/A";
      return gross;
    }

    const getOfferDate = (data) => {
      const offer = data.t_offers || {};
      return offer.offer_date || "N/A";
    }
    const getClosingDate = (data) => {
      const offer = data.t_offers || {};
      return offer.closing_date || "N/A";
    }

    const getStatus = (data) => {
      const status = data.status || "draft";
      return statusToShow[status];
    }
    const getBroker = (data) => {
      const broker = data.broker || {};
      let name = `${broker.first_name || ""} ${broker.last_name || ""
        }`;
      name = name === " " ? "N/A" : name;
      return name;
    }
    const getOffice = (data) => {
      const office = data.t_company_office || {};
      return office.office_name || "N/A";
    }

    dataDeals?.map((data) => {
      exportData = [...exportData, [getVessel(data),
      getAskPrice(data),
      getCommissions(data),
      getOfferDate(data),
      getClosingDate(data),
      getStatus(data),
      getBroker(data),
      getOffice(data)]]
    }
    )
    const ws = XLSX.utils.aoa_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'Deals-Report.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  };
  return (
    <PageMainContainer type="view-report">
      <Helmet>
        <title>Report Deals - BoatDox</title>
      </Helmet>

      <div className="d-flex flex-wrap justify-content-between  align-items-center ">
        <div>

          <ul className="uk-breadcrumb">
            <li>
              <Link to={"/"}>Reports</Link>
            </li>
          </ul>
          <h1>Deals</h1>
        </div>
        <div>
          <button className='uk-button uk-button-primary mb-0 mt-0 pl-2 text-capitalize' onClick={(e) => {
            e.preventDefault();
            history.push("/")
          }}>
            <FirstPageIcon className={classes.back_icon}></FirstPageIcon>
            <span>Back</span>
          </button>
        </div>
      </div>
      <div className="reports-filter d-flex justify-content-between align-items-center flex-wrap">
        <div className="w-75 w-sm-50">
          <select
            className="uk-select my-1"
            value={options.office}
            onChange={handleSetOptions("office")}
          >
            <option value="all">All Offices</option>
            {dataOffices.map((office) => {
              return (
                <option key={office.id} value={office.id}>
                  {office.office_name}
                </option>
              );
            })}
          </select>

          <select
            className="uk-select my-1"
            value={options.broker}
            onChange={handleSetOptions("broker")}
          >
            <option value="all">All Brokers</option>
            {dataBrokers.map((client) => {
              return (
                <option key={client.id} value={client.id}>
                  {`${client.first_name || ""} ${client.last_name || ""}`}
                </option>
              );
            })}
          </select>

          <select
            className="uk-select my-1"
            value={options.status}
            onChange={handleSetOptions("status")}
          >
            <option value="all">All Stages</option>
            <option value="active">Active</option>
            <option value="progress">In-Process</option>
            <option value="draft">Draft</option>
            <option value="completed">Closed</option>
            <option value="cancelled">Cancelled</option>
            <option value="rejected">Rejected</option>
          </select>

          <select
            className="uk-select my-1"
            value={options.rangeType}
            onChange={handleSetOptions("dateRange")}
          >
            <option value={THIS_MONTH}>This Month</option>
            <option value={LAST_MONTH}>Last Month</option>
            <option value={THIS_QUARTER}>This Quarter</option>
            <option value={LAST_QUARTER}>Last Quarter</option>
            <option value={YEAR_TO_DATE}>Year To Date</option>
            <option value={THIS_YEAR}>This Year</option>
            <option value={LAST_YEAR}>Last Year</option>
            <option value={CUSTOM}>Custom</option>
          </select>
          {options.rangeType === CUSTOM && (
            <RangePicker
              style={{ height: "40px", width: "220px" }}
              format="MM-DD-YYYY"
              allowClear={true}
              presets={dateRangePickerPresets}
              onChange={(value, dateString) => {
                handleDateRange(dateString);
              }}
            />
          )}

        </div>
        <div>

          <button className='btn btn-previous btn-sm' onClick={downloadExcel}>
            <div> <CloudDownloadIcon />  Download</div>
          </button>
        </div>
      </div>

      {!sortedDeals.length && isLoading
        ? <div className="no-data-table"><div className="p-4">Fetching Details...</div></div>
        : <DataTable
          noHeader
          customStyles={datatableStyles}
          columns={columns}
          data={sortedDeals}
        />
      }

      <Pagination
        count={Math.ceil(count / 10)}
        variant="outlined"
        shape="rounded"
        page={pageNo}
        onChange={(e, page) => setPageNo(page)}
      />
    </PageMainContainer>
  );
};

export default ReportsDealsPage;
