import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Button, Drawer, Space } from 'antd';

import FormInputWrapper from "../common/FormInputWapper";
import FormRow from "../common/FormRow";
import GridForm from "../common/GridForm";
import ModalBody from "../common/ModalBody";
import OffCanvasContainer from "../common/OffCanvasContainer";
import Auth from '../../utils/auth';
import { getFigure, getPrice } from "../../utils/currency";
import OfficeService from '../../services/OfficeService';

import { phoneNumber } from '../../utils/phonenumber';

import { retrieveRoles, inviteUser, retrieveOffices, retrieveBrokers, getProfile } from "../../redux/actions/users";
import { Tooltip } from "@mui/material";

const MyOfficeModal = ({ show, onClose }) => {
  const [brokerData, setBrokerData] = useState({});
  const [closingAgentData, setClosingAgentData] = useState({});
  const loggedUser = Auth.getInstance().getUserData();

  const dispatch = useDispatch();
  const dataBrokersAndAssistants = useSelector(state => state.users.brokers);
  const offices = useSelector(state => state.users.offices);
  const officeData = offices.find(office => office.id === loggedUser.company_office_id);
  const dataBrokers = dataBrokersAndAssistants.filter(broker => broker.UserInRole.UserRole.name === 'Broker');
  const dataBrokerAssistants = dataBrokersAndAssistants.filter(broker => broker.UserInRole.UserRole.name === 'Broker Assistant');

  const [displayedUser, setDisplayUser] = useState(loggedUser);

  useEffect(() => {
    if (show) {
      dispatch(getProfile(loggedUser.id)).then((profile) => {
        setDisplayUser(profile);
      });
    }
  }, [show]);

  useEffect(() => {
    if (loggedUser.company_id) {
      dispatch(retrieveOffices(loggedUser.company_id));

      if (loggedUser.company_office_id) {
        dispatch(retrieveBrokers(loggedUser.company_id, loggedUser.company_office_id));
        OfficeService.getAdmin(loggedUser.company_office_id).then(
          response => {
            const broker = response.data;
            setBrokerData(broker.office?.employing_broker ? broker.office?.employing_broker : broker)
          });
        // OfficeService.getClosingAgent(loggedUser.company_office_id).then(res => setClosingAgentData(res.data));
      }
    }
  }, [loggedUser.company_office_id, loggedUser.company_id]);

  const drawerStyles = {
    header: {
      display: 'flex', justifyContent: 'space-between'
    },
  };

  // Custom title for the My Office title with name of company
  const companyName = () => {
    return (
      <>My Office {displayedUser.company?.company_name && <span>(<span style={{ color: '#5479A3' }}>{displayedUser.company?.company_name}</span>)</span>}</>
    )
  }

  return (
    <Drawer
      style={drawerStyles}
      title={companyName()}
      placement="right"
      size='large'
      onClose={() => onClose(false)}
      closable={false}
      open={show}
      extra={
        <Space>
          <Button type='text' onClick={() => onClose(false)}><i className="fa fa-times-circle fa-lg"></i></Button>
        </Space>
      } >
      <Fragment>
        <GridForm>
          <FormRow>
            <FormInputWrapper label="Office Name">
              <div className="input-view">{officeData?.office_name || '\u00A0'}</div>
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Office Phone">
              <div className="input-view">{officeData?.office_phone ? phoneNumber(officeData?.office_phone) : '\u00A0'}</div>
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Office Address">
              <div className="input-view">{officeData?.address_1 || officeData?.address_2 || officeData?.address_3 || '\u00A0'}</div>
            </FormInputWrapper>
            {officeData?.address_2 && <FormInputWrapper label={'\u00A0'}>
              <div className="input-view">{officeData?.address_2 || '\u00A0'}</div>
            </FormInputWrapper>}
            {officeData?.address_3 && <FormInputWrapper label={'\u00A0'}>
              <div className="input-view">{officeData?.address_3 || '\u00A0'}</div>
            </FormInputWrapper>}
          </FormRow>
          <FormRow>
            <FormInputWrapper label="City">
              <div className="input-view">{officeData?.city || '\u00A0'}</div>
            </FormInputWrapper>
            <FormInputWrapper label="State">
              <div className="input-view">{officeData?.state || '\u00A0'}</div>
            </FormInputWrapper>
            <FormInputWrapper label="Zip Code">
              <div className="input-view">{officeData?.postal_code || '\u00A0'}</div>
            </FormInputWrapper>
          </FormRow>
          <FormRow />
          <FormRow>
            <FormInputWrapper label="Office Manager">
              <div className="input-view">
                {!!brokerData?.first_name && !!brokerData?.last_name
                  ? `${brokerData?.first_name} ${brokerData?.last_name}`
                  : '\u00A0'
                }
              </div>
            </FormInputWrapper>
            <FormInputWrapper label="Phone">
              <div className="input-view">{brokerData?.mobile_phone ? phoneNumber(brokerData?.mobile_phone) : '\u00A0'}</div>
            </FormInputWrapper>
            <FormInputWrapper label="Email">
              <div className="input-view">{brokerData?.email || '\u00A0'}</div>
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Closing Agent Name" width="sm-full-width">
              <div className="input-view">
                {!!closingAgentData?.first_name && !!closingAgentData?.last_name
                  ? `${closingAgentData?.first_name} ${closingAgentData?.last_name}`
                  : '\u00A0'
                }
              </div>
            </FormInputWrapper>
            <FormInputWrapper label="Closing Agent Phone" width="sm-full-width" >
              <div className="input-view">{closingAgentData?.mobile_phone ? phoneNumber(closingAgentData?.mobile_phone) : '\u00A0'}</div>
            </FormInputWrapper>
            <FormInputWrapper label="Closing Agent Email">
              <div className="input-view">{closingAgentData?.email || '\u00A0'}</div>
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Broker Admin">
              <div className="input-view">
                {dataBrokerAssistants[0] && (
                  (!!dataBrokerAssistants[0].first_name && !!dataBrokerAssistants[0].last_name)
                    ? `${dataBrokerAssistants[0].first_name} ${dataBrokerAssistants[0].last_name}`
                    : '\u00A0'
                )}
              </div>
            </FormInputWrapper>
            <FormInputWrapper label="Phone">
              <div className="input-view">{dataBrokerAssistants[0] && dataBrokerAssistants[0].mobile_phone ? phoneNumber(dataBrokerAssistants[0].mobile_phone) : '\u00A0'}</div>
            </FormInputWrapper>
            <FormInputWrapper label="Email">
              <div className="input-view">{dataBrokerAssistants[0] && dataBrokerAssistants[0].email || '\u00A0'}</div>
            </FormInputWrapper>
          </FormRow>
        </GridForm>
      </Fragment>
    </Drawer>



  )
};

export default MyOfficeModal;
