import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import CompanyService from "../../services/CompanyService";

const CompanyView = (props) => {
  const company_id = props.location.state ? props.location.state.id : null;

  const [company, setCompany] = useState({});

  useEffect(() => {
    CompanyService.get(company_id).then((company) => {
      setCompany(company.data);
    });
  }, []);

  return (
    <div className="uk-container page">
      <ul className="uk-breadcrumb">
        <li>
          <Link to="/companies">companies</Link>
        </li>
        <li className="uk-disabled">
          <a>View Company</a>
        </li>
      </ul>

      <h1>{company.company_name}</h1>

      <div className="form-container">
        <form className="uk-grid-small form" data-uk-grid>
          <div className="uk-width-1-3@s  uk-margin mt20">
            <label className="uk-form-label" htmlFor="name">
              Company Name
            </label>
            <div className="uk-text uk-text-emphasis">
              {company.company_name}
            </div>
          </div>
          <div className="uk-width-1-3@s  uk-margin mt20">
            <label className="uk-form-label" htmlFor="name">
              Company Email
            </label>
            <div className="uk-text uk-text-emphasis">
              {company.company_email}
            </div>
          </div>
        </form>
      </div>

      <div className="uk-column-1-1  uk-margin-small">
        <Link to={"/companies"}>
          <button className="uk-button iy-button-primary">Done</button>
        </Link>
      </div>
    </div>
  );
};

export default CompanyView;
