import Breadcrumb from './BreadCrumb';

const FormPage = ({ links, title, vesselName = '', uniqueId = '', children }) => {
  return (
    <div className="uk-container uk-container-small uk-position-relative">
      <Breadcrumb links={links} />
      <div className="content add-forms">
        <h2>{title}</h2>
        {(vesselName && uniqueId) &&
          <div className="text-center">
            <span className="uk-text-emphasis title">
              {vesselName}
            </span>
            <span className="uk-label stylus-bg-in-process pull-right" style={{ color: '#fff' }}>UID: {uniqueId}</span>
          </div>
        }
        <div className="form-container">
          {children}
        </div>
      </div>
    </div>
  )
}

export default FormPage;
