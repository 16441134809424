import React from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { setLoadingStatus } from "../../../../redux/actions/loading";
import { uploadTemplate } from '../../../../utils/template';

export const Nav = (props) => {
  const dispatch = useDispatch();

  const dots = [];
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const isActive = props.currentStep === i;
    dots.push((
      <span
        key={`step-${i}`}
        className={`dot ${isActive ? 'active' : ''}`}
      // onClick={() => props.goToStep(i)}
      >&bull;</span>
    ));
  }

  const validateStep = (currentStep, formData) => {
    switch (currentStep) {
      case 1:
        if ((formData.template_file || (formData.file_name && !formData.is_add)) && formData.checklist_id && formData.association) {
          if (formData.is_multi_section) {
            return parseInt(formData.total_sections) > 0;
          } else {
            return true;
          }
        }
        break;
      case 2:
        if (formData.file_name && formData.roles.length > 0 && !(formData.roles.indexOf('') > -1)) {
          return true;
        }
        break;
      case 3:
        return true;
      case 4:
        return true;
      default:
        return false;
    }
  }

  const history = useHistory()
  const handlePrevious = () => {
    if (props.currentStep == 1) {
      history.push('/esign-templates')
    } else {
      props.previousStep();
    }
  }

  const handleContinue = async () => {
    if (validateStep(props.currentStep, props.form)) {
      if (props.currentStep == 1 && props.form.template_file && (!props.form.file_link || !props.form.file_name)) {
        dispatch(setLoadingStatus(true));
        try {
          const res = await uploadTemplate(props.form.template_file, 'esign-templates');
          props.update('file_link', res);
          // props.update('file_name', `https://${process.env.REACT_APP_FORMS_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}esign-templates/${res}`);
          dispatch(setLoadingStatus(false));
          props.nextStep();
        } catch (err) {
          dispatch(setLoadingStatus(false));
          console.log(err);
        }
      } else {
        props.nextStep();
      }
    }
  }

  return (
    <div className="nav-bar">
      {props.currentStep !== 0 &&
        <div style={{ position: 'absolute', left: '30px' }}>
          <button className="btn btn-previous" onClick={handlePrevious}>Back</button>
        </div>
      }

      <div className="nav">{dots}</div>

      {props.currentStep !== 4 &&
        <div style={{ position: 'absolute', right: '30px' }}>
          <button className='btn btn-continue' onClick={handleContinue} disabled={!validateStep(props.currentStep, props.form)}>Continue</button>
        </div>
      }
    </div>
  );
};