/* eslint-disable react-hooks/exhaustive-deps */
// import { MDBBtn, MDBContainer, MDBIcon, MDBTypography } from "mdb-react-ui-kit";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
// import "./pdfStyle.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import MoonLoader from "react-spinners/MoonLoader";
import { Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Loading from "../loading";
// import { usePDF } from '@react-pdf/renderer';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
function PdfRender({ props, pageProps, doc, renderPdf, mergeProps, templatePageCount, handlePdfClick, textFields, handleTextChange, handleCheckboxSize, handleTextDelete, handleTextFontSize, checkboxFields, handleCheckboxChange, handleTextBackground, handleCheckboxDelete, handleCheckboxBackground }) {

  const currentPage = useRef(0);

  // Get the value of the "url" query parameter, or set it to a default value "pdf" if not present
  // const urlValue = doc; // urlParams.get("url") ? urlParams.get("url") : "pdf";
  // let urlValue = useMemo(() => doc, [doc]);
  // console.log(urlValue ,doc , "url value.......");


  const { mergedPdfUrl, setMergedPdfUrl } = mergeProps;
  const [numPages, setNumPages] = useState(0);
  const { pageNumber, setPageNumber, size, setSize } = pageProps;
  const [isLoading, setIsLoading] = useState(true);
  const [renderedPageNumber, setRenderedPageNumber] = useState(null);
  const [renderedScale, setRenderedScale] = useState(null);
  // const [instance, updateInstance] = usePDF({ document: doc });
  // console.log("updateInstance", instance.loading);

  // true if transparent
  const [textBackground, setTextBackground] = useState(false);

  const pdfRef = useRef(null);
  const pageRefs = useRef(Array(numPages).fill().map(() => React.createRef()));
  useEffect(() => {
    // Initialize refs when numPages changes
    pageRefs.current = Array(numPages).fill().map(() => React.createRef());
  }, [numPages]);

  useEffect(() => {
    if (pageNumber > 0 && pageNumber <= numPages) {
      const index = pageNumber - 1; // Convert to zero-based index
      const ref = pageRefs.current[index]?.current;

      if (ref) {
        // Log to confirm the ref
        ref.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.error(`Ref for page ${pageNumber} is not ready`);
      }
    }
  }, [pageNumber, numPages]);




  function onDocumentLoadSuccess({ numPages }) {
    setIsLoading(false);
    setNumPages(numPages);
  }

  // useMemo used for memoize pdfUrl bcz if same url will pass again than it will give error on Document rendering about unnecessary reloads
  // here doc is url of pdf you can log to see
  const fileOptions = useMemo(() => ({ url: mergedPdfUrl || doc }), [mergedPdfUrl, doc]);
  useEffect(() => {
    setIsLoading(true);
  }, [fileOptions]);





  const loading = renderedPageNumber !== pageNumber;
  return (
    <div className=" bg-gradient">
      {/* {pdfBlob && ( */}
      <>

        <div className="d-flex justify-content-center" >
          <Document
            file={fileOptions}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(error) => {
              setIsLoading(false);
            }}
            className={`d-flex align-items-center  pdf-document-viewer ${loading ? 'pdf-loaded' : ''}`}
            onRender={() => <Loading loading={true} />}
            loading={() => <div style={{ height: '75vh', display: 'flex', alignItems: 'center' }} > <MoonLoader color="#0063A6" /></div>}
          >
            <div ref={pdfRef}>
              {Array.from(new Array(numPages)).map((_, index) => (
                <div key={index} className="mb-2 shadow" ref={pageRefs.current[index]}>

                  {/* <div  style={{ position: "relative" }} > */}

                  {/* {isLoading ? (
                    <Page
                      key={renderedPageNumber + "@" + renderedScale}
                      className="prevPage"
                      pageNumber={index + 1}
                      scale={5}
                      width={500}
                    />
                  ) : null} */}
                  <Page
                    onClick={handlePdfClick}
                    key={`${index}@${90}_canvas@${size}_canvas`}
                    scale={size}
                    wrap={true}
                    renderMode="canvas"
                    pageNumber={index + 1}
                    enhanceTextSelection={true}
                    onLoadError={(error) => console.log('Error while loading document! ' + error.message)}
                    width={500}
                    renderAnnotationLayer={true}
                    renderForms={true}
                    onRenderAnnotationLayerSuccess={(e) => { renderPdf(e, index + 1); setRenderedPageNumber(index + 1); }}
                    loading={() => <Loading loading={true} />}
                    className={`position-relative react-pdf__Page custom-editor-page ${(mergedPdfUrl || (templatePageCount && numPages > templatePageCount)) && "merge-pdf__page"}`}
                  >
                    {/* for textbox */}
                    {textFields.map((field) => (
                      <div className="d-flex justify-content-center align-items-center"
                        style={{
                          position: 'fixed',
                          top: field.y,
                          left: field.x,
                          zIndex: "100"
                        }}
                      >
                        {console.log(field, "FIELD")}
                        <div >
                          <textarea
                            // className='uk-input'
                            key={field.id}
                            value={field.text}
                            onChange={(e) => handleTextChange(e, field.id)}
                            style={{
                              backgroundColor: field.background === true ? "transparent" : "#d9e5ff",
                              border: '2px solid black',
                              fontSize: `${field.fontSize}px`,
                              color: 'black',
                              verticalAlign: "middle"
                            }}
                          />
                        </div>

                        <div className="d-flex justify-content-center align-items-center m-1">
                          <i
                            class="fa fa-trash fa-lg text-danger m-1"
                            aria-hidden="true"
                            style={{ fontSize: '25px', }}
                            onClick={(e) => handleTextDelete(e, field.id)}
                          ></i>
                          <input type="number" min={10} max={50}
                            onChange={(e) => handleTextFontSize(e.target.value, field.id)}
                            value={field.fontSize}
                            className="rounded size-changer"
                            style={{
                              height: '30px',
                              width: '40px',
                              fontSize: '13px',
                              fontWeight: "bold",
                              border: "2px solid black"
                            }}

                          />
                          <div
                            onClick={(e) => handleTextBackground(!field.background, field.id)}
                            className="m-1 text-center rounded"
                            style={{
                              height: '30px',
                              width: '40px',
                              fontSize: '15px',
                              border: `2px solid ${field.background ? "#1976D2" : "black"}`
                            }}>
                            <img className="w-100 h-100" src="/images/transparent.png" style={{ objectFit: "cover" }} />
                          </div>
                        </div>
                      </div>
                    ))}

                    {/* for checkbox */}
                    {checkboxFields.map((field) => (
                      <div className="d-flex justify-content-center align-items-center"
                        style={{
                          position: 'fixed',
                          top: field.y,
                          left: field.x,
                          zIndex: "100"
                        }}
                      >
                        <div >
                          <input
                            // className="uk-input"
                            key={field.id}
                            type="checkbox"
                            checked={field.checked}
                            onChange={(e) => handleCheckboxChange(e, field.id)}
                            style={{
                              backgroundColor: field.background === true ? "transparent" : "white",
                              width: field.size + "px",
                              height: field.size + "px"
                            }}
                          />
                        </div>

                        <div className="d-flex justify-content-center align-items-center">
                          <i
                            class="fa fa-trash fa-lg text-danger m-1"
                            aria-hidden="true"
                            style={{ fontSize: '25px', }}
                            onClick={(e) => handleCheckboxDelete(e, field.id)}
                          ></i>
                          <input type="number" min={10} max={50}
                            onChange={(e) => handleCheckboxSize(e.target.value, field.id)}
                            value={field.size}
                            className="rounded"
                            style={{
                              height: '30px',
                              width: '40px',
                              fontSize: '13px',
                              fontWeight: "bold",
                              border: "2px solid black"
                            }}

                          />
                          {/* <div
                            onClick={(e) => handleCheckboxBackground(!field.background, field.id)}
                            className="m-1 text-center"
                            style={{
                              height: '30px',
                              width: '40px',
                              fontSize: '15px',
                              border: `2px solid ${field.background ? "green" : "black"}`
                            }}>
                            T
                          </div> */}
                        </div>

                      </div>
                    ))}

                  </Page>
                </div>
                // </div>
              ))}
            </div>
          </Document>
        </div>
      </>
      {/* )} */}
    </div>
  );
}

export default PdfRender;
