import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import { DebounceInput } from "react-debounce-input";
import {
  retrieveCompanies,
  updateCompany,
  findCompanyByName,
} from "../../redux/actions/companies";
import datatableStyles from "../../utils/datatable-style";
import {
  DELETE_MODAL_MESSAGE,
  DELETE_MODAL_TITLE,
} from "../../constants/Alert";
import { setLoadingStatus } from "../../redux/actions/loading";
import CompaniesService from "../../services/CompanyService";
import { toast } from "react-toastify";

const CompaniesList = () => {
  // const [search, setSearch] = useState("");
  // const dataCompanies = useSelector((state) => state.companies.companies);
  const isLoading = useSelector((state) => state.loading.status);
  const dataAssociations = useSelector(state => state.associations);
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  //Make a state for search
  const [search, setSearch] = useState({});

  //Store users data for listing
  const [dataCompanies, setDataCompanies] = useState([]);

  //Store count of total rows
  const [totalRows, setTotalRows] = useState(0)

  const fetchCompanyData = (search) => {
    dispatch(setLoadingStatus(true))
    CompaniesService.findByName(search)
      .then((res) => {
        const dataCompanies = res.data.data;
        const totalRecords = res.data.total;
        setDataCompanies(dataCompanies);
        setTotalRows(totalRecords);
        dispatch(setLoadingStatus(false))
      })
      .catch((error) => {
        dispatch(setLoadingStatus(false))
        console.log(error);
        toast.error("Error occurs, please try again.", {
          autoClose: 2000
        })
      })
  }

  useEffect(() => {
    fetchCompanyData(search)

    // dispatch(findCompanyByName(search));
  }, [search]);

  const onDelete = (id) => {
    confirmAlert({
      title: DELETE_MODAL_TITLE,
      message: DELETE_MODAL_MESSAGE,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const data = {
              deleted: true,
              deleted_at: new Date(),
            };
            dispatch(updateCompany(id, data))
              .then((response) => {
                fetchCompanyData(search)
              })
              .catch((e) => {
                console.log("Error = ", e);
              });
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const columns = [
    {
      name: "Company Name",
      selector: "company_name",
      sortable: true,
      minWidth: "250px",
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            <Link to={{ pathname: `${path}/view`, state: { id: row.id } }}>
              {row.company_name}
            </Link>
          </div>
        </div>
      ),
    },
    {
      name: 'Association',
      width: "20rem",
      cell: row => (
        <div>
          {row.association && row.association?.toUpperCase()}
        </div>
      ),
      grow: 0
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <Link to={{ pathname: `${path}/edit`, state: { id: row.id } }}>
            <img
              src="/icons/edit.svg"
              className="svg"
              alt=""
            />
          </Link>
          <img
            src="/icons/delete.svg"
            className="svg"
            onClick={() => onDelete(row.id)}
            alt=""
          />
        </div>
      ),
    },
  ];

  //Function for handle row limit
  const handleRowsPerPage = (numberOfRows, page) => {
    setSearch((search) => {
      return {
        ...search,
        limit: numberOfRows,
        page: page
      }
    })
  }

  //Function for handle page no
  const handlePageChange = page => {
    setSearch((search) => {
      return {
        ...search,
        page: page
      }
    })
  };

  //Function for handle sort
  const handleSort = (column, sortDirection) => {
    // column.selector, sortDirection
    setSearch((search) => {
      return {
        ...search,
        sortOrder: sortDirection,
        sortField: column.selector
      }
    })
  };

  return (
    <div className="uk-container uk-container-expand uk-position-relative">
      <div className="content">
        <h1>Companies</h1>

        <div className="uk-column-1-2  uk-margin-small">
          <Link to={`${path}/add`}>
            <div className="uk-button uk-button-default add-deal   align-items-center my-2">
              <span
                className="uk-margin-small-right uk-icon"
                data-uk-icon="plus"
              ></span>{" "}
              <span>Add</span>
            </div>
          </Link>
          <div className="list-page-search-container">
            <form className="uk-search uk-search-default search-top-margin">
              <DebounceInput
                className="uk-search-input"
                type="search"
                placeholder="Search"
                onChange={(e) => setSearch(state => {
                  return {
                    ...state,
                    name: e.target.value
                  }
                })}
                value={search.name ? search.name : ""}
                debounceTimeout={500}
                minLength={1}
              />
              {!search.name ? (
                <span className="bd-form-icon">
                  <i className="fa fa-search"></i>
                </span>
              ) : (
                <span
                  className="bd-form-icon"
                  onClick={() => setSearch(state => {
                    return {
                      ...state,
                      name: ""
                    }
                  })}
                >
                  <i className="fa fa-times"></i>
                </span>
              )}
            </form>
          </div>
        </div>

        {/* Companies List Table */}
        {
          !dataCompanies.length && isLoading
            ? <div className="no-data-table"><div className="p-4">Fetching Details...</div></div> :
            <DataTable
              title="Companies Table"
              columns={columns}
              data={dataCompanies}
              striped={true}
              highlightOnHover={true}
              pointerOnHover={true}
              noHeader={true}
              fixedHeader={true}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationRowsPerPageOptions={[10, 20, 50, 100]}
              customStyles={datatableStyles}
              onChangeRowsPerPage={handleRowsPerPage}
              onChangePage={handlePageChange}
              onSort={handleSort}
              sortServer
              persistTableHead
            />}
      </div>
    </div>
  );
};

export default CompaniesList;
