import React, { useEffect, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import { getTransactionInitialById, upsertTransaction, } from "../../../../../redux/actions/transaction";
import { getFormattedData, getPlainTransactionData } from "../../../../../utils/stepFilter";
import { getFigure, getPrice } from "../../../../../utils/currency";
import toWrittenPrice, { toWordsConverter } from '../../../../../utils/toWrittenPrice';
import { setLoadingStatus } from '../../../../../redux/actions/loading';
import Column from "../../../../common/Column";

import moment from "moment";
import clsx from "clsx";
import * as yup from "yup";
import HelperTerms from '../../../../common/HelperTerms';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { termsDateFields } from '../../../../../utils/AppConstants';
import { dateFormatter } from '../../../../../utils/common';

const PanelFormTerms = ({ closePanel }) => {

    const schema = yup.object().shape({
        asking_price: yup.string().required(),
        contract_date: yup.date().required(),
        contract_end_date: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
        commission_amount: yup.string().required(),
    });

    const { register, handleSubmit, setValue, getValues, watch, formState: { errors }, control } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    const dispatch = useDispatch();

    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);
    const dataListing = dataTransaction || {};
    const dataTerms = dataListing.t_terms || {};

    const [requiredFields, setRequiredFields] = useState(['asking_price', 'contract_date', 'commission_amount']);
    const [ruleOfBinding, setRulOfBinding] = useState(dataTerms.dispute_location || "A");
    const [ruleOfLocationCity, setRuleOfLocationCity] = useState(dataTerms.dispute_location_city || "Fort Lauderdale");
    const [ruleOfLocationLaw, setRuleOfLocationLaw] = useState(dataTerms.dispute_location_law || "Florida Law");
    const [commission, setCommission] = useState(undefined);
    const [boolIsYBAA, setIsYBAA] = useState(dataTransaction?.association === 'YBAA');

    useEffect(() => {
        if (dataTerms) {
            setValue("commission_percent", 10);
            Object.keys(dataTerms).map((key) => setValue(key, dataTerms[key]));
            setCommission(dataTerms.commission_type || "percentage");
            setValue(
                "contract_date",
                dataTerms.contract_date
                    ? dateFormatter(dataTerms?.contract_date)
                    : moment().local().format("yyyy-MM-DD")
            );
            setValue(
                "contract_end_date",
                dataTerms.contract_end_date
                    ? dateFormatter(dataTerms?.contract_end_date)
                    : null
            );
            if (dataTerms.asking_price) {
                setValue("asking_price", dataTerms.asking_price ? getPrice(dataTerms.asking_price) : null);
            }
            if (dataTerms.commission_amount) {
                setValue(
                    "commission_amount",
                    dataTerms.commission_amount ? getPrice(dataTerms.commission_amount) : null
                );
            }

            if (dataTerms?.minimum_commission_amount) {
                setValue("minimum_commission_amount", dataTerms.minimum_commission_amount ? getPrice(dataTerms.minimum_commission_amount) : null);
            }
            requiredFields.map((value) => {
                manageRequireFields(value, getValues(value));
            });
        } else {
            [
                "asking_price_written",
                "commission_percent_written",
                "dispute_location_city",
                "other_rules_apply",
                "showing_instructions",
            ].map((key) => setValue(key, null));
            setValue("commission_percent", 10);
        }
    }, [dataTransaction]);

    useEffect(() => {
        setRulOfBinding(getValues("dispute_location"));
    }, [watch("dispute_location")]);

    useEffect(() => {
        setRuleOfLocationCity(getValues("dispute_location_city"));
    }, [watch("dispute_location_city")]);

    useEffect(() => {
        setRuleOfLocationLaw(getValues("dispute_location_law"));
    }, [watch("dispute_location_law")]);

    useEffect(() => {
        let asking_price = getFigure(getValues("asking_price"));
        if (!asking_price) {
            setValue("commission_percent", 10);
            setValue("commission_amount", null);
        }
        if (commission === "percentage") {
            if (getValues("commission_percent") && asking_price) {
                setValue(
                    "commission_amount",
                    getPrice(((asking_price * parseFloat(getValues("commission_percent"))) / 100).toFixed(3))
                );
                setValue("selling_brokerage_percent", 50);
            }
            manageRequireFields('commission_amount', getValues('commission_amount'));
        }
        if (asking_price)
            setValue('asking_price_written', toWrittenPrice(asking_price));
    }, [watch("asking_price")]);

    useEffect(() => {
        if (commission === "percentage") {
            let asking_price = getFigure(getValues("asking_price"));
            if (getValues("commission_percent") && asking_price) {
                setValue(
                    "commission_amount",
                    getPrice(((asking_price * parseFloat(getValues("commission_percent"))) / 100).toFixed(3))
                );
                setValue("selling_brokerage_percent", 50);
            }
        }
        setValue('commission_percent_written', toWordsConverter(getFigure(getValues("commission_percent"))));
    }, [watch("commission_percent")]);

    useEffect(() => {
        if (commission === "amount") {
            let asking_price = getFigure(getValues("asking_price"));
            if (getValues("commission_amount") && asking_price) {
                setValue(
                    "commission_percent",
                    ((parseFloat(getFigure(getValues("commission_amount"))) / asking_price) * 100).toFixed(3)
                );
            }
        }
    }, [watch("commission_amount")]);


    const handleRegistration = (term) => {
        if (dataTerms) {
            term.id = dataTerms.id;
        }

        // change all date string in yyyy-MM-DD format
        for (const index in termsDateFields) {
            term[termsDateFields[index]] = dateFormatter(term[termsDateFields[index]]);
        }

        term.asking_price = getFigure(term.asking_price);
        term.commission_amount = getFigure(term.commission_amount);
        term.minimum_commission_amount = getFigure(term?.minimum_commission_amount);
        term.commission_type = commission;

        getFormattedData(term);
        dispatch(setLoadingStatus(true));
        dispatch(
            upsertTransaction({
                step: {
                    db: "terms",
                    main: term,
                },
                transaction: {
                    isBelonged: false,
                    main: {
                        ...getPlainTransactionData(dataListing),
                        task_type: "listing",
                        broker_user_id: dataBroker?.id,
                        company_id: dataBroker?.company_id,
                        company_office_id: dataBroker?.company_office_id,
                    },
                },
            }, "Terms")
        ).then((data) => {
            dispatch(setLoadingStatus(false));
            dispatch(getTransactionInitialById(data.id));
            closePanel(true);
        }).catch((error) => {
            dispatch(setLoadingStatus(false));
            console.log(error);
        }
        );
    };

    const handleError = err => {
        console.log('Error = ', err);
    };

    const handleBlur = (ref) => {
        const price = getPrice(getValues(ref));
        setValue(ref, price);
    };

    const handleFocus = (ref) => {
        const price = getFigure(getValues(ref));
        setValue(ref, price);
    };

    const manageRequireFields = (fieldName, fieldValue) => {
        setRequiredFields(state => {
            if (fieldValue) {
                return state.filter(field => field != fieldName);
            }
            return state.includes(fieldName) ? state : [...state, fieldName];
        })
    }

    return (
        <div className="form-container panel-form">
            <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                <FormRow>
                    <FormInputWrapper label="Asking Price *">
                        <input className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('asking_price') })}
                            type="text"
                            {...register("asking_price", {
                                onChange: e => {
                                    manageRequireFields('asking_price', e.target.value);
                                }
                            })}
                            onBlur={() => handleBlur("asking_price")}
                            onFocus={() => handleFocus("asking_price")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Contract Date *">
                        <Controller
                            control={control}
                            name="contract_date"
                            render={({ field }) => (
                                <DatePicker
                                    className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('contract_date') })}
                                    style={{ cursor: "pointer", color: "#000" }}
                                    format={'MM/DD/YYYY'}
                                    value={field.value && dayjs(field.value)}
                                    onChange={(date) => {
                                        field.onChange(date);
                                        manageRequireFields("contract_date", date || null);
                                    }}
                                />
                            )}
                        />
                    </FormInputWrapper>
                    <FormInputWrapper label="Brokerage Name">
                        <input
                            type="text"
                            className="uk-input"
                            defaultValue={`${dataBroker?.company?.company_name || ""}`}
                            readOnly
                        />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper size="two" label="Asking Price (written)">
                        <input
                            className="uk-input"
                            type="text"
                            {...register("asking_price_written")}
                            readOnly
                        />
                    </FormInputWrapper>
                    <FormInputWrapper label="Contract End Date">
                        <Controller
                            control={control}
                            name="contract_end_date"
                            render={({ field }) => (
                                <DatePicker
                                    className={"uk-input"}
                                    style={{ cursor: "pointer", color: "#000" }}
                                    format={'MM/DD/YYYY'}
                                    value={field.value && dayjs(field.value)}
                                    onChange={(date) => {
                                        field.onChange(date);
                                    }}
                                />
                            )}
                        />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <Column>
                        <label className="toys">Commission</label>
                        <div className="radio-toolbar">
                            <input
                                type="radio"
                                name="radioToys"
                                value="percentage"
                                id='percentage'
                                checked={commission === "percentage"}
                                onChange={(evt) => setCommission(evt.target.value)}
                            />
                            <label htmlFor='percentage' className="uk-form-label radio">Percentage</label>
                            <input
                                type="radio"
                                name="radioToys radio"
                                value="amount"
                                id="amount"
                                checked={commission === "amount"}
                                onChange={(evt) => setCommission(evt.target.value)}
                            />
                            <label htmlFor='amount' className="uk-form-label radio">Amount</label>
                        </div>
                    </Column>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Gross Commission %">
                        <input
                            className="uk-input required"
                            type="text"
                            {...register("commission_percent")}
                            readOnly={commission !== "percentage"}
                        />
                    </FormInputWrapper>

                    <FormInputWrapper label="Gross Commission $ *">
                        <input
                            className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('commission_amount') })}
                            type="text"
                            {...register("commission_amount", {
                                onChange: e => {
                                    manageRequireFields('commission_amount', e.target.value);
                                }
                            })}
                            onBlur={() => handleBlur("commission_amount")}
                            onFocus={() => handleFocus("commission_amount")}
                            readOnly={commission !== "amount"}
                        />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper size="one" label="Gross Commission (written)">
                        <input
                            className="uk-input required"
                            type="text"
                            {...register("commission_percent_written")}
                            readOnly
                        />
                    </FormInputWrapper>
                    <FormInputWrapper size="one" label="Minimum Commission Amount">
                        <input
                            className="uk-input"
                            type="text"
                            {...register("minimum_commission_amount")}
                            onBlur={() => handleBlur("minimum_commission_amount")}
                            onFocus={() => handleFocus("minimum_commission_amount")}
                        />
                    </FormInputWrapper>
                </FormRow>

                {!boolIsYBAA && (
                    <>
                        <h3>
                            Governing Law and Dispute Resolution{" "}
                            <span
                                data-uk-icon="icon: info"
                                data-uk-toggle="target: #modal-helper-term"
                                className="info-icon"
                            ></span>
                        </h3>
                        <HelperTerms />
                        <FormRow>
                            <FormInputWrapper label="Rules of Binding Arbitration *">
                                <div className="radio-toolbar">
                                    <input
                                        type="radio"
                                        {...register("dispute_location")}
                                        value="A"
                                        id='iyac'
                                        checked={ruleOfBinding === "A"}
                                    />
                                    <label htmlFor='iyac' className="uk-form-label radio">A - IYAC</label>
                                    <input
                                        type="radio"
                                        {...register("dispute_location")}
                                        value="B"
                                        id='lmaa'
                                        checked={ruleOfBinding === "B"}
                                    />
                                    <label htmlFor='lmaa' className="uk-form-label radio">B - LMAA</label>
                                </div>
                            </FormInputWrapper>
                        </FormRow>
                        {ruleOfBinding === 'A' && (
                            <>
                                <FormRow>
                                    <FormInputWrapper size="two">
                                        <div className="radio-toolbar">
                                            <input
                                                type="radio"
                                                value="Fort Lauderdale"
                                                id='fort_lauderdale'
                                                {...register('dispute_location_city')}
                                                checked={ruleOfLocationCity === "Fort Lauderdale"}
                                            />
                                            <label htmlFor='fort_lauderdale' className="uk-form-label radio">Fort Lauderdale</label>
                                            <input
                                                type="radio"
                                                value="London"
                                                id='london'
                                                {...register('dispute_location_city')}
                                                checked={ruleOfLocationCity === "London"}
                                            />
                                            <label htmlFor='london' className="uk-form-label radio">London</label>
                                            <input
                                                type="radio"
                                                value="Monaco"
                                                id='monaco'
                                                {...register('dispute_location_city')}
                                                checked={ruleOfLocationCity === "Monaco"}
                                            />
                                            <label htmlFor='monaco' className="uk-form-label radio">Monaco</label>
                                        </div>
                                    </FormInputWrapper>
                                </FormRow>
                                <FormRow>
                                    <FormInputWrapper size="two">
                                        <div className="radio-toolbar">
                                            <input
                                                type="radio"
                                                value="Florida Law"
                                                id='florida_law'
                                                {...register('dispute_location_law')}
                                                checked={ruleOfLocationLaw === "Florida Law"}
                                            />
                                            <label htmlFor='florida_law' className="uk-form-label radio">Florida Law</label>
                                            <input
                                                type="radio"
                                                value="English Law"
                                                id='english_law'
                                                {...register('dispute_location_law')}
                                                checked={ruleOfLocationLaw === "English Law"}
                                            />
                                            <label htmlFor='english_law' className="uk-form-label radio">English Law</label>
                                        </div>
                                    </FormInputWrapper>
                                </FormRow>
                            </>
                        )}
                    </>
                )}
                <FormRow>
                    <FormInputWrapper size="two" label="Vessel Location / Showing Instructions">
                        <textarea className="uk-textarea" rows="3" {...register("showing_instructions")} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <button type="submit" className='uk-button uk-button-primary pull-right'>Save</button>
                    <button type="button" className='uk-button uk-button-orange pull-right' onClick={() => closePanel(false)}>Close</button>
                </FormRow>
            </Form>
        </div>
    )
}

export default PanelFormTerms;
