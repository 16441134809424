import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import clsx from "clsx";
import PageSidebarContainer from "../../../../common/PageSidebarContainer";
import { updateOptionsRequiredFields, updateRequiredFields } from "../../../../../redux/actions/transaction";
import {
  CORPORATE_OWNED,
  ENTITY_AND_OR_ASSIGN,
  LEGAL_AND_OR_ASSIGN,
  LEGAL_OR_ASSIGN,
  LEGAL_OWNER,
  JOINT_OWNER,
  LLC_OWNED,
  RECORD_OWNER,
  TRUST_OWNED,
} from "../../../../../utils/signer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TradeInVesselStep = () => {
  let location = useLocation();
  let { id } = useParams();
  const dispatch = useDispatch();
  const dataTransaction = useSelector((state) => state.transactions.transaction || {});
  const dataTradeIn = dataTransaction.t_trade_in_vessel || {};
  const dataBuyer = dataTransaction.buyer || {};
  const dataCoBuyer = dataTransaction.co_buyer;
  const dataSeller = dataTransaction.seller || {};
  const dataCoSeller = dataTransaction.co_seller;

  const history = useHistory();

  const [asterix, setAsterix] = useState("empty");

  const tradeRequiredFields = {
    hull_number: "Hull No.",
    length: "Length",
    make: "Make",
    model: "Model",
    boat_type: "Boat Type",
    year: "Year",
    // engine_serial_1: "Engine Serial 1",
    accept_reject_date: "Trade Vessel Accept/Reject Date",
    delivery_location: "Trade Vessel Delivery Location",
    allowance: "Allowance",
    exclusion_list: "Trade Vessel Exclusion List",
    doc_no: "Please input at least one: Doc No., Reg No., or Title No.",
    engine_make: "Engine Make",
    engine_model: "Engine Model",
    engine_hp: "Engine HP"
  }

  const buyerFields = {
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    mobile_phone: "Mobile Phone",
  };
  const coBuyerFields = {
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    mobile_phone: "Mobile Phone",
  };

  const sellerFields = {
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    mobile_phone: "Mobile Phone",
  };
  const coSellerFields = {
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    mobile_phone: "Mobile Phone",
  };

  const groupFields = {
    trust_name: "Corporation/LLC/Trust Name",
    trust_tax_id: "Corporation/LLC/Trust Tax ID",
    trust_address: "Corporation/LLC/Trust Address",
    trust_city: "Corporation/LLC/Trust City",
    trust_state: "Corporation/LLC/Trust State",
    trust_signer_name: "Corporation/LLC/Trust Name of Signer",
    trust_signer_email: "Corporation/LLC/Trust Email",
    trust_signer_title: "Corporation/LLC/Trust Title of Signer",
  }

  useEffect(() => {
    let requiredFields = [];
    let buyerRequireds = [];
    let coBuyerRequireds = [];
    let sellerRequireds = [];
    let coSellerRequireds = [];

    if (dataTradeIn) {
      Object.keys(tradeRequiredFields).forEach(key => {
        if (key == "doc_no") {
          if (!dataTradeIn["doc_no"] && !dataTradeIn["reg_no"] && !dataTradeIn["title_no"]) {
            requiredFields.push({ "": "Please input at least one: Doc No., Reg No., or Title No." });
            return
          }
        }
        if (!dataTradeIn[key]) {
          requiredFields.push({ [key]: tradeRequiredFields[key] });
        }
      });

      if (requiredFields.length === 0) {
        setAsterix("fulfill");
      } else if (requiredFields.length === tradeRequiredFields.length) {
        setAsterix("empty");
      } else {
        setAsterix("notFulfill");
      }
    } else {
      Object.keys(tradeRequiredFields).forEach(key => {
        requiredFields.push({ [key]: tradeRequiredFields[key] });
      });
      setAsterix("empty");
    }

    if ([ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.buyer_type) > -1) {
      Object.keys(groupFields).forEach((key) => {
        if (!dataBuyer[key]) {
          buyerRequireds.push({ [key]: groupFields[key] });
        }
      });
    } else if ([LEGAL_OWNER, LEGAL_OR_ASSIGN, LEGAL_AND_OR_ASSIGN].indexOf(dataTransaction?.buyer_type) > -1) {
      Object.keys(buyerFields).forEach((key) => {
        if (!dataBuyer[key]) {
          buyerRequireds.push({ [key]: buyerFields[key] });
        }
      });
    } else if ([JOINT_OWNER].indexOf(dataTransaction?.buyer_type) > -1) {
      Object.keys(buyerFields).forEach((key) => {
        if (!dataBuyer[key]) {
          buyerRequireds.push({ [key]: buyerFields[key] });
        }
      });
    } else {
      buyerRequireds.push({ title: 'Buyer Role' });
    }

    if (dataCoBuyer) {
      Object.keys(coBuyerFields).forEach((key) => {
        if (!dataCoBuyer[key]) {
          coBuyerRequireds.push({ [key]: coBuyerFields[key] });
        }
      });
    }

    if ([TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.seller_type) > -1) {
      Object.keys(groupFields).forEach((key) => {
        if (!dataSeller[key]) {
          sellerRequireds.push({ [key]: groupFields[key] });
        }
      });
    } else if ([LEGAL_OWNER].indexOf(dataTransaction?.seller_type) > -1) {
      Object.keys(sellerFields).forEach((key) => {
        if (!dataSeller[key]) {
          sellerRequireds.push({ [key]: sellerFields[key] });
        }
      });
    } else if ([RECORD_OWNER].indexOf(dataTransaction?.seller_type) > -1) {
      if (!dataTransaction['seller_type']) sellerRequireds.push({ title: 'Seller Role' });
    } else if ([JOINT_OWNER].includes(dataTransaction?.seller_type)) {
      Object.keys(sellerFields).forEach((key) => {
        if (!dataSeller[key]) {
          sellerRequireds.push({ [key]: sellerFields[key] });
        }
      });
    } else {
      sellerRequireds.push({ title: 'Seller Role' });
    }

    if (dataCoSeller) {
      Object.keys(coSellerFields).forEach((key) => {
        if (!dataCoSeller[key]) {
          coSellerRequireds.push({ [key]: coSellerFields[key] });
        }
      });
    }

    dispatch(
      updateRequiredFields({
        buyer: buyerRequireds,
        co_buyer: coBuyerRequireds,
        seller: sellerRequireds,
        co_seller: coSellerRequireds
      })
    );

    dispatch(
      updateOptionsRequiredFields({
        tradeInVessel: requiredFields,
      })
    );
  }, [dataTransaction]);

  let branch = location.pathname.split("/").pop();

  return (
    <PageSidebarContainer type="add-deal">
      <div className="sidebar-container add-deal">
        <ul className="uk-nav uk-nav-default tm-nav">
          <Link to={`/deals/view/${id}/trade-in-vessel`}>
            <li
              className={clsx("add-deal chain-step", {
                current: branch === "trade-in-vessel",
              })}
            >
              <i
                className={clsx(
                  "fa",
                  { "fa-check-circle stepbar": asterix === "fulfill" },
                  { "fa-circle-o": asterix === "empty" || !asterix },
                  { "fa-circle": asterix === "notFulfill" }
                )}
              />
              <span className="add-deal-step">Trade-In Vessel Addendum</span>
            </li>
          </Link>
          {/* <Link to={`/deals/view/${id}/trade-in-vessel/send_to_signer`}>
            <li className={clsx("add-deal chain-step", { current: branch === "send_to_signer" })}>
              <i className="fa fa-circle-o"></i>
              <span className="add-deal-step">Send for eSignature</span>
            </li>
          </Link> */}

          {
            dataTransaction?.id &&
            // <li
            //   name="back-to-eDocuments"
            //   id="tab-back-to-eDocuments"
            //   className={clsx("add-deal chain-step")}
            // >
            //   <Link to={`/deals/view/${dataTransaction.id}/main#tab=%22edocuments%22`}> <span uk-icon="chevron-double-left"></span> Back to eDocuments</Link>
            // </li>
            <button className="uk-button uk-button-primary mt-2" onClick={() => history.push(`/deals/view/${dataTransaction.id}/main#tab=%22edocuments%22`)}>
              <span uk-icon="chevron-double-left"></span>Back to eDocuments
            </button>

          }


        </ul>
      </div>
    </PageSidebarContainer>
  );
};

export default TradeInVesselStep;
