import React from 'react'

import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Strikethrough, Heading, Subscript, Superscript, List, TodoList, Underline, ListProperties ,Markdown} from 'ckeditor5';
import './editor.css'
import 'ckeditor5/ckeditor5.css';
import 'ckeditor5-premium-features/ckeditor5-premium-features.css';
const MarkdownEditor = ({noteProps,setEditData}) => {
    const {note,setNote} =noteProps
    return (
        <CKEditor
            name='description'
            data={note}
            editor={ClassicEditor}
            onChange={(event, editor) => {
                const data = editor.getData();
                setNote(data);
                setEditData(data)
                // setValue('description', data);
            }}
            config={{
                toolbar: {
                    items: [
                        // 'heading',
                        '|', 'undo', 'redo',
                        // '|', 'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript',
                        '|', 'bulletedList', 'numberedList'
                    ],
                },
                plugins: [
                    Markdown, Undo, Bold, Essentials, Italic, Underline, Mention, Paragraph, Heading, Strikethrough, Subscript, Superscript, List
                ],
                placeholder: 'Add Notes'
            }}
        />
    )
}

export default MarkdownEditor