import {
  CREATE_ESIGN_TEMPLATE,
  GET_ESIGN_TEMPLATE,
  RETRIEVE_ESIGN_TEMPLATES,
  UPDATE_ESIGN_TEMPLATE,
  DELETE_ESIGN_TEMPLATE,
  PARSE_ESIGN_TEMPLATE
} from "./types";

import TemplatesService from "../../services/TemplatesService";

export const findTemplate = (id) => async (dispatch) => {
  try {
    const res = await TemplatesService.get(id);

    dispatch({
      type: GET_ESIGN_TEMPLATE,
      payload: res.data
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export const createTemplate = (data) => async (dispatch) => {
  try {
    const res = await TemplatesService.create(data);

    dispatch({
      type: CREATE_ESIGN_TEMPLATE,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export const updateTemplate = (id, data) => async (dispatch) => {
  try {
    const res = await TemplatesService.update(id, data);
    dispatch({
      type: UPDATE_ESIGN_TEMPLATE,
      payload: res.data,
    });
    return Promise.resolve(res);
  } catch (err) {
    console.log("Error = ", err);
    return Promise.reject(err);
  }
}

export const deleteTemplate = (id) => async (dispatch) => {
  try {
    const res = await TemplatesService.remove(id);
    dispatch({
      type: DELETE_ESIGN_TEMPLATE,
      payload: res.data
    });
    return Promise.resolve(res);
  } catch (err) {
    console.log("Error = ", err);
    return Promise.reject(err);
  }
}

export const findTemplateByName = (name) => async (dispatch) => {
  try {
    const res = await TemplatesService.findByName(name);

    dispatch({
      type: RETRIEVE_ESIGN_TEMPLATES,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export const parseTemplate = (file_name) => async (dispatch) => {
  try {
    const res = await TemplatesService.parseTemplate(file_name);

    dispatch({
      type: PARSE_ESIGN_TEMPLATE,
      payload: { key: 'parsed', data: res.data }
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
}