import FormRow from "../../../../common/FormRow";

const DetailOfAddendum = ({ addendum }) => {
  const ammendment = addendum ? addendum.ammendment : "";
  let data = ammendment.split("/:/");
  data = data.filter((d) => d);

  return (
    <FormRow>
      {data.map((d, index) => (
        <p className="mt20" key={`k${index}`}>
          <span className="uk-form-label">{`Item ${index + 1}:`}</span>
          <span className="input-view">{`${d}`}</span>
        </p>
      ))}
    </FormRow>
  );
};

export default DetailOfAddendum;
