import http from '../http-common';

const getLoginLogoutLogs = (page,limit,searchParams) => {
    return http.get(`activity-logs/login-out-logs/${page}/${limit}?${searchParams}`);
  }
  const getAllLogs = (page,limit,searchParams) => {
    return http.get(`activity-logs/${page}/${limit}?${searchParams}`);
  }
  const getAllFilters = () => {
    return http.get(`activity-logs/all-modules-activity-types`);
  }
const UserActivityService = {
    getLoginLogoutLogs,
    getAllLogs,
    getAllFilters
  }
  
  export default UserActivityService;
  