import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createDocumentTemplate, updateDocumentTemplate } from '../../../../redux/actions/document_tasks';

export const ReviewStep = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [title, setTitle] = useState(props.form.template_title);

  useEffect(() => {
    props.update('template_title', title);
  }, [title]);

  const handleChangeTitle = (evt) => {
    setTitle(evt.target.value);
  }

  const handleSave = () => {
    if (props.form.template_id) {
      dispatch(updateDocumentTemplate(props.form.template_id, props.form)).then(res => {
        history.push('/document-templates');
      }).catch(err => {
        console.log(err);
      })
    } else {
      dispatch(createDocumentTemplate(props.form)).then(res => {
        history.push('/document-templates');
      }).catch(err => {
        console.log(err);
      });
    }
  }

  return (
    <div className="container">
      <div className="col-12 container-box">
        <div className="col-10">
          <h4 style={{ fontWeight: '400', fontSize: '24px', marginTop: '2rem' }}>Does everything look right?</h4>
          {/* <h5 style={{ marginTop: '2rem' }}>Signer roles</h5>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {roles && roles.map((role, index) => <span key={index} style={{ margin: '5px 0' }}>{index + 1}  {role}</span>)}
          </div> */}
          <p style={{ marginTop: '24px', fontWeight: '500' }}>Does anyone else need to receive a copy?</p>
          <button type="button" style={{ border: '1px dashed #959ca4', color: '#727a83', display: 'flex', alignItems: 'center', padding: '1px 15px 1px 15px', width: '100%' }}>
            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" style={{ marginRight: '5px' }}>
              <path fillRule="evenodd" clipRule="evenodd" d="M13 3.75C13 3.33579 12.5523 3 12 3C11.4477 3 11 3.33579 11 3.75V11H3.75C3.33579 11 3 11.4477 3 12C3 12.5523 3.33579 13 3.75 13H11V20.25C11 20.6642 11.4477 21 12 21C12.5523 21 13 20.6642 13 20.25V13H20.25C20.6642 13 21 12.5523 21 12C21 11.4477 20.6642 11 20.25 11H13V3.75Z" fill="currentColor"></path>
            </svg>
            Add another role
          </button>
          <h5 style={{ marginTop: '4rem' }}>Add a title and default message for signers</h5>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>Tempalte title</span>
            <input
              type="text"
              style={{
                borderWidth: '1px',
                width: '100%',
                outline: 'none',
                padding: '5px 10px 5px 10px',
                border: '1px solid #adb5bd'
              }}
              defaultValue={props.form.template_title}
              onChange={handleChangeTitle}
            />

            <span style={{ marginTop: '2rem' }}>Message (optional)</span>
            <textarea type="text" style={{ borderWidth: '1px', width: '100%', outline: 'none', padding: '5px 10px 5px 10px', border: '1px solid #adb5bd' }} />
          </div>
          <hr />
          <div style={{ textAlign: 'right' }}>
            {/* <button className='btn btn-previous' onClick={handlePrevious}>Back</button> */}
            <button className='btn btn-continue' onClick={handleSave}>Save Template</button>
          </div>
        </div>
      </div>
    </div>
  );
};