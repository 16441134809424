import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { clearTransaction, updateRequiredFields } from "../../../redux/actions/transaction";
import ListingMainView from "./MainView";
import AddendumOption from "./options/OptionAddendum";
import ExclusionOption from "./options/OptionExclusion";
import MaterialDamageOption from "./options/OptionMaterialDamageForm";
import OptionYBAAListingAgreement from "./options/OptionYBAAListingAgreement";
import YBAAListingAgreement from "./options/OptionYBAAListingAgreement";
import EditPreviewDocument from "../../preview-edit";
import { CORPORATE_OWNED, JOINT_OWNER, LLC_OWNED, SOLE_OWNER, TRUST_OWNED } from "../../../utils/signer";
import EditCustomEditor from "../../preview-custom-edit";

const ViewPage = () => {
  let { path } = useRouteMatch();
  let { id } = useParams();
  const dispatch = useDispatch();
  const dataTransaction = useSelector((state) => state.transactions.transaction);

  const dataListing = dataTransaction || {};

  useEffect(() => {
    // return () => {
    //   dispatch(clearTransaction());
    // };
  }, []);


  useEffect(() => {
    const dataVessel = dataListing.t_vessel || {};
    const dataSeller = dataListing.seller || {};
    const dataTerms = dataListing.t_terms || {};
    const dataCoSeller = dataListing.co_seller || {};
    const dataTrailer = dataListing.t_trailer || []

    const vesselFields = {
      hull_number: "Hull No.",
      length: "Length",
      make: "Make",
      // model: "Model",
      // boat_type: "Boat Type",
      year: "Year",
    };

    const sellerFields = {
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      mobile_phone: "Mobile Phone",
    };

    const sellerGroupFields = {
      trust_name: "Corporation/LLC/Trust Name",
      // trust_tax_id: "Corporation/LLC/Trust Tax ID",
      trust_address: "Corporation/LLC/Trust Address",
      trust_city: "Corporation/LLC/Trust City",
      trust_state: "Corporation/LLC/Trust State",
      trust_signer_name: "Corporation/LLC/Trust Name of Signer",
      trust_signer_email: "Corporation/LLC/Trust Email",
      trust_signer_title: "Corporation/LLC/Trust Title of Signer",
    }

    const termsFields = {
      dispute_location: "Dispute Location",
      dispute_location_city: "Dispute Location City",
      asking_price: "Asking Price",
      contract_date: "Contract Date",
    };

    const coSellerFields = {
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      mobile_phone: "Mobile Phone",
    };

    const trailerFields = {
      make: "Make",
      year: "Year",
      vin: "VIN"
    }

    let vesselRequireds = [];
    let sellerRequireds = [];
    let termsRequireds = [];
    let coSellerRequireds = [];
    let trailerRequireds = [];

    Object.keys(vesselFields).forEach((key) => {
      if (!dataVessel[key]) {
        vesselRequireds.push({ [key]: vesselFields[key] });
      }
    });
    // if (!(dataVessel['doc_no'] || dataVessel['reg_no'] || dataVessel['title_no'])) {
    //   vesselRequireds.push({ 'doc_reg_title_no': 'Doc No, Reg No, Title No' });
    // }
    if (dataVessel.is_old_built == true) {
      vesselRequireds.splice(vesselRequireds.findIndex(field => field['hull_number']), 1);
    }
    if (dataVessel.hull_number?.length < 12 && dataVessel.is_old_built === false) {
      vesselRequireds.push({ 'hull_number': 'Invalid Hull Number' });
    }

    if ([TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataListing?.seller_type) > -1) {
      Object.keys(sellerGroupFields).forEach((key) => {
        if (!dataSeller[key]) {
          sellerRequireds.push({ [key]: sellerGroupFields[key] });
        }
      });
    } else if ([SOLE_OWNER, JOINT_OWNER].indexOf(dataListing?.seller_type) > -1) {
      Object.keys(sellerFields).forEach((key) => {
        if (!dataSeller[key]) {
          sellerRequireds.push({ [key]: sellerFields[key] });
        }
      });
    } else {
      sellerRequireds.push({ title: 'Owner Role' });
    }

    Object.keys(termsFields).forEach((key) => {
      if (!dataTerms[key]) {
        if (!((key === 'dispute_location' || key === 'dispute_location_city') && dataListing.association === 'YBAA')) {
          termsRequireds.push({ [key]: termsFields[key] });
        }
      }
    });
    Object.keys(coSellerFields).forEach((key) => {
      if (!dataCoSeller[key]) {
        coSellerRequireds.push({ [key]: coSellerFields[key] });
      }
    });

    Object.keys(trailerFields).forEach((key) => {
      if (dataTrailer.length == 0) {
        trailerRequireds.push({ [key]: trailerFields[key] });
      }
      dataTrailer.forEach(element => {
        if (!element[key]) {
          trailerRequireds.push({ [key]: trailerFields[key] });
        }
      });
    })
    dispatch(
      updateRequiredFields({
        vessel: vesselRequireds,
        seller: sellerRequireds,
        terms: termsRequireds,
        co_seller: coSellerRequireds,
        trailer: trailerRequireds
      })
    );
  }, [dataListing]);

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${path}/main`} />
      </Route>
      <Route path={`${path}/main`} component={ListingMainView} />
      <Route path={`${path}/listing-agreement-addendum`} component={AddendumOption} />
      <Route path={`${path}/listing-agreement-exclusion`} component={ExclusionOption} />
      <Route path={`${path}/material-damage-disclosure`} component={MaterialDamageOption} />
      <Route path={`${path}/ybaa-us-central-listing-agreement`} component={OptionYBAAListingAgreement} />
      <Route path={`${path}/preview/edit`} component={EditPreviewDocument} />
    </Switch>
  );
};

export default ViewPage;
