import { React, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { makeStyles } from '@mui/styles';
import {
  Button, CssBaseline, TextField, Box,
  Typography, Container, Alert, createTheme
} from '@mui/material'

import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { verifyEmailVerificationCode } from "../../../redux/actions/users";

import Auth from "../../../utils/auth";
import { setLoadingStatus } from "../../../redux/actions/loading";
import UserService from "../../../services/UserService";

const schema = yup.object().shape({
  code: yup
    .string()
    .required("Please enter your code")
});

const theme = createTheme();
const useStyles = makeStyles({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#0063A6 !important'
  },

  typo: {
    color: '#0063A6 !important'
  }
});

const VerifyForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [error, setError] = useState('');
  const [csrfToken, setCsrfToken] = useState(null);

  useEffect(() => {
    UserService.trackUserToken('verification')
      .then(response => {
        setCsrfToken(response.data.xt);
      }).catch(error => {
        console.log(error);
        setCsrfToken(null);
      });
  }, []);

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: {phone_number: ''}
  });

  const handleVerify = (data) => {
    dispatch(setLoadingStatus(true));
    const config = {
      headers: {
        xt: csrfToken
      }
    };
    dispatch(verifyEmailVerificationCode(Auth.getInstance().getAuthToken(), data['code'], config)).then(res => {
      history.goBack();
      dispatch(setLoadingStatus(false));
    }).catch(err => {
      dispatch(setLoadingStatus(false));
      setError('Your Code is incorrect. Please try again.')
    });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Box sx={{ width: 200 }} mb={4}>
          <img src="/images/boatdox-logo-blue.png" alt="" />
        </Box>
        <Typography component="h1" variant="h5">
          Verify YOUR Email Address
        </Typography>
        {error && (
          <Alert variant="outlined" severity="error" className={classes.alert}>
            {error}
          </Alert>
        )}
        <form className={classes.form} onSubmit={handleSubmit(handleVerify)}>
          <Typography component="h1" variant="body2" align="center" gutterBottom>
            We've just sent a verification code to your email address. Please check your inbox and input the code here.
          </Typography>
          <TextField variant="outlined" margin="normal" required fullWidth id="code" label="Code" autoFocus {...register("code")} />
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>Verify Code</Button>
        </form>
      </div>
    </Container>
  );
}

export default VerifyForm;