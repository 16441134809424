import { useEffect, useCallback } from "react";
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from "react-redux";

import { getDealsByOffice } from "../../../redux/actions/dashboard";
import { getPrice } from "../../../utils/currency";
import { setOfficeFilter } from '../../../redux/actions/report';
import Auth from '../../../utils/auth';

const DealsByOffice = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const stats = useSelector((state) => state.dashboard.dealsByOffice);
  const dataBroker = useSelector((state) => state.transactions.broker);
  const loggedUser = Auth.getInstance().getUserData();

  useEffect(() => {
    dispatch(getDealsByOffice());
  }, []);

  const handleClickViewAll = useCallback(() => {
    dispatch(setOfficeFilter(loggedUser.company_office_id));
    history.push('/reports/deals');
  }, []);

  return (
    <>
      <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
        <thead>
          <tr>
            <th>Office</th>
            <th>Deals</th>
            <th>Value</th>
            <th>Commission</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(stats)
            .slice(0, 3)
            .map((key) => {
              return (
                <tr key={key}>
                  <td>{stats[key].office_name}</td>
                  <td>{stats[key].count}</td>
                  <td>{getPrice(stats[key].value)}</td>
                  <td>{getPrice(stats[key].commission)}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {Object.keys(stats).length !== 0 && (
        <div className="view-all">
          <a href="#" onClick={handleClickViewAll}>View All</a>
        </div>
      )}
    </>
  );
};

export default DealsByOffice;
