import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactDragListView from "react-drag-listview";
import { confirmAlert } from "react-confirm-alert";
import { Table } from "antd";

import { DELETE_MODAL_MESSAGE, DELETE_MODAL_TITLE } from "../../../../../constants/Alert";
import { getTransactionEdocById, removeTaskFromTransaction, reOrderTasks } from "../../../../../redux/actions/transaction";
import Auth from "../../../../../utils/auth";
import { useParams } from "react-router-dom";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import TransactionService from "../../../../../services/TransactionService";

const UploadEditTable = ({ show, companyId, setDataForUpdateUploadListOrder }) => {

  const [tblData, setTblData] = useState([]);
  const dispatch = useDispatch();
  const logged_user = Auth.getInstance().getUserData();
  const { id } = useParams();

  useEffect(() => {
    if (show) {
      getCompanyTagList();
    }
  }, [show]);

  const getCompanyTagList = async () => {
    dispatch(setLoadingStatus(true));
    const dataTasks = (await TransactionService.getEdocCompanyTaskById(companyId, id)).data;
    const dataDocument = await dataTasks.filter((task) => {
      return task.task_type === "document";
    });
    setTblData(dataDocument);
    dispatch(setLoadingStatus(false));
  }

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const tbl = [...tblData];
      const item = tbl.splice(fromIndex, 1)[0];
      tbl.splice(toIndex, 0, item);
      tbl.forEach((t, index) => {
        t.sort_order = index;
      });
      const data = tbl.filter((t) => (t.master_task_id ? true : false));
      setTblData(data);

      // store updated data of sortOrder in state for every drag change 
      setDataForUpdateUploadListOrder(data);
    },
    handleSelector: "img",
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const columns = [
    {
      title: "",
      width: 80,
      key: "operate",
      render: (task) => <img src="/icons/move.svg" className="move-svg drag-handle" />,
    },
    {
      title: "Order",
      width: 100,
      render: (row) => (
        <div>
          {
            row?.sort_order + 1 || " "
          }
        </div>
      ),
    },
    {
      title: "Documents",
      key: "task",
      render: (task) => (
        <div>
          {task.company_tasks?.title || ""}
          <br />
          <span className="uk-text-small uk-text-muted">{task.completed_at || ""}</span>
        </div>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (task) => (
        <span>{capitalizeFirstLetter(task.status) || ""}</span>
      ),
    },
    // ** Commenting this feature as per client requirement */
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (task) => {
    //     return (
    //       task.master_task_id && (
    //         <a onClick={() => onDelete(task.master_task_id)}>
    //           <img src="/icons/delete.svg" className="svg" />
    //         </a>
    //       )
    //     );
    //   },
    // },
  ];

  // ** Commenting this feature as per client requirement */
  // const onDelete = (id) => {
  //   confirmAlert({
  //     title: DELETE_MODAL_TITLE,
  //     message: DELETE_MODAL_MESSAGE,
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: () => {
  //           dispatch(removeTaskFromTransaction(id, logged_user.id))
  //             .then((res) => {
  //               dispatch(getTransactionEdocById(urlParams.id));
  //             })
  //             .catch((err) => {
  //               dispatch(getTransactionEdocById(urlParams.id));
  //             })

  //         },
  //       },
  //       {
  //         label: "No",
  //         onClick: () => { },
  //       },
  //     ],
  //   });
  // };

  if (!show) {
    return <></>;
  }
  return (
    <div className="drag_list_view">
      <ReactDragListView {...dragProps}>
        <Table columns={columns} dataSource={tblData} pagination={false} />
      </ReactDragListView>
    </div>
  );
};

export default UploadEditTable;
