import React from 'react';

export const PreviewDocument = (props) => {

return (
    <embed  
       src={window.location}
       width="250"
       height="200">
    </embed>
  );
};