import ModalBody from "../../../../common/ModalBody";
import OffCanvasContainer from "../../../../common/OffCanvasContainer";
import DetailOfCoSeller from "../details/DetailOfCoSeller";
import DetailOfSeller from "../details/DetailOfSeller";

const ViewSellerForm = ({ seller, coseller, type }) => {
  return (
    <OffCanvasContainer id="modal-seller">
      <ModalBody title="Seller">
        <DetailOfSeller seller={seller} type={type} />
        <br />
        <h3>Co-Seller</h3>
        <DetailOfCoSeller coseller={coseller} />
      </ModalBody>
    </OffCanvasContainer>
  );
};

export default ViewSellerForm;
