import { toast } from "react-toastify";
import { pdfjs } from 'react-pdf';

/**
 * if object is empty then it will return true otherwise false.
 * @param {Object} obj 
 * @returns return true if object is empty.
 */
export const isEmptyObj = (obj) => {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false
        }
    }
    return true
}

/**
 * @param {String} string 
 * @returns String in lowercase and replace "-" into "_"
 */
export const convertFirstCharIntoSmallCase = (string) => {
    const arr = string.split("-");
    let a = []
    for (let name of arr) {
        a.push(name.charAt(0).toLowerCase() + name.slice(1))
    }
    return a.join("_")
}

/**
 * Its is used to find the status of specific role is Signed or not
 * @param {Array} signers 
 * @param {String} role 
 * @returns True or False
 */
export const isSigned = (signers, role) => {
    // if role is selling or listing employing then no need for capitalization
    const signerRole = ["selling-employing-broker", "listing-employing-broker"].includes(role) ? role : capitalizeFirstLetter(role);
    return signers?.find(signer => signer.request_signer.role === signerRole)?.status == "Signed"
}

/**
 * This function is used to conver small letters roles into 
 * Capitalize latters with "-" separator
 * @param {String} Role 
 * @returns Capitalize latters with "-" separator
 */
export const capitalizeFirstLetter = (string, separator = "_") => {
    const arr = string.split(separator);
    let a = []
    for (let name of arr) {
        a.push(name.charAt(0).toUpperCase() + name.slice(1))
    }
    return a.join("-")
}

/**
 * This function is used to conver small letters roles into 
 * Capitalize latters with "-" separator
 * @param {String} Role 
 * @returns Capitalize latters with "-" separator
 */
export const convertUnderscoreSeparateLetter = (string, separator = "_") => {
    const arr = string.split(separator);
    let a = []
    for (let name of arr) {
        a.push(name.charAt(0).toUpperCase() + name.slice(1))
    }
    return a.join(' ');
}

/**
 * 
 * @param {*} cookies 
 * @returns 
 */
// export const manageAppVersionWithRefresh = () => {
//     const updatedAppVersion = process.env.REACT_APP_VERSION;
//     if (localStorage.getItem('boatdox-app-version') != updatedAppVersion) {
//         localStorage.removeItem('boatdox-app-version');
//         localStorage.setItem('boatdox-app-version', updatedAppVersion);
//         setTimeout(() => {
//             window.location.reload(true);
//         }, 500);
//         return true
//     }
//     return false;
// }


/**
 * @param {}
 * @return toast 
 * 
 */

export const unauthorizedErrorToast = (error) => {
    return toast.error(`You are not authorized to perform this action.`);
};

export const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB',]

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const getFileExtension = (originalFile) => {

    let ext = originalFile.length > 1 ? originalFile[originalFile.length - 1] : undefined;
    if (ext.toLowerCase() === 'txt') ext = 'plain';
    if (ext.toLowerCase() === 'jpg') ext = 'jpeg';
    if (ext.toLowerCase() === 'xlsx') ext = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (ext.toLowerCase() === 'xls' || ext.toLowerCase() === 'csv') ext = 'vnd.ms-excel';
    if (ext.toLowerCase() === 'docx') ext = 'vnd.openxmlformats-officedocument.wordprocessingml.document';
    if (ext.toLowerCase() === 'doc') ext = 'msword';
    if (ext.toLowerCase() === 'png') ext = 'png';
    if (ext.toLowerCase() === 'gif') ext = 'gif';
    return ext;
}

/**
 * This function is used to get total page number count from pdf url
 * @param {String} PdfUrl 
 * @returns Total page count of pdf
 */
export const getPdfPageCountFromUrl = async (url) => {
    try {
        // Load the PDF document from the URL
        const pdf = await pdfjs.getDocument(url).promise;

        // Return the number of pages in the PDF
        return pdf.numPages;
    } catch (err) {
        console.error("Error loading PDF:", err);
        return null;
    }
}

/**
 * Function that returns the masked value of a string with provided character
 * @param {*} length 
 * @param {*} maskCharacter 
 * @returns String 
 */
export const renderMaskedValue = (length, maskCharacter = '*') => {
    return maskCharacter.repeat(length);
}