import { GET_TUTORIALS } from "../actions/types";

const initialState = [];

function tutorialsState(states = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TUTORIALS:
      return payload;
    default:
      return states;
  }
}

export default tutorialsState;
