import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import clsx from "clsx";
import PageSidebarContainer from "../../../../common/PageSidebarContainer";
import { updateOptionsRequiredFields } from "../../../../../redux/actions/transaction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SellerDetailStep = () => {
  let location = useLocation();
  let { id } = useParams();
  const dispatch = useDispatch();
  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const [asterix, setAsterix] = useState({
    payoff: null,
    detail: null,
  });

  const history = useHistory();

  const dataDeal = dataTransaction || {};
  const dataSellerClosing = dataDeal.t_seller_closing;
  const dataSellerPayoff = dataDeal.t_seller_payoff;
  const dataDetails = dataDeal.t_details || [];

  useEffect(() => {
    if (dataSellerClosing) {
      const { gross_sales_commission } = dataSellerClosing;
      if (gross_sales_commission) {
        setAsterix((prev) => ({ ...prev, detail: "fulfill" }));
      } else {
        setAsterix((prev) => ({ ...prev, detail: "empty" }));
      }
    } else {
      setAsterix((prev) => ({ ...prev, detail: "empty" }));
    }
    if (dataSellerPayoff) {
      setAsterix((prev) => ({ ...prev, payoff: "fulfill" }));
    } else {
      setAsterix((prev) => ({ ...prev, payoff: "empty" }));
    }
    if (
      dataDetails.filter((d) => d.type === "expenses" && d.document_type === "seller").length > 0
    ) {
      setAsterix((prev) => ({ ...prev, expense: "fulfill" }));
    } else {
      setAsterix((prev) => ({ ...prev, expense: "empty" }));
    }
    if (
      dataDetails.filter((d) => d.type === "credits" && d.document_type === "seller").length > 0
    ) {
      setAsterix((prev) => ({ ...prev, credit: "fulfill" }));
    } else {
      setAsterix((prev) => ({ ...prev, credit: "empty" }));
    }
    if (
      dataDetails.filter((d) => d.type === "deposits" && d.document_type === "seller").length > 0
    ) {
      setAsterix((prev) => ({ ...prev, deposit: "fulfill" }));
    } else {
      setAsterix((prev) => ({ ...prev, deposit: "empty" }));
    }
    if (
      dataDetails.filter((d) => d.type === "disbursements" && d.document_type === "seller").length >
      0
    ) {
      setAsterix((prev) => ({ ...prev, disbursement: "fulfill" }));
    } else {
      setAsterix((prev) => ({ ...prev, disbursement: "empty" }));
    }
    if (dataDetails.filter((d) => d.type === "notes" && d.document_type === "seller").length > 0) {
      setAsterix((prev) => ({ ...prev, note: "fulfill" }));
    } else {
      setAsterix((prev) => ({ ...prev, note: "empty" }));
    }
    registerRequiredFields(dataSellerClosing);
  }, [dataTransaction]);

  const registerRequiredFields = (detail) => {
    let fields = [];

    if (detail) {
      const { gross_sales_commission } = detail;
      if (!gross_sales_commission) {
        fields.push({ gross_sales_commission: "Gross Sales Commission" });
      }
    } else {
      fields = [{ gross_sales_commission: "Gross Sales Commission" }];
    }

    dispatch(
      updateOptionsRequiredFields({
        sellerClosing: fields,
      })
    );
  };

  let branch = location.pathname.split("/").pop();

  return (
    <PageSidebarContainer type="add-deal">
      <div className="sidebar-container add-deal">
        <ul className="uk-nav uk-nav-default tm-nav">
          <Link to={`/deals/view/${id}/seller-closing-statement`}>
            <li
              className={clsx("add-deal chain-step", {
                current: branch === "seller-closing-statement",
              })}
            >
              <i
                className={clsx(
                  "fa",
                  { "fa-check-circle stepbar": asterix.payoff === "fulfill" },
                  { "fa-circle-o": asterix.payoff === "empty" || !asterix.payoff },
                  { "fa-circle": asterix.payoff === "notFulfill" }
                )}
              ></i>
              <span className="add-deal-step">Payoff Information</span>
            </li>
          </Link>
          <Link to={`/deals/view/${id}/seller-closing-statement/expenses`}>
            <li
              className={clsx("add-deal chain-step", {
                current: branch === "expenses",
              })}
            >
              <i
                className={clsx(
                  "fa",
                  { "fa-check-circle stepbar": asterix.expense === "fulfill" },
                  { "fa-circle-o": asterix.expense === "empty" || !asterix.expense },
                  { "fa-circle": asterix.expense === "notFulfill" }
                )}
              ></i>
              <span className="add-deal-step">Expenses</span>
            </li>
          </Link>
          <Link to={`/deals/view/${id}/seller-closing-statement/credits`}>
            <li
              className={clsx("add-deal chain-step", {
                current: branch === "credits",
              })}
            >
              <i
                className={clsx(
                  "fa",
                  { "fa-check-circle stepbar": asterix.credit === "fulfill" },
                  { "fa-circle-o": asterix.credit === "empty" || !asterix.credit },
                  { "fa-circle": asterix.credit === "notFulfill" }
                )}
              ></i>
              <span className="add-deal-step">Credits</span>
            </li>
          </Link>
          <Link to={`/deals/view/${id}/seller-closing-statement/deposits`}>
            <li
              className={clsx("add-deal chain-step", {
                current: branch === "deposits",
              })}
            >
              <i
                className={clsx(
                  "fa",
                  { "fa-check-circle stepbar": asterix.deposit === "fulfill" },
                  { "fa-circle-o": asterix.deposit === "empty" || !asterix.deposit },
                  { "fa-circle": asterix.deposit === "notFulfill" }
                )}
              ></i>
              <span className="add-deal-step">Deposits</span>
            </li>
          </Link>
          <Link to={`/deals/view/${id}/seller-closing-statement/disbursements`}>
            <li
              className={clsx("add-deal chain-step", {
                current: branch === "disbursements",
              })}
            >
              <i
                className={clsx(
                  "fa",
                  { "fa-check-circle stepbar": asterix.disbursement === "fulfill" },
                  { "fa-circle-o": asterix.disbursement === "empty" || !asterix.disbursement },
                  { "fa-circle": asterix.disbursement === "notFulfill" }
                )}
              ></i>
              <span className="add-deal-step">Disbursements</span>
            </li>
          </Link>
          <Link to={`/deals/view/${id}/seller-closing-statement/notes`}>
            <li
              className={clsx("add-deal chain-step", {
                current: branch === "notes",
              })}
            >
              <i
                className={clsx(
                  "fa",
                  { "fa-check-circle stepbar": asterix.note === "fulfill" },
                  { "fa-circle-o": asterix.note === "empty" || !asterix.note },
                  { "fa-circle": asterix.note === "notFulfill" }
                )}
              ></i>
              <span className="add-deal-step">Notes</span>
            </li>
          </Link>
          <Link to={`/deals/view/${id}/seller-closing-statement/detail`}>
            <li
              className={clsx("add-deal chain-step", {
                current: branch === "detail",
              })}
            >
              <i
                className={clsx(
                  "fa",
                  { "fa-check-circle stepbar": asterix.detail === "fulfill" },
                  { "fa-circle-o": asterix.detail === "empty" || !asterix.detail },
                  { "fa-circle": asterix.detail === "notFulfill" }
                )}
              ></i>
              <span className="add-deal-step">Transaction Details</span>
            </li>
          </Link>
          {/* <Link to={`/deals/view/${id}/seller-closing-statement/send_to_signer`}>
            <li className={clsx("add-deal chain-step", { current: branch === "send_to_signer" })}>
              <i className="fa fa-circle-o"></i>
              <span className="add-deal-step">Send for eSignature</span>
            </li>
          </Link> */}

          {
            dataTransaction?.id &&
            // <li
            //   name="back-to-eDocuments"
            //   id="tab-back-to-eDocuments"
            //   className={clsx("add-deal chain-step")}
            // >
            //   <Link to={`/deals/view/${dataTransaction.id}/main#tab=%22edocuments%22`}> <span uk-icon="chevron-double-left"></span> Back to eDocuments</Link>
            // </li>
            <button className="uk-button uk-button-primary mt-2" onClick={() => history.push(`/deals/view/${dataTransaction.id}/main#tab=%22edocuments%22`)}>
              <span uk-icon="chevron-double-left"></span>Back to eDocuments
            </button>
          }

        </ul>
      </div>
    </PageSidebarContainer>
  );
};

export default SellerDetailStep;
