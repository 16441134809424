import { Switch, Route, Redirect, useRouteMatch, useParams } from "react-router-dom";
import EsignBillOfSaleNotary from "./steps/EsignBillOfSaleNotary";
import BillNotaryStep from "./stepbars/BillNotaryStep";

const BillOfSaleNotaryOption = () => {
  let { path } = useRouteMatch();
  let { id } = useParams();

  return (
    <div>
      <BillNotaryStep />
      <Switch>
        <Route path={`${path}/send_to_signer`} render={() => <EsignBillOfSaleNotary transactionId={id} />} />
        <Redirect to={path} />
      </Switch>
    </div>
  );
};

export default BillOfSaleNotaryOption;
