import { yupResolver } from "@hookform/resolvers/yup";
import { makeStyles } from '@mui/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';

import { toast } from 'react-toastify';
import * as yup from "yup";
import { setLoadingStatus } from '../../redux/actions/loading';
import { getParticipants } from '../../redux/actions/transaction';
import TransactionService from '../../services/TransactionService';
import Auth from '../../utils/auth';
import AutoCompleteSearch from './Autocomplete';

const useStyles = makeStyles({
    phone_input: {
        padding: '12px 12px 12px 50px !important',
        width: '100% !important'
    },
    broker_dropdown: {
        marginTop: "10px",
    },
    broker_dropdown_label: {
        marginTop: "23px",
        marginRight: "-20px",
        marginLeft: "-14px"
    }
});

const SelectParticipantModal = ({ show, onClose, transactionId = null }) => {

    const dispatch = useDispatch();
    const user = Auth.getInstance().getUserData();
    const companyId = user.company_id;


    //This variable is used to store a users roles.
    const [selectedUser, setSelectedUser] = useState(null);
    const [dataUsers, setDataUsers] = useState([])
    const schema = yup.object().shape({
        title: yup.string(),
        first_name: yup.string(),
        last_name: yup.string(),
    });

    const { register, handleSubmit, reset, setValue, getValues, formState: { errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    useEffect(() => {
        if (show) {
            dispatch(setLoadingStatus(true));
            dispatch(getParticipants(companyId)).then((res) => {

                dispatch(setLoadingStatus(false));
                setDataUsers(res)
            }).catch((err) => {
                dispatch(setLoadingStatus(false));
            })
        }

    }, [show]);

    const handleError = (errors) => {
        console.log("Errors = ", errors);
    };

    const handleSelect = (value, option) => {
        if (option?.id) {
            setValue('user_participant_id', option.id)
            setSelectedUser(dataUsers.find(user => user.id === option.id));
        } else {
            setSelectedUser(null)
        }
    };
    /**
     * on submit participant form data for Add/Update participant 
     * @param {*} participant value of participant form data
     */
    const handleRegistration = (participant) => {
        participant = { ...participant, company_id: companyId, transaction_id: transactionId, user_type: "participant" }


        dispatch(setLoadingStatus(true));
        if (!participant.user_participant_id) {
            toast.info(`Please select a participant .`);
            dispatch(setLoadingStatus(false));
            return;
        }
        const participantSubmissionAPI = TransactionService.selectParticipant(participant)
        participantSubmissionAPI.then((response) => {
            dispatch(setLoadingStatus(false));
            toast.success(`Participant Selected successfully.`);
            reset();
            onClose(true);
        }).catch((error) => {
            dispatch(setLoadingStatus(false));
            console.log(error.message);
            toast.error('Something went wrong while submit data!');
        });
    }
    const optionsForAutocomplete = useMemo(() => dataUsers
        .map((participant) => {
            let name = participant?.user?.first_name + " " + participant?.user?.last_name
            return { id: participant.id, value: name };
        })

        .sort((prev, next) => prev.value.localeCompare(next.value))
        , [dataUsers]
    );

    return (
        <Modal
            show={show}
            onHide={onClose}
            centered={true}
            size='md'>
            <Modal.Header closeButton>
                <h3>Select Participant</h3>
            </Modal.Header>
            <div className="uk-margin-small pl-2">
                <form onSubmit={handleSubmit(handleRegistration, handleError)}>
                    <Modal.Body>
                        <div className="panel-form">
                            <div className="form-row">
                                <div className="half-column uk-padding-small">
                                    <label className="uk-form-label">Select Participant</label>
                                    <AutoCompleteSearch options={optionsForAutocomplete} onSelect={handleSelect} placeholder="Search Your Client List" isClear={true} />
                                </div>

                            </div>
                            {selectedUser && <div className="form-row">
                                <div className="">
                                    <h4><img height='25px' width='25px' src="/icons/user.svg" alt="" />&nbsp;{selectedUser?.user?.first_name + " " + selectedUser?.user?.last_name}</h4>
                                    <p className='ml-1 d-flex align-item-center'>
                                        <span style={{ width: '21px' }}  > <img height='20px' width='20px' src="/icons/email.svg" alt="" /></span>&nbsp;&nbsp;{selectedUser?.user?.email}&nbsp;&nbsp;</p>
                                    <p className='ml-1 d-flex align-item-center'>
                                        <span style={{ width: '21px' }}  ><img height='15px' width='15px' src="/icons/phone.svg" alt="" /></span>&nbsp;&nbsp;{selectedUser?.user?.mobile_phone}</p>
                                </div>
                                <div className="px-1">
                                    <div className="uk-grid-small form" data-uk-grid>
                                        <div>
                                            <label className="uk-form-label" htmlFor="name">Title</label>
                                            <div className="uk-text uk-text-emphasis">{selectedUser?.user?.title}</div>
                                        </div>
                                        <div>
                                            <label className="uk-form-label" htmlFor="name">Company name</label>
                                            <div className="uk-text uk-text-emphasis">{selectedUser?.user?.participant_company_name}</div>
                                        </div>


                                    </div>
                                </div>
                            </div>}

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="uk-button uk-button-orange uk-modal-close small" type="button" onClick={(e) => onClose(false)}>
                            Cancel
                        </button>
                        <button className="uk-button uk-button-primary small" type="submit">Save</button>
                    </Modal.Footer>
                </form>
            </div>
        </Modal>
    )
}

export default SelectParticipantModal;