export const getFormattedData = (dataObj) => {
  Object.keys(dataObj).forEach((key) => {
    if (!dataObj[key] && dataObj[key] !== 0) {
      dataObj[key] = null;
    }
  });

  return dataObj;
};

export const getPlainTransactionData = (data) => {
  return {
    id: data.id || null,
    asking_price: data.asking_price || null,
    listed_on: data.listed_on || null,
    status: data.status || null,
    deleted_at: data.deleted_at || null,
    deleted: data.deleted || false,
    vessel_id: data.vessel_id || null,
    company_office_id: data.company_office_id || null,
    broker_user_id: data.broker_user_id || null,
    buyer_contact_id: data.buyer_contact_id || null,
    co_buyer_contact_id: data.co_buyer_contact_id || null,
    seller_contact_id: data.seller_contact_id || null,
    co_seller_contact_id: data.co_seller_contact_id || null,
    co_broker_contact_id: data.co_broker_contact_id || null,
    company_id: data.company_id || null,
    task_type: data.task_type || null,
    owner_or_legal: data.owner_or_legal || null,
    and_or_assigns: data.and_or_assigns || null,
    association: data.association || null,
    unique_transaction_id: data.unique_transaction_id || null,
  };
};
