import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import clsx from "clsx";
import PageSidebarContainer from "../../../../common/PageSidebarContainer";
import { updateOptionsRequiredFields } from "../../../../../redux/actions/transaction";
import { useQueryParams } from "../../../../../utils/query-params";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AddendumStep = () => {
  let location = useLocation();
  let { id } = useParams();
  const dispatch = useDispatch();
  const dataTransaction = useSelector(
    (state) => state.transactions.transaction
  );
  const [asterix, setAsterix] = useState("empty");

  const dataListing = dataTransaction || {};
  const dataAddendum = dataListing.t_purchase_addendum || [];
  const query = useQueryParams();

  const history = useHistory();

  let requiredFields = [
    { addendum_no: "Addendum Number" },
    { ammendment: "Provision #1" },
  ];

  useEffect(() => {
    let notfilledFields = [];

    const addendum = dataAddendum.find(el => el.t_edocument_id === query.get('doc_id'));
    if (addendum) {
      requiredFields.forEach((rf) => {
        const field_key = Object.keys(rf)[0];
        if (field_key === 'ammendment') {
          if (addendum[field_key] && addendum[field_key] === "/://://://:/") {
            notfilledFields.push(rf);
          }
        } else {
          if (!addendum[field_key]) {
            notfilledFields.push(rf);
          }
        }
      })
      if (notfilledFields.length === 2) {
        setAsterix("empty");
      } else if (notfilledFields.length === 0) {
        setAsterix("fulfill");
      } else {
        setAsterix("notFulfill");
      }
    } else {
      notfilledFields = requiredFields
      setAsterix("empty");
    }

    dispatch(
      updateOptionsRequiredFields({
        addendum: notfilledFields
      })
    );
  }, [dataTransaction, query]);

  let branch = location.pathname.split("/").pop();

  return (
    <PageSidebarContainer type="add-deal">
      <div className="sidebar-container add-deal">
        <ul className="uk-nav uk-nav-default tm-nav">
          <Link
            to={
              query.get('doc_id') ?
                `/listing-agreement/view/${id}/listing-agreement-addendum?doc_id=${query.get('doc_id')}` :
                `/listing-agreement/view/${id}/listing-agreement-addendum`
            }
          >
            <li
              className={clsx("add-deal chain-step", {
                current: branch === "listing-agreement-addendum",
              })}
            >
              <i
                className={clsx(
                  "fa",
                  { "fa-check-circle stepbar": asterix === "fulfill" },
                  { "fa-circle-o": asterix === "empty" || !asterix },
                  { "fa-circle": asterix === "notFulfill" }
                )}
              />
              <span className="add-deal-step">Addendum</span>
            </li>
          </Link>
          {/* <Link
            to={
              query.get('doc_id') ?
                `/listing-agreement/view/${id}/listing-agreement-addendum/send_to_signer?doc_id=${query.get('doc_id')}` :
                `/listing-agreement/view/${id}/listing-agreement-addendum/send_to_signer`
            }
          >
            <li
              className={clsx("add-deal chain-step", {
                current: branch === "send_to_signer",
              })}
            >
              <i className="fa fa-circle-o"></i>
              <span className="add-deal-step">Send for eSignature</span>
            </li>
          </Link> */}

          {
            dataTransaction?.id &&
            // <li
            //   name="back-to-eDocuments"
            //   id="tab-back-to-eDocuments"
            //   className={clsx("add-deal chain-step")}
            // >
            //   <Link to={`/listing-agreement/view/${dataTransaction.id}/main#tab=%22edocuments%22`}><span uk-icon="chevron-double-left"></span>Back to eDocuments</Link>
            // </li>
            <button className="uk-button uk-button-primary mt-2" onClick={() => history.push(`/listing-agreement/view/${dataTransaction.id}/main#tab=%22edocuments%22`)}>
              <span uk-icon="chevron-double-left"></span>Back to eDocuments
            </button>

          }

        </ul>
      </div>
    </PageSidebarContainer>
  );
};

export default AddendumStep;
