import GridForm from "../../../../common/GridForm";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";

const DetailOfToys = ({ toys }) => {
  toys = toys || [];

  return (
    <>
      {toys.map((toy) => {
        let vessel_title = `${toy.t_vessel.length || ""} ${toy.t_vessel?.make || ""} ${
          toy.t_vessel?.model || ""
        } ${toy.t_vessel?.year || ""}`;
        vessel_title = vessel_title === "   " ? "N/A" : vessel_title;

        return (
          <GridForm key={toy.id} className="toy-detail">
            <FormRow>
              <FormInputWrapper label="Hull Number">
                <div className="input-view">{toy ? toy.t_vessel?.hull_number || "N/A" : "N/A"}</div>
              </FormInputWrapper>
              <FormInputWrapper size="two" label="Toy/Tender">
                <div className="input-view">{vessel_title}</div>
              </FormInputWrapper>
            </FormRow>
            <FormRow>
              <FormInputWrapper size="two" label="Engine Description">
                <div className="input-view">{toy ? toy.t_vessel?.description || "N/A" : "N/A"}</div>
              </FormInputWrapper>
              <FormInputWrapper label="Name">
                <div className="input-view">{toy ? toy.t_vessel?.vessel_name || "N/A" : "N/A"}</div>
              </FormInputWrapper>
            </FormRow>
            <FormRow>
              <FormInputWrapper label="Flag">
                <div className="input-view">{toy ? toy.t_vessel?.flag || "N/A" : "N/A"}</div>
              </FormInputWrapper>
              <FormInputWrapper label="Doc/Reg No.(Doc#)">
                <div className="input-view">{toy ? toy.t_vessel?.doc_or_reg_no || "N/A" : "N/A"}</div>
              </FormInputWrapper>
            </FormRow>
            <FormRow>
              <div className="toys-line-separator"></div>
            </FormRow>
          </GridForm>
        );
      })}
    </>
  );
};

export default DetailOfToys;
