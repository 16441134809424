import { useEffect, useCallback } from "react";
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import { getListingsByBroker } from "../../../redux/actions/dashboard";
import { getPrice, humanizeDate } from "../../../utils/currency";
import { setBrokerFilter } from '../../../redux/actions/report';
import Auth from '../../../utils/auth';

const ActiveListingsByBroker = () => {
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.dashboard.listingsByBroker);
  const history = useHistory();
  const loggedUser = Auth.getInstance().getUserData();

  useEffect(() => {
    dispatch(getListingsByBroker());
  }, []);

  const handleClickViewAll = useCallback(() => {
    if (loggedUser?.UserInRole?.UserRole?.name === 'Broker') {
      dispatch(setBrokerFilter(loggedUser.id));
    }
    history.push('/reports/listing-agreements');
  }, []);

  return (
    <>
      <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
        <thead>
          <tr>
            <th>Broker</th>
            <th>Listings</th>
            <th>Value</th>
            <th>Commission</th>
            <th>Listed Dates</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(stats)
            .slice(0, 3)
            .map((key, index) => {
              return (
                <tr key={`${key}_${index}`}>
                  <td>{stats[key].broker_name}</td>
                  <td>{stats[key].count}</td>
                  <td>{getPrice(stats[key].value)}</td>
                  <td>{getPrice(stats[key].commission)}</td>
                  <td>{stats[key].listed.length > 0 && stats[key].listed.map((element) => {
                    return <><p style={{ marginBottom: "3px" }}>{humanizeDate(element)}</p></>
                  })}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {Object.keys(stats).length !== 0 && (
        <div className="view-all">
          <a href="#" onClick={handleClickViewAll}>View All</a>
        </div>
      )}
    </>
  );
};

export default ActiveListingsByBroker;
