import { RETRIEVE_STATES } from "../actions/types";

const initialState = [];

function countryState(states = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_STATES:
      return payload;
    default:
      return states;
  }
}

export default countryState;
