import React, { useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from "@hookform/resolvers/yup";

import Form from '../../../../../common/Form';
import FormRow from '../../../../../common/FormRow';
import FormInputWrapper from '../../../../../common/FormInputWapper';
import { getFigure, getPrice } from '../../../../../../utils/currency';
import { getPlainTransactionData } from "../../../../../../utils/stepFilter";
import { setLoadingStatus } from '../../../../../../redux/actions/loading';
import { getTransactionEsignById, upsertTransaction } from '../../../../../../redux/actions/transaction';
import { dateFormatter } from "../../../../../../utils/common";

import * as yup from "yup";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

const TabFormPayoffInformation = ({ closePanel }) => {

    const schema = yup.object().shape({});

    const { register, handleSubmit, setValue, reset, getValues, watch, formState: { isDirty, errors }, control } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: false,
    });

    const dispatch = useDispatch();

    const figureFields = ["payoff", "per_diem"];

    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataDeal = dataTransaction || {};
    const dataPayOff = dataDeal.t_seller_payoff || {};

    useEffect(() => {
        if (dataPayOff) {
            Object.keys(dataPayOff).map((key) => {
                if (figureFields.indexOf(key) > -1) {
                    setValue(key, getPrice(dataPayOff[key]));
                } else {
                    setValue(key, dataPayOff[key]);
                }
            });
        }
    }, [dataPayOff]);

    const handleBlur = (ref) => {
        const price = getPrice(getValues(ref));
        setValue(ref, price);
    };

    const handleFocus = (ref) => {
        const figure = getFigure(getValues(ref));
        setValue(ref, figure);
    };

    const handleRegistration = (detail) => {
        Object.keys(detail).forEach((key) => {
            if (!detail[key]) {
                detail[key] = null;
            }
            if (figureFields.indexOf(key) > -1) {
                detail[key] = getFigure(detail[key]);
            }
        });
        dispatch(setLoadingStatus(true));
        dispatch(upsertTransaction({
            step: {
                db: "seller_payoff",
                main: detail,
            },
            transaction: {
                isBelonged: false,
                main: { ...getPlainTransactionData(dataDeal), task_type: "deal" },
            },
        }, "Seller Payoff")
        ).then((data) => {
            dispatch(setLoadingStatus(false));
            dispatch(getTransactionEsignById(data.id));
        }).catch((error) => {
            dispatch(setLoadingStatus(false));
            console.log(error);
        });
    };

    const handleError = (error) => {
        console.log(error);
    };

    return (
        <div className="form-container panel-form">
            <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                <FormRow>
                    <FormInputWrapper label="Bank Name">
                        <input className="uk-input" type="text" {...register("bank_name")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Address">
                        <input className="uk-input" type="text" {...register("address")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Address 2">
                        <input className="uk-input" type="text" {...register("address_2")} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="City">
                        <input className="uk-input" type="text" {...register("city")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="State">
                        <input className="uk-input" type="text" {...register("state")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Zip">
                        <input className="uk-input" type="text" {...register("zip")} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Phone">
                        <input className="uk-input" type="text" {...register("phone")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Fax">
                        <input className="uk-input" type="text" {...register("fax")} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Contact">
                        <input className="uk-input" type="text" {...register("contact")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Mobile">
                        <input className="uk-input" type="text" {...register("mobile")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Email">
                        <input className="uk-input" type="text" {...register("email")} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Account Number">
                        <input className="uk-input" type="text" {...register("account_number")} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Payoff">
                        <input className="uk-input" type="text" {...register("payoff")}
                            onBlur={() => handleBlur("payoff")} onFocus={() => handleFocus("payoff")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Good Until">
                        <Controller
                            control={control}
                            name="good_until"
                            render={({ field }) => (
                                <DatePicker
                                    className="uk-input"
                                    style={{ cursor: "pointer", color: "#000" }}
                                    format={'MM/DD/YYYY'}
                                    value={field.value && dayjs(field.value)}
                                    onChange={(date, dateString) => {
                                        field.onChange(dateString ? dateFormatter(dateString) : null);
                                    }}
                                />
                            )}
                        />
                    </FormInputWrapper>
                    <FormInputWrapper label="Per Diem">
                        <input className="uk-input" type="text" {...register("per_diem")}
                            onBlur={() => handleBlur("per_diem")} onFocus={() => handleFocus("per_diem")} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <div className="text-right pt-3">
                        <button type="button" className='uk-button uk-button-orange' onClick={() => closePanel(true)}>Close</button>
                        <button type="submit" className='uk-button uk-button-primary'>Save</button>
                    </div>
                </FormRow>
            </Form>
        </div>
    )
}

export default TabFormPayoffInformation;