import {
  CREATE_OFFICE,
  RETRIEVE_OFFICES,
  GET_OFFICE,
  UPDATE_OFFICE,
  DELETE_OFFICE,
  DELETE_ALL_OFFICES,
  FIND_OFFICE_BY_NAME,
  FIND_BY_COMPANY,
  FIND_BY_OFFICE_ID
} from "./types";

import OfficeService from "../../services/OfficeService";

export const createOffice = (office) => async (dispatch) => {
  try {
    const res = await OfficeService.create(office);

    dispatch({
      type: CREATE_OFFICE,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const retrieveOffices = () => async (dispatch) => {
  try {
    const res = await OfficeService.getAll();

    dispatch({
      type: RETRIEVE_OFFICES,
      payload: res.data,
    });
  } catch (err) {
    console.log("Error = ", err);
  }
};

export const updateOffice = (id, data) => async (dispatch) => {
  try {
    const res = await OfficeService.update(id, data);

    dispatch({
      type: UPDATE_OFFICE,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getOffice = (id) => async (dispatch) => {
  try {
    const res = await OfficeService.get(id);
    dispatch({
      type: GET_OFFICE,
      payload: res.data,
    });
  } catch (err) {
    console.log("Error = ", err);
  }
};

export const deleteOffice = (id) => async (dispatch) => {
  try {
    await OfficeService.remove(id);

    dispatch({
      type: DELETE_OFFICE,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllOffices = () => async (dispatch) => {
  try {
    const res = await OfficeService.removeAll();

    dispatch({
      type: DELETE_ALL_OFFICES,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findOfficeByName = (name) => async (dispatch) => {
  try {
    const res = await OfficeService.findByName(name);

    dispatch({
      type: FIND_OFFICE_BY_NAME,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const findByCompany = (id) => async (dispatch) => {
  try {
    const res = await OfficeService.findByCompany(id);

    dispatch({
      type: FIND_BY_COMPANY,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getByOfficeId = (companyId, officeId) => async (dispatch) => {
  try {
    const res = await OfficeService.getByOfficeId(companyId, officeId);

    dispatch({
      type: FIND_BY_OFFICE_ID,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};