import DataTable from 'react-data-table-component'
import datatableStyles from '../../utils/datatable-style';
import { momentDateWithTimezone } from '../../utils/common';
import { capitalizeFirstLetter } from '../../utils/functions';
import { useSelector } from 'react-redux';

const LoginOutLogsTable = ({ searchProps, loginActivities, totalRows }) => {
  const { search, setSearch } = searchProps;
  const isLoading = useSelector((state) => state.loading.status);


  const columns = [
    {
      name: "Full Name",
      selector: "first_name",
      sortable: true,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>{`${capitalizeFirstLetter(row?.ul_user?.first_name || "") || ""} ${capitalizeFirstLetter(row?.ul_user?.last_name || "") || ""}`}</div>
          </div>
        );
      },
      // width: '250px',
    },
    {
      name: "Login Time",
      selector: "login_time",
      sortable: true,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>{momentDateWithTimezone(row.login_time)}</div>
          </div>
        );
      },
      // width: '250px',
    },
    {
      name: "Logout time",
      selector: "logout_time",
      sortable: true,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>{momentDateWithTimezone(row.logout_time)}</div>
          </div>
        );
      },
      // width: '250px',
    },
    {
      name: "IP Address",
      selector: "ip_address",
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>{row.ip_address}</div>
          </div>
        );
      },

    },

  ];

  //Function for handle row limit
  const handleRowsPerPage = (numberOfRows, page) => {
    setSearch((search) => {
      return {
        ...search,
        limit: numberOfRows,
        page: page
      }
    })
  }

  //Function for handle page no
  const handlePageChange = page => {
    setSearch((search) => {
      return {
        ...search,
        page: page
      }
    })
  };

  //Function for handle sort
  const handleSort = (column, sortDirection) => {
    // column.selector, sortDirection
    setSearch((search) => {
      return {
        ...search,
        sortOrder: sortDirection,
        sortField: column.selector
      }
    })
  };
  return (
    <>{/* Users List Table */}

      {!loginActivities.length && isLoading
        ? <div className="no-data-table"><div className="p-4">Fetching Details...</div></div>
        : <DataTable
          title="Users Table"
          columns={columns}
          data={loginActivities}
          striped={true}
          highlightOnHover={true}
          pointerOnHover={true}
          center={true}
          noHeader={true}
          fixedHeader={true}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationRowsPerPageOptions={[10, 20, 50, 100]}
          onChangeRowsPerPage={handleRowsPerPage}
          onChangePage={handlePageChange}
          onSort={handleSort}
          sortServer
          persistTableHead
          customStyles={datatableStyles}
        />
      }</>
  )
}

export default LoginOutLogsTable