const hull_materials = [
    "Aluminum",
    "Composite",
    "Carbon Fiber",
    "Cold Molded",
    "Epoxy Composite",
    "Ferro-Cement",
    "Fiberglass",
    "FRP",
    "Hypalon",
    "Other",
    "PVC",
    "Roplene",
    "Steel",
    "Wood",
    "Unknown"
]

export default hull_materials