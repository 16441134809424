import FormRow from "../../../../common/FormRow";
import ModalBody from "../../../../common/ModalBody";
import OffCanvasContainer from "../../../../common/OffCanvasContainer";

const ViewExclusionsForm = ({ exclusions }) => {
  exclusions = exclusions || [];

  return (
    <OffCanvasContainer id="modal-exclusions">
      <ModalBody title="Exclusions">
        <FormRow>
          <ul>
            {exclusions?.length ? (
              exclusions.map((exclusion, idx) => {
                return (
                  <li key={exclusion.id} style={{ listStyle: "none" }}>
                    <span className="uk-form-label" style={{ display: "inline-block" }}>
                      Exclusion {idx + 1}: &nbsp;
                    </span>
                    <span className="input-view">
                      {exclusion.exclusion ? exclusion.exclusion || "N/A" : "N/A"}
                    </span>
                  </li>
                );
              })
            ) : (
              <li className="input-view mt20">No exclusions</li>
            )}
          </ul>
        </FormRow>
      </ModalBody>
    </OffCanvasContainer>
  );
};

export default ViewExclusionsForm;
