import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormPage from "../../../../common/FormPage";
import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import AsterixTypo from "../../../../common/AsterixTypo";
import SubmitButton from "../../../../common/buttons/SubmitButton";
import LinkButton from "../../../../common/buttons/LinkButton";
import { getTransactionInitialById } from "../../../../../redux/actions/transaction";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "../../../../../utils/query-params";
import { toast } from "react-toastify";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import TransactionService from "../../../../../services/TransactionService";
import { useState } from "react";

const schema = yup.object().shape({});

const AddMaterialDamageForm = ({ transactionId }) => {
  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const dataTerms = dataTransaction?.t_terms || {};

  const dispatch = useDispatch();
  const query = useQueryParams();
  let history = useHistory();
  const [materialDamaged, setMaterialDamaged] = useState(dataTerms?.materially_damaged);

  /* Form Validation */
  const { register, handleSubmit, setValue, getValues } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  useEffect(() => {
    setValue("material_damage_detail", dataTerms.material_damage_detail);
  }, [dataTerms]);

  useEffect(() => {
    setValue("materially_damaged", materialDamaged)
  }, [materialDamaged])

  const handleRegisteration = async (data, isContinue = true) => {
    try {
      data["transaction_id"] = dataTransaction?.id || null;
      data["id"] = dataTerms?.id || null;
      dispatch(setLoadingStatus(true));
      await TransactionService.updateTerms(data);
      dispatch(getTransactionInitialById(dataTransaction?.id));
      dispatch(setLoadingStatus(false));
      toast.success("Terms data updated successfully.", {
        autoClose: 2000
      })
      if (isContinue) {
        history.push(`/listing-agreement/view/${transactionId}/material-damage-disclosure`);
      }
    } catch (error) {
      dispatch(setLoadingStatus(false));
      toast.error("Error occurs, please add terms data.", {
        autoClose: 2000
      })
    }
  };

  const handleError = () => {
    toast.error("Error occurs, please add at least one exclusion.", {
      autoClose: 2000
    })
  };

  const links = [
    {
      label: "Listing Agreement",
      link: `/listing-agreement/view/${transactionId}/main`,
    },
    { label: "Add a Listing Agreement", link: null },
  ];

  const vesselName = `${dataTransaction?.t_vessel?.year || ""} ${dataTransaction?.t_vessel?.make || ""} ${dataTransaction?.t_vessel?.model || ""} ${dataTransaction?.t_vessel?.length || ""}`;
  const uniqueTransactionId = dataTransaction?.unique_transaction_id ? `LA-${dataTransaction?.unique_transaction_id}` : '';

  return (
    <FormPage links={links} title="Terms ( Materially Damaged Form )" vesselName={vesselName} uniqueId={uniqueTransactionId}>
      <Form onSubmit={handleSubmit(handleRegisteration, handleError)}>
        <FormRow>
          <FormInputWrapper label="Materially Damaged">
            <div className="radio-toolbar">
              <input
                type="radio"
                id="yes"
                name="material_damaged"
                checked={materialDamaged != null && materialDamaged}
                onClick={() => setMaterialDamaged(true)}
                readOnly
              />
              <label className="uk-form-label radio" htmlFor="yes">Yes</label>
              <input
                type="radio"
                name="material_damaged"
                id="no"
                checked={materialDamaged != null && !materialDamaged}
                onClick={() => { setMaterialDamaged(false); setValue('material_damage_detail', null); }}
                readOnly
              />
              <label className="uk-form-label radio" htmlFor="no">No</label>
              <input
                type="radio"
                name="material_damaged"
                checked={materialDamaged == null}
                onClick={() => { setMaterialDamaged(null); setValue('material_damage_detail', null); }}
                id="unknown"
                readOnly
              />
              <label className="uk-form-label radio" htmlFor="unknown">Unknown</label>
            </div>
          </FormInputWrapper>
        </FormRow>
        {materialDamaged && (
          <FormRow>
            <FormInputWrapper size="two" label="Please explain material damage">
              <textarea className="uk-textarea" rows="3" {...register("material_damage_detail")} />
            </FormInputWrapper>
          </FormRow>
        )}
        <FormRow>
          <SubmitButton>Save & Continue</SubmitButton>
          <LinkButton
            to={`/listing-agreement/view/${transactionId}/main`}
            onClick={() => handleRegisteration(getValues(), false)}
          >
            Save & Finish Later
          </LinkButton>
        </FormRow>
        <AsterixTypo isVisible={false} />
      </Form>
    </FormPage>
  );
};

export default AddMaterialDamageForm;
