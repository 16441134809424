import { useEffect } from "react";
import { useDispatch } from "react-redux";
import ModalBody from "../../../../common/ModalBody";
import OffCanvasContainer from "../../../../common/OffCanvasContainer";
import DetailOfVessel from "../details/DetailOfVessel";
import { clearVessel } from "../../../../../redux/actions/vessels_toys";

const ViewVesselForm = ({ vessel }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearVessel());
    };
  }, []);

  return (
    <OffCanvasContainer id="modal-vessel">
      <ModalBody title="Vessel">
        <DetailOfVessel vessel={vessel} />
      </ModalBody>
    </OffCanvasContainer>
  );
};

export default ViewVesselForm;
