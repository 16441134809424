import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Auth from '../../utils/auth';
import DataTable from "react-data-table-component";
import datatableStyles from "../../utils/datatable-style";
import AssociateCompanyForParticipantModal from '../common/AssociateCompanyForParticipantModal';

const GuestAgentList = ({ searchProps, agentList, totalRows, columns }) => {

    const { search, setSearch } = searchProps;
    const isLoading = useSelector((state) => state.loading.status);

    // States for manage open close modal for manage Guest Agents associate companies
    const [showAssociateCompanies, setShowAssociateCompanies] = useState(false);
    const [associateGuestAgents, setAssociateGuestAgents] = useState(null);

    //Function for handle row limit
    const handleRowsPerPage = (numberOfRows, page) => {
        setSearch((search) => {
            return {
                ...search,
                limit: numberOfRows,
                page: page
            }
        })
    }

    //Function for handle page no
    const handlePageChange = page => {
        setSearch((search) => {
            return {
                ...search,
                page: page
            }
        })
    };

    //Function for handle sort
    const handleSort = (column, sortDirection) => {
        // column.selector, sortDirection
        setSearch((search) => {
            return {
                ...search,
                sortOrder: sortDirection,
                sortField: column.selector
            }
        })
    };

    return (
        <>
            {!agentList.length && isLoading
                ? <div className="no-data-table"><div className="p-4">Fetching Details...</div></div>
                : <DataTable
                    title="Users Table"
                    columns={columns}
                    data={agentList}
                    striped={true}
                    highlightOnHover={true}
                    pointerOnHover={true}
                    center={true}
                    noHeader={true}
                    fixedHeader={true}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                    onChangeRowsPerPage={handleRowsPerPage}
                    onChangePage={handlePageChange}
                    onSort={handleSort}
                    sortServer
                    persistTableHead
                    customStyles={datatableStyles}
                />}

            {showAssociateCompanies && <AssociateCompanyForParticipantModal show={showAssociateCompanies} onClose={() => setShowAssociateCompanies(false)} participant={associateGuestAgents} />}
        </>
    )
}

export default GuestAgentList;