import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from '@mui/styles';

import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import moment from "moment";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Tooltip } from "@mui/material";
import Multiselect from "multiselect-react-dropdown";
import { Helmet } from "react-helmet";
import PhoneInput from 'react-phone-input-2';
import { useHistory } from "react-router-dom";
import { getCompany, updateCompany } from "../../redux/actions/companies";
import { setLoadingStatus } from "../../redux/actions/loading";
import { getProfile, removeOfficeUsers, retrieveOffices, retrieveOfficeUsers } from "../../redux/actions/users";
import BrokerageService from '../../services/BrokerageService';
import Auth from "../../utils/auth";
import { getFigure, getPrice } from "../../utils/currency";
import { uploadImage } from "../../utils/s3";
import Form from '../common/Form';
import FormInputWrapper from "../common/FormInputWapper";
import FormRow from "../common/FormRow";
import SubmitButton from '../common/buttons/SubmitButton';
import VideoPlayerModal from "../common/videoPlayerModal";
import StyleSwitch from "../common/buttons/StyleSwitch";

const useStyles = makeStyles({
    phone_input: {
        padding: '12px 12px 12px 50px !important',
        width: '100% !important',
        height: '40px !important'
    },
    preview_container: {
        position: "relative",
        width: "fit-content"
    },
    preview_overlay: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        background: "rgba(0,0,0,0.8)",
        color: "white",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    logo_select_area: {
        border: '1px dashed #c3c3c3',
        width: '100%',
        height: '40px',
        color: '#c3c3c3',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    }
});

const schema = yup.object().shape({
});

const BrokeragePage = (props) => {
    const [show, setShow] = useState(false);
    const [currentVideo, setCurrentVideo] = useState(null)
    const dispatch = useDispatch();
    const [isFinishSubmit, setFinishSubmit] = useState(false);
    const [mlsBrokerageId, setMlsBrokerId] = useState(null);
    const [error, setError] = useState(undefined);
    const [logoPreview, setLogoPreview] = useState(null);
    const [showMlsIntegration, setShowMlsIntegration] = useState(false);
    const [logoFile, setLogoFile] = useState(null);
    const [editOverlay, setEditOverlay] = useState(false);
    const [brokerProfile, setBrokerProfile] = useState(null);
    const fileRef = useRef(null);
    const [associations, setAssociations] = useState([]);

    // logoNameForTooltip state stores logoName which will use in tooltip
    const [logoNameForTooltip, setLogoNameForTooltip] = useState(null);

    const offices = useSelector((state) => state.users.offices);
    // office_name
    offices.sort((a, b) => a.office_name < b.office_name ? -1 : 1)

    const officeUsers = useSelector((state) => state.users.officeUsers);
    officeUsers.sort((a, b) => a.last_name < b.last_name ? -1 : 1)

    const classes = useStyles();

    let history = useHistory();
    const tutorials = useSelector((state) => state?.tutorials) || null;

    const logged_user = Auth.getInstance().getUserData();

    const fields = [
        'main_office_name',
        'employing_broker_name',
        "associations",
        'default_co_brokerage',
        'fees',
        'logo_url',
        'ein',
        'brokerage_company_name',
        'wire_account_name',
        'wire_account_number',
        'wire_routing_number',
        'wire_swift_code',
        'wire_bank_name',
        'wire_bank_contact',
        'wire_bank_phone',
        'wire_bank_address',
        'wire_brokerage_contact',
        'wire_work_phone',
        'wire_instructions',
        'is_only_admin_can_edit_pdf',
        'notify_on_doc_execution',
        'is_admin_and_agent_notify_upon_la_or_deal_creation',
        'is_admin_and_agent_notify_upon_la_or_deal_signature',
    ];

    const currencyFields = [
        'fees'
    ];

    const dropDownFields = [
        'main_office_name',
        'employing_broker_name',
    ]

    const associationList = [
        { id: 0, name: 'IYBA', fieldName: 'iyba' },
        { id: 1, name: 'YBAA', fieldName: 'ybaa' },
        { id: 2, name: 'CYBA', fieldName: 'cyba' },
        { id: 3, name: 'MYBA', fieldName: 'myba' },
        { id: 4, name: 'NYBA', fieldName: 'nyba' },
        { id: 5, name: 'USCG', fieldName: 'uscg' },
        { id: 6, name: 'Custom', fieldName: 'custom' }
    ];

    const initializeForms = async company_id => {
        const { data: brokerageData } = await BrokerageService.get(company_id);

        // main_office_name
        if (brokerageData.main_office_name) {
            setValue("main_office_name", brokerageData.main_office_name)
            // dispatch(retrieveOfficeUsers(brokerageData.main_office_name));
        }
        dispatch(getCompany(company_id)).then(company => {
            // check if company already have logo then set that logo's name
            if (company?.data?.logo) {
                setLogoNameForTooltip(company?.data?.logo);
            }
            if (company.data?.mls_brokerage_id) {
                setShowMlsIntegration(true);
                setMlsBrokerId(company.data?.mls_brokerage_id)
            }
            setValue('brokerage_company_name', company.data?.company_name);
            setAssociations(associationList.filter(associationData => company.data?.association?.split(',').includes(associationData['fieldName'])));
        });


        dispatch(removeOfficeUsers());
        fields.forEach(key => {
            if (key.indexOf("date") > -1) {
                setValue(key, brokerageData[key] ? moment(brokerageData[key]).utc().format("yyyy-MM-DD") : null);
            } else if (currencyFields.indexOf(key) > -1) {
                setValue(key, getPrice(brokerageData[key]));
            } else if (["main_office_name", "employing_broker_name", 'brokerage_company_name'].includes(key)) {
                return;
            } else {
                setValue(key, brokerageData[key]);
            }
        });
    }

    const { register, handleSubmit, reset, setValue, formState: { errors }, getValues, watch } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true
    });

    const fetchOffices = async (company_id) => {
        dispatch(retrieveOffices(company_id));
    }

    useEffect(() => {
        if (logged_user.company_id) {
            fetchOffices(logged_user.company_id);
            initializeForms(logged_user.company_id)
        }
    }, [logged_user.company_id]);


    const handleError = (errors) => {
        setError(errors);
    };

    const handleBlur = (ref) => {
        const price = getPrice(getValues(ref));
        setValue(ref, price);
    };

    const handleFocus = (ref) => {
        const figure = getFigure(getValues(ref));
        setValue(ref, figure);
    };

    const handleFileChange = e => {
        if (e.target.files[0]) {
            setLogoPreview(URL.createObjectURL(e.target.files[0]));
            setLogoFile(e.target.files[0]);
            // if user select another logo then set that new name on tooltip title
            setLogoNameForTooltip(e.target.files[0].name);
        }
    }

    useEffect(() => {
        if (watch('logo_url')) {
            setLogoPreview(`https://${process.env.REACT_APP_CDN_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}brokerage-logo/${getValues('logo_url')}`);
        }
    }, [watch('logo_url')]);

    const fetchBrokerData = (officeId) => {
        setBrokerProfile(null);
        if (officeId) {
            dispatch(retrieveOfficeUsers(officeId));
        } else {
            // setValue("main_office_name", "");
            dispatch(removeOfficeUsers());
        }
    }


    useEffect(() => {
        setBrokerProfile(null);
        officeUsers.map((broker) => {
            if (broker.is_broker_manager) {
                setValue("employing_broker_name", broker.id);
                getBrokerProfileData(broker.id)
            }
        })
    }, [officeUsers]);

    const getBrokerProfileData = async (brokerId) => {
        if (brokerId) {
            const brokerProfile = await BrokerageService.getBrokerProfile(brokerId);
            setBrokerProfile(brokerProfile.data);
        } else {
            setBrokerProfile(null);
        }
    }

    const handleRegister = async (brokerage) => {
        brokerage.fees = getFigure(brokerage.fees);
        try {
            dispatch(setLoadingStatus(true));
            if (logoPreview && logoFile) {
                brokerage.logo_url = null;
                const logo_name = await uploadImage(logoFile, 'brokerage-logo');
                brokerage.logo_url = logo_name;
                // once it is saved , have to change logo's name as database
                setLogoNameForTooltip(logo_name);
                // if we save changes then  upload api calls everytime so have to setLogoFile null to prevent this problem
                setLogoFile(null);
            }
            brokerage['deleted'] = false;
            BrokerageService.upsert(logged_user.company_id, brokerage).then(response => {
                dispatch(updateCompany(logged_user.company_id, { 'company_name': brokerage.brokerage_company_name }))
                    .then((response) => {
                        dispatch(setLoadingStatus(false));
                    })
                    .catch((err) => {
                        dispatch(setLoadingStatus(false));
                    });
                dispatch(getProfile(logged_user.id)).then((profile) => {
                    Auth.getInstance().setUserData(profile);
                });
                dispatch(setLoadingStatus(false));
                setError(undefined);
                setFinishSubmit(true);
                setTimeout(() => {
                    setFinishSubmit(false);
                }, 2000);
            });
        } catch (err) {
            dispatch(setLoadingStatus(false));
        }
    };
    useEffect(() => {
        setCurrentVideo(tutorials?.brokerage);
    }, [tutorials]);
    const onCloseVideoPlayerModal = () => {
        setShow(() => false);
    }

    const onChangeNotificationSettings = (notificationType) => {
        setValue(notificationType, !getValues(notificationType));
    }

    const notify_on_doc_execution = useMemo(() => getValues("notify_on_doc_execution"), [watch('notify_on_doc_execution')]);
    const is_admin_and_agent_notify_upon_la_or_deal_creation = useMemo(() => getValues("is_admin_and_agent_notify_upon_la_or_deal_creation"), [watch('is_admin_and_agent_notify_upon_la_or_deal_creation')]);
    const is_admin_and_agent_notify_upon_la_or_deal_signature = useMemo(() => getValues("is_admin_and_agent_notify_upon_la_or_deal_signature"), [watch('is_admin_and_agent_notify_upon_la_or_deal_signature')]);

    return (
        <div className="sign-in uk-container page">
            <Helmet>
                <title>Setting Brokerage - BoatDox</title>
            </Helmet>
            <div className=" uk-container uk-container-expand uk-position-relative">
                <h1 className="d-flex align-items-center gap-1">
                    {currentVideo && <Tooltip title="Play Tutorial" arrow placement='top'  >
                        <PlayCircleOutlineIcon fontSize="large" style={{ cursor: "pointer" }} className="mr-1" onClick={() => setShow(true)} />
                    </Tooltip>}Brokerage</h1>

                <div className="form-container uk-margin-small" style={{ marginBottom: '48px' }}>
                    <Form onSubmit={handleSubmit(handleRegister, handleError)}>
                        {isFinishSubmit ?
                            <div className="uk-alert uk-alert-primary" data-uk-alert><p>Updated Successfully</p></div>
                            : undefined
                        }
                        <FormRow>
                            <FormInputWrapper width="sm-full-width">
                                <div className="uk-grid">
                                    <div className="uk-width-1-1">
                                        <div className="d-flex flex-wrap justify-content-between">
                                            <div className="uk-pull-2-5"><label className="uk-form-label">Main Office Name</label></div>
                                            <div className="uk-push-3-5">
                                                <div className="uk-flex uk-flex-center@m uk-flex-right@l">
                                                    <button type="button" onClick={() => history.push("/offices")} className="uk-button uk-button-link" style={{ marginRight: "7px", fontSize: "small", textTransform: "none" }}>+Add New</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <FormRow>
                                    <select
                                        aria-label="Custom controls"
                                        className={clsx("uk-select", { 'uk-form-danger': errors.main_office_name })}
                                        {...register('main_office_name')}
                                    // onChange={(event) => fetchBrokerData(event.target.value)}
                                    >
                                        <option value="" key={`Default_Office`}>
                                            Please select office
                                        </option>
                                        {offices.map((office) => {
                                            return (
                                                <option value={office.id} key={office.id}>
                                                    {office.office_name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </FormRow>
                            </FormInputWrapper>
                            <div className="one-column sm-full-width uk-padding-small" style={{ paddingTop: '4px' }}>
                                <label className="uk-form-label ">Company Name*</label>
                                <input
                                    className={clsx("uk-input", {
                                        "uk-form-danger": errors.brokerage_company_name,
                                    })}
                                    type="text"
                                    {...register("brokerage_company_name")}
                                />
                            </div>
                        </FormRow>
                        <FormRow>
                            <div className="one-column sm-full-width uk-padding-small">
                                <label className="uk-form-label">EIN#</label>
                                <input
                                    className={clsx("uk-input", {
                                        "uk-form-danger": errors.ein,
                                    })}
                                    type="text"
                                    {...register("ein")}
                                />
                            </div>

                            <FormInputWrapper label='Associations' width="sm-full-width">
                                <Multiselect
                                    options={associationList}
                                    displayValue="name"
                                    showCheckbox="true"
                                    disable
                                    selectedValues={associations}
                                />
                            </FormInputWrapper>
                            <div className={` sm-full-width uk-padding-small`} style={{ marginTop: "30px" }}>
                                <Fragment key={Math.random()}>
                                    <input id="is_only_admin_can_edit_pdf" type="checkbox" name="is_only_admin_can_edit_pdf" {...register("is_only_admin_can_edit_pdf")} className="uk-checkbox" />
                                    <label className={`uk-form-label radio ${classes.show_broker_label}`} htmlFor="is_only_admin_can_edit_pdf" >PDF Edit for Company Admin only</label>
                                </Fragment>
                            </div>
                        </FormRow>
                        <FormRow>
                            <FormInputWrapper label='Brokerage logo'>
                                <>
                                    <div className="photo-container">
                                        <label className="uk-form-label" htmlFor="file-photo">
                                            {logoPreview ?
                                                <div className={classes.preview_container}>
                                                    {/* added tooltip for logo name */}
                                                    <Tooltip title={logoNameForTooltip} placement="right">
                                                        <img src={logoPreview} onMouseEnter={() => setEditOverlay(true)} />
                                                        {editOverlay && (
                                                            <div className={classes.preview_overlay} onMouseLeave={() => setEditOverlay(false)}>
                                                                <div>
                                                                    <svg viewBox="0 0 24 24" style={{ display: "inline-block", color: "rgb(255, 255, 255)", fill: "currentcolor", height: "32px", width: "32px", userSelect: "none" }}>
                                                                        <circle cx="12" cy="12" r="3.2"></circle>
                                                                        <path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"></path>
                                                                    </svg>
                                                                    <div style={{ color: "rgb(255, 255, 255)", fontSize: "18px" }}>Upload logo</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Tooltip>
                                                </div> :
                                                <div className={classes.logo_select_area}>
                                                    <span>Click Here to Select Logo</span>
                                                </div>}
                                        </label>
                                        <input id="file-photo" type="file" onChange={handleFileChange} style={{ display: 'none' }} ref={fileRef} accept="image/png, image/gif, image/jpeg" />
                                    </div>
                                </>
                            </FormInputWrapper>
                        </FormRow>
                        <FormRow>
                            <FormInputWrapper label='Default Co-brokerage %' width="sm-full-width">
                                <input className={clsx("uk-input", { 'uk-form-danger': errors.default_co_brokerage })} type="text" {...register("default_co_brokerage")} />
                            </FormInputWrapper>
                            <FormInputWrapper label='Processing & Other Fees' width="sm-full-width">
                                <input
                                    className={clsx("uk-input ", { 'uk-form-danger': errors.fees })}
                                    type="text"
                                    {...register("fees")}
                                    onBlur={() => handleBlur("fees")}
                                    onFocus={() => handleFocus("fees")}
                                />
                            </FormInputWrapper>
                        </FormRow>
                        <FormRow>
                            <hr style={{ width: "100%", marginBottom: "14px" }} />
                        </FormRow>
                        <FormRow>
                            <div className="half-column uk-padding-small">
                                <label className="uk-form-label text-wrap">
                                    <h3>Notification Settings</h3>
                                </label>
                            </div>
                        </FormRow>
                        <FormRow>
                            <div className={`uk-padding-small d-flex flex-column`}>
                                <div>
                                    <label className="mb-0 pb-0 custom-switch-bnt" style={{ marginLeft: '-8px' }}>
                                        <StyleSwitch
                                            checked={notify_on_doc_execution}
                                            onChange={() => onChangeNotificationSettings('notify_on_doc_execution')}
                                        />Notify Company Admins & Authorized Agents Upon Final Document Execution
                                    </label>
                                </div>
                                <div>
                                    <label className="mb-0 pb-0 custom-switch-bnt" style={{ marginLeft: '-8px' }}>
                                        <StyleSwitch
                                            checked={is_admin_and_agent_notify_upon_la_or_deal_creation}
                                            onChange={() => onChangeNotificationSettings('is_admin_and_agent_notify_upon_la_or_deal_creation')}
                                        />Notify Company Admins & Authorized Agents Upon LA or Deal Creation
                                    </label>
                                </div>
                                <div>
                                    <label className="mb-0 pb-0 custom-switch-bnt" style={{ marginLeft: '-8px' }}>
                                        <StyleSwitch
                                            checked={is_admin_and_agent_notify_upon_la_or_deal_signature}
                                            onChange={() => onChangeNotificationSettings('is_admin_and_agent_notify_upon_la_or_deal_signature')}
                                        />Notify Company Admins & Authorized Agents Upon Individual LA or Deal Signature
                                    </label>
                                </div>
                            </div>
                        </FormRow>
                        {showMlsIntegration &&
                            <React.Fragment>
                                <div className="form-row"><hr style={{ width: "100%", marginBottom: "14px" }} /></div>
                                <div className="form-row">
                                    <div className="half-column uk-padding-small">
                                        <label className="uk-form-label text-wrap"><h3 style={{ marginTop: "19px" }}>Yachtbroker.org MLS Integration :</h3></label>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="one-column sm-full-width uk-padding-small">
                                        <input
                                            readOnly
                                            className="uk-input"
                                            type="text"
                                            value={mlsBrokerageId ? mlsBrokerageId : ""}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        {['Company Admin', 'Boatdox Admin'].includes(logged_user.UserInRole.UserRole.name) &&
                            <Fragment>
                                <div className="form-row"><hr style={{ width: "100%", marginBottom: "14px" }} /></div>
                                <div className="form-row">
                                    <div className="half-column uk-padding-small">
                                        <label className="uk-form-label text-wrap"><h3 style={{ marginTop: "19px" }}>WIRE TRANSFER INFO :</h3></label>
                                    </div>
                                </div>
                                <FormRow>
                                    <div className="one-column sm-full-width uk-padding-small">
                                        <label className="uk-form-label">Account Name</label>
                                        <input className="uk-input"
                                            type="text" {...register("wire_account_name")} />
                                    </div>
                                    <div className="one-column sm-full-width uk-padding-small">
                                        <label className="uk-form-label">Account Number</label>
                                        <input className="uk-input"
                                            type="text" {...register("wire_account_number")} />
                                    </div>
                                    <div className="one-column sm-full-width uk-padding-small">
                                        <label className="uk-form-label">Routing Number</label>
                                        <input className="uk-input"
                                            type="text" {...register("wire_routing_number")} />
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className="one-column sm-full-width uk-padding-small">
                                        <label className="uk-form-label">SWIFT Code</label>
                                        <input className="uk-input"
                                            type="text" {...register("wire_swift_code")} />
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className="one-column sm-full-width uk-padding-small">
                                        <label className="uk-form-label">Bank Name</label>
                                        <input className="uk-input"
                                            type="text" {...register("wire_bank_name")} />
                                    </div>
                                    <div className="one-column sm-full-width uk-padding-small">
                                        <label className="uk-form-label">Bank Contact</label>
                                        <input className="uk-input"
                                            type="text" {...register("wire_bank_contact")} />
                                    </div>
                                    <div className="one-column sm-full-width uk-padding-small">
                                        <label className="uk-form-label">Bank Phone</label>
                                        <input type="hidden" name="wire_bank_phone" {...register("wire_bank_phone")} />
                                        <PhoneInput
                                            placeholder='+'
                                            country={'us'}
                                            inputClass={classes.phone_input}
                                            containerClass={classes.phone_container}
                                            autoFocus={false}
                                            onFocus={() => { }}
                                            value={getValues("wire_bank_phone")}
                                            onChange={phone => { setValue('wire_bank_phone', phone) }}
                                        />
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className="full-column uk-padding-small">
                                        <label className="uk-form-label">Bank Address</label>
                                        <input className="uk-input"
                                            type="text" {...register("wire_bank_address")} />
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className="one-column sm-full-width uk-padding-small">
                                        <label className="uk-form-label">Brokerage Contact</label>
                                        <input className="uk-input"
                                            type="text" {...register("wire_brokerage_contact")} />
                                    </div>
                                    <div className="one-column sm-full-width uk-padding-small">
                                        <label className="uk-form-label">Work Phone</label>
                                        <input className="uk-input"
                                            type="hidden" {...register("wire_work_phone")} />
                                        <PhoneInput
                                            placeholder='+'
                                            country={'us'}
                                            inputClass={classes.phone_input}
                                            containerClass={classes.phone_container}
                                            autoFocus={false}
                                            onFocus={() => { }}
                                            value={getValues("wire_work_phone")}
                                            onChange={phone => { setValue('wire_work_phone', phone) }}
                                        />
                                    </div>
                                </FormRow>
                                <FormRow>
                                    <div className="full-column uk-padding-small">
                                        <label className="uk-form-label">Notes/Instructions</label>
                                        <textarea type="text" rows='4' className="uk-textarea" {...register("wire_instructions")} />
                                    </div>
                                </FormRow>
                            </Fragment>
                        }
                        <FormRow>
                            <SubmitButton>Save</SubmitButton>
                        </FormRow>
                    </Form>
                </div>
            </div>
            {show && currentVideo && <VideoPlayerModal show={show} onClose={onCloseVideoPlayerModal} videoTitle={currentVideo} />}
        </div >
    );

}

export default BrokeragePage;