import DashboardService from "../../services/DasahboardService";
import {
  GET_DEALS_BY_BROKER,
  GET_DEALS_BY_OFFICE,
  GET_DEALS_BY_STATUS,
  GET_DEALS_BY_CLOSED_YEAR_TO_DATE,
  GET_DEALS_BY_UPCOMING_CLOSINGS,
  GET_LISTINGS_BY_BROKER,
  GET_LISTINGS_BY_OFFICE,
  GET_LISTINGS_BY_STATUS,
} from "./types";

export const getDealsByStatus = () => async (dispatch) => {
  try {
    const result = await DashboardService.getDealsByStatus();

    dispatch({
      type: GET_DEALS_BY_STATUS,
      payload: result.data,
    });

    return result.data;
  } catch (e) {
    return e;
  }
};

export const getDealsByUpcomingClosings = () => async (dispatch) => {
  try {
    const result = await DashboardService.getDealsByUpcomingClosings();

    dispatch({
      type: GET_DEALS_BY_UPCOMING_CLOSINGS,
      payload: result.data,
    });

    return result.data;
  } catch (e) {
    return e;
  }
};

export const getDealsByYTD = () => async (dispatch) => {
  try {
    const result = await DashboardService.getDealsByYTD();

    dispatch({
      type: GET_DEALS_BY_CLOSED_YEAR_TO_DATE,
      payload: result.data,
    });

    return result.data;
  } catch (e) {
    return e;
  }
}

export const getDealsByOffice = () => async (dispatch) => {
  try {
    const result = await DashboardService.getDealsByOffice();

    dispatch({
      type: GET_DEALS_BY_OFFICE,
      payload: result.data,
    });

    return result.data;
  } catch (e) {
    return e;
  }
};

export const getDealsByBroker = () => async (dispatch) => {
  try {
    const result = await DashboardService.getDealsByBroker();

    dispatch({
      type: GET_DEALS_BY_BROKER,
      payload: result.data,
    });

    return result.data;
  } catch (e) {
    return e;
  }
};

export const getListingsByStatus = () => async (dispatch) => {
  try {
    const result = await DashboardService.getListingsByStatus();

    dispatch({
      type: GET_LISTINGS_BY_STATUS,
      payload: result.data,
    });

    return result.data;
  } catch (e) {
    return e;
  }
};

export const getListingsByOffice = () => async (dispatch) => {
  try {
    const result = await DashboardService.getListingsByOffice();

    dispatch({
      type: GET_LISTINGS_BY_OFFICE,
      payload: result.data,
    });

    return result.data;
  } catch (e) {
    return e;
  }
};

export const getListingsByBroker = () => async (dispatch) => {
  try {
    const result = await DashboardService.getListingsByBroker();

    dispatch({
      type: GET_LISTINGS_BY_BROKER,
      payload: result.data,
    });

    return result.data;
  } catch (e) {
    return e;
  }
};
