import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormPage from "../../../../common/FormPage";
import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import AsterixTypo from "../../../../common/AsterixTypo";
import SubmitButton from "../../../../common/buttons/SubmitButton";
import LinkButton from "../../../../common/buttons/LinkButton";
import {
  getTransactionEdocById,
  getTransactionEsignById,
  upsertTransaction,
} from "../../../../../redux/actions/transaction";
import { getFormattedData, getPlainTransactionData } from "../../../../../utils/stepFilter";
import { useQueryParams } from "../../../../../utils/query-params";

const schema = yup.object().shape({
  ammendment: yup.string(),
});

const AddAddendum = ({ transactionId }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const query = useQueryParams();

  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const dataListing = dataTransaction || {};
  const dataAddendum = dataListing.t_purchase_addendum;

  const [addendum, setAddendum] = useState({});

  const keys = ["ammendment_1", "ammendment_2", "ammendment_3", "ammendment_4", "ammendment_5"];
  const doc_id = query.get('doc_id');

  useEffect(() => {
    const doc_id = query.get('doc_id');
    const ammendment = dataAddendum?.find(el => el.t_edocument_id === doc_id);
    if (ammendment) {
      setAddendum(ammendment);
      ammendment.ammendment.split("/:/").forEach((str, index) => {
        setValue(`ammendment_${index + 1}`, str);
      });
      setValue('addendum_no', ammendment.addendum_no);
    }
  }, [dataAddendum, query]);

  /* Form Validation */
  const { register, handleSubmit, setValue, getValues } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const handleRegisteration = (ammendment, isContinue = true) => {
    let dataStr = "";
    ammendment = getFormattedData(ammendment);
    keys.forEach((key) => {
      dataStr = dataStr.concat(ammendment[key] || "", "/:/");
    });
    dataStr = dataStr.slice(0, -3);

    let req_data = {};
    req_data.ammendment = dataStr;
    req_data.addendum_no = getValues('addendum_no');
    if (doc_id) {
      req_data.id = doc_id
    }
    dispatch(
      upsertTransaction({
        step: {
          db: "listing_esign_addendum",
          main: req_data,
        },
        transaction: {
          isBelonged: false,
          main: { ...getPlainTransactionData(dataListing), task_type: "deal" },
        },
      } ,"Listing Esign Addendum")
    ).then((data) => {
      dispatch(getTransactionEdocById(data.id));
      dispatch(getTransactionEsignById(data.id));
      if (isContinue) {
        history.push(
          `/listing-agreement/view/${transactionId}/listing-agreement-addendum?doc_id=${data.relatedId}`
        );
      }
    });
  };

  const handleError = () => { };

  const links = [
    {
      label: "Listing Agreement",
      link: `/listing-agreement/view/${transactionId}/main`,
    },
    { label: "Add a Listing Agreement Addendum", link: null },
  ];

  const vesselName = `${dataTransaction?.t_vessel?.year || ""} ${dataTransaction?.t_vessel?.make || ""} ${dataTransaction?.t_vessel?.model || ""} ${dataTransaction?.t_vessel?.length || ""}`;
  const uniqueTransactionId = dataTransaction?.unique_transaction_id ? `LA-${dataTransaction?.unique_transaction_id}` : '';

  return (
    <FormPage links={links} title="Addendum" vesselName={vesselName} uniqueId={uniqueTransactionId}>
      <Form onSubmit={handleSubmit(handleRegisteration, handleError)}>
        <FormRow>
          <FormInputWrapper label="Addendum Number *">
            <input className="uk-input" type="text" {...register("addendum_no")} />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <p>This Listing Agreement is subject to the following additional provisions:</p>
          <FormInputWrapper label="Provision #1" size="two">
            <textarea type="text" className="uk-input" {...register("ammendment_1")} />
          </FormInputWrapper>
          <FormInputWrapper label="Provision #2" size="two">
            <textarea type="text" className="uk-input" {...register("ammendment_2")} />
          </FormInputWrapper>
          <FormInputWrapper label="Provision #3" size="two">
            <textarea type="text" className="uk-input" {...register("ammendment_3")} />
          </FormInputWrapper>
          <FormInputWrapper label="Provision #4" size="two">
            <textarea type="text" className="uk-input" {...register("ammendment_4")} />
          </FormInputWrapper>
          <FormInputWrapper label="Provision #5" size="two">
            <textarea type="text" className="uk-input" {...register("ammendment_5")} />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <SubmitButton>Save & Continue</SubmitButton>
          <LinkButton
            to={`/listing-agreement/view/${transactionId}/main`}
            onClick={() => handleRegisteration(getValues(), false)}
          >
            Save & Finish Later
          </LinkButton>
        </FormRow>
        <AsterixTypo isVisible={false} />
      </Form>
    </FormPage>
  );
};

export default AddAddendum;
