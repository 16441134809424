import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import "./Header.css";
import Swal from "sweetalert2";
import { getPrice } from "../../../utils/currency";

const ESignHeader = (props) => {

  const isInitial = useMemo(() => {
    return props?.reqFields.find(el => el.type === 'PDFInitial') ? true : false;
  }, [props.reqFields]);

  const [defaultResetValue, setDefaultResetValue] = useState({
    price: 0,
    terms: ''
  });

  const [hasReset, setHasReset] = useState(false);

  useEffect(() => {
    setDefaultResetValue(() => {
      if (props.resetData?.isReset) {
        return {
          price: props.resetData.price,
          terms: props.resetData.terms || ''
        }
      }
      return {
        price: props.templateData.purchase_price,
        terms: props.templateData.terms || ''
      }
    })
  }, [props.resetData])


  const onResetclick = async () => {
    const { value: formValues } = await Swal.fire({
      title: "Counter Offer",
      // icon: "warning",
      allowOutsideClick: false,
      html: `
      <p class="swal_title">Please revise to represent your offer below.</p>
        <div class="swal-block">
          <label class="uk-form-label">Total Purchase Price</label>
          <input id="swal-input1" class="swal2-input" type="text" value="${defaultResetValue.price || ''}" placeholder="Purchase Price" /><br />
          <label for="swal-input2" class="uk-form-label">Additional Terms</label>
          <textarea id="swal-input2" class="swal2-textarea" rows="7" placeholder="Additional Terms">${defaultResetValue.terms}</textarea>
        </div>
      `,
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      focusConfirm: false,
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value
        ];
      }
    });
    if (formValues && formValues[0]) {
      Swal.fire({
        icon: "warning",
        title: 'Your document will reset with your updated information - make take a few seconds to update.',
        confirmButtonText: 'Proceed to Initial & Sign to submit your counter offer',
        confirmButtonColor: '#1ea2d5',
        allowOutsideClick: false
      }).then((response) => {
        props.handleReset(formValues);
        setHasReset(true);
      });
    }
  }

  return (
    <div className="header">
      <div className="esign_header">
        <a className="uk-logo p-2" >
          <img src="/logo.png" alt="" />
        </a>
      </div>

      <div className={!props.isHandSigned ? "esign_toolbar" : "onlyDownloadbtnDiv esign_toolbar"}>
        {!props.isHandSigned &&
          <>
            <div style={{ width: "340px" }}>
              <small>
                <b>Signer:</b> {props.templateData?.request_signer?.name} <br />
                <b>Email:</b> {props.templateData?.request_signer?.email_address} <br />
                <b>IP:</b> {props.templateData?.ip}
              </small>
            </div>
            {props.reqFields.length === 0
              ? <span>Please click "Complete" to confirm and finalize your signatures.</span>
              : <div style={{ position: "relative", right: "1.45%" }}>
                <button
                  className="btn btn-continue"
                  onClick={props.scrollNext}>
                  {props.touched ? 'Next' : 'Initial & Sign'}
                </button>
                {(['IYBA Purchase & Sale - Make Offer'].includes(props.templateData.template_name) && ['Seller', 'Listing-Broker', 'Selling-Broker'].includes(props.templateData.role) && !hasReset) &&
                  <button
                    className="btn btn-danger ml-3"
                    onClick={onResetclick}>
                    Counter Offer
                  </button>
                }
              </div>
            }
          </>
        }
        <div>
          <button
            className={!props.isHandSigned ? `btn uk-button uk-button-default downloadBtn` : `btn uk-button uk-button-default downloadBtn onlyDownloadbtn`}
            onClick={props.downloadDocument}>
            <span uk-icon="download" className="downloadIcon"></span>
            Download
          </button>
          {!props.isHandSigned &&
            <>
              <button
                className="btn btn-dark btn_reject"
                onClick={props.handleReject}>Reject
              </button>
              <button
                className="btn btn-continue"
                disabled={props.reqFields.length !== 0}
                onClick={props.handleComplete}>Complete
              </button>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default ESignHeader;
