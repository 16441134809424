import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { DebounceInput } from 'react-debounce-input'
// import Switch from "react-switch";
import { convertUnderscoreSeparateLetter } from '../../utils/functions';
import datatableStyles from '../../utils/datatable-style';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { setLoadingStatus } from '../../redux/actions/loading';
import { findTemplateByName } from '../../redux/actions/document_tasks';
import { useDispatch, useSelector } from 'react-redux';
import TemplatesService from '../../services/TemplatesService';
import StyleSwitch from '../common/buttons/StyleSwitch';
import { Tab, Tabs } from '@mui/material';
import TransactionService from '../../services/TransactionService';
import { toast } from 'react-toastify';
import Auth from '../../utils/auth';
import { Tooltip } from "@mui/material";
import Swal from 'sweetalert2';
import LibraryEditTable from './LibraryEditTable';


const LibraryManagerList = () => {
    // const dataTemplates = useSelector((state) => state.templates.all);
    const [dataTemplates, setDataTemplates] = useState([]);
    const [transactionType, setTransactionType] = useState('Deal')
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loading.status);
    const [isEdit, setIsEdit] = useState(false);

    // dataForUpdateListOrder State Will Contain Updated Data With Updated SortOrder Which Will Use In updateOrder's Api Payload
    const [dataForUpdateFormListOrder, setDataForUpdateFormListOrder] = useState([]);

    //Make a state for search
    const [search, setSearch] = useState({});

    const loggedUser = Auth.getInstance().getUserData();

    const userTypes = {
        "single": "Single Use",
        "multi_select": "Multi Select",
        "multiple": "Multi Use"
    }

    useEffect(() => {
        if (!isEdit) {
            fetchData(transactionType)
        }

    }, [search, transactionType, isEdit]);

    const fetchData = (type) => {
        dispatch(setLoadingStatus(true));
        TemplatesService.getCompanyTemplate(loggedUser?.company_id, type, isEdit ? "" : search)
            .then((res) => {

                setDataTemplates(res.data.templates)
                dispatch(setLoadingStatus(false))
            }
            )
            .catch((err) => dispatch(setLoadingStatus(false)))
    }

    // updateListOrder function made for call updateListOrder's api which will update sortOrder as per change
    const updateFormListOrder = async (data, companyId, type) => {
        dispatch(setLoadingStatus(true));
        try {
            await TemplatesService.updateCompanyFormOrder({ sort_choice: data, company_id: companyId, form_type: type });
            // dispatch(setLoadingStatus(false));
        } catch (error) {
            dispatch(setLoadingStatus(false));
            toast.error("Error, While update sort order", {
                autoClose: 2000
            })
        }
    }

    // function for handle click of updateFormList Order
    const handleUpdateFormListOrder = async (isCancel = false) => {
        // if there is any changes in sort order then only call updateListOrder's Api
        if ((!isCancel) && isEdit && dataForUpdateFormListOrder.length > 0) {
            await updateFormListOrder(dataForUpdateFormListOrder, loggedUser.company_id, transactionType);
        }
        setIsEdit(!isEdit);
        setDataForUpdateFormListOrder([]);
    }

    const updateUseField = async (row) => {
        dispatch(setLoadingStatus(true));
        try {
            let updateData = {
                "use": !row?.use,
            }
            TemplatesService.updateCompanyTemplate(row.id, updateData)
                .then((res) => {
                    if (res.data.use) {

                        toast.success(` The ${res.data.template} template is activated for the upcoming ${res.data.type === "deal" ? "deals" : "listing agreements"}.`)

                    } else {
                        toast.info(`The ${res.data.template} template is deactivated for the upcoming ${res.data.type === "deal" ? "Deals" : "Listing Agreements"}.`)

                    }
                    fetchData(transactionType)
                    dispatch(setLoadingStatus(false))
                }
                )
                .catch((err) => dispatch(setLoadingStatus(false)))
        } catch (error) {
            dispatch(setLoadingStatus(false));
            console.log(error);
            toast.error("Error occurs, please try again.");
        }
    }

    //Function for handle sort
    const handleSort = (column, sortDirection) => {
        // column.selector, sortDirection
        setSearch((search) => {
            return {
                ...search,
                sortOrder: sortDirection,
                sortField: column.selector
            }
        })
    };

    //Function for handle sort
    const handleSearch = (name) => {
        // column.selector, sortDirection
        setSearch((search) => {
            return {
                ...search,
                name: name
            }
        })
    };
    const columns = [
        {
            name: "Order",
            selector: "sort_order",
            width: "80px",
            sortable: true,
            cell: (row) => (
                <div data-tag="allowRowEvents">
                    <div>
                        {
                            row?.sort_order + 1 || " "
                        }
                    </div>
                </div>
            ),
        },
        {
            name: "USE",
            width: "130px",
            center: true,
            cell: (row) => (
                <label className='m-0'>
                    <StyleSwitch
                        checked={row.use}
                        onChange={(e) => {
                            updateUseField(row)
                        }}
                    />
                </label>

            ),
        },
        {
            name: "Template Name",
            selector: "template_name",
            grow: 2,
            sortable: true,
            cell: (row) => (
                <div data-tag="allowRowEvents">
                    <div>
                        {
                            row?.template?.template_name ? row?.template?.template_name?.replace(',', '/') : "N/A"
                        }
                    </div>
                </div>
            ),
        },
        {
            name: "Association",
            cell: (row) => (
                <div data-tag="allowRowEvents">
                    <div>
                        {
                            row?.template?.association || "N/A"
                        }
                    </div>
                </div>
            ),
        },
        {
            name: "Use Type",
            cell: (row) => (
                <div data-tag="allowRowEvents">
                    <div>
                        {
                            userTypes[row?.template?.use_type] || ""
                        }
                    </div>
                </div>
            ),
        },
        {
            name: "Hand Signed Only",
            center: true,
            cell: (row) => (
                <div data-tag="allowRowEvents">
                    <div>
                        {
                            row && row?.template?.is_hand_signed ? "Yes" : "No"
                        }
                    </div>
                </div>
            ),
        },
        {
            name: "View Blank PDF",
            center: true,
            grow: 2,
            cell: (row) => (
                <div>
                    <div onClick={(() => {
                        dispatch(setLoadingStatus(true));
                        TransactionService.getPdfLink(row.template_id)
                            .then((res) => {
                                dispatch(setLoadingStatus(false));
                                if (res?.data?.url) {
                                    window.open(`${res.data?.url}`, '_blank');
                                } else {
                                    toast.error("Error occurs, please try again.", {
                                        autoClose: 2000
                                    })
                                }
                            }).catch((err) => {
                                dispatch(setLoadingStatus(false));
                            })
                    })} style={{ cursor: "pointer" }}>
                        <Tooltip title="Preview Document" placement="right"> <img src="/icons/view.svg" className="svg" alt='preview' /> </Tooltip>
                    </div>
                </div>
            ),
        },
    ];

    const handelLogType = (event, newValue) => {
        handleSearch('')
        setTransactionType(newValue)
    }
    // return(<></>)
    return (
        <div className="uk-container uk-container-expand uk-position-relative">
            <div className=''>
                <h1 className="d-flex align-items-center gap-1">Form Library Manager</h1>
            </div>
            <div className="forms-nav-actions">


                <button className={`uk-button ${isEdit ? "uk-button-primary" : "uk-button-default"}`} style={(!isEdit) ? { width: "6vw", margin: "0px" } : {margin:"0px"}} disabled={search.name} onClick={() => handleUpdateFormListOrder()}>
                    {isEdit ? "Save Sort" : "Edit Sort"}
                </button>
                {isEdit && <button className="uk-button uk-button-orange" onClick={() => handleUpdateFormListOrder(true)}>
                    Cancel
                </button>}

                {!isEdit && <div className='d-flex justify-content-between w-100'>
                    <Tabs value={transactionType} indicatorColor="primary" onChange={handelLogType}>
                        <Tab value="Deal" label="Deals" />
                        <Tab value="Listing" label="Listing Agreements" />
                    </Tabs>
                    <div className="list-page-search-container search-block">
                        <form className="uk-search uk-search-default">
                            <DebounceInput
                                className="uk-search-input"
                                type="search"
                                placeholder="Search"
                                onChange={(e) => handleSearch(e.target.value)}
                                value={search.name}
                                debounceTimeout={500}
                                minLength={1}
                            />
                            {!search.name ? (
                                <span className="bd-form-icon">
                                    <i className="fa fa-search"></i>
                                </span>
                            ) : (
                                <span className="bd-form-icon" onClick={() => handleSearch('')}>
                                    <i className="fa fa-times"></i>
                                </span>
                            )}
                        </form>
                    </div>
                </div>}
            </div>
            {isEdit ?
                <><LibraryEditTable data={dataTemplates} companyId={loggedUser.company_id} type={transactionType} setDataForUpdateFormListOrder={setDataForUpdateFormListOrder} /></>
                : <>{!dataTemplates.length && isLoading
                    ? <div className="no-data-table"><div className="p-4">Fetching Library...</div></div>
                    : <DataTable
                        columns={columns}
                        data={dataTemplates}
                        highlightOnHover={true}
                        striped={true}
                        noHeader={true}
                        onSort={handleSort}
                        customStyles={datatableStyles}
                    />
                }</>}

        </div>
    )
}

export default LibraryManagerList