import { Helmet } from "react-helmet";
import ActiveListingsByBroker from "./tables/ActiveListingsByBroker";
import ActiveListingsByOffice from "./tables/ActiveListingsByOffice";
import AnalyticDeals from "./tables/AnalyticDeals";
import AnalyticListings from "./tables/AnalyticListings";
import DealsByBroker from "./tables/DealsByBroker";
import DealsByOffice from "./tables/DealsByOffice";
import DealsByYTD from "./tables/DealsByYTD";
import UpcomingClosings from "./tables/UpcomingClosings";
import Auth from '../../utils/auth';

const DashboardPage = () => {
  const loggedUser = Auth.getInstance().getUserData();
  const user_role_name = loggedUser?.UserInRole?.UserRole?.name;

  return (
    <div className="dashboard-container">
      <Helmet>
        <title>Dashboard - BoatDox</title>
      </Helmet>
      <div className="uk-container uk-container-expand uk-position-relative">
        <div className="content">
          <h1>Dashboard</h1>

          <div className="dashboard">
            <div className="dashboard-row">
              <div className="dashboard-col wide ">
                <h3>Deals</h3>

                <AnalyticDeals />
              </div>
            </div>
            <div className="dashboard-row">
              <div className="dashboard-col wide">
                <h3>Upcoming Closings</h3>

                <UpcomingClosings />
              </div>
            </div>
            <div className="dashboard-row">
              <div className="dashboard-col wide">
                <h3>Deals Closed YTD</h3>
                <DealsByYTD />
              </div>
            </div>
            {(user_role_name !== 'Broker' && user_role_name !== 'Broker Assistant') && (
              <>
                <div className="dashboard-row">
                  <div className="dashboard-col wide">
                    <h3>Deals Closing By Office (next 15 days)</h3>
                    <p>
                      Top 3 offices chosen by value of boats (Total Sales Price) that are scheduled to
                      close in next 15 days (Closing date from P&SA)
                    </p>

                    <DealsByOffice />
                  </div>
                </div>

                <div className="dashboard-row">
                  <div className="dashboard-col wide">
                    <h3>Deals Closing by Broker (next 15 days)</h3>
                    <p>
                      Top 3 brokers chosen by value of boats (Total Sales Price) that are scheduled to
                      close in next 15 days (Closing date from P&SA)
                    </p>

                    <DealsByBroker />
                  </div>
                </div>
              </>
            )}
            <div className="dashboard-row">
              <div className="dashboard-col wide">
                <h3>Listings</h3>

                <AnalyticListings />
              </div>
            </div>
            {(user_role_name !== 'Broker' && user_role_name !== 'Broker Assistant') && (
              <>
                <div className="dashboard-row">
                  <div className="dashboard-col wide">
                    <h3>Active Listings By Office</h3>
                    <p>
                      Top 3 offices chosen by value of boats (Total Asking Price) that are currently
                      Active Listings by that office
                    </p>
                    <ActiveListingsByOffice />
                  </div>
                  <div className="dashboard-col wide">
                    <h3>Active Listings By Broker</h3>
                    <p>
                      Top 3 brokers chosen by value of boats (Total Asking Price) of Active Listings
                      that they currently have.
                    </p>
                    <ActiveListingsByBroker />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
