import OffCanvasContainer from "../../../../common/OffCanvasContainer";
import FormRow from "../../../../common/FormRow";
import ModalBody from "../../../../common/ModalBody";

const ViewAddendumForm = ({ addendum }) => {
  const ammendment = addendum ? addendum.ammendment : "";
  const data = ammendment.split("/:/");
  return (
    <OffCanvasContainer id="modal-addendum">
      <ModalBody title="Addendum">
        <FormRow>
          {data.map((d, index) => (
            <p className="mt20" key={`k${index}`}>
              <span className="uk-form-label">{`Item ${index + 1}:`}</span>
              <span className="input-view">{`${d}`}</span>
            </p>
          ))}
        </FormRow>
      </ModalBody>
    </OffCanvasContainer>
  );
};

export default ViewAddendumForm;
