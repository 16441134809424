import { useEffect } from "react";
import reactDom from "react-dom";

const el = document.createElement("div");

const RequiredFieldPortal = ({ children }) => {
    const portalRoot = document.getElementById("root");
    el.setAttribute("data-uk-modal", "true");
    el.id = "modal-required-field";

    useEffect(() => {
        portalRoot.appendChild(el);
    }, []);
    return reactDom.createPortal(children, el);
};

export default RequiredFieldPortal;
