import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import clsx from "clsx";
import PageSidebarContainer from "../../../../common/PageSidebarContainer";
import { updateOptionsRequiredFields, updateRequiredFields } from "../../../../../redux/actions/transaction";
import {
  CORPORATE_OWNED,
  ENTITY_AND_OR_ASSIGN,
  JOINT_OWNER,
  LEGAL_AND_OR_ASSIGN,
  LEGAL_OR_ASSIGN,
  LEGAL_OWNER,
  LLC_OWNED,
  OWNER_OF_RECORD,
  RECORD_OWNER,
  TRUST_OWNED,
} from "../../../../../utils/signer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BillNotaryStep = () => {
  let location = useLocation();
  let { id } = useParams();
  const dispatch = useDispatch();
  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const [asterix, setAsterix] = useState("empty");

  const history = useHistory();

  const dataDeal = dataTransaction || {};
  const dataVessel = dataDeal.t_vessel || {};
  const dataBuyer = dataDeal.buyer;
  const dataCoBuyer = dataDeal.co_buyer;
  const dataSeller = dataDeal.seller;
  const dataCoSeller = dataDeal.co_seller;

  let requiredFields = [
    { engine_make: "Engine Make" },
    { engine_model: "Engine Model" },
    { engine_hp: "Engine HP" },
    { engine_serial_1: "Engine Serial 1" },
  ];

  const buyerFields = {
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    mobile_phone: "Mobile Phone",
  };
  const coBuyerFields = {
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    mobile_phone: "Mobile Phone",
  };
  const sellerFields = {
    // owner_or_legal: "Title",
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    mobile_phone: "Mobile Phone",
  };
  const coSellerFields = {
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    mobile_phone: "Mobile Phone",
  };
  const groupFields = {
    trust_name: "Corporation/LLC/Trust Name",
    trust_tax_id: "Corporation/LLC/Trust Tax ID",
    trust_address: "Corporation/LLC/Trust Address",
    trust_city: "Corporation/LLC/Trust City",
    trust_state: "Corporation/LLC/Trust State",
    trust_signer_name: "Corporation/LLC/Trust Name of Signer",
    trust_signer_email: "Corporation/LLC/Trust Email",
    trust_signer_title: "Corporation/LLC/Trust Title of Signer",
  }

  let buyerRequireds = [];
  let coBuyerRequireds = [];
  let sellerRequireds = [];
  let coSellerRequireds = [];

  useEffect(() => {
    let notfilledFields = [];

    if (dataVessel) {
      requiredFields.forEach((rf) => {
        if (!dataVessel[Object.keys(rf)[0]]) {
          notfilledFields.push(rf);
        }
      });
      if (notfilledFields.length === 5) {
        setAsterix("empty");
      } else if (notfilledFields.length === 0) {
        setAsterix("fulfill");
      } else {
        setAsterix("notFulfill");
      }
    } else {
      setAsterix("empty");
    }

    if ([ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataDeal?.buyer_type) > -1) {
      Object.keys(groupFields).forEach((key) => {
        if (!dataBuyer[key]) {
          buyerRequireds.push({ [key]: groupFields[key] });
        }
      });
    } else if ([LEGAL_OWNER, JOINT_OWNER, LEGAL_OR_ASSIGN, LEGAL_AND_OR_ASSIGN].indexOf(dataDeal?.buyer_type) > -1) {
      Object.keys(buyerFields).forEach((key) => {
        if (!dataBuyer[key]) {
          buyerRequireds.push({ [key]: buyerFields[key] });
        }
      });
    } else {
      buyerRequireds.push({ title: 'Buyer Role' });
    }

    if (dataCoBuyer) {
      Object.keys(coBuyerFields).forEach((key) => {
        if (!dataCoBuyer[key]) {
          coBuyerRequireds.push({ [key]: coBuyerFields[key] });
        }
      });
    }

    if ([TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataDeal?.seller_type) > -1) {
      Object.keys(groupFields).forEach((key) => {
        if (!dataSeller[key]) {
          sellerRequireds.push({ [key]: groupFields[key] });
        }
      });
    } else if ([LEGAL_OWNER, JOINT_OWNER].indexOf(dataDeal?.seller_type) > -1) {
      Object.keys(sellerFields).forEach((key) => {
        if (!dataSeller[key]) {
          sellerRequireds.push({ [key]: sellerFields[key] });
        }
      });
    } else if ([RECORD_OWNER, OWNER_OF_RECORD].indexOf(dataDeal?.seller_type) > -1) {
      if (!dataDeal['seller_type']) sellerRequireds.push({ title: 'Seller Role' });
    } else {
      sellerRequireds.push({ title: 'Seller Role' });
    }

    if (dataCoSeller) {
      Object.keys(coSellerFields).forEach((key) => {
        if (!dataCoSeller[key]) {
          coSellerRequireds.push({ [key]: coSellerFields[key] });
        }
      });
    }

    dispatch(
      updateOptionsRequiredFields({
        billOfSale: notfilledFields,
      })
    );

    dispatch(
      updateRequiredFields({
        buyer: buyerRequireds,
        co_buyer: coBuyerRequireds,
        seller: sellerRequireds,
        co_seller: coSellerRequireds,
      })
    )
  }, [dataTransaction]);

  let branch = location.pathname.split("/").pop();

  return (
    <PageSidebarContainer type="add-deal">
      <div className="sidebar-container add-deal">
        <ul className="uk-nav uk-nav-default tm-nav">
          {/* <Link to={`/deals/view/${id}/bill-of-sale---notary/send_to_signer`}>
            <li className={clsx("add-deal chain-step", { current: branch === "send_to_signer" })}>
              <i className="fa fa-circle-o"></i>
              <span className="add-deal-step">Send for eSignature</span>
            </li>
          </Link> */}

          {
            dataTransaction?.id &&
            // <li
            //   name="back-to-eDocuments"
            //   id="tab-back-to-eDocuments"
            //   className={clsx("add-deal chain-step")}
            // >
            //   <Link to={`/deals/view/${dataTransaction.id}/main#tab=%22edocuments%22`}> <span uk-icon="chevron-double-left"></span> Back to eDocuments</Link>
            // </li>

            <button className="uk-button uk-button-primary mt-2" onClick={() => history.push(`/deals/view/${dataTransaction.id}/main#tab=%22edocuments%22`)}>
              <span uk-icon="chevron-double-left"></span>Back to eDocuments
            </button>

          }

        </ul>
      </div>
    </PageSidebarContainer>
  );
};

export default BillNotaryStep;
