import GridForm from "../../../../common/GridForm";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";

const DetailOfVessel = ({ vessel }) => {
  vessel = vessel || {};
  let vessel_title = `${vessel.length || ""} ${vessel.make || ""} ${vessel.model || ""} ${
    vessel.year || ""
  }`;
  vessel_title = vessel_title === "   " ? "N/A" : vessel_title;

  return (
    <GridForm>
      <FormRow>
        <FormInputWrapper label="Hull Number">
          <div className="input-view">{vessel ? vessel.hull_number || "N/A" : "N/A"}</div>
        </FormInputWrapper>
        <FormInputWrapper size="two" label="Vessel">
          <div className="input-view">{vessel_title}</div>
        </FormInputWrapper>
      </FormRow>
      <FormRow>
        <FormInputWrapper size="two" label="Engine Description">
          <div className="input-view">{vessel ? vessel.description || "N/A" : "N/A"}</div>
        </FormInputWrapper>
        <FormInputWrapper label="Name">
          <div className="input-view">{vessel ? vessel.vessel_name || "N/A" : "N/A"}</div>
        </FormInputWrapper>
      </FormRow>
      <FormRow>
        <FormInputWrapper label="Flag">
          <div className="input-view">{vessel ? vessel.flag || "N/A" : "N/A"}</div>
        </FormInputWrapper>
        <FormInputWrapper label="Doc/Reg No.(Doc#)">
          <div className="input-view">{vessel ? vessel.doc_or_reg_no || "N/A" : "N/A"}</div>
        </FormInputWrapper>
      </FormRow>
    </GridForm>
  );
};

export default DetailOfVessel;
