import React, { useState, useEffect } from 'react';

export const AddSignersStep = props => {
  const [roles, setRoles] = useState(props.form.roles || ['']);

  useEffect(() => {
    props.update('roles', roles);
  }, [roles]);

  const handleAddSigner = () => {
    setRoles([...roles, '']);
  }

  const handleRemoveSigner = (index) => {
    setRoles(roles.filter((role, idx) => idx !== index));
  }

  const handleChangeTitle = (evt, index) => {
    setRoles(roles.map((role, idx) => idx === index ? evt.target.value : role));
  }

  return (
    <div className="container">
      <div className="col-12 container-box">
        <div className="col-10">
          <h4 style={{ margin: '30px 0 30px 0', fontWeight: '400' }}>Who needs to sign?</h4>
          <h5 style={{ margin: '30px 0 30px 0' }}>Add Signer roles</h5>
          {roles.map((role, index) => {
            return (
              <div style={{ padding: '15px', display: 'flex', flexDirection: 'column' }} key={index}>
                <span style={{ marginBottom: '5px' }}>Role title</span>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div>
                    <svg width="16px" height="24px" viewBox="0 0 16 24" fill="none" style={{ marginRight: '5px', color: '#959ca4' }}>
                      <path fillRule="evenodd" clipRule="evenodd" d="M7 4.5C7 5.32843 6.32843 6 5.5 6C4.67157 6 4 5.32843 4 4.5C4 3.67157 4.67157 3 5.5 3C6.32843 3 7 3.67157 7 4.5ZM12 4.5C12 5.32843 11.3284 6 10.5 6C9.67157 6 9 5.32843 9 4.5C9 3.67157 9.67157 3 10.5 3C11.3284 3 12 3.67157 12 4.5ZM5.5 11C6.32843 11 7 10.3284 7 9.5C7 8.67157 6.32843 8 5.5 8C4.67157 8 4 8.67157 4 9.5C4 10.3284 4.67157 11 5.5 11ZM12 9.5C12 10.3284 11.3284 11 10.5 11C9.67157 11 9 10.3284 9 9.5C9 8.67157 9.67157 8 10.5 8C11.3284 8 12 8.67157 12 9.5ZM5.5 16C6.32843 16 7 15.3284 7 14.5C7 13.6716 6.32843 13 5.5 13C4.67157 13 4 13.6716 4 14.5C4 15.3284 4.67157 16 5.5 16ZM7 19.5C7 20.3284 6.32843 21 5.5 21C4.67157 21 4 20.3284 4 19.5C4 18.6716 4.67157 18 5.5 18C6.32843 18 7 18.6716 7 19.5ZM10.5 16C11.3284 16 12 15.3284 12 14.5C12 13.6716 11.3284 13 10.5 13C9.67157 13 9 13.6716 9 14.5C9 15.3284 9.67157 16 10.5 16ZM12 19.5C12 20.3284 11.3284 21 10.5 21C9.67157 21 9 20.3284 9 19.5C9 18.6716 9.67157 18 10.5 18C11.3284 18 12 18.6716 12 19.5Z" fill="currentColor"></path>
                    </svg>
                  </div>
                  <input type="text" style={{ borderWidth: '1px', width: '100%', outline: 'none', padding: '5px 10px 5px 10px', border: '1px solid #adb5bd' }} onChange={(evt) => handleChangeTitle(evt, index)} value={role} />
                  <a data-qa-ref="remove-document" style={{ marginLeft: '5px' }} onClick={() => handleRemoveSigner(index)}>
                    <img src="https://cdn.hellosign.com/1.116.0/build/ae9ae8331a142bc9ce60901d10a41fc6.svg" style={{ width: '18px', height: '18px' }} />
                  </a>
                </div>
              </div>
            )
          })}
          <div style={{ padding: '15px', display: 'flex', flexDirection: 'column', color: '#727a83' }}>
            <button type="button" style={{ border: '1px dashed #959ca4', display: 'flex', alignItems: 'center', padding: '1px 15px 1px 15px' }} onClick={handleAddSigner}>
              <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" style={{ marginRight: '5px' }}>
                <path fillRule="evenodd" clipRule="evenodd" d="M13 3.75C13 3.33579 12.5523 3 12 3C11.4477 3 11 3.33579 11 3.75V11H3.75C3.33579 11 3 11.4477 3 12C3 12.5523 3.33579 13 3.75 13H11V20.25C11 20.6642 11.4477 21 12 21C12.5523 21 13 20.6642 13 20.25V13H20.25C20.6642 13 21 12.5523 21 12C21 11.4477 20.6642 11 20.25 11H13V3.75Z" fill="currentColor"></path>
              </svg>
              Add another role
            </button>
          </div>
          <hr />
        </div>
      </div>
    </div>
  )
};