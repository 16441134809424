import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { useDispatch } from 'react-redux';
import { setLoadingStatus } from '../../redux/actions/loading';
import UserService from '../../services/UserService';
import { toast } from 'react-toastify';

const ChangeUserPasswordModal = ({ show, user, onClose }) => {

    const [userData, setUserData] = useState(null);

    useEffect(() => {
        if (show && user.type == 'participant') {
            setUserData(user.user);
        } else {
            setUserData(user);
        }
    }, [show])


    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = useState(false);
    const [showRePassword, setShowRePassword] = useState(false);

    useEffect(() => {
        reset();
    }, [show]);

    const schema = yup.object().shape({
        password: yup
            .string()
            .required("Please enter your password")
            .matches(
                /^.*(?=.{8,})((?=.*\d){1})((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                "Password must contain at least 8 characters, one uppercase, one lowercase, and one number"
            ),
        confirm_password: yup.string()
            .required("Please enter your password")
            .matches(
                /^.*(?=.{8,})((?=.*\d){1})((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                "Password must contain at least 8 characters, one uppercase, one lowercase, and one number"
            ).oneOf([yup.ref('password'), null], 'Passwords must match')
    });

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            password: '',
            confirm_password: ''
        },
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true
    });

    const onSubmitForm = (data) => {
        if (data) {
            dispatch(setLoadingStatus(true));
            const api = userData.is_new_user
                ? UserService.setupPassword(userData.id, { email: userData.email, new_password: data.password })
                : UserService.resetUserPassword(userData.id, { email: userData.email, new_password: data.password });

            api.then(
                (response) => {
                    if (response.status == 200) {userData.is_new_user
                        ?toast.success('Password set successfully')
                        :toast.success('Password changed successfully')
                        dispatch(setLoadingStatus(false));
                        reset();
                        onClose(true);
                    }
                }
            ).catch(
                (error) => {
                    toast.error('Error while change user Password!');
                    dispatch(setLoadingStatus(false));
                    console.log(error);
                }
            )
        }
    }

    const handleError = (error) => {
        console.log("ERROR::", error);
    }

    return (
        <Modal
            show={show}
            onHide={() => onClose(false)}
            centered={true}
            size='lg'>
            <Modal.Header closeButton>
                <h3>{userData?.is_new_user ? 'Set' : 'Change'} Password</h3>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmitForm, handleError)}>
                <Modal.Body>
                    <div className='p-2'>
                        <div className="form-row pl-2">
                            <div className=" uk-padding-small">
                                <h4><img height='25px' width='25px' src="/icons/user.svg" alt="" />&nbsp;{userData?.first_name + " " + userData?.last_name}</h4>
                                <p className='ml-1'><img height='20px' width='20px' src="/icons/email.svg" alt="" />&nbsp;&nbsp;{userData?.email}</p>
                            </div>
                        </div>
                        <div className="form-row uk-grid-margin uk-first-column">
                            <div className="half-column uk-padding-small">
                                <label className="uk-form-label" htmlFor="name">New Password *</label>
                                <div className="input-group">
                                    <input className={clsx("uk-input rounded-pill-left form-control border border-right-0", { 'uk-form-danger': errors.password })} type={showPassword ? 'text' : 'password'} {...register("password")} autoComplete="new-password" />
                                    <button
                                        onClick={() => setShowPassword(!showPassword)}
                                        className="btn btn-outline-secondary rounded-pill-right border border-left-0"
                                        type="button">
                                        <i class={showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'} aria-label="Search Icon" role="search"></i>
                                    </button>
                                </div>
                                <span class="input-group-addon" role="button" title="veiw password" id="passBtn"></span>
                                <p className='text-danger'>{errors.password?.message}</p>
                            </div>
                            <div className="half-column uk-padding-small">
                                <div className="password"><label className="uk-form-label" htmlFor="name">Verify New Password *</label></div>
                                <div className="input-group">
                                    <input className={clsx("uk-input rounded-pill-left form-control border border-right-0", { 'uk-form-danger': errors.confirm_password })} type={showRePassword ? 'text' : 'password'} {...register("confirm_password")} />
                                    <button
                                        onClick={() => setShowRePassword(!showRePassword)}
                                        className="btn btn-outline-secondary rounded-pill-right border border-left-0"
                                        type="button">
                                        <i class={showRePassword ? 'fa fa-eye-slash' : 'fa fa-eye'} aria-label="Search Icon" role="search"></i>
                                    </button>
                                </div>
                                <p className='text-danger'>{errors.confirm_password?.message}</p>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="half-column uk-padding-small pl-4">
                                <ul>
                                    <li>Password must contain numbers</li>
                                    <li>Password must contain uppercase letters</li>
                                </ul>
                            </div>
                            <div className="half-column uk-padding-small pl-4">
                                <ul>
                                    <li>Length must be greater than 8 characters</li>
                                    <li>Password should not contain strings</li>
                                    <li>Password must not contain repetitions.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="uk-button uk-button-default uk-modal-close small" type="button" onClick={(e) => onClose(false)}>
                        Cancel
                    </button>
                    <button
                        className="uk-button uk-button-primary small"
                        type="submit">Submit</button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default ChangeUserPasswordModal;
