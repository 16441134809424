import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { object } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  getTransactionEsignById,
  upsertTransaction,
} from "../../../../../redux/actions/transaction";
import FormInputWrapper from "../../../../common/FormInputWapper";
import Form from "../../../../common/Form";
import FormPage from "../../../../common/FormPage";
import FormRow from "../../../../common/FormRow";
import SubmitButton from "../../../../common/buttons/SubmitButton";
import LinkButton from "../../../../common/buttons/LinkButton";
import { getFigure, getPrice } from "../../../../../utils/currency";
import { getPlainTransactionData } from "../../../../../utils/stepFilter";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const schema = object().shape({});

const figureFields = [
  "gross_sales_commission",
  "survey_allowance",
  "holdback_escrow",
  "balance_due_seller",
  "expense_total",
  "credit_total",
  "deposit_total",
  "disbursement_total",
];

const AddSellerTransactionDetails = ({ transactionId }) => {
  const { handleSubmit, setValue, getValues, register, watch } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const dataDeal = dataTransaction || {};
  const dataSellerClosing = dataDeal.t_seller_closing || {};
  const dataBuyerClosing = dataDeal.t_buyer_closing || {};
  const dataOffer = dataDeal.t_offers || {};
  const dataSellerPayoff = dataDeal.t_seller_payoff || {};
  const dataDetails = dataDeal.t_details || [];
  const dataCobroker = dataDeal.co_broker || {};
  const dataVesselAcceptance = dataDeal.t_acceptance || [];

  const dispatch = useDispatch();
  const history = useHistory();

  const getTotals = (details) => {
    let expenseTotal = 0;
    let creditTotal = 0;
    let depositTotal = 0;
    let disbursementTotal = 0;
    details.forEach((detail) => {
      const amount = getFigure(detail.amount);
      switch (detail.type) {
        case "expenses":
          expenseTotal += amount || 0;
          break;
        case "credits":
          creditTotal += amount || 0;
          break;
        case "deposits":
          depositTotal += amount || 0;
          break;
        case "disbursements":
          disbursementTotal += amount || 0;
          break;
        default:
          break;
      }
    });
    return { expenseTotal, creditTotal, depositTotal, disbursementTotal };
  };

  useEffect(() => {
    if (dataSellerClosing) {
      Object.keys(dataSellerClosing).map((key) => {
        if (figureFields.indexOf(key) > -1) {
          setValue(key, getPrice(dataSellerClosing[key]));
        } else {
          setValue(key, dataSellerClosing[key]);
        }
        setValue("survey_allowance", getPrice(dataSellerClosing?.survey_allowance || dataVesselAcceptance?.adjustment_amount));
        setValue("gross_sales_commission", getPrice(dataCobroker?.gross_commission_amount));
      });
    }
  }, [dataSellerClosing]);

  useEffect(() => {
    const details = dataDetails.filter((d) => d.document_type === "seller");
    const { expenseTotal, creditTotal, depositTotal, disbursementTotal } = getTotals(details);
    setValue("expense_total", getPrice(expenseTotal));
    setValue("credit_total", getPrice(creditTotal));
    setValue("deposit_total", getPrice(depositTotal));
    setValue("disbursement_total", getPrice(disbursementTotal));
  }, [dataDetails]);

  const handleBlur = (ref) => {
    const price = getPrice(getValues(ref));
    setValue(ref, price);
  };

  const handleFocus = (ref) => {
    const figure = getFigure(getValues(ref));
    setValue(ref, figure);
  };

  const handleRegistration = (detail, isFinishLater = false) => {
    Object.keys(detail).forEach((key) => {
      if (!detail[key]) {
        detail[key] = null;
      }
      if (figureFields.indexOf(key) > -1) {
        detail[key] = getFigure(detail[key]);
      }
    });
    dispatch(upsertTransaction({
      step: {
        db: "seller_closing",
        main: { ...detail, buyer_closing_id: dataBuyerClosing?.id ? dataBuyerClosing?.id : null },
      },
      transaction: {
        isBelonged: false,
        main: { ...getPlainTransactionData(dataDeal), task_type: "deal" },
      },
    },"Seller Closing")
    ).then((data) => {
      if (isFinishLater) {
        history.push(`/deals/view/${data.id}/main`);
      } else {
        dispatch(getTransactionEsignById(data.id));
        history.push(`/deals/view/${transactionId}/seller-closing-statement`);
      }
    });
  };

  const handleError = () => { };

  const links = [
    { label: "Deals", link: `/deals/view/${transactionId}/main` },
    { label: "Seller Closing Statement" },
  ];

  const purchase_total =
    (dataOffer.purchase_price || 0) + (dataOffer.purchase_price_additional_units || 0);
  const payoff = dataSellerPayoff.payoff || 0;

  useEffect(() => {
    let total = purchase_total || 0;
    let sales_commission = getFigure(getValues("gross_sales_commission")) || 0;
    let survey_allowance = getFigure(getValues("survey_allowance")) || 0;
    let holdback_escrow = getFigure(getValues("holdback_escrow")) || 0;

    let gross_proceeds = total - sales_commission - survey_allowance - holdback_escrow;
    setValue("gross_proceeds", getPrice(gross_proceeds));
  }, [watch(["holdback_escrow"]), watch("survey_allowance")]);

  useEffect(() => {
    let grossProceeds = getFigure(getValues("gross_proceeds")) || 0;
    let expenseAmount = getFigure(getValues("expense_total")) || 0;
    let subtotal = getPrice(grossProceeds - payoff - expenseAmount);

    setValue("subtotal", subtotal);
  }, [watch(["expense_total"])]);

  useEffect(() => {
    let { credit_total, deposit_total, subtotal } = getValues();
    let net_proceeds =
      (getFigure(subtotal) || 0) + (getFigure(credit_total) || 0) + (getFigure(deposit_total) || 0);

    setValue("net_proceeds", getPrice(net_proceeds));
  }, [watch(["credit_total", "deposit_total"])]);

  useEffect(() => {
    let { net_proceeds, disbursement_total } = getValues();
    let balance_due_seller = (getFigure(net_proceeds) || 0) - (getFigure(disbursement_total) || 0);
    setValue("balance_due_seller", getPrice(balance_due_seller));
  }, [watch(["disbursement_total"])]);

  const vesselName = `${dataTransaction?.t_vessel?.year || ""} ${dataTransaction?.t_vessel?.make || ""} ${dataTransaction?.t_vessel?.model || ""} ${dataTransaction?.t_vessel?.length || ""}`;
  const uniqueTransactionId = dataTransaction?.unique_transaction_id ? `PS-${dataTransaction?.unique_transaction_id}` : '';

  return (
    <FormPage links={links} title="Transaction Details" vesselName={vesselName} uniqueId={uniqueTransactionId}>
      <Form>
        <FormRow>
          <FormInputWrapper label="Contract Sales Price">
            <input className="uk-input" defaultValue={getPrice(purchase_total)} readOnly />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Gross Sales Commission *">
            <input
              className="uk-input"
              type="text"
              {...register("gross_sales_commission")}
              defaultValue={getPrice(dataCobroker?.gross_commission_amount)}
              readOnly
            />
          </FormInputWrapper>
          <FormInputWrapper label="Survey Allowance">
            <input
              className="uk-input"
              type="text"
              {...register("survey_allowance")}
              onBlur={() => handleBlur("survey_allowance")}
              onFocus={() => handleFocus("survey_allowance")}
              defaultValue={getPrice(dataVesselAcceptance?.adjustment_amount)}
            />
          </FormInputWrapper>
          <FormInputWrapper label="Holdback (Escrow)">
            <input
              className="uk-input"
              type="text"
              {...register("holdback_escrow")}
              onBlur={() => handleBlur("holdback_escrow")}
              onFocus={() => handleFocus("holdback_escrow")}
            />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Gross Proceeds">
            <input className="uk-input" type="text" {...register("gross_proceeds")} readOnly />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Payoff">
            <input className="uk-input" type="text" defaultValue={getPrice(payoff)} readOnly />
          </FormInputWrapper>
          <FormInputWrapper label="Expense Total">
            <input className="uk-input" type="text" {...register("expense_total")} readOnly />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Subtotal">
            <input className="uk-input" type="text" {...register("subtotal")} readOnly />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Credit Total">
            <input className="uk-input" type="text" {...register("credit_total")} readOnly />
          </FormInputWrapper>
          <FormInputWrapper label="Deposit Total">
            <input className="uk-input" type="text" {...register("deposit_total")} readOnly />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Net Proceeds">
            <input className="uk-input" type="text" {...register("net_proceeds")} readOnly />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Disbursement Total">
            <input className="uk-input" type="text" {...register("disbursement_total")} readOnly />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Balance Due Seller">
            <input className="uk-input" {...register("balance_due_seller")} readOnly />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <button type="button" className='uk-button uk-button-primary' onClick={() => handleRegistration(getValues(), false)}>Save & Continue</button>
          <button type="button" className='uk-button uk-button-default' onClick={() => handleRegistration(getValues(), true)}>
            Save & Finish Later
          </button>
        </FormRow>
      </Form>
    </FormPage>
  );
};

export default AddSellerTransactionDetails;
