import http, { setHeaders } from "../http-common";
import Auth from "../utils/auth";

setHeaders(Auth.getInstance().getAuthToken());

const getAll = (broker, search) => {
  if (broker?.UserInRole?.UserRole?.name === "Agent") {
    return http.post(`/transactions/all`, { ...search, broker_id: broker.id });
  } else {
    return http.post(`/transactions/all`, search);
  }
};

const getAllParticipantDeals = (userId, search) => {
  return http.post(`/transactions/deals/userParticipant/${userId}`, search);
};

const getAllInviteDeals = (userId, search) => {
  return http.post(`/transactions/deals/invite/${userId}`, search);
};

const getAllByFilterOptions = (type, filterOptions, pageNo, size) => {
  return http.get(
    `/transactions/reports/${type}/${(pageNo - 1) * size
    }/${size}?filters=${encodeURIComponent(JSON.stringify(filterOptions))}`
  );
};

const upsert = (data) => {
  return http.post("/transactions", data);
};

const buyerSeller = (data) => {
  return http.post("/transactions/buyer-seller-closing", data);
};

const update = (id, data) => {
  return http.put(`/transactions/${id}`, data);
};

const getTransactionbyId = (id) => {
  return http.get(`/transactions/${id}`);
};

const getTransactionInitialById = (id) => {
  return http.get(`transactions/initial/${id}`);
};

const getTransactionEdocListById = (
  id,
  userId,
  companyId,
  isParticipant,
  roleType = null
) => {
  const queryParams = isParticipant
    ? `?userId=${userId}&companyId=${companyId}&userRole=${roleType}`
    : "";
  return http.get(`transactions/edoc_task/${id}${queryParams}`);
};

const getEdocCompanyTaskById = (companyId, checkListId, search) => {
  return http.get(
    `transactions/tasksTags/${companyId}/${checkListId}?${search}`
  );
};

const getTransactionEsignById = (id) => {
  return http.get(`transactions/esign/${id}`);
};

const getTransactionCompanyById = (id) => {
  return http.get(`transactions/company/${id}`);
};

const eSignDealAddendum = (id, data = null) => {
  return http.post(`/e-sign/purchase-and-sale/addendum/${id}`, data);
};

const eSignDealExclusion = (id, data = null) => {
  return http.post(`/e-sign/purchase-and-sale/exclusion/${id}`, data);
};

const eSignListingAddendum = (id, data = null) => {
  return http.post(`/e-sign/listing/addendum/${id}`, data);
};

const eSignListingExclusion = (transactionId, data = null) => {
  return http.post(`/e-sign/listing/exclusion/${transactionId}`, data);
};

const eSignMaterialDamage = (transactionId, data = null) => {
  return http.post(
    `/e-sign/listing/material-damage-disclosure/${transactionId}`,
    data
  );
};

const eSignMaterialDamageDisclosureSellerFills = (transactionId, data = null) => {
  return http.post(
    `/e-sign/listing/material-damage-disclosure-seller-fills/${transactionId}`,
    data
  );
};

const eSignCoBroker = (id, data = null) => {
  return http.post(`/e-sign/co-broker/${id}`, data);
};

const eSignCYBACooperativeAgreement = (id, data = null) => {
  return http.post(`/e-sign/cyba-cooperative-agreement/${id}`, data);
};

const eSignAcceptance = (id, data = null) => {
  return http.post(`/e-sign/acceptance/${id}`, data);
};

const eSignBillOfSale = (id, data = null) => {
  return http.post(`/e-sign/bill-of-sale/${id}`, data);
};

const eSignBillOfSaleEngine = (id, data = null) => {
  return http.post(`/e-sign/bill-of-sale-engine/${id}`, data);
};

const eSignBillOfSaleAllEngines = (id, data = null) => {
  return http.post(`/e-sign/bill-of-sale-all-engines/${id}`, data);
};

const eSignBillOfSaleEngineOneToSix = (url, id, data = null) => {
  return http.post(`/e-sign/${url}/${id}`, data);
};

const eSignCYBAPurchaseAgreement = (id, data = null) => {
  return http.post(`/e-sign/cyba-purchase-agreement/${id}`, data);
};

const eSignEscrowAgreement = (id, data = null) => {
  return http.post(`/e-sign/escrow-holdback-agreement/${id}`, data);
};

const eSignCYBAPurchaseAgreementAmendment = (id, data = null) => {
  return http.post(`/e-sign/cyba-purchase-agreement-amendment/${id}`, data);
};

const eSignBillOfSaleNotary = (id, data = null) => {
  return http.post(`/e-sign/bill-of-sale-notary/${id}`, data);
};

const eSignWarrantyTitleNotary = (id, data = null) => {
  return http.post(`/e-sign/warranty-of-title-with-notary/${id}`, data);
};

const getPreview = (documentName, id, data) => {
  return http.post(`/e-sign/preview/${documentName}/${id}`, data);
};

const buyerClosingStatement = (id, data = null) => {
  return http.post(`/e-sign/buyer-closing/${id}`, data);
};

const sellerClosingStatement = (id, data = null) => {
  return http.post(`/e-sign/seller-closing/${id}`, data);
};

const eSignCommissionAgreementSeller = (id, data = null) => {
  return http.post(`/e-sign/commission-agreement-seller/${id}`, data);
};
const eSignCommissionAgreementSellerCoBroker = (id, data = null) => {
  return http.post(`/e-sign/commission-agreement-seller-co-broker/${id}`, data);
};

const eSignTradeInVessel = (id, data = null) => {
  return http.post(`/e-sign/trade-in-vessel/${id}`, data);
};

const getOffer = (transactionId) => {
  return http.post(`/transactions/offer`, { id: transactionId });
};

const getAddendum = (transactionId) => {
  return http.post("/transactions/addendum", { id: transactionId });
};

const getExclusions = (transactionId) => {
  return http.post("/transactions/exclusions", { id: transactionId });
};

const getCobroker = (id) => {
  return http.post("/transactions/cobroker", { id: id });
};

const getListingSeller = (transaction_id, client_id) => {
  return http.post("/clients/listing-seller", {
    transaction_id: transaction_id,
    transaction_client_id: client_id,
  });
};

const resetEdoc = (id, data = null) => {
  return http.post(`/transactions/reset-edoc/${id}`, data);
};

const sendEmail = (id) => {
  return http.get(`/transactions/send-email/${id}`);
};

const deleteCobroker = (id) => {
  return http.delete(`/transactions/cobroker/${id}`);
};

const listingESignRequest = (id, data = null) => {
  return http.post(`/e-sign/listing/c/${id}`, data);
};

const pierOneListingESignRequest = (id, data = null) => {
  return http.post(`/e-sign/pier-one-listing-agreement/${id}`, data);
};

const dealsESignRequest = (url, id, data = null) => {
  return http.post(`/e-sign/${url}/${id}`, data);
};

const buyersAsIsAcknowledgementESignRequest = (id, data = null) => {
  return http.post(`/e-sign/buyer's-as-is-acknowledgement/${id}`, data);
};

const authorizeOfReleaseOfFundsESignRequest = (id, data = null) => {
  return http.post(`/e-sign/authorization-of-release-of-funds/${id}`, data);
};

const YBAAAcceptanceOfVesselESignRequest = (id, data = null) => {
  return http.post(`/e-sign/ybaa-acceptance-of-vessel/${id}`, data);
};

const AsIsAcceptanceOfVesselESignRequest = (id, data = null) => {
  return http.post(`/e-sign/as-is-acceptance-of-vessel/${id}`, data);
};

const surveyWaiverESignRequest = (id, data = null) => {
  return http.post(`/e-sign/survey-waiver/${id}`, data);
};

// function calls api for Disbursement Of Funds - Buyer Document
const disbursementOfFundsBuyerESignRequest = (id, data = null) => {
  return http.post(`/e-sign/disbursement-of-funds-buyer/${id}`, data);
};

// function calls api for FL Removal Affidavit - 2024 Document
const flRemovalAffidavit2024ESignRequest = (id, data = null) => {
  return http.post(`/e-sign/fl-removal-affidavit-2024/${id}`, data);
};

const trialRunWaiverESignRequest = (id, data = null) => {
  return http.post(`/e-sign/trial-run-waiver/${id}`, data);
};

const netToSellerESignRequest = (id, data = null) => {
  return http.post(`/e-sign/net-to-seller/${id}`, data);
};

const pierOneESignRequest = (id, data = null) => {
  return http.post(`e-sign/pier-one-purchase-and-sale/${id}`, data);
};

const potentialTaxLiabilityESignRequest = (id, data = null) => {
  return http.post(`/e-sign/potential-tax-liability/${id}`, data);
};

const cancellationAndRefundRequestESignRequest = (id, data = null) => {
  return http.post(`/e-sign/cancellation-and-refund-request/${id}`, data);
};

const getDocumentUrl = (documentName) => {
  return http.get(
    `/e-sign/template-link?document_name=${encodeURIComponent(documentName)}`
  );
};

const getPdfLink = (documentId) => {
  return http.get(`/e-sign/template-link/${documentId}`);
};

const getEbUsers = (companyId, isAgent = false) => {
  return http.get(`/transactions/employingBrokers/${companyId}?isAgent=${isAgent}`);
};

const fetchAllDocuments = (transactionId, search) => {
  const searchQuery = new URLSearchParams(search).toString();
  return http.get(
    `/transactions/transactionEsignTemplates/${transactionId}?${searchQuery}`
  );
};

const updateDocumentUseField = (id, data) => {
  return http.put(`/transactions/transactionEsignTemplate/${id}`, data);
};

const updateSortOrederForm = (data) => {
  return http.post(`/transactions/updateOrder`, data);
};

const copyDocuments = (documentId, payload) => {
  return http.post(`/transactions/addDuplicateDocument/${documentId}`, payload);
};

const getExclusionDoc = (exclusionDocId) => {
  return http.get(`/transactions/getPurchaseExclusion/${exclusionDocId}`);
};

const getAcceptanceDoc = (acceptanceDocId) => {
  return http.get(`/transactions/getAcceptance/${acceptanceDocId}`);
};

const deleteEDocById = (id) => {
  return http.delete(`/transactions/deleteDuplicateDocument/${id}`);
};

const updateTerms = (data) => {
  return http.post(`/transactions/transactionTerms`, data);
};

const sendSignatures = (type, data) => {
  return http.post(`/e-sign/signature/${type}`, data);
};

const eSignYbaaLA = (transactionId, data = null) => {
  return http.post(`/e-sign/listing/central-listing/${transactionId}`, data);
};

const eSignYBAAUsOpenListingAgreement = (transactionId, data = null) => {
  return http.post(
    `/e-sign/listing/ybaa-us-open-listing-agreement/${transactionId}`,
    data
  );
};

const eSignYBAACanadaOpenListingAgreement = (transactionId, data = null) => {
  return http.post(
    `/e-sign/listing/ybaa-canada-open-listing-agreement/${transactionId}`,
    data
  );
};

const eSignCSAListingAgreement = (transactionId, data = null) => {
  return http.post(
    `/e-sign/listing/csa-listing-agreement/${transactionId}`,
    data
  );
};

const eSignCYBAListingAgreement = (transactionId, data = null) => {
  return http.post(
    `/e-sign/listing/cyba-listing-agreement/${transactionId}`,
    data
  );
};

const eSignCYBAExclusiveListingAgreementNet = (transactionId, data = null) => {
  return http.post(
    `/e-sign/listing/cyba-exclusive-listing-agreement-net/${transactionId}`,
    data
  );
};

const eSignCYBAExclusiveListingAgreementGross = (transactionId, data = null) => {
  return http.post(
    `/e-sign/listing/cyba-exclusive-listing-agreement-gross/${transactionId}`,
    data
  );
};
const eSignCYBAListingAgreementAmendment = (transactionId, data = null) => {
  return http.post(
    `/e-sign/listing/cyba-listing-agreement-amendment/${transactionId}`,
    data
  );
};

const eSignYBAACanadaCentralListingAgreement = (transactionId, data = null) => {
  return http.post(
    `/e-sign/listing/ybaa-canada-central-listing-agreement/${transactionId}`,
    data
  );
};

const eSignCYBAOpenListingAgreement = (transactionId, data = null) => {
  return http.post(
    `/e-sign/listing/cyba-open-listing-agreement/${transactionId}`,
    data
  );
};

const eSignNYBAListingAgreement = (transactionId, data = null) => {
  return http.post(
    `/e-sign/listing/nyba-listing-agreement/${transactionId}`,
    data
  );
};

const eSignYBAAAmendmentToCentralListingAgreement = (
  transactionId,
  data = null
) => {
  return http.post(
    `/e-sign/listing/ybaa-amendment-to-central-listing-agreement/${transactionId}`,
    data
  );
};

const eSignUSCGBillOfSale = (id, data = null) => {
  return http.post(`/e-sign/uscg-bill-of-sale/${id}`, data);
};

const eSignAssignmentOfContract = (id, data = null) => {
  return http.post(`/e-sign/assignment-of-contract/${id}`, data);
};

const eSignUSPowerOfAttorneyBuyer = (id, data = null) => {
  return http.post(`/e-sign/us-power-of-attorney-buyer/${id}`, data);
};

const esignUSCGDeletionRequest = (id, data = null) => {
  return http.post(`/e-sign/uscg-deletion-request/${id}`, data);
};

const eSignUSPowerOfAttorneySeller = (id, data = null) => {
  return http.post(`/e-sign/us-power-of-attorney-seller/${id}`, data);
};

const eSignFLPowerOfAttorneyForAVesselOrVesselWithTrailer = (
  id,
  data = null
) => {
  return http.post(
    `/e-sign/fl-power-of-attorney-for-a-vessel-or-vessel-with-trailer/${id}`,
    data
  );
};

const eSignFLDealersSalesTaxStmt = (id, data = null) => {
  return http.post(`/e-sign/fl-dealers-sales-tax-stmt/${id}`, data);
};

const eSignCYBABuyerFinalStatement = (id, data = null) => {
  return http.post(`/e-sign/cyba-buyer-final-statement/${id}`, data);
};

const eSignCYBACounterOffer = (id, data = null) => {
  return http.post(`/e-sign/cyba-counter-offer/${id}`, data);
};

const eSignCounterOffer = (id, data = null) => {
  return http.post(`/e-sign/counter-offer/${id}`, data);
};

const eSignYBAAAmendmentToPSACommon = (url, id, data = null) => {
  return http.post(`/e-sign/${url}/${id}`, data);
};

const eSignNYBACounterOffer = (id, data = null) => {
  return http.post(`/e-sign/nyba-counter-offer/${id}`, data);
};

const eSignNYBAPurchaseAndSales = (id, data = null) => {
  return http.post(`/e-sign/nyba-purchase-and-sale-agreement/${id}`, data);
};

const eSignBillOfSaleNotarySellerOnly = (id, data = null) => {
  return http.post(`/e-sign/bill-of-sale-notary-seller-only/${id}`, data);
};

const eSignCYBASellerFinalStatement = (id, data = null) => {
  return http.post(`/e-sign/cyba-seller-final-statement/${id}`, data);
};

const eSignPayoffVerification = (id, data = null) => {
  return http.post(`/e-sign/payoff-verification/${id}`, data);
};

const eSignEscrowAgreementDisbursementAuthorization = (id, data = null) => {
  return http.post(
    `/e-sign/escrow-agreement-disbursement-authorization/${id}`,
    data
  );
};

const eSignReleaseOfLiabilityAndHoldHarmless = (id, data = null) => {
  return http.post(
    `/e-sign/release-of-liability-and-hold-harmless/${id}`,
    data
  );
};

const eSignRegistrationWaiver = (id, data = null) => {
  return http.post(`/e-sign/registration-waiver/${id}`, data);
};

const eSignYBAAEscrowAgreement = (id, data = null) => {
  return http.post(`/e-sign/ybaa-escrow-agreement/${id}`, data);
};

const eSignBillOfSaleCommon = (url, id, data = null) => {
  return http.post(`/e-sign/${url}/${id}`, data);
};

const eSignBillOfSaleTrailer = (id, data = null) => {
  return http.post(`/e-sign/bill-of-sale-trailer/${id}`, data);
};

const eSignCYBARepairAllowanceAddendum = (id, data = null) => {
  return http.post(`/e-sign/cyba-repair-allowance-addendum/${id}`, data);
};

const eSignCYBATrialSurvey = (id, data = null) => {
  return http.post(`/e-sign/cyba-trial-survey/${id}`, data);
};

const eSignCancelationOfPurchaseBuyer = (id, data = null) => {
  return http.post(`/e-sign/cancelation-of-purchase-buyer/${id}`, data);
};

const eSignCYBATransactionSummary = (id, data = null) => {
  return http.post(`/e-sign/cyba-transaction-summary/${id}`, data);
};

const eSignDealSummary = (id, data = null) => {
  return http.post(`/e-sign/deal-summary/${id}`, data);
};

const eSignExemptOfBoatSoldForRemovalFromFLByANonresPurch = (
  id,
  data = null
) => {
  return http.post(
    `/e-sign/exempt-of-boat-sold-for-removal-from-fl-by-a-nonres-purch/${id}`,
    data
  );
};

const eSignFLHSMV82040ApplicationForCertificateOfTitleVessel = (
  id,
  data = null
) => {
  return http.post(
    `/e-sign/fl-hsmv-82040-application-for-certificate-of-title-vessel/${id}`,
    data
  );
};

const eSignLoanPayoffAuthorization = (id, data = null) => {
  return http.post(`/e-sign/loan-payoff-authorization/${id}`, data);
};

const eSignDisbursementOfFundsSeller = (id, data = null) => {
  return http.post(`/e-sign/disbursement-of-funds/${id}`, data);
};

const eSignResolutionToBuySellCorpLLC = (url, id, data = null) => {
  return http.post(`/e-sign/${url}/${id}`, data);
};

const eSignAffidavitOfNonExport = (url, id, data = null) => {
  return http.post(`/e-sign/${url}/${id}`, data);
};
const eSignYBAAPurchaseAndSale = (id, data = null) => {
  return http.post(`/e-sign/ybaa-purchase-and-sale/${id}`, data);
};
const esignFLSalesTaxExemptionAffidavit = (id, data = null) => {
  return http.post(`/e-sign/fl-sales-tax-exemption-affidavit/${id}`, data);
};
const esignFLAffidavitForAcceptanceOutsideFlorida = (id, data = null) => {
  return http.post(
    `/e-sign/fl-affidavit-for-acceptance-outside-florida/${id}`,
    data
  );
};

const eSignYBAAPurchaseAndSaleAgreementCanada = (id, data = null) => {
  return http.post(`/e-sign/ybaa-purchase-and-sale-canada/${id}`, data);
};

const setAppliedFilterTagData = (transactionId, data = null) => {
  return http.post(`/transactions/applied/filter/tag/${transactionId}`, data);
};

const addVesselImage = (transactionId, data = null) => {
  return http.post(`/transactions/addImageUrl/${transactionId}`, data);
};

const getAllTransactionTasks = (transactionData = null) => {
  return http.post(`/transactions/fetchTransactionTasksTags`, transactionData);
};

const resetPdfEditorVersion = (data = null) => {
  return http.post(`/transactions/resetPdfVersion`, data);
};

const getSignerActivities = (transactionId) => {
  return http.get(`/transactions/getActivities/${transactionId}`);
};

// Temporary for Migration
const addRecords = (transactionId, companyId) => {
  return http.get(`/transactions/addRecords/${transactionId}/${companyId}`);
};

const addRecordsForToys = (transactionId, companyId) => {
  return http.get(`/transactions/addRecordsToys/${transactionId}/${companyId}`);
};

const addRecordsForClients = (transactionId, companyId) => {
  return http.get(`/transactions/update/unique-transaction-id`);
};

const migrateUploadsForBucket = () => {
  return http.get(`/transactions/MigrateUploads`);
};

const migrateFormForBucket = () => {
  return http.get(`/transactions/MigrateHandSignedPdf`);
};

const migrateEsignBucket = () => {
  return http.get(`/transactions/MigrateEsignDocumentPdf`);
};

const sendDocumentPreviewEmail = (data) => {
  return http.post("/e-sign/preview/deal", data);
};

const changeTransactionBroker = (transactionId, data = null) => {
  return http.put(`/transactions/updateBroker/${transactionId}`, data);
};

const getListingAgreemenetUploads = (transactionId, companyId) => {
  return http.get(
    `/transactions/customUploadsOfListingAgreement/${companyId}/${transactionId}`
  );
};

const submitImportUploads = (transactionId, uploads) => {
  return http.post(
    `/transactions/importUploadsOfListingAgreement/${transactionId}`,
    uploads
  );
};

const searchListingAgreementVesseles = (companyId, searchtext) => {
  return http.get(
    `/transactions/getListingAgreements/${companyId}?searchtext=${searchtext}`
  );
};

const checkValidationForTransactionDocument = (documentList) => {
  return http.post(`/transactions/validateDocuments`, documentList);
};

const removeTradeInVessel = (tradeInId) => {
  return http.delete(`/transactions/deleteTradeInVessel/${tradeInId}`);
};

const getCompanyClients = (companyId) => {
  return http.get(`/transactions/transactionClients/${companyId}`);
};

const getAllParticipants = (transactionId) => {
  return http.get(`/transactions/participants/${transactionId}`);
};

const addGuestAgent = (transactionId, participantData = null) => {
  return http.post(
    `/transactions/guestAgent/${transactionId}`,
    participantData
  );
};

const getParticipantDetail = (participantId) => {
  return http.get(`/transactions/participant/${participantId}`);
};

const editParticipant = (participantId, updatedParticipant) => {
  return http.put(
    `/transactions/participant/${participantId}`,
    updatedParticipant
  );
};

const updateSelectedDocuments = (
  participantId,
  transactionId,
  documentData = null
) => {
  return http.put(
    `/transactions/participantAuthDocs/${participantId}/${transactionId}`,
    documentData
  );
};

const fetchParticipants = (companyId) => {
  return http.get(`/transactions/selectParticipants/${companyId}`);
};

const selectParticipant = (payload) => {
  return http.post(`/transactions/selectedParticipants`, payload);
};
const deleteParticipant = (id) => {
  return http.delete(`/transactions/deleteParticipant/${id}`);
};

const TransactionService = {
  eSignYBAAPurchaseAndSale,
  eSignUSCGBillOfSale,
  eSignYbaaLA,
  getExclusionDoc,
  getAcceptanceDoc,
  deleteEDocById,
  sendSignatures,
  updateTerms,
  getAll,
  getAllParticipantDeals,
  getAllInviteDeals,
  getEbUsers,
  fetchAllDocuments,
  updateDocumentUseField,
  eSignMaterialDamage,
  eSignMaterialDamageDisclosureSellerFills,
  copyDocuments,
  updateSortOrederForm,
  getPreview,
  update,
  getAllByFilterOptions,
  upsert,
  buyerSeller,
  getTransactionbyId,
  getTransactionInitialById,
  getTransactionEdocListById,
  getTransactionEsignById,
  getTransactionCompanyById,
  eSignCoBroker,
  eSignCYBACooperativeAgreement,
  eSignAcceptance,
  eSignBillOfSale,
  buyerClosingStatement,
  sellerClosingStatement,
  eSignDealAddendum,
  eSignDealExclusion,
  eSignListingAddendum,
  eSignListingExclusion,
  eSignBillOfSaleNotary,
  eSignWarrantyTitleNotary,
  eSignCommissionAgreementSeller,
  eSignCommissionAgreementSellerCoBroker,
  eSignTradeInVessel,
  getOffer,
  getAddendum,
  getExclusions,
  getCobroker,
  getListingSeller,
  resetEdoc,
  sendEmail,
  deleteCobroker,
  listingESignRequest,
  dealsESignRequest,
  getDocumentUrl,
  getPdfLink,
  pierOneESignRequest,
  pierOneListingESignRequest,
  getEdocCompanyTaskById,
  setAppliedFilterTagData,
  addVesselImage,
  getAllTransactionTasks,
  resetPdfEditorVersion,
  getSignerActivities,
  addRecords,
  addRecordsForToys,
  addRecordsForClients,
  migrateUploadsForBucket,
  migrateFormForBucket,
  migrateEsignBucket,
  buyersAsIsAcknowledgementESignRequest,
  authorizeOfReleaseOfFundsESignRequest,
  surveyWaiverESignRequest,
  disbursementOfFundsBuyerESignRequest,
  flRemovalAffidavit2024ESignRequest,
  YBAAAcceptanceOfVesselESignRequest,
  trialRunWaiverESignRequest,
  potentialTaxLiabilityESignRequest,
  cancellationAndRefundRequestESignRequest,
  netToSellerESignRequest,
  eSignYBAAUsOpenListingAgreement,
  eSignYBAACanadaCentralListingAgreement,
  eSignYBAACanadaOpenListingAgreement,
  eSignBillOfSaleEngine,
  eSignBillOfSaleAllEngines,
  eSignBillOfSaleEngineOneToSix,
  eSignYBAAPurchaseAndSaleAgreementCanada,
  eSignUSPowerOfAttorneyBuyer,
  esignUSCGDeletionRequest,
  eSignUSPowerOfAttorneySeller,
  AsIsAcceptanceOfVesselESignRequest,
  eSignLoanPayoffAuthorization,
  eSignAssignmentOfContract,
  esignFLSalesTaxExemptionAffidavit,
  esignFLAffidavitForAcceptanceOutsideFlorida,
  eSignCSAListingAgreement,
  sendDocumentPreviewEmail,
  eSignFLHSMV82040ApplicationForCertificateOfTitleVessel,
  eSignCYBAListingAgreement,
  eSignCYBAExclusiveListingAgreementNet,
  eSignCYBAExclusiveListingAgreementGross,
  eSignCYBAPurchaseAgreement,
  eSignEscrowAgreement,
  eSignFLPowerOfAttorneyForAVesselOrVesselWithTrailer,
  eSignFLDealersSalesTaxStmt,
  eSignCYBABuyerFinalStatement,
  eSignCYBACounterOffer,
  eSignCounterOffer,
  eSignYBAAAmendmentToPSACommon,
  eSignNYBACounterOffer,
  eSignNYBAPurchaseAndSales,
  eSignBillOfSaleNotarySellerOnly,
  eSignCYBASellerFinalStatement,
  eSignPayoffVerification,
  eSignCYBARepairAllowanceAddendum,
  eSignCYBATrialSurvey,
  eSignCancelationOfPurchaseBuyer,
  eSignCYBATransactionSummary,
  eSignDealSummary,
  eSignCYBAPurchaseAgreementAmendment,
  eSignExemptOfBoatSoldForRemovalFromFLByANonresPurch,
  eSignCYBAListingAgreementAmendment,
  eSignDisbursementOfFundsSeller,
  eSignResolutionToBuySellCorpLLC,
  eSignAffidavitOfNonExport,
  changeTransactionBroker,
  eSignCYBAOpenListingAgreement,
  getListingAgreemenetUploads,
  submitImportUploads,
  eSignNYBAListingAgreement,
  searchListingAgreementVesseles,
  eSignYBAAAmendmentToCentralListingAgreement,
  eSignEscrowAgreementDisbursementAuthorization,
  eSignReleaseOfLiabilityAndHoldHarmless,
  eSignRegistrationWaiver,
  eSignYBAAEscrowAgreement,
  eSignBillOfSaleCommon,
  eSignBillOfSaleTrailer,
  checkValidationForTransactionDocument,
  removeTradeInVessel,
  getCompanyClients,
  getAllParticipants,
  addGuestAgent,
  getParticipantDetail,
  editParticipant,
  updateSelectedDocuments,
  fetchParticipants,
  selectParticipant,
  deleteParticipant,
};

export default TransactionService;
