import { Redirect, Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import DealMainView from "./MainView";
import VesselAcceptance from "./options/OptionAcceptance";
import BillOfSale from "./options/OptionBillOfSale";
import BuyerDetail from "./options/OptionBuyerDetail";
import SellerDetail from "./options/OptionSellerDetail";
import AddendumOption from "./options/OptionAddendum";
import ExclusionOption from "./options/OptionExclusion";
import TradeInVessel from './options/OptionTradeInVessel'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { clearTransaction, updateRequiredFields } from "../../../redux/actions/transaction";
import BillOfSaleNotaryOption from "./options/OptionBillOfSaleNotary";

import {
  CORPORATE_OWNED,
  ENTITY_AND_OR_ASSIGN,
  JOINT_OWNER,
  LEGAL_AND_OR_ASSIGN,
  LEGAL_OR_ASSIGN,
  LEGAL_OWNER,
  LLC_OWNED,
  RECORD_OWNER,
  SOLE_OWNER,
  TRUST_OWNED,
} from "../../../utils/signer";
import USCGBillOfSaleOption from "./options/OptionUSCG1340BillOfSale";
import EditPreviewDocument from "../../preview-edit";
import EditCustomEditor from "../../preview-custom-edit";

const ViewPage = (props) => {
  let { path } = useRouteMatch();
  let { id } = useParams();
  const dispatch = useDispatch();

  const dataDeal = useSelector((state) => state.transactions.transaction || {});

  useEffect(() => {
    // return () => {
    //   dispatch(clearTransaction());
    // };
  }, []);


  useEffect(() => {
    const dataVessel = dataDeal.t_vessel || {};
    const dataOffer = dataDeal.t_offers || {};
    const dataBuyer = dataDeal.buyer || {};
    const dataCoBuyer = dataDeal.co_buyer || {};
    const dataSeller = dataDeal.seller || {};
    const dataCoSeller = dataDeal.co_seller || {};
    const dataCoBroker = dataDeal.co_broker || {};
    const dataTerms = dataDeal.t_terms || {};
    const dataTrailer = dataDeal.t_trailer || []

    const vesselFields = {
      hull_number: "Hull No.",
      length: "Length",
      make: "Make",
      // model: "Model",
      // boat_type: "Boat Type",
      year: "Year",
    };
    const buyerFields = {
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      mobile_phone: "Mobile Phone",
    };
    const jointBuyerFields = {
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      mobile_phone: "Mobile Phone",
    };
    const coBuyerFields = {
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      mobile_phone: "Mobile Phone",
    };
    const offerFields = {
      offer_date: "Offer Date",
      offer_expiration_date: "Offer Expiration Date",
      accept_reject_date: "Accept and Reject Date",
      closing_date: "Closing Date",
      purchase_price: "Purchase Price",
      // less_deposit: "Less Deposit",
    };
    const sellerFields = {
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      mobile_phone: "Mobile Phone",
    };
    const coSellerFields = {
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      mobile_phone: "Mobile Phone",
    };
    const termsFields = {
      dispute_location: "Dispute Location",
      // dispute_location_state: "Dispute Location State",
      dispute_location_city: "Dispute Location City",
    };

    const trailerFields = {
      make: "Make",
      year: "Year",
      vin: "VIN"
    }

    const sellingCoBrokerFields = {
      selling_broker_email: "Selling Broker Email",
      selling_brokerage_broker: "Selling Broker Name",
      selling_broker_phone_number: "Selling Broker Phone Number",
    }
    
    const listingCoBrokerFields = {
      listing_broker_email: "Listing Broker Email",
      listing_brokerage_broker: "Listing Broker Name",
      listing_broker_phone_number: "Listing Broker Phone Number",
    }

    const groupFields = {
      trust_name: "Corporation/LLC/Trust Name",
      // trust_tax_id: "Corporation/LLC/Trust Tax ID",
      trust_address: "Corporation/LLC/Trust Address",
      trust_city: "Corporation/LLC/Trust City",
      trust_state: "Corporation/LLC/Trust State",
      trust_signer_name: "Corporation/LLC/Trust Name of Signer",
      trust_signer_email: "Corporation/LLC/Trust Email",
      trust_signer_title: "Corporation/LLC/Trust Title of Signer",
    }

    const assigneeFields = {
      // assignee_first_name: "Assignee First Name",
      // assignee_last_name: "Assignee Last Name",
      // assignee_email: "Assignee Email",
    }

    let vesselRequireds = [];
    let buyerRequireds = [];
    let coBuyerRequireds = [];
    let offerRequireds = [];
    let sellerRequireds = [];
    let coSellerRequireds = [];
    let termsRequireds = [];
    let coBrokerRequireds = [];
    let trailerRequireds = [];

    Object.keys(vesselFields).forEach((key) => {
      if (!dataVessel[key]) {
        vesselRequireds.push({ [key]: vesselFields[key] });
      }
    });
    // if (!(dataVessel['doc_no'] || dataVessel['reg_no'] || dataVessel['title_no'])) {
    //   vesselRequireds.push({ 'doc_reg_title_no': 'Doc No, Reg No, Title No' });
    // }
     if (dataVessel.is_old_built == true) {
      vesselRequireds.splice(vesselRequireds.findIndex(field => field['hull_number']), 1);
    }
    if (dataVessel.hull_number?.length < 12 && dataVessel.is_old_built === false) {
      vesselRequireds.push({ 'hull_number': 'Invalid Hull Number' });
    }

    if ([ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataDeal?.buyer_type) > -1) {
      Object.keys(groupFields).forEach((key) => {
        if (!dataBuyer[key]) {
          buyerRequireds.push({ [key]: groupFields[key] });
        }
      });
    } else if ([LEGAL_OWNER, LEGAL_OR_ASSIGN, LEGAL_AND_OR_ASSIGN].indexOf(dataDeal?.buyer_type) > -1) {
      Object.keys(buyerFields).forEach((key) => {
        if (!dataBuyer[key]) {
          buyerRequireds.push({ [key]: buyerFields[key] });
        }
      });
    } else if ([JOINT_OWNER].indexOf(dataDeal?.buyer_type) > -1) {
      Object.keys(buyerFields).forEach((key) => {
        if (!dataBuyer[key]) {
          buyerRequireds.push({ [key]: buyerFields[key] });
        }
      });
    } else {
      buyerRequireds.push({ title: 'Buyer Role' });
    }

    if ([LEGAL_OR_ASSIGN, LEGAL_AND_OR_ASSIGN].indexOf(dataDeal?.buyer_type) > -1) {
      Object.keys(assigneeFields).forEach((key) => {
        if (!dataBuyer[key]) {
          buyerRequireds.push({ [key]: assigneeFields[key] });
        }
      });
    }

    Object.keys(coBuyerFields).forEach((key) => {
      if (!dataCoBuyer[key]) {
        coBuyerRequireds.push({ [key]: coBuyerFields[key] });
      }
    });
    Object.keys(offerFields).forEach((key) => {
      if (!dataOffer[key]) {
        offerRequireds.push({ [key]: offerFields[key] });
      }
    });

    if ([TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].includes(dataDeal?.seller_type)) {
      Object.keys(groupFields).forEach((key) => {
        if (!dataSeller[key]) {
          sellerRequireds.push({ [key]: groupFields[key] });
        }
      });
    } else if ([LEGAL_OWNER].includes(dataDeal?.seller_type)) {
      Object.keys(sellerFields).forEach((key) => {
        if (!dataSeller[key]) {
          sellerRequireds.push({ [key]: sellerFields[key] });
        }
      });
    } else if ([JOINT_OWNER].includes(dataDeal?.seller_type)) {
      Object.keys(sellerFields).forEach((key) => {
        if (!dataSeller[key]) {
          sellerRequireds.push({ [key]: sellerFields[key] });
        }
      });
    } else if ([RECORD_OWNER].includes(dataDeal?.seller_type)) {
      if (!dataDeal['seller_type']) sellerRequireds.push({ title: 'Seller Role' });
    } else {
      sellerRequireds.push({ title: 'Seller Role' });
    }

    Object.keys(coSellerFields).forEach((key) => {
      if (!dataCoSeller[key]) {
        coSellerRequireds.push({ [key]: coSellerFields[key] });
      }
    });
    Object.keys(termsFields).forEach((key) => {
      if (!dataTerms[key]) {
        termsRequireds.push({ [key]: termsFields[key] });
      }
      if (dataTerms['dispute_location'] === 'B') {
        termsRequireds.pop();
      }
    });

    if (["selling","both"].includes(dataCoBroker?.broker_type)) {
      Object.keys(sellingCoBrokerFields).forEach(key => {
        if (!dataCoBroker[key]) {
          coBrokerRequireds.push({ [key]: sellingCoBrokerFields[key] });
        }
      });
    }

    if (["listing","both"].includes(dataCoBroker?.broker_type)) {
      Object.keys(listingCoBrokerFields).forEach(key => {
        if (!dataCoBroker[key]) {
          coBrokerRequireds.push({ [key]: listingCoBrokerFields[key] });
        }
      });
    }

    Object.keys(trailerFields).forEach((key) => {
      if (dataTrailer.length == 0) {
        trailerRequireds.push({ [key]: trailerFields[key] });
      }
      dataTrailer.forEach(element => {
        if (!element[key]) {
          trailerRequireds.push({ [key]: trailerFields[key] });
        }
      });
    })

    dispatch(
      updateRequiredFields({
        vessel: vesselRequireds,
        buyer: buyerRequireds,
        co_buyer: coBuyerRequireds,
        offer: offerRequireds,
        seller: sellerRequireds,
        co_seller: coSellerRequireds,
        terms: termsRequireds,
        co_broker: coBrokerRequireds,
        trailer: trailerRequireds
      })
    );
  }, [dataDeal]);

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Redirect to={`${path}/main`} />
      </Route>
      <Route path={`${path}/main`}> <DealMainView {...props}/></Route>
      <Route path={`${path}/acceptance-of-vessel`} component={VesselAcceptance} />
      <Route path={`${path}/bill-of-sale`} component={BillOfSale} />
      <Route path={`${path}/bill-of-sale---notary`} component={BillOfSaleNotaryOption} />
      <Route path={`${path}/buyer-closing-statement`} component={BuyerDetail} />
      <Route path={`${path}/seller-closing-statement`} component={SellerDetail} />
      <Route path={`${path}/trade-in-vessel`} component={TradeInVessel} />
      <Route path={`${path}/purchase-&-sale-agreement-addendum`} component={AddendumOption} />
      <Route path={`${path}/purchase-&-sale-agreement-exclusion`} component={ExclusionOption} />
      <Route path={`${path}/uscg-bill-of-sale`} component={USCGBillOfSaleOption} />
      <Route path={`${path}/preview/edit`} component={EditCustomEditor} />
      <Redirect to={`${path}/main`} />
    </Switch>
  );
};

export default ViewPage;
