import S3Service from '../services/S3Service';
import { uuid } from 'short-uuid';
import { fileUploadWithServer, uploadCopyToServer } from './s3';

export const uploadTemplate = async (file, type) => {
  try {
    const spt = file.name.split('.');
    let ext = spt.length > 1 ? spt[spt.length - 1] : undefined;
    if (ext.toLowerCase() === 'pdf') ext = 'pdf';
    // spt.pop();
    // const filename = spt.join('.');
    const filename = uuid();

    var { data: s3UrlResp } = await S3Service.uploadFormsUrl(`${filename}.${ext.toLowerCase()}`, type);

    const options = {
      method: 'PUT',
      body: file
    };

    // API call for uploaded file copy on Server
    await uploadCopyToServer(file, `${spt[0]}(${filename}).${ext.toLowerCase()}`, type);

    const response = await fetch(s3UrlResp.url, options);

    if (s3UrlResp.error !== null) {
      return Promise.reject(s3UrlResp.error);
    }
    if (!response.ok) {
      return Promise.reject(`${response.status}: ${response.statusText}`);
    } else {
      return Promise.resolve(`${filename}.${ext.toLowerCase()}`)
    }
    // return Promise.resolve(`${filename}.${ext.toLowerCase()}`)
  } catch (err) {
    return Promise.reject(err);
  }
}