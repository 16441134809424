import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Helmet } from "react-helmet";
import ClientForm from "./Form";
import ClientsList from "./List";
import ClientView from "./View";

const EDocsPage = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <Helmet>
        <title>Edocument Templates - BoatDox</title>
      </Helmet>
      <Switch>
        <Route exact path={path} component={ClientsList} />
        <Route path={`${path}/add`} component={ClientForm} />
        <Route path={`${path}/edit`} component={ClientForm} />
        <Route path={`${path}/view`} component={ClientView} />
      </Switch>
    </>
  );
};

export default EDocsPage;
