import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Helmet } from "react-helmet";
import TemplateForm from "./form/Form";
import ListTemplates from "./list/List";
import ViewTemplate from "./view/View";

const ESignTemplatesPage = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <Helmet>
        <title>E-Sign Templates - BoatDox</title>
      </Helmet>
      <Switch>
        <Route exact path={path} component={ListTemplates} />
        <Route path={`${path}/add`} component={TemplateForm} />
        <Route path={`${path}/edit`} component={TemplateForm} />
        {/* <Route path={`${path}/view`} component={ViewTemplate} /> */}
      </Switch>
    </>
  );
};

export default ESignTemplatesPage;
