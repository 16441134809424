import { useEffect, useCallback } from "react";
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { getDealsByUpcomingClosings } from "../../../redux/actions/dashboard";
import { setDateFilter, CUSTOM } from '../../../redux/actions/report';
import { getPrice } from "../../../utils/currency";

const UpcomingClosings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const stats = useSelector((state) => state.dashboard.dealsByUpcomingClosings);

  useEffect(() => {
    dispatch(getDealsByUpcomingClosings());
  }, []);

  const handleClickCell = useCallback(key => {
    let range;
    switch (key) {
      case 'next7':
        range = [
          moment().format("YYYY-MM-DD"),
          moment().add(7, 'days').format("YYYY-MM-DD"),
        ]
        break;
      case 'next15':
        range = [
          moment().add(8, 'days').format("YYYY-MM-DD"),
          moment().add(15, 'days').format("YYYY-MM-DD"),
        ]
        break;
      case 'next30':
        range = [
          moment().add(16, 'days').format("YYYY-MM-DD"),
          moment().add(30, 'days').format("YYYY-MM-DD"),
        ]
        break;
      case 'total':
        range = [
          moment().format("YYYY-MM-DD"),
          moment().add(30, 'days').format("YYYY-MM-DD")
        ]
        break;
      default:
        range = [];
    }

    dispatch(setDateFilter(CUSTOM,  range));
    history.push('/reports/deals');
  }, []);

  const handleClickViewAll = useCallback(() => {
    const range = [
      moment().format('YYYY-MM-DD'),
      moment().add(30, 'days').format('YYYY-MM-DD'), // after 30 days
    ];
    dispatch(setDateFilter(CUSTOM, range));
    history.push('/reports/deals');
  }, []);

  const titles = {
    next7: "Next 7 days",
    next15: "Next 15 days",
    next30: "Next 30 days",
    total: "Total",
  };

  return (
    <>
      <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
        <thead>
          <tr>
            <th rowSpan="2">Timeframe</th>
            <th rowSpan="2">Deals</th>
            <th rowSpan="2">Value</th>
            <th colSpan="2" className="value-commision">
              Commission
            </th>
          </tr>
          <tr>
            <th className="in-house">In-House</th>
            <th>Co-Brokerage</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(stats).map((key) => {
            return (
              <tr key={key} onClick={() => handleClickCell(key)}>
                <td>{titles[key]}</td>
                <td>{stats[key].count}</td>
                <td>{getPrice(stats[key].value)}</td>
                <td>{getPrice(stats[key].in_house)}</td>
                <td>{getPrice(stats[key].co_brokerage)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {Object.keys(stats).length !== 0 && (
        <div className="view-all">
          <a href="#" onClick={handleClickViewAll}>View All</a>
        </div>
      )}
    </>
  );
};

export default UpcomingClosings;
