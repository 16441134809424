import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import StepWizard from "react-step-wizard";

import { Nav } from './steps/Nav';
import { UploadDocumentStep } from './steps/UploadDocumentStep';
import { AddSignersStep } from './steps/AddSignersStep';
import { PlaceFieldsStep } from './steps/PlaceFieldsStep';
import { ReviewStep } from './steps/ReviewStep';

import { findTemplate } from '../../../redux/actions/templates';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Form.css';

const TemplateForm = (props) => {
  const dispatch = useDispatch();

  const template_id = props.location.state ? props.location.state.id : null;

  useEffect(() => {
    if (!stepState.form.template_id && template_id) {
      dispatch(findTemplate(template_id)).then(res => {
        const { form } = stepState;
        form['file_name'] = res.template_filename;
        form['file_link'] = res.template_link;
        form['elements'] = res.template_fields;
        form['roles'] = res.template_roles;
        form['template_title'] = res.template_name;
        form['template_id'] = template_id;
        form['width'] = res.width;
        form['height'] = res.height;
        form['checklist_id'] = res.checklist_id;
        form['iyba_edocuments_id'] = res.iyba_edocuments_id;
        form['association'] = res.association;
        form['is_hand_signed'] = res?.is_hand_signed || false;
        form["company_id"] = res?.company_id;
        form["use_type"] = res?.use_type;
        form["category"] = res?.category;
        form["status"] = res?.status;
        form["is_main"] = res?.is_main;
        form["is_main_document_id"] = res?.is_main_document_id;
        form['is_multi_section'] = res?.is_multi_section || false;
        form['total_sections'] = res?.total_sections || null;

        updateStepState({
          ...stepState,
          form,
        });
      }).catch(err => {
        console.log(err);
      })
    }
  }, []);

  const [stepState, updateStepState] = useState({
    form: {
      file_name: null,
      template_file: null,
      file_link: null,
      elements: [],
      roles: [''],
      template_title: null,
      template_id: null,
      width: null,
      height: null,
      checklist_id: null,
      association: null,
      is_hand_signed: false,
      company_id: null,
      status: false,
      use_type: null,
      category: "",
      iyba_edocuments_id: null,
      is_main: true,
      is_main_document_id: null,
      is_multi_section: false,
      total_sections: null
    },
    transitions: {
      enterRight: `animated enterRight`,
      enterLeft: `animated enterLeft`,
      exitRight: `animated exitRight`,
      exitLeft: `animated exitLeft`,
      intro: `animated intro`,
    },
  });

  const updateForm = (key, value) => {
    const { form } = stepState;

    form[key] = value;
    updateStepState({
      ...stepState,
      form,
    });
  };

  // Do something on step change
  const onStepChange = (stats) => {
  };

  return (
    <div className="create-container">
      {/* <h4 style={{ textAlign: 'center' }}>Create E-Sign Document</h4> */}
      <div className="col-12 rsw-wrapper">
        <StepWizard
          onStepChange={onStepChange}
          isHashEnabled
          transitions={stepState.transitions} // comment out for default transitions
          nav={<Nav form={stepState.form} update={updateForm} />}
          isLazyMount
        >
          <UploadDocumentStep hashKey={'upload-document'} form={stepState.form} update={updateForm} />
          <AddSignersStep hashKey={'add-signers'} form={stepState.form} update={updateForm} />
          <PlaceFieldsStep hashKey={'place-fields'} form={stepState.form} update={updateForm} />
          <ReviewStep hashKey={'review-and-send'} form={stepState.form} update={updateForm} />
        </StepWizard>
      </div>
    </div>
  );
};

export default TemplateForm;