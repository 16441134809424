import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getTransactionEdocById, listingESignRequest } from "../../../../../redux/actions/transaction";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import { toast } from "react-toastify";
import {
  SOLE_OWNER,
  JOINT_OWNER,
  TRUST_OWNED,
  LLC_OWNED,
  CORPORATE_OWNED,
  getSigner
} from "../../../../../utils/signer";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import Auth from "../../../../../utils/auth";
import FormPage from "../../../../common/FormPage";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import { convertFirstCharIntoSmallCase, isSigned } from "../../../../../utils/functions";
import SignatureMessageModal from "../../../../common/SignatureMessageModal";
import HandSignUploadModal from "../../../../common/HandSignUploadModal";
import { ViewDocumentsModal } from "../../../../common/ViewDocumentsModal";
import { Tooltip } from "@mui/material";
import { momentDateWithTimezone } from "../../../../../utils/common";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import CustomActionButtons from "../../../View/options/steps/CustomActionButtons";
import CustomRenderStatus from "../../../View/options/steps/CustomRenderStatus";

const useStyles = makeStyles({
  previewSendDiv: {
    width: "250px !important"
  },
  handSignedDiv: {
    marginTop: "-4px"
  }
});

/**
 * @param {Function} updateDocumentObj // It is used to update payload object
 * @param {String} selectedRole //It is used to check which role is selected from signature tab
 * @param {Object} payload //Prepared object for the back-end
 * @param {Function} makeDropDownSigners // Send signers array to the signature tab for displaying signer drop-down
 * @returns 
*/
const AddEsign = ({ updateDocumentObj, selectedRole, payload, transactionId, roles, makeDropDownSigners }) => {

  const dispatch = useDispatch();
  const classes = useStyles();

  const [showAdditionalModal, setShowAdditionalModal] = useState(false);
  const [emailDetailsToSend, setEmailDetailsToSend] = useState(null);
  const [showHandsignModal, setShowHandsignModal] = useState(false);
  const [handSignDetails, setHandSignDetails] = useState(null);
  const [showDocument, setShowDocument] = useState(false);
  const [document, setDocument] = useState(null);

  //Get logged User
  const user = Auth.getInstance().getUserData();

  const listings = useSelector((state) => state.transactions);
  const dataTransaction = listings?.transaction || {};
  const dataBroker = dataTransaction.broker;
  const dataOwner = dataTransaction.seller;
  const dataCoOwner = dataTransaction.co_seller;
  const dataVessel = dataTransaction.t_vessel;
  const requiredFields = listings?.requiredFields || [];

  //Take a data of transaction eDocumnets
  const eDoc = (dataTransaction?.t_edocuments || []).find(doc => doc.task_type === "listing-agreement");

  //Take a signers data
  const signers = eDoc?.request_signature?.signers || [];

  /**
    * useEffect to generate the array of signers which is available 
    * for this document only
  */
  useEffect(() => {
    if (!updateDocumentObj || !isValidSend()) {
      return;
    }

    let signerArr = [];

    if (dataTransaction?.employing_broker && !isSigned(signers, "employing_broker")) {
      signerArr.push("employing_broker")
    } else if (!isSigned(signers, "listing_broker")) {
      signerArr.push("listing_broker");
    }

    if (dataTransaction?.seller && !isSigned(signers, "owner")) {
      signerArr.push("owner")
    }

    if (dataCoOwner && !isSigned(signers, "co_owner")) {
      signerArr.push("co_owner")
    }

    makeDropDownSigners(signerArr);

  }, [dataTransaction, signers.length]);

  //Apply use Effect when role is changed from the signature tab
  useEffect(() => {
    /**
     * Check selected role is included for this document &
     * Check the selected role's status is not equal to signed &
     * Check this document has proper data for the sign this document.
     */
    if (selectedRole && roles.includes(selectedRole)
      && (signers && signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === selectedRole)?.status != "Signed")
      && dataTransaction && isValidSend()) {

      //If selected role has no realated data to the transation then return
      if (["employing_broker", "listing_broker", "owner"].includes(selectedRole)) {
        switch (selectedRole) {
          case "owner":
            if (!dataTransaction?.seller) {
              return;
            }
            break;
          case "employing_broker":
            if (!dataTransaction?.employing_broker) {
              return
            }
            break;
          case "listing_broker":
            if (dataTransaction?.employing_broker) {
              return;
            }
            break;
          case "co_owner":
            if (!dataCoOwner) {
              return;
            }
            break;
          default:
            break;
        }
      }

      //Prepare object for the payload
      let obj = {}
      obj[selectedRole] = {
        document_name: "listing-agreement",
        id: dataTransaction.id
      };

      //Update payload object
      updateDocumentObj(obj, selectedRole);
      return;
    }
  }, [selectedRole]);

  //Prepare a role variable to set the the role as an owner or principal
  let role = "owner";

  //Define links for this page
  const links = [
    {
      label: "Listing Agreement",
      link: `/listing-agreement/view/${transactionId}/main`,
    },
    { label: "Listing Agreement", link: "" },
  ];

  const vesselName = `${dataVessel?.length || ""}' ${dataVessel?.make || ""} ${dataVessel?.model || ""} ${dataVessel?.year || ""}` !== "'   "
    ? `${dataVessel?.length || ""}' ${dataVessel?.make || ""} ${dataVessel?.model || ""} ${dataVessel?.year || ""}`
    : "N/A";

  const brokerName = dataBroker
    ? `${dataBroker.first_name || ""} ${dataBroker.last_name}` === " "
      ? "N/A"
      : `${dataBroker.first_name || ""} ${dataBroker.last_name}`
    : "N/A";

  const coOwnerName = dataCoOwner
    ? `${dataCoOwner.first_name || ""} ${dataCoOwner.last_name || ""}` === " "
      ? "N/A"
      : `${dataCoOwner.first_name || ""} ${dataCoOwner.last_name || ""}`
    : "N/A";

  /**
   * This function is use to get the preview link of document for the selected signers
   * @param {Object} evt // Event Object
   * @param {String} role
  */
  const previewByStep = (evt, role) => {
    dispatch(setLoadingStatus(true));
    dispatch(listingESignRequest(dataTransaction?.id, "preview", role)).then((res) => {
      dispatch(setLoadingStatus(false));
      if (res.status !== 200) {
        throw new Error();
      } else {
        window.open(res.data.url + '?tm=' + (new Date()).getTime(), '_blank');
      }
    }).catch((err) => {
      dispatch(setLoadingStatus(false));
      toast.error("Error occurs, while preview.", {
        autoClose: 2000
      })
    });
  }

  /**
     * This function is use for the send email to the selected signers
     * @param {Object} evt // Event Object
     * @param {String} mode //It can be "send" or "hand-signed"
     * @param {*} role 
     * @param {*} data // selected date for the hand signed
    */
  const sendByStep = (mode, role, data = null) => {

    let obj = {};
    if (data) {
      if (mode === 'signed') {
        obj["signed_date"] = data.signed_date;
        obj["signed_document"] = JSON.stringify(data.documents);
      } else {
        obj["additional_email_content"] = data.additional_email_content || '';
      }
      obj["selected_recipients"] = data.selectedUser
    }
    obj["is_email_send"] = data.is_email_send ? 'yes' : 'no';
    obj["requested_by"] = user.id;
    dispatch(setLoadingStatus(true));
    dispatch(listingESignRequest(dataTransaction?.id, mode, role, obj)).then(res => {
      dispatch(setLoadingStatus(false));
      if (res.status !== 200) {
        throw new Error();
      }
      dispatch(getTransactionEdocById(dataTransaction?.id));
      if (data.signed_date) {
        toast.success(`Hand signed documents uploaded successfully.`);
      } else {
        if (!data.is_email_send) {
          setShowAdditionalModal(false);
          toast.success('E-Sign Link generated successfully.');
        } else {
          toast.success("Document sent for eSignature successfully.", {
            autoClose: 2000
          });
        }
      }
    }).catch((err) => {
      dispatch(setLoadingStatus(false));
      toast.error("Error occurs, please try again.", {
        autoClose: 2000
      })
    })
  }

  const onAdditionalMessageModalOpen = (mode, role, data = null) => {
    const selectedRecipients = eDoc.request_signature?.signers?.find((signer) => signer.request_signer?.role === role) || {};
    setEmailDetailsToSend({ mode: mode, role: role, data: data, selectedRecipients: selectedRecipients.selected_recipients || [] });
    setShowAdditionalModal(true);
  }

  const onCloseAdditionalEmailModal = (isEmailSend, emailAdditionalContent = null) => {
    setShowAdditionalModal(false);
    if (isEmailSend) {
      sendByStep(emailAdditionalContent.mode, emailAdditionalContent.role, { ...emailAdditionalContent.data, is_email_send: true, selectedUser: emailAdditionalContent.data.selected_recipients });
    }
  }

  const onGenerateEsignatureLink = (emailAdditionalContent) => {
    const signatureLink = signers.find(signer => signer.request_signer.role === emailAdditionalContent.role)?.doc_signer_link || '';
    if (signatureLink) {
      setShowAdditionalModal(false);
      toast.success('E-Sign link generated successfully.');
    } else {
      sendByStep(emailAdditionalContent.mode, emailAdditionalContent.role, { ...emailAdditionalContent.data, is_email_send: false, selectedUser: emailAdditionalContent.data.selected_recipients });
    }
  }

  /**
     * On open Hand Sign document selection modal after selecting date
     * @param {*} role 
     * @param {*} signDate 
    */
  const onHandSignUploadModalOpen = (role, signDate) => {
    let userRole = '';
    switch (role) {
      case 'Owner':
        userRole = 'seller'
        break;

      case 'Co-Owner':
        userRole = 'co_seller'
        break;

      case 'Broker':
        userRole = 'broker'
        break;
    }
    const selectedRecipients = eDoc.request_signature?.signers?.find((signer) => signer.request_signer?.role === role) || {};
    const client = dataTransaction[userRole.toLowerCase().replace('-', '_')];
    setHandSignDetails({
      documentName: eDoc.t_esign_template?.template?.template_name,
      signDate: signDate,
      role: role,
      client: client,
      selectedRecipients: selectedRecipients.selected_recipients || []
    });
    if (signDate && signDate != null) {
      if (signDate && signDate != null) {
        setShowHandsignModal(true);
      }
    }
  }

  /**
   * On close Hand Sign modal and submit uploaded document to sendByStep() function
   * @param {*} isUploaded 
   * @param {*} documentUploadFiles 
  */
  const onCloseHandsignModal = (isUploaded, documentUploadFiles = null, selectedUser = []) => {
    setShowHandsignModal(false);
    if (isUploaded) {
      sendByStep("signed", handSignDetails.role, { signed_date: handSignDetails.signDate, documents: documentUploadFiles, selectedUser: selectedUser })
    }
    setHandSignDetails(null);
  }

  /**
   * On open view hand signed uploaded modal
   * @param {*} document 
  */
  const handleOpenViewDocumentModal = (document) => {
    setDocument(document);
    setShowDocument(true);
  }

  /**
   * On close view hand signed uploaded modal
  */
  const handleCloseModal = () => {
    setDocument(null);
    setShowDocument(false);
  }

  /**
   * Check this document is valid for preview or not
   * @returns True or False
  */
  const isValidPreview = () => {
    return listings.requiredFields.seller.length === 0 &&
      listings.requiredFields.vessel?.length === 0 &&
      listings.requiredFields.terms.length === 0
  };

  /**
   * Check this document is valid for send or not
   * @returns True or False
  */
  const isValidSend = () => {
    let valid = !listings.requiredFields.seller.some(obj => Object.keys(obj).includes('email'));

    if (dataTransaction.co_seller_contact_id) {
      valid = valid && !listings.requiredFields.co_seller.some(obj => Object.keys(obj).includes('email'))
    }

    return valid;
  };

  /**
   * This function is used to show a signed date of document.
   * @param {String} role 
   * @param {Date} signedDate 
   * @returns Date
  */
  const handleSignedDate = (role, signedDate) => {
    if (signedDate) {
      return moment(signedDate ? signedDate : null).utc().format("MM-DD-yyyy")
    }
    if (!signedDate) {
      if (eDoc.request_signature.signers.find(signer => signer.request_signer.role === role)?.status === 'Signed') {
        const timezone = moment.tz(moment.tz.guess()).zoneAbbr();
        return `${moment(eDoc.request_signature.signers.find(signer => signer.request_signer.role === role)?.esigned_date || null).local().format("MM-DD-yyyy hh:mm A")} ${timezone}`
      }
    }
    return null;
  }

  /**
   * Provides the eSign link for email send for signature
   * @param {*} role 
   * @returns 
  */
  const getEsignLink = (role) => {
    return signers.find(signer => signer.request_signer.role === role)?.doc_signer_link || '';
  }

  const [tooltipText, setTooltipText] = useState('Copy E-Sign Link');

  /**
   * Renders the button for eSign Link according to role of client
   * @param {*} role 
   * @returns Button for Link
   */
  const renderLinkButtons = (role) => {
    if (role.includes('broker') || role.includes('Broker')) {
      return <button style={{ height: '26px' }}
        className="uk-button uk-button-primary"
        onClick={() => { window.open(getEsignLink(role), '_blank'); }}>
        Sign </button>
    }

    return <Tooltip title={tooltipText} arrow>
      <button style={{ height: '26px', padding: '0 4px 0' }}
        className="uk-button uk-button-primary"
        onClick={() => {
          navigator.clipboard.writeText(getEsignLink(role));
          setTooltipText('E-Sign Link Copied..')
          setTimeout(() => {
            setTooltipText('Copy E-Sign Link');
          }, 800);
        }}>Copy E-Sign Link </button>
    </Tooltip>
  }

  /**
   * This fucntion is used to change the payload 
   * as per user manually checks the checkbox of particular checkbox
   * @param {Object} e //Event Object 
   * @param {String} role 
   * @returns 
  */
  const checkBoxandler = (e, role) => {
    let obj = {};
    obj[role] = {
      document_name: "listing-agreement",
      id: dataTransaction?.id
    };
    updateDocumentObj(obj, role, !e.target.checked, true);
  }

  const renderAction = (role) => {
    return (
      <CustomActionButtons
        role={role}
        signers={signers}
        listings={listings}
        isValidSend={isValidSend}
        handleOpenViewDocumentModal={handleOpenViewDocumentModal}
        previewByStep={previewByStep}
        onAdditionalMessageModalOpen={onAdditionalMessageModalOpen}
        onHandSignUploadModalOpen={onHandSignUploadModalOpen}
        dataTransaction={dataTransaction}
      />
    )
  }

  const renderStatus = (role) => {
    return (
      <CustomRenderStatus
        role={role}
        signedDate={getSigner(signers, role)?.signed_date}
        signers={signers}
        listings={listings}
        isValidSend={isValidSend}
        handleOpenViewDocumentModal={handleOpenViewDocumentModal}
        previewByStep={previewByStep}
        onAdditionalMessageModalOpen={onAdditionalMessageModalOpen}
        onHandSignUploadModalOpen={onHandSignUploadModalOpen}
        dataTransaction={dataTransaction}
        eDoc={eDoc}
        getEsignLink={getEsignLink}
        renderLinkButtons={renderLinkButtons}
        handleSignedDate={handleSignedDate}
      />
    )
  }

  /**
   * Function that used for Display Broker Name and Email Address as per the status 
   * If status is not Sent or Signed then display current Broker data otherwise display Signer data instead
   * @param {*} brokerType 
   * @returns Broker Name and Email Address 
  */
  const getBrokerNameAndEmail = (brokerType) => {
    return !["Sent", "Signed"].includes(signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === `${brokerType}_broker`)?.status)
      ? { name: brokerName, email: dataBroker.email }
      : {
        name: (signers && signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === `${brokerType}_broker`))?.request_signer?.name,
        email: (signers && signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === `${brokerType}_broker`))?.request_signer?.email_address
      }
  }

  return (
    <>
      <div className="uk-container uk-container-small uk-position-relative m-0">
        <div className="scroll-div">
          <div className="form-container scroll-fix-div">
            <div className="esign-send listing-agreement">
              <h4>
                <b>IYBA Listing Agreement</b>
              </h4>
              <FormRow><hr style={{ width: "100%" }} /></FormRow>
              <FormRow>
                <FormInputWrapper label="Vessel">{vesselName}</FormInputWrapper>
              </FormRow>
              <FormRow><hr style={{ width: "100%" }} /></FormRow>

              <div className="client-row">
                <FormInputWrapper label="Owner" className="signer-name-block">
                  {[SOLE_OWNER, JOINT_OWNER].indexOf(dataTransaction?.seller_type) > -1 && (
                    `${dataOwner.first_name} ${dataOwner.last_name}`
                  )}
                  {[TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.seller_type) > -1 && (
                    `${dataOwner?.trust_signer_name || 'N/A'}`
                  )}
                  {!([SOLE_OWNER, JOINT_OWNER, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.seller_type) > -1) && (
                    `N/A`
                  )}
                </FormInputWrapper>
                <FormInputWrapper label="Owner's Email" className="signer-email-block">
                  {[SOLE_OWNER, JOINT_OWNER].indexOf(dataTransaction?.seller_type) > -1 && (
                    `${dataOwner?.email}`
                  )}
                  {[TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.seller_type) > -1 && (
                    `${dataOwner?.trust_signer_email}`
                  )}
                  {!([SOLE_OWNER, JOINT_OWNER, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.seller_type) > -1) && (
                    `N/A`
                  )}
                </FormInputWrapper>
                <div className={!updateDocumentObj && "signers_div"}>
                  {
                    signers && getSigner(signers, "Owner")?.status
                      ? renderStatus("Owner")
                      : renderAction("Owner")
                  }
                </div>
                {eDoc && updateDocumentObj && !isSigned(signers, role) &&
                  <div className="col-1">
                    <input
                      type="checkbox"
                      className="signatures_checkbox"
                      onChange={(e) => { checkBoxandler(e, "owner") }}
                      checked={payload["owner"]?.find((obj) => obj.document_name == eDoc.task_type ? true : false) || false}
                      disabled={
                        (!dataTransaction || !isValidSend()) || selectedRole && selectedRole != "owner"
                      }
                    />
                  </div>
                }
              </div>
              <FormRow><hr style={{ width: "100%" }} /></FormRow>

              {dataCoOwner && (
                <>
                  <div className="client-row">
                    <FormInputWrapper label="Co-Owner" className="signer-name-block" >{coOwnerName}</FormInputWrapper>
                    <FormInputWrapper label="Co-Owner's Email" className="signer-email-block">
                      {dataCoOwner.email || "N/A"}
                    </FormInputWrapper>
                    <div className={!updateDocumentObj && "signers_div"}>
                      {(signers && (getSigner(signers, "Co-Owner") || getSigner(signers, "Principal")) && (
                        getSigner(signers, "Co-Owner"))
                        ? renderStatus("Co-Owner")
                        : renderAction("Co-Owner")
                      )}
                    </div>
                    {eDoc && updateDocumentObj && !isSigned(signers, "Co-Owner") &&
                      <div className="col-1">
                        <input
                          type="checkbox"
                          className="signatures_checkbox"
                          onChange={(e) => { checkBoxandler(e, "co_owner") }}
                          checked={payload.co_owner?.find((obj) => obj.document_name == eDoc.task_type ? true : false) || false}
                          disabled={
                            (!dataTransaction || !isValidSend()) || selectedRole && selectedRole != "co_owner"
                          }
                        />
                      </div>
                    }
                  </div>
                  <FormRow><hr style={{ width: "100%" }} /></FormRow>
                </>
              )}

              {dataTransaction?.employing_broker && !["Sent", "Signed"].includes(signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === `listing_broker`)?.status) ?
               ( <>
                  <div className="client-row">
                    <FormInputWrapper label="Employing Broker" className="signer-name-block">{`${dataTransaction.employing_broker?.first_name || "N/A"} ${dataTransaction.employing_broker?.last_name || "N/A"}`}</FormInputWrapper>
                    <FormInputWrapper label="Employing Broker's Email" className="signer-email-block">
                      {dataTransaction?.employing_broker?.email || "N/A"}
                    </FormInputWrapper>
                    <div className={!updateDocumentObj && "signers_div"}>
                      {signers && (
                        getSigner(signers, "Employing-Broker")?.status
                          ? renderStatus("Employing-Broker")
                          : renderAction("Employing-Broker")
                      )}
                    </div>
                    {eDoc && updateDocumentObj && !isSigned(signers, "Employing-Broker") &&
                      <div className="col-1">
                        <input
                          type="checkbox"
                          className="signatures_checkbox"
                          onChange={(e) => { checkBoxandler(e, "employing_broker") }}
                          checked={payload?.employing_broker?.find((obj) => obj.document_name == eDoc.task_type ? true : false) || false}
                          disabled={
                            (!dataTransaction || !isValidSend()) || selectedRole && selectedRole != "employing_broker"
                          }
                        />
                      </div>
                    }
                  </div>
                  <FormRow><hr style={{ width: "100%" }} /></FormRow>
                </>)
              :(
                <>
                  <div className="client-row">
                    <FormInputWrapper label="Listing Broker" className="signer-name-block">{getBrokerNameAndEmail('listing').name || "N/A"}</FormInputWrapper>
                    <FormInputWrapper label="Listing Broker's Email" className="signer-email-block">{getBrokerNameAndEmail('listing').name || "N/A"}</FormInputWrapper>
                    <div className={!updateDocumentObj && "signers_div"}>
                      {signers && (
                        getSigner(signers, "Listing-Broker")?.status
                          ? renderStatus("Listing-Broker")
                          : renderAction("Listing-Broker")
                      )}
                    </div>
                    {eDoc && updateDocumentObj && !isSigned(signers, "Listing-Broker") &&
                      <div className="col-1">
                        <input
                          type="checkbox"
                          className="signatures_checkbox"
                          onChange={(e) => { checkBoxandler(e, "listing_broker") }}
                          checked={payload?.listing_broker?.find((obj) => obj.document_name == eDoc.task_type ? true : false) || false}
                          disabled={
                            (!dataTransaction || !isValidSend()) || selectedRole && selectedRole != "listing_broker"
                          }
                        />
                      </div>
                    }
                  </div>
                  <FormRow><hr style={{ width: "100%" }} /></FormRow>
                </>)
              }

              <br />
              {requiredFields && requiredFields.length > 0 && (
                <>
                  <p>Required fields for Listing Agreement Form.</p>
                  <div className="form-row uk-padding-small">
                    <div>
                      {requiredFields.map((obj, key) => {
                        return key === requiredFields.length - 1
                          ? `${Object.values(obj)[0]}`
                          : `${Object.values(obj)[0]}, `;
                      })}
                    </div>
                  </div>
                </>
              )}
              {/* {requiredFields.vessel.length > 0 && (
            <div className="form-row uk-padding-small">
              <label className="uk-form-label">Vessel</label>
              <div>
                {requiredFields.vessel.map((obj, key) => {
                  return key === requiredFields.vessel.length - 1
                    ? `${Object.values(obj)[0]}.`
                    : `${Object.values(obj)[0]}, `;
                })}
              </div>
            </div>
          )} */}

              {listings.requiredFields.seller && listings.requiredFields.seller.some(obj => Object.keys(obj).includes('email')) && (
                <div className="form-row uk-margin-small">
                  <label className="uk-form-label">Seller</label>
                  <div>
                    {requiredFields.seller.map((obj, key) => {
                      return Object.values(obj)[0] == 'Email'
                        ? `${Object.values(obj)[0]}`
                        : ``;
                    })}
                  </div>
                </div>
              )}

              {dataCoOwner && listings.requiredFields.co_seller.some(obj => Object.keys(obj).includes('email')) && (
                <div className="form-row uk-margin-small">
                  <label className="uk-form-label" htmlFor="name">
                    Co-Seller (Send)
                  </label>
                  <div>
                    {requiredFields.co_seller.map((obj, key) => {
                      return Object.values(obj)[0] == 'Email'
                        ? `${Object.values(obj)[0]}`
                        : ``;
                    })}
                  </div>
                </div>
              )}

              {/* {requiredFields.terms.length > 0 && (
            <div className="form-row uk-margin-small">
              <label className="uk-form-label">Terms</label>
              <div>
                {requiredFields.terms.map((obj, key) => {
                  return key === requiredFields.terms.length - 1
                    ? `${Object.values(obj)[0]}.`
                    : `${Object.values(obj)[0]}, `;
                })}
              </div>
            </div>
          )} */}

            </div>
          </div>
        </div>
        <br />
      </div>
      {showAdditionalModal && <SignatureMessageModal
        show={showAdditionalModal}
        onClose={(isEmailSend, emailAdditionalContent = null) => onCloseAdditionalEmailModal(isEmailSend, emailAdditionalContent)}
        emailDetail={emailDetailsToSend}
        onGenerateLink={onGenerateEsignatureLink}
      />}
      {showHandsignModal && <HandSignUploadModal show={showHandsignModal} onClose={onCloseHandsignModal} signData={handSignDetails} />}
      {showDocument && <ViewDocumentsModal documentTask={document} onClose={handleCloseModal} />}
    </>
  );
};

export default AddEsign;
