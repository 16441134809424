import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  term_container: {
    margin: 'auto',
    padding: '64px 15px'
  },

  text: {
    margin: '1rem 0'
  }
});

const Terms = (props) => {
  const classes = useStyles();

  return (
    <div className={`uk-container ${classes.term_container}`}>
      <h1>Terms of Use</h1>
      <span>Last updated: July 20, 2022</span>
      <p className={classes.text}>
        Please read these Terms of Use (the “Terms”) carefully before using the https://boatdox.org website (the “Service”) operated by BoatDox LLC (“us,” “we,” or “our”).  BoatDox LLC is a not-for-profit Florida limited liability company wholly owned by and organized consistent with the purposes of the Florida Yacht Brokers Association, Inc., d/b/a International Yacht Brokers Association (the “IYBA”).  We provide a document processing platform, dashboards for every user, and an interface to help brokers and administrators collect information and complete the paperwork needed for smooth listings and sales.  Our goal is to provide these services to the IYBA members and the yachting industry in general.
        Any person accessing or using the Service is referred to as “you.”  Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms.  These Terms apply to all visitors, users and others who wish to access or use the Service.  By accessing or using the Service you agree to be bound by these Terms as may be modified by us from time to time without notice to you.  You are responsible for regularly reviewing the Terms.  You can review the most current version of the Terms at any time at: https://app.boatdox.org/terms.  If you disagree with any part of the Terms, then you do not have permission to access the Service.
      </p>
      <h3>General Use of Service</h3>
      <p className={classes.text}>TYou agree to access or use Service only for legal purposes that are permitted by the Terms. Among other things, you agree not to:</p>
      <p className={classes.text}>Except with our express written permission, modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, frame in another web page, use on any other website or application, transfer or sell any information, software, lists of users, databases or other lists, products or services provided through or obtained from the Service, including, without limitation, engaging in the practices of “screen scraping,” “database scraping,” or any other activity with the purpose of obtaining lists of users or other information.
      </p>
      <p className={classes.text}>
      Damage, disable, overburden, or impair the Service or interfere with any other party's use and enjoyment of the Service.
      </p>
      <p className={classes.text}>
      Obtain or attempt to obtain any materials or information through any means not made readily accessible by us through the Service.
      </p>
      <p className={classes.text}>
      Except with our express written permission, you agree that you will not access or attempt to access password-protected, secure, or non-public areas of the Service.
      </p>
      <h3>Communications</h3>
      <p className={classes.text}>
      By creating an Account on our Service, you agree to subscribe to newsletters, marketing or promotional materials, and other information we may send.  You have the choice to opt out of receiving certain marketing communications (email or text messages) you might receive from us or our affiliates (each referred to as a “Sender”).  Normally, a marketing email you receive from a Sender will have an opt-out link provided within the email itself.  Usually, you can opt out of receiving such marketing emails in the future by clicking on the opt-out link and indicating your decision to opt out.  If you receive marketing texts from a Sender, typically, you can opt out of receiving further such texts by following instructions provided in the text itself, often by responding “STOP” or by emailing our customer support team at info@boatdox.org and including sufficient information to allow the Sender to properly respond to your request (e.g., your full name, your complete phone number with area code, and an image or description of the marketing text you received).  Whether it's email or text, your request will be handled promptly but within a reasonable time.  Therefore, for some time, you might still receive further marketing emails or texts while the Sender processes your request.  Note also that opting out of marketing emails or texts may - but will not necessarily - cause you to stop receiving non-marketing emails or texts, such as texts about a purchase you may have made, a transaction you may have with a Sender, as well as other types (for example, about technical, administrative or informational matters).
      </p>
      <h3>Subscriptions</h3>
      <p className={classes.text}>
      Some parts of the Service are billed on a subscription basis (“Subscription(s)”).  You will be billed in advance on a recurring and periodic basis (“Billing Cycle(s)”).  Billing Cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.
      </p>
      <p className={classes.text}>
      At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you or us cancel it.  You may cancel your Subscription renewal either through your online account management page or by contacting our customer support team.
      </p>
      <p className={classes.text}>
      A valid payment method, including a credit card or PayPal, is required to process the payment for your Subscription.  You will need to provide us with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information.  By submitting such payment information, you automatically authorize us to charge all Subscription fees incurred through your account to any such payment instruments.
      </p>
      <p className={classes.text}>
      Should automatic billing fail to occur for any reason, we will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.
      </p>
      <h3>Fee Changes</h3>
      <p className={classes.text}>In our sole discretion and at any time, we may modify the Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.</p>
      <p className={classes.text}>We will provide you with a reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.Your continued use of the Service after the Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.
      </p>
      <h3>Refunds</h3>
      <p className={classes.text}>Except when required by law, paid Subscription fees are non-refundable.</p>
      <h3>Content</h3>
      <p className={classes.text}>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material (“Content”).  You are responsible for the Content that you post on or through the Service, including its legality, reliability, and appropriateness.</p>
      <p className={classes.text}>
      By posting Content on or through the Service, you represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and (ii) that the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.
      </p>
      <p className={classes.text}>You promise that you will not use the Service to:</p>
      <h3>Transmit any Content</h3>
      <p className={classes.text}>not related to appropriate subject matters;</p>
      <p className={classes.text}>which is misleading to others, including consumers;</p>
      <p className={classes.text}>that is unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libelous, invasive of another’s privacy, hateful, or racially, ethnically or otherwise objectionable;</p>
      <p className={classes.text}>that you do not have a right to post and transmit under any law or under contractual relationships;</p>
      <p className={classes.text}>such that such posting, uploading, or transmission constitutes the infringement of any patent, trademark, trade secret, copyright or other proprietary rights of any party;
      and materials that contain software viruses or any other computer code, files or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment; or
      </p>
      <p className={classes.text}>intentionally or unintentionally violate any applicable local, state, national or international law, or any regulations having the force of law, including the Civil Rights Act of 1866, the Americans with Disabilities Act, and the Equal Credit Opportunity Act.</p>
      <p className={classes.text}>You retain all your rights to any Content you submit, post, or display on or through the Service and you are responsible for protecting those rights.  We take no responsibility and assume no liability for Content you or any third party posts on or through the Service.  However, by transmitting Content using the Service, you grant, and you represent and warrant that you have the right to grant, to us an irrevocable, perpetual, non-exclusive, fully paid, worldwide license to use, copy, perform, display, and distribute the Content and to prepare derivative works of, or incorporate into other works, the Content, and to grant and authorize sublicenses (through multiple tiers) of the foregoing.  You agree that this license includes the right for us to make your Content available to other users of the Service, who may also use your Content subject to these Terms.</p>
      <p className={classes.text}>Furthermore, by posting Content to any public area of the Service, you grant us all rights necessary to prohibit any subsequent aggregation, display, copying, duplication, reproduction, or exploitation of the Content on the Service by any party for any purpose.</p>
      <p className={classes.text}>We have the right but not the obligation to monitor and edit all Content provided by users.  You acknowledge that we do not screen or approve third-party Content, and that we will have the right (but not the obligation) in our sole discretion to refuse, modify, delete, or move any Content that is available via the Service, for any reason.</p>
      <p className={classes.text}>We take matters of privacy and information seriously and work hard to maintain the privacy and security of all information that you provide to us through the Service in accordance with our Privacy Policy [hyperlink].  Our Privacy Policy applies to all Content and other information you provide, or that is collected from or about you, through your use of the Service, as provided for in our Privacy Policy.</p>
      <p className={classes.text}>In addition, Content found on or through this Service is our property or used with permission.  You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.</p>
      <h3>Accounts</h3>
      <p className={classes.text}>
      When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times.  Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service.
      </p>
      <p className={classes.text}>You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and account.  You agree to accept responsibility for all activities or actions that occur under your account or password, whether your password is with our Service or a third-party service.  You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
      <p className={classes.text}>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization.  You may not use as a username any name that is offensive, vulgar, or obscene.</p>
      <h3>Copyright Policy</h3>
      <p className={classes.text}>We respect the intellectual property rights of others.  It is our policy to respond to any claim that Content posted on the Service infringes on the copyright or other intellectual property rights (“Infringement”) of any person or entity.</p>
      <p className={classes.text}>If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to info@boatdox.org, with the subject line: “Copyright Infringement” and include in your claim a detailed description of the alleged Infringement as detailed below, under “DMCA Notice and Procedure for Copyright Infringement Claims.”</p>
      <p className={classes.text}>You may be held accountable for damages (including costs and attorneys’ fees) for misrepresentation or bad-faith claims on the Infringement of any Content found on or through the Service on your copyright.</p>
      <h3>DMCA Notice and Procedure for Copyright Infringement Claims</h3>
      <p className={classes.text}>You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C. 512(c)(3) for further detail):</p>
      <p className={classes.text}>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest.</p>
      <p className={classes.text}>A description of the copyrighted work that you claim has been infringed, including the URL (i.e., webpage address) of the location where the copyrighted work exists or a copy of the copyrighted work.</p>
      <p className={classes.text}>Identification of the URL or other specific location on the Service where the material that you claim is infringing is located.</p>
      <p className={classes.text}>Your address, telephone number, and email address.</p>
      <p className={classes.text}>A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.</p>
      <p className={classes.text}>
      A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.
      </p>
      <p className={classes.text}>You can contact our Copyright Agent via email at info@boatdox.org.</p>
      <h3>Intellectual Property</h3>
      <p className={classes.text}>The Service and its original content (excluding Content provided by users), features and functionality are – and will remain – the exclusive property of us and our licensors.  The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries.  Our trademarks and trade dress may not be used in connection with any product or service without our prior written consent.</p>
      <h3>Links to Other Web Sites</h3>
      <p className={classes.text}>Our Service may contain links to third-party websites or services that are not owned or controlled by us.  To be clear, we have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services.  We do not warrant the offerings of any of these entities/individuals or their websites.
      You acknowledge and agree that we will not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third-party websites or services.
      </p>
      <p className={classes.text}>We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit</p>
      <h3>Termination</h3>
      <p className={classes.text}>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, at our sole discretion, for any reason whatsoever and without limitation.  You further agree that use of the Service will be immediately terminated if you violate these Terms.  In addition, we reserve the right, in our sole discretion, to modify or discontinue the Service or any portion thereof, with or without notice, and without liability to you.</p>
      <p className={classes.text}>If you wish to terminate your account, you may simply discontinue using the Service.  All provisions of the Terms which by their nature should survive termination, will survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>
      <h3>No Waiver</h3>
      <p className={classes.text}>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.  These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding the Service.</p>
      <h3>Governing Law and Venue</h3>
      <p className={classes.text}>hese Terms will be governed and construed in accordance with the laws of the State of Florida, United States, without regard to its conflict of law provisions.  Any proceeding arising out of or relating to this Agreement will be brought in the state (not including federal) courts located in Broward County, Florida, and each of the parties irrevocably submits to the exclusive jurisdiction of such court, waives any objection they may now or hereafter have to venue or to convenience of forum, agree that all claims in respect of the proceeding shall be heard and determined only in any such court, and agree not to bring any proceeding arising out of or relating to this Agreement in any other court.</p>
      <p className={classes.text}>EACH OF THE PARTIES HERETO HEREBY IRREVOCABLY WAIVES ANY AND ALL RIGHT TO TRIAL BY JURY IN ANY LEGAL PROCEEDING ARISING OUT OF OR RELATED TO THESE TERMS AND THE SERVICE.</p>
      <h3>Interpretation of the Terms of Use</h3>
      <p className={classes.text}>If any part of the Terms is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations contained herein, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the Terms will continue in effect.  Unless otherwise specified herein (or in any other definitive written agreement between you and us), the Terms constitute the entire agreement between you and us with respect to the Service and supersede all prior or contemporaneous communications and proposals, whether electronic, oral or written, between you and us with respect to the Service.  A printed version of the Terms and of any notice given in electronic form will be admissible in judicial or administrative proceedings based upon or relating to the Terms to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.</p>
      <p className={classes.text}>You agree that no joint venture, partnership, employment, or agency relationship exists between you and us as a result of the Terms or accessing or using the Service.  Our performance under the Terms is subject to existing laws and legal process, and nothing contained in the Terms is in derogation of our right to comply with governmental, court and law enforcement requests or requirements relating to your access or use of the Service or information provided to or gathered by us with respect to such use.</p>
      <h3>Changes</h3>
      <p className={classes.text}>We reserve the right, at our sole discretion, to modify or replace these Terms at any time.  If a revision is material, we will provide at least 30-day notice prior to any new Terms taking effect.  What constitutes a material change will be determined at our sole discretion.</p>
      <p className={classes.text}>By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised Terms.  If you do not agree to the new Terms, you are no longer authorized to use the Service.</p>
      <h3>Disclaimer of Warranties</h3>
      <p className={classes.text}>You understand and agree that (a) your use of and reliance upon the Service or Content is at your sole risk; (b) the Service and Content is provided on an “AS IS” and “AS AVAILABLE” basis.  </p>
      <p className={classes.text}>WE PROVIDE THE SERVICE AND CONTENT WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR COURSE OF PERFORMANCE. WE MAKE NO EXPRESS OR IMPLIED REPRESENTATIONS, WARRANTIES, OR GUARANTEES WITH REGARD TO THE APPROPRIATENESS, ACCURACY, SUFFICIENCY, CORRECTNESS, VERACITY, VALUE, COMPLETENESS, OR TIMELINESS OF THE SERVICE OR CONTENT.</p>
      <p className={classes.text}>We, our subsidiaries, affiliates, and licensors do not warrant that (i) the Service will function uninterrupted, secure or be available at any particular time or location; (ii) any errors or defects will be corrected; (iii) the Service is free of viruses or other harmful components; or (iv) the results of using the Service will meet your requirements.</p>
      <h3>Indemnification</h3>
      <p className={classes.text}>You agree to defend, indemnify and hold harmless us, our licensees and licensors, third-party vendors, corporate affiliates, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorneys' fees), resulting from or arising out of (i) your use and access of the Service, by you or any person using your account and password; (ii) a breach of these Terms, or (iii) Content posted on the Service.</p>
      <h3>Limitation of Liability</h3>
      <p className={classes.text}>Any services or content made available or obtained through the use of the Service, and all other use of the Service, is done at your own discretion and risk and you will be solely responsible for any damage to your computer system or loss of data that results therefrom.</p>
      <p className={classes.text}>If you are dissatisfied with any portion of the Service, or with any of these Terms, your sole and exclusive remedy is to discontinue using the Service.</p>
      <p className={classes.text}>In no event will we, nor our directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) Service’s performance, delay or inability to perform or provide Service; (iii) any content, software, products or services made available or obtained through the Service; (iv) conduct of any third party on the Service; (v) unauthorized access, use or alteration of your transmissions or content, or otherwise, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p> 
      <h3>Exclusions</h3>
      <p className={classes.text}>Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.</p>
      <h3>Copyright</h3>
      <p className={classes.text}>All materials on the Service (as well as the organization and layout of the Service) are owned and copyrighted or licensed by BoatDox LLC, its corporate affiliates, or its third-party vendors.   No reproduction, distribution, or transmission of the copyrighted materials on the Service is permitted without our written permission. Any rights not expressly granted herein are reserved.</p>
      <p className={classes.text}>Pursuant to Title 17, United States Code, Section 512, notifications of claimed copyright infringement should be sent to our Designated Agent set forth below. All inquiries must be filed in accordance with Title 17, United States Code, Section 512.</p>
      <h3>BoatDox LLC's Legal Department</h3>
      <p className={classes.text}>c/o Robert Allen Law 1441 Brickell Avenue, Suite 1400</p>
      <h3>Miami, FL 33131</h3>
      <p className={classes.text}>
      Attn: Director of Compliance and Adrian Z. Karborani, Esq. Telephone: (305) 372-3300 Email: info@boatdox.org; akarborani@robertallenlaw.com
      </p>
      <h3>Trademarks</h3>
      <p className={classes.text}>
      IYBA is a trademark of the Florida Yacht Brokers Association, Inc.  All other brands, taglines, and stylized logo treatments are trademarks of their respective owners and are used with their permission.
      </p>
    </div>
  );
};

export default Terms;
