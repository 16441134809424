import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Auth from '../../../../../utils/auth';
import FormPage from "../../../../common/FormPage";
import FormRow from "../../../../common/FormRow";

import { convertFirstCharIntoSmallCase, isSigned, unauthorizedErrorToast } from '../../../../../utils/functions';
import { setLoadingStatus } from '../../../../../redux/actions/loading';
import SignatureMessageModal from '../../../../common/SignatureMessageModal';
import FormInputWrapper from '../../../../common/FormInputWapper';
import { getHandSignedDocumentPath, momentDateWithTimezone } from '../../../../../utils/common';
import { eSignCYBATransactionSummary, getTransactionEdocById } from '../../../../../redux/actions/transaction';
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { toast } from 'react-toastify';

const useStyles = makeStyles({
    previewSendDiv: {
        width: "250px !important"
    },
    handSignedDiv: {
        marginTop: "-4px"
    }
});

/**
* @param {Function} updateDocumentObj // It is used to update payload object
* @param {String} selectedRole //It is used to check which role is selected from signature tab
* @param {Object} payload //Prepared object for the back-end
* @param {Function} makeDropDownSigners // Send signers array to the signature tab for displaying signer drop-down
* @returns 
*/
const EsignCYBATransactionSummary = ({ transactionId, updateDocumentObj, makeDropDownSigners, selectedRole, payload, roles, menuType }) => {

    const dispatch = useDispatch();

    const [isSuccess, setSuccess] = useState(undefined);
    const [isError, setError] = useState(undefined);
    const [message, setMessage] = useState("");
    const [showAdditionalModal, setShowAdditionalModal] = useState(false);
    const [emailDetailsToSend, setEmailDetailsToSend] = useState(null);

    //Get logged User
    const user = Auth.getInstance().getUserData();
    const classes = useStyles();

    const deals = useSelector((state) => state.transactions);
    const dataTransaction = deals.transaction || {};
    const dataBroker = dataTransaction.broker;
    const dataVessel = dataTransaction.t_vessel;
    const requiredFields = deals ? deals.requiredOptionFields : [];

    const eDoc = deals.transaction
        ? deals.transaction.t_edocuments.find(doc => doc.task_type === "cyba-transaction-summary")
        : null;

    const signers = eDoc && eDoc.request_signature ? eDoc.request_signature.signers : [];

    const links = [
        { label: "Deals", link: `/deals/view/${transactionId}/main` },
        { label: "CYBA Transaction Summary", link: "" }
    ];

    /**
     * useEffect to generate the array of signers which is available 
     * for this document only
    */
    useEffect(() => {
        if (!updateDocumentObj || !isValidSend()) {
            return;
        }
        let signerArr = [];

        if (dataTransaction?.employing_broker && !isSigned(signers, "employing_broker")) {
            signerArr.push("employing_broker")
        } else if (!dataTransaction?.employing_broker && !isSigned(signers, "broker")) {
            signerArr.push("broker")
        }

        makeDropDownSigners(signerArr);
    }, [dataTransaction, signers.length]);

    //Apply use Effect when role is changed from the signature tab
    useEffect(() => {
        /**
        * Check selected role is included for this document &
        * Check the selected role's status is not equal to signed &
        * Check this document has proper data for the sign this document.
        */
        if (selectedRole && roles.includes(selectedRole)
            && (signers && signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === selectedRole)?.status != "Signed")
            && dataTransaction && isValidSend()) {

            //Prepare object for the payload
            let obj = {}
            obj[selectedRole] = {
                document_name: "cyba-transaction-summary",
                id: dataTransaction?.id
            };

            //Update payload object
            updateDocumentObj(obj, selectedRole);
            return;
        }
    }, [selectedRole]);

    //Define vessel name
    let vesselName = `${dataVessel?.length || ""}' ${dataVessel?.make || ""} ${dataVessel?.model || ""} ${dataVessel?.year || ""}` !== "'   "
        ? `${dataVessel?.length || ""}' ${dataVessel?.make || ""} ${dataVessel?.model || ""} ${dataVessel?.year || ""}`
        : "N/A";

    //Define broker name
    let brokerName = dataBroker
        ? `${dataBroker.first_name || ""} ${dataBroker.last_name}` === " "
            ? "N/A"
            : `${dataBroker.first_name || ""} ${dataBroker.last_name}`
        : "N/A";

    /**
     * @param {Object} evt // Event Object
     * @param {String} role
    */
    const previewByStep = (evt, role) => {
        dispatch(setLoadingStatus(true));
        dispatch(eSignCYBATransactionSummary(dataTransaction.id, "preview", role)).then((res) => {
            dispatch(setLoadingStatus(false));
            if (res.status !== 200) {
                throw res;
            } else {
                window.open(res.data.url + '?tm=' + (new Date()).getTime(), '_blank');
            }
        }).catch((err) => {
            dispatch(setLoadingStatus(false));
            switch (err.status) {
                case 400:
                    setMessage(`${err.data.error.message}`);
                    break;
                case 401:
                    toast.error("You are not authorized to access this action.")
                    break;
                default:
                    setMessage(`${err.data.message}`);
                    break;
            }
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 5000);
        });
    }

    /**
        * @param {Object} evt // Event Object
        * @param {String} mode //It can be "send" or "hand-signed"
        * @param {*} role 
        * @param {*} data // selected date for the hand signed
    */
    const sendByStep = (mode, role, data = null) => {
        let obj = {};
        if (data) {
            obj["signed_date"] = data.signed_date;
            obj["signed_document"] = data.document_url;
            obj["additional_email_content"] = data.additional_email_content || '';
        }
        obj["requested_by"] = user.id
        dispatch(setLoadingStatus(true));
        dispatch(eSignCYBATransactionSummary(dataTransaction.id, mode, role, obj)).then(res => {
            dispatch(setLoadingStatus(false));
            if (res.status === 200) {
                dispatch(getTransactionEdocById(deals.transaction.id, menuType === 'invitation', dataTransaction?.company_id));
                setMessage("Document sent successfully.");
                setSuccess(true);
                setTimeout(() => {
                    setSuccess(undefined);
                }, 3000);
            } else {
                switch (res.status) {
                    case 400:
                        setMessage(`${res.data.error.message}`);
                        break;
                    case 401:
                        unauthorizedErrorToast()
                        break;
                    default:
                        toast.error(`Something went wrong while upload hand signed documents.`);
                        setMessage(`${res.data.message}`);
                        break;
                }
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 5000);
            }
        });
    }

    const onAdditionalMessageModalOpen = (mode, role, data = null) => {
        const selectedRecipients = eDoc.request_signature?.signers?.find((signer) => signer.request_signer?.role === role) || {};
        setEmailDetailsToSend({ mode: mode, role: role, data: data, selectedRecipients: selectedRecipients.selected_recipients || [] });
        setShowAdditionalModal(true);
    }

    const onCloseAdditionalEmailModal = (isEmailSend, emailAdditionalContent = null) => {
        setShowAdditionalModal(false);
        if (isEmailSend) {
            sendByStep(emailAdditionalContent.mode, emailAdditionalContent.role, { ...emailAdditionalContent.data, selectedUser: emailAdditionalContent.data.selected_recipients })
        }
    }

    /**
     * Check this document is valid for preview or not
     * @returns True or False
    */
    const isValidPreview = () => {
        return deals.requiredFields.co_broker.length === 0;
    };

    /**
     * Check this document is valid for send or not
     * @returns True or False
    */
    const isValidSend = () => {
        let valid = !deals.requiredFields.buyer.some(obj => Object.keys(obj).includes('email')) &&
            !deals.requiredFields.seller.some(obj => Object.keys(obj).includes('email'));

        if (dataTransaction.co_buyer_contact_id) {
            valid = valid && !deals.requiredFields.co_buyer.some(obj => Object.keys(obj).includes('email'))
        }

        if (dataTransaction.co_seller_contact_id) {
            valid = valid && !deals.requiredFields.co_seller.some(obj => Object.keys(obj).includes('email'))
        }

        return valid;
    };

    /**
     * This function is used to render a action button link preview, send and hand-signed.
     * @param {String} role 
     * @returns HTML
    */
    const renderActionButtons = (role) => {
        return (
            <div className="status-main-block">
                <div className="signer-status-block"></div>
                <div className="signer-button-block">
                    <button
                        className="uk-button uk-button-default"
                        type="button"
                        id="preview-cyba-transaction-summary-for-esign"
                        onClick={(evt) => {
                            const signed_document = signers.find(signer => signer.request_signer.role === role)?.signed_document || null;
                            signed_document
                                ? window.open(getHandSignedDocumentPath(signed_document), "_blank")
                                : previewByStep(evt, role);
                        }}
                        disabled={!dataTransaction}
                    ><i className='fa fa-cloud-download' area-hidden="true"></i>&nbsp;Preview
                    </button>
                    <div>
                        <button
                            className={updateDocumentObj ? "uk-button uk-button-primary" : "uk-button uk-button-primary"}
                            id="send-cyba-transaction-summary-for-esign"
                            onClick={(evt) => onAdditionalMessageModalOpen("send", role)}
                            disabled={
                                !dataTransaction || !isValidSend()
                            }
                        >Send Link
                        </button>
                    </div>
                </div>
            </div>
        )
    };

    /**
    * This function is used to show a signed date of document.
    * @param {String} role 
    * @param {Date} signedDate 
    * @returns Date
    */
    const renderStatus = (role, signedDate) => {
        return (
            <div className="status-main-block">
                <div className="signer-status-block">
                    <label className="uk-form-label">
                        Status
                    </label>
                    <span>
                        {eDoc.request_signature.signers.find(signer => signer.request_signer.role === role).status}
                        {eDoc.request_signature.signers.find(signer => signer.request_signer.role === role).status == 'Declined' &&
                            <OverlayTrigger
                                trigger={['hover', 'focus']}
                                placement="right"
                                overlay={
                                    <Popover id={`popover-positioned-`}>
                                        <Popover.Title as="h6">Reason</Popover.Title>
                                        <Popover.Content>{eDoc.request_signature.signers.find(signer => signer.request_signer.role === role).note}</Popover.Content>
                                    </Popover>
                                }>
                                <img src="/icons/comment.svg" alt="comment" className="rejectReason" />
                            </OverlayTrigger>
                        }
                    </span>
                    <span className='d-block' style={{ fontSize: '10px' }}>
                        {eDoc.request_signature.signers.find(signer => signer.request_signer.role === role).status == 'Sent'
                            ? `${momentDateWithTimezone(eDoc.request_signature.signers.find(signer => signer.request_signer.role === role).updatedAt)}`
                            : ''
                        }
                    </span>
                </div>
                <div className="signer-button-block">
                    <div>
                        <button
                            className="uk-button uk-button-default"
                            type="button"
                            id="preview-cyba-transaction-summary-for-esign"
                            onClick={(evt) => {
                                const signed_document = signers.find(signer => signer.request_signer.role === role)?.signed_document || null;
                                signed_document
                                    ? window.open(getHandSignedDocumentPath(signed_document), "_blank")
                                    : previewByStep(evt, role);
                            }}
                            disabled={!dataTransaction}
                        ><i className='fa fa-cloud-download' area-hidden="true"></i>&nbsp;Preview
                        </button>
                    </div>
                    <div>
                        <button
                            className={updateDocumentObj ? "uk-button uk-button-primary" : "uk-button uk-button-primary"}
                            id="send-cyba-transaction-summary-for-esign"
                            onClick={(evt) => onAdditionalMessageModalOpen("send", role)}
                            disabled={
                                !dataTransaction || !isValidSend() || isSigned(signers, role)
                            }
                        >Send Link
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    /**
     * This fucntion is used to change the payload 
     * as per user manually checks the checkbox of particular checkbox
     * @param {Object} e //Event Object 
     * @param {String} role 
     * @returns 
    */
    const checkBoxandler = (e, role) => {
        if (!isValidSend()) {
            return;
        }
        let obj = {};
        obj[role] = {
            document_name: "cyba-transaction-summary",
            id: dataTransaction?.id
        };
        updateDocumentObj(obj, role, !e.target.checked, true);
    }

    /**
     * Function that used for Display Broker Name and Email Address as per the status 
     * If status is not Sent or Signed then display current Broker data otherwise display Signer data instead
     * @returns Broker Name and Email Address 
    */
    const getBrokerNameAndEmail = () => {
        return !["Sent", "Signed"].includes(signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === `broker`)?.status)
            ? { name: brokerName, email: dataBroker.email }
            : {
                name: (signers && signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === `broker`))?.request_signer?.name,
                email: (signers && signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === `broker`))?.request_signer?.email_address
            }
    }

    return (
        <>
            <div className="uk-container uk-container-small uk-position-relative m-0">
                <div className="scroll-div">
                    <div className="form-container scroll-fix-div">
                        <div className="esign-send listing-agreement">
                            {!updateDocumentObj &&
                                <>
                                    {isSuccess ? (
                                        <div className="uk-alert uk-alert-primary" data-uk-alert>
                                            <p>{message}</p>
                                        </div>
                                    ) : isError ? (
                                        <div className="uk-alert uk-alert-danger" data-uk-alert>
                                            <p>{message}</p>
                                        </div>
                                    ) : null}
                                </>
                            }

                            <h4>
                                <b>{eDoc?.t_esign_template?.template?.template_name}</b>
                            </h4>
                            <FormRow><hr /></FormRow>
                            <FormRow>
                                <FormInputWrapper label="Vessel">{vesselName}</FormInputWrapper>
                            </FormRow>
                            <FormRow><hr /></FormRow>

                            {dataBroker &&
                                <>
                                    <div className="client-row">
                                        <FormInputWrapper label="Broker" className="signer-name-block">{getBrokerNameAndEmail().name || "N/A"}</FormInputWrapper>
                                        <FormInputWrapper label="Broker's Email" className="signer-email-block">{getBrokerNameAndEmail().email || "N/A"}</FormInputWrapper>
                                        {eDoc &&
                                            <div className={!updateDocumentObj && "signers_div"}>
                                                {signers.find(signer => signer.request_signer.role === 'Broker')
                                                    ? renderStatus("Broker", signers.find(signer => signer.request_signer.role === "Broker").signed_date)
                                                    : renderActionButtons("Broker")}
                                            </div>
                                        }
                                        {eDoc &&
                                            updateDocumentObj && !isSigned(signers, "Broker") &&
                                            <div className="col-1">
                                                <input
                                                    type="checkbox"
                                                    className="signatures_checkbox"
                                                    onChange={(e) => { checkBoxandler(e, "broker"); }}
                                                    checked={payload?.broker?.find((obj) => obj.document_name == eDoc.task_type ? true : false) || false}
                                                    disabled={
                                                        (!dataTransaction || !isValidSend(deals.requiredFields)) || selectedRole && selectedRole != "broker"
                                                    }
                                                />
                                            </div>
                                        }
                                    </div>
                                    <FormRow><hr /></FormRow>

                                </>
                            }

                            <br />
                            {requiredFields && requiredFields.length > 0 && (
                                <div className="form-row uk-padding-small">
                                    <p style={{ color: '#5479A3' }}>Required fields for CYBA Transaction Summary.</p>
                                    <div>
                                        {requiredFields.map((obj, key) => {
                                            return key === requiredFields.length - 1
                                                ? `${Object.values(obj)[0]}`
                                                : `${Object.values(obj)[0]}, `;
                                        })}
                                    </div>
                                </div>
                            )}
                            {/* {deals.requiredFields.offer.length !== 0 && (
                        <div className="form-row uk-margin-small">
                            <label className="uk-form-label" htmlFor="name">
                                Offer (Send)
                            </label>
                            <div>
                                {deals.requiredFields.offer.map((obj, key) => {
                                    return key === deals.requiredFields.offer.length - 1
                                        ? `${Object.values(obj)[0]}`
                                        : `${Object.values(obj)[0]}, `;
                                })}
                            </div>
                        </div>
                    )} */}
                        </div>
                    </div>
                </div>
                <br />
            </div>
            <SignatureMessageModal show={showAdditionalModal} onClose={(isEmailSend, emailAdditionalContent = null) => onCloseAdditionalEmailModal(isEmailSend, emailAdditionalContent)} emailDetail={emailDetailsToSend} isNoGenerateLink={true} />
        </>
    )
}

export default EsignCYBATransactionSummary;