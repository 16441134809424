const ThemeButton = ({ theme, children, ...props }) => {
  if (!theme) {
    theme = 'default';
  }

  return (
    <button className={`uk-button uk-button-${theme}`} {...props}>
      {children}
    </button>
  )
}

export default ThemeButton;
