import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import clsx from "clsx";
import PageSidebarContainer from "../../../../common/PageSidebarContainer";
import { getTransactionInitialById, updateOptionsRequiredFields } from "../../../../../redux/actions/transaction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const YBAAListingAgreementStep = () => {
  let location = useLocation();
  let { id } = useParams();
  const dispatch = useDispatch();
  const dataTransaction = useSelector((state) => state.transactions.transaction);

  const history = useHistory();

  useEffect(() => {
    dispatch(getTransactionInitialById(id));
  }, [])

  let branch = location.pathname.split("/").pop();

  return (
    <PageSidebarContainer type="add-deal">
      <div className="sidebar-container add-deal">
        <ul className="uk-nav uk-nav-default tm-nav">
          {/* <Link
            to={`/listing-agreement/view/${id}/ybaa-us-central-listing-agreement/send_to_signer`}
          >
            <li
              className={clsx("add-deal chain-step", {
                current: branch === "send_to_signer",
              })}
            >
              <i className="fa fa-circle-o"></i>
              <span className="add-deal-step">Send for eSignature</span>
            </li>
          </Link> */}

          {
            dataTransaction?.id &&
            // <li
            //   name="back-to-eDocuments"
            //   id="tab-back-to-eDocuments"
            //   className={clsx("add-deal chain-step")}
            // >
            //   <Link to={`/listing-agreement/view/${dataTransaction.id}/main#tab=%22edocuments%22`}><span uk-icon="chevron-double-left"></span>Back to eDocuments</Link>
            // </li>
            <button className="uk-button uk-button-primary mt-2" onClick={() => history.push(`/listing-agreement/view/${dataTransaction.id}/main#tab=%22edocuments%22`)}>
              <span uk-icon="chevron-double-left"></span>Back to eDocuments
            </button>
          }


        </ul>
      </div>
    </PageSidebarContainer>
  );
};

export default YBAAListingAgreementStep;
