const AsterixTypo = ({ isVisible }) => {
  if (!isVisible) return <></>;

  return (
    <p>
      * Required fields for the purchase and sale agreement.
    </p>
  );
}

export default AsterixTypo;
