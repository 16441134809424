import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";

import {
  removeDocumentTemplate,
  findTemplateByName,
} from "../../../redux/actions/document_tasks";

import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import datatableStyles from "../../../utils/datatable-style";
import { DebounceInput } from "react-debounce-input";
import { DELETE_MODAL_MESSAGE, DELETE_MODAL_TITLE } from "../../../constants/Alert";
import moment from 'moment';

const ListDocuments = () => {
  const [searchName, setSearchName] = useState("");
  const dataTemplates = useSelector((state) => state.documents.templates || []);
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  useEffect(() => {
    dispatch(findTemplateByName(searchName));
  }, [searchName]);

  const onDelete = (id) => {
    confirmAlert({
      title: DELETE_MODAL_TITLE,
      message: DELETE_MODAL_MESSAGE,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const data = {
              deleted: true,
              deleted_at: new Date(),
            };
            dispatch(removeDocumentTemplate(id))
              .then((response) => {
                dispatch(findTemplateByName(searchName));
              })
              .catch((e) => {
                alert("Error = ", e);
              });
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const columns = [
    {
      name: "Template Name",
      selector: "template_name",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            <Link to={{ pathname: `${path}/view`, state: { id: row.id } }}>{row.template_name}</Link>
          </div>
        </div>
      ),
    },
    {
      name: "Type",
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.checklist?.name || "N/A"
            }
          </div>
        </div>
      ),
    },
    {
      name: "Sub Type",
      grow: 2,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.iyba_task?.title || "N/A"
            }
          </div>
        </div>
      ),
    },
    {
      name: "Hand Signed",
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.is_hand_signed ? "Yes" : "No"
            }
          </div>
        </div>
      ),
    },
    {
      name: "Updated",
      selector: "updatedAt",
      sortable: true,
      cell: (row) => (
        <div>
          {moment(row.updatedAt).format('MMM DD')}
        </div>
      ),
    },
    // { name: "Office Phone", selector: "office_phone", sortable: true },
    // { name: "Timezone", selector: "timezone", sortable: true },
    {
      name: "",
      cell: (row) => (
        <div>
          <Link to={{ pathname: `${path}/edit`, state: { id: row.id } }}>
            <img src="/icons/edit.svg" className="svg uk-margin-right" alt="" />
          </Link>
          <img src="/icons/delete.svg" className="svg" onClick={() => onDelete(row.id)} alt="" />
        </div>
      ),
    },
  ];

  const sortedData = dataTemplates.sort((a, b) => (b.template_name > a.template_name ? -1 : 1));

  return (
    <div className="uk-container uk-container-expand uk-position-relative">
      <div className="content">
        <h1>Document Templates</h1>

        <div className="uk-column-1-2  uk-margin-small">
          <Link to={`${path}/add`}>
            <div className="uk-button uk-button-default add-deal">
              <span className="uk-margin-small-right uk-icon" data-uk-icon="plus"></span> Add
            </div>
          </Link>
          <div className="list-page-search-container">
            <form className="uk-search uk-search-default">
              <DebounceInput
                className="uk-search-input"
                type="search"
                placeholder="Search"
                onChange={(e) => setSearchName(e.target.value)}
                value={searchName}
                debounceTimeout={500}
                minLength={1}
              />
              {searchName === "" ? (
                <span className="bd-form-icon">
                  <i className="fa fa-search"></i>
                </span>
              ) : (
                <span className="bd-form-icon" onClick={() => setSearchName("")}>
                  <i className="fa fa-times"></i>
                </span>
              )}
            </form>
          </div>
        </div>

        {/* Offices List Table */}
        <DataTable
          title="Offices Table"
          columns={columns}
          data={sortedData}
          striped={true}
          highlightOnHover={true}
          pointerOnHover={true}
          noHeader={true}
          fixedHeader={true}
          pagination={sortedData.length > 10}
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 20, 50, 100]}
          customStyles={datatableStyles}
        />
      </div>
    </div>
  );
};

export default ListDocuments;
