import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import Country from "../../../../../constants/Country";
import State from "../../../../../constants/StatesForUSA";
import { phoneNumber } from "../../../../../utils/phonenumber";
import { getDealSellerInfo } from "../../../../../utils/customer";

const DetailOfSeller = ({ seller, type }) => {
  const seller_info = getDealSellerInfo(type, seller)

  return (
    <>
      <FormRow>
        <FormInputWrapper label="Name">
          <div className="input-view">{seller_info.name}</div>
        </FormInputWrapper>
        <FormInputWrapper label="Entity Name">
          <div className="input-view">{seller_info.entity_name || "N/A"}</div>
        </FormInputWrapper>
        {/* <FormInputWrapper label="Owner of Record">
          <div className="input-view">{ownerOfRecord ? "Yes" : "No"}</div>
        </FormInputWrapper> */}
      </FormRow>
      <FormRow>
        <FormInputWrapper size="two" label="Email *">
          <div className="input-view">
            {seller_info.email ? <a href={`mailto:${seller_info.email}`} target="_blank">{seller_info.email}</a> : "N/A"}
          </div>
        </FormInputWrapper>
        <FormInputWrapper label="Mobile Phone">
          <div className="input-view">
            {seller_info.phone}
          </div>
        </FormInputWrapper>
        <FormInputWrapper label="Citizen Of">
          <div className="input-view">{seller_info.nationality || "N/A"}</div>
        </FormInputWrapper>
      </FormRow>
      <FormRow>
        <FormInputWrapper size="three" label="Address">
          <div className="input-view">{seller_info.address} {seller_info.unit || ""}</div>
          <div className="input-view">{seller_info.city_state_zip}</div>
          <div className="input-view">{seller_info.country}</div>
        </FormInputWrapper>
      </FormRow>
    </>
  );
};

export default DetailOfSeller;
