import http, { setHeaders } from "../http-common";
import Auth from "../utils/auth";

setHeaders(Auth.getInstance().getAuthToken() + " " + Auth.getInstance().getIDToken());

const getAll = () => {
  return http.get("/clients");
};

const get = (id) => {
  return http.get(`/clients/${id}`);
};

const getClient = (id) => {
  return http.get(`/clients/transaction/client/${id}`);
};

const create = (data) => {
  return http.post("/clients", data);
};

const update = (id, data) => {
  return http.put(`/clients/${id}`, data);
};

const remove = (id) => {
  return http.delete(`/clients/transactionClient/${id}`);
};

const removeAll = () => {
  return http.delete("/clients");
};

const findByName = (name, company_id, company_office_id, user_id, permission) => {
  return http.post("/clients/searchByName", { search: name, company_id, company_office_id, user_id, permission });
};

const getCRMClientList = () => {
  return http.get('/clients/crm/list');
}

const findOneByName = (name) => {
  return http.post("/clients/searchOne", { name: name });
};

const ClientsService = {
  getAll,
  get,
  getClient,
  create,
  update,
  remove,
  removeAll,
  findByName,
  findOneByName,
  getCRMClientList
};

export default ClientsService;
