const boatMakes = `7oceans
AB
AB Inflatables
ACM
ARK Yacht
Abaco
Abacus
Abati Yachts
Abbott
Abeking & Rasmussen
Ablemarle
Absolute
Action Craft
Adamaris
Admiral
Aicon
Airship
Albemarle
Alberg
Albin
Alden
Alerion
Allied
Aloha
Alpha Craft
Alpha Custom Yachts
Altima
Amel
Amels
American Tug
Andrews
Andros
Angel
Angler
Antago
Antique
Aquarius
Aquasport
Aquila
Arno Leopard
Astondoa
Atlantic
Atlas
Avalon
Avangard Yachts
Axopar
Azimut
Azov
BRESIL
Back Cove
Bahama
Baia
Balance
Barker Boatworks
Bavaria
Bayfield
Bayliner
Beachcomber
Belize
Bell Composites
Belliure
Beneteau
Beneteau America
Benetti
Benford
Bennington
Bentley Pontoons
Berkshire
Bertram
Bestway
Bilgin
Billfish
Birdsall
Black Watch
Blackfin
Blackman
Bloemsma
Blue Water
Blue Wave
Bluegame
Bluewater
Bluewater Sportfishing
Bluewater Yachts
Bombardier
Bombay Clipper
Boston Whaler
Bostrom
Bowman
Brabus
Breaux Bay Craft
Breaux Brothers
Brewer
Bristol
Brooke Yachts
Broward
Bruce Roberts
Bruckmann
Bruno & Stillman
Buddy Davis
Bulls Bay
Burger
C&C
C-Hawk
C.W. Hood
CAL
CHB
CL Yachts
CNB
CORPORACION BALLOTA SAC
CRN
CSY
CT
Cabo
Cabo Rico
Calcutta
Caliber
Californian
Camano
Camargue
Cammenga
Camper & Nicholsons
Canados
Canoe Cove
Cantiere Delle Marche
Cantiere Navale Ferrari
Cantieri Serenella
Cantieri di Pisa
Cantieri di Sarnico
Cape Dory
Cape Horn
Cape Power Cats
Capoforte
Caribe
Carolina Classic
Carolina Skiff
Carver
Catalac
Catalina
Catamaran
Catamaran Cruisers
Catana
Cayman
Caymas
Century
Chantier Navals de Normandie
Chaos
Chaparral
Chaser Yachts
Cheoy Lee
Cherubini
Chesapeake
Chris-Craft
Christensen
Chuck Paine
Cigarette
Classic
Coastal Tug
Cobalt
Cobia
Columbia
Columbia Yacht
Columbus Yachts
Com-Pac
Comitti
Competition
Concept
Concord
Concorde
Conrad
Constellation Yachts
Contender
Contest
Corbin
Cornish Crabbers
Coronado
Correct Craft
Corsair
Cranchi
Crescent
Crest
Crevalle
Crosby
Crownline
Crowther
Cruisers
Cruisers Yachts
Custom
Custom Carolina
Custom Line
Cutwater
Dakota
Dashew
Daytona
De Antonio
DeFever
Deep Impact
Dehler
Del Rey
Delta
Delta Marine
Delta Powerboats
Dencho Marine
Destination
Destiny
Dettling
Dickerson
Dominator
Don Smith
Donzi
Doral
Dragonfly
Dudley Dix
Duffy
Dufour
Dyer
Dyna Yachts
Dynamiq
Eastern
Edgewater
Egg Harbor
Endeavour
Endeavour Catamaran
Endurance
Ericson
Euromarine
Everglades
F&S
Fairline
Falcon
Farr
Fayne Limbo Boat Corp
Feadship
Ferretti Yachts
Finngulf
Fisher
Fjord
Fleming
Formula
Fortier
Fortuna
Fountain
Fountaine Pajot
Four Winns
Freedom
Freeman
French Yachts
Frers
Friendship
Front Runner
G&S Boats
Galeon
Gar Wood
Garlington
Gemini
George Lawley & Sons
Gianetti
Gillikin
Glacier Bay
Glastron
Gozzard
Grady-White
Grand Alaskan
Grand Banks
Grand Inflatables
Grand Soleil
Graves
Greenline
Gulet
Gulf Crosser
Gulf Stream Yachts
Gulfstar
Guy Couach
HCB
HUNTER MARINE
Hacker-Craft
Hakvoort
Hallberg-Rassy
Hampton
Hans Christian
Hanse
Hanstaiger
Hargrave
Harris Cuttyhunk
Hatteras
Heesen Yachts
Henriques
Heritage East
Heritage Yacht
Herreshoff
Hewescraft
Heysea
Hi-Star
Higgins
Highfield
Hinckley
Hines-Farley
Hinterhoeller
Hodgdon Yachts
Holland
Horizon
Houseboat
Huckins
Hunt Yachts
Hunter
Hurricane
Hustler
Hydra-Sports
Hydrocat
Hylas
IAG
ISA
Ice Yachts
Inace
Intermarine
Intrepid
Invictus
Invincible
Irwin
Island Hopper
Island Packet
Islander
Italcraft
Italiayachts
Itama
J Boats
JFA
Jarrett Bay
Jeanneau
Jeanneau Prestige
Jefferson
Jersey
Jersey Cape
Jim Smith
Johnson
Jones Goodell
Jongert
Joyce
Judge
Jupiter
Kachina
Kadey-Krogen
Kencraft
Kenner
Kettenburg
Key West
Kha Shing
Kingship
Knight & Carver
Knowles
Kong & Halvorsen
Koopmans
L&H
Lagoon
Lakeview
Larson
Lazzara Yachts
Legacy
Legacy Yachts
Leopard
Liberty
Licia Yachts
Lien Hwa
Lightning
Limone
Linssen
Little Harbor
Lowland
Luhrs
Lurssen
Lyman
Lyman-Morse
MCP
MJM
MTI
MYSTIQUE YACHT (PTY) LTD
Mag Bay
Magnum
Maine Cat
Mainship
Maiora
Majesty Yachts
Mako
Malo
Mangusta
Manta
Maori
Marine Trader
Marine Trading
Mariner
Maritimo
Marlago
Marlin
Marlin Yachts
Marlow
Marlow Hunter
Marlow-Hunter
Marquis
Marten
Mason
Mastercraft
Maverick
Maxum
May-Craft
McConaghy Boats
McKee Craft
McKinna
Mediterranean
Melges
Menorquin
Meridian
Merritt
Midnight Express
Midnight Lace
Mikelson
Millennia
Miller Marine
Mochi Craft
Monachus Issa
Monaro
Mondomarine
Monk
Monte Carlo Yachts
Monte Fino
Monterey
Monza
Moody
Morgan
Morrelli & Melvin
Morris
Motion
Motor Yacht
Nantucket
NauticStar
Nautica
Nautique
Nautitech
Nautor's Swan
Naval Yachts
Navigator
Neptunus
New Zealand Yachts
Niagara
Niigata
Nimble
Nimbus
Nobiskrug
Nonsuch
Nor-Tech
Nordhavn
Nordic
Nordic Tugs
Nordlund
Norseman
Norstar
Nortech
North Coast
North Wind
Northcoast
Northern Bay
Northstar
Novatec
Novurania
Numarine
O'Day
Occhilupo Yacht & Carbon
Ocean
Ocean 1
Ocean Alexander
Ocean Craft Marine
Ocean Express
Ocean Master
Ocean Yachts
Oceanco
Oceanfast
Offshore Yachts
Okean
Onslow Bay
Orca
Orion
Other
Out Island
Outback Yachts
Outer Reef Yachts
Outremer
Overblue
Overseas
Oyster
PDQ
Pacemaker
Pacific Boats
Pacific Mariner
Pacific Seacraft
Pacifica
Palmer Johnson
Paragon
Pardo Yachts
Parker
Pascoe
Passport
Pathfinder
Pearl
Pearson
Pedigree Cat
Pendennis
PerMare
Performance Cruising
Perry
Pershing
Pioneer
Polycraft
Ponderosa
Poole Boat Company
Post
Power Play
Predator
President
Prestige
Princess
Princess Yachts
Princess Yachts International
Prinz Yachts
Privateer
Privilege
Pro-Line
Profil
Pronautica
Protector
Proteksan
Pursuit
Queenship
Rampage
Ranger Tugs
Ranger Yachts
Ray Hunt Custom
Regal
Regency
Regulator
Reichel/Pugh
Release
Renegade
Ridas Yacht
Rinker
Rio Yachts
Ritchie Howell
Riva
Riviera
Robalo
Robertson & Caine
Rodman
Ron Holland
Rosborough
Roscioli Donzi
Rosetti Superyachts
Rossinavi
Roughwater
Royal Denship
Royal Falcon Fleet
Royal Huisman
Rybo Runner
Rybovich
SAFE Boats
SAY
SPRONK
SUNREEF YACHTS
Sabre
Saga
Sailfish
Salish Sea
San Juan
Sanlorenzo
Santa Cruz
Sarp Yachts
Scarab
Schaefer
Schionning
Scopinich
Scorpion
Scout
Sculley
Sea Born
Sea Force IX
Sea Fox
Sea Hunt
Sea Master
Sea Pro
Sea Ranger
Sea Ray
SeaCraft
SeaHunter
SeaPiper
SeaVee
Sealegs
Sealine
Seaswirl
Seaswirl Striper
Seaward
Seawind
Seven Seas Yachts Hermes Speedster
Shadow
Shamrock
Shannon
ShearWater
Shearline
Sichterman
Silver
Silverhawk
Silverton
Sirena
Skipperliner
Slocum
Sloop
Solace
Solaris
Solliner
Sonic
SouthWind
Southerly
Southern Ocean
Southport
Soverel
Spencer
Sportsman
St. Francis
St. James
Starcraft
Stardust Cruisers
Steiger Craft
Stephens
Stevens
Stingray
Stolper
Stoner
Streamline
Strike
Striker
Stuart Marine
Su Marine
Sumerset
Sunny Briggs
Sunreef
Sunseeker
Sutphen
Sutton
Swan
Swiftships
Symbol
TAKA Yachts
TECNA TECHNALOGICA
TIARA
Ta Chiao
Ta Shing
Tango
Tanzer
Tarrab
Tartan
Taswell
Tayana
Technimarine
Tecnomar
Tecnomarine
Ted Brewer
Tempest
Tender
Terranova Yachts
Thunderbird
Tiara Sport
Tiara Yachts
Tidewater
Titan
Tiwal
Tollycraft
Topaz
Torres
TransWorld
Transpacific Marine
Trawler
Tribute
Trident
Trinity Yachts
Trintella
Triton
Trojan
Trumpy
Tucker
Twin Vee
USMI
Ultra Lite Tenders
Uniesse
Uniflite
Union
Urkmezler
VMG Shipyard
VSY
VZ
Vagabond
Valhalla Boatworks
Van der Heijden
Van der Valk
VanDutch
Vancouver
Vandalize
Vanquish
Vanquish Yachts
Vantare
Velocity
Vento
Venture
Vicem
Viking
Viking Cockpit Sport
Viking Princess
Viking Sport Cruisers
Vilano
Vista
Vitech
Voyage
Voyage Yachts
Vripack
Vuyk en Zonen
W.D. Schock
Wajer
Wally
Washburn & Doughty
Wauquiez
Weaver
Webbers Cove
Wellcraft
Wellington
Wesmac
West Bay
Westport
Westship
Whaly
Whitby
Whitby Yachts
Whitewater
Whiticar
Wilbur
William Garden
Willis
Windship
Windy
Winter Custom Yachts
Wittholz
World Cat
Wylie
X-Yachts
Xquisite Yachts
Yachtcat
Yachting Developments
Yamaha Boats
Yellowfin
Yuka Yacht
Zar Formenti
Zeelander
Zodiac
`;

export default boatMakes;