import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import VesselAndToyService from "../../services/VesselAndToyService";

const VesselView = (props) => {
  const vessel_id = props.location.state ? props.location.state.id : null;

  const [vessel, setVessel] = useState({});
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    VesselAndToyService.getVessel(vessel_id).then((vessel) => {
      setVessel(vessel.data.vessel);
      setLogs(vessel.data.logs);
    });
  }, [vessel_id]);

  return (
    <div className="uk-container page">
      <ul className="uk-breadcrumb">
        <li>
          <a href="/vessels">Vessels</a>
        </li>
        <li className="uk-disabled">
          <a href="#">View Vessel</a>
        </li>
      </ul>

      <h1>{vessel.vessel_name}</h1>

      <div className="form-container">
        <form className="uk-grid-small form" data-uk-grid>
          <div className="uk-width-1-3@s  uk-margin mt20">
            <label className="uk-form-label" htmlFor="name">
              Vessel Name
            </label>
            <div className="uk-text uk-text-emphasis">{`${vessel.length}' ${vessel.make} ${vessel.model} ${vessel.year}`}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Hull Number
            </label>
            <div className="uk-text uk-text-emphasis">{vessel.hull_number}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Length
            </label>
            <div className="uk-text uk-text-emphasis">{`${vessel.length} ${
              vessel.length_unit || "ft"
            }`}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Make
            </label>
            <div className="uk-text uk-text-emphasis">{vessel.make}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Model
            </label>
            <div className="uk-text uk-text-emphasis">{vessel.model}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Year
            </label>
            <div className="uk-text uk-text-emphasis">{vessel.year}</div>
          </div>
        </form>
      </div>

      <div className="uk-column-1-1  uk-margin-small">
        <Link to={"/vessels"}>
          <button className="uk-button iy-button-primary">Done</button>
        </Link>
      </div>

      {logs.length > 0 &&
        logs.map((value, index) => {
          return (
            <p key={index}>
              {value["event_type"]} - {value.createdAt}
            </p>
          );
        })}
    </div>
  );
};

export default VesselView;
