import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import EDocService from "../../services/EDocService";

const EDocView = (props) => {
  const doc_id = props.location.state ? props.location.state.id : null;

  const [doc, setDoc] = useState({});

  useEffect(() => {
    EDocService.get(doc_id).then((doc) => {
      setDoc(doc.data);
    });
  }, []);

  return (
    <div className="uk-container page">
      <ul className="uk-breadcrumb">
        <li>
          <Link to="/edoc-templates">eDoc Templates</Link>
        </li>
        <li className="uk-disabled">
          <a>View eDoc Template</a>
        </li>
      </ul>

      <Typography variant="h5" component="h1">
        {doc.name}
      </Typography>

      <div className="form-container">
        <form className="uk-grid-small form" data-uk-grid>
          <div className="uk-width-1-3@s  uk-margin mt20">
            <label className="uk-form-label" htmlFor="name">
              Template Name:
            </label>
            <div className="uk-text uk-text-emphasis">{doc.name}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Template Type:
            </label>
            <div className="uk-text uk-text-emphasis">{doc.document_type}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              HelloSign ID:
            </label>
            <div className="uk-text uk-text-emphasis">{doc.hellosign_template_id}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Description:
            </label>
            <div className="uk-text uk-text-emphasis">{doc.description}</div>
          </div>
        </form>
      </div>

      <div className="uk-column-1-1  uk-margin-small">
        <Link to={"/edoc-templates"}>
          <button className="uk-button iy-button-primary">Done</button>
        </Link>
      </div>
    </div>
  );
};

export default EDocView;
