import clsx from "clsx";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { JOINT_OWNER, SOLE_OWNER } from "../../../../utils/signer";

const StepBar = ({ setStep, stepNum, asterix, setAsterix }) => {
  const dataRequiredFields = useSelector((state) => state.transactions.requiredFields);
  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const dataListing = dataTransaction || {};
  const dataSeller = dataListing.seller || {};
  const [boolIsYBAA, setIsYBAA] = useState(dataTransaction?.association === 'YBAA');

  let params = useParams();
  let { id } = params;
  let history = useHistory();

  const steps = [
    "vessel",
    "seller",
    "co_seller",
    "toys",
    "trailer",
    "terms",
    "exclusions",
    // "addendum",
    // "send_to_signer",
  ];

  const page = window.location.pathname.split("/")

  const step =
    page[page.length - 2] == 'toys'
      ? 3
      : page[page.length - 2] == 'trailer'
        ? 4
        : steps.indexOf(page.pop());
  const handleJumpStep = (step) => {
    history.push(`/listing-agreement/add/${id}/${steps[step]}`);
    setStep(step);
  };

  useEffect(() => {
    const step = steps.indexOf(window.location.pathname.split("/").pop())
    setStep(steps.indexOf(step) != -1 ? steps.indexOf(step) : 0);
    // history.push(`/listing-agreement/add/${id}/${steps[stepNum]}`);
  }, [window.location.pathname]);

  useEffect(() => {
    const vesselRequireds = dataRequiredFields.vessel;
    const sellerRequireds = dataRequiredFields.seller;
    const termsRequireds = dataRequiredFields.terms;
    const coSellerRequireds = dataRequiredFields.co_seller;
    const trailerRequireds = dataRequiredFields.trailer;

    let vesselAsterix = "empty";
    let sellerAsterix = "empty";
    let termsAsterix = "empty";
    let coSellerAsterix = "empty";
    let trailerAsterix = "empty";

    if (vesselRequireds.length > 0 && vesselRequireds.length < 7) vesselAsterix = "notFulfill";
    if (sellerRequireds.length > 0 && sellerRequireds.length < ((dataListing?.seller_type === SOLE_OWNER || dataListing?.seller_type === JOINT_OWNER) ? 4 : 8)) sellerAsterix = "notFulfill";
    if (sellerRequireds.length === 1 && sellerRequireds[0]['title']) sellerAsterix = "empty"
    if (termsRequireds.length > 0 && termsRequireds.length < (dataListing.association === 'YBAA' ? 2 : 4)) termsAsterix = "notFulfill";
    if (coSellerRequireds.length > 0 && coSellerRequireds.length < 4)
      coSellerAsterix = "notFulfill";

    if (vesselRequireds.length === 0) vesselAsterix = "fulfill";
    if (sellerRequireds.length === 0) sellerAsterix = "fulfill";
    if (termsRequireds.length === 0) termsAsterix = "fulfill";
    if (coSellerRequireds.length === 0) coSellerAsterix = "fulfill";
    if (trailerRequireds.length === 0) trailerAsterix = "fulfill";


    const val = {
      vessel: vesselAsterix,
      seller: sellerAsterix,
      terms: termsAsterix,
      coseller: coSellerAsterix,
      trailer: trailerAsterix
    };
    if (dataListing.t_toys?.length > 0) {
      val.toys = "fulfill";
    } else {
      val.toys = "empty";
    }
    if (dataListing.t_exclusion?.length > 0) {
      val.exclusions = "fulfill";
    } else {
      val.exclusions = "empty";
    }
    // if (dataListing.t_addendum) {
    //   if (dataListing.t_addendum.ammendment === "/://://://:/") {
    //     val.addendum = "empty";
    //   } else {
    //     val.addendum = "fulfill";
    //   }
    // } else {
    //   val.addendum = "empty";
    // }
    setAsterix(val);
  }, [dataRequiredFields, dataTransaction]);

  return (
    <div className="tm-sidebar-left uk-visible@m add-deal">
      <div className="sidebar-container add-deal">
        <ul className="uk-nav uk-nav-default tm-nav">
          <li
            name="vessel"
            id="tab-vessel"
            className={clsx("add-deal chain-step", { current: step == -1 || step == 0 })}
            onClick={() => handleJumpStep(0)}
          >
            <i
              className={clsx(
                "fa",
                { "fa-check-circle stepbar": asterix.vessel === "fulfill" },
                { "fa-circle-o": asterix.vessel === "empty" || !asterix.vessel },
                { "fa-circle": asterix.vessel === "notFulfill" }
              )}
            ></i>
            <span className="add-deal-step">Vessel</span>
          </li>
          <li
            name="seller"
            id="tab-seller"
            className={clsx("add-deal chain-step", { current: step === 1 })}
            onClick={() => handleJumpStep(1)}
          >
            <i
              className={clsx(
                "fa",
                { "fa-check-circle stepbar": asterix.seller === "fulfill" },
                { "fa-circle-o": asterix.seller === "empty" || !asterix.seller },
                { "fa-circle": asterix.seller === "notFulfill" }
              )}
            ></i>
            <span className="add-deal-step">Seller</span>
          </li>
          <li
            name="co-seller"
            id="tab-co-seller"
            className={clsx("add-deal chain-step", { current: step === 2 })}
            onClick={() => handleJumpStep(2)}
          >
            <i
              className={clsx(
                "fa",
                { "fa-check-circle stepbar": asterix.coseller === "fulfill" },
                { "fa-circle-o": asterix.coseller === "empty" || !asterix.coseller },
                { "fa-circle": asterix.coseller === "notFulfill" }
              )}
            ></i>
            <span className="add-deal-step">Co-Seller</span>
          </li>
          <li
            name="toys"
            id="tab-toys"
            className={clsx("add-deal chain-step", { current: step === 3 })}
            onClick={() => handleJumpStep(3)}
          >
            <i
              className={clsx(
                "fa",
                { "fa-check-circle stepbar": asterix.toys === "fulfill" },
                { "fa-circle-o": asterix.toys === "empty" || !asterix.toys },
                { "fa-circle": asterix.toys === "notFulfill" }
              )}
            ></i>
            <span className="add-deal-step">Toys & Tenders</span>
          </li>
          <li
            name="trailer"
            id="trailer"
            className={clsx("add-deal chain-step", { current: step === 4 })}
            onClick={() => handleJumpStep(4)}
          >
            <i
              className={clsx(
                "fa",
                { "fa-check-circle stepbar": asterix.trailer === "fulfill" },
                { "fa-circle-o": asterix.trailer === "empty" || !asterix.trailer },
                { "fa-circle": asterix.trailer === "notFulfill" }
              )}
            ></i>
            <span className="add-deal-step">Trailer</span>
          </li>
          <li
            name="terms"
            id="tab-terms"
            className={clsx("add-deal chain-step", { current: step === 5 })}
            onClick={() => handleJumpStep(5)}
          >
            <i
              className={clsx(
                "fa",
                { "fa-check-circle stepbar": asterix.terms === "fulfill" },
                { "fa-circle-o": asterix.terms === "empty" || !asterix.terms },
                { "fa-circle": asterix.terms === "notFulfill" }
              )}
            />
            <span className="add-deal-step">Terms</span>
          </li>
          {!boolIsYBAA && (
            <>
              <li
                name="exclusions"
                id="tab-exclusions"
                className={clsx("add-deal chain-step", { current: step === 6 })}
                onClick={() => handleJumpStep(6)}
              >
                <i
                  className={clsx(
                    "fa",
                    { "fa-check-circle stepbar": asterix.exclusions === "fulfill" },
                    { "fa-circle-o": asterix.exclusions === "empty" || !asterix.exclusions },
                    { "fa-circle": asterix.exclusions === "notFulfill" }
                  )}
                ></i>
                <span className="add-deal-step">Exclusions</span>
              </li>
            </>
          )}

          {/* <li
            name="send-to-seller"
            id="tab-send-to-seller"
            className={clsx("add-deal chain-step", { current: step === 7 })}
            onClick={() => handleJumpStep(7)}
          >
            <i className="fa fa-circle-o"></i>
            <span className="add-deal-step">Send for eSign</span>
          </li> */}

          {
            dataTransaction?.id &&
            // <li
            //   name="back-to-eDocuments"
            //   id="tab-back-to-eDocuments"
            //   className={clsx("add-deal chain-step")}
            // >
            //   <Link to={`/listing-agreement/view/${dataTransaction.id}/main#tab=%22edocuments%22`}><span uk-icon="chevron-double-left"></span>Back to eDocuments</Link>
            // </li>
            <button className="uk-button uk-button-primary mt-2" onClick={() => history.push(`/listing-agreement/view/${dataTransaction.id}/main#tab=%22edocuments%22`)}>
              <span uk-icon="chevron-double-left"></span>Back to eDocuments
            </button>
          }


        </ul>
      </div>
    </div>
  );
};

export default StepBar;
