import React from 'react'
import LibraryManagerList from './List'
import { Helmet } from 'react-helmet'

const FormLibraryManager = () => {
  return (
    <>
    <Helmet><title>Form Library Manager- BoatDox</title></Helmet>
    <LibraryManagerList/>
    </>
  )
}

export default FormLibraryManager