import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DebounceInput } from "react-debounce-input";
import {
  retrieveVesselOrToys,
  updateVesselOrToy,
  findVesselOrToyByName,
  clearList,
} from "../../redux/actions/vessels_toys";
import { Link, useRouteMatch } from "react-router-dom";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import datatableStyles from "../../utils/datatable-style";
import { DELETE_MODAL_MESSAGE, DELETE_MODAL_TITLE } from "../../constants/Alert";
import Auth from "../../utils/auth";
import { getProfile, getUser } from "../../redux/actions/users";
import VesselAndToyService from "../../services/VesselAndToyService";
import { toast } from "react-toastify";
import { setLoadingStatus } from "../../redux/actions/loading";
import CreateMLSVessel from "../common/CreateMLSVesselModal";
import CreateMLSVesselModal from "../common/CreateMLSVesselModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logUserCompanyMismatch } from "../../utils/access";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Tooltip } from "@mui/material";
import VideoPlayerModal from "../common/videoPlayerModal";
import TutorialService from "../../services/TutorialService";

const VesselsList = () => {
  // const dataVessels = useSelector((state) => state.vessel_toys.list);
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading.status);

  const logged_user = Auth.getInstance().getUserData();
  const user = useSelector((state) => state.users.logged_by)

  //Make a state for search
  const [search, setSearch] = useState({});

  //State for create mls vessel
  const [isOpenMLSForm, setIsOpenMLSForm] = useState(null);

  //Store users data for listing
  const [dataVessels, setDataVessels] = useState([]);

  //Store count of total rows
  const [totalRows, setTotalRows] = useState(0)

  const [show, setShow] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null)
  const tutorials = useSelector((state) => state?.tutorials) || null;

  const [loadCount, setLoadCount] = useState(0);
  useEffect(() => {
    if (loadCount === 0) {
      dispatch(setLoadingStatus(false));
      setLoadCount(0);
    }
  }, [loadCount])

  const fetchVesselsData = (search) => {
    dispatch(setLoadingStatus(true));
    setLoadCount((prev) => prev + 1);
    VesselAndToyService.findByName(search, "vessel")
      .then((res) => {
        const dataVessels = res.data.data;
        for (let index = 0; index < dataVessels.length; index++) {
          const element = dataVessels[index];
          if (logged_user.UserInRole?.UserRole?.name != "Boatdox Admin" && logged_user.company_id != element.company_id) {
            logUserCompanyMismatch(element, 'MLS Vessel List', history);
            break;
          }
        }
        const totalRecords = res.data.total;
        setDataVessels(dataVessels);
        setTotalRows(totalRecords);
        // dispatch(setLoadingStatus(false));
        setLoadCount((prev) => prev - 1);
      })
      .catch((error) => {
        dispatch(setLoadingStatus(false))
        setLoadCount((prev) => prev - 1);
        console.log(error);
        toast.error("Error occurs, please try again.", {
          autoClose: 2000
        })
      })
  }

  useEffect(() => {
    dispatch(getUser(logged_user.id))
  }, [])

  useEffect(() => {
    fetchVesselsData(search);
    // dispatch(findVesselOrToyByName(search, "vessel")).catch(err => console.log(err));
  }, [search]);

  const onDelete = (id) => {
    confirmAlert({
      title: DELETE_MODAL_TITLE,
      message: DELETE_MODAL_MESSAGE,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const data = {
              deleted: true,
              deleted_at: new Date(),
            };
            dispatch(updateVesselOrToy(id, data))
              .then((response) => {
                fetchVesselsData(search)
                toast.info("Delete successfully", {
                  autoClose: 2000,
                });
              })
              .catch((e) => {
                toast.error("Error has occured.", {
                  autoClose: 2000,
                });
                console.log("Error = ", e);
              });
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const columns = [
    {
      name: "Year",
      selector: "year",
      sortable: true,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>
              <Link to={{ pathname: `${path}/view`, state: { id: row.id } }}>
                {`${row?.year || ""}`}
              </Link>
            </div>
          </div>
        );
      },
    },
    {
      name: "Brand",
      selector: "make",
      sortable: true,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>
              <Link to={{ pathname: `${path}/view`, state: { id: row.id } }}>
                {`${row?.make || ""}`}
              </Link>
            </div>
          </div>
        );
      },
    },
    {
      name: "Model",
      selector: "model",
      sortable: true,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>
              <Link to={{ pathname: `${path}/view`, state: { id: row.id } }}>
                {`${row?.model || ""}`}
              </Link>
            </div>
          </div>
        );
      },
    },
    {
      name: "Length",
      selector: "length",
      sortable: true,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>
              <Link to={{ pathname: `${path}/view`, state: { id: row.id } }}>
                {`${row?.length || ""}`}
              </Link>
            </div>
          </div>
        );
      },
    },
    // {
    //   name: "Vessel",
    //   selector: "vessel_name",
    //   sortable: true,
    //   cell: (row) => { 
    //     let vesselTitle = `${row?.year || ""} ${row?.make || ""} ${row?.model || ""} ${
    //       row?.length || ""
    //     }`;
    //     return (
    //       <div data-tag="allowRowEvents">
    //         <div>
    //           <Link to={{ pathname: `${path}/view`, state: { id: row.id } }}>
    //             {vesselTitle === "   " ? "N/A" : vesselTitle}
    //           </Link>
    //         </div>
    //       </div>
    //     );
    //   },
    // },
    {
      name: "Hull Number",
      selector: "hull_number",
      sortable: true,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>{row.hull_number}</div>
          </div>
        );
      },
    },
    {
      name: "Vessel Name",
      selector: "vessel_name",
      sortable: true,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>{row.vessel_name || "N/A"}</div>
          </div>
        );
      },
    },
    {
      name: "Broker",
      selector: "last_name",
      sortable: true,
      grow: 1,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>{row.v_broker ? `${row.v_broker?.last_name} ${row.v_broker?.first_name}` : "N/A"}</div>
          </div>
        );
      },
    },
    {
      name: "MLS EXPORT",
      center: false,
      textAlign: "right",
      cell: (row) => {
        return (
          <>
            {
              row?.mls_vessel_id ?
                <label>{row?.mls_vessel_id}</label> :
                <div onClick={() => setIsOpenMLSForm(row?.id)}>
                  <label className="uk-form-label" style={{ cursor: "pointer" }}>Export</label>
                </div>

            }
          </>
        );
      },
    },
    {
      name: "",
      center: false,
      textAlign: "right",
      cell: (row) => {
        return (
          <div>
            <Link to={{ pathname: `${path}/edit`, state: { id: row.id } }}>
              <Tooltip title="Edit" placement="left">
                <img src="/icons/edit.svg" className="svg uk-margin-right" alt="" />
              </Tooltip>
            </Link>
            {
              (user && (user.access_type && user.access_type == "full") || ["Company Admin", "Boatdox Admin"].includes(user.UserInRole.UserRole.name)) &&
              <Tooltip title="Delete" placement="right">
                <img src="/icons/delete.svg" className="svg" onClick={() => onDelete(row.id)} alt="" />
              </Tooltip>
            }
          </div>
        );
      },
    },
  ];

  //Function for handle row limit
  const handleRowsPerPage = (numberOfRows, page) => {
    setSearch((search) => {
      return {
        ...search,
        limit: numberOfRows,
        page: page
      }
    })
  }

  //Function for handle page no
  const handlePageChange = page => {
    setSearch((search) => {
      return {
        ...search,
        page: page
      }
    })
  };

  //Function for handle sort
  const handleSort = (column, sortDirection) => {
    // column.selector, sortDirection
    setSearch((search) => {
      return {
        ...search,
        sortOrder: sortDirection,
        sortField: column.selector
      }
    })
  };

  const closeMLSModel = () => {
    setIsOpenMLSForm(null);
    fetchVesselsData(search)
  }

  useEffect(() => {
    setCurrentVideo(tutorials?.mls_vessels);
  }, [tutorials]);

  const onCloseVideoPlayerModal = () => {
    setShow(() => false);
  }

  return (
    // <div className="tm-main uk-section uk-section-default">
    <>
      {
        isOpenMLSForm &&
        <CreateMLSVesselModal onClose={closeMLSModel} vesselId={isOpenMLSForm} />
      }
      <div className="uk-container uk-container-expand uk-position-relative">
        <div className="content">
          <h1 className="d-flex align-items-center gap-1">
            {currentVideo && <Tooltip title="Play Tutorial" arrow placement='top'  >
              <PlayCircleOutlineIcon fontSize="large" style={{ cursor: "pointer" }} className="mr-1" onClick={() => setShow(true)} />
            </Tooltip>}MLS Vessels</h1>

          <div className="uk-column-1-2">
            {
              (user && (user.access_type && user.access_type == "full") || ["Company Admin", "Boatdox Admin"].includes(user.UserInRole.UserRole.name)) &&
              <Link to="/vessels/add">
                <div className="uk-button uk-button-default add-deal my-2">
                  <span className="uk-margin-small-right uk-icon" data-uk-icon="plus"></span> Add
                </div>
              </Link>
            }
            <div className="list-page-search-container my-2 ">
              <form className="uk-search uk-search-default search-top-margin">
                <DebounceInput
                  type="text"
                  className="uk-search-input"
                  placeholder="Search"
                  debounceTimeout={1000}
                  minLength={1}
                  onChange={(e) => setSearch(state => {
                    return {
                      ...state,
                      name: e.target.value
                    }
                  })}
                  value={search.name ? search.name : ""}
                />
                {!search.name ? (
                  <span className="bd-form-icon">
                    <i className="fa fa-search"></i>
                  </span>
                ) : (
                  <span className="bd-form-icon"
                    onClick={() => setSearch(state => {
                      return {
                        ...state,
                        name: ""
                      }
                    })}
                  >
                    <i className="fa fa-times"></i>
                  </span>
                )}
              </form>
            </div>
          </div>

          {/* Vessels List Table */}

          {!dataVessels.length && isLoading
            ? <div className="no-data-table"><div className="p-4">Fetching Details...</div></div>
            : <DataTable
              title="Vessels Table"
              columns={columns}
              data={dataVessels}
              striped={true}
              highlightOnHover={true}
              pointerOnHover={true}
              noHeader={true}
              fixedHeader={true}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationRowsPerPageOptions={[10, 20, 50, 100]}
              customStyles={datatableStyles}
              onChangeRowsPerPage={handleRowsPerPage}
              onChangePage={handlePageChange}
              onSort={handleSort}
              sortServer
              persistTableHead
            />
          }

        </div>
      </div>
      {show && <VideoPlayerModal show={show} onClose={onCloseVideoPlayerModal} videoTitle={currentVideo} />}
    </>
    // </div>
  );
};

export default VesselsList;
