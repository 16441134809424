const TransactionDetailContent = ({ tabContent, accordionContent, tab }) => {
  return (
    <div className="content transaction-detail">
      <div className="content-middle">
        <div className="list-table transaction-detail-item">{tabContent()}</div>
        {
          tab != "signatures" &&
          <>
            {accordionContent()}
          </>
        }
      </div>
    </div>
  );
};

export default TransactionDetailContent;
