import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from 'react-bootstrap';
import { makeStyles } from '@mui/styles';

import * as yup from "yup";
import clsx from 'clsx';
import PhoneInput from 'react-phone-input-2';
import { setLoadingStatus } from '../../redux/actions/loading';
import TransactionService from '../../services/TransactionService';
import { toast } from 'react-toastify';
import { retrieveRoles } from '../../redux/actions/users';
const useStyles = makeStyles({
    phone_input: {
        padding: '12px 12px 12px 50px !important',
        width: '100% !important'
    },
    broker_dropdown: {
        marginTop: "10px",
    },
    broker_dropdown_label: {
        marginTop: "23px",
        marginRight: "-20px",
        marginLeft: "-14px"
    }
});

const AddParticipantModal = ({ participant = null, show, onClose }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    //This variable is used to store a users roles.
    const dataRoles = useSelector(state => state.users.roles);
    const dataTransaction = useSelector((state) => state.transactions.transaction);

    const schema = yup.object().shape({
        title: yup.string(),
        first_name: yup.string(),
        last_name: yup.string(),
        email: yup.string().required("Please enter Email Address").email("Please enter valid Email Address"),
        mobile_phone: yup.string().nullable(true),
        participant_company_name: yup.string(),
    });

    const { register, handleSubmit, reset, setValue, getValues, formState: { errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    useEffect(() => {
        if (show) {
            reset();
            if (participant !== null) {
                getPrticipantDetail();
            }
            dispatch(retrieveRoles());
        }
    }, [show]);

    /**
     * get detail of a participant to be updated with form
     */
    const getPrticipantDetail = () => {
        const participantDetail = participant;
        for (const key in participantDetail) {
            if (Object.hasOwnProperty.call(participantDetail, key)) {
                const element = participantDetail[key];
                setValue(key, element || '');
            }
        }
    }

    const handleError = (errors) => {
        console.log("Errors = ", errors);
    };

    /**
     * on submit participant form data for Add/Update participant 
     * @param {*} participant value of participant form data
     */
    const handleRegistration = (payload) => {
        payload = { ...payload, is_listing_broker_from_other_company: payload.is_listing_broker_from_other_company || false, user_role_id: dataRoles.find(role => role.name === 'Guest Agent').id }
        const addBroker = (payload) => {
            dispatch(setLoadingStatus(true));
            TransactionService.addGuestAgent(dataTransaction.id, payload).then((response) => {
                dispatch(setLoadingStatus(false));
                toast.success(`Guest Agent invite successfully`);
                reset();
                onClose(true);
            }).catch((error) => {
                dispatch(setLoadingStatus(false));
                toast.error('Something went wrong while submit data!');
            });
        }
        addBroker(payload)
    }

    return (
        <Modal
            show={show}
            onHide={onClose}
            centered={true}
            size='lg'>
            <Modal.Header closeButton>
                <h3>Invite Broker</h3>
            </Modal.Header>
            <div className="uk-margin-small pl-2">
                <form onSubmit={handleSubmit(handleRegistration, handleError)}>
                    <Modal.Body>
                        <div className="panel-form">
                            <div className="form-row">
                                <div className="half-column uk-padding-small">
                                    <label className="uk-form-label">First Name</label>
                                    <input
                                        className={clsx("uk-input", {
                                            "uk-form-danger": errors.name,
                                        })}
                                        type="text"
                                        {...register("first_name")}
                                    />
                                </div>
                                <div className="half-column uk-padding-small">
                                    <label className="uk-form-label">Last Name</label>
                                    <input
                                        className={clsx("uk-input", {
                                            "uk-form-danger": errors.name,
                                        })}
                                        type="text"
                                        {...register("last_name")}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="half-column uk-padding-small">
                                    <label className="uk-form-label">Title</label>
                                    <input className="uk-input" type="text" id="title" {...register("title")} />
                                </div>
                                <div className="half-column uk-padding-small">
                                    <label className="uk-form-label">Company Name</label>
                                    <input
                                        className={clsx("uk-input", {
                                            "uk-form-danger": errors.participant_company_name,
                                        })}
                                        type="text"
                                        {...register("participant_company_name")}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="half-column uk-padding-small">
                                    <label className="uk-form-label">Email*</label>
                                    <input
                                        className={clsx("uk-input", {
                                            "uk-form-danger": errors.email?.message,
                                        })}
                                        type="email"
                                        {...register("email")}
                                    />
                                    <p className='text-danger'>{errors.email?.message}</p>
                                </div>
                                <div className="half-column uk-padding-small">
                                    <label className="uk-form-label" htmlFor="name">Mobile Phone</label>
                                    <input type="hidden" required name="mobile_phone"  {...register("mobile_phone")} />
                                    <PhoneInput
                                        placeholder='+'
                                        country={'us'}
                                        inputClass={classes.phone_input}
                                        containerClass={classes.phone_container}
                                        autoFocus={false}
                                        value={getValues("mobile_phone")}
                                        onChange={phone => { setValue('mobile_phone', phone, { shouldValidate: true }) }}
                                    />
                                    <p style={{ color: "red" }}>{errors.mobile_phone?.message}</p>
                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="uk-button uk-button-orange uk-modal-close small" type="button" onClick={(e) => onClose(false)}>
                            Cancel
                        </button>
                        <button className="uk-button uk-button-primary small" type="submit">{'Send Invite'}</button>
                    </Modal.Footer>
                </form>
            </div>
        </Modal>
    )
}

export default AddParticipantModal;