import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Tooltip } from "@mui/material";
import FormRow from './FormRow';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingStatus } from '../../redux/actions/loading';
import TransactionService from '../../services/TransactionService';
import { convertUnderscoreSeparateLetter } from '../../utils/functions';
import { phoneNumber } from '../../utils/phonenumber';
import datatableStyles from '../../utils/datatable-style';
import { CORPORATE_OWNED, ENTITY_AND_OR_ASSIGN, LLC_OWNED, OWNER_OF_RECORD, TRUST_OWNED } from '../../utils/signer';
import { useRouteMatch } from 'react-router-dom';
import Auth from '../../utils/auth';

const SignatureMessageModal = ({ show, onClose, emailDetail, onGenerateLink, isNoGenerateLink = false }) => {

    const dispatch = useDispatch();
    const [message, setMessage] = useState('');
    const [submitType, setSubmitType] = useState("send")
    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const [senderRole, setSenderRole] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    let { path } = useRouteMatch();
    const loginEmail = Auth.getInstance().getUserData().email;

    const fetchParticipants = async () => {
        dispatch(setLoadingStatus(true));
        await TransactionService.getAllParticipants(dataTransaction.id).then(async (response) => {
            const transactionParticipants = response.data?.transaction;
            const customParticipants = response.data?.participants;
            const participantEmailList = customParticipants.map((participant) => participant.t_participant?.user?.email);
            let participantData = [];
            for (const index in transactionParticipants) {
                if (Object.hasOwnProperty.call(transactionParticipants, index)) {
                    const element = transactionParticipants[index];
                    if (element) {
                        if (index == 'broker') {
                            if (transactionParticipants.task_type == 'listing') {
                                await prepareParticipantList(index, element, participantData, participantEmailList, transactionParticipants);
                            }
                        } else {
                            await prepareParticipantList(index, element, participantData, participantEmailList, transactionParticipants);
                        }
                    }
                }
            }
            customParticipants.map((participant) => {
                participantData.push({
                    participantId: participant.id,
                    id: `${participant.t_participant?.user?.id}`,
                    title: `${participant.t_participant?.user?.title || 'N/A'}`,
                    first_name: participant.t_participant?.user?.first_name,
                    last_name: participant.t_participant?.user?.last_name,
                    participant_company_name: participant.t_participant?.user?.participant_company_name,
                    mobile_phone: participant.t_participant?.user?.mobile_phone,
                    name: `${(participant.t_participant?.user?.first_name || participant.t_participant?.user?.last_name) ? ((participant.t_participant?.user?.first_name || '') + ' ' + (participant.t_participant?.user?.last_name || '')) : 'N/A'}`,
                    email: `${participant.t_participant?.user?.email || 'N/A'}`,
                    mobile: `${participant.t_participant?.user?.mobile_phone || ''}`,
                    company: `${participant.t_participant?.user?.participant_company_name || 'N/A'}`,
                    participant_auth_docs: participant.participant_auth_docs || [],
                    isCustom: true,
                    status: participant.status,
                    userType: participant.user_type || ""
                });
            });
            let selectedRecipientList = [];
            const clientRole = emailDetail?.role?.replace('-', ' ');
            const currentRole = clientRole === "Owner"
                ? "Seller"
                : clientRole === "Co Owner"
                    ? "Co Seller"
                    : clientRole
            setSenderRole(currentRole);
            if (path.includes("invitation")) {
                if (dataTransaction?.co_broker?.listing_broker_email === loginEmail) {
                    participantData = participantData.filter((participant) => !['Buyer', 'Co Buyer', 'Selling Broker'].includes(participant.title));
                } else if (dataTransaction?.co_broker?.selling_broker_email === loginEmail) {
                    participantData = participantData.filter((participant) => !['Seller', 'Co Seller', 'Listing Broker'].includes(participant.title));
                }
            }
            if (!path.includes("invitation") && dataTransaction.is_co_seller_hide_by_invited_broker) {
                participantData = participantData.filter((participant) => !['Co Seller'].includes(participant.title));
            }
            if (!path.includes("invitation") && dataTransaction.is_seller_hide_by_invited_broker) {
                participantData = participantData.filter((participant) => !['Seller'].includes(participant.title));
            }
            if (!path.includes("invitation") && dataTransaction.is_co_buyer_hide_by_invited_broker) {
                participantData = participantData.filter((participant) => !['Co Buyer'].includes(participant.title));
            }
            if (!path.includes("invitation") && dataTransaction.is_buyer_hide_by_invited_broker) {
                participantData = participantData.filter((participant) => !['Buyer'].includes(participant.title));
            }
            // Check if selected the recipients previously for send email
            if (emailDetail.selectedRecipients?.length) {
                const recipientEmails = emailDetail.selectedRecipients.map((participant) => participant.match(/<([^>]+)>/)[1]);
                selectedRecipientList = participantData.filter((participant) => recipientEmails.includes(participant.email));
            } else {
                // if no recipients selected previously then select the role as default selected
                selectedRecipientList = participantData.filter((participant) => participant.title == currentRole) || [];

                // If the role does not match when there is mismatch on participant title then do check for the same email exist on the participant array
                if (selectedRecipientList.length == 0) {
                    selectedRecipientList = participantData.filter((participant) => participant.email == emailDetail.data.email) || [];
                }
            }
            if (['Selling-Employing-Broker', 'Listing-Employing-Broker'].includes(emailDetail.role)) {
                selectedRecipientList = participantData.filter((participant) => participant.title == 'Employing Broker') || [];
            }
            setSelectedUsers(selectedRecipientList);
            setParticipants(moveUserToFront(participantData, clientRole));
            dispatch(setLoadingStatus(false));
        }).catch((error) => {
            dispatch(setLoadingStatus(false));
            console.log(error);
        });
    }

    /**
     * set the selected user at the top of the list by matching with email address
     * @param {*} users 
     * @param {*} targetRole 
     * @returns List of users with selected email at the top of list
     */
    function moveUserToFront(users, targetRole) {
        const index = users.findIndex(user => user.title === targetRole);
        if (index > -1) {
            const [user] = users.splice(index, 1); // Remove the user from the array
            users.unshift(user); // Add the user to the front of the array
        }
        return users;
    }

    const prepareParticipantList = async (type, element, participantData, participantEmailList = [], transactionParticipants) => {
        let participantArray = participantData;
        switch (type) {
            case 'buyer':
                participantArray.push({
                    title: convertUnderscoreSeparateLetter(type),
                    name: ` ${[ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(transactionParticipants?.buyer_type) > -1 ? element?.trust_signer_name ? element?.trust_signer_name : "N/A" : (element.first_name || element.last_name) ? ((element.first_name || '') + ' ' + (element.middle_name || '') + ' ' + (element.last_name || '')) : 'N/A'}`,
                    email: `${[ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(transactionParticipants?.buyer_type) > -1 ? element?.trust_signer_email ? element?.trust_signer_email : 'N/A' : element.email || 'N/A'}`,
                    mobile: `${[ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(transactionParticipants?.buyer_type) > -1 ? element?.trust_signer_phone || 'N/A' : element.mobile_phone || 'N/A'}`,
                    company: `${element.c_company?.company_name || 'N/A'}`,
                    isFormData: true,
                });
                break;
            case 'co_buyer':
            case 'co_seller':
                participantArray.push({
                    title: convertUnderscoreSeparateLetter(type),
                    name: ` ${(element.first_name || element.last_name) ? ((element.first_name || '') + ' ' + (element.middle_name || '') + ' ' + (element.last_name || '')) : 'N/A'}`,
                    email: `${element.email || 'N/A'}`,
                    mobile: `${element.mobile_phone || ''}`,
                    company: `${element.c_company?.company_name || 'N/A'}`,
                    isFormData: true,
                });
                break;
            case 'seller':
                participantArray.push({
                    title: convertUnderscoreSeparateLetter(type),
                    name: ` ${(element.first_name || element.last_name) ? ((element.first_name || '') + ' ' + (element.middle_name || '') + ' ' + (element.last_name || '')) : [OWNER_OF_RECORD].indexOf(transactionParticipants?.seller_type) > -1 ? "Owner of Record" : 'N/A'}`,
                    email: `${element.email || 'N/A'}`,
                    mobile: `${element.mobile_phone || ''}`,
                    company: `${element.c_company?.company_name || 'N/A'}`,
                    isFormData: true,
                });
                break;

            case 'co_broker':
                if (element.selling_broker_email && !participantEmailList.includes(element.selling_broker_email)) {
                    participantArray.push({
                        title: `Selling Broker`,
                        name: `${element.selling_brokerage_broker || 'N/A'}`,
                        email: `${element.selling_broker_email || 'N/A'}`,
                        mobile: `${element.selling_broker_phone_number || ''}`,
                        company: `${element.selling_brokerage || 'N/A'}`,
                        is_selling_broker_from_other_company: element.is_selling_broker_from_other_company,
                    });
                } if (element.listing_broker_email && !participantEmailList.includes(element.listing_broker_email)) {
                    participantArray.push({
                        title: `Listing Broker`,
                        name: `${element.listing_brokerage_broker || 'N/A'}`,
                        email: `${element.listing_broker_email || 'N/A'}`,
                        mobile: `${element.listing_broker_phone_number || ''}`,
                        company: `${element.listing_brokerage || 'N/A'}`,
                        is_listing_broker_from_other_company: element.is_listing_broker_from_other_company,
                    });
                }
                break;

            case 'employing_broker':
            case 'selling_employing_broker':
            case 'listing_employing_broker':
            case 'broker':
                participantArray.push({
                    title: convertUnderscoreSeparateLetter(type),
                    name: `${(element.first_name || element.last_name) ? ((element.first_name || '') + ' ' + (element.last_name || '')) : 'N/A'}`,
                    email: `${element.email || 'N/A'}`,
                    mobile: `${element.mobile_phone || ''}`,
                    company: `${element.company?.company_name || 'N/A'}`
                });
                break;

            default:
                break;
        }
        return participantArray;
    }

    useEffect(() => {
        setSubmitType("send");
        setMessage('');
        if (show) {
            fetchParticipants();
        }
        setSelectedUsers([]);
    }, [show]);

    const handleSend = () => {
        submitType === "send" ? handleSubmit() : handleGenerateLink();
    }
    const handleSubmit = () => {
        const data = emailDetail || {};
        onClose(true, returnData(data));
    }

    const handleGenerateLink = () => {
        setTimeout(() => {
            const data = emailDetail || {};
            onGenerateLink(returnData(data));
        }, 1000);
    }

    const returnData = (dataObj) => {
        return {
            ...dataObj,
            data:
            {
                ...dataObj.data,
                additional_email_content: message,
                selected_recipients: selectedUsers.map(user => `${user.name} <${user.email}>`)
            }
        }
    }

    const isValidSend = () => {
        const signerObj = emailDetail?.data;
        let isValid = false;
        if (signerObj) {
            switch (emailDetail?.role) {
                case 'Listing-Broker':
                    isValid = !!signerObj.listing_broker_email || !!signerObj.email;
                    break;

                case 'Selling-Broker':
                    isValid = !!signerObj.selling_broker_email || !!signerObj.email;
                    break;

                default:
                    isValid = !!signerObj.email || !!signerObj.trust_signer_email;
                    break;
            }
        }
        return isValid;
    }

    const handelSelect = (check, selectedUser) => {
        if (check) {
            setSelectedUsers(prev => ([...prev, selectedUser]));
        } else {
            setSelectedUsers(prev => prev.filter(user => user.email !== selectedUser.email))
        }
    }

    const columns = [
        {
            width: "50px",
            center: true,
            padding: 0,
            cell: (row) => (
                <div className={senderRole === row.title && 'selected-row'}>
                    <input
                        type="checkbox"
                        className="uk-checkbox"
                        style={{
                            border: (row.email !== "N/A" && senderRole !== row.title) ? '1.75px solid #1ea2d5' : '1.75px solid #adadad',
                            cursor: (row.email !== "N/A" && senderRole !== row.title) ? "pointer" : "not-allowed",
                            backgroundColor: (row.email !== "N/A" && senderRole === row.title) && 'lightGrey',
                        }}
                        checked={row.email !== "N/A" && (senderRole === row.title || selectedUsers?.filter(user => user?.email === row?.email).length || null)}
                        onClick={(e) => { handelSelect(e.target.checked, row) }}
                        disabled={(row.email === "N/A" || senderRole === row.title) ? true : false}
                    />
                </div>
            )
        },
        {
            name: "title",
            padding: 0,
            cell: (row) => (
                <div className={senderRole == row.title && 'selected-row'}>
                    {
                        row && row?.title
                    }
                </div>
            )
        },
        {
            name: "Full Name",
            selector: "name",
            cell: (row) => (
                <div className={senderRole == row.title && 'selected-row'}>
                    {row.name}
                </div>
            )
        },
        {
            name: "Email",
            selector: "email",
            width: '240px',
            fontSize: "12px",
            cell: (row) => (
                <div className={senderRole == row.title && 'selected-row'}>
                    {row.email}
                </div>
            )
        },
        {
            name: "Mobile Phone",
            selector: "mobile_phone",
            width: '150px',
            cell: (row) => (
                <div className={senderRole == row.title && 'selected-row'}>
                    {row.mobile
                        ? phoneNumber(row.mobile)
                        : 'N/A'
                    }
                </div>
            )
        },
        {
            name: "company",
            padding: 0,
            cell: (row) => (
                <div className={senderRole == row.title && 'selected-row'}>
                    {
                        row && row?.company
                    }
                </div>
            )
        }
    ];

    const setType = (type) => {
        setSubmitType(type);
        // setSelectedUsers([])
        setMessage('');
    }
    return (
        <Modal
            show={show}
            onHide={() => onClose(false)}
            centered={true}
            size="lg">
            <Modal.Header closeButton>
                <h3>Include Message</h3>
            </Modal.Header>
            <Modal.Body>
                {!isNoGenerateLink && <div className="radio-toolbar">
                    <input type="radio" id="send" name="fav_language" value={submitType} checked={submitType === "send"} onClick={() => { setType("send") }} onChange={() => { }} />
                    <label htmlFor="send" className="uk-form-label radio font-weight-bold" >Send</label>
                    <input type="radio" id="generate" name="fav_language" value={submitType} checked={submitType === "generate"} onClick={() => { setType("generate") }} onChange={() => { }} />
                    <label htmlFor="generate" className="uk-form-label radio font-weight-bold">Generate E-Sign Link only</label>
                </div>}
                <div style={submitType === "" ? { pointerEvents: "none", opacity: "0.4" } : {}}>

                    {submitType === "send" && <div className=''>
                        <FormRow>
                            <div className="uk-padding-small">
                                <h1> <label className="uk-form-label">Enter Message (optional)</label></h1>
                                <textarea type="text" rows='4' value={message} className="uk-textarea" onChange={(e) => { setMessage(e.target.value) }} />
                            </div>
                        </FormRow>
                    </div>}

                    <div className="col-md-12 pt-3 px-0">
                        <h4>
                            <b className='text-size-h3 uk-form-label  text-danger'>Select Whom To Notify When Signed or Rejected:</b>
                        </h4>
                        <div className='modal-table'>
                            <DataTable
                                columns={columns}
                                data={participants}
                                highlightOnHover={true}
                                striped={true}
                                noHeader={true}
                                customStyles={datatableStyles}
                            />
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer style={{ justifyContent: 'space-between' }}>
                <div style={{ justifyContent: 'start' }}>
                    <button className="uk-button uk-button-orange uk-modal-close small ml-2" type="button" onClick={(e) => onClose(false)}>Cancel</button>
                </div>
                <div style={{ justifyContent: 'end' }}>
                    {!isNoGenerateLink && submitType === "generate" &&
                        <>
                            <Tooltip title={"Generate & Save Recipients"} arrow placement='top'>
                                <button className="uk-button uk-button-primary small" onClick={handleGenerateLink}>Generate & Save</button>
                            </Tooltip>
                            {/* <label className='mr-2'>OR</label> */}
                        </>
                    }
                    {submitType === "send" && <button className="uk-button uk-button-primary small" type="button" onClick={handleSend} disabled={!isValidSend()}>{submitType === "send" ? "Send" : "Generate & Save"}</button>}
                </div>
            </Modal.Footer>
        </Modal >
    )
}

export default SignatureMessageModal;
