import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Helmet } from "react-helmet";
import DocumentForm from "./form/Form";
import ListDocuments from "./list/List";
import ViewDocument from "./view/View";

const PrefillDocuments = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <Helmet>
        <title>Document Templates - BoatDox</title>
      </Helmet>
      <Switch>
        <Route exact path={path} component={ListDocuments} />
        <Route path={`${path}/add`} component={DocumentForm} />
        <Route path={`${path}/edit`} component={DocumentForm} />
        <Route path={`${path}/view`} component={ViewDocument} />
      </Switch>
    </>
  );
};

export default PrefillDocuments;
