import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import clsx from "clsx";
import PageSidebarContainer from "../../../../common/PageSidebarContainer";
import { updateOptionsRequiredFields } from "../../../../../redux/actions/transaction";
import { useQueryParams } from "../../../../../utils/query-params";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AddendumStep = () => {
  let location = useLocation();
  let { id } = useParams();
  const dispatch = useDispatch();
  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const [asterix, setAsterix] = useState("empty");

  const dataDeal = dataTransaction || {};
  const dataAddendum = dataDeal.t_purchase_addendum || [];
  const query = useQueryParams();

  const history = useHistory();

  useEffect(() => {
    const addendum = dataAddendum.find(el => el.t_edocument_id === query.get('doc_id'));
    if (addendum?.ammendment && addendum?.ammendment !== "/://://://:/") {
      setAsterix("fulfill");
    } else {
      setAsterix("empty");
    }
    registerRequiredFields(addendum?.ammendment);
  }, [dataTransaction]);

  const registerRequiredFields = (addendum) => {
    if (addendum && addendum !== "/://://://:/") {
      dispatch(
        updateOptionsRequiredFields({
          addendum: [],
        })
      );
    } else {
      dispatch(
        updateOptionsRequiredFields({
          addendum: [{ addendum_1: "Addendum #1" }],
        })
      );
    }
  };

  let branch = location.pathname.split("/").pop();

  return (
    <PageSidebarContainer type="add-deal">
      <div className="sidebar-container add-deal">
        <ul className="uk-nav uk-nav-default tm-nav">
          <Link
            to={
              query.get('doc_id') ?
                `/deals/view/${id}/purchase-&-sale-agreement-addendum?doc_id=${query.get('doc_id')}` :
                `/deals/view/${id}/purchase-&-sale-agreement-addendum`
            }
          >
            <li
              className={clsx("add-deal chain-step", {
                current: branch === "purchase-&-sale-agreement-addendum",
              })}
            >
              <i
                className={clsx(
                  "fa",
                  { "fa-check-circle stepbar": asterix === "fulfill" },
                  { "fa-circle-o": asterix === "empty" || !asterix }
                )}
              />
              <span className="add-deal-step">Addendum</span>
            </li>
          </Link>
          {/* <Link
            to={
              query.get('doc_id') ?
              `/deals/view/${id}/purchase-&-sale-agreement-addendum/send_to_signer?doc_id=${query.get('doc_id')}` :
              `/deals/view/${id}/purchase-&-sale-agreement-addendum/send_to_signer`
            }
          >
            <li className={clsx("add-deal chain-step", { current: branch === "send_to_signer" })}>
              <i className="fa fa-circle-o"></i>
              <span className="add-deal-step">Send for eSignature</span>
            </li>
          </Link> */}

          {
            dataTransaction?.id &&
            // <li
            //   name="back-to-eDocuments"
            //   id="tab-back-to-eDocuments"
            //   className={clsx("add-deal chain-step")}
            // >
            //   <Link to={`/deals/view/${dataTransaction.id}/main#tab=%22edocuments%22`}> <span uk-icon="chevron-double-left"></span> Back to eDocuments</Link>
            // </li>

            <button className="uk-button uk-button-primary mt-2" onClick={() => history.push(`/deals/view/${dataTransaction.id}/main#tab=%22edocuments%22`)}>
              <span uk-icon="chevron-double-left"></span>Back to eDocuments
            </button>

          }
        </ul>
      </div>
    </PageSidebarContainer>
  );
};

export default AddendumStep;
