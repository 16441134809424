import { Helmet } from "react-helmet";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import DealEditPage from "../deals/Edit";
import ListPage from "../deals/List";
import ViewPage from "../deals/View";
import EditCustomEditor from "../preview-custom-edit";


const InvitationDealPage = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <Helmet>
        <title>Invitation Deals - BoatDox</title>
      </Helmet>
      <Switch>
        <Route exact path={path}  > <ListPage menuType="invitation" /> </Route>
        <Route path={`${path}/view/:id`} > <ViewPage menuType="invitation" /> </Route>
        <Route path={`${path}/add/:id`} component={DealEditPage} />
        <Route path={`${path}/preview/edit`} component={EditCustomEditor} />
        <Redirect to={path} />
      </Switch>
    </>
  );
};

export default InvitationDealPage;
