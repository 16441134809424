import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Helmet } from "react-helmet";
import ESignForm from "./Form";
import ESignSuccess from "./Success";
import ESignReject from "./Reject";

const ESignPage = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <Helmet>
        <title>Sign E-Documents - BoatDox</title>
      </Helmet>
      <Switch>
        <Route path={`${path}/reject`} component={ESignReject} />
        <Route path={`${path}/success`} component={ESignSuccess} />
        <Route path={`${path}/:id`} component={ESignForm} />
      </Switch>
    </>
  );
};

export default ESignPage;
