export const URI_SIDEBAR_DASHBOARD = "dashboard";
export const URI_SIDEBAR_LISTING_AGREEMENTS = "listing-agreement";
export const URI_SIDEBAR_DEALS = "deals";
export const URI_SIDEBAR_INVITATION_DEALS = "invitation-deals";
export const URI_SIDEBAR_REPORTS_DEALS = "reports/deals";
export const URI_SIDEBAR_REPORTS_LISTING_AGREEMENTS = "reports/listing-agreements";
export const URI_SIDEBAR_REPORTS_BROKERS = "reports/brokers";
export const URI_SIDEBAR_REPORTS_OFFICES = "reports/offices";
export const URI_SIDEBAR_COMPANIES = "companies";
export const URI_SIDEBAR_ASSOCIATIONS = "associations";
export const URI_SIDEBAR_VESSELS = "vessels";
export const URI_SIDEBAR_CLIENTS = "clients";
export const URI_SIDEBAR_COMPANY_DOCUMENT_TASK = "company-document-task";
export const URI_SIDEBAR_IYBA_DOCUMENT_TASK = "iyba-document-task";
export const URI_SIDEBAR_OFFICES = "offices";
export const URI_SIDEBAR_BROKERS = "brokers";
export const URI_SIDEBAR_USERS = "users";
export const URI_SIDEBAR_PROFILE = "profile";
export const URI_SIDEBAR_EMAIL_TEMPLATES = "email-templates";
export const URI_SIDEBAR_REPORTS = "reports";
export const URI_SIDEBAR_EDOC_TEMPLATES = "edoc-templates";
export const URI_SIDEBAR_ESIGN_TEMPLATES = "esign-templates";
export const URI_SIDEBAR_TUTORIALS = "tutorials";
export const URI_SIDEBAR_ACTIVITYLOG = "activity-log";
export const URI_SIDEBAR_LIBRARY_MANAGER = "form-library-manager";
export const URI_SIDEBAR_UPLOAD_FILE_MANAGER = "upload-file-manager";
export const URI_SIDEBAR_DOCUMENT_TEMPLATES = "document-templates";
export const URI_SIDEBAR_BROKERAGE = 'brokerage';