import moment from "moment";

import { SET_DATE_FILTER, SET_OFFICE_FILTER, SET_BROKER_FILTER, SET_SORTBY_FILTER, CLEAR_FILTER } from "./types";

export const setDateFilter = (rangeType, range) => {
  let action = {
    type: SET_DATE_FILTER,
    payload: {
      rangeType
    }
  };
  switch (rangeType) {
    case THIS_MONTH:
      action.payload.range = [
        moment().startOf("month").format("MM-DD-YYYY"),
        moment().format("MM-DD-YYYY"),
      ]
      break;
    case LAST_MONTH:
      action.payload.range = [
        moment().subtract(1, "months").startOf("month").format("MM-DD-YYYY"),
        moment().subtract(1, "months").endOf("month").format("MM-DD-YYYY"),
      ]
      break;
    case THIS_QUARTER:
      action.payload.range = [
        moment().startOf('quarter').format('MM-DD-YYYY'),
        moment().format('MM-DD-YYYY')
      ]
      break;
    case LAST_QUARTER:
      action.payload.range = [
        moment().subtract(1, 'quarter').startOf('quarter').format('MM-DD-YYYY'),
        moment().subtract(1, 'quarter').endOf('quarter').format('MM-DD-YYYY'),
      ]
      break;
    case YEAR_TO_DATE:
      action.payload.range = [
        moment().startOf("year").format("MM-DD-YYYY"),
        moment().format("MM-DD-YYYY"),
      ]
      break;
    case THIS_YEAR:
      action.payload.range = [
        moment().startOf('year').format("MM-DD-YYYY"),
        moment().endOf('year').format("MM-DD-YYYY"),
      ]
      break;
    case LAST_YEAR:
      action.payload.range = [
        moment().subtract(1, "years").startOf("year").format("MM-DD-YYYY"),
        moment().subtract(1, "years").endOf("year").format("MM-DD-YYYY"),
      ]
      break;
    case CUSTOM: {
      if (range === undefined) {
        throw new Error("Range is required");
      }
      if (range.length !== 2) {
        throw new Error("Range must have 2 elements");
      }
      action.payload.range = [...range];
      break;
    }
    default:
      throw new Error("Invalid range type");
  }
  return action;
}

export const setOfficeFilter = officeId => ({
  type: SET_OFFICE_FILTER,
  payload: {
    office: officeId
  }
})

export const setBrokerFilter = brokerId => ({
  type: SET_BROKER_FILTER,
  payload: {
    broker: brokerId
  }
})

export const setSortByFilter = sortby => ({
  type: SET_SORTBY_FILTER,
  payload: {
    sortby
  }
})

export const clearFilter = () => ({
  type: CLEAR_FILTER,
})

export const THIS_MONTH = 'THIS_MONTH';
export const LAST_MONTH = 'LAST_MONTH';
export const THIS_QUARTER = 'THIS_QUARTER';
export const LAST_QUARTER = 'LAST_QUARTER';
export const THIS_YEAR = 'THIS_YEAR';
export const YEAR_TO_DATE = 'YEAR_TO_DATE';
export const LAST_YEAR = 'LAST_YEAR';
export const CUSTOM = 'CUSTOM';
