import FormInputWrapper from "../../../../common/FormInputWapper";
import FormRow from "../../../../common/FormRow";
import GridForm from "../../../../common/GridForm";
import ModalBody from "../../../../common/ModalBody";
import OffCanvasContainer from "../../../../common/OffCanvasContainer";
import { getFigure, getPrice } from "../../../../../utils/currency";

const ViewVesselForm = ({ vessel, askingPrice }) => {
  let vessel_title = `${vessel ? vessel.length : ""} ${vessel ? vessel.make : ""} ${vessel ? vessel.model : ""
    } ${vessel ? vessel.year : ""}`;
  vessel_title = vessel_title === "   " ? "N/A" : vessel_title;

  return (
    <OffCanvasContainer id="modal-vessel">
      <ModalBody title="Vessel">
        <GridForm>
          <FormRow>
            <FormInputWrapper label="Hull Number">
              <div className="input-view">{vessel ? vessel.hull_number || "N/A" : "N/A"}</div>
            </FormInputWrapper>
            <FormInputWrapper label="Vessel">
              <div className="input-view">{vessel_title}</div>
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper size="two" label="Engine Description">
              <div className="input-view">{vessel ? `${vessel.number_of_engines || ''} ${vessel.engine_make || ''} ${vessel.engine_model || ''} ${vessel.engine_hp ? '- ' + vessel.engine_hp + 'HP' : ''}` || "N/A" : "N/A"}</div>
            </FormInputWrapper>
            <FormInputWrapper label="Name">
              <div className="input-view">{vessel ? vessel.vessel_name || "N/A" : "N/A"}</div>
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Flag">
              <div className="input-view">{vessel ? vessel.flag || "N/A" : "N/A"}</div>
            </FormInputWrapper>
            <FormInputWrapper label="Doc/Reg No.(Doc#)">
              <div className="input-view">{vessel ? vessel.doc_or_reg_no || "N/A" : "N/A"}</div>
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper size="three" label="Asking Price">
              <div className="input-view">{vessel ? getPrice(askingPrice) || "N/A" : "N/A"}</div>
            </FormInputWrapper>
          </FormRow>
        </GridForm>
      </ModalBody>
    </OffCanvasContainer>
  );
};

export default ViewVesselForm;
