import { Redirect, Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import AddPayOffInformation from "./steps/AddPayOffInformation";
import AddSellerTransactionDetails from "./steps/AddSellerTransactionDetails";
import EsignSellerClosing from "./steps/EsignSellerClosing";
import SellerDetailStepBar from "./stepbars/SellerClosingStep";
import AddSellerExpenses from "./steps/AddSellerExpenses";
import AddSellerCredits from "./steps/AddSellerCredits";
import AddSellerDeposits from "./steps/AddSellerDeposits";
import AddSellerDisbursements from "./steps/AddSellerDisbursements";
import AddSellerNote from "./steps/AddSellerNote";

const SellerDetailOption = () => {
  let { path } = useRouteMatch();
  let { id } = useParams();

  return (
    <div>
      <SellerDetailStepBar />
      <Switch>
        <Route exact path={path} render={() => <AddPayOffInformation transactionId={id} />} />
        <Route path={`${path}/expenses`} render={() => <AddSellerExpenses transactionId={id} />} />
        <Route path={`${path}/credits`} render={() => <AddSellerCredits transactionId={id} />} />
        <Route path={`${path}/deposits`} render={() => <AddSellerDeposits transactionId={id} />} />
        <Route
          path={`${path}/disbursements`}
          render={() => <AddSellerDisbursements transactionId={id} />}
        />
        <Route path={`${path}/notes`} render={() => <AddSellerNote transactionId={id} />} />
        <Route
          path={`${path}/detail`}
          render={() => <AddSellerTransactionDetails transactionId={id} />}
        />
        <Route
          path={`${path}/send_to_signer`}
          render={() => <EsignSellerClosing transactionId={id} />}
        />
        <Redirect to={path} />
      </Switch>
    </div>
  );
};

export default SellerDetailOption;
