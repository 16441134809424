//Define roles which is used for Purchase and sales agreement documents
export const PurchaseAndSalesRoles = [
  "buyer",
  "co_buyer",
  "listing_employing_broker",
  "listing_broker",
  "seller",
  "co_seller",
  "selling_employing_broker",
  "selling_broker"
]

//Define roles which is used for IYBA Purchase and sale agreement and  IYBA Purchase&sale Make Offer documents
export const IYBAPurchaseSaleMakeOffer = [
  "buyer",
  "co_buyer",
  "seller",
  "co_seller",
  "selling_employing_broker",
  "selling_broker"
]

//Define roles which is used for YBAA Purchase and sales agreement documents
export const YBAAPurchaseAndSalesRoles = [
  "buyer",
  "co_buyer",
  "seller",
  "co_seller",
  "selling_employing_broker",
  "selling_broker"
]

//Define roles which is used for listing agreement documents
export const listingAgreementsRoles = [
  "owner",
  "co_owner",
  "principal",
  "employing_broker",
  "listing_broker",
]

//Define roles which is used for CSA listing agreement documents
export const csaListingAgreementsRoles = [
  "owner",
  "co_owner",
  "principal",
  "listing_broker",
  "employing_broker",
]

//Define roles which is used for CYBA listing agreement documents
export const cybaListingAgreementsRoles = [
  "owner",
  "co_owner",
  "principal",
  "listing_broker",
  "employing_broker",
]


//Define roles which is used for CYBA listing agreement Amendment documents
export const cybaListingAgreementAmendmentRoles = [
  "owner",
  "co_owner",
]

//Define roles which is used for listing agreement exclusion documents
export const listingAgreementExclusionRoles = [
  "owner",
  "principal",
  "co_owner"
]

//Define roles which is used for material damage disclosure documents
export const materialDamageDisclosureRoles = [
  "owner",
  "principal",
  "co_owner"
]

//Define roles which is used for YBAA Listing Agreement documents
export const YBAAListingAgreementRoles = [
  "owner",
  "co_owner",
  "listing_broker",
  "employing_broker",
]

//Define roles which is used for CYBA Open Listing Agreement documents
export const CYBAOpenListingAgreement = [
  "owner",
  "co_owner",
  "listing_broker",
  "employing_broker",
]

//Define roles which is used for NYBA Listing Agreement document
export const NYBAListingAgreement = [
  "owner",
  "listing_broker",
  "employing_broker",
]

//Define roles which is used for listing agreement addendum documents
export const listingAgreementAddendumRoles = [
  "owner",
  "principal",
  "co_owner",
  "listing_broker",
  "employing_broker",

]

//Define roles which is used for Buyer Closing Statement document
export const BuyerClosingStmtRoles = [
  "buyer",
  "co_buyer",
  "selling_broker",
  "selling_employing_broker",
]

//Define roles which is used for Trial Run Waiver document
export const TrialRunWaiverRoles = [
  "buyer",
  "co_buyer"
]

//Define roles which is used for Us Power Of Attorney Seller
export const UsPowerOfAttorneyBuyer = [
  "buyer",
  "co_buyer"
]

//Define roles which is used for Disbursement of Funds Buyer
export const DisbursementOfFundsBuyer = [
  "buyer",
  "co_buyer"
]

//Define roles which is used for FL Removal Affidavit - 2024 
export const FLRemovalAffidavit2024 = [
  "buyer",
  "co_buyer"
]

// Define roles which is used for Us Power Of Attorney Buyer
export const UsPowerOfAttorneySeller = [
  "seller",
  "co_seller"
]

// Define roles which is used for FL HSMV 82040 Application For Certification Of Title Vessel Document
export const flHSMV82040ApplicationForCertificateOfTitleVesselRoles = [
  "seller",
]

// Define roles which is used for Purchase and sales agreement Addendum document
export const purchaseAndSaleAddendumRoles = [
  "buyer",
  "co_buyer",
  "seller",
  "co_seller",
]

// Define roles which is used for Purchase and sales agreement Exclusion document
export const purchaseAndSaleExclusionRoles = [
  "buyer",
  "co_buyer",
  "seller",
  "co_seller",
]

//Define roles which is used for Seller Closing Stmt document
export const sellerClosingStmtRoles = [
  "seller",
  "co_seller",
  "listing_broker",
  "listing_employing_broker",
]

//Define roles which is used for Trade in Vessels document
export const tradeInVesselRoles = [
  "buyer",
  "co_buyer",
  "seller",
  "co_seller",
]

//Define roles which is used for Release Of Liability And Harmless document
export const releaseOfLiabilityAndHoldHarmlessRoles = [
  "buyer",
  "co_buyer",
]

//Define roles which is used for Cancellation And Refund Request document
export const cancellationAndRefundRequestRoles = [
  "buyer",
  "co_buyer",
]

//Define roles which is used for Registration Waiver document
export const registrationWaiverRoles = [
  "buyer",
  "co_buyer",
]

//Define roles which is used for Potential Tax Liability document
export const potentialTaxLiabilityRoles = [
  "buyer",
  "co_buyer",
]

//Define roles which is used for FL Sales Tax Exemption Affidavit document
export const flSalesTaxExemptionAffidavitRoles = [
  "buyer",
]

//Define roles which is used for Acceptance Of Vessels document
export const acceptanceOfVesselsRoles = [
  "buyer",
  "co_buyer",
  "seller",
  "co_seller"
]

//Define roles which is used for Bill of Sale document
export const billOfSaleRoles = [
  "listing_employing_broker",
  "listing_broker",
  "buyer",
  "co_buyer",
  "seller",
  "co_seller",
]

//Define roles which is used for Bill of Sale Notary document
export const billOfSaleNotaryRoles = [
  "buyer",
  "co_buyer",
  "seller",
  "co_seller",
]

//Define roles which is used for Warranty Of Title With Notary document
export const warrantyOfTitleWithNotary = [
  "seller",
  "co_seller",
]

//Define roles which is used for USCG Bill of Sale document
export const uscgBillOfSaleRoles = [
  "buyer",
  "co_buyer"
]

//Define roles which is used for USCG 1340 Bill of Sale document
export const uscg1340BillOfSaleRoles = [
  "buyer",
  "co_buyer",
  "seller",
  "co_seller",
]

//Define roles which is used for Co Broker & Comission Agreement document
export const coBrokerComissionRoles = [
  "listing_employing_broker",
  "listing_broker",
  "selling_employing_broker",
  "selling_broker"
]

//Define roles which is used for Co Brokerage Commission Agreement document
export const coBrokerageCommissionAgreementRoles = [
  "listing_broker",
  "selling_broker",
]

//Define roles which is used for Co Broker & Comission Agreement document
export const flDealersSalesTaxStmtRoles = [
  "buyer",
  "seller",
  "broker",
]

//Define roles which is used for Co Broker & Comission Agreement document
export const exemptOfBoatSoldRoles = [
  "buyer",
  "broker",
]

//Define roles which is used for CYBA Buyer Final Statement document
export const CYBABuyerFinalStatement = [
  "buyer",
  "co_buyer",
  "broker",
]

//Define roles which is used for CYBA Seller Final Statement document
export const CYBASellerFinalStatement = [
  "seller",
  "co_seller",
  "broker",
]

//Define roles which is used for CYBA Transaction Summary document
export const CYBATransactionSummary = [
  "broker"
]

//Define roles which is used for Payoff Verification document
export const payoffVerification = [
  "seller",
  "co_seller"
]

//Define roles which is used for Resolution To Buy Corp & LLC
export const resolutionToBuyCorpLLC = [
  "buyer"
];

//Define roles which is used for Resolution To Buy Corp & LLC
export const resolutionToSellCorpLLC = [
  "seller"
];

//Define roles which is used for Commission Agreement (Seller/Co-Broker)
export const commissionAgreementSellerCoBroker = [
  "seller",
  "co_seller",
  "listing_employing_broker",
  "listing_broker",
  "selling_employing_broker",
  "selling_broker"
];

//Define roles which is used for NYBA Purchase and Sale Agreement document
export const nybaPurchaseAndSalesRoles = [
  "buyer",
  "co_buyer",
  "seller",
  "co_seller",
  "listing_employing_broker",
  "listing_broker",
  "selling_employing_broker",
  "selling_broker"
];

//Define roles which is used forNet to seller
export const netToSeller = [
  "seller",
  "co_seller",
  "listing_broker"
];