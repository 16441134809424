import {
  CREATE_OFFICE,
  RETRIEVE_OFFICES,
  GET_OFFICE,
  UPDATE_OFFICE,
  DELETE_OFFICE,
  DELETE_ALL_OFFICES,
  FIND_OFFICE_BY_NAME,
  FIND_BY_COMPANY,
  FIND_BY_OFFICE_ID
} from "../actions/types";

const initialState = [];

const officeReducer = (offices = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_OFFICE:
      return [...offices, payload];
    case RETRIEVE_OFFICES:
      return payload;
    case GET_OFFICE:
      return payload;
    case UPDATE_OFFICE:
      return offices.map((office) => {
        if (office.id === payload.id) {
          return {
            ...office,
            ...payload,
          };
        } else {
          return office;
        }
      });
    case DELETE_OFFICE:
      return offices.filter(({ id }) => id !== payload.id);
    case DELETE_ALL_OFFICES:
      return [];
    case FIND_OFFICE_BY_NAME:
      return payload;
    case FIND_BY_COMPANY:
      return payload;
    case FIND_BY_OFFICE_ID:
      return payload;
    default:
      return offices;
  }
};

export default officeReducer;
