import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createClient, updateClient } from "../../redux/actions/clients";
import { Link, useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import clsx from "clsx";

import ClientsService from "../../services/ClientService";
import StatesForUSA from "../../constants/StatesForUSA";
import Country from "../../constants/Country";
// import { phoneFigure, phoneNumber } from "../../utils/phonenumber";
import { getFormattedData } from "../../utils/stepFilter";
import { setLoadingStatus } from "../../redux/actions/loading";
import "./Form.css"
import { makeStyles } from "@mui/styles";
import PhoneInput from "react-phone-input-2";
import Auth from "../../utils/auth";
import { fetchAllAccessibleBroker } from "../../redux/actions/users";

const schema = yup.object().shape({
  first_name: yup.string().required(),
  middle_name: yup.string().nullable(true),
  last_name: yup.string().required(),
  email: yup.string().required().email(),
  mobile_phone: yup.string().nullable(true),
  spouse_name: yup.string().nullable(true),
  spouse_mobile_phone: yup.string().nullable(true),
  spouse_email: yup.string().email().nullable(true),
  additional_contact_name: yup.string().nullable(true),
  additional_contact_mobile_phone: yup.string().nullable(true),
  additional_contact_email: yup.string().email().nullable(true),
  additional_contact_title: yup.string().nullable(true),
  country: yup.string().nullable(true),
  address_1: yup.string().nullable(true),
  address_2: yup.string().nullable(true),
  postal_code: yup.string().nullable(true),
  city: yup.string().nullable(true),
  state: yup.string().nullable(true),
  nationality: yup.string().nullable(true),
  broker_user_id: yup.string().required(),
  createdBy: yup.string().required(),
  updatedBy: yup.string().required(),
});

const useStyles = makeStyles({
  phone_input: {
    padding: '12px 12px 12px 50px !important',
    width: '100% !important'
  },
  broker_dropdown: {
    marginTop: "10px",
  },
  broker_dropdown_label: {
    marginTop: "23px",
    marginRight: "-20px",
    marginLeft: "-14px"
  }
});

const ClientForm = (props) => {

  const logged_user = Auth.getInstance().getUserData();
  const classes = useStyles();
  const { client_id } = props.location.state;
  const isAddMode = !client_id;
  const history = useHistory();
  const [isSelectedUSA, setSelectedUSA] = useState(true);
  const [isFinishSubmit, setFinishSubmit] = useState(false);
  const [err, setErr] = useState(null);
  const dispatch = useDispatch();

  const allAccessibleBrokers = useSelector((state) => state.users.allAccessibleBrokers);
  allAccessibleBrokers.sort((a, b) => a.last_name < b.last_name ? -1 : 1)

  useEffect(() => {
    dispatch(fetchAllAccessibleBroker(logged_user.id))
  }, [])


  const fields = [
    "spouse_name",
    "spouse_mobile_phone",
    "spouse_email",
    "additional_contact_name",
    "additional_contact_mobile_phone",
    "additional_contact_email",
    "additional_contact_title",
    "first_name",
    "middle_name",
    "last_name",
    "email",
    "mobile_phone",
    "address_1",
    "address_2",
    "address_3",
    "city",
    "state",
    "postal_code",
    "broker_user_id",
    "createdBy",
    "updatedBy",
    "company_id",
    "company_office_id",
    "nationality",
    "country"
  ];

  useEffect(() => {
    if (!isAddMode) {
      ClientsService.get(client_id).then((client) => {
        fields.forEach((field) => {
          setValue(field, client.data[field])
          if (field == "broker_user_id") {
            setValue("broker_user_id", client.data[field] ? client.data[field] : logged_user.id)
          }
          if (field == "createdBy") {
            setValue("createdBy", client.data[field] ? client.data[field] : logged_user.id)
          }
          if (field == "updatedBy") {
            setValue("updatedBy", logged_user.id)
          }
          if (["spouse_mobile_phone", "additional_contact_mobile_phone", "mobile_phone"].includes(field)) {
            if (!client.data[field]) {
              setValue(field, "+1")
            }
          }
        });
        client.data.country === "USA" ? setSelectedUSA(true) : setSelectedUSA(false);
        // setValue("spouse_mobile_phone", client.data.spouse_mobile_phone);
      });
    } else {
      setValue("country", "USA");
      setSelectedUSA(true);
      setValue("broker_user_id", logged_user.id)
      setValue("createdBy", logged_user.id)
      setValue("updatedBy", logged_user.id)
      setValue("company_id", logged_user.company_id)
      setValue("company_office_id", logged_user.company_office_id)
    }
  }, []);

  /* Form Validation */
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  useEffect(() => {
    if (getValues("country") === "USA") {
      setSelectedUSA(true);
    } else {
      setSelectedUSA(false);
    }
  }, [watch("country")]);

  // useEffect(() => {
  //   let figure = phoneFigure(getValues("mobile_phone"));
  //   setValue("mobile_phone", phoneNumber(figure));
  // }, [watch("mobile_phone")]);

  /* Handle Function for From Inputs */
  const handleRegistration = (client) => {
    dispatch(setLoadingStatus(true));
    client.email = client.email?.toLowerCase();
    client.deleted = false;
    client = getFormattedData(client);
    if (isAddMode) {
      dispatch(createClient(client))
        .then(() => {
          setFinishSubmit(true);
          setErr(null);
          dispatch(setLoadingStatus(false));
          setTimeout(() => {
            history.push("/clients");
          }, 2000);
        })
        .catch((e) => {
          setErr(e.response ? e.response.data.message : e.message);
          dispatch(setLoadingStatus(false));
          setTimeout(() => {
            setErr(null);
          }, 2000);
        });
    } else {
      dispatch(updateClient(client_id, client))
        .then(() => {
          setFinishSubmit(true);
          setErr(null);
          dispatch(setLoadingStatus(false));
          setTimeout(() => {
            history.push("/clients");
          }, 2000);
        })
        .catch((e) => {
          setErr(e.response ? e.response.data.message : e.message);
          dispatch(setLoadingStatus(false));
          setTimeout(() => {
            setErr(null);
          }, 2000);
        });
    }
  };

  const handleError = (errors) => {
    console.log("Errors = ", errors);
  };

  const handleChangeBrokerDropdown = (event) => {
    const company_id = event.target[event.target.selectedIndex].getAttribute("data-company")
    const office_id = event.target[event.target.selectedIndex].getAttribute("data-office")
    setValue("broker_user_id", event.target.value)
    setValue("company_id", company_id)
    setValue("company_office_id", office_id)
    isAddMode && setValue("createdBy", event.target.value);
  }

  return (
    <div className="uk-container page">
      <ul className="uk-breadcrumb">
        <li>
          <Link to={"/clients"}>Clients</Link>
        </li>
        <li className="uk-disabled">{isAddMode ? "Add a Client" : "Edit a Client"}</li>
      </ul>
      <div className="uk-column-1-3  uk-margin-small ">
        <h1>{isAddMode ? "Add Client" : "Edit Client"}</h1>
        <div className={`one-column uk-padding-small`}>
          {/* <h2 className={`${classes.broker_dropdown_label} uk-form-label uk-position-right`}>Broker :</h2> */}
          <label className={`${classes.broker_dropdown_label} uk-form-label uk-position-right`}>Broker :</label>
        </div>
        <div className="one-column uk-padding-small">
          <select
            className={clsx(`uk-select ${classes.broker_dropdown}`, { "uk-form-danger": errors.broker_user_id?.message })}
            {...register('broker_user_id')}
            onChange={(event) => handleChangeBrokerDropdown(event)}
          >
            <option key={`client_broker_user`} data-company={logged_user.company_id} data-office={logged_user.company_office_id} value={logged_user.id}>{logged_user.last_name} {logged_user.first_name} </option>
            {
              allAccessibleBrokers && allAccessibleBrokers.length > 0 && allAccessibleBrokers.map((broker, id) => {
                return (
                  <option key={id} data-company={broker.company_id} data-office={broker.company_office_id} value={broker.id}>{broker.last_name} {broker.first_name} </option>
                )
              })
            }
          </select>
        </div>
      </div>

      {isFinishSubmit ? (
        isAddMode ? (
          <div className="uk-alert uk-alert-primary" data-uk-alert>
            <p>Added Successfully</p>
          </div>
        ) : (
          <div className="uk-alert uk-alert-primary" data-uk-alert>
            <p>Updated Successfully</p>
          </div>
        )
      ) : undefined}

      {err ? (
        <div className="uk-alert uk-alert-danger" data-uk-alert>
          <p>{err}</p>
        </div>
      ) : (
        ""
      )}

      <div className="form-container uk-margin-small">
        <form
          onSubmit={handleSubmit(handleRegistration, handleError)}
          className="uk-grid-small form"
          data-uk-grid
          id="addform"
          method="POST"
          onReset={reset}
        >
          <div className="form-row">
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">First Name*</label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.first_name,
                })}
                type="text"
                {...register("first_name")}
              />
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">Middle Name</label>
              <input
                className="uk-input"
                type="text"
                id="middle_name"
                {...register("middle_name")}
              />
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">Last Name*</label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.last_name?.message,
                })}
                type="text"
                {...register("last_name")}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">Email*</label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.email?.message,
                })}
                type="email"
                {...register("email")}
              />
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="name">Mobile Phone</label>
              <input type="hidden" required name="mobile_phone"  {...register("mobile_phone")} />
              <PhoneInput
                placeholder='+'
                country={'us'}
                inputClass={classes.phone_input}
                containerClass={classes.phone_container}
                autoFocus={false}
                onFocus={() => { }}
                value={getValues("mobile_phone")}
                onChange={phone => { setValue('mobile_phone', phone, { shouldValidate: true }) }}
              />
              <p style={{ color: "red" }}>{errors.mobile_phone?.message}</p>
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">Country</label>
              <select className="uk-select" {...register("country")}>
                {Country.map((country) => {
                  return (
                    <option value={country.code} key={country.code}>
                      {country.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="form-row">
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">Spouse Name</label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.spouse_name,
                })}
                type="text"
                {...register("spouse_name")}
              />
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="spouse_mobile_phone">Spouse Mobile Phone</label>
              <input type="hidden" name="spouse_mobile_phone" {...register("spouse_mobile_phone")} />
              <PhoneInput
                placeholder='+'
                country={'us'}
                inputClass={classes.phone_input}
                containerClass={classes.phone_container}
                value={getValues("spouse_mobile_phone")}
                onChange={phone => { setValue('spouse_mobile_phone', phone, { shouldValidate: true }) }}
              />
              <p style={{ color: "red" }}>{errors.spouse_mobile_phone?.message}</p>
            </div>

            <div className="one-column uk-padding-small">
              <label className="uk-form-label">Spouse Email</label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.spouse_email?.message,
                })}
                type="email"
                {...register("spouse_email")}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">Address 1</label>
              <input className="uk-input" type="text" {...register("address_1")} />
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">Address 2</label>
              <input className="uk-input" type="text" {...register("address_2")} />
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">Address 3</label>
              <input className="uk-input" type="text" {...register("address_3")} />
            </div>
          </div>
          <div className="form-row">
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">City</label>
              <input className="uk-input" type="text" {...register("city")} />
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">State</label>
              {isSelectedUSA ? (
                <select className="uk-select" {...register("state")}>
                  <option value="" key="default">Select</option>
                  {StatesForUSA.map((state) => {
                    return (
                      <option value={state.code} key={state.code}>
                        {state.name}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <input className="uk-input" type="text" {...register("state")} />
              )}
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">Zip Code</label>
              <input className="uk-input" type="text" {...register("postal_code")} />
            </div>
          </div>
          <div className="form-row">
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">Citizen Of</label>
              <input className="uk-input" type="text" {...register("nationality")} />
            </div>
          </div>

          <div className="form-row"><hr style={{ width: "100%", marginBottom: "14px" }} /></div>
          <div className="form-row">
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">Additional Contact Name</label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.additional_contact_name,
                })}
                type="text"
                {...register("additional_contact_name")}
              />
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="name">Additional Contact Mobile Phone</label>
              <input type="hidden" name="additional_contact_mobile_phone" {...register("additional_contact_mobile_phone")} />
              <PhoneInput
                placeholder='+'
                inputClass={classes.phone_input}
                country={'us'}
                value={getValues("additional_contact_mobile_phone")}
                containerClass={classes.phone_container}
                onChange={phone => { setValue('additional_contact_mobile_phone', phone, { shouldValidate: true }) }}
              />
              <p style={{ color: "red" }}>{errors.additional_contact_mobile_phone?.message}</p>
            </div>

            <div className="one-column uk-padding-small">
              <label className="uk-form-label">Additional Contact Email</label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.additional_contact_email?.message,
                })}
                type="email"
                {...register("additional_contact_email")}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="one-column uk-padding-small">
              <label className="uk-form-label">Additional Contact Title</label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.additional_contact_title,
                })}
                type="text"
                {...register("additional_contact_title")}
              />
            </div>
          </div>


          <div className="form-row">
            <div className="one-column uk-padding-small">
              <input
                type="submit"
                className="uk-button iy-button-primary"
                value={isAddMode ? "Add" : "Save"}
              />
              <Link to={"/clients"}>
                <button className="uk-button uk-button-default" id="add-cancel">
                  Cancel
                </button>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ClientForm;
