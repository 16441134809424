import { isNumber } from "lodash";

export function getLength(length, symbol = "ft") {
  if (length && length.toString().indexOf(symbol) > -1) return length;
  if (!length) return null;
  const value = parseFloat(`${length}`)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  return `${value} ${symbol}`;
}

export function getOrigin(length, symbol = "ft") {
  if (isNumber(length)) return length;
  if (length) {
    return parseFloat(length.replace(` ${symbol}`, "").replaceAll(",", ""));
  } else {
    return null;
  }
}
