
import http, { setHeaders } from '../http-common';
import Auth from '../utils/auth';

setHeaders(Auth.getInstance().getAuthToken());

const getAll = () => {
  return http.get("/associations");
};

const get = (id) => {
  return http.get(`/associations/${id}`);
};

const create = (data) => {
  return http.post("/associations", data);
};

const update = (id, data) => {
  return http.put(`/associations/${id}`, data);
};

const remove = (id) => {
  return http.delete(`/associations/${id}`);
};

const removeAll = () => {
  return http.delete("/associations");
};

const findByName = (name) => {
  return http.post("/associations/searchByName", { association_name: name });
};

const AssociationsService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByName,
};

export default AssociationsService;
