import ModalPortal from "./RolesHelperModalPortal";

const RolesHelper = () => {
  return (
    <ModalPortal>
      <div className="uk-modal-container uk-modal-body uk-margin-auto-vertical" style={{ backgroundColor: 'white', padding: '30px' }}>
        <button className="uk-modal-close-default" type="button" data-uk-close="true"></button>
        <div>
          <p>
            <b>Company Admin: </b>Allows access to all features including:
            Add users, mark brokers and other users Active/InActive, edit the Uploads and Tasks lists company wide, Update Wire instructions, access to all Listings
            and Deals by any Broker/Yacht Sales Person throughout the company and any other company wide edits that are or will be available.
          </p>
          <p>
            <b>Agent: </b>Allows access to all Listings and Deals for all selected "Users" whose "Show as Broker" check box field in User Edit is "ON".
            Access to all Listings and Deals shall either be "Full Access" or "Edit Only" based on "Full Access|Edit Only" toggle settings.
            Users will always have Full Access to their own Listings and Deals.
          </p>
          <p>
            <b>Participant: </b>Allows access only to specific deals and specific documents in those deals.
          </p>

        </div>
      </div>
    </ModalPortal>
  );
};

export default RolesHelper;
