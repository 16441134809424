import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import UserService from "../../services/UserService";

import { phoneNumber } from '../../utils/phonenumber';
import { useDispatch } from "react-redux";
import { setLoadingStatus } from "../../redux/actions/loading";

const UsersView = (props) => {
  const dispatch = useDispatch();
  const user_id = props.location.state ? props.location.state.id : null;

  const [user, setUser] = useState({});

  useEffect(() => {
    dispatch(setLoadingStatus(true))
    UserService.get(user_id).then(res => {
      setUser(res.data);
      dispatch(setLoadingStatus(false))
    }).catch(err => {
      dispatch(setLoadingStatus(false))
    });
  }, []);

  return (
    <div className="uk-container page">
      <ul className="uk-breadcrumb">
        <li><Link to="/users">Users</Link></li>
        <li className="uk-disabled"><a>View User</a></li>
      </ul>

      <h1>{`${user.last_name||""} ${user.first_name||""}`}</h1>

      <div className="form-container">
        <form className="uk-grid-small form" data-uk-grid>
          <div className="uk-width-1-3@s  uk-margin mt20">
            <label className="uk-form-label" htmlFor="name">First Name</label>
            <div className="uk-text uk-text-emphasis">{user.first_name}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">Last Name</label>
            <div className="uk-text uk-text-emphasis">{user.last_name}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">User Role</label>
            {user && user.UserInRole && (
              <div className="uk-text uk-text-emphasis">{user.UserInRole.UserRole.name}</div>
            )}
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">Email</label>
            <div className="uk-text uk-text-emphasis">{user.email}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">Mobile Phone</label>
            <div className="uk-text uk-text-emphasis">{phoneNumber(user.mobile_phone)}</div>
          </div>
          {user && user.company && (
            <div className="uk-width-1-3@s  uk-margin">
              <label className="uk-form-label" htmlFor="name">Company</label>
              <div className="uk-text uk-text-emphasis">{user.company.company_name}</div>
            </div>
          )}
        </form>
      </div>

      <div className="uk-column-1-1  uk-margin-small" id="save-section">
        <Link to={"/users"}><button className="uk-button iy-button-primary" id="add-deal-save">Done</button></Link>
      </div>

    </div>
  );
};

export default UsersView;
