import { Switch, Route, Redirect, useRouteMatch, useParams } from "react-router-dom";
import AddBill from "./steps/AddBillOfSale";
import AddBillEngine from "./steps/EsignBillOfSale";
import BillStepBar from "./stepbars/BillStep";

const BillOfSaleOption = () => {
  let { path } = useRouteMatch();
  let { id } = useParams();

  return (
    <div>
      <BillStepBar />
      <Switch>
        {/* <Route exact path={path} render={() => <AddBill transactionId={id} />} /> */}
        <Route path={`${path}/send_to_signer`} render={() => <AddBillEngine transactionId={id} />} />
        <Redirect to={path} />
      </Switch>
    </div>
  );
};

export default BillOfSaleOption;
