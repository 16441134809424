import React, { useEffect, useState, useCallback } from "react";

import RequiredFieldPortal from "./RequiredFieldPortal";
import './Signature.css';
import { DatePicker, TimePicker } from "antd";
import dayjs from 'dayjs';

const PDFRequiredField = ({ handleUpdateCanvas, removeSignerData, elementId, modalType, questionValue }) => {

  const [requiredTextField, setRequiredTextField] = useState('');
  const [requiredCheckBoxField, setRequiredCheckBoxField] = useState(true);
  const [requiredSellerAuthFields, setRequiredSellerAuthFields] = useState(null);

  useEffect(() => {
    setRequiredTextField('');
    setRequiredCheckBoxField(true);
    setRequiredSellerAuthFields(null);
  }, [elementId]);

  const handleDone = useCallback(() => {
    handleUpdateCanvas({
      modalType: modalType,
      signatureText: modalType == 'RequiredTextbox' ? requiredSellerAuthFields != null ? requiredSellerAuthFields.toString() : requiredTextField : requiredCheckBoxField,
      elementId: elementId,
      questionValue: questionValue
    });
  }, [requiredTextField, requiredCheckBoxField, requiredSellerAuthFields, elementId]);

  const handleCancel = useCallback(() => {
    setRequiredTextField('');
    setRequiredCheckBoxField(true);
    setRequiredSellerAuthFields(null);
  }, []);

  // return question for the checkbox
  const getQuestionValue = (input) => {

    switch (true) {
      case input.includes('vessel_damage'):
        return 'The Vessel sustained material damage?'
      case input.includes('boat_loan'):
        return 'Is there a loan on the boat?'
      case input.includes('boat_run'):
        return 'Has the boat ever run aground?'
      case input.includes('boat_on_water'):
        return 'Has the boat ever taken on water?'
      case input.includes('boat_sunk'):
        return 'Has the boat ever sunk?'
      case input.includes('boat_rebuilt'):
        return 'Have the engines been rebuilt?'
      case input.includes('damage_gear'):
        return 'Has there been any damage to the running gear?'
      case input.includes('boat_collision'):
        return 'Has the boat ever been in a collision?'
      case input.includes('boat_fire'):
        return 'Has there ever been a fire on board?'
      case input.includes('boat_repair'):
        return 'Has there ever been a fire on board?'
      case input.includes('boat_mexico'):
        return 'Has the boat ever been to Mexico?'
      case input.includes('boat_other'):
        return 'Is there anything else the broker should know that may affect the value of this vessel?'
      case input.includes('time'):
        return 'Hours - AM/PM'
      case input.includes('date'):
        return 'Vessel to be available date'
      default:
        return input
    }
  }

  const handleSellerAuthFieldChange = (value) => {
    setRequiredSellerAuthFields(value)
    // setRequiredSellerAuthFields((requiredSellerFields) => {
    //   if (requiredSellerFields.includes(value)) {
    //     return requiredSellerFields.filter((field) => field != value);
    //   }
    //   return [...requiredSellerFields, value];
    // });
  }

  return (
    <RequiredFieldPortal>
      <div className="uk-modal-dialog" style={{ width: "" }}>
        <button className="uk-modal-close-default" type="button" data-uk-close="true"></button>
        <div className="uk-modal-header">
          <h5>Add Your Answer</h5>
        </div>
        <div className="uk-modal-body">

          <div className="signature-text-container">
            {modalType == 'RequiredCheckbox'
              ? <div className="custom-container">
                <div className="centered-element">
                  <h5>{getQuestionValue(questionValue)}</h5>

                  <div className="radio-toolbar">
                    <input
                      type="radio"
                      value="true"
                      id='yes'
                      checked={requiredCheckBoxField}
                      onChange={() => setRequiredCheckBoxField(true)}
                    />
                    <label className="uk-form-label radio">Yes</label>
                    <input
                      type="radio"
                      value="false"
                      id='no'
                      checked={!requiredCheckBoxField}
                      onChange={() => setRequiredCheckBoxField(false)}
                    />
                    <label className="uk-form-label radio">No</label>
                  </div>
                </div>
              </div>
              : questionValue === "time" ? <div style={{ width: "100%" }}>
                <h5>{getQuestionValue(questionValue)}</h5>
                <TimePicker
                  className="signature-text-field uk-textarea"
                  // type="date"
                  style={{ cursor: 'pointer', color: '#000', width: "60%" }}
                  // value={requiredTextField && dayjs(requiredTextField)}
                  format="h A"
                  onChange={async (date, dateString) => {
                    setRequiredTextField(dateString)

                  }}
                />
              </div>
                : questionValue === "date"
                  ? <div style={{ width: "100%" }}>
                    <h5>{getQuestionValue(questionValue)}</h5>
                    <DatePicker
                      className="signature-text-field uk-textarea"
                      // type="date"
                      style={{ cursor: 'pointer', color: '#000', width: "60%" }}
                      value={requiredTextField && dayjs(requiredTextField)}
                      format={'MM/DD/YYYY'}
                      onChange={async (date, dateString) => {
                        setRequiredTextField(dateString)

                      }}
                    />
                  </div>
                  : questionValue == 'Select One Seller Authorizes'
                    ? <div style={{ width: "100%" }}>
                      <h5>{getQuestionValue(questionValue)}</h5>
                      <div className={`one-column m-3`}>
                        <input
                          type="checkbox"
                          name="auth_one"
                          className="auth_one uk-checkbox"
                          onChange={(e) => { handleSellerAuthFieldChange(1); }}
                          checked={requiredSellerAuthFields == 1}
                        />
                        <label className={`uk-form-label font-weight-bold`}>1</label>
                        <input
                          type="checkbox"
                          name="auth_two"
                          className="auth_two uk-checkbox"
                          onChange={(e) => { handleSellerAuthFieldChange(2); }}
                          checked={requiredSellerAuthFields == 2}
                        />
                        <label className={`uk-form-label font-weight-bold`}>2</label>
                        <input
                          type="checkbox"
                          name="auth_three"
                          className="auth_three uk-checkbox"
                          checked={requiredSellerAuthFields == 3}
                          onChange={(e) => { handleSellerAuthFieldChange(3); }}
                        />
                        <label className={`uk-form-label font-weight-bold`}>3</label>
                      </div>
                    </div>
                    : <div style={{ width: "100%" }}>
                      <h5>{questionValue}</h5>
                      <textarea rows='3' className="signature-text-field uk-textarea" placeholder='Enter value'
                        onChange={evt => setRequiredTextField(evt.target.value)}
                        value={requiredTextField}
                      />
                    </div>
            }
          </div>
        </div>

        <div className="uk-modal-footer">
          <div className="row">
            <div className="col-12 uk-padding-small float-right">
              <button
                className="uk-button uk-button-default uk-modal-close small"
                type="button"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                className="uk-button uk-button-primary uk-modal-close small"
                type="button"
                onClick={handleDone}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </RequiredFieldPortal>
  );
}

export default PDFRequiredField;