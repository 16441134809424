import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { object } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  getTransactionEsignById,
  upsertTransaction,
} from "../../../../../redux/actions/transaction";
import FormInputWrapper from "../../../../common/FormInputWapper";
import Form from "../../../../common/Form";
import FormPage from "../../../../common/FormPage";
import FormRow from "../../../../common/FormRow";
import SubmitButton from "../../../../common/buttons/SubmitButton";
import LinkButton from "../../../../common/buttons/LinkButton";
import { getFigure, getPrice } from "../../../../../utils/currency";
import { getPlainTransactionData } from "../../../../../utils/stepFilter";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const schema = object().shape({});

const figureFields = ["payoff", "per_diem"];

const AddPayOffInformation = ({ transactionId }) => {
  const { handleSubmit, setValue, getValues, register, control } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const dataDeal = dataTransaction || {};
  const dataPayOff = dataDeal.t_seller_payoff || {};

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (dataPayOff) {
      Object.keys(dataPayOff).map((key) => {
        if (figureFields.indexOf(key) > -1) {
          setValue(key, getPrice(dataPayOff[key]));
        } else {
          setValue(key, dataPayOff[key]);
        }
      });
    }
  }, [dataPayOff]);

  const handleBlur = (ref) => {
    const price = getPrice(getValues(ref));
    setValue(ref, price);
  };

  const handleFocus = (ref) => {
    const figure = getFigure(getValues(ref));
    setValue(ref, figure);
  };

  const handleRegistration = (detail) => {
    Object.keys(detail).forEach((key) => {
      if (!detail[key]) {
        detail[key] = null;
      }
      if (figureFields.indexOf(key) > -1) {
        detail[key] = getFigure(detail[key]);
      }
    });
    dispatch(
      upsertTransaction({
        step: {
          db: "seller_payoff",
          main: detail,
        },
        transaction: {
          isBelonged: false,
          main: { ...getPlainTransactionData(dataDeal), task_type: "deal" },
        },
      }, "Seller Payoff")
    ).then((data) => {
      dispatch(getTransactionEsignById(data.id));
    });
    history.push(`/deals/view/${transactionId}/seller-closing-statement/expenses`);
  };

  const handleError = () => { };

  const links = [
    { label: "Deals", link: `/deals/view/${transactionId}/main` },
    { label: "Seller Closing Statement" },
  ];

  const vesselName = `${dataTransaction?.t_vessel?.year || ""} ${dataTransaction?.t_vessel?.make || ""} ${dataTransaction?.t_vessel?.model || ""} ${dataTransaction?.t_vessel?.length || ""}`;
  const uniqueTransactionId = dataTransaction?.unique_transaction_id ? `PS-${dataTransaction?.unique_transaction_id}` : '';

  return (
    <FormPage links={links} title="Payoff Information" vesselName={vesselName} uniqueId={uniqueTransactionId}>
      <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
        <FormRow>
          <FormInputWrapper label="Bank Name">
            <input className="uk-input" type="text" {...register("bank_name")} />
          </FormInputWrapper>
          <FormInputWrapper label="Address">
            <input className="uk-input" type="text" {...register("address")} />
          </FormInputWrapper>
          <FormInputWrapper label="Address 2">
            <input className="uk-input" type="text" {...register("address_2")} />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="City">
            <input className="uk-input" type="text" {...register("city")} />
          </FormInputWrapper>
          <FormInputWrapper label="State">
            <input className="uk-input" type="text" {...register("state")} />
          </FormInputWrapper>
          <FormInputWrapper label="Zip">
            <input className="uk-input" type="text" {...register("zip")} />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Phone">
            <input className="uk-input" type="text" {...register("phone")} />
          </FormInputWrapper>
          <FormInputWrapper label="Fax">
            <input className="uk-input" type="text" {...register("fax")} />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Contact">
            <input className="uk-input" type="text" {...register("contact")} />
          </FormInputWrapper>
          <FormInputWrapper label="Mobile">
            <input className="uk-input" type="text" {...register("mobile")} />
          </FormInputWrapper>
          <FormInputWrapper label="Email">
            <input className="uk-input" type="text" {...register("email")} />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Account Number">
            <input className="uk-input" type="text" {...register("account_number")} />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Payoff">
            <input
              className="uk-input"
              type="text"
              {...register("payoff")}
              onBlur={() => handleBlur("payoff")}
              onFocus={() => handleFocus("payoff")}
            />
          </FormInputWrapper>
          <FormInputWrapper label="Good Until">
            <Controller
              control={control}
              name="good_until"
              render={({ field }) => (
                <DatePicker
                  className="uk-input"
                  style={{ cursor: "pointer", color: "#000" }}
                  format={'MM/DD/YYYY'}
                  value={field.value && dayjs(field.value)}
                  onChange={(date, dateString) => {
                    field.onChange(dateString);
                  }}
                />
              )}
            />
          </FormInputWrapper>
          <FormInputWrapper label="Per Diem">
            <input
              className="uk-input"
              type="text"
              {...register("per_diem")}
              onBlur={() => handleBlur("per_diem")}
              onFocus={() => handleFocus("per_diem")}
            />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <SubmitButton>Save & Continue</SubmitButton>
          <LinkButton
            to={`/deals/view/${transactionId}/main`}
            onClick={() => handleRegistration(getValues())}
          >
            Save & Finish Later
          </LinkButton>
        </FormRow>
      </Form>
    </FormPage>
  );
};

export default AddPayOffInformation;
