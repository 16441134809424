import React, { useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from "@hookform/resolvers/yup";

import Form from '../../../../../common/Form';
import FormRow from '../../../../../common/FormRow';
import FormInputWrapper from '../../../../../common/FormInputWapper';
import { getFigure, getPrice } from '../../../../../../utils/currency';
import { setLoadingStatus } from '../../../../../../redux/actions/loading';
import { getFormattedData, getPlainTransactionData } from '../../../../../../utils/stepFilter';
import { getTransactionEsignById, upsertTransaction } from '../../../../../../redux/actions/transaction';

import moment from 'moment';
import * as yup from "yup";
import dayjs from 'dayjs';
import { DatePicker } from 'antd';

const TabFormDisbursements = ({ closePanel }) => {

    const schema = yup.object().shape({});

    const { register, handleSubmit, setValue, reset, getValues, watch, formState: { isDirty, errors }, control } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: false,
    });

    const dispatch = useDispatch();

    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataDeal = dataTransaction || {};
    const dataDetail = dataDeal.t_details || [];

    useEffect(() => {

        // need to do clean all disbursements before fill updated values
        for (let i = 0; i < 5; i++) {
            setValue(`disbursement${i + 1}`, { date: null, amount: null, description: null });
        }

        const disbursements = dataDetail.filter((d) => d.type === "disbursements" && d.document_type === "seller");
        disbursements.forEach((disbursement, idx) => {
            disbursement.date = disbursement.date ? moment(disbursement.date).format("yyyy-MM-DD") : null;
            disbursement.amount = disbursement.amount !== null ? getPrice(disbursement.amount) : null;
            setValue(`disbursement${idx + 1}`, disbursement);
        });
    }, [dataTransaction]);

    const handleBlur = (ref) => {
        const price = getPrice(getValues(`disbursement${ref}.amount`));
        setValue(`disbursement${ref}.amount`, price);
    };

    const handleFocus = (ref) => {
        const figure = getFigure(getValues(`disbursement${ref}.amount`));
        setValue(`disbursement${ref}.amount`, figure);
    };

    const handleRegistration = (data) => {
        let disbursements = [];
        Object.values(data).forEach((val) => {
            const { date, description, amount } = val;
            if (!!date || !!description || !!amount) {
                val.amount = getFigure(val.amount);
                val.date = val.date ? moment(val.date).format() : null;
                val.type = "disbursements";
                val.document_type = "seller";
                val = getFormattedData(val);
                disbursements.push(val);
            }
        });

        dispatch(setLoadingStatus(true));
        dispatch(upsertTransaction({
            step: {
                db: "details",
                main: disbursements,
                detailType: "disbursements",
                documentType: "seller"
            },
            transaction: {
                isBelonged: false,
                main: { ...getPlainTransactionData(dataDeal), task_type: "deal" },
            },
        }, "Disbursement's")
        ).then((data) => {
            dispatch(setLoadingStatus(false));
            dispatch(getTransactionEsignById(data.id));
        }).catch((error) => {
            console.log(error);
            dispatch(setLoadingStatus(false));
        });
    };

    const handleError = (error) => {
        console.log(error);
    };

    return (
        <div className="form-container panel-form">
            <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                <FormRow>
                    <FormInputWrapper label="Disbursement Dates">
                        <Controller
                            control={control}
                            name="disbursement1.date"
                            render={({ field }) => (
                                <DatePicker
                                    className="uk-input"
                                    style={{ cursor: "pointer", color: "#000" }}
                                    format={'MM/DD/YYYY'}
                                    value={field.value && dayjs(field.value)}
                                    onChange={(date, dateString) => {
                                        field.onChange(dateString);
                                    }}
                                />
                            )}
                        />
                    </FormInputWrapper>
                    <FormInputWrapper label="Disbursement Description">
                        <input type="text" className="uk-input" {...register("disbursement1.description")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Disbursement Amount">
                        <input type="text" className="uk-input" {...register("disbursement1.amount")}
                            onBlur={() => handleBlur(1)} onFocus={() => handleFocus(1)} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="">
                        <Controller
                            control={control}
                            name="disbursement2.date"
                            render={({ field }) => (
                                <DatePicker
                                    className="uk-input"
                                    style={{ cursor: "pointer", color: "#000" }}
                                    format={'MM/DD/YYYY'}
                                    value={field.value && dayjs(field.value)}
                                    onChange={(date, dateString) => {
                                        field.onChange(dateString);
                                    }}
                                />
                            )}
                        />
                    </FormInputWrapper>
                    <FormInputWrapper label="">
                        <input type="text" className="uk-input" {...register("disbursement2.description")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="">
                        <input type="text" className="uk-input" {...register("disbursement2.amount")}
                            onBlur={() => handleBlur(2)} onFocus={() => handleFocus(2)} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="">
                        <Controller
                            control={control}
                            name="disbursement3.date"
                            render={({ field }) => (
                                <DatePicker
                                    className="uk-input"
                                    style={{ cursor: "pointer", color: "#000" }}
                                    format={'MM/DD/YYYY'}
                                    value={field.value && dayjs(field.value)}
                                    onChange={(date, dateString) => {
                                        field.onChange(dateString);
                                    }}
                                />
                            )}
                        />
                    </FormInputWrapper>
                    <FormInputWrapper label="">
                        <input type="text" className="uk-input" {...register("disbursement3.description")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="">
                        <input type="text" className="uk-input" {...register("disbursement3.amount")}
                            onBlur={() => handleBlur(3)} onFocus={() => handleFocus(3)} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="">
                        <Controller
                            control={control}
                            name="disbursement4.date"
                            render={({ field }) => (
                                <DatePicker
                                    className="uk-input"
                                    style={{ cursor: "pointer", color: "#000" }}
                                    format={'MM/DD/YYYY'}
                                    value={field.value && dayjs(field.value)}
                                    onChange={(date, dateString) => {
                                        field.onChange(dateString);
                                    }}
                                />
                            )}
                        />
                    </FormInputWrapper>
                    <FormInputWrapper label="">
                        <input type="text" className="uk-input" {...register("disbursement4.description")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="">
                        <input type="text" className="uk-input" {...register("disbursement4.amount")}
                            onBlur={() => handleBlur(4)} onFocus={() => handleFocus(4)} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="">
                        <Controller
                            control={control}
                            name="disbursement5.date"
                            render={({ field }) => (
                                <DatePicker
                                    className="uk-input"
                                    style={{ cursor: "pointer", color: "#000" }}
                                    format={'MM/DD/YYYY'}
                                    value={field.value && dayjs(field.value)}
                                    onChange={(date, dateString) => {
                                        field.onChange(dateString);
                                    }}
                                />
                            )}
                        />
                    </FormInputWrapper>
                    <FormInputWrapper label="">
                        <input type="text" className="uk-input" {...register("disbursement5.description")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="">
                        <input type="text" className="uk-input" {...register("disbursement5.amount")}
                            onBlur={() => handleBlur(5)} onFocus={() => handleFocus(5)} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <div className="text-right pt-3">
                        <button type="button" className='uk-button uk-button-orange' onClick={() => closePanel(true)}>Close</button>
                        <button type="submit" className='uk-button uk-button-primary'>Save</button>
                    </div>
                </FormRow>
            </Form>
        </div>
    )
}

export default TabFormDisbursements;