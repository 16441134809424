import { uuid } from 'short-uuid';
 
import S3Service from '../services/S3Service';

/**
 * Upload the selected files into server after successfully uploaded on S3 server
 * @param {*} selectedFiles 
 * @param {*} moduleType 
 * @returns Promise with the response after server file upload on API
 */
export const fileUploadWithServer = async (selectedFile, moduleType, uniqueId = null, companyUID = null) => {
  try {
    const formData = new FormData();
    formData.append('files', selectedFile);

    return await S3Service.uploadFileAllModules(moduleType, uniqueId, formData, companyUID);
  } catch (error) {
    throw error;
  }
}

export const uploadDocument = async (file, type = '', transactionId, uniqueId, companyUID , isServerUpload = true) => {
  try {
    const originalFile = file.name.split('.');
    let ext = originalFile.length > 1 ? originalFile[originalFile.length - 1] : undefined;
    if (ext.toLowerCase() === 'txt') ext = 'plain';
    if (ext.toLowerCase() === 'jpg') ext = 'jpeg';
    if (ext.toLowerCase() === 'xlsx') ext = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (ext.toLowerCase() === 'xls' || ext.toLowerCase() === 'csv') ext = 'vnd.ms-excel';
    if (ext.toLowerCase() === 'docx') ext = 'vnd.openxmlformats-officedocument.wordprocessingml.document';
    if (ext.toLowerCase() === 'doc') ext = 'msword';
    if (ext.toLowerCase() === 'png') ext = 'png';
    if (ext.toLowerCase() === 'gif') ext = 'gif';
    const filename = uuid();

    var { data: s3UrlResp } = await S3Service.documentUploadUrl(`${filename}.${ext.toLowerCase()}`, type, transactionId);
    // : await S3Service.getUploadUrl(`${filename}.${ext.toLowerCase()}`);

    if (s3UrlResp.error !== null) {
      return Promise.reject(s3UrlResp.error);
    }

    const options = {
      method: 'PUT',
      body: file
    };
    if (isServerUpload) {
      // API call for uploaded file copy on Server
      await uploadCopyToServer(file, `${originalFile[0]}(${filename}).${ext.toLowerCase()}`, type, uniqueId, companyUID);
    }

    const response = await fetch(s3UrlResp.url, options);
    if (!response.ok) {
      return Promise.reject(`${response.status}: ${response.statusText}`);
    } else {
      return Promise.resolve(`${filename}.${ext.toLowerCase()}`)
    }
    // return Promise.resolve(`${filename}.${ext.toLowerCase()}`)
  } catch (err) {
    return Promise.reject(err);
  }
}

export const uploadForms = async (file, type, uniqueId, companyUID) => {
  try {
    const originalFile = file.name.split('.');
    let ext = originalFile.length > 1 ? originalFile[originalFile.length - 1] : undefined;
    if (ext.toLowerCase() === 'txt') ext = 'plain';
    if (ext.toLowerCase() === 'jpg') ext = 'jpeg';
    if (ext.toLowerCase() === 'xlsx') ext = 'vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (ext.toLowerCase() === 'xls' || ext.toLowerCase() === 'csv') ext = 'vnd.ms-excel';
    if (ext.toLowerCase() === 'docx') ext = 'vnd.openxmlformats-officedocument.wordprocessingml.document';
    if (ext.toLowerCase() === 'doc') ext = 'msword';
    if (ext.toLowerCase() === 'png') ext = 'png';
    if (ext.toLowerCase() === 'gif') ext = 'gif';
    const filename = uuid();

    var { data: s3UrlResp } = await S3Service.uploadFormsUrl(`${filename}.${ext.toLowerCase()}`, type);

    if (s3UrlResp.error !== null) {
      return Promise.reject(s3UrlResp.error);
    }

    // API call for uploaded file copy on Server
    await uploadCopyToServer(file, type == 'reset-documents' ? `${filename}.${ext.toLowerCase()}` : `${originalFile[0]}(${filename}).${ext.toLowerCase()}`, type, uniqueId, companyUID);

    const options = {
      method: 'PUT',
      body: file
    };

    const response = await fetch(s3UrlResp.url, options);
    if (!response.ok) {
      return Promise.reject(`${response.status}: ${response.statusText}`);
    } else {
      return Promise.resolve(`${filename}.${ext.toLowerCase()}`);
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export const downloadDocument = (name, transactionId) => {
  return S3Service.downloadDocument(name, transactionId);
}

export const uploadImage = async (file, type, companyUID = false) => {
  try {
    const originalFile = file.name.split('.');
    let ext = originalFile.length > 1 ? originalFile[originalFile.length - 1] : undefined;
    if (ext.toLowerCase() === 'jpg') ext = 'jpg';
    if (ext.toLowerCase() === 'jpeg') ext = 'jpeg';
    if (ext.toLowerCase() === 'png') ext = 'png';
    if (ext.toLowerCase() === 'gif') ext = 'gif';
    const filename = uuid();

    var { data: s3UrlResp } = await S3Service.uploadImageCDN(`${filename}.${ext.toLowerCase()}`, type);

    if (s3UrlResp.error !== null) {
      return Promise.reject(s3UrlResp.error);
    }

    // API call for uploaded file copy on Server 
    // for company logo we calling this api directly and skipping here
    if (!(companyUID === true)) {
      await uploadCopyToServer(file, `${originalFile[0]}(${filename}).${ext.toLowerCase()}`, type, null, companyUID);
    }

    const options = {
      method: 'PUT',
      body: file
    };

    const response = await fetch(s3UrlResp.url, options);
    if (!response.ok) {
      return Promise.reject(`${response.status}: ${response.statusText}`);
    } else {
      return Promise.resolve(`${filename}.${ext.toLowerCase()}`)
    }
  } catch (err) {
    return Promise.reject(err);
  }
}

export const uploadPdf = async (file, type, uniqueId, companyUID) => {
  try {
   
    const filename = uuid();

    var { data: s3UrlResp } = await S3Service.uploadPdfCDN(`${filename}.pdf`, type);

    if (s3UrlResp.error !== null) {
      return Promise.reject(s3UrlResp.error);
    }


    const options = {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': 'application/pdf',
        'Content-Disposition': 'inline; filename="' + filename + '.pdf"',
      },
    };
    
    var response = await fetch(s3UrlResp.url, options)

    // API call for uploaded file copy on Server
    await uploadCopyToServer(file ,filename, type, uniqueId, companyUID);
    if (!response.ok) {
      return Promise.reject(`${response.status}: ${response.statusText}`);
    } else {
      return Promise.resolve(`${filename}.pdf`)
    }
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * Function for Uploaded file copy to server with specified directory name
 * @param {*} file 
 * @param {*} filename 
 * @param {*} type 
 * @returns Result of File Copy to Server
 */
export const uploadCopyToServer = async (file, filename, type, uniqueId = null, companyUID = null) => {
  try {
    return await fileUploadWithServer(new File([file], filename || 'file.pdf', {
      type: file.type,
      lastModified: file.lastModified,
    }), type, uniqueId, companyUID);
  } catch (error) {
    console.log(error);
    return;
  }
}
