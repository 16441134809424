import { Fragment } from "react";
import Moment from "react-moment";
import { Events } from "../../../../../utils/events";

const DetailOfActivities = (props) => {
  return (
    <div className="uk-accordion-content">
      <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
        <thead>
          <tr>
            <th>Date</th>
            <th>Action</th>
            <th>EDocument</th>
            <th>Signee</th>
          </tr>
        </thead>
        <tbody>
          {props.activity.map((activity) => {
            return (
              <tr key={activity.id}>
                <td><Moment date={activity.updated_at} format="MM-DD-YYYY hh:mm A" /></td>
                <td>{Events[activity.event_type]}</td>
                <td>{activity.document}</td>
                <td>{activity.names.map((name, index) => {
                  return (
                    <Fragment key={index}>{name} <br /></Fragment>
                  )
                })}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
};

export default DetailOfActivities;
