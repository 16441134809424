import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

import VesselAndToyService from '../../services/VesselAndToyService';
import { convertUnderscoreSeparateLetter } from '../../utils/functions';
import { useDispatch } from 'react-redux';
import { setLoadingStatus } from '../../redux/actions/loading';
import { toast } from 'react-toastify';
import { Switch } from '@mui/material';

import './surveyWaiverFormDataModal.css';

const SurveyWaiverFormDataModal = ({ vesselId, show, onClose }) => {

    const dispatch = useDispatch();

    const [waiverData, setWaiverData] = useState({});

    useEffect(() => {
        if (show) {
            getVesselSurveyWaivers();
        }
    }, [show]);

    const getVesselSurveyWaivers = async () => {
        dispatch(setLoadingStatus(true));
        try {
            const response = await VesselAndToyService.getSurveyWaiverData(vesselId);
            const waiverFields = response.data.vessel.waiver_fields
                ? JSON.parse(response.data.vessel.waiver_fields)
                : {
                    waive_vessel_survey: false,
                    waive_engine_inspection: false,
                    waive_trial_run: false,
                    waive_visual_inspection: false,
                    no_item_waived: false
                };
            setWaiverData(waiverFields);
            dispatch(setLoadingStatus(false));
        } catch (error) {
            dispatch(setLoadingStatus(false));

        }
    }

    const onChangeWaiver = (waiveType, value) => {
        setWaiverData(state => {
            return { ...state, [waiveType]: !value }
        });
    }

    const handleSubmit = async () => {
        dispatch(setLoadingStatus(true));
        VesselAndToyService.updateTransactionVessel(vesselId, { waiver_fields: JSON.stringify(waiverData) }).then(response => {
            dispatch(setLoadingStatus(false));
            toast.success('Survey Waiver submitted successfully');
            onClose(true);
        }).catch(error => {
            console.error(error);
            dispatch(setLoadingStatus(false));
            console.error('Something went wrong while submitting data');
        });
    }

    const renderWaiverFields = () => {
        const tifOptions = Object.keys(waiverData).map(key => {
            return <div className="form-row mb-1" key={key}>
                <label htmlFor="small-radius-switch">
                    <Switch
                        checked={waiverData[key]}
                        onChange={() => onChangeWaiver(key, waiverData[key])} />
                </label>
                <span className='ml-2 font-weight-bold'>
                    {convertUnderscoreSeparateLetter(key)}
                </span>
            </div>
        })
        return tifOptions;
    }

    return (
        <Modal
            show={show}
            onHide={onClose}
            centered={true}>
            <Modal.Header closeButton>
                <h3>Survey Waiver Form</h3>
            </Modal.Header>
            <Modal.Body>
                <div className='p-2 ml-2'>
                    {renderWaiverFields()}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="uk-button uk-button-default uk-modal-close small" type="button" onClick={(e) => onClose(false)}>
                    Cancel
                </button>
                <button
                    className="uk-button uk-button-primary small"
                    type="button" onClick={handleSubmit}>Save</button>
            </Modal.Footer>
        </Modal>
    )
}

export default SurveyWaiverFormDataModal;
