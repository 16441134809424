import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Helmet } from "react-helmet";
import ListPage from "./List/index";
import ViewPage from "./View/index";
import EditPage from "./Edit";
import EditPreviewDocument from "../preview-edit";
import EditCustomEditor from "../preview-custom-edit";

const ListingAgreementPage = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <Helmet>
        <title>Listing Agreements - BoatDox</title>
      </Helmet>
      <Switch>
        <Route exact path={path} component={ListPage} />
        <Route path={`${path}/view/:id`} component={ViewPage} />
        <Route path={`${path}/add/:id`} component={EditPage} />
        <Route path={`${path}/preview/edit`} component={EditPreviewDocument} />
      </Switch>
    </>
  );
};

export default ListingAgreementPage;
