import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getTransactionEdocById, updateTaskForTransaction } from "../../../../../redux/actions/transaction";
import { useParams } from "react-router-dom";
import Auth from "../../../../../utils/auth";
import { Modal } from "react-bootstrap";
import { setLoadingStatus } from "../../../../../redux/actions/loading";

const UpdateTaskStatus = ({ task, transactionId, show, onClose }) => {

  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [taskStatus, setTaskStatus] = useState([]);
  const urlParams = useParams();
  const logged_user = Auth.getInstance().getUserData();

  useEffect(() => {
    if (task) {
      setTaskStatus(task?.task_type == 'document'
        ? [{ name: 'Open', value: 'open' }, { name: 'Required', value: 'required' }, { name: 'Received', value: 'received' }, { name: 'N/A', value: 'not_available' }]
        : [{ name: 'Not Started', value: 'not_started' }, { name: 'In Process', value: 'in_process' }, { name: 'Completed', value: 'completed' }, { name: 'N/A', value: 'not_available' }]
      );
      setStatus(task.status ||"not_started");
    }
  }, [show]);

  const handleUpdate = (e) => {
    dispatch(setLoadingStatus(true));
    task.status = status;
    task.actionBy = logged_user.id
    task.transactionId = transactionId;
    delete task.updatedAt;
    if (status === "received" || status === "completed") {
      const userId = localStorage.getItem("userId");
      task.completed_by_user_id = userId;
      task.completed_at = new Date();
    }
    dispatch(updateTaskForTransaction(task))
      .then((res) => {
        dispatch(getTransactionEdocById(urlParams.id));
        dispatch(setLoadingStatus(false));
        onClose(true);
      })
      .catch((err) => {
        console.log(err)
        dispatch(setLoadingStatus(false));
      })
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered={true}>
      <Modal.Header closeButton>
        <h3>Update {task?.task_type == 'document' ? 'Document' : 'Task'} Status</h3>
      </Modal.Header>
      <Modal.Body>
        <div className='p-2' style={{ height: '120px' }}>
          <div className="h-75">
            <p style={{ fontSize: '15px' }}><b>Title:</b>&nbsp;{task?.company_tasks?.title}</p>
            <select className="uk-select" value={status} onChange={(e) => setStatus(e.target.value)}>
              {taskStatus.map((value) => (
                <option key={value.value} value={value.value}>{value.name}</option>
              ))}
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="uk-button uk-button-default uk-modal-close small" type="button" onClick={(e) => onClose()}>
          Cancel
        </button>
        <button
          className="uk-button uk-button-primary small"
          type="button" onClick={() => handleUpdate()}>Done</button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateTaskStatus;
