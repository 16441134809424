import dayjs from "dayjs";

// Define all the constant variables used throughout the front side application and use it in multiple places

export const testCompanyId = '912b0e9e-dad5-4963-b677-958f313b7634';

// form wise all date fields  
export const offerFormDateFields = ["offer_date", "offer_expiration_date", "accept_reject_date", "closing_date"];

export const tradeInVesselDateFields = ["accept_reject_date"];

export const termsDateFields = ["contract_date", "contract_end_date"];

export const dateRangePickerPresets = [
    { label: 'Today', value: [dayjs(), dayjs()] },
    { label: 'Yesterday', value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')] },
    { label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: 'This Month', value: [dayjs().startOf('month'), dayjs()] },
    { label: 'Last Month', value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] },
    { label: 'This Year', value: [dayjs().startOf('year'), dayjs()] },
];