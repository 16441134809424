import { Route, Switch, useRouteMatch } from "react-router";
import { Helmet } from "react-helmet";
import CompanyForm from "./Form";
import CompaniesList from "./List";
import CompanyView from "./View";

const CompanyPage = () => {
  let { path } = useRouteMatch();
  return (
    <>
      <Helmet>
        <title>Companies - BoatDox</title>
      </Helmet>
      <Switch>
        <Route exact path={path} component={CompaniesList} />
        <Route path={`${path}/add`} component={CompanyForm} />
        <Route path={`${path}/edit`} component={CompanyForm} />
        <Route path={`${path}/view`} component={CompanyView} />
      </Switch>
    </>
  );
};

export default CompanyPage;
