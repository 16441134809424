import boatMakes from './boat-makes';
import boatTypes from './boat-types';
import engineMakes from './engine-makes';
import toyTypes from './toy-types';
import manufacturersList from './manufacturers';
import trailerTypes from './trailer-type';

const lists = {
  boatMakes: boatMakes.split(/\r?\n/).sort((prev, next) => prev.localeCompare(next)),
  boatTypes: boatTypes.split(/\r?\n/).sort((prev, next) => prev.localeCompare(next)),
  engineMakes: engineMakes.split(/\r?\n/).sort((prev, next) => prev.localeCompare(next)),
  toyTypes: toyTypes.split(/\r?\n/).sort((prev, next) => prev.localeCompare(next)),
  manufacturers: manufacturersList.split(/\r?\n/).sort((prev, next) => prev.localeCompare(next)),
  trailerTypes: trailerTypes.split(/\r?\n/).sort((prev, next) => prev.localeCompare(next))
};

export default lists;