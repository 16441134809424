import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import DataTable from "react-data-table-component";
import { DELETE_MODAL_MESSAGE, DELETE_MODAL_TITLE } from "../../../../../constants/Alert";
import datatableStyles from "../../../../../utils/datatable-style";
import { updateTransactionVesselOrToy, updateVesselOrToy } from "../../../../../redux/actions/vessels_toys";
import { removeToyFromTransaction } from "../../../../../redux/actions/transaction";
import { setLoadingStatus } from "../../../../../redux/actions/loading";

const ToyTable = ({ fromPanel = false, onEdit = null }) => {
  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const dataListing = dataTransaction || {};
  const data = dataListing.t_toys || [];
  const dispatch = useDispatch();

  const onDelete = (id) => {
    confirmAlert({
      title: DELETE_MODAL_TITLE,
      message: DELETE_MODAL_MESSAGE,
      overlayClassName: "title-overlay-custom-class",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const data = {
              deleted: true,
              deleted_at: new Date(),
            };
            dispatch(setLoadingStatus(true));
            dispatch(updateTransactionVesselOrToy(id, data))
              .then(() => {
                dispatch(setLoadingStatus(false));
                dispatch(removeToyFromTransaction(id));
              })
              .catch((e) => {
                dispatch(setLoadingStatus(false));
                console.log("Error = ", e);
              });
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const columns = [
    {
      name: "Vessel",
      selector: "vessel",
      width: '250px',
      cell: (row) => {
        let vesselName = `${row.t_vessel?.year || ""} ${row.t_vessel?.make || ""} ${row.t_vessel?.model || ""
          } ${row.t_vessel?.length || ""}`;
        vesselName = vesselName === "   " ? "N/A" : vesselName;
        return (
          <div data-tag="allowRowEvents">
            <div>{vesselName}</div>
          </div>
        );
      },
    },
    {
      name: "Hull Number",
      selector: "hull_number",
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>{row.t_vessel?.hull_number || "N/A"}</div>
          </div>
        );
      },
    },
    {
      name: "Vessel Name",
      selector: "vessel_name",
      width: '250px',
      cell: (row) => {
        return (
          <div data-tab="allowRowEvents">
            <div>{row.t_vessel?.vessel_name || "N/A"}</div>
          </div>
        );
      },
    },
    {
      name: "Action",
      cell: (row) => {
        return (
          <div>
            {fromPanel
              ? <img src="/icons/edit.svg" onClick={() => onEdit(row.t_vessel?.id)} className="svg uk-margin-right" alt="" />
              : <Link
                to={{
                  pathname: `/listing-agreement/add/${dataListing.id}/toys/edit`,
                  state: { id: row.t_vessel?.id },
                }}
              >
                <img src="/icons/edit.svg" className="svg uk-margin-right" alt="" />
              </Link>
            }
            <img
              src="/icons/delete.svg"
              className="svg"
              onClick={() => onDelete(row.t_vessel?.id)}
              alt=""
            />
          </div>
        );
      },
    },
  ];

  return (
    <DataTable
      title="Toys & Tenders"
      columns={columns}
      data={data}
      striped={true}
      highlightOnHover={true}
      pointerOnHover={true}
      center={true}
      noHeader={true}
      fixedHeader={true}
      customStyles={datatableStyles}
      refresh={true}
    />
  );
};

export default ToyTable;
