import {
  GET_TRANSACTIONS,
  UPDATE_FIELD_OF_TRANSACTION,
  UPSERT_TRANSACTION,
  CLEAR_TRANSACTION,
  GET_OFFER_FOR_TRANSACTION,
  GET_ADDENDUM_FOR_TRANSACTION,
  GET_EXCLUSION_FOR_TRANSACTION,
  GET_CO_BROKER_FOR_TRANSACTION,
  GET_TRANSACTION_BY_ID,
  UPDATE_TASK_ORDER_FOR_TRANSACTION,
  UPDATE_TASK_FOR_TRANSACTION,
  INSERT_TASK_TO_TRANSACTION,
  DELETE_TASK_FOR_TRANSACTION,
  UPDATE_REQUIRED_FIELDS,
  ESIGN_REQUEST,
  DELETE_TOY_FROM_TRANSACTION,
  UPDATE_OPTION_REQUIRED_FIELDS,
  SET_BROKER,
  GET_LISTING_SELLER,
  RESET_EDOC,
  CLEAR_TRANSACTIONS,
  DELETE_COBROKER,
  ESIGN_REQUEST_UPDATE,
  GET_TRANSACTION_INITIAL_BY_ID,
  GET_TRANSACTION_EDOC_BY_ID,
  GET_TRANSACTION_ESIGN_BY_ID,
  GET_TRANSACTION_COMPANY_BY_ID,
  PUT_ASSOCIATION_FOR_TRANSACTION,
  GET_COMPANY_EDOC_TASK_LIST
} from "../actions/types";

const actionForState = (action, state) => ({
  ...action,
  success: state === "success",
  loading: state === "pending",
  message: state,
});

const initialOfOptionalFields = {
  acceptanceOfVessel: [{
    "0": "Please add form data of acceptance of vessel document"
  }],
  billOfSale: [],
  warrantyOfTitle: [],
  buyerClosing: [{
    "0": "Please add form data of buyer closing statement document"
  }],
  sellerClosing: [{
    "0": "Please add form data of seller closing statement document"
  }],
  addendum: [{
    "0": "Please add form data of addendum document"
  }],
  exclusion: [{
    "0": "Please add form data of exclusion document"
  }],
  tradeInVessel: [{
    "0": "Please add form data of trade in vessel document"
  }],
  materialDamage: [{
    "0": "Please add form data of material damage disclosure document"
  }]
}

const initialState = {
  error: "",
  all: [],
  transaction: null,
  offer: null,
  addendum: null,
  exclusions: [],
  cobroker: null,
  toys: [],
  broker: null,
  previewAction: {
    success: undefined,
    loading: undefined,
    message: undefined,
  },
  requiredFields: {
    vessel: [],
    buyer: [],
    co_buyer: [],
    offer: [],
    seller: [],
    co_seller: [],
    trailer: [],
    terms: [],
    co_broker: [],
  },
  requiredOptionFields: initialOfOptionalFields,
};

function transactionReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TRANSACTIONS:
      return {
        ...state,
        all: payload,
      };
    case UPSERT_TRANSACTION:
      return {
        ...state,
        transaction: payload,
      };
    case CLEAR_TRANSACTION:
      return {
        ...state,
        transaction: null,
        offer: null,
        addendum: null,
        exclusions: [],
        cobroker: null,
        toys: [],
        requiredFields: {
          vessel: [],
          buyer: [],
          co_buyer: [],
          offer: [],
          seller: [],
          co_seller: [],
          trailer: [],
          terms: [],
          co_broker: [],
        },
        requiredOptionFields: initialOfOptionalFields,
      };
    case CLEAR_TRANSACTIONS:
      return {
        ...state,
        all: [],
      };
    case UPDATE_FIELD_OF_TRANSACTION:
      if (payload && payload.deleted) {
        delete payload.deleted;
      }
      return {
        ...state,
        transaction: { ...state.transaction, ...payload },
      };
    case GET_OFFER_FOR_TRANSACTION:
      return {
        ...state,
        offer: payload,
      };
    case GET_ADDENDUM_FOR_TRANSACTION:
      return {
        ...state,
        addendum: payload,
      };
    case GET_EXCLUSION_FOR_TRANSACTION:
      return {
        ...state,
        exclusions: payload,
      };
    case GET_CO_BROKER_FOR_TRANSACTION:
      return {
        ...state,
        cobroker: payload,
      };
    case GET_TRANSACTION_BY_ID:
      return {
        ...state,
        transaction: payload,
      };
    case GET_TRANSACTION_INITIAL_BY_ID:
      return {
        ...state,
        transaction: { ...(state.transaction || {}), ...payload },
      };
    case GET_TRANSACTION_EDOC_BY_ID:
      return {
        ...state,
        transaction: { ...(state.transaction || {}), ...payload },
      };
    case GET_COMPANY_EDOC_TASK_LIST:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          t_task_master: payload
        }
      };
    case GET_TRANSACTION_ESIGN_BY_ID:
      return {
        ...state,
        transaction: { ...(state.transaction || {}), ...payload },
      };
    case GET_TRANSACTION_COMPANY_BY_ID:
      return {
        ...state,
        transaction: { ...(state.transaction || {}), ...payload },
      };
    case ESIGN_REQUEST.pending:
      return {
        ...state,
        previewAction: actionForState(state.action, "pending"),
      };
    case ESIGN_REQUEST.success:
      let updated_edocs = state.transaction.t_edocuments.map(edoc => edoc.id === payload.id ? payload : edoc);
      return {
        ...state,
        transaction: { ...state.transaction, t_edocuments: updated_edocs },
        previewAction: actionForState(state.action, "success"),
      };
    case ESIGN_REQUEST.error:
      return {
        ...state,
        previewAction: actionForState(state.action, "failed"),
      };
    case UPDATE_TASK_ORDER_FOR_TRANSACTION: {
      const tasks = state.transaction.t_task_master;
      const update = tasks.map((task) => {
        let newTask = payload.filter((p) => p.id === task.id)[0];
        if (newTask) {
          return newTask;
        }
        return task;
      });
      const transaction = { ...state.transaction, t_tasks: update };
      return {
        ...state,
        transaction: transaction,
      };
    }
    case UPDATE_TASK_FOR_TRANSACTION: {
      const tasks = state.transaction.t_task_master;
      const update = tasks.map((task) => {
        if (task.id === payload.id) return payload;
        return task;
      });
      const transaction = { ...state.transaction, t_task_master: update };
      return { ...state, transaction: transaction };
    }
    case INSERT_TASK_TO_TRANSACTION: {
      const tasks = state.transaction.t_task_master;
      const update = tasks.concat(payload);
      const transaction = { ...state.transaction, t_task_master: update };
      return { ...state, transaction: transaction };
    }
    case DELETE_TASK_FOR_TRANSACTION: {
      const tasks = state.transaction.t_task_master;
      const update = tasks.filter((task) => task.id !== payload);
      const transaction = { ...state.transaction, t_task_master: update };
      return { ...state, transaction: transaction };
    }
    case UPDATE_REQUIRED_FIELDS:
      return {
        ...state,
        requiredFields: { ...state.requiredFields, ...payload },
      };
    case DELETE_TOY_FROM_TRANSACTION: {
      const toys = state.transaction.t_toys;

      const update = toys.filter((toy) => toy.transaction_vessel_id !== payload);
      const transaction = { ...state.transaction, t_toys: update };
      return {
        ...state,
        transaction: transaction,
      };
    }
    case UPDATE_OPTION_REQUIRED_FIELDS:
      return {
        ...state,
        requiredOptionFields: { ...state.requiredOptionFields, ...payload },
      };
    case SET_BROKER:
      return {
        ...state,
        broker: payload,
      };
    case GET_LISTING_SELLER:
      const transaction = { ...state.transaction, listing_seller: payload };
      return {
        ...state,
        transaction: transaction,
      };
    case RESET_EDOC:
      let reset_edocs = state.transaction.t_edocuments.map(edoc => edoc.id === payload.id ? payload : edoc);
      return {
        ...state,
        transaction: { ...state.transaction, t_edocuments: reset_edocs }
      };
    case DELETE_COBROKER:
      if (payload) {
        return {
          ...state,
          transaction: {
            ...state.transaction,
            co_broker: null,
            co_broker_contact_id: null,
          },
        };
      }
      break;
    case ESIGN_REQUEST_UPDATE.pending:
      return state;
    case ESIGN_REQUEST_UPDATE.success:
      return state;
    case ESIGN_REQUEST_UPDATE.error:
      return state;

    case PUT_ASSOCIATION_FOR_TRANSACTION:
      return {
        ...state,
        transaction: {
          association: payload
        }
      };

    default:
      return state;
  }
}

export default transactionReducer;
