import { Switch, Route, Redirect, useRouteMatch, useParams } from "react-router-dom";
import AddAcceptanceOfVessel from "./steps/AddVesselAcceptance";
import AddAcceptanceEngine from "./steps/EsignVesselAcceptance";
import VesselStepBar from "./stepbars/AcceptanceStep";

const VesselAcceptanceOption = () => {
  let { path } = useRouteMatch();
  let { id } = useParams();

  return (
    <div>
      <VesselStepBar />
      <Switch>
        <Route exact path={path} render={() => <AddAcceptanceOfVessel transactionId={id} />} />
        <Route
          path={`${path}/send_to_signer`}
          render={() => <AddAcceptanceEngine transactionId={id} />}
        />
        <Redirect to={path} />
      </Switch>
    </div>
  );
};

export default VesselAcceptanceOption;
