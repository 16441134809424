const OffCanvasContainer = ({ id, children }) => {
  return (
    <div id={id} data-uk-offcanvas="flip: true; overlay: true" >
      <div className="uk-offcanvas-bar" style={{maxWidth:"100%"}}>
        <button className="uk-offcanvas-close" type="button" data-uk-close="true"></button>
        {children}
      </div>
    </div>
  );
};

export default OffCanvasContainer;
