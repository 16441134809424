import OffCanvasContainer from "../../../../common/OffCanvasContainer";
import ModalBody from "../../../../common/ModalBody";
import DetailOfBuyer from "../details/DetailOfBuyer";
import DetailOfCoBuyer from "../details/DetailOfCoBuyer";

const ViewBuyerForm = ({ buyer, cobuyer, type }) => {
  return (
    <OffCanvasContainer id="modal-buyer">
      <ModalBody title="Buyer">
        <DetailOfBuyer buyer={buyer} type={type} />
      </ModalBody>
      <ModalBody title="Co-Buyer">
        <DetailOfCoBuyer cobuyer={cobuyer} />
      </ModalBody>
    </OffCanvasContainer>
  );
};

export default ViewBuyerForm;
