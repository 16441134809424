import React, { useEffect } from "react";
import FormRow from "../../../../common/FormRow";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import clsx from "clsx";
import FormPage from "../../../../common/FormPage";
import Form from "../../../../common/Form";
import FormInputWrapper from "../../../../common/FormInputWapper";
import SubmitButton from "../../../../common/buttons/SubmitButton";
import LinkButton from "../../../../common/buttons/LinkButton";
import dropdownLists from '../../../../../constants/dropdownLists';
import { getPlainTransactionData } from "../../../../../utils/stepFilter";
import { useDispatch, useSelector } from "react-redux";
import { getTransactionInitialById, upsertTransaction } from "../../../../../redux/actions/transaction";
import TrailerTable from "../table/TrailerTable";
import ThemeButton from "../../../../common/buttons/ThemeButton";
import { useState } from "react";
import TrailerService from "../../../../../services/trailer";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { getFigure, getPrice } from "../../../../../utils/currency";
import { setLoadingStatus } from "../../../../../redux/actions/loading";

//Define schema for form
const schema = yup.object().shape({
    year: yup.string().required(),
    make: yup.string().required(),
    model: yup.string().nullable(true),
    type: yup.string().nullable(true),
    weight: yup.string().nullable(true),
    vin: yup.string().required(),
    plate_no: yup.string().nullable(true),
    reg_no: yup.string().nullable(true),
    title_no: yup.string().nullable(true),
    plate_reg_issuing_entity: yup.string().nullable(true),
    title_issuing_entity: yup.string().nullable(true),
    price: yup.string().required(),
    sales_tax_collected: yup.string().nullable(true),
});

const TrailerForm = ({ step, setStep, setAsterix, id }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    let { path, url } = useRouteMatch();

    //Fetch dropdown list option
    const { manufacturers, trailerTypes } = dropdownLists;

    //State for storing current transaction
    const dataTransaction = useSelector((state) => state.transactions.transaction);

    //State for storing current transaction broker
    const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);

    //Store current transaction
    const dataListing = dataTransaction || {};

    //Define flag to check it is a add form page or edit form.
    const [isAdd, setIsAdd] = useState(false)

    // const isAdd = id ? false : true;
    const isEdit = id ? true : false;

    const [requiredFields, setRequiredFields] = useState(['vin', 'make', 'year', 'price']);

    const { register, handleSubmit, setValue, reset, trigger, clearErrors, getValues, watch, formState: { errors } } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    //useEffect for autofill trailer form
    useEffect(() => {
        if (isEdit) {

            //Fetch trailer data from ID
            TrailerService.getTrailerById(id)
                .then((res) => {
                    let trailer = res.data;
                    if (trailer) {
                        //Auto fill data
                        Object.keys(trailer).forEach((key) => {
                            clearErrors(key);
                            setValue(key, trailer[key] ? trailer[key] : null);
                        });
                        setValue("price", getPrice(trailer.price || 0));
                        setValue("sales_tax_collected", getPrice(trailer.sales_tax_collected || 0));
                        requiredFields.map((value) => {
                            manageRequireFields(value, getValues(value));
                        });
                    } else {
                        Object.keys(trailer).forEach((key) => setValue(key, null));
                    }
                })
        }
    }, [isEdit])

    //Define function to handle form validations errors.
    const handleError = err => {
        console.log('Error = ', err);
    };

    const links = [
        { label: "Listing Agreements", link: `/listing-agreement` },
        { label: "Add a Listing Agreement", link: null },
    ];

    ///Define function for update or store the data of trailer
    const handleRegisteration = (data) => {

        //CHeck form value is valid or not
        const isValid = schema.isValidSync(data);

        //Show toaster method if form value is not valid
        if (!isValid) {
            toast.error("Please fill a valid value for trailer", {
                autoClose: 2000,
            });
        }

        //Convert price into number
        if (data && data.price) {
            data.price = getFigure(data.price)
        }

        //Convert sales tax into number
        if (data && data.sales_tax_collected) {
            data.sales_tax_collected = getFigure(data.sales_tax_collected)
        }

        dispatch(setLoadingStatus(true));
        //if it is a edit form and form is valid then update the data of trailer.
        if (isEdit && isValid) {
            TrailerService.updateTrailerById(id, data)
                .then((res) => {

                    //Fetch latest transaction by ID
                    dispatch(getTransactionInitialById(dataListing.id));

                    dispatch(setLoadingStatus(false));

                    //Show toaster
                    // toast.info("Updated successfully", {
                    //     autoClose: 2000,
                    // });

                    //Clear form
                    let obj = {}
                    Object.keys(data).map((key) => obj[key] = null);
                    reset(obj);
                    history.push(`/listing-agreement/add/${dataListing.id}/trailer`);
                })
                .catch((e) => {
                    dispatch(setLoadingStatus(false));
                    toast.error("Error occur. Please try again", {
                        autoClose: 2000,
                    });
                });
            return;
        }

        //If it is add and data is not null
        if (Object.keys(data).length > 0 && isAdd) {

            //If form is valid then add trailer data.
            if (isValid) {

                //send trailer data to the server
                dispatch(
                    upsertTransaction({
                        step: {
                            db: "trailer",
                            main: data
                        },
                        transaction: {
                            isBelonged: false,
                            main: {
                                ...getPlainTransactionData(dataListing),
                                task_type: "listing",
                                broker_user_id: dataBroker?.id,
                                company_id: dataBroker?.company_id,
                                company_office_id: dataBroker?.company_office_id,
                            },
                        },
                    },"Trailer's")
                )
                    .then((data) => {

                        //Get updated transaction
                        dispatch(getTransactionInitialById(data.id));
                        dispatch(setLoadingStatus(false));

                    })
                    .catch((e) => {
                        dispatch(setLoadingStatus(false));
                        toast.error("Error occur. Please try again", {
                            autoClose: 2000,
                        });
                    });

                //Clear form
                let obj = {}
                Object.keys(data).map((key) => obj[key] = null);
                reset(obj);
            }
        } else {
            dispatch(setLoadingStatus(false));
            //If data is null then update step
            setStep(step + 1);
        }
    };

    //Function to convert price into `$xxx` format
    const handleBlur = (fieldName) => {
        if (fieldName) {
            let value = getValues(fieldName);
            let price = getPrice(value);
            setValue(fieldName, price);
        }
    };

    //Function to convert price into number
    const handleFocus = (fieldName) => {
        if (fieldName) {
            let value = getValues(fieldName);
            let price = getPrice(value);
            setValue(fieldName, getFigure(price));
        }
    };

    const manageRequireFields = (fieldName, fieldValue) => {
        setRequiredFields(state => {
            if (fieldValue) {
                return state.filter(field => field != fieldName);
            }
            return state.includes(fieldName) ? state : [...state, fieldName];
        })
    }

    const vesselName = `${dataTransaction?.t_vessel?.year || ""} ${dataTransaction?.t_vessel?.make || ""} ${dataTransaction?.t_vessel?.model || ""} ${dataTransaction?.t_vessel?.length || ""}`;
    const uniqueTransactionId = dataTransaction?.unique_transaction_id ? `LA-${dataTransaction?.unique_transaction_id}` : '';

    return (
        <FormPage links={links} title="Trailer" vesselName={vesselName} uniqueId={uniqueTransactionId}>
            <FormRow>
                {!isEdit && <TrailerTable taskType="listing-agreement" />}
            </FormRow>
            {
                !isEdit &&
                <FormRow>
                    <label className="toys">Do you want to add trailer ?</label>
                    <div className="radio-toolbar">
                        <input
                            type="radio"
                            id="radioTrailerYes"
                            name="radioTrailer"
                            checked={isAdd}
                            onChange={(e) => setIsAdd(e.target.checked)}
                        />
                        <label for="radioTrailerYes" className="uk-form-label radio">Yes</label>
                        <input
                            type="radio"
                            id="radioTrailerNo"
                            name="radioTrailer"
                            checked={!isAdd}
                            onChange={(e) => {
                                clearErrors();
                                setIsAdd(!e.target.checked);
                            }}
                        />
                        <label for="radioTrailerNo" className="uk-form-label radio">No</label>
                    </div>
                    {/* </Column> */}
                </FormRow>
            }
            {
                <Form style={{ marginTop: "10px" }} onSubmit={handleSubmit(handleRegisteration, handleError)}>
                    {

                        (isAdd || isEdit) &&
                        <>
                            <FormRow>
                                <FormInputWrapper label="Year *">
                                    <input className={clsx("uk-input", {
                                        "uk-form-danger": requiredFields.includes('year'),
                                    })} type="text" {...register("year", {
                                        onChange: e => {
                                            manageRequireFields('year', e.target.value);
                                        }
                                    })} />
                                </FormInputWrapper>
                                <FormInputWrapper label="Make *">
                                    <input
                                        type="text"
                                        className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('make') })}
                                        {...register("make", {
                                            onChange: e => {
                                                manageRequireFields('make', e.target.value);
                                            }
                                        })}
                                    />
                                    {/* <select
                                        className={clsx("uk-select", { "uk-form-danger": requiredFields.includes('make'), })}
                                        {...register("make", {
                                            onChange: e => {
                                                manageRequireFields('make', e.target.value);
                                            }
                                        })} >
                                        {manufacturers.map((manufacturer, idx) => (
                                            <option key={`toy_vessel_make_${idx}`} value={manufacturer}>{manufacturer}</option>
                                        ))}
                                    </select> */}
                                </FormInputWrapper>
                                <FormInputWrapper label="Model">
                                    <input
                                        type="text"
                                        className={clsx("uk-input", { 'uk-form-danger': errors.model })}
                                        {...register("model")}
                                    />
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="Type">
                                    <select
                                        className={clsx("uk-select", { 'uk-form-danger': errors.type })}
                                        {...register("type")}
                                    >
                                        {trailerTypes.map((trailerType, idx) => (
                                            <option key={`trailer_type_${idx}`} value={trailerType}>{trailerType}</option>
                                        ))}
                                    </select>
                                </FormInputWrapper>
                                <FormInputWrapper label="Weight">
                                    <input className={clsx("uk-input", { 'uk-form-danger': errors.weight })} type="text" {...register("weight")} />
                                </FormInputWrapper>
                                <FormInputWrapper label="VIN *">
                                    <input
                                        type="text"
                                        className={clsx("uk-input", { "uk-form-danger": requiredFields.includes('vin') })}
                                        {...register("vin", {
                                            onChange: e => {
                                                manageRequireFields('vin', e.target.value);
                                            }
                                        })}
                                    />
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="Plate No.">
                                    <input className={clsx("uk-input", { 'uk-form-danger': errors.plate_no })} type="text" {...register("plate_no")} />
                                </FormInputWrapper>
                                <FormInputWrapper label="Reg No.">
                                    <input className={clsx("uk-input", { 'uk-form-danger': errors.reg_no })} type="text" {...register("reg_no")} />
                                </FormInputWrapper>
                                <FormInputWrapper label="Plate/Reg Issuing Entity">
                                    <input className={clsx("uk-input", { 'uk-form-danger': errors.plate_reg_issuing_entity })} type="text" {...register("plate_reg_issuing_entity")} />
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="Title No.">
                                    <input
                                        type="text"
                                        className={clsx("uk-input", { 'uk-form-danger': errors.title_no })}
                                        {...register("title_no")}
                                    />
                                </FormInputWrapper>
                                <FormInputWrapper label="Title Issuing Entity">
                                    <input className={clsx("uk-input", { 'uk-form-danger': errors.title_issuing_entity })} type="text" {...register("title_issuing_entity")} />
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="Price *">
                                    <input
                                        className={clsx("uk-input", { "uk-form-danger": requiredFields.includes('price') })}
                                        {...register("price", {
                                            onChange: e => {
                                                manageRequireFields('price', e.target.value);
                                            }
                                        })}
                                        type="text"
                                        onBlur={() => handleBlur("price")}
                                        onFocus={() => handleFocus("price")}
                                    />
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="Sales Tax Collected">
                                    <input
                                        className={clsx("uk-input", { 'uk-form-danger': errors.sales_tax_collected })}
                                        type="text"
                                        {...register("sales_tax_collected")}
                                        onBlur={() => handleBlur("sales_tax_collected")}
                                        onFocus={() => handleFocus("sales_tax_collected")}
                                    />
                                </FormInputWrapper>
                            </FormRow>
                            {/* <AsterixTypo isVisible={true} /> */}
                            <FormRow>
                                <SubmitButton>{!isEdit ? "Save and Add Another" : "Update"}</SubmitButton>
                                <LinkButton to={`/listing-agreement/add/${dataListing.id}/trailer`} onClick={
                                    () => {
                                        clearErrors();
                                        let obj = {}
                                        Object.keys(getValues()).map((key) => obj[key] = null);
                                        reset(obj);
                                        setIsAdd(false)
                                    }
                                }>Cancel</LinkButton>
                            </FormRow>
                        </>
                    }

                    {!isEdit && (
                        <FormRow style={{ marginTop: "20px" }}>
                            {
                                isAdd &&
                                <ThemeButton theme="primary" type="submit" onClick={async () => {
                                    const isValid = schema.isValidSync(getValues());
                                    await trigger(Object.keys(getValues()));
                                    if (isValid) {
                                        await handleRegisteration(getValues());
                                        let obj = {};
                                        Object.keys(getValues()).map((key) => (obj[key] = null));
                                        reset(obj);
                                        setStep(step + 1)
                                    }
                                }} >
                                    Save & Continue
                                </ThemeButton>
                            }
                            <ThemeButton theme="primary" onClick={async () => {
                                await trigger(Object.keys(getValues()));
                                const isValid = schema.isValidSync(getValues());
                                if (!isAdd) {
                                    let obj = {};
                                    Object.keys(getValues()).map((key) => (obj[key] = null));
                                    reset(obj);
                                    setStep(step + 1);
                                }
                                if (isValid) {
                                    await handleRegisteration(getValues());
                                    let obj = {};
                                    Object.keys(getValues()).map((key) => (obj[key] = null));
                                    reset(obj);
                                    setStep(step + 1);
                                }
                            }}>
                                Continue
                            </ThemeButton>
                            <LinkButton to="/listing-agreement">Finish Later</LinkButton>
                        </FormRow>
                    )}
                </Form>
            }
        </FormPage>
    )

}

export default TrailerForm