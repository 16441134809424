import {
  GET_USER,
  SET_USER,
  FIND_USER_BY_NAME,
  RETRIEVE_USER_ROLES,
  RETRIEVE_USER_OFFICES,
  INVITE_USER,
  RETRIEVE_BROKERS,
  CLEAR_BROKERS,
  RETRIEVE_USER_RELATED,
  RETRIEVE_USERS,
  RETRIEVE_OFFICE_USERS,
  CLEAR_OFFICE_USERS,
  UPDATE_VERIFICATION_STATUS,
  UPDATE_USER,
  UPDATE_PROFILE,
  RETRIEVE_MLS_OFFICES,
  SET_OFFICE_WITH_BROKER,
  ALL_ACCESSIBLE_BROKERS
} from "../actions/types";

const actionForState = (action, state) => ({
  ...action,
  success: state === "success",
  loading: state === "pending",
  message: state,
});

const initialState = {
  cogitoUser: {},
  users: [],
  mlsOffices: [],
  officeUsers: [],
  roles: [],
  offices: [],
  userProfile: null,
  brokers: [],
  related: [],
  officeListWithBroker: [],
  allAccessibleBrokers: [],
  logged_by: {},
  rolesAction: {
    success: undefined,
    loading: undefined,
    message: undefined,
  },
  userAction: {
    success: undefined,
    loading: undefined,
    message: undefined,
  },
  officeAction: {
    success: undefined,
    loading: undefined,
    message: undefined,
  },
  brokerAction: {
    success: undefined,
    loading: undefined,
    message: undefined,
  },
  relatedAction: {
    success: undefined,
    loading: undefined,
    message: undefined,
  },
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER.pending:
      return {
        ...state,
      };
    case GET_USER.success:
      return {
        ...state,
        logged_by: action.payload,
      };
    case GET_USER.error:
      return {
        ...state,
      };
    case UPDATE_USER.pending:
      return {
        ...state,
      };
    case UPDATE_USER.success:
      return {
        ...state,
        logged_by: action.payload,
      };
    case UPDATE_USER.error:
      return {
        ...state,
      };
    case RETRIEVE_USERS.pending:
      return {
        ...state,
        rolesAction: actionForState(state.action, "pending"),
      };
    case RETRIEVE_USERS.success:
      return {
        ...state,
        users: action.payload,
        rolesAction: actionForState(state.action, "success"),
      };
    case RETRIEVE_USERS.error:
      return {
        ...state,
        rolesAction: actionForState(state.action, "failed"),
      };
    case RETRIEVE_USER_ROLES.pending:
      return {
        ...state,
        rolesAction: actionForState(state.action, "pending"),
      };
    case RETRIEVE_USER_ROLES.success:
      return {
        ...state,
        roles: action.payload,
        rolesAction: actionForState(state.action, "success"),
      };
    case RETRIEVE_USER_ROLES.error:
      return {
        ...state,
        rolesAction: actionForState(state.action, "failed"),
      };
    case RETRIEVE_USER_RELATED.pending:
      return {
        ...state,
        relatedAction: actionForState(state.action, "pending"),
      };
    case RETRIEVE_USER_RELATED.success:
      return {
        ...state,
        related: action.payload,
        relatedAction: actionForState(state.action, "success"),
      };
    case RETRIEVE_USER_RELATED.error:
      return {
        ...state,
        relatedAction: actionForState(state.action, "failed"),
      };
    case ALL_ACCESSIBLE_BROKERS:
      return {
        ...state,
        allAccessibleBrokers: action.payload,
      };
    case RETRIEVE_USER_OFFICES.pending:
      return {
        ...state,
        officeAction: actionForState(state.action, "pending"),
      };
    case RETRIEVE_USER_OFFICES.success:
      return {
        ...state,
        offices: action.payload,
        officeAction: actionForState(state.action, "success"),
      };
    case RETRIEVE_USER_OFFICES.error:
      return {
        ...state,
        officeAction: actionForState(state.action, "failed"),
      };
    case FIND_USER_BY_NAME.pending:
      return {
        ...state,
        userAction: actionForState(state.action, "pending"),
      };
    case FIND_USER_BY_NAME.success:
      return {
        ...state,
        users: action.payload,
        userAction: actionForState(state.action, "success"),
      };
    case FIND_USER_BY_NAME.error:
      return {
        ...state,
        userAction: actionForState(state.action, "failed"),
      };
    case INVITE_USER.pending:
      return {
        ...state,
        userAction: actionForState(state.action, "pending"),
      };
    case INVITE_USER.success:
      return {
        ...state,
        userAction: actionForState(state.action, "success"),
      };
    case INVITE_USER.error:
      return {
        ...state,
        userAction: actionForState(state.action, "failed"),
      };
    case RETRIEVE_BROKERS.pending:
      return {
        ...state,
        userAction: actionForState(state.action, "pending"),
      };
    case RETRIEVE_BROKERS.success:
      return {
        ...state,
        brokers: action.payload,
        userAction: actionForState(state.action, "success"),
      };
    case RETRIEVE_BROKERS.error:
      return {
        ...state,
        userAction: actionForState(state.action, "failed"),
      };
    case RETRIEVE_OFFICE_USERS.pending:
      return {
        ...state,
        userAction: actionForState(state.action, "pending"),
      };
    case RETRIEVE_OFFICE_USERS.success:
      return {
        ...state,
        officeUsers: action.payload,
        userAction: actionForState(state.action, "success"),
      };
    case RETRIEVE_OFFICE_USERS.error:
      return {
        ...state,
        userAction: actionForState(state.action, "failed"),
      };
    case RETRIEVE_MLS_OFFICES.pending:
      return {
        ...state,
        userAction: actionForState(state.action, "pending"),
      };
    case RETRIEVE_MLS_OFFICES.success:
      return {
        ...state,
        mlsOffices: action.payload,
        userAction: actionForState(state.action, "success"),
      };
    case RETRIEVE_MLS_OFFICES.error:
      return {
        ...state,
        userAction: actionForState(state.action, "failed"),
      };
    case UPDATE_PROFILE.pending:
      return {
        ...state,
        rolesAction: actionForState(state.action, "pending"),
      };
    case UPDATE_PROFILE.success:
      return {
        ...state,
        userProfile: action.payload,
        rolesAction: actionForState(state.action, "success"),
      };
    case UPDATE_PROFILE.error:
      return {
        ...state,
        rolesAction: actionForState(state.action, "failed"),
      };


    case CLEAR_OFFICE_USERS:
      return {
        ...state,
        officeUsers: []
      };
    case CLEAR_BROKERS:
      return {
        ...state,
        brokers: []
      };
    case SET_USER:
      return {
        ...state,
        cognitoUser: action.payload
      };
    case UPDATE_VERIFICATION_STATUS:
      return {
        ...state,
        cognitoUser: {
          ...state.cognitoUser,
          email_verified: true
        }
      };
    case SET_OFFICE_WITH_BROKER:
      return {
        ...state,
        officeListWithBroker: action.payload
      };
    default:
      return state;
  }
}

export default userReducer;
