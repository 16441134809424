import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import clsx from "clsx";
import PageSidebarContainer from "../../../../common/PageSidebarContainer";
import { updateOptionsRequiredFields } from "../../../../../redux/actions/transaction";
import { useQueryParams } from "../../../../../utils/query-params";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ViewStepBar = () => {

  let location = useLocation();
  let { id } = useParams();
  const dispatch = useDispatch();
  const query = useQueryParams();

  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const dataDeal = dataTransaction || {};
  const dataAcceptance = dataDeal.t_acceptance.find(el => el.id === query.get('doc_id')) || {};

  const [asterix, setAsterix] = useState("empty");

  const history = useHistory();

  useEffect(() => {
    if (dataAcceptance) {
      switch (dataAcceptance.status) {
        case 0:
          setAsterix("fulfill");
          break;
        case 1: {
          if (!!dataAcceptance.days_to_complete_conditions && !!dataAcceptance.condition_1 || !!dataAcceptance.condition_2) {
            setAsterix("fulfill");
          } else {
            setAsterix("empty");
          }
          break;
        }
        case 2: {
          if (!!dataAcceptance.adjustment_amount &&
            (!!dataAcceptance.condition_1 || !!dataAcceptance.condition_2)
          ) {
            setAsterix("fulfill");
          } else if (
            !dataAcceptance.adjustment_amount &&
            !dataAcceptance.condition_1 &&
            !dataAcceptance.condition_2
          ) {
            setAsterix("empty");
          } else {
            setAsterix("notFulfill");
          }
          break;
        }
        case 3: {
          if (!!dataAcceptance.reason_for_rejection) {
            setAsterix("fulfill");
          } else {
            setAsterix("empty");
          }
          break;
        }
        default:
          setAsterix("empty");
          break;
      }
    } else {
      setAsterix("empty");
    }
    registerRequiredFields(dataAcceptance);
  }, [dataTransaction]);

  const registerRequiredFields = (acceptance) => {
    switch (acceptance.status) {
      case 0:
        dispatch(
          updateOptionsRequiredFields({
            acceptanceOfVessel: [],
          })
        );
        break;
      case 1:
        if (acceptance.condition_1 || acceptance.condition_2) {
          dispatch(
            updateOptionsRequiredFields({
              acceptanceOfVessel: [],
            })
          );
        } else {
          dispatch(
            updateOptionsRequiredFields({
              acceptanceOfVessel: [{ condition_1: "Condition 1" }],
            })
          );
        }
        break;
      case 2:
        if (
          !!acceptance.adjustment_amount &&
          (!!acceptance.condition_1 || !!acceptance.condition_2)
        ) {
          dispatch(
            updateOptionsRequiredFields({
              acceptanceOfVessel: [],
            })
          );
        } else {
          let fields = [];
          if (!acceptance.days_to_complete_conditions) {
            fields.push({ days_to_complete_conditions: "Days to Complete Conditions" });
          }
          if (!acceptance.adjustment_amount) {
            fields.push({ adjustment_amount: "Adjustment Amount" });
          }
          if (!acceptance.condition_1) {
            fields.push({ condition_1: "Condition 1" });
          }

          dispatch(
            updateOptionsRequiredFields({
              acceptanceOfVessel: fields,
            })
          );
        }
        break;
      case 3:
        if (!acceptance.reason_for_rejection) {
          dispatch(
            updateOptionsRequiredFields({
              acceptanceOfVessel: [{ reason_for_rejection: "Reason For Rejection" }],
            })
          );
        } else {
          dispatch(
            updateOptionsRequiredFields({
              acceptanceOfVessel: [],
            })
          );
        }
        break;
      default:
        break;
    }
  };

  let branch = location.pathname.split("/").pop();

  return (
    <PageSidebarContainer type="add-deal">
      <div className="sidebar-container add-deal">
        <ul className="uk-nav uk-nav-default tm-nav">
          <Link to={`/deals/view/${id}/acceptance-of-vessel`}>
            <li
              className={clsx("add-deal chain-step", {
                current: branch === "acceptance-of-vessel",
              })}
            >
              <i
                className={clsx(
                  "fa",
                  { "fa-check-circle stepbar": asterix === "fulfill" },
                  { "fa-circle-o": asterix === "empty" || !asterix },
                  { "fa-circle": asterix === "notFulfill" }
                )}
              />
              <span className="add-deal-step">Acceptance of Vessel</span>
            </li>
          </Link>
          {/* <Link to={`/deals/view/${id}/acceptance-of-vessel/send_to_signer`}>
            <li className={clsx("add-deal chain-step", { current: branch === "send_to_signer" })}>
              <i className="fa fa-circle-o"></i>
              <span className="add-deal-step">Send for eSignature</span>
            </li>
          </Link> */}

          {
            dataTransaction?.id &&
            // <li
            //   name="back-to-eDocuments"
            //   id="tab-back-to-eDocuments"
            //   className={clsx("add-deal chain-step")}
            // >
            //   <Link to={`/deals/view/${dataTransaction.id}/main#tab=%22edocuments%22`}> <span uk-icon="chevron-double-left"></span> Back to eDocuments</Link>
            // </li>

            <button className="uk-button uk-button-primary mt-2" onClick={() => history.push(`/deals/view/${dataTransaction.id}/main#tab=%22edocuments%22`)}>
              <span uk-icon="chevron-double-left"></span>Back to eDocuments
            </button>
          }

        </ul>
      </div>
    </PageSidebarContainer>
  );
};

export default ViewStepBar;
