import Moment from "react-moment";
import GridForm from "../../../../common/GridForm";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import { getPrice } from "../../../../../utils/currency";

const DetailOfOffer = ({ offer }) => {
  let ofer = offer ? offer : {};

  return (
    <GridForm>
      <FormRow>
        <FormInputWrapper label="Offer Date">
          <div className="input-view">
            {ofer.offer_date && <Moment date={offer.offer_date} format="MMMM DD, YYYY" />}
            {!ofer.offer_date && "N/A"}
          </div>
        </FormInputWrapper>
        <FormInputWrapper label="Offer Expiration Date">
          <div className="input-view">
            {ofer.offer_expiration_date && (
              <Moment date={ofer.offer_expiration_date} format="MMMM DD, YYYY" />
            )}
            {!ofer.offer_expiration_date && "N/A"}
          </div>
        </FormInputWrapper>
        <FormInputWrapper label="Accept/Reject Date">
          <div className="input-view">
            {ofer.accept_reject_date && (
              <Moment date={ofer.accept_reject_date} format="MMMM DD, YYYY" />
            )}
            {!ofer.accept_reject_date && "N/A"}
          </div>
        </FormInputWrapper>
      </FormRow>
      <FormRow>
        <FormInputWrapper label="Closing Date">
          <div className="input-view">
            {ofer.closing_date && <Moment date={ofer.closing_date} format="MMMM DD, YYYY" />}
            {!ofer.closing_date && "N/A"}
          </div>
        </FormInputWrapper>
      </FormRow>
      <FormRow>
        <FormInputWrapper label="Purchase Price">
          <div className="input-view">
            {ofer.purchase_price ? getPrice(ofer.purchase_price) : "N/A"}
          </div>
        </FormInputWrapper>
        <FormInputWrapper size="two" label="Purchase Price(Addt'l Units)">
          <div className="input-view">
            {ofer.purchase_price_additional_units
              ? getPrice(ofer.purchase_price_additional_units)
              : "N/A"}
          </div>
        </FormInputWrapper>
      </FormRow>
      <FormRow>
        <FormInputWrapper label="Less Deposit">
          <div className="input-view">
            {ofer.less_deposit ? getPrice(ofer.less_deposit) : "N/A"}
          </div>
        </FormInputWrapper>
        <FormInputWrapper label="Less Trade Allowance">
          <div className="input-view">
            {ofer.less_trade_allowance ? getPrice(ofer.less_trade_allowance) : "N/A"}
          </div>
        </FormInputWrapper>
      </FormRow>
      <FormRow>
        <FormInputWrapper label="Balance Due">
          <div className="input-view">
            {ofer.purchase_price ? getPrice(ofer.purchase_price - (ofer.less_deposit || 0)) : "N/A"}
          </div>
        </FormInputWrapper>
      </FormRow>
    </GridForm>
  );
};

export default DetailOfOffer;
