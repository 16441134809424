import {
  CREATE_COMPANY,
  RETRIEVE_COMPANIES,
  GET_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY,
  DELETE_ALL_COMPANIES,
  FIND_COMPANY_BY_NAME,
} from "./types";

import CompanyService from "../../services/CompanyService";

export const createCompany = (company) => async (dispatch) => {
  try {
    const res = await CompanyService.create(company);

    dispatch({
      type: CREATE_COMPANY,
      payload: res.data,
    });
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const retrieveCompanies = () => async (dispatch) => {
  try {
    const res = await CompanyService.getAll();
    dispatch({
      type: RETRIEVE_COMPANIES,
      payload: res.data,
    });
  } catch (err) {
    console.log("Error = ", err);
  }
};

export const updateCompany = (id, data) => async (dispatch) => {
  try {
    const res = await CompanyService.update(id, data);

    dispatch({
      type: UPDATE_COMPANY,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getCompany = (id) => async (dispatch) => {
  try {
    const res = await CompanyService.get(id);
    dispatch({
      type: GET_COMPANY,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.resolve(err);
  }
};

export const deleteCompany = (id) => async (dispatch) => {
  try {
    await CompanyService.remove(id);

    dispatch({
      type: DELETE_COMPANY,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllCompanies = () => async (dispatch) => {
  try {
    const res = await CompanyService.removeAll();

    dispatch({
      type: DELETE_ALL_COMPANIES,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findCompanyByName = (name) => async (dispatch) => {
  try {
    const res = await CompanyService.findByName(name);

    dispatch({
      type: FIND_COMPANY_BY_NAME,
      payload: res.data.data
    });
  } catch (err) {
    console.log(err);
  }
};
