
import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import clsx from "clsx";

import { createCompany, getCompany, updateCompany } from "../../redux/actions/companies";
import { retrieveAssociations } from "../../redux/actions/associations";
import { setLoadingStatus } from "../../redux/actions/loading";

import { fileUploadWithServer, uploadCopyToServer, uploadImage } from "../../utils/s3";

const schema = yup.object().shape({
  company_name: yup.string().required(),
  company_email: yup.string().required().email(),
  auth_key: yup.string().nullable(true),
  mls_brokerage_id: yup.string().nullable(true),
  master_user_id: yup.string().nullable(true),
});

const CompanyForm = (props) => {
  const company_id = props.location.state ? props.location.state.id : null;
  const isAddMode = !company_id;

  let history = useHistory();

  /* Define State Variables */
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [associations, setAssociations] = useState([]);
  const [company, setCompany] = useState(undefined);
  const [logo, setLogo] = useState(undefined);

  // uploadFile state for contain uploaded file
  const [uploadFile, setUploadFile] = useState(null);

  const dispatch = useDispatch();

  const dataAssociations = useSelector(state => state.associations);

  /* Form Validation */
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  /* If isAddMode=false, get company by id */
  useEffect(() => {
    dispatch(retrieveAssociations());
    if (!isAddMode) {
      dispatch(setLoadingStatus(true));
      dispatch(getCompany(company_id)).then(company => {
        dispatch(setLoadingStatus(false));
        const companyData = company.data;
        const fields = ["company_name", "company_email", "association_id", "logo", "auth_key", "mls_brokerage_id", "master_user_id"];
        fields.forEach((field) => setValue(field, companyData[field]));
        setAssociations(associationList.filter(associationData => companyData.association.split(',').includes(associationData.fieldName)));
        setCompany(companyData);
        setLogo(companyData['logo'] ? `https://${process.env.REACT_APP_CDN_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}company-logo/${companyData['logo']}` : undefined);
      }).catch(() => {
        dispatch(setLoadingStatus(false));
      });
    }
    setValue("deleted", false);
  }, []);


  /* Handle Function for From Inputs */
  const handleRegistration = async (companyData) => {
    companyData['company_email'] = companyData.company_email?.toLowerCase();
    dispatch(setLoadingStatus(true));
    if (associations.length === 0) {
      dispatch(setLoadingStatus(false));
      setError(true);
      setMessage('You must select one or more associations.');
      return;
    }

    const companyAssociations = associationList.filter(association => {
      return associations.some(selectedAssociation => selectedAssociation.fieldName === association.fieldName)
    }).map(association => association.fieldName);

    companyData['association'] = companyAssociations.toString() || '';
    let filename;
    if (uploadFile) {
      try {
        filename = await uploadImage(uploadFile, 'company-logo', isAddMode ? true : company?.unique_company_id );
        const url = `https://${process.env.REACT_APP_CDN_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}company-logo/${filename}`;
        setValue('logo', url);
      } catch (error) {
        dispatch(setLoadingStatus(false));
        console.log(error);
        return;
      }
    }

    if (companyData['logo']) {
      companyData['logo'] = companyData['logo']?.split('/')[companyData['logo']?.split('/').length - 1];
    }

    if (isAddMode) {
      dispatch(createCompany(companyData))
        .then(async (response) => { 
          // we need company unique id for this api that's why called here
          if (uploadFile && filename) {
            await uploadCopyToServer(uploadFile, `${uploadFile?.name?.split('.')[0]}(${filename.split('.')[0]}).${uploadFile?.name?.split('.')[1]?.toLowerCase()}`, "company-logo", null, response.data.unique_company_id);
          }  
          dispatch(setLoadingStatus(false));
          setSuccess(true);
          setMessage('Added Successfully');
          setTimeout(() => {
            history.push("/companies");
          }, 2000);
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          setError(true);
          if (err.data.error.message) {
            setMessage(err.data.error.message);
          } else {
            setMessage(`Sorry, but we can't proceed your request right now.`);
          }
        });
    } else {
      dispatch(updateCompany(company_id, companyData))
        .then((response) => {
          dispatch(setLoadingStatus(false));
          setSuccess(true);
          setMessage('Updated Successfully');
          setTimeout(() => {
            history.push("/companies");
          }, 2000);
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          if (err.data.error.message) {
            setMessage(err.data.error.message);
          } else {
            setMessage(`Sorry, but we can't proceed your request right now.`);
          }
        });
    }
  };

  const handleAssociationChange = newSelection => setAssociations(newSelection);

  const handleError = (errors) => {
    if (Object.keys(errors)[0]) {
      setError(true);
      setMessage(errors[Object.keys(errors)[0]].message);
    }
  };

  const handleSelectFile = (e) => {
    document.getElementById('file').click();
  }

  const handleFileChange = async (e) => {
    if (e.target.files?.length) {   
      setLogo(URL.createObjectURL(e.target.files[0]));
      setUploadFile(e.target.files[0]);
    }
  }

  const associationList = [
    { id: 0, name: 'IYBA', fieldName: 'iyba' },
    { id: 1, name: 'YBAA', fieldName: 'ybaa' },
    { id: 2, name: 'CYBA', fieldName: 'cyba' },
    { id: 3, name: 'MYBA', fieldName: 'myba' },
    { id: 4, name: 'NYBA', fieldName: 'nyba' },
    { id: 5, name: 'USCG', fieldName: 'uscg' },
    { id: 6, name: 'Custom', fieldName: 'custom' }
  ];

  return (
    <div className="uk-container page">
      <ul className="uk-breadcrumb">
        <li>
          <Link to={"/companies"}>Companies</Link>
        </li>
        <li className="uk-disabled">
          <a>{isAddMode ? "Add a Company" : "Edit a Company"}</a>
        </li>
      </ul>

      <h1>{isAddMode ? "Add Company" : "Edit Company"}</h1>

      {isSuccess ? (
        <div className="uk-alert uk-alert-primary" data-uk-alert>
          <p>{message}</p>
        </div>
      ) : isError ? (
        <div className="uk-alert uk-alert-danger" data-uk-alert>
          <p>{message}</p>
        </div>
      ) : null}

      <div className="form-container uk-margin-small">
        <form
          onSubmit={handleSubmit(handleRegistration, handleError)}
          className="uk-grid-small form"
          data-uk-grid
          id="addform"
          method="POST"
          onReset={reset}
        >
          <div className="px-3  row w-100">
            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="company_name">
                Company Name*
              </label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.company_name,
                })}
                type="text"
                {...register("company_name")}
              />
            </div>

            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="company_name">
                Company Admin Email*
              </label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.company_email,
                })}
                type="email"
                {...register("company_email")}
              />
            </div>
          </div>
          <div className="px-3  row w-100">
            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="association">
                Logo*
              </label>
              <input id="file" type="file" accept="image/x-png,image/jpeg" onChange={handleFileChange} hidden />
              <div
                style={{
                  border: '1px dashed #c3c3c3',
                  width: '203px',
                  height: '40px',
                  color: '#c3c3c3',
                  padding: "10px",
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer'
                }}
                onClick={handleSelectFile}
              >
                {/* logo name is dynamic so this condition will put name of logo  */}
                <span>{(uploadFile) ? uploadFile.name : ((logo) ? company?.logo : "Click Here to Select Logo")}</span>
              </div>
            </div>

            <div className="one-column uk-padding-small">
              <input type="hidden" {...register("logo")} />
              {logo && <img src={logo} height={"150px"} width={"150px"} style={{ marginTop: "19px" }} />}
            </div>
          </div>
          <div className="px-3  row w-100">
            <div className="two-column uk-padding-small pr-0" style={{ width: "416px" }}>
              <label className="uk-form-label" htmlFor="association">
                Association*
              </label>
              <Multiselect
                style={
                  { inputField: { width: "43px" } }
                }
                options={associationList}
                displayValue="name"
                showCheckbox="true"
                selectedValues={associations}
                onSelect={handleAssociationChange}
                onRemove={handleAssociationChange}
              />
            </div>
          </div>
          <div className="px-3  row w-100"><hr style={{ width: "100%", marginBottom: "14px" }} /></div>
          <div className="px-3  row w-100">
            <div className="half-column uk-padding-small">
              <label className="uk-form-label text-wrap"><h2 style={{ marginTop: "19px" }}>Yachtbroker.org MLS Integration :</h2></label>
            </div>
          </div>
          <div className="px-3  row w-100">
            <div className="two-column uk-padding-small" style={{ width: "426px" }}>
              <label className="uk-form-label" >
                Auth Key
              </label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.auth_key,
                })}
                type="text"
                {...register("auth_key")}
              />
            </div>
          </div>

          <div className="px-3  row w-100">
            <div className="half-column uk-padding-small">
              <label className="uk-form-label" >
                Brokerage ID
              </label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.mls_brokerage_id,
                })}
                type="text"
                {...register("mls_brokerage_id")}
              />
            </div>
            <div className="half-column uk-padding-small">
              <label className="uk-form-label" >
                Master User ID
              </label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.master_user_id,
                })}
                type="text"
                {...register("master_user_id")}
              />
            </div>

          </div>
          <div className="px-3  row w-100">
            <input
              type="submit"
              className="uk-button iy-button-primary"
              id="add-company-save"
              value={isAddMode ? "Add" : "Save"}
            />
            <Link to={"/companies"}>
              <button className="uk-button uk-button-default" id="add-cancel">
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompanyForm;
