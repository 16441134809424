import * as React from "react";
import { Unstable_NumberInput as BaseNumberInput } from "@mui/base/Unstable_NumberInput";
import { Input as BaseInput } from "@mui/base/Input";
import { styled } from "@mui/system";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {

  const handleIncrement = () => {
    const newValue = Number(props.value) + 1;
    if (newValue <= props.max) {
      props.onChange(newValue);
    }
  };

  const handleDecrement = () => {
    const newValue = Number(props.value) - 1;
    if (newValue >= props.min) {
      props.onChange(newValue);
    }
  };

  return (
    <div className="d-flex">
      {/* comment for refrence  */}
      {/* <BaseNumberInput
        slots={{
          root: StyledInputRoot,
          input: StyledInput,
          label: StyledLabel,
          incrementButton: StyledButton,
          decrementButton: StyledButton,
        }}
        slotProps={{
          label: "Section",
          incrementButton: {
            children: <AddIcon fontSize="small" />,
            className: "increment",
          },
          decrementButton: {
            children: <RemoveIcon fontSize="small" />,
          },
        }}
        {...props}
        ref={ref}
      />  */}
      <div>
        <StyledButton onClick={handleDecrement}>
          <RemoveIcon fontSize="small" />
        </StyledButton>
      </div>
      <div>
        <BaseInput
          {...props}
          ref={ref}
          slots={{
            root: StyledInputRoot,
            input: StyledInput,
          }}
          onChange={(e) => props.onChange(e.target.value)}
        />
      </div>
      <div>
        <StyledButton className="increment" onClick={handleIncrement}>
          <AddIcon fontSize="small" />
        </StyledButton>
      </div>
    </div>
  );
});

export default function QuantityInput({ handleChange, value, label, maxNum }) {
  return (
    <NumberInput
      aria-label="Quantity Input"
      min={1}
      max={maxNum}
      onChange={handleChange}
      value={value}
    />
  );
}

const blue = {
  100: "#daecff",
  200: "#b6daff",
  300: "#66b2ff",
  400: "#3399ff",
  500: "#007fff",
  600: "#0072e5",
  700: "#0059B2",
  800: "#004c99",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const StyledInputRoot = styled("div")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[500]};
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: center;
`
);
const StyledLabel = styled("label")(
  ({ theme }) => `
font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[500]};
    `
);

const StyledInput = styled("input")(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 0.8;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  border-radius: 4px;
  margin: 0 8px;
  padding: 7px 12px;
  outline: 0;
  min-width: 0;
  width: 8rem;
  text-align: center;

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[700] : blue[200]
    };
  }

  &:focus-visible {
    outline: 0;
  }
`
);

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  line-height: 1.5;
  border: 1px solid;
  border-radius: 4px;
  border-color: ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
  background: ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
  color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
  width: 32px;
  height: 32px;
  display: flex;
  // flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    cursor: pointer;
    background: ${theme.palette.mode === "dark" ? blue[700] : blue[500]};
    border-color: ${theme.palette.mode === "dark" ? blue[500] : blue[400]};
    color: ${grey[50]};
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
`
);
