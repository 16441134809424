import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { createTask, updateTask } from "../../redux/actions/document_tasks";
import { setLoadingStatus } from "../../redux/actions/loading";
import CreatableSelect from 'react-select/creatable';
import DocumentAndTaskService from "../../services/DocumentAndTaskService";
import FormRow from "../common/FormRow";
import FormInputWrapper from "../common/FormInputWapper";
import Auth from "../../utils/auth";

import clsx from "clsx";
import { toast } from "react-toastify";
import * as yup from "yup";

const DocumentTaskForm = (props) => {

  const logged_user = Auth.getInstance().getUserData();
  const isGlobalAdminRole = logged_user?.UserInRole?.UserRole?.name == 'Boatdox Admin';

  const createOption = (label, value) => ({ label, value });

  const schema = yup.object().shape({
    title: yup.string().required(),
    task_type: yup.string().required(),
    active: yup.boolean().required(),
  });

  const {
    checklist_id,
    path,
    task: editTask,
    breadcrumb,
    model,
    tab,
    company_id
  } = props.location.state;
  const isAddMode = !editTask;
  const pathState = {
    checklist_id: checklist_id,
    path: path,
    breadcrumb: breadcrumb,
    model: model,
    tab: tab,
    company_id
  };

  const [isFinishSubmit, setFinishSubmit] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const [companyTagList, setCompanyTagList] = useState([]);

  useEffect(() => {
    if (!isAddMode) {
      const keys = ["title", "task_type", "active", "status"];
      keys.forEach((key) => setValue(key, editTask[key]?.toString()));
    }
  }, []);

  useEffect(() => {
    const getTagList = async () => {
      dispatch(setLoadingStatus(true));
      try {
        const response = isGlobalAdminRole
          ? await DocumentAndTaskService.getIYBATagList()
          : await DocumentAndTaskService.getCompanyTagList(company_id);
        const allTags = (await response.data?.data?.map(tag => {
          return createOption(tag.tag, tag.id)
        }));
        setCompanyTagList(allTags);
        if (!isAddMode) {
          const tagList = editTask["tags_name"].split(',');

          if (tagList.length) {
            const tasks = allTags.filter(tag => tagList.includes(tag.label));
            setTags(tasks || []);
            setValue('tags', tasks.map(tag => {
              return isGlobalAdminRole
                ? { iybaTagTaskId: tag.value }
                : { companyTagTaskId: tag.value }
            }));
          }
        }
        dispatch(setLoadingStatus(false));
      } catch (error) {
        console.error(error);
        dispatch(setLoadingStatus(false));
      }
    }
    getTagList();
  }, []);


  const handleRegisteration = (task) => {
    if (!task['tags']) {
      task['tags'] = [];
    }
    dispatch(setLoadingStatus(true));
    if (isAddMode) {
      task.checklist_id = checklist_id;
      if (!isGlobalAdminRole) {
        task.company_id = company_id;
        task.create_type = 'global';
      }
      dispatch(createTask(task, model)).then((res) => {
        setFinishSubmit(true);
        dispatch(setLoadingStatus(false));
        setTimeout(() => {
          history.push({
            pathname: `${path}/list`,
            state: pathState,
          });
        }, 2000);
      }).catch((err) => {
        dispatch(setLoadingStatus(false));
        console.log(err);
      });
    } else {
      dispatch(updateTask(isGlobalAdminRole ? editTask.iyba_task_id : editTask.company_task_id, { ...task, company_id }, model)).then((res) => {
        setFinishSubmit(true);
        dispatch(setLoadingStatus(false));
        setTimeout(() => {
          history.push({
            pathname: `${path}/list`,
            state: pathState,
          });
        }, 2000);
      }).catch((err) => {
        dispatch(setLoadingStatus(false));
        console.log(err);
      });
    }
  };

  const handleError = (errors) => {
    console.log(errors);
  };

  const [inputValue, setInputValue] = useState('');
  const [tags, setTags] = useState([]);

  /**
   * Handle on Enter or Tab key press on add tag input field
   * Call API for add task after checking the entered task already not exist
   * @param {*} event 
   * @returns 
   */
  const handleKeyDown = async (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        const isTagExist = !!(companyTagList.find(tag => tag.label == inputValue) || tags.find(tag => tag.label == inputValue));
        if (!isTagExist) {
          dispatch(setLoadingStatus(true));
          try {
            const response = isGlobalAdminRole
              ? await DocumentAndTaskService.addIYBATag({ tag: inputValue })
              : await DocumentAndTaskService.addTaskTag({ tag: inputValue, companyId: company_id });
            if (response.status === 200) {
              setTags((tags) => [...tags, createOption(response.data.tag, response.data.id)]);
              setValue('tags', [...(getValues('tags') || []), isGlobalAdminRole ? { iybaTagTaskId: response.data?.id } : { companyTagTaskId: response.data?.id }]);
              setCompanyTagList([...companyTagList, createOption(response.data.tag, response.data.id)])
              dispatch(setLoadingStatus(false));
            }
          } catch (error) {
            toast.error(error.message);
            dispatch(setLoadingStatus(false));
          }
          setInputValue('');
        }
        event.preventDefault();
    }
  };

  /**
   * On select a tag from the list
   * @param {*} tagList 
   */
  const handleTagSelect = (tagList) => {
    if (tagList.length == 0) {
      setTags([]);
      setValue('tags', []);
      return;
    }
    tagList.map(tag => {
      if (tag.__isNew__ || inputValue) return;
      setTags(tagList);
      setValue('tags', tagList.map(tag => {
        return isGlobalAdminRole
          ? { iybaTagTaskId: tag.value }
          : { companyTagTaskId: tag.value }
      }));
    });
  }

  return (
    <div className="tm-main sign-in">
      <div className="  uk-container uk-container-expand uk-position-relative ">
        <ul className="uk-breadcrumb">
          <li>
            <Link to={{ pathname: `${path}/list`, state: pathState }}>
              Uploads List & Tasks
            </Link>
          </li>
          <li className="uk-disabled">
            <a>{isAddMode ? "Add a Task" : "Edit a Task"}</a>
          </li>
        </ul>
        <h1>{isAddMode ? "Add Task" : "Edit Task"}</h1>
        {isFinishSubmit
          ? isAddMode
            ? <div className="uk-alert uk-alert-primary" data-uk-alert>
              <p>Added Successfully</p>
            </div>
            : <div className="uk-alert uk-alert-primary" data-uk-alert>
              <p>Updated Successfully</p>
            </div>
          : <></>}
        <div className="form-container uk-margin-small">
          <form onSubmit={handleSubmit(handleRegisteration, handleError)}
            onReset={reset} className="uk-grid-small form">
            <FormRow>
              <FormInputWrapper width="sm-full-width" label="Title*" size="two">
                <input className={clsx("uk-input", { "uk-form-danger": errors.title })} type="text" {...register("title")} />
              </FormInputWrapper>
              {(!isGlobalAdminRole && tab === "document") &&
                <FormInputWrapper width="sm-full-width" label="Default Status">
                  <select className="uk-select" {...register("status")}>
                    <option value="open">Open</option>
                    <option value="required">Required</option>
                  </select>
                </FormInputWrapper>
              }
            </FormRow>
            {tab !== 'form' && (
              <FormRow>
                <FormInputWrapper width="sm-full-width" size="three" label="Tags">
                  <CreatableSelect
                    options={companyTagList}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    onChange={(newValue) => { handleTagSelect(newValue) }}
                    onInputChange={(newValue) => setInputValue(newValue)}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter tag name"
                    value={tags}
                    formatCreateLabel={(e) => `Create '${e}' by pressing enter`}
                  />
                </FormInputWrapper>
              </FormRow>
            )}
            <FormRow>
              <FormInputWrapper width="sm-full-width" label="Type" className="pb-2">
                <input type="radio" id="task_type_doc" name="task_type" value="document"
                  {...register("task_type")} defaultChecked={tab === "document"} />
                <label htmlFor="task_type_doc" className="uk-form-label radio">Document</label>
                <input type="radio" id="task_type_task" name="task_type" value="task"
                  {...register("task_type")} defaultChecked={tab === "task"} />
                <label htmlFor="task_type_task" className="uk-form-label radio">Task</label>
              </FormInputWrapper>
            </FormRow>
            <FormRow>
              <FormInputWrapper width="sm-full-width" className="pb-2" label="Active">
                <div className="radio-toolbar">
                  <input id="active_yes" type="radio" name="actived" value={true} {...register("active")} defaultChecked />
                  <label htmlFor="active_yes" className="uk-form-label radio">Yes</label>
                  <input id="active_no" type="radio" name="actived" value={false} {...register("active")} />
                  <label htmlFor="active_no" className="uk-form-label radio">No</label>
                </div>
              </FormInputWrapper>
            </FormRow>
            <div className="form-row">
              <button type="submit" className="uk-button iy-button-primary">
                {isAddMode ? "Add" : "Save"}
              </button>
              <Link to={{ pathname: `${path}/list`, state: pathState }}>
                <button className="uk-button uk-button-default">Cancel</button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DocumentTaskForm;
