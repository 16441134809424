import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { object } from "yup";
import {
  getTransactionEsignById,
  upsertTransaction,
} from "../../../../../redux/actions/transaction";
import { getFigure, getPrice } from "../../../../../utils/currency";
import { getFormattedData, getPlainTransactionData } from "../../../../../utils/stepFilter";
import LinkButton from "../../../../common/buttons/LinkButton";
import SubmitButton from "../../../../common/buttons/SubmitButton";
import Form from "../../../../common/Form";
import FormInputWrapper from "../../../../common/FormInputWapper";
import FormPage from "../../../../common/FormPage";
import FormRow from "../../../../common/FormRow";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import { toast } from "react-toastify";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const schema = object().shape({});

const AddSellerDeposits = ({ transactionId }) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const [oldDeposits, setOldDeposits] = useState([]);

  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const dataDeal = dataTransaction || {};
  const dataDetail = dataDeal.t_details || [];

  const links = [
    { label: "Deals", link: `/deals/view/${transactionId}/main` },
    { label: "Seller Closing Statement" },
  ];

  const { handleSubmit, setValue, getValues, register, control } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  useEffect(() => {
    const deposits = dataDetail.filter((d) => d.type === "deposits" && d.document_type === "seller");
    setOldDeposits(deposits);

    deposits.forEach((deposit, idx) => {
      deposit.date = deposit.date ? moment(deposit.date).format("yyyy-MM-DD") : null;
      deposit.amount = deposit.amount !== null ? getPrice(deposit.amount) : null;
      setValue(`deposit${idx + 1}`, deposit);
    });
  }, [dataTransaction]);

  const handleBlur = (ref) => {
    const price = getPrice(getValues(`deposit${ref}.amount`));
    setValue(`deposit${ref}.amount`, price);
  };

  const handleFocus = (ref) => {
    const figure = getFigure(getValues(`deposit${ref}.amount`));
    setValue(`deposit${ref}.amount`, figure);
  };

  const handleRegistration = (data, isFinishLater = false) => {
    let deposits = [];
    Object.values(data).forEach((val) => {
      let { date, description, amount } = val;
      if (!!date || !!description || !!amount) {
        val.amount = getFigure(val.amount);
        val.date = val.date ? moment(val.date).format() : null;
        val.type = "deposits";
        val.document_type = "seller";
        val = getFormattedData(val);
        deposits.push(val);
      }
    });

    if (oldDeposits.length == 0 && deposits.length == 0) {
      handleNextStep();
    } else {
      dispatch(setLoadingStatus(true));
      dispatch(
        upsertTransaction({
          step: {
            db: "details",
            main: deposits,
            detailType: "deposits",
            documentType: "seller"
          },
          transaction: {
            isBelonged: false,
            main: { ...getPlainTransactionData(dataDeal), task_type: "deal" },
          },
        }, "Deposit's")
      ).then((data) => {
        dispatch(setLoadingStatus(false));
        if (isFinishLater) {
          history.push(`/deals/view/${data.id}/main`);
        } else {
          dispatch(getTransactionEsignById(data.id));
          handleNextStep();
        }
      }).catch((error) => {
        console.log(error);
        dispatch(setLoadingStatus(false));
      });
    }
  };

  const handleNextStep = () => {
    history.push(`/deals/view/${transactionId}/seller-closing-statement/disbursements`);
  }

  const handleError = (error) => {
    console.log(error);
  };

  const vesselName = `${dataTransaction?.t_vessel?.year || ""} ${dataTransaction?.t_vessel?.make || ""} ${dataTransaction?.t_vessel?.model || ""} ${dataTransaction?.t_vessel?.length || ""}`;
  const uniqueTransactionId = dataTransaction?.unique_transaction_id ? `PS-${dataTransaction?.unique_transaction_id}` : '';

  return (
    <FormPage links={links} vesselName={vesselName} uniqueId={uniqueTransactionId}>
      <Form>
        <FormRow>
          <FormInputWrapper label="Deposit Dates">
            <Controller
              control={control}
              name="deposit1.date"
              render={({ field }) => (
                <DatePicker
                  className="uk-input"
                  style={{ cursor: "pointer", color: "#000" }}
                  format={'MM/DD/YYYY'}
                  value={field.value && dayjs(field.value)}
                  onChange={(date, dateString) => {
                    field.onChange(dateString);
                  }}
                />
              )}
            />
          </FormInputWrapper>
          <FormInputWrapper label="Deposit Description">
            <input type="text" className="uk-input" {...register("deposit1.description")} />
          </FormInputWrapper>
          <FormInputWrapper label="Deposit Amount">
            <input
              type="text"
              className="uk-input"
              {...register("deposit1.amount")}
              onBlur={() => handleBlur(1)}
              onFocus={() => handleFocus(1)}
            />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="">
            <Controller
              control={control}
              name="deposit2.date"
              render={({ field }) => (
                <DatePicker
                  className="uk-input"
                  style={{ cursor: "pointer", color: "#000" }}
                  format={'MM/DD/YYYY'}
                  value={field.value && dayjs(field.value)}
                  onChange={(date, dateString) => {
                    field.onChange(dateString);
                  }}
                />
              )}
            />
          </FormInputWrapper>
          <FormInputWrapper label="">
            <input type="text" className="uk-input" {...register("deposit2.description")} />
          </FormInputWrapper>
          <FormInputWrapper label="">
            <input
              type="text"
              className="uk-input"
              {...register("deposit2.amount")}
              onBlur={() => handleBlur(2)}
              onFocus={() => handleFocus(2)}
            />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="">
            <Controller
              control={control}
              name="deposit3.date"
              render={({ field }) => (
                <DatePicker
                  className="uk-input"
                  style={{ cursor: "pointer", color: "#000" }}
                  format={'MM/DD/YYYY'}
                  value={field.value && dayjs(field.value)}
                  onChange={(date, dateString) => {
                    field.onChange(dateString);
                  }}
                />
              )}
            />
          </FormInputWrapper>
          <FormInputWrapper label="">
            <input type="text" className="uk-input" {...register("deposit3.description")} />
          </FormInputWrapper>
          <FormInputWrapper label="">
            <input
              type="text"
              className="uk-input"
              {...register("deposit3.amount")}
              onBlur={() => handleBlur(3)}
              onFocus={() => handleFocus(3)}
            />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="">
            <Controller
              control={control}
              name="deposit4.date"
              render={({ field }) => (
                <DatePicker
                  className="uk-input"
                  style={{ cursor: "pointer", color: "#000" }}
                  format={'MM/DD/YYYY'}
                  value={field.value && dayjs(field.value)}
                  onChange={(date, dateString) => {
                    field.onChange(dateString);
                  }}
                />
              )}
            />
          </FormInputWrapper>
          <FormInputWrapper label="">
            <input type="text" className="uk-input" {...register("deposit4.description")} />
          </FormInputWrapper>
          <FormInputWrapper label="">
            <input
              type="text"
              className="uk-input"
              {...register("deposit4.amount")}
              onBlur={() => handleBlur(4)}
              onFocus={() => handleFocus(4)}
            />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="">
            <Controller
              control={control}
              name="deposit5.date"
              render={({ field }) => (
                <DatePicker
                  className="uk-input"
                  style={{ cursor: "pointer", color: "#000" }}
                  format={'MM/DD/YYYY'}
                  value={field.value && dayjs(field.value)}
                  onChange={(date, dateString) => {
                    field.onChange(dateString);
                  }}
                />
              )}
            />
          </FormInputWrapper>
          <FormInputWrapper label="">
            <input type="text" className="uk-input" {...register("deposit5.description")} />
          </FormInputWrapper>
          <FormInputWrapper label="">
            <input
              type="text"
              className="uk-input"
              {...register("deposit5.amount")}
              onBlur={() => handleBlur(5)}
              onFocus={() => handleFocus(5)}
            />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <button type="button" className='uk-button uk-button-primary' onClick={() => handleRegistration(getValues(), false)}>Save & Continue</button>
          <button type="button" className='uk-button uk-button-default' onClick={() => handleRegistration(getValues(), true)}>
            Save & Finish Later
          </button>
        </FormRow>
      </Form>
    </FormPage>
  );
};

export default AddSellerDeposits;
