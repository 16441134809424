import { 
  COMPLETE_INVITATION,
  PASSWORD_RESET_REQUIRED,
  USER_LOGOUT
} from './types';

import UserService from '../../services/UserService';

export const resetRequired = (data) => async (dispatch) => {
  dispatch({
    type: PASSWORD_RESET_REQUIRED,
    payload: data
  });
}

export const completeInvitation = (data, config) => async (dispatch) => {
  try {
    dispatch({
      type: COMPLETE_INVITATION.pending,
      payload: 'loading'
    });

    const res = await UserService.completeInvitation(data, config);
    
    dispatch({
      type: COMPLETE_INVITATION.success,
      payload: res.data
    });
    
    return res;
  } catch(err) {
    dispatch({
      type: COMPLETE_INVITATION.error,
      payload: err.response
    });

    return err.response;
  }
}

export const resetStore = () => async (dispatch) => {
  dispatch({
    type: USER_LOGOUT,
    payload: 'loading'
  });
}
