import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Helmet } from "react-helmet";
import DocumentTaskForm from "./Form";
import DocumentTaskList from "./List";
import DocumentTaskListTable from "./ListTable";
import DocumentTaskView from "./View";

const DocumentTaskPage = (props) => {
  let { path } = useRouteMatch();
  let prefix = path.includes("iyba") ? "BOATDOX ADMIN" : "Company";

  return (
    <>
      <Helmet>
        <title>{prefix} Document Tasks - BoatDox</title>
      </Helmet>
      <Switch>
        <Route exact path={path} component={DocumentTaskList} />
        <Route path={`${path}/list`} component={DocumentTaskListTable} />
        <Route path={`${path}/add`} component={DocumentTaskForm} />
        <Route path={`${path}/edit`} component={DocumentTaskForm} />
        <Route path={`${path}/view`} component={DocumentTaskView} />
      </Switch>
    </>
  );
};

export default DocumentTaskPage;
