import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Helmet } from "react-helmet";
import ProfileView from './Profile';

const ProfilePage = () => {
  let { path } = useRouteMatch();

  return (
    <>
    <Helmet><title>Profile - BoatDox</title></Helmet>
    <Switch>
      <Route exact path={path} component={ProfileView} />
    </Switch>
    </>
  )
}

export default ProfilePage;
