import { Switch, Route, Redirect, useRouteMatch, useParams } from "react-router-dom";
import EsignExclusion from "./steps/EsignExclusion";
import AddExclusion from "./steps/AddExclusion";
import ExclusionStep from "./stepbars/ExclusionStep";

const ExclusionOption = () => {
  let { path } = useRouteMatch();
  let { id } = useParams();

  return (
    <div>
      <ExclusionStep />
      <Switch>
        <Route exact path={path} render={() => <AddExclusion transactionId={id} />} />
        <Route
          path={`${path}/send_to_signer`}
          render={() => <EsignExclusion transactionId={id} />}
        />
        <Redirect to={path} />
      </Switch>
    </div>
  );
};

export default ExclusionOption;
