export const lightColors = [
  "#dee3e9",
  "#ffa6d2",
  "#d7f9ff",
  "#f8ce80",
  "#efdafc",
  "#fff6c7",
  "#d2f7d4",
  "#40a9ff",
];

export const darkColors = [
  "#959ca4",
  "#ff54a8",
  "#8ae2f4",
  "#f59600",
  "#e5befc",
  "#ffe175",
  "#74dd90",
  "#0063a6",
];
