import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  privacy_container: {
    margin: 'auto',
    padding: '64px 15px'
  },

  text: {
    margin: '1rem 0'
  }
});

const Privacy = (props) => {
  const classes = useStyles();

  return (
    <div className={`uk-container ${classes.privacy_container}`}>
      <h1>Privacy Policy</h1>
      <span>Last updated: July 20, 2022</span>
      <p className={classes.text}>
      BoatDox LLC (“us,” “we,” or “our”), including our affiliates, recognize the importance of protecting the personal information you provide at websites owned or controlled by us.  This Privacy Policy describes in detail the types of information we collect about you; how we use your information and with whom we share it; the choices and rights you have with regard to your information; how we protect your information; and how you can contact us about our privacy practices.
      </p>
      <h3>Scope</h3>
      <p className={classes.text}>This Privacy Notice applies to the use of the Services (as defined below), including by both consumers and Professionals. “Professional(s)” mean individuals or companies acting as yacht brokerage professionals or otherwise engaged in a business related to our Services, including, without limitation, yacht brokers, salespeople, lenders, product or service providers, and other service professionals.  Except as otherwise indicated, Professionals, consumers, and other persons using the Services are individually and collectively referred to as “you.”</p>
      <p className={classes.text}>This Privacy Policy applies to personal information collected by us through our website, mobile app, and other electronic media (such as emails) (collectively, the “Services”).</p>
      <p className={classes.text}>We obtain personal information about you from a variety of sources.  This includes personal information you provide to us directly, information we obtain from other sources, and information we gather through automated means.</p>
      <p className={classes.text}>Information you provide to us.  When you register for or participate in certain Services, seek access to certain content or features, or directly correspond with us, we may collect certain types of information from you:</p>
      <p className={classes.text}>Contact information (such as name, personal and business addresses, email address and telephone and/or mobile numbers).</p>
      <p className={classes.text}>Username and password when you register through our Services.</p>
      <p className={classes.text}>Your social security number or payment information, when needed to facilitate certain transactions.</p>
      <p className={classes.text}>Reviews, comments, or surveys.</p>
      <p className={classes.text}>Other information you may provide to us, such as through our “Submit a Request” or “Contact Us” feature.</p>
      <p className={classes.text}>If you are a Professional, we may also ask for additional information, such as your company affiliations and titles, affiliations with other marine industry organizations, and relevant professional licensing numbers.</p>
      <p className={classes.text}>Information obtained from other sources.  We may obtain personal information about you in connection with the Services from publicly and commercially available sources and from our affiliates or business partners, including:</p>
      <p className={classes.text}>Demographic data (such as gender, age range, educational level, household income range, number of children in household, ethnicity to the extent permitted).</p>
      <p className={classes.text}>Purchasing data, including information about advertisements you have seen or acted upon and information about your interaction with advertisers’ products and services.</p>
      <p className={classes.text}>Occupational data (such as profession, position, title, industry; and business address).</p>
      <p className={classes.text}>If you log in with a third-party account with the Services (e.g., Google), we may obtain information about you, such as your user ID or user name and other information you have permitted those services to share with us consistent with your privacy settings for that third-party account.</p>
      <p className={classes.text}>Information collected by automated means through our Services.  We may gather by automated means (such as cookies, web beacons, web server logs, JavaScript and other similar technologies) certain information through our Services:</p>
      <p className={classes.text}>Your Internet Protocol (IP) address.</p>
      <p className={classes.text}>Device information, including unique identifiers and connection information, including mobile device advertising IDs (e.g., Apple’s IDFA or Google’s AAID) and the means of internet connection (e.g., WiFi connection, ISP) that can identify the physical location of such devices, in accordance with applicable law.</p>
      <p className={classes.text}>Your device type and settings, software used, browser type and operating system.</p>
      <p className={classes.text}>Websites or other services you visited before and after visiting the Services (referring URL).</p>
      <p className={classes.text}>Web pages and advertisements you view and links you click on within, and what search queries you may have run on, the Services (clickstream).</p>
      <p className={classes.text}>Viewing behavior, including the content you view, how long you view content, the quality of the service you receive, and advertisements you have been shown or interacted with.</p>
      <p className={classes.text}>Dates and times you access or use the Services.</p>
      <p className={classes.text}>Location information, including the city, state and zip code associated with your IP Address, information derived through WiFi triangulation, and precise location information from GPS-based functionality on your mobile apps, with your consent.</p>
      <p className={classes.text}>Your phone number and mobile carrier details in connection with our websites and mobile apps.</p>

      <h3>Cookies and Similar Technologies</h3>
      <p className={classes.text}>Cookies are small files that we or others send to and store on or with your computer so that your computer, browser, mobile app, or other application can be recognized as unique the next time you access, visit, use or otherwise take advantage of the Services or other media.  Cookies may also reflect demographic data pertaining to you or other data linked to information you submit.  One use or consequence of cookies is to enable you to receive customized ads, alerts, content, services, or information.  You are always free to decline any cookies we use by adjusting the settings of your browser, as your browser may permit; however, some products, services or features might not be available or operate properly if cookies are not enabled.  Some of our advertisers and third-party service providers may also utilize their own cookies.</p>
      <p className={classes.text}>In addition, we, our service providers, and others sometimes use data-gathering mechanisms on the Services, including without limitation “web beacons”, “clear GIFs”, “pixels” and/or “tags”.  These perform statistical and administrative functions, such as measuring site and page traffic, verifying advertising paths, better understanding user interests and activity, gathering related information (such as information relating to a particular browser, device, or IP address) and positioning images, and typically do so without detracting from your online experience.  Such mechanisms are not necessarily designed to collect personal information.  In addition, if you have provided your email address, we might use a non-human unreadable form (or “hash”) of your email address to deliver, or facilitate delivery of, relevant advertisements and information to you on or by way of the Services or on or by way of other websites or media, including, for example, popular social media sites and features.</p>

      <h3>Use of Information</h3>
      <p className={classes.text}>We, or service providers acting on our behalf, may use the information collected from and about you to:</p>
      <p className={classes.text}>Improve and customize the content and layout of our website and other communications tools.</p>
      <p className={classes.text}>Notify you of updates to our sites; relevant products and services; and upcoming events and programs, as well as provide technical support.</p>
      <p className={classes.text}>Compile specialty directories about which you will be made aware and generate suggestions about the type of content you may enjoy.</p>
      <p className={classes.text}>Create and manage online accounts and track usage of our sites.</p>
      <p className={classes.text}>Assist brokerage and charter associations and affiliated Institutes, Societies, and Councils in membership tracking and for their use for purposes similar to those listed above.</p>
      <p className={classes.text}>Provide our products and services, including authorizing a purchase, or completing a transaction that you requested.</p>
      <p className={classes.text}>Send promotional materials, alerts regarding available offers and other communications, including text/SMS messages if you provided your mobile number and opted in to receive such messages (for information on our text messaging program, please see our Text/SMS Messaging Program Terms).</p>
      <p className={classes.text}>Communicate about, and administer participation in, special events, promotions, programs, offers, surveys, contests and market research.</p>
      <p className={classes.text}>Respond to inquiries from you and other third-parties, including inquiries from law enforcement agencies.</p>
      <p className={classes.text}>Anonymize or de-identify personal information to provide third parties with aggregated data reports showing anonymized information and other non-personal information.</p>
      <p className={classes.text}>Supplement your personal information collected directly from you or from automated means with additional information from publicly and commercially available sources, or information from our affiliates and our business partners.</p>
      <p className={classes.text}>Associate your browser or device with other browsers or devices you use for the purpose of providing relevant and easier access to content, advertising across browsers and devices, and other operational/business purposes.</p>
      <p className={classes.text}>Operate, evaluate, and improve our business (including developing, enhancing, analyzing and improving our Services; managing our communications; reviewing and processing employment applications, performing data and statistical analytics; and performing accounting, auditing and other internal functions).</p>
      <p className={classes.text}>Protect against, identify and prevent fraud and other unlawful activity, claims and other liabilities.</p>
      <p className={classes.text}>Comply with and enforce applicable legal requirements, relevant industry standards, contractual obligations, and our terms of service and other policies.</p>
      <p className={classes.text}>And in other ways for which we provide specific notice at the time of collection.</p>
      
      <h3>Information we Share</h3>
      <p className={classes.text}>We may share your personal information with:</p>
      <p className={classes.text}>Our affiliated businesses, such as IYBA, may use your personal information consistent with this Privacy Policy. Those businesses may also use your personal information for their own purposes, including marketing purposes.</p>
      <p className={classes.text}>Our Service Providers – third parties who perform services on our behalf.</p>
      <p className={classes.text}>Our third-party clients or business partners, such as other Professionals to facilitate your transactions, and other third parties for their own commercial or marketing purposes, such as to serve targeted advertisements.</p>
      <p className={classes.text}>Law enforcement authorities or other government officials if we are required to do so to comply with subpoenas, court orders, legal process or other law enforcement or other government measures, and to comply with other legal obligations. We may also disclose information if we believe disclosure is necessary or appropriate in connection with an investigation of suspected or actual fraudulent or illegal activity.</p>
      <p className={classes.text}>Select third-party partners with your consent or at your direction so they can send you promotions, ads or offers about their services or products that you may be interested in. We also may give you the option to receive communications from one of our advertising partners, such as when we offer a joint promotion with that third-party advertiser. If you choose to opt in to receiving communications from that third-party advertiser by providing your explicit consent, the third-party advertiser will manage your personal information pursuant to their own privacy policy.</p>
      <p className={classes.text}>Third parties at our discretion, when the information collected from or about you is not defined as personal information under applicable law, we may share such non-personal, de-identified information or aggregated information.</p>
      <p className={classes.text}>Also, we may share your personal information:</p>
      <p className={classes.text}>To protect and defend the rights, interests and safety of the Services, our subsidiaries and affiliates, and their employees, contractors and agents; to protect the security and safety of our users of the Services, including when we believe disclosure is necessary or appropriate to prevent physical harm or financial loss.</p>
      <p className={classes.text}>In the event of a proposed or completed sale or transfer of all or a portion of our business or assets (including in the event of a reorganization, dissolution or liquidation).</p>
      
      <h3>Information We Do Not Share</h3>
      <p className={classes.text}>Email contact information.  We do not share, sell, or trade email addresses, except that state and local association staff and leadership email addresses may be listed in the membership directories and listing details available on our website.  We may use your email address to directly send you information and may provide you with online informational or marketing messages that have been approved by us together with other communications to which you have subscribed.  </p>
      <p className={classes.text}>Credit information that you and credit authorizers provide when you make payments by credit card or electronic check for products or other services via our payment gateway will only be used to process the transactions you request. This information will be provided to and maintained by reputable credit reporting databases, but will never be sold, shared, or provided to other third parties.</p>

      <h3>How we Protect Personal Information</h3>
      <p className={classes.text}>We follow generally accepted standards to protect the information we collect and make available via our website.  We test our security procedures regularly and modify them as new technologies become feasible.  We maintain administrative, technical and physical safeguards designed to protect the personal information we have about you against accidental, unlawful or unauthorized destruction, loss, alteration, access, disclosure or use.  However, no data transmission or storage, or use of the Internet, can be guaranteed to be 100% secure.</p>
      <p className={classes.text}>Some browsers have a “Do Not Track” feature that allows you to communicate to websites that you do not want to have your online activities tracked.  Our system does not respond to Do Not Track requests or headers from some or all browsers at this time.</p>
      
      <h3>Advertising</h3>
      <p className={classes.text}>Our website may contain advertising placed by advertising networks pursuant to certain agreements.  We do not control these advertising networks, the sites of third parties reached through links on our site, or their information collection practices, and we are not responsible for the activities of these third parties.  The advertising networks may use cookies to collect certain non-personally identifiable information when you click on the banner ads appearing on our sites.  This information may be collected by the advertising networks for purposes of measuring and reporting on the advertising.  The advertising networks may also aggregate the information for certain other statistical and reporting purposes.</p>

      <h3>Your Choices</h3>
      <p className={classes.text}>You can choose to use Services without providing any information about yourself to us directly, but some information may be collected automatically or be requested by us so that you can take advantage of certain features on our Services.</p>
      <p className={classes.text}>We utilize a strict Opt-Out policy for sending online notifications regarding services, products, and programs.  To ask us to remove your information from our marketing mailing lists, please contact us as outlined in the How to Contact Us section below.  You may adjust your Communication Preferences by reviewing your subscription emails and clicking unsubscribe to change your preferences.  Even if you unsubscribe from our marketing communications, we may still need to send you communications relating to your use of the Services, such as service announcements where you are using our Service.</p>
      <p className={classes.text}>You also have choices about whether cookies and other similar technologies are placed on your computer or mobile device.  For more information, see our section on Cookies and Other Technologies above.</p>
      <p className={classes.text}>We may use third-party analytics services on our Services, such as Google Analytics.  The analytics providers that administer these services use technologies such as cookies, web beacons and similar technologies to help us analyze your use of the Services.  You can learn more about this technology online.</p>
      <p className={classes.text}>You may also choose to opt out of use of data that we or our third-party service providers collect about your activities to provide you with tailored advertising about products and services of interest to you.  We and our third-party service providers also may obtain information for this purpose from third-party websites or apps.  To learn more about how to opt out of ad network interest-based advertising, please see the section on Cookies and Other Technologies section above.</p>

      <h3>Accessing, Correcting, and Deleting your Personal Information</h3>
      <p className={classes.text}>Consumers and Professionals may access, review, update, or correct inaccuracies in your personal information in our custody and control as described below.  Please note that any change you request regarding personal information may affect or otherwise change the availability, deliverability, or quality for you of information, features, products or services in which you are interested or your experience on or relating to the Services.</p>
      <p className={classes.text}>If you would like us to no longer use information about you or share it with our affiliates, partners, or other service providers, for their marketing purposes, please send a request to <a href="mailto:privacy@boatdox.org">privacy@boatdox.org</a>.</p>

      <h3>Transfers of Information</h3>
      <p className={classes.text}>The Services are hosted in and managed from the United States.  We may transfer the information we collect about you to another country, which may not be deemed to have the same data protection laws as the country in which you initially provided the information.  Applicable laws in the United States or other countries may allow the courts, law enforcement and national security authorities to access your personal information when in such country.</p>
      <p className={classes.text}>If you are a user located outside the United States or the country to which your information may be transferred, you understand (and, as permitted under applicable law, consent to) having any personal information processed in the United States or such country.  When we transfer your information, we will protect that information as described in this Privacy Policy.</p>

      <h3>Links to Third-Party Sites, Apps and Services</h3>
      <p className={classes.text}>For your convenience and information, our Services may provide links to third-party sites, apps and services that may be operated by companies not affiliated with us.  These companies may have their own privacy notices or policies, which we strongly suggest you review.  We are not responsible for the privacy practices of any third-party sites, apps, or services.</p>

      <h3>Changes to our Privacy Policy</h3>
      <p className={classes.text}>From time to time, we may change our Privacy Policy to reflect changes to our privacy practices and Services.  If we modify the Privacy Policy, we will update the “Last Modified Date” at the top of the page and the changes will be effective as of the listed date.  For material changes, we will notify you by posting a prominent notice on our Services indicating at the top of the Privacy Policy when it was most recently updated or by other appropriate means, such as email through the email address you most recently provided to us.</p>
      
      <h3>How to Contact us</h3>
      <p className={classes.text}>If you have any questions or comments about this Privacy Policy or the manner in which we or our service providers treat your personal information, would like to exercise your choices, or would like us to update information we have about you or your preferences, please contact us at 
      <a href='mailto:privacy@boatdox.org'> privacy@boatdox.org</a></p>

      <h3>CALIFORNIA PRIVACY RIGHTS</h3>
      <p className={classes.text}>The California Consumer Privacy Act of 2018 (“CCPA”) provides certain rights to residents of California.  This section of the Privacy Policy applies if you are a natural person who is a resident of California (“California Consumer”) and use our Services.  This notice supplements the information in the above Privacy Policy.  Certain terms used below have the meanings given to them in the CCPA.</p>
      <p className={classes.text}>The following sets forth the categories of information we collect and purposes for which we may use California Consumers’ personal information collected by us:</p>

      <h3>Categories of Personal Information We Collect About You</h3>
      <p className={classes.text}>The categories of personal information we may have collected about you or your use of our Services are:</p>
      <p className={classes.text}>Identifiers (such as your contact information (name, email address), government identifiers (social security numbers and MLS ID# or similar professional licensing numbers) or unique personal identifiers (device ID, cookies, IP address).</p>
      <p className={classes.text}>Commercial information (such as transaction data or purchase history).</p>
      <p className={classes.text}>Financial information (such as credit card information).</p>
      <p className={classes.text}>Internet or other network or device activity (such as browsing history or app usage).</p>
      <p className={classes.text}>Geo-location information.</p>
      <p className={classes.text}>Professional or employment related information.</p>
      <p className={classes.text}>Education information.</p>
      <p className={classes.text}>Profile data about you and any inferences drawn from the profile data (such as characteristics, behaviors, attitudes).</p>
      <p className={classes.text}>Information that identifies or can be reasonably associated with you.</p>

      <h3>Categories of Sources from Which We Collected Personal Information</h3>
      <p className={classes.text}>The sources from which we may have collected personal information about you or your use of the Services are:</p>
      <p className={classes.text}>Directly from you.</p>
      <p className={classes.text}>From other sources (such as Company affiliates and business partners).</p>
      <p className={classes.text}>Through your use of the Services.</p>
      <p className={classes.text}>Publicly available records.</p>

      <h3>Business or Commercial Purposes for Which We Collected or Sold the Personal Information</h3>
      <p className={classes.text}>The business or commercial purposes, as defined under the CCPA, for which we collected or sold the personal information are:</p>
      <p className={classes.text}>Performing services, including maintaining or servicing accounts, providing customer service, processing, or fulfilling orders and transactions, verifying customer information, processing payments, providing advertising or marketing services, providing analytics services, or providing similar services.</p>
      <p className={classes.text}>Auditing related to a current interaction with you and concurrent transactions, including, but not limited to, counting ad impressions to unique visitors, verifying positioning and quality of ad impressions, and auditing compliance.</p>
      <p className={classes.text}>Short-term, transient use, including, but not limited to, the contextual customization of ads shown as part of the same interaction.</p>
      <p className={classes.text}>Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity, and prosecuting those responsible for that activity.</p>
      <p className={classes.text}>Debugging to identify and repair errors that impair existing intended functionality.</p>
      <p className={classes.text}>Undertaking internal research for technological development and demonstration.</p>
      <p className={classes.text}>Undertaking activities to verify or maintain the quality or safety of a service or device that is owned, manufactured, manufactured for, or controlled by us, and to improve, upgrade, or enhance the service or device that is owned, manufactured, manufactured for, or controlled by us.</p>
      <p className={classes.text}>We may disclose the categories of personal information identified in this California Privacy Notice about our California consumers for our operational purposes where the use of such personal information is reasonably necessary and proportionate to achieve the operational purpose for which the personal information was collected or processed or for another operational purpose that is compatible with the context in which the personal information was collected.</p>

      <h3>Categories of Third Parties with Whom We Shared the Personal Information</h3>
      <p className={classes.text}>The categories of third parties, as defined by the CCPA, with whom we share personal information include our subsidiaries and affiliates; business partners, data analytics provers and third-party marketers (who may combine your personal information with their own records and records available from other sources), for their own business or direct marketing purposes, and the direct marketing purposes of other third-party marketers.</p>

      <h3>Your California Privacy Rights</h3>
      <p className={classes.text}>If you are a California resident, you may have certain rights. California law may permit you to request that we:</p>
      <p className={classes.text}>Provide you the categories of personal information we have collected or disclosed about you in the last twelve months; the categories of sources of such information; the business or commercial purpose for collecting or selling your personal information; and the categories of third parties with whom we shared personal information.</p>
      <p className={classes.text}>Provide access to or a copy of certain information we hold about you.</p>
      <p className={classes.text}>Delete certain information we have about you.</p>
      <p className={classes.text}>You may have the right to receive information about the financial incentives that we offer to you (if any).  You also have the right to not be discriminated against (as provided for in applicable law) for exercising certain of your rights.  Certain information may be exempt from such requests under applicable law.  For example, we need certain types of information so that we can provide the Services to you and for compliance with applicable law.  If you ask us to delete certain information, you may no longer be able to access or use the Services.</p>
      <p className={classes.text}>California customers may request, once per year, that we disclose the identity of any third parties with whom we have shared personal information for the third-parties’ direct marketing purposes within the previous calendar year, along with the type of personal information disclosed.</p>
      <p className={classes.text}>You will be required to verify your identify before we fulfill your request.  You can also designate an authorized agent to make a request on your behalf.  To do so, you must provide us with written authorization or a power of attorney, signed by you, for the agent to act on your behalf.  You will still need to verify your identity directly with us.</p>
      <p className={classes.text}>You also have the right to opt out of the sale of your personal information.  California law broadly defines sale such that it may include allowing third parties to receive certain information, such as cookies, IP address or browsing behavior, to deliver targeted advertising.</p>

      <h3>INFORMATION FOR VISITORS FROM CANADA</h3>
      <p className={classes.text}>The Canadian Personal Information Protection and Electronic Documents Act (PIPEDA) provides certain rights to Canadian residents.  Under Canadian law, you have a right to access your personal information held by us, subject to applicable exceptions.  You may contact us or write to us at the address below to ask us to access or correct your information.  We may require you to send us proof of your identity before providing you with access to your personal information.  If you send us a request to access or correct information, then we will contact you within 30 days (although it may take us longer to fully respond to your request).</p>
      <p className={classes.text}>Our Privacy Policy clearly states how we collect, use, and share your personal information.  We may share information with third parties, such as subsidiaries, affiliates, business partners and authorized third parties.  However, we will only share your information with such parties where we have your explicit consent to do so or, in some cases, where we may infer consent from your actions or behavior.  Please contact us at any time to let us know (1) if you do not wish to receive marketing communications (such as emails) from us or our third-party partners, or you otherwise object to our sharing your information with those partners, (2) if you have any questions, or complaints, regarding the collection or use of your personal information or the content of this notice, or (3) if you wish to withdraw your consent to us using your data.</p>
      <p className={classes.text}>If you are not satisfied with our response to your query or complaint, you may also contact the Office of the Information and Privacy Commissioner of Canada.</p>
    </div>
  );
};

export default Privacy;
