import {
  CREATE_CLIENT,
  RETRIEVE_CLIENTS,
  GET_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  DELETE_ALL_CLIENTS,
  FIND_CLIENT_BY_NAME,
  FIND_ONE_BY_NAME,
  CLEAR_CLIENT,
} from "./types";

import ClientService from "../../services/ClientService";

export const createClient = (client) => async (dispatch) => {
  try {
    const res = await ClientService.create(client);

    dispatch({
      type: CREATE_CLIENT,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const retrieveClients = () => async (dispatch) => {
  try {
    const res = await ClientService.getAll();

    dispatch({
      type: RETRIEVE_CLIENTS,
      payload: res.data,
    });
  } catch (err) {
    console.log("Error = ", err);
  }
};

export const updateClient = (id, data) => async (dispatch) => {
  try {
    const res = await ClientService.update(id, data);

    dispatch({
      type: UPDATE_CLIENT,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getClient = (id) => async (dispatch) => {
  try {
    const res = await ClientService.get(id);
    dispatch({
      type: GET_CLIENT,
      payload: res.data,
    });
  } catch (err) {
    console.log("Error = ", err);
  }
};

// get client detail for transactions
export const getTransactionClient = (id) => async (dispatch) => {
  try {
    const res = await ClientService.getClient(id);
    dispatch({
      type: GET_CLIENT,
      payload: res.data,
    });
  } catch (err) {
    console.log("Error = ", err);
  }
};

export const deleteClient = (id) => async (dispatch) => {
  try {
    const res = await ClientService.remove(id);

    dispatch({
      type: DELETE_CLIENT,
      payload: { id },
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllClients = () => async (dispatch) => {
  try {
    const res = await ClientService.removeAll();

    dispatch({
      type: DELETE_ALL_CLIENTS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findClientByName = () => async (dispatch) => {
  try {
    const res = await ClientService.getCRMClientList();

    dispatch({
      type: FIND_CLIENT_BY_NAME,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const findOneByName = (name) => async (dispatch) => {
  try {
    const res = await ClientService.findOneByName(name);

    dispatch({
      type: FIND_ONE_BY_NAME,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const clearClient = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CLIENT,
  });
};
