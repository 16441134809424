import http, { setHeaders } from '../http-common';
import Auth from '../utils/auth';

setHeaders(Auth.getInstance().getAuthToken());

const getAll = () => {
  return http.get("/brokerage");
}

const get = company_id => {
  return http.get(`/brokerage/${company_id}`);
}

const upsert = (company_id, data) => {
  return http.post(`/brokerage/${company_id}`, data);
}

const update = (id, data) => {
  return http.put(`/brokerage/${id}`, data);
}

const getBrokerProfile = (id) => {
  return http.get(`/brokerage/profile/${id}`);
}

const BrokerageService = {
  getAll,
  getBrokerProfile,
  get,
  upsert,
  update,
}

export default BrokerageService;
