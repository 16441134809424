import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import ModalPortal from "./UploadModalPortal";
import { getChecklist } from "../../../../../redux/actions/document_tasks";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import {
  insertTaskToTransaction,
  updateTaskForTransaction, s, getTransactionEdocById, getEdocCompanyTaskList, addRecords
} from "../../../../../redux/actions/transaction";
import 'bootstrap/dist/css/bootstrap.min.css';
import { fileUploadWithServer, uploadDocument } from "../../../../../utils/s3";
import { color } from "@mui/system";
import Auth from "../../../../../utils/auth";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import { Modal } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from 'yup';

const UploadDocument = ({ transactionId, sortOrder, predocumentType, companyId, show, onClose, uniqueId, companyUId }) => {

  const [documentName, setDocumentName] = useState("");
  const logged_user = Auth.getInstance().getUserData();
  const [error, setErrorMessage] = useState(null);

  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();

  const { id } = useParams();
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    reset();
    setFiles([]);
  }, [show]);


  useEffect(() => {
    setValue('title', predocumentType?.company_tasks?.title ? predocumentType.company_tasks?.title : '');
  }, [predocumentType]);

  const schema = yup.object().shape({
    title: yup
      .string()
      .required("Document Name is required.")
  });

  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: {
      title: ''
    },
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true
  });

  const handleChange = (e) => {
    for (let index = 0; index < e.target.files.length; index++) {
      setFiles(files =>
        [
          ...files,
          e.target.files[index]
        ]
      )
    }
  };

  // // Temporary for Migration
  // const runMigration = (id, companyId) => {
  //   dispatch(addRecords(id, companyId)).then(response => {
  //     console.log(response)
  //   }).catch(error => {
  //     console.error(error);
  //   })
  // }


  const uploadFile = async (file) => {
    try {
      const filename = await uploadDocument(file, 'uploads', transactionId, uniqueId, companyUId);
      return {
        ...file,
        file_url: filename,
        original_file_name: file.name,
        transaction_task_master_id: predocumentType?.master_task_id || null,
        uploaded_by: logged_user.id
      };
    } catch (error) {
      console.error(error);
      toast.error(error.message);
      dispatch(setLoadingStatus(false));
      return file;
    }
  }

  const handleUpload = async (formData) => {
    dispatch(setLoadingStatus(true));

    try {
      let filesToUpload = [];
      let serverUploadFiles = [];
      for (const index in files) {
        if (Object.hasOwnProperty.call(files, index)) {
          const element = files[index];
          const documentFile = await uploadFile(element);
          filesToUpload[index] = documentFile;
          serverUploadFiles.push(new File([element], documentFile.file_url, {
            type: element.type,
            lastModified: element.lastModified,
          }));
        }
      }

      if (predocumentType == null) {
        dispatch(getChecklist())
          .then((res) => {
            const checklist = res;
            const checklist_id = checklist.filter((c) => c.short_name === "listing")[0].id;
            const data = {
              title: formData.title,
              task_type: "document",
              actionBy: logged_user.id,
              checklist_id: checklist_id,
              transaction_id: transactionId,
              documents: filesToUpload,
              created_by_user_id: userId,
              sort_order: sortOrder,
              status: "received",
              completed_at: new Date(),
              completed_by_user_id: userId,
              active: true,
              company_id: companyId,
              create_type: 'custom'
            };
            dispatch(insertTaskToTransaction(data))
              .then((res) => {
                toast.success('Document added successfully.');
                dispatch(getTransactionEdocById(id));
              })
          })
          .catch((err) => {
            console.log(err);
          });
        handleCloseModal();
        dispatch(setLoadingStatus(false));
        return;
      }

      let data = predocumentType;
      data.status = "received";
      data.documents = filesToUpload;
      data.actionBy = logged_user.id;
      data.completed_by_user_id = userId;
      data.completed_at = new Date();
      data.transaction_id = transactionId;
      dispatch(updateTaskForTransaction(data))
        .then((res) => {
          toast.success('Document updated successfully.');
          dispatch(setLoadingStatus(false));
          dispatch(getTransactionEdocById(id));
          dispatch(getEdocCompanyTaskList(companyId, id, predocumentType?.sortOrder ? { sortOrder: predocumentType?.sortOrder } : {}));
        }).catch((err) => {
          console.log(err);
          dispatch(setLoadingStatus(false));
        });
      dispatch(getTransactionEdocById(id));
    } catch (error) {
      console.error(error);
    }
    handleCloseModal();
  };

  const openPreview = async (file) => {
    const url = URL.createObjectURL(file);
    window.open(url, "_blank");
  }

  const handleRemoveDocument = (file) => {
    setFiles(files.filter(doc => doc.name != file.name));
  }

  const handleCloseModal = () => {
    setFiles([]);
    reset();
    onClose();
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered={true}
      size="lg">
      <Modal.Header closeButton>
        <h3>Upload Documents</h3>
      </Modal.Header>
      <Modal.Body>
        <form style={{ padding: '10px' }}>
          <div className="form-row">
            <div className="two-column uk-padding-small">
              <label className="uk-form-label">Document Name</label>
              <input
                className={clsx("uk-input", { 'uk-form-danger': errors.title })}
                type="text"
                {...register("title")}
                disabled={predocumentType}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="two-column uk-padding-small">
              <div className="js-upload" data-uk-form-custom="true">
                <input type="file" id="upload-file" onChange={handleChange} multiple accept=".pdf, .doc, .docx, .xls, .xlsx, .png, .jpg, .jpeg" />
                <button className="uk-button uk-button-default">Upload Documents</button>
                {
                  error && <span style={{ color: "red" }}>{error}</span>
                }
              </div>
            </div>
          </div>
          <div className="form-row file-format-size">File formats supported: pdf, doc, docx, xls, xlsx, png, jpg. Maximum file size: 10 MB.</div>
          {files.length > 0 &&
            <>
              {files.map((file, index) => (
                <div className="form-row mt-2" key={`${file.name}_${index}`}>
                  <a style={{ marginLeft: "11px" }} onClick={() => openPreview(file)}>
                    <img src="/icons/view.svg" className="svg" data-uk-tooltip="Preview Document" />
                  </a>
                  <span>{file.name}</span>
                  <a data-uk-tooltip="remove-document" style={{ marginLeft: '5px' }} onClick={() => handleRemoveDocument(file)}>
                    <img src="https://cdn.hellosign.com/1.116.0/build/ae9ae8331a142bc9ce60901d10a41fc6.svg" />
                  </a>
                </div>
              ))}
            </>
          }
        </form>
      </Modal.Body>
      <Modal.Footer>
        {/* <button
          className="uk-button uk-button-info small"
          type="button" onClick={() => runMigration(id, companyId)}>Migrate</button> */}
        <button className="uk-button uk-button-default uk-modal-close small" type="button" onClick={(e) => handleCloseModal()}>
          Cancel
        </button>
        <button
          className="uk-button uk-button-primary small"
          type="button" onClick={handleSubmit(handleUpload)}>Done</button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadDocument;
