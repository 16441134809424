import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setLoadingStatus } from '../../redux/actions/loading';
import { addVesselImage } from '../../redux/actions/transaction';
import { fileUploadWithServer, uploadDocument } from '../../utils/s3';
import { toast } from 'react-toastify';

const UploadVesselImage = ({ show, onClose, transaction, imgUrl = "", uniqueId, companyUId }) => {

    const [image, setImage] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setImage(null);
        setSubmitted(false);
    }, [show])


    const handleChange = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    }

    const handleSubmit = async () => {
        setSubmitted(true);
        if (image) {
            dispatch(setLoadingStatus(true));
            try {
                const filename = await uploadDocument(image, 'vessel-image', transaction.id, uniqueId, companyUId);
                if (filename) {
                    dispatch(addVesselImage(transaction.id, { imageUrl: filename })).then(
                        response => {
                            dispatch(setLoadingStatus(false));
                            toast.success('Image uploaded successfully!');
                            onClose(true);
                        }
                    ).catch(error => {
                        toast.error('Could not upload image!');
                        dispatch(setLoadingStatus(false));
                    })
                }
            } catch (error) {
                dispatch(setLoadingStatus(false));
                console.error(error)
            }
        } else {
            return;
        }
    }

    return (
        <Modal
            show={show}
            onHide={onClose}
            centered={true}
            size="lg">
            <Modal.Header closeButton>
                <h3>Upload Vessel Image</h3>
            </Modal.Header>
            <Modal.Body>
                <form style={{ padding: '10px' }}>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="uk-padding-small">
                                <div className={`custom-file ${image ? 'mt-4' : ''}`}>
                                    <input type="file" className={`custom-file-input form-control${(!image && submitted) ? 'uk-form-danger' : ''}`} id="image" name="image"
                                        accept="image/x-png,image/jpeg"
                                        onChange={handleChange}
                                        lang="es" />
                                    <label className="custom-file-label imageLabel" htmlFor="customFileLang">{`Select Vessel Image ${image?.name ? `: ${image?.name}` : ""}`}</label>
                                    {
                                        (!image && submitted) &&
                                        <label class="custom-file-label imageLabel"><font color="red">Please select image</font></label>
                                    }
                                </div>
                            </div>
                            <div className="file-format-size">Image file formats supported: png, jpg, jpeg.</div>
                        </div>
                        <div className="col-md-4">
                            <div className='d-flex align-items-center'>
                                {image ? <img src={URL.createObjectURL(image)} alt="Vessel Img" height={'150px'} width={'150px'} /> : imgUrl ? <img src={`https://${process.env.REACT_APP_UPLOADS_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}vessel-image/${imgUrl}`} alt="Vessel Img" height={'150px'} width={'150px'} /> : <></>}
                            </div>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className="uk-button uk-button-default uk-modal-close small" type="button" onClick={() => onClose(false)}>
                    Cancel
                </button>
                <button
                    className="uk-button uk-button-primary small"
                    type="button" onClick={handleSubmit}>Done</button>
            </Modal.Footer>
        </Modal>
    )
}

export default UploadVesselImage;