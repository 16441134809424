import { useEffect, useCallback } from "react";
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import { getDealsByYTD } from "../../../redux/actions/dashboard";
import { getPrice } from "../../../utils/currency";
import { setDateFilter, THIS_YEAR } from '../../../redux/actions/report';

const DealsByYTD = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const stats = useSelector((state) => state.dashboard.dealsByClosedYearToDate) || {};

  useEffect(() => {
    dispatch(getDealsByYTD());
  }, []);

  const handleClickViewAll = useCallback(() => {
    dispatch(setDateFilter(THIS_YEAR));
    history.push('/reports/deals');
  }, []);

  return (
    <>
      <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
        <thead>
          <tr>
            <th rowSpan="2">Number of Deals</th>
            <th colSpan="2" className="value-commision">
              value
            </th>
            <th colSpan="2" className="value-commision">
              Commission
            </th>
          </tr>
          <tr>
            <th className="in-house">Contract Value</th>
            <th>Sale Value</th>
            <th>In-House</th>
            <th>Co-Brokerage</th>
          </tr>
        </thead>
        <tbody>
          {stats.ytd
            ?.slice(0, 3)
            .map((stat, idx) => (
              <tr key={idx}>
                <td>{stat?.count}</td>
                <td>{getPrice(stat?.contract_value)}</td>
                <td>{getPrice(stat?.sale_value)}</td>
                <td>{getPrice(stat?.in_house)}</td>
                <td>{getPrice(stat?.co_brokerage)}</td>
              </tr>
            ))}
          <tr className="total-row">
            <td>Total</td>
            <td>{getPrice(stats?.total?.contract_value)}</td>
            <td>{getPrice(stats?.total?.sale_value)}</td>
            <td>{getPrice(stats?.total?.in_house)}</td>
            <td>{getPrice(stats?.total?.co_brokerage)}</td>
          </tr>
        </tbody>
      </table>
      {stats?.ytd?.length !== 0 && (
        <div className="view-all">
          <a href="#" onClick={handleClickViewAll}>View All</a>
        </div>
      )}
    </>
  );
};

export default DealsByYTD;
