import FormRow from "../../../../common/FormRow";

const DetailOfExclusions = ({ exclusions }) => {
  exclusions = exclusions || [];

  return (
    <FormRow>
      <ul>
        {exclusions?.length ? (
          exclusions.map((exclusion, idx) => {
            return (
              <li key={exclusion.id} style={{ listStyle: "none" }}>
                <span className="uk-form-label" style={{ display: "inline-block" }}>
                  Exclusion {idx + 1}: &nbsp;
                </span>
                <span className="input-view">
                  {exclusion.exclusion ? exclusion.exclusion || "N/A" : "N/A"}
                </span>
              </li>
            );
          })
        ) : (
          <li className="uk-form-label mt20">No exclusions</li>
        )}
      </ul>
    </FormRow>
  );
};

export default DetailOfExclusions;
