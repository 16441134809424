import { GET_ESIGN_URL, GET_SIGNATURE_DATA, SIGN_DOCUMENT, REJECT_DOCUMENT } from "./types";

import ESignService from "../../services/ESignService";

export const getESignURL = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ESIGN_URL.pending,
      payload: 'loading',
    });

    const res = await ESignService.getESignURL(id);

    dispatch({
      type: GET_ESIGN_URL.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: GET_ESIGN_URL.error,
      payload: err,
    });

    return err;
  }
};

export const getSignatureData = (id, signer) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SIGNATURE_DATA.pending,
      payload: 'loading',
    });

    const res = await ESignService.getSignatureData(id, signer);

    dispatch({
      type: GET_SIGNATURE_DATA.success,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: GET_SIGNATURE_DATA.error,
      payload: err
    });

    return Promise.reject(err);
  }
};

export const signDocument = (id, signer, data) => async (dispatch) => {
  try {
    dispatch({
      type: SIGN_DOCUMENT.pending,
      payload: 'loading'
    });

    const res = await ESignService.signDocument(id, signer, data);

    dispatch({
      type: SIGN_DOCUMENT.success,
      payload: res.data
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: SIGN_DOCUMENT.error,
      payload: err
    });
    return Promise.reject(err);
  }
}

export const resetWithCounterOffer = (id, data) => async (dispatch) => {
  try {
    const res = await ESignService.resetWithCounterOffer(id, data);
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

export const rejectDocument = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: REJECT_DOCUMENT.pending,
      payload: 'loading'
    });

    const res = await ESignService.rejectDocument(id, data);

    dispatch({
      type: REJECT_DOCUMENT.success,
      payload: res.data
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: REJECT_DOCUMENT.error,
      payload: err
    });

    return Promise.reject(err);
  }
}