const types = `Aft Cabin
Airboat
Aluminum Fish Boats
Barge
Bass Boats
Bay Boats
Bowrider
Center Consoles
Classic Expedition Motorsailer
Commercial Boats
Convertible Boats
Cruise Ship
Cruisers
Cuddy Cabin
Daysailers
Deck Boats
Downeast
Dual Console
Expedition Yacht
Express Cruiser
Flats Boats
Flybridge
House Boats
Jet Boats
Ketch
Lobster Boat
Long Range Cruiser
Motor Yachts
Other Power
Passenger
Pontoon Boats
Power - Antique and Classics
Power Catamaran
Power-Pilothouse
Racing Sailboat
Racing-High Performance
Rigid Inflatable Boats
Runabouts
Sail - Antique and Classics
Sail - Cutter
Sail - Deck Saloon
Sail - Motorsailers
Sail - Other
Sail Beach Catamarans
Sail Catamaran
Sail Center Cockpit
Sail Commercial Boats
Sail Cruiser-Racer
Sail Cruisers
Sail Multi-Hulls
Sail Trimaran
Sail-Pilothouse
Saltwater Fishing
Schooner
Ski and Fish
Ski-Wakeboard Boats
Skiff
Skylounge
Sloop
Solar Power
Sport Fishing
Sports Cruiser
Submarine
Superyacht
Tender
Trawlers
Trideck
Tug
Walkarounds
Yawl
`;

export default types;