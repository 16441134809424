import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import clsx from "clsx";
import PageSidebarContainer from "../../../../common/PageSidebarContainer";
import { updateOptionsRequiredFields } from "../../../../../redux/actions/transaction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BuyerDetailStep = () => {
  let location = useLocation();
  let { id } = useParams();
  const dispatch = useDispatch();
  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const [asterix, setAsterix] = useState({});

  const history = useHistory();

  const dataDeal = dataTransaction || {};
  const dataBuyerClosing = dataDeal.t_buyer_closing;
  const dataDetails = dataDeal.t_details || [];

  useEffect(() => {
    if (dataBuyerClosing) {
      const { sales_price_total, sub_total, balance_due, sales_tax_vessel, is_sales_tax } = dataBuyerClosing;
      if (is_sales_tax) {
        if (sales_price_total && sub_total && sales_tax_vessel) {
          setAsterix("fulfill");
        } else if (sales_price_total || sub_total || sales_tax_vessel) {
          setAsterix("notFulfill");
        } else {
          setAsterix("empty");
        }
      } else {
        if (sales_price_total && sub_total) {
          setAsterix("fulfill");
        } else if (sales_price_total || sub_total) {
          setAsterix("notFulfill");
        } else {
          setAsterix("empty");
        }
      }
    } else {
      setAsterix("empty");
    }
    registerRequiredFields(dataBuyerClosing);
  }, [dataTransaction]);

  const registerRequiredFields = (detail) => {
    let fields = [];
    if (detail) {
      const { sales_price_total, sub_total, sales_tax_vessel, balance_due } = detail;
      if (!sales_price_total) {
        fields.push({ sales_price_total: "Sales Price (total)" });
      }
      if (!sub_total) {
        fields.push({ sub_total: "Sub Total" });
      }
      if (!sales_tax_vessel && dataBuyerClosing.is_sales_tax) {
        fields.push({ sales_tax_vessel: "Sales Tax (Vessel)" });
      }
      // if (!balance_due) {
      //   fields.push({ balance_due: "Balance Due" });
      // }
    } else {
      fields.push({ sales_price_total: "Sales Price (total)" });
      fields.push({ sub_total: "Sub Total" });
      fields.push({ sales_tax_vessel: "Sales Tax (Vessel)" });
      // fields.push({ balance_due: "Balance Due" });
      setAsterix((prev) => ({ ...prev, detail: "empty" }));
    }
    if (fields.length === 0) {
      setAsterix((prev) => ({ ...prev, detail: "fulfill" }));
    } else if (fields.length < 4) {
      setAsterix((prev) => ({ ...prev, detail: "notFulfill" }));
    }
    if (
      dataDetails.filter((d) => d.type === "expenses" && d.document_type === "buyer").length > 0
    ) {
      setAsterix((prev) => ({ ...prev, expense: "fulfill" }));
    } else {
      setAsterix((prev) => ({ ...prev, expense: "empty" }));
    }
    if (dataDetails.filter((d) => d.type === "credits" && d.document_type === "buyer").length > 0) {
      setAsterix((prev) => ({ ...prev, credit: "fulfill" }));
    } else {
      setAsterix((prev) => ({ ...prev, credit: "empty" }));
    }
    if (
      dataDetails.filter((d) => d.type === "deposits" && d.document_type === "buyer").length > 0
    ) {
      setAsterix((prev) => ({ ...prev, deposit: "fulfill" }));
    } else {
      setAsterix((prev) => ({ ...prev, deposit: "empty" }));
    }
    if (dataDetails.filter((d) => d.type === "notes" && d.document_type === "buyer").length > 0) {
      setAsterix((prev) => ({ ...prev, note: "fulfill" }));
    } else {
      setAsterix((prev) => ({ ...prev, note: "empty" }));
    }
    dispatch(
      updateOptionsRequiredFields({
        buyerClosing: fields,
      })
    );
  };

  let branch = location.pathname.split("/").pop();

  return (
    <PageSidebarContainer type="add-deal">
      <div className="sidebar-container add-deal">
        <ul className="uk-nav uk-nav-default tm-nav">
          <Link to={`/deals/view/${id}/buyer-closing-statement`}>
            <li
              className={clsx("add-deal chain-step", {
                current: branch === "buyer-closing-statement",
              })}
            >
              <i
                className={clsx(
                  "fa",
                  { "fa-check-circle stepbar": asterix.expense === "fulfill" },
                  { "fa-circle-o": asterix.expense === "empty" || !asterix.expense },
                  { "fa-circle": asterix.expense === "notFulfill" }
                )}
              ></i>
              <span className="add-deal-step">Expenses</span>
            </li>
          </Link>
          <Link to={`/deals/view/${id}/buyer-closing-statement/credits`}>
            <li
              className={clsx("add-deal chain-step", {
                current: branch === "credits",
              })}
            >
              <i
                className={clsx(
                  "fa",
                  { "fa-check-circle stepbar": asterix.credit === "fulfill" },
                  { "fa-circle-o": asterix.credit === "empty" || !asterix.credit },
                  { "fa-circle": asterix.credit === "notFulfill" }
                )}
              ></i>
              <span className="add-deal-step">Credits</span>
            </li>
          </Link>
          <Link to={`/deals/view/${id}/buyer-closing-statement/deposits`}>
            <li
              className={clsx("add-deal chain-step", {
                current: branch === "deposits",
              })}
            >
              <i
                className={clsx(
                  "fa",
                  { "fa-check-circle stepbar": asterix.deposit === "fulfill" },
                  { "fa-circle-o": asterix.deposit === "empty" || !asterix.deposit },
                  { "fa-circle": asterix.deposit === "notFulfill" }
                )}
              ></i>
              <span className="add-deal-step">Deposits</span>
            </li>
          </Link>
          <Link to={`/deals/view/${id}/buyer-closing-statement/notes`}>
            <li
              className={clsx("add-deal chain-step", {
                current: branch === "notes",
              })}
            >
              <i
                className={clsx(
                  "fa",
                  { "fa-check-circle stepbar": asterix.note === "fulfill" },
                  { "fa-circle-o": asterix.note === "empty" || !asterix.note },
                  { "fa-circle": asterix.note === "notFulfill" }
                )}
              ></i>
              <span className="add-deal-step">Notes</span>
            </li>
          </Link>
          <Link to={`/deals/view/${id}/buyer-closing-statement/detail`}>
            <li
              className={clsx("add-deal chain-step", {
                current: branch === "detail",
              })}
            >
              <i
                className={clsx(
                  "fa",
                  { "fa-check-circle stepbar": asterix.detail === "fulfill" },
                  { "fa-circle-o": asterix.detail === "empty" || !asterix.detail },
                  { "fa-circle": asterix.detail === "notFulfill" }
                )}
              ></i>
              <span className="add-deal-step">Transaction Details</span>
            </li>
          </Link>
          {/* <Link to={`/deals/view/${id}/buyer-closing-statement/send_to_signer`}>
            <li className={clsx("add-deal chain-step", { current: branch === "send_to_signer" })}>
              <i className="fa fa-circle-o"></i>
              <span className="add-deal-step">Send for eSignature</span>
            </li>
          </Link> */}

          {
            dataTransaction?.id &&
            // <li
            //   name="back-to-eDocuments"
            //   id="tab-back-to-eDocuments"
            //   className={clsx("add-deal chain-step")}
            // >
            //   <Link to={`/deals/view/${dataTransaction.id}/main#tab=%22edocuments%22`}> <span uk-icon="chevron-double-left"></span> Back to eDocuments</Link>
            // </li>
            <button className="uk-button uk-button-primary mt-2" onClick={() => history.push(`/deals/view/${dataTransaction.id}/main#tab=%22edocuments%22`)}>
              <span uk-icon="chevron-double-left"></span>Back to eDocuments
            </button>

          }

        </ul>
      </div>
    </PageSidebarContainer>
  );
};

export default BuyerDetailStep;
