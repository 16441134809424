import React from "react";
import { makeStyles } from "@mui/styles";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import clsx from "clsx";
import Auth from "../../utils/auth";
import TemplatesService from "../../services/TemplatesService";
import { useDispatch } from "react-redux";
import { setLoadingStatus } from "../../redux/actions/loading";

//Make a schema for form validation
const schema = yup.object().shape({
    title: yup.string().required(),
});

//Define styling
const useStyles = makeStyles({
    boxModalHeader: {
        borderRadius: "inherit"
    },
    modalTitle: { 
        marginLeft: "91px" 
    },
    modalBody: { 
        display: "contents" 
    },
    formContainer: {
        borderRadius: "25px"
    },
    modalFooter: {
        borderRadius: "inherit"
    },
  });

const AddSubTypeModal = ({ dataChecklist, onClose ,type}) => {
    const dataType = dataChecklist?.find((checklist) => checklist.id ===type)?.name ||"";

    //Get the data of logged user
    const logged_user = Auth.getInstance().getUserData();

    //Classess variable for styling
    const classes = useStyles();
    const dispatch = useDispatch();

    // Import form attributes from useForm hooks
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    /* Handle Function for From Inputs */
    const handleRegistration = (formdata) => {
        const dataType = dataChecklist && dataChecklist.find((checklist) => checklist.id === type);

        //Add document type from selected checklist id.
        formdata.document_type = dataType.name.toLowerCase();

        //Add document type id  from selected checklist id.
        formdata.checklist_id = type;

        //Add logged userId in payload
        formdata.created_by_user_account_id = logged_user.id;

        //Showing the loader
        dispatch(setLoadingStatus(true));

        //Send data of subtype to the server
        TemplatesService.addSubtype(formdata)
        .then(({data}) => {

            //Showing the success message from toaster.
            toast.success("Subtype added successfully.", {
                autoClose: 2000
            })

            //Hide the loader
            dispatch(setLoadingStatus(false));

            //Close the modal
            onClose();
        })
        .catch((err) => {

            //Showing the error message through toaster.
            toast.error("Error occurs, please try again.", {
                autoClose: 2000
            });

            //Hide the loader
            dispatch(setLoadingStatus(false));
        })
        
    };

    //Error handler function for form
    const handleError = (errors) => {
        console.log("Errors = ", errors);
    };

    //Document type handler
    const documentTypeHandler = (evt) => {
        setValue("checklist_id", evt.target.value);
    }
    
    //Make a style object for box of the modal
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 500,
        minWidth:260,
        "border-radius": "20px",
        bgcolor: "#fff",
        border: '2px solid #000',
        boxShadow: 24,
        p: 0,
    };

    //Make a style object for the modal
    const modalStyle = {
        borderRadius: "20px"
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={true}
            sx={modalStyle}
            closeAfterTransition
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Box sx={style}>
                <div className={`uk-modal-header ${classes.boxModalHeader} d-flex justify-content-center`}>
                    <h3 className={`uk-modal-title`}>Add Sub Type</h3>
                </div>
                <div className={`uk-modal-body ${classes.modalBody}`}>
                    <div className={`form-container uk-margin-small ${classes.formContainer}`}>
                            <form
                            onSubmit={handleSubmit(handleRegistration, handleError)}
                            className="uk-grid-small form"
                            data-uk-grid
                            id="addform"
                            method="POST"
                            onReset={reset}
                            >
                                 <div className="doc_div d-flex w-100 justify-content-center">
                                {(type) ? <div className="uk-alert uk-alert-primary" data-uk-alert>
                                    <p>{`Type : ${dataType}`}</p>
                                </div> :
                                    <div className="uk-alert uk-alert-danger" data-uk-alert>
                                        <p>{"Please select type first"}</p>
                                    </div>
                                     }

                            </div> 
                                <div className="form-row">
                                    <div className="uk-padding-small">
                                        <label className="uk-form-label">Title</label>
                                        <input
                                            className={clsx("uk-input", {
                                                "uk-form-danger": errors.title,
                                                })}
                                            name="title"
                                            {...register("title")}
                                            type="text"
                                            id="address_1"
                                        />
                                    </div>
                                </div>
                                <div className={`uk-modal-footer uk-text-right ${classes.modalFooter}`}>
                                    <input
                                        type="submit"
                                        className="uk-button iy-button-primary"
                                        id="add-office-save"
                                        value="Save"
                                    />
                                    <button value="Submit" className="uk-button uk-button-danger" type="button"onClick={onClose} >Close</button>
                                </div>
                            </form>
                    </div>
                </div>
            </Box>
        </Modal>
    );
};

export default AddSubTypeModal;