import {
  CREATE_CLIENT,
  RETRIEVE_CLIENTS,
  FIND_CLIENT_BY_NAME,
  GET_CLIENT,
  FIND_ONE_BY_NAME,
  CLEAR_CLIENT,
  DELETE_CLIENT,
} from "../actions/types";

const initialState = {
  list: [],
  client: null,
};

function clientReducer(clients = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CLIENT:
      return { ...clients, list: [...clients.list, payload] };
    case RETRIEVE_CLIENTS:
      return { ...clients, list: payload };
    case GET_CLIENT:
      return { ...clients, client: payload };
    case FIND_CLIENT_BY_NAME:
      return { ...clients, list: payload };
    case FIND_ONE_BY_NAME:
      return { ...clients, client: payload[0] };
    case CLEAR_CLIENT:
      return { ...clients, client: null };
    case DELETE_CLIENT:
      return { ...clients, client: null };
    default:
      return clients;
  }
}

export default clientReducer;
