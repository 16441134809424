import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { getAllTransactions, updateTransaction } from "../../redux/actions/transaction";
import { toast } from "react-toastify";
import { setLoadingStatus } from "../../redux/actions/loading";
import ThemeButton from "./buttons/ThemeButton";
import { getEsignedDocumentpath, getHandSignedDocumentPath } from "../../utils/common";


const PreviewModal = ({ request_signature, onClose }) => {

    const signers = request_signature?.signers || []

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        "border-radius": "20px",
        bgcolor: "#fff",
        border: '2px solid #000',
        boxShadow: 24,
        p: 0,
    };

    // if (signer.signed_date && signer.hand_signed_docs) {
    //             window.open(getHandSignedDocumentPath(signer.signed_document), "_blank")
    //             return;
    //         }

    const handlePreview = (signer) => {
        window.open(
            request_signature?.is_manual_updated && signer.status != 'Signed'
                ? `https://${process.env.REACT_APP_PDF_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}pdf-documents/${request_signature.doc_link}`
                : getEsignedDocumentpath(request_signature.doc_link),
            "_blank");
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={true}
            style={{
                borderRadius: "20px"
            }}
            closeAfterTransition
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Box sx={style}>
                <div className="uk-modal-header" style={{
                    borderRadius: "inherit"
                }}>

                    <h4 className="uk-modal-title" style={{ marginLeft: "23px" }}>Preview Signed Documents</h4>
                </div>
                <div className="uk-modal-body" style={{ display: "contents" }}>
                    <table className="uk-table uk-table-middle uk-table-responsive uk-table-divider" style={{ width: "-webkit-fill-available" }}>
                        <tbody>
                            {
                                signers.map((signer) => {
                                    return (
                                        <tr>
                                            <td>{signer.hand_signed_docs?.length
                                                ? <img src="/icons/handSign.svg" alt="" />
                                                : <img height="25px" width="25px" style={{color: '#2b6ea1'}} src="/images/esign.png" alt="" />}
                                                &nbsp;{`${signer.request_signer.role} (${signer.request_signer.name})`}
                                            </td>
                                            <td>
                                                {signer.hand_signed_docs?.length
                                                    ? signer.hand_signed_docs?.map((doc, index) => {
                                                        return <>
                                                            <a key={doc.id} onClick={() => window.open(getHandSignedDocumentPath(doc.file_name), "_blank")}>
                                                                <span className='font-weight-bold'>{index + 1}.&nbsp;{doc.original_file_name}</span>
                                                            </a>
                                                            <br />
                                                        </>
                                                    })
                                                    : <a onClick={() => window.open(
                                                        request_signature?.is_manual_updated && signer.status != 'Signed'
                                                            ? `https://${process.env.REACT_APP_PDF_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}pdf-documents/${request_signature.doc_link}`
                                                            : getEsignedDocumentpath(request_signature.doc_link),
                                                        "_blank")}>
                                                        <span className='font-weight-bold'>{request_signature.doc_link}</span>
                                                    </a>

                                                    // <ThemeButton theme="primary" onClick={() => { handlePreview(signer) }}>Preview</ThemeButton>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="uk-modal-footer uk-text-right" style={{
                    borderRadius: "inherit"
                }}>
                    <button value="Submit" className="uk-button uk-button-primary" type="button"
                        onClick={onClose} >Close</button>
                </div>
            </Box>
        </Modal >
    );
};

export default PreviewModal;