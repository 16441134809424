import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Fragment } from 'react';

import ESignPage from '../components/esign/index.jsx';

const ESignLayout = () => {
  let {path} = useRouteMatch();

  return (
    <Fragment>
      <Switch>
        <Route path={`${path}/c`} component={ESignPage} />
      </Switch>
    </Fragment>
  );
}

export default ESignLayout;