import { Route, Switch,Redirect, useRouteMatch } from "react-router"

import LoginForm from "./forms/Login";
import SignupForm from "./forms/Signup";
import ResetForm from "./forms/Reset";
import AcceptForm from "./forms/Accept";
import VerifyForm from "./forms/Verify";
import VerificationForm from "./forms/verification";

const MainAuth = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={LoginForm} />
      <Route path={`${path}/login`} component={LoginForm} />
      <Route path={`${path}/signup`} component={SignupForm} />
      <Route path={`${path}/forgot-password`} component={ResetForm} />
      <Route path={`${path}/accept-invite`} component={AcceptForm} />
      <Route path={`${path}/verify-email`} component={VerifyForm} />
      <Route path={`${path}/verification`} component={VerificationForm} />
      <Redirect to={`${path}/login`} />

    </Switch>
  )
}

export default MainAuth;
