import React, { useEffect, useState } from 'react'
import AllLogsTable from './AllLogsTable'
import LoginOutLogsTable from './LoginOutLogsTable'
import FormRow from '../common/FormRow';
import Select from 'react-select'
import { DatePicker } from 'antd';
import UserService from '../../services/UserService';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveCompanies } from '../../redux/actions/companies';
import dayjs from 'dayjs';
import { setLoadingStatus } from '../../redux/actions/loading';
import UserActivityService from '../../services/UserActivityService';
import { convertUnderscoreSeparateLetter } from '../../utils/functions';
import { Tab, Tabs } from '@mui/material';
import { isCompanyAdmin } from '../../utils/permission';
import Auth from '../../utils/auth';
import "./mainActivity.css"
import { dateRangePickerPresets } from '../../utils/AppConstants';

const { RangePicker } = DatePicker;

const MainActivity = () => {
  const [allActivities, setAllActivities] = useState([])
  const [loginActivities, setLoginActivities] = useState([])

  //Store count of total rows
  const [totalRows, setTotalRows] = useState(0)

  const [logType, setLogType] = useState("all");
  const [search, setSearch] = useState({});

  const dispatch = useDispatch();
  const [companyList, setCompanyList] = useState([])

  //Store user data for dropdown
  const [userList, setUserList] = useState([]);

  const [moduleList, setModuleList] = useState([]);
  const [type, setType] = useState([])
  const dataCompanies = useSelector((state) => state.companies.companies);
  const sortedDataCompanies = dataCompanies.sort((a, b) => (b.company_name > a.company_name ? -1 : 1))

  useEffect(() => {
    setCompanyList(() => ([]));
    sortedDataCompanies?.map((val) => {
      setCompanyList((prev) => [
        ...(prev || []),
        { value: `${val.id}`, label: `${val.company_name}` },
      ]);
    });
  }, [sortedDataCompanies])

  useEffect(() => {
    dispatch(retrieveCompanies());
    // get all filter 
    UserActivityService.getAllFilters().then((res) => {
      res.data?.activityTypesList?.map((val) => {
        setType((prev) => [
          ...(prev || []),
          { value: `${val}`, label: `${convertUnderscoreSeparateLetter(val, "-")}` },
        ]);
      });
      res.data?.moduleNamesList?.map((val) => {
        setModuleList((prev) => [
          ...(prev || []),
          { value: `${val}`, label: `${convertUnderscoreSeparateLetter(val, "-")}` },
        ]);
      });
    })
  }, []);

  // get user of  particular company
  const getOfficesByCompany = (option) => {
    setUserList(() => ([]));
    if (option?.value) {
      const { value: company_id } = option;
      dispatch(setLoadingStatus(true));
      UserService.searchByCompany(company_id).then((res) => {
        let users = res.data;
        users.sort((a, b) => (b.last_name > a.last_name ? -1 : 1));
        users.map((val) => {
          setUserList((prev) => [
            ...(prev || []),
            { value: `${val.id}`, label: `${val.first_name} ${val.last_name}` },
          ]);
        });
        dispatch(setLoadingStatus(false))
      }).catch((error) => {
        dispatch(setLoadingStatus(false))
        setUserList([]);
      });
    } else {
      setUserList([])
      setSearch(search => {
        return {
          ...search,
          company: null,
          users: null
        }
      })
    }
  }
  // if login id company admin get data fo their company users 
  useEffect(() => {
    if (isCompanyAdmin()) {
      const currentCompany = Auth.getInstance().getUserData().company;
      getOfficesByCompany({ value: currentCompany?.id, label: currentCompany?.company_name });
    }
  }, [isCompanyAdmin()]);

  useEffect(() => {
    if (search) {
      fetchLog()
    }
  }, [search, logType])

  // fetch log information
  const fetchLog = () => {
    dispatch(setLoadingStatus(true))
    const page = search.page || 1
    const limit = search.limit || 10
    let searchParams = `userId=${search?.users?.value || ""}&companyId=${search?.company?.value || ""}&startDate=${search.startDate || dayjs().add(-7, 'd').format('MM-DD-YYYY')}&endDate=${search.endDate || dayjs().format('MM-DD-YYYY')}&ipAddress=${search.ipAddress || ""}&sortOrder=${search.sortOrder || ""}&sortField=${search.sortField || ""}`
    if (logType === 'all') {
      searchParams = searchParams + `&module_name=${search.module?.value || ""}&activity_type=${search?.activity?.value || ""}`
      UserActivityService.getAllLogs(page, limit, searchParams).then(res => {
        dispatch(setLoadingStatus(false));
        setAllActivities(res.data.rows);
        const totalRecords = res.data.count;
        setTotalRows(totalRecords);
      }).catch(err => {
        dispatch(setLoadingStatus(false))
      });
    } else {
      UserActivityService.getLoginLogoutLogs(page, limit, searchParams).then(res => {
        dispatch(setLoadingStatus(false))
        setLoginActivities(res.data.rows)
        const totalRecords = res.data.count;
        setTotalRows(totalRecords);
      }).catch(err => {
        dispatch(setLoadingStatus(false))
      })
    }

  }

  const handelLogType = (event, newValue) => {
    setLogType(newValue)
  }

  const delay = (function () {
    var timer = 0;
    return function (callback, ms) {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };
  })();

  const setFilter = (label, value) => {
    setSearch(search => {
      return {
        ...search,
        [label]: value
      }
    });
  }

  const setDateFilter = (dateRange) => {
    setSearch(search => {
      return {
        ...search,
        startDate: dateRange[0],
        endDate: dateRange[1]
      }
    });
  }

  return (
    <div className="uk-container uk-container-expand uk-position-relative">
      <div className="">
        <h1 className="d-flex align-items-center gap-1">User Activity Logs</h1>
      </div>
      <div className="forms-nav-actions">
        <div className='d-flex justify-content-between w-100'>
          <Tabs value={logType} indicatorColor="primary" onChange={handelLogType}>
            <Tab value="all" label="All Logs" />
            <Tab value="login" label="User Login-out Log" />
          </Tabs>
          <div>
            <button className="uk-button uk-button-dark" onClick={() => { fetchLog() }}><i class="fa fa-refresh" aria-hidden="true"></i> Refresh</button>
          </div>
        </div>
      </div>
      <FormRow>
        <div className="d-flex flex-wrap d">

          {logType === "all"
            ? <>
              <div className="one-column date-range-picker-div ml-1 form_drop_down">
                <Select
                  isClearable={true}
                  components={{
                    IndicatorSeparator: () => null
                  }}
                  maxMenuHeight={300}
                  options={type}
                  closeMenuOnSelect={true}
                  classNamePrefix="select"
                  placeholder="Select Activity Type"
                  value={search.activity}
                  onChange={(value) => { setFilter("activity", value); }}
                />
              </div>
              <div className="one-column date-range-picker-div  form_drop_down">
                <Select
                  isClearable={true}
                  components={{
                    IndicatorSeparator: () => null
                  }}
                  maxMenuHeight={300}
                  options={moduleList}
                  closeMenuOnSelect={true}
                  classNamePrefix="select"
                  placeholder="Select By Module"
                  value={search.module}
                  onChange={(value) => { setFilter("module", value); }}
                />
              </div>
            </>
            : <></>
          }
          {!isCompanyAdmin() &&
            <div className="one-column date-range-picker-div form_drop_down">
              <Select
                isClearable={true}
                components={{
                  IndicatorSeparator: () => null
                }}
                maxMenuHeight={300}
                options={companyList}
                classNamePrefix="select"
                placeholder="Select By Company"
                value={search.company}
                onChange={(value) => {
                  getOfficesByCompany(value);
                  setSearch(search => {
                    return {
                      ...search,
                      company: value,
                    }
                  });
                }}
              />
            </div>
          }

          <div className="one-column date-range-picker-div   form_drop_down">
            <Select
              isClearable={true}
              components={{
                IndicatorSeparator: () => null
              }}
              maxMenuHeight={300}
              options={userList}
              closeMenuOnSelect={true}
              classNamePrefix="select"
              placeholder="Select By Users"
              value={search.users}
              onChange={(value) => { setFilter("users", value); }}
            />
          </div>
          <div className="date-range-picker-div">
            <RangePicker
              style={{ height: "40px", width: "230px" }}
              format="MM-DD-YYYY"
              defaultValue={[dayjs().add(-7, 'd'), dayjs()]}
              allowClear={true}
              presets={dateRangePickerPresets}
              onChange={(value, dateString) => {
                setDateFilter(dateString);
              }}
            />
          </div>
          <div className="one-column date-range-picker-div " style={{ width: "200px" }}>
            <input className="uk-input" type="text" onChange={(e) => {
              delay(function () {
                setFilter("ipAddress", e.target.value)
              }, 2000);
            }} placeholder="Search By IP" />
          </div>
        </div>
      </FormRow>
      {logType === "all"
        ? <AllLogsTable searchProps={{ search, setSearch }} allActivities={allActivities} totalRows={totalRows} />
        : <LoginOutLogsTable searchProps={{ search, setSearch }} loginActivities={loginActivities} totalRows={totalRows} />
      }
    </div>
  )
}

export default MainActivity