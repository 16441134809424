import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@mui/styles';

import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";

import PhoneInput from 'react-phone-input-2';

import { completeInvitation, resetStore } from "../../../redux/actions/auth";
import { setLoadingStatus } from "../../../redux/actions/loading";
import Auth from "../../../utils/auth";
import UserService from "../../../services/UserService";

const useStyles = makeStyles({
  phone_input: {
    padding: '12px 12px 12px 50px !important',
    width: '100% !important'
  },
  phone_container: {
    marginTop: '1rem'
  }
});

const schema = yup.object().shape({
  first_name: yup
    .string()
    .required("First name is required field"),
  last_name: yup
    .string()
    .required("Last name is required field"),
  mobile_phone: yup
    .string()
    .required('Phone number is required')
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'Phone number is not valid'
    ),
  password: yup
    .string()
    .required("Please enter your password")
    .matches(
      /^.*(?=.{8,})((?=.*\d){1})((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, and one number"
    ),
  confirm_password: yup.string()
    .required("Please enter your password")
    .matches(
      /^.*(?=.{8,})((?=.*\d){1})((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, and one number"
    ).oneOf([yup.ref('password'), null], 'Passwords must match')
});

const AcceptForm = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const history = useHistory();
  const classes = useStyles();

  const [isFinishSubmit, setFinishSubmit] = useState(false);
  const [err, setErr] = useState(undefined);

  const [csrfToken, setCsrfToken] = useState(null);

  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: {
      email: auth.user.email?.toLowerCase(),
      old_password: auth.user.password,
      first_name: '',
      last_name: '',
      password: '',
      confirm_password: ''
    },
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true
  });

  useEffect(() => {
    UserService.trackUserToken('accept-invite')
      .then(response => {
        setCsrfToken(response.data.xt);
      }).catch(error => {
        console.log(error);
        setCsrfToken(null);
      });
  }, []);

  const handleUpdate = (userdata) => {
    dispatch(setLoadingStatus(true));
    const config = {
      headers: {
        xt: csrfToken
      }
    };
    dispatch(completeInvitation(userdata, config)).then(res => {
      if (res.status !== 200) {
        throw res;
      }

      dispatch(setLoadingStatus(false));
      setFinishSubmit(true);
      setTimeout(() => {
        Auth.getInstance().logout();
        dispatch(resetStore());
        history.push("/auth/login");
      }, 3000);
    }).catch(err => {
      dispatch(setLoadingStatus(false));
      if (err.status === 400 || err.status === 401) {
        setErr(err.data.message);
      }
    });
  }

  const handleError = (errors) => {
    console.log("Errors = ", errors);
  };

  useEffect(() => {
    if (auth.resetAction.message !== 'reset') {
      history.push("/auth/login");
    }
  }, [auth, history]);

  return (
    <div className="tm-main sign-in px-5">
      <div className="content sign-in">
        <h1>Welcome to BoatDox</h1>

        {isFinishSubmit ?
          <div className="uk-alert uk-alert-primary" data-uk-alert><p>Updated Successfully</p></div>
          : undefined
        }

        {err ?
          <div className="uk-alert uk-alert-danger" data-uk-alert><p>{err}</p></div>
          : undefined
        }

        <form onSubmit={handleSubmit(handleUpdate, handleError)} className="uk-grid-small form " data-uk-grid id="addform" method="POST" onReset={reset} style={{maxWidth:"1080px"}}>
          <div className="form-row">
            <div className="half-column uk-padding-small">
              <label className="uk-form-label" htmlFor="name">First Name*</label>
              <input className={clsx("uk-input", { 'uk-form-danger': errors.first_name })} type="text" {...register("first_name")} />
            </div>
            <div className="half-column uk-padding-small">
              <label className="uk-form-label" htmlFor="name">Last Name*</label>
              <input className={clsx("uk-input", { 'uk-form-danger': errors.last_name })} type="text" {...register("last_name")} />
            </div>
          </div>
          <div className="form-row">
            <div className="half-column uk-padding-small">
              <label className="uk-form-label" htmlFor="name">Mobile Phone</label>
              {/* <input className={clsx("uk-input", {'uk-form-danger': errors.mobile_phone})} type="number" {...register("mobile_phone")} /> */}
              <input type="hidden" required name="mobile_phone" {...register("mobile_phone")} />
              <PhoneInput
                placeholder='+'
                country={'us'}
                inputClass={classes.phone_input}
                containerClass={classes.phone_container}
                onChange={phone => { setValue('mobile_phone', phone, { shouldValidate: true }) }}
              />
              <p>{errors.mobile_phone?.message}</p>
            </div>
          </div>
          {/* <div className="form-row">
            <div className="three-column uk-padding-small">	
              <label className="uk-form-label" htmlFor="name">Profile Photo</label>
              <div className="profile-photo">Select</div>
            </div>        
          </div> */}
          <div className="form-row">
            <div className="half-column uk-padding-small">
              <label className="uk-form-label" htmlFor="name">Password*</label>
              <input className={clsx("uk-input", { 'uk-form-danger': errors.password })} type="password" {...register("password")} autoComplete="new-password" />
            </div>
            <div className="half-column uk-padding-small">
              <div className="password"><label className="uk-form-label" htmlFor="name">Verify Password*</label></div>
              <input className={clsx("uk-input", { 'uk-form-danger': errors.confirm_password })} type="password" {...register("confirm_password")} />
            </div>
          </div>
          <div className="form-row">
            <div className="half-column uk-padding-small">
              <ul>
                <li>Password must contain numbers</li>
                <li>Password must contain uppercase letters</li>
              </ul>
            </div>
            <div className="half-column uk-padding-small">
              <ul>
                <li>Length must be greater than 8 characters</li>
                <li>Password should not contain strings</li>
                <li>Password must not contain repetitions.</li>
              </ul>
            </div>
          </div>

          <div className="form-row">
            <div className="three-column uk-padding-small">
              <button type="submit" className="uk-button iy-button-primary">Continue</button>
            </div>
          </div>

          <div className="form-row">
            <div className="three-column uk-padding-small">
              <label>
                Need help? <a href="#">Contact us</a>.
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AcceptForm;