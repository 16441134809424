import { Switch, Route, Redirect, useRouteMatch, useParams } from "react-router-dom";
import EsignUSCGBillOfSale from "./steps/EsignUSCG1340BillOfSale";
import USCGBillStep from "./stepbars/USCG1340BillOfSaleStep";

const USCGBillOfSaleOption = () => {
  let { path } = useRouteMatch();
  let { id } = useParams();

  return (
    <div>
      <USCGBillStep />
      <Switch>
        <Route path={`${path}/send_to_signer`} render={() => <EsignUSCGBillOfSale transactionId={id} />} />
        <Redirect to={path} />
      </Switch>
    </div>
  );
};

export default USCGBillOfSaleOption;
