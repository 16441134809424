import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Rnd } from "react-rnd";
import { useDrop } from 'react-dnd';
import { makeStyles } from '@mui/styles';

import { lightColors } from '../../../../utils/colors';

const style = {
  width: '100%',
  height: '100%',
  top: '0',
  position: 'absolute',
  zIndex: '1',
};

const useStyles = makeStyles({
  focused: {
    border: '2px solid #1976d2 !important',
    borderRadius: '0px'
  },
  bottomLeft: {
    width: '8px !important',
    height: '8px !important',
    left: '-4px !important',
    bottom: '-4px !important',
    border: '1px solid',
    background: 'white',
    borderRadius: '4px'
  },
  bottomRight: {
    width: '8px !important',
    height: '8px !important',
    right: '-4px !important',
    bottom: '-4px !important',
    border: '1px solid',
    background: 'white',
    borderRadius: '4px'
  },
  topLeft: {
    width: '8px !important',
    height: '8px !important',
    left: '-4px !important',
    top: '-4px !important',
    border: '1px solid',
    background: 'white',
    borderRadius: '4px'
  },
  topRight: {
    width: '8px !important',
    height: '8px !important',
    right: '-4px !important',
    top: '-4px !important',
    border: '1px solid',
    background: 'white',
    borderRadius: '4px'
  }
});

const rndStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // border: "solid 1px #ddd",
  background: "#f0f0f0",
};

export const DocPanel = memo(function DocPanel({ accept, handleDrop, parentProps, pageId, updateRef }) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: handleDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const pageRef = useRef();
  const targetRoles = ["Me", "Sender", ...parentProps.roles];
  // const isActive = isOver && canDrop;
  const classes = useStyles();
  const viewport = { width: 856, height: parentProps.height * (856 / parentProps.width) };

  useEffect(() => {
    if (isOver) {
      updateRef(pageRef);
    }
  }, [isOver]);

  const activeStatus = useCallback((element) => {
    return parentProps.current && parentProps.current.key === element.key;
  }, [parentProps.current]);

  const currentFieldName = useCallback((element) => {
    switch (element.type) {
      case 'PDFCheckBox':
        return ''
      case 'PDFInitial':
        return 'xx'
      case 'PDFSignature':
        return 'Signature'
      case 'PDFDateSigned':
        return 'Date'
      default:
        return element.value
    }
  }, []);

  return (
    <div ref={drop} role="DocPanel" style={{ ...style }}>
      <div id={pageId} ref={pageRef} className="page_container" onClick={(event) => parentProps.handleBlurField(event)}>
        {parentProps.data.length > 0 &&
          parentProps.data.filter(field => field.page_number === pageId).map((element, idx) =>
            <Rnd
              key={`rnd_${pageId}_${idx}}`}
              style={rndStyle}
              position={{
                x: element.coord.x * viewport.width / parentProps.width,
                y: viewport.height - (element.coord.y + element.coord.height) * viewport.height / parentProps.height
              }}
              size={{
                width: element.coord.width * viewport.width / parentProps.width,
                height: element.coord.height * viewport.height / parentProps.height
              }}
              enableResizing={{
                bottomLeft: activeStatus(element) ? true : false,
                bottomRight: activeStatus(element) ? true : false,
                topLeft: activeStatus(element) ? true : false,
                topRight: activeStatus(element) ? true : false
              }}
              resizeHandleClasses={{
                bottomLeft: classes.bottomLeft,
                bottomRight: classes.bottomRight,
                topLeft: classes.topLeft,
                topRight: classes.topRight
              }}
              onDragStop={(event, dimension) => {
                const new_pos = {
                  x: dimension.x / viewport.width * parentProps.width,
                  y: (viewport.height - dimension.y) / viewport.height * parentProps.height - element.coord.height,
                  width: element.coord.width,
                  height: element.coord.height
                };
                parentProps.handleChangeCoordination(new_pos);
              }}

              onResizeStop={(event, direction, ref, delta, position) => {
                const new_pos = {
                  x: element.coord.x,
                  y: element.coord.y,
                  width: element.coord.width + delta.width / viewport.width * parentProps.width,
                  height: element.coord.height + delta.height / viewport.height * parentProps.height
                };
                parentProps.handleChangeCoordination(new_pos);
              }}
            >
              <input
                style={{
                  cursor: 'move',
                  width: '100%',
                  height: '100%',
                  padding: '5px',
                  outline: 'none',
                  borderRadius: '2px',
                  backgroundColor: targetRoles.indexOf(element.assigner) > -1 ? lightColors[targetRoles.indexOf(element.assigner)] : '#ffffff',
                  // borderRadius: '0px',
                  border: '1px solid #565656'
                }}
                id={element.key}
                onFocus={parentProps.handleFocusField}
                onClick={(event) => event.stopPropagation()}
                // focused={parentProps.current && parentProps.current.key === element.key}
                className={parentProps.current && parentProps.current.key === element.key ? classes.focused : ''}
                value={currentFieldName(element)}
                onKeyUp={(event) => { event.stopPropagation(); parentProps.handleKey(event); }}
                readOnly
              />
            </Rnd>
          )
        }
      </div>
    </div>
  );
});
