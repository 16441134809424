
import http, { setHeaders } from '../http-common';
import Auth from '../utils/auth';

setHeaders(Auth.getInstance().getAuthToken());

const getAll = () => {
  return http.get("/companies");
};

const get = (id) => {
  return http.get(`/companies/${id}`);
};

const create = (data) => {
  return http.post("/companies", data);
};

const update = (id, data) => {
  return http.put(`/companies/${id}`, data);
};

const remove = (id) => {
  return http.delete(`/companies/${id}`);
};

const removeAll = () => {
  return http.delete("/companies");
};

const findByName = (search) => {
  return http.post("/companies/searchByName", search);
};

const CompaniesService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByName,
};

export default CompaniesService;
