const PageMainContainer = ({ children, type }) => {
  if (!type) {
    type = "";
  }

  return (
    <div className="tm-main right-side-tm-main-section">
      <div className={`uk-container uk-container-small uk-position-relative ${type}`}>
        {children}
      </div>
    </div>
  );
};

export default PageMainContainer;
