'use strict';

import moment from "moment";

export const createFileFromLink = async (fileUrl, fileName) => {
    try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        return new File([blob], fileName, { type: 'application/pdf' });
    } catch (error) {
        console.log('Error:', error);
        return null;
    }
}

export const getHandSignedDocumentPath = (fileName) => {
    return fileName ? `https://${process.env.REACT_APP_FORMS_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}hand-signed-documents/${fileName}` : '';
}

export const getEsignedDocumentpath = (fileName) => {
    return fileName ? `https://${process.env.REACT_APP_FORMS_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}e-signed-documents/${fileName}` : '';
}

export const momentDateWithTimezone = (date) => {
    let formatedDate = '';
    if (date) {
        const timezone = moment.tz(moment.tz.guess()).zoneAbbr();
        formatedDate = `${moment(date).local().format("MM-DD-yyyy hh:mm A")} ${timezone}`;
    }
    return formatedDate;
}

// function for change date format in yyyy-MM-DD format (used for api payload formatted date)
export const dateFormatter = (date) => {
    // if there is no date means if null comes then it will be back
    if (date) {
        return moment(date).local().format("yyyy-MM-DD");
    }
    return date;
}