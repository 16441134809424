import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ClientsService from "../../services/ClientService";

const ClientView = (props) => {
  const client_id = props.location.state ? props.location.state.id : null;

  const [client, setClient] = useState({});

  useEffect(() => {
    ClientsService.get(client_id).then((client) => {
      setClient(client.data);
    });
  }, []);

  return (
    <div className="uk-container page">
      <ul className="uk-breadcrumb">
        <li>
          <Link to="/clients">Clients</Link>
        </li>
        <li className="uk-disabled">
          <a>View Client</a>
        </li>
      </ul>

      <h1>{client.last_name + " " + client.first_name}</h1>

      <div className="form-container">
        <form className="uk-grid-small form" data-uk-grid>
          <div className="uk-width-1-3@s  uk-margin mt20">
            <label className="uk-form-label" htmlFor="name">
              First Name
            </label>
            <div className="uk-text uk-text-emphasis">{client.first_name}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Middle Name
            </label>
            <div className="uk-text uk-text-emphasis">{client.middle_name}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Last Name
            </label>
            <div className="uk-text uk-text-emphasis">{client.last_name}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Email
            </label>
            <div className="uk-text uk-text-emphasis">{client.email}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Mobile Phone
            </label>
            <div className="uk-text uk-text-emphasis">
              {client.mobile_phone}
            </div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Citizen Of
            </label>
            <div className="uk-text uk-text-emphasis">{client.nationality}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Address
            </label>
            <div className="uk-text uk-text-emphasis">{client.address_1}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Unit/Apt
            </label>
            <div className="uk-text uk-text-emphasis">{client.unit_apt}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              City
            </label>
            <div className="uk-text uk-text-emphasis">{client.city}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              State
            </label>
            <div className="uk-text uk-text-emphasis">{client.state}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Zip Code
            </label>
            <div className="uk-text uk-text-emphasis">{client.postal_code}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Country
            </label>
            <div className="uk-text uk-text-emphasis">{client.country}</div>
          </div>
        </form>
      </div>

      <div className="uk-column-1-1  uk-margin-small">
        <Link to={"/clients"}>
          <button className="uk-button iy-button-primary">
            Done
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ClientView;
