import { COMPLETE_INVITATION, PASSWORD_RESET_REQUIRED } from '../actions/types';

const actionForState = (action, state) => ({
  ...action,
  success: state === 'success',
  loading: state === 'pending',
  message: state
});

const initialState = {
	user: {},
  resetAction: {
    success: undefined,
    loading: undefined,
    message: undefined
  },
  new_user: {}
};

function authReducer(state = initialState, action) {
	switch(action.type) {
		case PASSWORD_RESET_REQUIRED:
      return {
        ...state,
        resetAction: actionForState(state.action, 'reset'),
        user: action.payload
      };
    case COMPLETE_INVITATION.pending:
      return {
        ...state,
        completeAction: actionForState(state.action, 'pending')
      };
    case COMPLETE_INVITATION.success:
      return {
        ...state,
        user: action.payload,
        completeAction: actionForState(state.action, 'success')
      };
    case COMPLETE_INVITATION.error:
      return {
        ...state,
        completeAction: actionForState(state.action, 'error')
      };
		default:
			return state;
	}
}

export default authReducer;