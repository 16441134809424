import { useEffect } from "react";
import reactDom from "react-dom";

const el = document.createElement("div");

const UploadPortal = ({ children }) => {
  const portalRoot = document.getElementById("root");
  el.setAttribute("data-uk-modal", "true");
  el.id = "modal-upload-document-listing";

  useEffect(() => {
    portalRoot.appendChild(el);
  }, []);
  return reactDom.createPortal(children, el);
};

export default UploadPortal;
