import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteUser, resendInvitation, updateUser } from "../../redux/actions/users";
import Auth from "../../utils/auth";

import { Switch, Tab, Tabs, Tooltip } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { retrieveCompanies } from "../../redux/actions/companies";
import { setLoadingStatus } from "../../redux/actions/loading";
import OfficesService from "../../services/OfficeService";
import UserService from "../../services/UserService";
import { logUserCompanyMismatch } from "../../utils/access";
import { phoneNumber } from '../../utils/phonenumber';
import ChangeUserPasswordModal from "../common/ChangeUserPasswordModal";

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Swal from "sweetalert2";
import TutorialService from "../../services/TutorialService";
import AssociateCompanyForParticipantModal from "../common/AssociateCompanyForParticipantModal";
import VideoPlayerModal from "../common/videoPlayerModal";
import ParticipantList from "./ParticipantList";
import UserList from "./UserList";
import GuestAgentList from "./GuestAgentList";

const UsersList = () => {
  const [officeList, setOfficeList] = useState([]);

  //Store user data for dropdown
  const [userList, setUserList] = useState([]);

  //Make a state for search
  const [search, setSearch] = useState({
    isUserParticipantList: false
  });

  //Store users data for listing
  const [users, setUsers] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [guestAgents, setGuestAgents] = useState([]);

  //Store count of total rows
  const [totalRows, setTotalRows] = useState(0);

  // States for manage open close modal for manage participant associate companies
  const [showAssociateCompanies, setShowAssociateCompanies] = useState(false);
  const [associateParticipant, setAssociateParticipant] = useState(null);

  // const users = useSelector((state) => state.users.users);
  const dataComapnies = useSelector((state) => state.companies.companies);
  const isLoading = useSelector((state) => state.loading.status);
  const sortedDataCompanies = dataComapnies.sort((a, b) => (b.company_name > a.company_name ? -1 : 1))

  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  const loggedUser = Auth.getInstance().getUserData();
  const { UserInRole } = loggedUser;
  const { UserRole } = UserInRole;
  const { name } = UserRole;
  const { company_id } = loggedUser;

  // for Change Password of User
  const [show, setShow] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const history = useHistory();

  const [type, setType] = useState('users');

  const [showVideo, setShowVideo] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null)
  const tutorials = useSelector((state) => state?.tutorials) || null;

  const [loadCount, setLoadCount] = useState(0);
  useEffect(() => {
    if (loadCount === 0) {
      dispatch(setLoadingStatus(false));
      setLoadCount(0);
    }
  }, [loadCount]);

  const fetchUserData = (search) => {
    dispatch(setLoadingStatus(true));
    setLoadCount((prev) => prev + 1);
    UserService.findByName(search).then((res) => {
      const usersList = res.data.data;
      for (let index = 0; index < usersList.length; index++) {
        const element = usersList[index];
        if (name != "Boatdox Admin" && company_id != element.company_id) {
          logUserCompanyMismatch(element, 'User List', history);
          break;
        }
      }
      const totalRecords = res.data.total;
      setTotalRows(totalRecords);
      if (!['participant', 'guest_agent'].includes(type)) {
        setUsers(usersList);
      } else if (type == 'participant') {
        if (name != 'Boatdox Admin') {
          setParticipants(usersList.map((user) => {
            return {
              ...user,
              participant_id: user.id,
              ...user.user
            }
          }));
        } else {
          setParticipants(usersList);
        }
      } else {
        setGuestAgents(usersList)
      }
      // dispatch(setLoadingStatus(false));
      setLoadCount((prev) => prev - 1);
    }).catch((error) => {
      dispatch(setLoadingStatus(false));
      setLoadCount((prev) => prev - 1);
      console.log(error);
      toast.error("Error occurs, please try again.", {
        autoClose: 2000
      })
    })
  }

  useEffect(() => {
    if (search) {
      fetchUserData(search)
      // dispatch(findUserByName(search)).then(() => dispatch(setLoadingStatus(false)));
    }
  }, [search]);

  useEffect(() => {
    dispatch(retrieveCompanies());
  }, []);

  const onDelete = (id) => {
    Swal.fire({
      didOpen: () => {
        Swal.showLoading();
        UserService.getAssociatedTransactionForUser(id).then((response) => {
          // if any transaction exist then cannot delete otherwise confirmation before API call for delete
          if (response.data.count) {
            Swal.fire({
              title: "Cannot Delete User",
              text: "Please delete or move any listing agreements and deals to another broker before deleting.",
              icon: "warning"
            });
            return;
          } else {
            Swal.fire({
              title: 'Are you sure?',
              text: "Deleted user account cannot be restored.",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes',
              cancelButtonText: "No"
            }).then((result) => {
              if (result.isConfirmed) {
                dispatch(setLoadingStatus(true));
                dispatch(deleteUser(id)).then((response) => {
                  dispatch(setLoadingStatus(false));
                  toast.info("User account deleted successfully.");
                  fetchUserData(search);
                }).catch((err) => {
                  dispatch(setLoadingStatus(false));
                  console.log(err);
                  toast.error("Something went wrong while delete user.");
                });
              }
            });
          }
        }).catch((error) => {
          Swal.hideLoading();
          toast.error("Something went wrong, please try again.")
          console.log(error);
        });
      }
    })
  };

  const handelDeleteParticipant = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to remove this Participant.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: "No"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(setLoadingStatus(true));
        UserService.deleteParticipant(id).then(response => {
          toast.success('Participant deleted successfully.');
          if (search) {
            fetchUserData(search)
          }
          dispatch(setLoadingStatus(false));
        }
        ).catch(error => {
          dispatch(setLoadingStatus(false));
          console.error(error);
        });
      }
    })
  }

  const onPasswordChange = (user) => {
    setUserDetail({ ...user, type });
    setShow(true);
  }

  const onClosePasswordModal = (isRefresh) => {
    setShow(false);
    setTimeout(() => {
      setUserDetail(null);

    }, 200);
    if (isRefresh) {
      fetchUserData(search);
    }
  }

  /**
   * On Click Manage Companies for participant open modal for display associated companies
   * @param {*} participantData 
   */
  const onOpenAssociateCompaniesModal = (participantData) => {
    setAssociateParticipant(participantData);
    setShowAssociateCompanies(true);
  }

  /**
   * On Change participant status from toggle switch
   * @param {*} participantId 
   * @param {*} status 
   */
  const onChangeParticipantStatus = (participantId, status) => {
    dispatch(setLoadingStatus(true));
    dispatch(updateUser(participantId, { active: status })).then(response => {
      dispatch(setLoadingStatus(false));
      toast.success('Status successfully updated.');
      fetchUserData(search);
    }).catch(err => {
      dispatch(setLoadingStatus(false));
      console.log(err);
      toast.error('Something went wrong while update participant.')
    });
  }

  const columns = [
    {
      name: "Name",
      selector: "last_name",
      sortable: true,
      width: '250px',
      grow: 4,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>
              {row.first_name ? (
                <a href={row.profile_photo ? `https://${process.env.REACT_APP_CDN_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}user-avatars/${row.profile_photo}` : "javascript:void(0)"} target={row.profile_photo && "_blank"} className="d-flex align-items-center" >
                  <img src={row.profile_photo ? `https://${process.env.REACT_APP_CDN_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}user-avatars/${row.profile_photo}` : "biopic.png"} style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "5px",
                    marginRight: "-102px;"
                  }} alt="Avatar" class="avatar" />
                  <Link style={{ marginLeft: "10px" }} to={{ pathname: `${path}/view`, state: { id: row.id } }}>
                    {row.last_name + ", " + row.first_name}
                  </Link>
                  {!row.active && !['participant', 'guest_agent'].includes(type) && (
                    <p className="uk-form-danger">Inactive</p>
                  )}
                </a>
              ) : (
                <div style={{ marginLeft: "10px" }}>{['participant', 'guest_agent'].includes(type) ? 'N/A' : 'Invite Sent'}</div>
              )}
            </div>
          </div>
        );
      },
    },
    ...!['participant', 'guest_agent'].includes(type)
      ? [{
        name: "User Role",
        grow: 3,
        cell: (row) => {
          return (
            <div data-tag="allowRowEvents">
              <div>{row.UserInRole?.UserRole?.name}</div>
            </div>
          );
        },
      },
      {
        name: "BROKER",
        selector: "is_broker",
        sortable: true,
        width: '80px',
        grow: 3,
        cell: (row) => {
          return (
            <div data-tag="allowRowEvents">
              <div>{row.is_broker && "Yes"}</div>
            </div>
          );
        },
      },
      {
        name: "EB SIGNS DEAL",
        width: '110px',
        grow: 3,
        cell: (row) => {
          return (
            <div data-tag="allowRowEvents">
              <div>{row.is_deals_office_employing_broker_signs ? "Yes" : "No"}</div>
            </div>
          );
        },
      },
      {
        name: "EB SIGNS LA",
        width: '110px',
        grow: 3,
        cell: (row) => {
          return (
            <div data-tag="allowRowEvents">
              <div>{row.is_listing_office_employing_broker_signs ? "Yes" : "No"}</div>
            </div>
          );
        },
      }
      ]
      : [],
    {
      name: "Email",
      selector: "email",
      grow: 3,
      sortable: true,
      center: false
    },
    {
      name: "Mobile Phone",
      // width: '140px',
      sortable: false,
      selector: "mobile_phone",
      grow: 3,
      center: false,
      cell: row => <>{phoneNumber(row.mobile_phone)}</>
    },
    ...name === 'Boatdox Admin' && !['participant', 'guest_agent'].includes(type)
      ? [{
        name: "Company",
        grow: 3,
        center: true,
        sortable: false,
        cell: (row) => {
          return (
            <div data-tag="allowRowEvents">
              <div>{
                row.company ? row.company.company_name : 'N/A'
              }</div>
            </div>
          )
        }
      }]
      : [],
    ...['participant', 'guest_agent'].includes(type) && name != 'Boatdox Admin'
      ? [{
        name: "Company",
        grow: 3,
        sortable: true,
        selector: "participant_company_name",
        cell: (row) => {
          return (
            <div data-tag="allowRowEvents">
              <div>{row.user?.participant_company_name ? row.user?.participant_company_name : 'N/A'}</div>
            </div>
          )
        }
      }]
      : !['participant', 'guest_agent'].includes(type) ? [{
        name: "Office",
        grow: 3,
        width: '120px',
        sortable: true,
        selector: "office_name",
        cell: (row) => {
          return (
            <div data-tag="allowRowEvents">
              <div>{row.office ? row.office.office_name : ''}</div>
            </div>
          )
        }
      }] : [],
    ...['participant', 'guest_agent'].includes(type) && name == 'Boatdox Admin'
      ? [{
        name: "Status",
        // width: '140px',
        grow: 3,
        sortable: true,
        selector: "active",
        cell: (row) => {
          return (
            <Switch
              checked={row.active}
              onChange={() => { onChangeParticipantStatus(row.id, !row.active) }} />
          )
        }
      }]
      : [],
    {
      name: "",
      grow: 3,
      width: '220px',
      cell: (row) => {
        return (
          <div>
            {!row.is_new_user
              ? <>
                {['Boatdox Admin', 'Company Admin'].includes(loggedUser.UserInRole?.UserRole?.name) &&
                  (type == 'participant'
                    ? <></>
                    : <>
                      <Tooltip title="Update Password" placement="left">
                        <img src="/icons/key.svg" className="uk-margin-right" alt="" onClick={() => onPasswordChange(row)} />
                      </Tooltip>
                      <Link to={{ pathname: `/users/${row.id}/edit`, state: { user_id: row.id, userCompanyId: row.company_id } }}>
                        <Tooltip title="Edit" placement="top">
                          <img src="/icons/edit.svg" className="uk-margin-right svg" alt="" />
                        </Tooltip>
                      </Link>
                    </>
                  )
                }
              </>
              : (type == 'participant' && company_id != row.user?.company_id
                ? <></>
                : <a className="uk-margin-right" onClick={() => onPasswordChange(row)}>Set Password</a>
              )
            }
            {(name === 'Company Admin' && row.UserInRole?.UserRole?.name === "Company Admin") || ['participant', 'guest_agent'].includes(type)
              ? <></>
              : <Tooltip title="Delete User" placement="right">
                <img
                  src="/icons/delete.svg"
                  className="svg"
                  onClick={() => onDelete(row.id)}
                  alt=""
                />
              </Tooltip>
            }
            {(name == 'Boatdox Admin' && ['participant', 'guest_agent'].includes(type)) &&
              <>
                <Tooltip title="View Associated Companies" placement="left">
                  <a className="uk-margin-right" onClick={() => { onOpenAssociateCompaniesModal(row); }}>
                    <img
                      src="/icons/company-participant.svg"
                      className="svg"
                      alt=""
                    />
                  </a>
                </Tooltip>
                {/* code comment for hide the delete button for participant user and will show after backend support */}
                {/* <Tooltip title="Delete Participant" placement="right">
                  <img
                    src="/icons/delete.svg"
                    className="svg"
                    onClick={() => handelDeleteParticipant(row.id)}
                    alt=""
                  />
                </Tooltip> */}
              </>
            }
          </div>
        );
      },
    },
  ];

  const getOfficesByCompany = (company_id) => {
    if (company_id) {
      OfficesService.findByCompany(company_id)
        .then((res) => {
          let offices = res.data;
          offices.sort((a, b) => (b.office_name > a.office_name ? -1 : 1))
          setOfficeList(offices);
        })
        .catch((error) => {
          setOfficeList([]);
        })
      UserService.searchByCompany(company_id)
        .then((res) => {
          let users = res.data;
          users.sort((a, b) => (b.last_name > a.last_name ? -1 : 1))
          setUserList(users);
        })
        .catch((error) => {
          setUserList([]);
        })
      setSearch(search => {
        return {
          ...search,
          company_id: company_id
        }
      })
    } else {
      setOfficeList([]);
      setUserList([])
      setSearch(search => {
        return {
          ...search,
          company_id: null,
        }
      })
    }
  }

  const getUsersByOffice = (office_id) => {
    if (office_id) {
      UserService.getOfficeUsers(office_id)
        .then((res) => {
          let users = res.data;
          users.sort((a, b) => (b.last_name > a.last_name ? -1 : 1))
          setUserList(users);
        })
      setSearch(search => {
        return {
          ...search,
          office_id: office_id
        }
      })
    } else {
      setSearch(search => {
        return {
          ...search,
          office_id: null,
        }
      })
    }

  }

  const getUserById = (user_id) => {
    if (user_id) {
      setSearch((state) => {
        return {
          ...state,
          user_id: user_id
        }
      })
    } else {
      setSearch((state) => {
        return {
          ...state,
          user_id: null
        }
      })
    }
  }


  /**
 * On change category tab and filter by selected category
 * @param {*} event 
 * @param {*} newValue 
 */
  const handleChange = (event, newValue) => {
    setType(newValue);
    setSearch((search) => {
      return {
        isUserParticipantList: newValue == 'participant',
        isGuestBrokerList: newValue == 'guest_agent',
        isGlobalAdmin: name == 'Boatdox Admin',
        page: 1
      }
    })
  };

  useEffect(() => {
    setCurrentVideo(tutorials?.users);
  }, [tutorials]);

  const onCloseVideoPlayerModal = () => {
    setShowVideo(false);
  }

  const renderSearchBox = () => {
    return (
      <div className="list-page-search-container m-1 sm-full-width">
        <form className="uk-search uk-search-default">
          <DebounceInput
            className="uk-search-input"
            type="search"
            placeholder="Search"
            onChange={(e) => setSearch(state => {
              return {
                ...state,
                name: e.target.value
              }
            })}
            value={search ? search?.name : null}
            debounceTimeout={500}
            minLength={1}
            style={{ width: "-webkit-fill-available" }}
          />
          {!search.name || search === "" ? (
            <span className="bd-form-icon">
              <i className="fa fa-search"></i>
            </span>
          ) : (
            <span className="bd-form-icon" onClick={() => setSearch(state => {
              return {
                ...state,
                name: ""
              }
            })}>
              <i className="fa fa-times"></i>
            </span>
          )}
        </form>
      </div>
    )
  }

  return (
    <div className="uk-container uk-container-expand uk-position-relative">
      <div className="content">
        <h1 className="d-flex align-items-center gap-1">
          {currentVideo && <Tooltip title="Play Tutorial" arrow placement='top'  >
            <PlayCircleOutlineIcon fontSize="large" style={{ cursor: "pointer" }} className="mr-1" onClick={() => setShowVideo(true)} />
          </Tooltip>}Users</h1>
        <div className={`${loggedUser.UserInRole.UserRole.name == "Boatdox Admin" && !['participant', 'guest_agent'].includes(type) ? "uk-column-1-4  uk-margin-small" : "uk-column-1-2 "} `}>
          {name === 'Company Admin' && (
            <Link
              to={{ pathname: `${path}/invite` }}
              disabled={name === 'Boatdox Admin'}>
              <div className="uk-button uk-button-default add-deal">
                <span className="uk-margin-small-right uk-icon" data-uk-icon="plus"></span> Invite
                User
              </div>
            </Link>
          )}
          {loggedUser.UserInRole?.UserRole?.name == "Boatdox Admin" && !['participant', 'guest_agent'].includes(type) &&
            <>
              <div className="sm-full-width">
                <select className="uk-select"
                  defaultValue=""
                  onChange={(event) => {
                    getOfficesByCompany(event.target.value)
                    // setAddressFields(event.target.value);
                  }}
                >
                  <option selected value="">Select Company</option>
                  {sortedDataCompanies.map((company) => {
                    return (
                      <option value={company.id} key={company.id}>
                        {`${company.company_name}`}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="sm-full-width">
                <select className="uk-select"
                  defaultValue=""
                  onChange={(event) => {
                    getUsersByOffice(event.target.value)
                  }}
                >
                  <option selected value="">Select Office</option>
                  {officeList.map((office) => {
                    return (
                      <option value={office.id} key={office.id}>
                        {`${office.office_name}`}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="sm-full-width">
                <select className="uk-select"
                  defaultValue=""
                  onChange={(event) => {
                    getUserById(event.target.value)
                    // setAddressFields(event.target.value);
                  }}
                >
                  <option selected value="">Select user</option>
                  {userList.map((user) => {
                    return (
                      <option value={user.id} key={user.id}>
                        {`${user.last_name} ${user.first_name}`}
                      </option>
                    );
                  })}
                </select>

              </div>
            </>
          }

          {name == 'Boatdox Admin' &&
            ['participant', 'guest_agent'].includes(type)
            ? <></>
            : renderSearchBox()
          }
        </div>

        {
          <div className="row user-tabs">
            <div className="col-md-8">
              <Tabs value={type} indicatorColor="primary" onChange={handleChange}>
                <Tab value="users" label="User List" />
                <Tab value="participant" label="Participant List" />
                {name == 'Boatdox Admin' && <Tab value="guest_agent" label="Guest Agent List" />}
              </Tabs>
            </div>
            <div className="col-md-4">
              {['participant', 'guest_agent'].includes(type) && name == 'Boatdox Admin' &&
                renderSearchBox()
              }
            </div>
          </div>
        }

        {type == 'participant'
          ? <ParticipantList searchProps={{ search, setSearch }} type={type} onPasswordChange={onPasswordChange} onDelete={handelDeleteParticipant} participants={participants} totalRows={totalRows} columns={columns} />
          : type == 'guest_agent'
            ? <GuestAgentList searchProps={{ search, setSearch }} agentList={guestAgents} totalRows={totalRows} columns={columns} />
            : <UserList searchProps={{ search, setSearch }} type={type} onPasswordChange={onPasswordChange} onDelete={onDelete} users={users} totalRows={totalRows} fetchUserData={fetchUserData} columns={columns} />}
      </div>
      <ChangeUserPasswordModal show={show} onClose={onClosePasswordModal} user={userDetail} />

      {showAssociateCompanies && <AssociateCompanyForParticipantModal show={showAssociateCompanies} onClose={() => setShowAssociateCompanies(false)} participant={associateParticipant} />}
      {showVideo && <VideoPlayerModal show={showVideo} onClose={onCloseVideoPlayerModal} videoTitle={currentVideo} />}
    </div>
  );
};

export default UsersList;
