import http, { setHeaders } from '../http-common';
import Auth from '../utils/auth';

setHeaders(Auth.getInstance().getAuthToken());

const getAll = () => {
  return http.get("/tutorials");
};
const updateTutorials = (data) => {
  return http.post("/tutorials", data);
};


const TutorialService = {
    getAll,updateTutorials
   
  };
  
  export default TutorialService;
  