import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import clsx from "clsx";
import PageSidebarContainer from "../../../../common/PageSidebarContainer";
import { updateOptionsRequiredFields, updateRequiredFields } from "../../../../../redux/actions/transaction";
import {
  CORPORATE_OWNED,
  ENTITY_AND_OR_ASSIGN,
  JOINT_OWNER,
  LEGAL_AND_OR_ASSIGN,
  LEGAL_OR_ASSIGN,
  LEGAL_OWNER,
  LLC_OWNED,
  OWNER_OF_RECORD,
  RECORD_OWNER,
  TRUST_OWNED,
} from "../../../../../utils/signer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const USCGBillStep = () => {
  let location = useLocation();
  let { id } = useParams();
  const dataTransaction = useSelector((state) => state.transactions.transaction);

  let branch = location.pathname.split("/").pop();

  const history = useHistory();

  return (
    <PageSidebarContainer type="add-deal">
      <div className="sidebar-container add-deal">
        <ul className="uk-nav uk-nav-default tm-nav">
          {/* <Link to={`/deals/view/${id}/uscg-bill-of-sale/send_to_signer`}>
            <li className={clsx("add-deal chain-step", { current: branch === "send_to_signer" })}>
              <i className="fa fa-circle-o"></i>
              <span className="add-deal-step">Send for eSignature</span>
            </li>
          </Link> */}

          {
            dataTransaction?.id &&
            // <li
            //   name="back-to-eDocuments"
            //   id="tab-back-to-eDocuments"
            //   className={clsx("add-deal chain-step")}
            // >
            //   <Link to={`/deals/view/${dataTransaction.id}/main#tab=%22edocuments%22`}> <span uk-icon="chevron-double-left"></span> Back to eDocuments</Link>
            // </li>
            <button className="uk-button uk-button-primary mt-2" onClick={() => history.push(`/deals/view/${dataTransaction.id}/main#tab=%22edocuments%22`)}>
              <span uk-icon="chevron-double-left"></span>Back to eDocuments
            </button>

          }

        </ul>
      </div>
    </PageSidebarContainer>
  );
};

export default USCGBillStep;
