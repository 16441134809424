import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import clsx from "clsx";
import PageSidebarContainer from "../../../../common/PageSidebarContainer";
import { updateOptionsRequiredFields } from "../../../../../redux/actions/transaction";
import { useQueryParams } from "../../../../../utils/query-params";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ExclusionStep = () => {
  let location = useLocation();
  let { id } = useParams();
  const dispatch = useDispatch();
  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const [asterix, setAsterix] = useState("empty");

  const history = useHistory();

  const dataDeal = dataTransaction || {};
  const dataExclusion = dataDeal?.t_purchase_exclusion || [];
  const query = useQueryParams();

  const totalExclusionInputs = document.getElementsByClassName("exclusion")?.length || 0;
  const blankExclusionStr = Array(totalExclusionInputs).fill().map((v, i) => "/:/").join("").slice(0, -3);

  useEffect(() => {
    const exclusion = dataExclusion.find(el => el.id === query.get('doc_id'));
    if (exclusion?.exclusion && !["", blankExclusionStr].includes(exclusion?.exclusion)) {
      setAsterix("fulfill");
    } else {
      setAsterix("empty");
    }
    registerRequiredFields(exclusion);
  }, [dataExclusion, query]);

  const registerRequiredFields = (exclusion) => {
    if ((exclusion?.exclusion) && !["", blankExclusionStr].includes(exclusion?.exclusion)) {
      dispatch(
        updateOptionsRequiredFields({
          exclusion: [],
        })
      );
    } else {
      dispatch(
        updateOptionsRequiredFields({
          exclusion: [{ exclusion_1: "Exclusion #1" }],
        })
      );
    }
  };

  let branch = location.pathname.split("/").pop();

  return (
    <PageSidebarContainer type="add-deal">
      <div className="sidebar-container add-deal">
        <ul className="uk-nav uk-nav-default tm-nav">
          <Link to={`/deals/view/${id}/purchase-&-sale-agreement-exclusion?doc_id=${query.get('doc_id')}`}>
            <li
              className={clsx("add-deal chain-step", {
                current: branch === "purchase-&-sale-agreement-exclusion",
              })}
            >
              <i
                className={clsx(
                  "fa",
                  { "fa-check-circle stepbar": asterix === "fulfill" },
                  { "fa-circle-o": asterix === "empty" || !asterix }
                )}
              />
              <span className="add-deal-step">Exclusion</span>
            </li>
          </Link>
          {/* <Link to={`/deals/view/${id}/purchase-&-sale-agreement-exclusion/send_to_signer?doc_id=${query.get('doc_id')}`}>
            <li className={clsx("add-deal chain-step", { current: branch === "send_to_signer" })}>
              <i className="fa fa-circle-o"></i>
              <span className="add-deal-step">Send for eSignature</span>
            </li>
          </Link> */}

          {
            dataTransaction?.id &&
            // <li
            //   name="back-to-eDocuments"
            //   id="tab-back-to-eDocuments"
            //   className={clsx("add-deal chain-step")}
            // >
            //   <Link to={`/deals/view/${dataTransaction.id}/main#tab=%22edocuments%22`}> <span uk-icon="chevron-double-left"></span> Back to eDocuments</Link>
            // </li>
            <button className="uk-button uk-button-primary mt-2" onClick={() => history.push(`/deals/view/${dataTransaction.id}/main#tab=%22edocuments%22`)}>
              <span uk-icon="chevron-double-left"></span>Back to eDocuments
            </button>

          }

        </ul>
      </div>
    </PageSidebarContainer>
  );
};

export default ExclusionStep;
