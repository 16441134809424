import { Helmet } from "react-helmet";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import EditPage from "./Edit";
import ListPage from "./List/index";
import ViewPage from "./View/index";

const DealPage = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <Helmet>
        <title>Deals - BoatDox</title>
      </Helmet>
      <Switch>
        <Route exact path={path} component={ListPage} />
        <Route path={`${path}/view/:id`} component={ViewPage} />
        <Route path={`${path}/add/:id`} component={EditPage} />
        <Redirect to={path} />
      </Switch>
    </>
  );
};

export default DealPage;
