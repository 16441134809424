import GridForm from "../../../../common/GridForm";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import { phoneNumber } from "../../../../../utils/phonenumber";
import { getPrice } from "../../../../../utils/currency";

const DetailOfCoBroker = ({ cobroker, offers }) => {
  return (
    <>
      <GridForm>
        <FormRow>
          <FormInputWrapper label="Selling Price">
            <div className="input-view">
              {offers
                ? `${offers.purchase_price ? `${getPrice(offers.purchase_price)}` : "N/A"}`
                : "N/A"}
            </div>
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Gross Commision">
            <div className="input-view">{`${
              cobroker
                ? `${cobroker.gross_commission_percent || "N/A"}% / ${getPrice(
                    cobroker.gross_commission_amount
                  )}`
                : "N/A"
            }`}</div>
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper size="three" label="Additional Terms/Comments">
            <div className="input-view">
              {cobroker ? `${cobroker.additional_terms_comments || "N/A"}` : "N/A"}
            </div>
          </FormInputWrapper>
        </FormRow>
      </GridForm>
      <FormRow>
        <h3>Selling Broker</h3>
        <GridForm>
          <FormRow>
            <FormInputWrapper label="Name">
              <div className="input-view">
                {cobroker ? cobroker.selling_brokerage_broker || "N/A" : "N/A"}
              </div>
            </FormInputWrapper>
            <FormInputWrapper label="Selling Brokerage">
              <div className="input-view">
                {cobroker ? cobroker.selling_brokerage || "N/A" : "N/A"}
              </div>
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Mobile Phone">
              <div className="input-view">
                {cobroker ? `${phoneNumber(cobroker.selling_broker_phone_number) || "N/A"}` : "N/A"}
              </div>
            </FormInputWrapper>
            <FormInputWrapper label="Email">
              <div className="input-view">
                {
                  cobroker 
                  ? (
                    cobroker.selling_broker_email 
                    ? <a href={`mailto:${cobroker.selling_broker_email}`} target="_blank">{cobroker.selling_broker_email}</a>
                    : "N/A"
                  ) 
                  : "N/A"
                }
              </div>
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Selling  % / $">
              <div className="input-view">
                {cobroker
                  ? `${cobroker.selling_brokerage_percent || 0}% / ${getPrice(
                      cobroker.selling_brokerage_amount
                    )}`
                  : "N/A"}
              </div>
            </FormInputWrapper>
          </FormRow>
        </GridForm>
      </FormRow>
      <FormRow>
        <h3>Listing Broker</h3>
        <GridForm>
          <FormRow>
            <FormInputWrapper label="Name">
              <div className="input-view">
                {cobroker ? `${cobroker.listing_brokerage_broker || "N/A"}` : "N/A"}
              </div>
            </FormInputWrapper>
            <FormInputWrapper label="Listing Brokerage">
              <div className="input-view">
                {cobroker ? cobroker.listing_brokerage || "N/A" : "N/A"}
              </div>
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Mobile Phone">
              <div className="input-view">
                {cobroker ? `${phoneNumber(cobroker.listing_broker_phone_number) || "N/A"}` : "N/A"}
              </div>
            </FormInputWrapper>
            <FormInputWrapper label="Email">
              <div className="input-view">
                {cobroker ? cobroker.listing_broker_email || "N/A" : "N/A"}
              </div>
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Listing  % / $">
              <div className="input-view">
                {cobroker
                  ? `${cobroker.listing_brokerage_percent || 0}% / $${getPrice(
                      cobroker.listing_brokerage_amount
                    )}`
                  : "N/A"}
              </div>
            </FormInputWrapper>
          </FormRow>
        </GridForm>
      </FormRow>
    </>
  );
};

export default DetailOfCoBroker;
