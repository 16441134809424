const manufacturersList = `5 Starr Trailer Co., Inc.
Advanced Marine Performance, LLC dba Vexus Boats
Amera Trail
B & M Manufacturing Company
BackTrack Trailers
Bass Cat Boats
Bear Trailer Manufacturing, Inc.
BoatMate Trailers, LLC
Brownell Boat Stands
Cequent Trailer Products
Continental Trailer
Con-O-Lift By Kropf Industrial Inc.
Coyote Manufacturing Company
Diamond City Trailers Inc.
Eagle Trailers
EZ Loader Boat Trailers, Inc.
EZ Loader Custom Boat Trailers Inc.
Float-On Corp.
Gateway Materials
Heritage Custom Trailers
HOSTAR Marine Transport Systems, Inc.
J.Q. Tex, Inc.
JDCI Enterprises
Karavan Trailers, Inc.
King Trailers, Inc.
Kropf Industrial Inc.
Load Rite Trailers, Inc.
Loadmaster Trailer Company, LTD.
McClain
MYCO Trailers
Magic Tilt Trailers, Inc.
Malibu Boats LLC - Trailers
Marine Venture Enterprises dba Venture Trailers
MasterCraft Boat Company
Midwest Industries, Inc
Nationwide Trailer Company
Performance Trailers, Inc.
Ram-Lin Custom Trailers, Inc
Rec Boat Holdings, LLC
REMEQ Inc.
Road King Trailers, Inc.
Rock N Roll
Rocket Trailers Corporation
Rogue Jet Boatworks, Inc.
SC Trailers
Skeeter Boats, Inc/A Yamaha Boat Company
Sweetwater Metal Products, Inc.
Tebben Enterprises Inc.
Tennessee Trailers, Inc.
Tidewater Trailers
Trail Rite, Inc.
Triad
V/M Custom Boat Trailers
Venture Trailers, Inc.
Waterland Manufacturing, Inc.
White River Marine Group - Ozark
Yacht Club Trailers
Zieman Manufacturing Division of Lippert Components, Inc.
`


export default manufacturersList