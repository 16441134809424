import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { getAllTransactions, updateTransaction } from "../../redux/actions/transaction";
import { toast } from "react-toastify";
import { setLoadingStatus } from "../../redux/actions/loading";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { dateFormatter } from "../../utils/common";


const SoldDateModel = ({ componentsId, type, data, isOpen, onClose, user, search }) => {

    const dispatch = useDispatch();
    const [soldDate, setSoldDate] = useState(moment(data?.sold_date || new Date()).utc().format("yyyy-MM-DD"));

    const handleSoldDate = (soldDate) => {
        setSoldDate(soldDate);
    }

    const handleSubmit = () => {
        dispatch(setLoadingStatus(true))
        dispatch(updateTransaction(componentsId, { sold_date: soldDate, status: "completed" })).then(() => {
            dispatch(getAllTransactions(1, 5, type, user, search)).then((res) => {
                dispatch(setLoadingStatus(false))
                toast.info("Sold date updated successfully", {
                    autoClose: 2000,
                });
            }).catch((error) => {
                throw error;
            });
            onClose();
        }).catch((error) => {
            toast.error("Error occur, please try again", {
                autoClose: 2000,
            });
        })
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: "#fff",
        border: '2px solid #000',
        boxShadow: 24,
        p: 0,
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isOpen}
            onClose={onClose}
            closeAfterTransition
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Box sx={style}>
                <div className="uk-modal-header">
                    <h2 className="uk-modal-title">Status</h2>
                </div>
                <div className="uk-modal-body">
                    <label className="uk-form-label" htmlFor="name">Date Sold</label>
                    <DatePicker
                        className="uk-input"
                        style={{ cursor: "pointer", color: "#000" }}
                        format={'MM/DD/YYYY'}
                        value={soldDate && dayjs(soldDate)}
                        onChange={(date, dateString) => {
                            handleSoldDate(dateFormatter(dateString));
                        }}
                    />
                </div>
                <div className="uk-modal-footer uk-text-right">
                    <button value="Submit" className="uk-button uk-button-primary" type="button"
                        onClick={onClose} >Cancel</button>
                    <button value="Submit" className="uk-button uk-button-primary" type="button"
                        onClick={handleSubmit} >Submit</button>
                </div>
            </Box>
        </Modal>
    );
};

export default SoldDateModel;