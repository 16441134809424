import {
  CREATE_COMPANY,
  RETRIEVE_COMPANIES,
  GET_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY,
  DELETE_ALL_COMPANIES,
  FIND_COMPANY_BY_NAME,
} from "../actions/types";

const initialState = {
  companies: [],
  company: {},
  associations: []
};

function companyReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_COMPANY:
      return {
        ...state,
        company: payload
      };
    case RETRIEVE_COMPANIES:
      return {
        ...state,
        companies: payload
      }
    case GET_COMPANY:
      return {
        ...state,
        company: payload
      }
    case UPDATE_COMPANY:
      const new_companies = state.companies.map((company) => {
        if (company.id === payload.id) {
          return {
            ...company,
            ...payload,
          };
        } else {
          return company;
        }
      });
      return {
        ...state,
        companies: new_companies
      };
    case DELETE_COMPANY:
      const companies = state.companies.filter(({ id }) => id !== payload.id)
      return {
        ...state,
        companies: companies
      };
    case DELETE_ALL_COMPANIES:
      return {
        ...state,
        companies: []
      };
    case FIND_COMPANY_BY_NAME:
      return {
        ...state,
        companies: payload
      }
    default:
      return state;
  }
}

export default companyReducer;
