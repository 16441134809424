import moment from "moment";

const ESignSuccess = (props) => {

  const propData = props.location.state;
  const timezone = moment.tz(moment.tz.guess()).zoneAbbr();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' ,textAlign:"center"}}>
      <h1>{propData.userData?.request_signer?.name}</h1>
      <h2>Thank you for signing the document. We have recorded your signature.</h2>
      <h5 style={{ fontWeight: 500 }}>{propData.userData?.request_signer?.email_address}</h5>
      <h5 style={{ fontWeight: 500, marginTop: 0 }}>{propData.userData?.signed_from_ip}</h5>
      <h5 style={{ fontWeight: 500, marginTop: 0 }}>{propData.userData?.esigned_date ? `${moment(propData.userData?.esigned_date).local().format('MM/DD/YYYY h:mm A')} ${timezone}` : ''}</h5>
      {/* <h5 style={{ fontWeight: 500, marginTop: 0 }}>{propData.userData?.esigned_date ? moment(propData.userData?.esigned_date).format('h:mm A') : ''}</h5> */}
    </div>
  );
};

export default ESignSuccess;
