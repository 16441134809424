import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormPage from "../../../../common/FormPage";
import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import AsterixTypo from "../../../../common/AsterixTypo";
import SubmitButton from "../../../../common/buttons/SubmitButton";
import LinkButton from "../../../../common/buttons/LinkButton";
import {
  getTransactionInitialById,
  upsertTransaction,
} from "../../../../../redux/actions/transaction";
import { getFormattedData, getPlainTransactionData } from "../../../../../utils/stepFilter";

const schema = yup.object().shape({
  ammendment: yup.string(),
});

const AddAddendum = ({ step, setStep }) => {
  const dispatch = useDispatch();

  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);
  const dataDeal = dataTransaction || {};
  const dataAddendum = dataDeal.t_addendum;

  const keys = ["ammendment_1", "ammendment_2", "ammendment_3", "ammendment_4", "ammendment_5"];

  useEffect(() => {
    const ammendment = dataAddendum?.ammendment;
    if (ammendment) {
      ammendment.split("/:/").forEach((str, index) => {
        setValue(`ammendment_${index + 1}`, str);
      });
    }
  }, [dataAddendum]);

  /* Form Validation */
  const { register, handleSubmit, setValue, getValues } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const handleRegisteration = (ammendment) => {
    let dataStr = "";
    ammendment = getFormattedData(ammendment);
    keys.forEach((key) => {
      dataStr = dataStr.concat(ammendment[key] || "", "/:/");
    });
    dataStr = dataStr.slice(0, -3);
    ammendment.ammendment = dataStr;
    if (dataAddendum && dataAddendum.id) {
      ammendment.id = dataAddendum.id;
    }
    dispatch(
      upsertTransaction({
        step: {
          db: "ammendment",
          main: ammendment,
        },
        transaction: {
          isBelonged: false,
          main: {
            ...getPlainTransactionData(dataDeal),
            task_type: "deal",
            broker_user_id: dataBroker?.id,
            company_id: dataBroker?.company_id,
            company_office_id: dataBroker?.company_office_id,
          },
        },
      }, "Ammendment's")
    ).then((data) => {
      dispatch(getTransactionInitialById(data.id));
      setStep(step + 1);
    });
  };

  const handleError = () => {
    setStep(step + 1);
  };

  const links = [
    { label: "Deals", link: "/deals" },
    { label: "Add a Deal", link: null },
  ];

  return (
    <FormPage links={links} title="Addendum">
      <Form onSubmit={handleSubmit(handleRegisteration, handleError)}>
        <FormRow>
          <FormInputWrapper label="Provision #1" size="two">
            <textarea type="text" className="uk-input" {...register("ammendment_1")} />
          </FormInputWrapper>
          <FormInputWrapper label="Provision #2" size="two">
            <textarea type="text" className="uk-input" {...register("ammendment_2")} />
          </FormInputWrapper>
          <FormInputWrapper label="Provision #3" size="two">
            <textarea type="text" className="uk-input" {...register("ammendment_3")} />
          </FormInputWrapper>
          <FormInputWrapper label="Provision #4" size="two">
            <textarea type="text" className="uk-input" {...register("ammendment_4")} />
          </FormInputWrapper>
          <FormInputWrapper label="Provision #5" size="two">
            <textarea type="text" className="uk-input" {...register("ammendment_5")} />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <SubmitButton>Save & Continue</SubmitButton>
          <LinkButton to="/deals" onClick={() => handleRegisteration(getValues())}>
            Save & Finish Later
          </LinkButton>
        </FormRow>
        <AsterixTypo isVisible={false} />
      </Form>
    </FormPage>
  );
};

export default AddAddendum;
