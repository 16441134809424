import { SET_LOADING_STATUS } from "./types";

export const setLoadingStatus = (status, msg = "") => async (dispatch) => {
  try {
    dispatch({
      type: SET_LOADING_STATUS,
      payload: { status, msg },
    });
  } catch (err) {
    console.log("Error = ", err);
  }
};
