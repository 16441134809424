import { confirmAlert } from "react-confirm-alert";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_MODAL_MESSAGE, DELETE_MODAL_TITLE } from "../../../../../constants/Alert";
import datatableStyles from "../../../../../utils/datatable-style";
import { updateTransactionVesselOrToy, updateVesselOrToy } from "../../../../../redux/actions/vessels_toys";
import { removeToyFromTransaction } from "../../../../../redux/actions/transaction";
import { Link } from "react-router-dom";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import { getPrice } from "../../../../../utils/currency";
import { toast } from "react-toastify";

const ToyTable = ({ fromPanel = false, onEdit = null }) => {

  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const dataDeal = dataTransaction || {};
  const data = dataDeal.t_toys || [];
  const dispatch = useDispatch();

  const onDelete = (id) => {
    confirmAlert({
      title: DELETE_MODAL_TITLE,
      message: DELETE_MODAL_MESSAGE,
      overlayClassName: "title-overlay-custom-class",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const data = {
              deleted: true,
              deleted_at: new Date(),
            };
            dispatch(setLoadingStatus(true));
            dispatch(updateTransactionVesselOrToy(id, data))
              .then(() => {
                dispatch(setLoadingStatus(false));
                dispatch(removeToyFromTransaction(id))
                .then((res) => {
                  toast.info("Deleted successfully", {
                      autoClose: 2000,
                  });
              })
              .catch((e) => {
                  toast.error("Error occur. Please try again", {
                      autoClose: 2000,
                  });
              });
              }).catch((e) => {
                dispatch(setLoadingStatus(false));
                console.log("Error = ", e);
              });
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const totalPurchasePrice = () => {
    const additional_price = data.reduce(
      (total, toy) => total + parseFloat(toy.item_value),
      0
    );
    return additional_price;
  }

  const columns = [
    {
      name: "Vessel",
      selector: "vessel",
      cell: (row) => {
        let vesselName = `${row.t_vessel?.length || ""}' ${row.t_vessel?.make || ""} ${row.t_vessel?.model || ""
          } ${row.t_vessel?.year || ""}`;
        vesselName = vesselName === "'   " ? "N/A" : vesselName;
        return (
          <div data-tag="allowRowEvents">
            <div>{vesselName}</div>
          </div>
        );
      },
    },
    {
      name: "Hull Number",
      selector: "hull_number",
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>{row.t_vessel?.hull_number || "N/A"}</div>
          </div>
        );
      },
    },
    {
      name: "Vessel Name",
      selector: "vessel_name",
      textAlign: "right",
      cell: (row) => {
        return (
          <div data-tab="allowRowEvents">
            <div>{row.t_vessel?.vessel_name || "N/A"}</div>
          </div>
        );
      },
    },
    {
      name: "Price",
      selector: "item_value",
      textAlign: "right",
      cell: (row) => {
        return (
          <div data-tab="allowRowEvents">
            <div>{row.item_value ? getPrice(row.item_value) : "N/A"}</div>
          </div>
        );
      },
    },
    {
      name: "Action",
      center: false,
      textAlign: "right",
      cell: (row) => {
        return (
          <div>
            {fromPanel
              ? <img src="/icons/edit.svg" onClick={() => onEdit(row.t_vessel?.id, row?.id)} className="svg uk-margin-right" alt="" />
              : <Link to={{ pathname: `/deals/add/${dataDeal.id}/toys/edit`, state: { toy: row } }}>
                <img src="/icons/edit.svg" className="svg uk-margin-right" alt="" />
              </Link>
            }
            <img
              src="/icons/delete.svg"
              className="svg"
              onClick={() => onDelete(row.t_vessel?.id)}
              alt=""
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <DataTable
        title="Toys & Tenders"
        columns={columns}
        data={data}
        striped={true}
        highlightOnHover={true}
        pointerOnHover={true}
        center={true}
        noHeader={true}
        fixedHeader={true}
        customStyles={datatableStyles}
        refresh={true}
      />
      {(fromPanel && totalPurchasePrice() > 0) &&
        <div className="text-right">
          <div style={{ color: '#092540', fontSize: '17px', fontWeight: 500 }}>Total Price: {getPrice(totalPurchasePrice())}</div>
        </div>
      }
    </>
  );
};

export default ToyTable;
