import http, { setHeaders } from '../http-common';
import Auth from '../utils/auth';

setHeaders(Auth.getInstance().getAuthToken() + ' ' + Auth.getInstance().getIDToken());


const documentUploadUrl = (key, type, transactionId) => {
  return http.get(`/s3/upload-url?key=${key}&type=${type}&transactionId=${transactionId || ""}`);
};

const uploadImageCDN = (fileKey, uploadFolderStructure) => {
  return http.get(`/s3/cdn-upload-url?key=${fileKey}&type=${uploadFolderStructure}`);
}

const uploadFormsUrl = (fileKey, FormsFolderName) => {
  return http.get(`/s3/form-upload-url?key=${fileKey}&type=${FormsFolderName}`);
}

const downloadDocument = (fileName, transactionId) => {
  return http.get(`/s3/users/uploads-document-download?name=${fileName}&transactionId=${transactionId || ""}`, { responseType: 'blob' });
}

const uploadFileAllModules = (moduleType, uniqueId = null, data = null, companyUID = null) => {
  return http.post(`/s3/file-upload-server?type=${moduleType}&uniqueId=${uniqueId || ""}&companyUniqueId=${companyUID || ""}`, data);
}
const getUploadFolders = () => {
  return http.get(`/s3/upload-folder-list`,);
}
const getUploadFiles = (folder) => {
  return http.get(`/s3/file-list?path=${folder}`,);
}
export const uploadPdfCDN = async (fileKey, uploadFolderStructure) => {
  return http.get(`/s3/pdf-upload-url?key=${fileKey}&type=${uploadFolderStructure}`);
}

const S3Service = {
  uploadImageCDN,
  documentUploadUrl,
  downloadDocument,
  uploadFormsUrl,
  uploadPdfCDN,
  uploadFileAllModules,
  getUploadFolders,
  getUploadFiles
};

export default S3Service;
