import { React } from "react";
import { useForm } from "react-hook-form";
import { useCookies } from 'react-cookie';
import { makeStyles } from '@mui/styles';
// import { createTheme } from '@mui/material/styles';
import {
  Avatar, Button, CssBaseline, TextField,
  FormControlLabel, Checkbox, Link, Grid,
  Box, Typography, Container, createTheme
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

const schema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup
    .string()
    .email()
    .required("Email is a required field"),
  password: yup
    .string()
    .required("Please enter your password")
    .matches(
      /^.*(?=.{8,})((?=.*\d){1})((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, and one number"
    ),
  phone_number: yup
    .string()
    .required('Phone number is required')
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'Phone number is not valid'
    )
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();
const useStyles = makeStyles({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#0063A6 !important'
  },

  typo: {
    color: '#0063A6 !important'
  }
});

const SignupForm = (props) => {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(['register-check-privacy']);

  const { 'register-check-privacy': checkPrivacy } = cookies;

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: {phone_number: ''}
  });
  const onSubmit = (data) => {
    
  };

  const handleCheckPrivacy = (e) => {
    setCookie('register-check-privacy', e.target.checked, {path: "/"});
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="first_name"
                variant="outlined"
                required
                fullWidth
                id="first_name"
                label="First Name"
                autoFocus
                {...register("first_name")}
              />
              <p>{errors.first_name?.message}</p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="last_name"
                label="Last Name"
                autoComplete="last_name"
                {...register("last_name")}
              />
              <p>{errors.last_name?.message}</p>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                {...register("email")}
              />
            </Grid>
            <Grid item xs={12}>
              <input
                type="hidden"
                required
                name="phone_number"
                {...register("phone_number")}
              />

              <PhoneInput
                placeholder='+'
                country={'us'}
                // value={this.state.phone}
                onChange={phone => { setValue('phone_number', phone, { shouldValidate: true }) }}
              />
              <p>{errors.phone_number?.message}</p>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                {...register("password")}
              />
              <p>{errors.password?.message}</p>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
              <FormControlLabel
                control={<Checkbox color="primary" onChange={ handleCheckPrivacy } 
                checked={ checkPrivacy == "true" ? true : false } />}
                label={
                  <span>I agree to the  
                    <Link href="/terms" target="_blank"> Terms of Service </Link> 
                    and 
                    <Link href="/privacy" target="_blank"> Privacy Policy</Link>
                  </span>
                }
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={ checkPrivacy == "true" ? false : true }
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/auth/login" variant="body2" className={classes.typo}>
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default SignupForm;