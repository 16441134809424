const ModalBody = ({ title, styleClass = "uk-modal-body", children }) => {
  return (
    <div className={styleClass}>
      <h3>{title}</h3>
      {children}
    </div>
  );
};

export default ModalBody;
