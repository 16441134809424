import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createClient, updateClient } from "../../redux/actions/clients";
import { Link, useHistory } from "react-router-dom";

import { Typography } from '@mui/material';

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import clsx from "clsx";

import EDocService from "../../services/EDocService";
import { createEDoc, updateEDoc } from "../../redux/actions/edoc_templates";
import { setLoadingStatus } from "../../redux/actions/loading";

const schema = yup.object().shape({
  name: yup.string().required(),
  document_type: yup.string().required(),
  description: yup.string(),
  hellosign_template_id: yup.string().required(),
});

const EDocForm = (props) => {
  const { doc_id } = props.location.state;
  const isAddMode = !doc_id;
  const docTypes = ['Deal', 'Listing'];
  const history = useHistory();
  const [isFinishSubmit, setFinishSubmit] = useState(false);
  const dispatch = useDispatch();
  const fields = [
    "name",
    "document_type",
    "description",
    "hellosign_template_id"
  ];

  useEffect(() => {
    if (!isAddMode) {
      EDocService.get(doc_id).then((doc) => {
        fields.forEach((field) => setValue(field, doc.data[field]));
      });
    }
  }, []);

  /* Form Validation */
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  /* Handle Function for From Inputs */
  const handleRequest = (doc) => {
    dispatch(setLoadingStatus(true));
    if (isAddMode) {
      dispatch(createEDoc(doc))
        .then(() => {
          setFinishSubmit(true);
          dispatch(setLoadingStatus(false));
          setTimeout(() => {
            history.push("/edoc-templates");
          }, 2000);
        })
        .catch((e) => {
          dispatch(setLoadingStatus(false));
          alert(e.message);
        });
    } else {
      dispatch(updateEDoc(doc_id, doc)).then(() => {
        dispatch(setLoadingStatus(false));
        setFinishSubmit(true);
        setTimeout(() => {
          history.push("/edoc-templates");
        }, 2000);
      });
    }
  };

  const handleError = (errors) => {
    console.log("Errors = ", errors);
  };

  return (
    <div className="uk-container page">
      <ul className="uk-breadcrumb">
        <li>
          <Link to={"/edoc-templates"}>eDocument Templates</Link>
        </li>
        <li className="uk-disabled">
          {isAddMode ? "Add a template" : "Edit a template"}
        </li>
      </ul>

      <Typography variant="h6" component="h1">{isAddMode ? "Add eDocument Template" : "Edit eDocument Template"}</Typography>

      {isFinishSubmit ? (
        isAddMode ? (
          <div className="uk-alert uk-alert-primary" data-uk-alert>
            <p>Added Successfully</p>
          </div>
        ) : (
          <div className="uk-alert uk-alert-primary" data-uk-alert>
            <p>Updated Successfully</p>
          </div>
        )
      ) : undefined}

      <div className="form-container uk-margin-small">
        <form
          onSubmit={handleSubmit(handleRequest, handleError)}
          className="uk-grid-small form"
          data-uk-grid
          id="addform"
          method="POST"
          onReset={reset}
        >
          <div className="uk-width-1-3@s">
            <label className="uk-form-label">Template Name*</label>
            <input
              className={clsx("uk-input", {
                "uk-form-danger": errors.name,
              })}
              type="text"
              id="name"
              {...register("name")}
            />
          </div>
          <div className="uk-width-1-3@s">
            <label className="uk-form-label">Document Type*</label>
            <select
              className="uk-select"
              {...register("document_type")}
            >
              {docTypes.map((type, index) => {
                return (
                  <option value={type} key={index}>
                    {type}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="uk-width-1-3@s">
            <label className="uk-form-label">HelloSign Template ID*</label>
            <input
              className={clsx("uk-input", {
                "uk-form-danger": errors.hellosign_template_id?.message,
              })}
              type="text"
              {...register("hellosign_template_id")}
            />
          </div>
          <div className="uk-width-1-3@s">
            <label className="uk-form-label">Description</label>
            <input
              className="uk-input"
              type="text"
              {...register("description")}
            />
          </div>
          <div className="uk-width-1-3@s"></div>
          <div className="uk-width-1-3@s"></div>
          <div className="uk-width-1-3@s uk-column-1-1">
            <input
              type="submit"
              className="uk-button iy-button-primary"
              value={isAddMode ? "Add" : "Save"}
            />
            <Link to={"/edoc-templates"}>
              <button className="uk-button uk-button-default" id="add-cancel">
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EDocForm;
