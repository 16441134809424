import http, { setHeaders } from '../http-common';
import Auth from '../utils/auth';

setHeaders(Auth.getInstance().getAuthToken());

const getAll = () => {
  return http.get("/offices");
};

const get = (id) => {
  return http.get(`/offices/${id}`);
};

const create = (data) => {
  return http.post("/offices", data);
};

const update = (id, data) => {
  return http.put(`/offices/${id}`, data);
};

const remove = (id) => {
  return http.delete(`/offices/${id}`);
};

const removeAll = () => {
  return http.delete("/offices");
};

const findByName = (name) => {
  return http.post("/offices/searchByName", name);
};

const getAdmin = (id) => {
  return http.get(`/offices/${id}/admin`);
}

const getClosingAgent = (id) => {
  return http.get(`/offices/${id}/closing_agent`);
}

const retrieveMlsOffices = (id) => {
  return http.get(`/offices/retrieveMlsOffices/${id}`);
}

const findByCompany = (id) => {
  return http.get(`/offices/searchByCompany/${id}`);
}

const getByOfficeId = (companyId, officeId) => {
  return http.get(`/offices/searchByOfficeAndCompanyId/${companyId}/${officeId}`);
}

const OfficesService = {
  getAll,
  getByOfficeId,
  retrieveMlsOffices,
  get,
  create,
  update,
  remove,
  removeAll,
  findByName,
  getAdmin,
  getClosingAgent,
  findByCompany
};

export default OfficesService;
