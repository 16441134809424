import GridForm from "../../../../common/GridForm";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import Country from "../../../../../constants/Country";
import State from "../../../../../constants/StatesForUSA";
import { phoneNumber } from "../../../../../utils/phonenumber";

const DetailOfCoBuyer = ({ cobuyer }) => {
  let cobuye = cobuyer || {};

  let name = `${cobuye.first_name || ""} ${cobuye.last_name || ""}`;
  let address = `${cobuye.address_1 || ""} ${cobuye.address_2 || ""} ${cobuye.address_3 || ""}`;
  address = address.replace(/ /g, "") ? address : "N/A";

  let unit = cobuye.unit_apt || "N/A";
  let city_state_zip = `${cobuye.city || ""} ${
    cobuye.state ? State.find((s) => s.code === cobuye.state)?.name || "" : ""
  } ${cobuye.postal_code || ""}`;
  city_state_zip = city_state_zip.replace(/ /g, "") ? city_state_zip : "N/A";
  let country = cobuye.country ? Country.find((c) => c.code === cobuye.country)?.name || "" : "";

  return (
    <GridForm>
      <FormRow>
        <FormInputWrapper label="Name">
          <div className="input-view">{name}</div>
        </FormInputWrapper>
        <FormInputWrapper label="Entity Name">
          <div className="input-view">{`${
            cobuyer ? `${cobuyer.entity_name || "N/A"}` : "N/A"
          }`}</div>
        </FormInputWrapper>
        {/* <FormInputWrapper label="And/Or Assigns">
          <div className="input-view">{cobuyer ? cobuyer.and_or_assigns : "False"}</div>
        </FormInputWrapper> */}
      </FormRow>
      <FormRow>
        <FormInputWrapper size="two" label="Email *">
          <div className="input-view">
            {
              cobuyer 
              ? (
                cobuyer.email 
                ? <a href={`mailto:${cobuyer.email}`} target="_blank">{cobuyer.email}</a>
                : "N/A"
              ) 
              : "N/A"
            }
          </div>
        </FormInputWrapper>
        <FormInputWrapper label="Mobile Phone">
          <div className="input-view">
            {cobuyer ? (cobuyer.mobile_phone ? phoneNumber(cobuyer.mobile_phone) : "N/A") : "N/A"}
          </div>
        </FormInputWrapper>
        <FormInputWrapper label="Citizen Of">
          <div className="input-view">
            {cobuyer ? (cobuyer.nationality ? cobuyer.nationality : "N/A") : "N/A"}
          </div>
        </FormInputWrapper>
      </FormRow>
      <FormRow>
        <FormInputWrapper size="three" label="Address">
          <div className="input-view">{address}</div>
          <div className="input-view">{unit}</div>
          <div className="input-view">{city_state_zip}</div>
          <div className="input-view">{country}</div>
        </FormInputWrapper>
      </FormRow>
    </GridForm>
  );
};

export default DetailOfCoBuyer;
