import Auth from "../utils/auth";

export function isboatdoxAdmin() {
  const loggedUser = Auth.getInstance().getUserData();
  return loggedUser && loggedUser.UserInRole.UserRole.name === 'Boatdox Admin';
}

export function isCompanyAdmin() {
  const loggedUser = Auth.getInstance().getUserData();
  return loggedUser && loggedUser.UserInRole.UserRole.name === 'Company Admin';
}

export function isParticipant() {
  const loggedUser = Auth.getInstance().getUserData();
  return loggedUser && loggedUser.UserInRole.UserRole.name === 'Participant';
}

export function isAgent() {
  const loggedUser = Auth.getInstance().getUserData();
  return loggedUser && loggedUser.UserInRole.UserRole.name === 'Agent';
}

export function isGuestAgent() {
  const loggedUser = Auth.getInstance().getUserData();
  return loggedUser && loggedUser.UserInRole.UserRole.name === 'Guest Agent';
}

export function isCompanyAdminAgent() {
  const loggedUser = Auth.getInstance().getUserData();
  return loggedUser && loggedUser.UserInRole.UserRole.name === 'Company Admin' && loggedUser.is_broker;
}





