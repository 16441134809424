import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";

import FormPage from "../../../../common/FormPage";
import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import Column from "../../../../common/Column";
import AsterixTypo from "../../../../common/AsterixTypo";
import LinkButton from "../../../../common/buttons/LinkButton";
import ThemeButton from "../../../../common/buttons/ThemeButton";
import ToyTable from "../table/ToyTable";
import {
  getTransactionInitialById,
  upsertTransaction,
} from "../../../../../redux/actions/transaction";
import {
  clearList,
  clearVessel,
  findVesselOrToyByHullNumber,
  getGlobalVesselOrToy,
  retrieveVesselOrToys,
} from "../../../../../redux/actions/vessels_toys";

import {
  getFormattedData,
  getPlainTransactionData,
} from "../../../../../utils/stepFilter";
import { getFigure, getPrice } from "../../../../../utils/currency";
import { getLength, getOrigin } from "../../../../../utils/length";

import dropdownLists from "../../../../../constants/dropdownLists";
import { useHistory } from "react-router-dom";
import AutoCompleteSearch from "../../../../common/Autocomplete";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import { Autocomplete, TextField } from "@mui/material";

const MAX_ENGINE_COUNT = 6;

//Define schema for validations
const schema = yup.object().shape(
  {
    // vessel_name: yup.string().required(),
    hull_number: yup.string().when(['is_old_built'], {
      is: (is_old_built) => is_old_built !== true,
      then: yup.string().required().min(12),
      otherwise: yup.string().nullable()
    }),
    length: yup.string().required(),
    make: yup.string().required(),
    model: yup.string().nullable(),
    year: yup.number().required(),
    doc_no: yup.string().when(["reg_no", "title_no"], {
      is: (reg_no, title_no) =>
        (!reg_no || reg_no.length === 0) &&
        (!title_no || title_no.length === 0),
      then: yup.string().required(),
      otherwise: yup.string().nullable(),
    }),
    reg_no: yup.string().when(["doc_no", "title_no"], {
      is: (doc_no, title_no) =>
        (!doc_no || doc_no.length === 0) &&
        (!title_no || title_no.length === 0),
      then: yup.string().required(),
      otherwise: yup.string().nullable(),
    }),
    title_no: yup.string().when(["doc_no", "reg_no"], {
      is: (doc_no, reg_no) =>
        (!doc_no || doc_no.length === 0) && (!reg_no || reg_no.length === 0),
      then: yup.string().required(),
      otherwise: yup.string().nullable(),
    }),
  },
  [
    ["reg_no", "title_no"],
    ["doc_no", "title_no"],
    ["doc_no", "reg_no"],
  ]
);

const AddToys = (props) => {
  const dispatch = useDispatch();

  const history = useHistory();

  //Get step number from props
  const setStep = props.setStep ? props.setStep : null;
  const step = props.step ? props.step : null;

  //Define flag to check it is a add form page or edit form.
  const [isAdd, setIsAdd] = useState(false);

  //Define state forsearch hull number
  const [searchHull, setSearchHull] = useState("");

  //State for number of engines
  const [numberOfEngines, setNumberOfEngines] = useState(1);

  //State to convert length unit into foot or meter.
  const [lengthUnit, setLengthUnit] = useState("");

  //Define state for dutyPaid radio button
  const [isDutyPaid, setIsDutyPaid] = useState(null);

  //Define state for salesPaid radio button
  const [isSalesPaid, setIsSalesPaid] = useState(null);

  //State for old hullnumber checkbox
  const [isOld, setOld] = useState(false);

  /* Define State Variables */
  const [boatType, setBoatType] = useState("");

  //Variable for store list of vessels
  const dataVessels = useSelector((state) => state.vessel_toys.list);

  const [isSelectedVessel, setIsSelectedVessel] = useState(false);

  //State for foreign vessel
  const [isForeignBuiltVessel, setIsForeignBuiltVessel] = useState(false);

  //State for storing boat category dropdown
  const [boatCategories, setBoatCategories] = useState([]);

  //State for storing boat manufactures dropdown
  const [boatManufacturers, setBoatManufacturers] = useState([]);

  //State for store current vessel if this fom is rendered for edit
  const dataVessel = useSelector((state) => state.vessel_toys.current);

  const [requiredFields, setRequiredFields] = useState(['hull_number', 'length', 'item_value', 'make', 'year']);

  //Separate power category of boat from all category of boat
  const dataPowerCategories = useSelector((state) => {
    if (state.vessel_toys.mlsFilters.PowerCategories) {
      return state.vessel_toys.mlsFilters.PowerCategories || [];
    } else {
      return [];
    }
  });

  //Separate sail category of boat from all category of boat
  const dataSailCategories = useSelector((state) => {
    if (state.vessel_toys.mlsFilters.SailCategories) {
      return state.vessel_toys.mlsFilters.SailCategories || [];
    } else {
      return [];
    }
  });

  //Remove null manufactures from all data manufactures
  // const dataManuFecturers = vessels.sort();

  let dataManuFecturers = useSelector((state) => {
    return state.vessel_toys.mlsFilters.manufacturers || [];
  });

  dataManuFecturers = dataManuFecturers.filter((item, i, ar) => ar.indexOf(item) === i).map(mfr => { return { label: mfr } });

  //State for storing current transaction
  const dataTransaction = useSelector(
    (state) => state.transactions.transaction
  );

  //State for storing transaction broker
  const dataBroker = useSelector(
    (state) => dataTransaction?.broker || state.transactions.broker
  );

  //Variable for storing current transaction
  const dataDeal = dataTransaction || {};

  //Variable for storing toys data
  const toys = dataDeal.t_toys || [];

  //Variable for storing current toys
  const toy = useSelector((state) => state.vessel_toys.current);
  let dataToy = toy;
  dataToy =
    props.location && props.location.state ? props.location.state.toy : toy;

  //Define all keys of form
  const keys = [
    "lienholder",
    "hull_number",
    "is_old_built",
    "vessel_name",
    "length",
    "model",
    "make",
    "boat_type",
    "boat_category",
    "year",
    "flag",
    "doc_no",
    "doc_no_issuer",
    "reg_no",
    "reg_no_issuer",
    "title_no",
    "title_no_issuer",
    "place_of_registration",
    "is_duty_paid",
    "sales_use_tax_paid",
    "number_of_engines",
    "engine_make",
    "engine_model",
    "engine_hp",
    "broker_user_id",
    "company_office_id",
    "company_id",
    "stock",
  ];

  //Push engine fields as per number of engine fields
  Array.from({ length: MAX_ENGINE_COUNT }).forEach((_, index) => {
    const realIndex = index + 1;
    keys.push(`engine_serial_${realIndex}`);
    keys.push(`engine_year_${realIndex}`);
    keys.push(`engine_hours_${realIndex}`);
  });

  //Fetch all vessels
  useEffect(() => {
    dispatch(retrieveVesselOrToys("toy"));
    return () => {
      dispatch(clearVessel());
    };
  }, []);

  //If search method is boatdox then fill vessels fields
  const optionsForAutocomplete = useMemo(() => dataVessels && dataVessels
    .map((vessel) => {
      let vessel_title = `${vessel.length || ""} ${vessel.make || ""} ${vessel.model || ""} ${vessel.year || ""
        }`;
      vessel_title = vessel_title === "   " ? "N/A" : vessel.stock ? `${vessel_title} (${vessel.stock})` : vessel_title;
      return { id: vessel.id, value: vessel_title };
    })
    .filter(option => option.value !== 'N/A')
    .sort((prev, next) => prev.value.localeCompare(next.value))
    .reduce((newArray, cur) => newArray.length && newArray.at(-1).value === cur.value ? newArray : [...newArray, cur], []), [dataVessels]);

  const handleSelect = (value, option) => {
    dispatch(getGlobalVesselOrToy(option.id));
  };

  const [makeValue, setMakeValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  //UseEffect for setBoatCategories with sorting
  useEffect(() => {
    const categories = boatType == "Power" ? dataPowerCategories : dataSailCategories;
    setBoatCategories(categories.sort());
  }, [boatType]);

  //Set value of boat category field of form
  useEffect(() => {
    setTimeout(() => {
      setValue("boat_category", dataVessel?.boat_category || "");
    }, 1000);
  }, [dataVessel]);

  //UseEffect for storing value of length into form
  useEffect(() => {
    let figure = getOrigin(getValues("length"));
    setValue("length", getLength(figure, lengthUnit));
  }, [lengthUnit]);

  //Get values of all dropdowns from dropdownList
  const { boatMakes, boatTypes, engineMakes, toyTypes } = dropdownLists;

  //UseEffect for clear vessel values and list
  useEffect(() => {
    return () => {
      dispatch(clearVessel());
      dispatch(clearList());
    };
  }, []);

  //useEffect for search the vessel as per hull number
  useEffect(() => {
    if (searchHull && searchHull.length > 3)
      dispatch(findVesselOrToyByHullNumber(searchHull, "toy"));
  }, [searchHull]);

  //Set the values of all_purchase_price field
  useEffect(() => {
    const additional_price = toys.reduce(
      (total, toy) => total + toy.item_value,
      0
    );
    setValue("all_purchase_price", getPrice(additional_price));
  }, [toys]);

  //useEffect for set the value of toys & tenders form
  useEffect(() => {
    if (dataToy) {
      keys.forEach((key) => {
        if (key == "item_value") {
          if (isSelectedVessel) {
            setValue("item_value", dataToy?.vessel?.item_value || null)
          } else {
            setValue("item_value", dataToy?.item_value || null)
          }
          return;
        }
        setValue(key, dataToy[key] ? dataToy[key] : null);
      });
      setValue('number_of_engines', dataToy?.number_of_engines || 1);
      setValue('is_old_built', dataToy?.is_old_built || false);
      setValue("length", getLength(dataToy?.length));
      setLengthUnit(dataToy?.length_unit || "ft");
      setIsForeignBuiltVessel(dataToy?.foreign_built_vessel || false)
      setIsDutyPaid(dataToy?.is_duty_paid);
      setIsSalesPaid(dataToy?.sales_use_tax_paid);
      setOld(dataToy?.is_old_built || false);
      setValue("boat_type", dataToy?.boat_type || "Power");
      setBoatType(dataToy?.boat_type || "Power");
      setMakeValue(dataToy?.make ? { label: dataToy?.make } : null);
      requiredFields.map((value) => {
        manageRequireFields(value, getValues(value));
      });
    } else {
      keys.forEach((key) => setValue(key, null));
      setValue("number_of_engines", dataToy?.number_of_engines || 1);
      setValue("is_old_built", dataToy?.is_old_built || false);
      setIsForeignBuiltVessel(dataToy?.foreign_built_vessel || false);
      setValue("length", getLength(dataToy?.length));
      setLengthUnit(dataToy?.length_unit || "ft");
      setIsDutyPaid(dataToy?.is_duty_paid);
      setIsSalesPaid(dataToy?.sales_use_tax_paid);
      setOld(dataToy?.is_old_built || false);
      setValue("boat_type", "Power");
      setBoatType("Power");
      setMakeValue(null);
    }
  }, [dataToy]);

  //Define variables for formOptions
  let formJson = {
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: false,
  };

  if (!isAdd) {
    delete formJson["resolver"];
  }

  //Get in built function from useForm
  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    clearErrors,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm(formJson);

  //Define function for update or store the data of toys
  const handleRegistration = (toy, isFinishLater = false, isAddAnother = false) => {
    //CHeck form value is valid or not
    const isValid = schema.isValidSync(toy);

    if (isValid) {
      dispatch(setLoadingStatus(true));
      //add some fields value for toy
      toy.vessel_type = "toy";
      toy = getFormattedData(toy);
      toy.is_duty_paid = isDutyPaid;
      toy.sales_use_tax_paid = isSalesPaid;
      toy.length_unit = lengthUnit;
      toy.length = getOrigin(toy.length, lengthUnit);
      toy.is_old_built = isOld;
      toy.boat_type = boatType;
      toy.foreign_built_vessel = isForeignBuiltVessel;
      toy.company_id = dataBroker?.company_id;
      toy.make = makeValue?.label || '';

      //Call api to store or update the toy
      dispatch(
        upsertTransaction({
          step: {
            db: "toy",
            main: toy,
          },
          transaction: {
            main: {
              ...getPlainTransactionData(dataDeal),
              task_type: "listing",
              broker_user_id: dataBroker?.id,
              company_id: dataBroker?.company_id,
              company_office_id: dataBroker?.company_office_id,
            },
            isBelonged: false,
          },
          third: {
            main: {
              item_value: getFigure(toy.item_value),
              item_type: toy.item_type,
            },
            db: "transaction_toy",
            stepKey: "transaction_vessel_id",
          },
        }, "Toy & Tender's")
      ).then((data) => {
        dispatch(setLoadingStatus(false));
        if (isFinishLater) {
          history.push(`/listing-agreement/view/${dataTransaction.id}/main`);
        } else {
          //Clear the form
          let obj = {}
          keys.map((key) => obj[key] = null);
          setMakeValue(null);
          reset(obj);
          if (!isAddAnother) {
            setStep(step + 1);
          }
        }
        dispatch(getTransactionInitialById(data.id));
      }).catch(() => {
        dispatch(setLoadingStatus(false));
      });

      //Clear the form
      // let obj = {};
      // keys.map((key) => (obj[key] = null));
      // setMakeValue(null);
      // reset(obj);
    }
  };

  //Define function to handle form validations errors.
  const handleError = (err) => {
    console.log(err);
    // setStep((step || 0) + 1);
  };

  /**
   *  @param {Object} toy
   * Define function for save and continue
   */
  const handleSubmitRegist = (toy) => {
    handleRegistration(toy);
    !props.location.state && setStep(step + 1);
  };

  const handleBlur = () => {
    let totalValue = getValues("all_purchase_price");
    let totalPrice = getPrice(totalValue);
    setValue("all_purchase_price", totalPrice);
  };

  //Function for add ft. or mt. for length
  const handleLengthBlur = () => {
    let figure = getOrigin(getValues("length"));
    setValue("length", getLength(figure, lengthUnit));
  };

  //Function for remove ft. or mt. for length
  const handleLengthFocus = () => {
    let symbolitic = getValues("length");
    setValue("length", getOrigin(symbolitic, lengthUnit));
  };

  const handleFocus = () => {
    let totalValue = getValues("all_purchase_price");
    let totalPrice = getPrice(totalValue);
    setValue("all_purchase_price", totalPrice);
  };

  //UseEffect for add fields as per number of field is selected.
  useEffect(() => {
    setNumberOfEngines(getValues("number_of_engines"));
  }, [watch("number_of_engines")]);

  const links = [
    { label: "Listing Agreements", link: `/listing-agreement` },
    { label: "Add a Listing Agreement", link: null },
  ];

  const manageRequireFields = (fieldName, fieldValue) => {
    setRequiredFields(state => {
      if (fieldValue) {
        if (fieldName == 'hull_number' && getValues('hull_number').length < 12) {
          return state.includes(fieldName) ? state : [...state, fieldName];
        }
        return state.filter(field => field != fieldName);
      }
      return state.includes(fieldName) ? state : [...state, fieldName];
    })
  }

  const vesselName = `${dataTransaction?.t_vessel?.year || ""} ${dataTransaction?.t_vessel?.make || ""} ${dataTransaction?.t_vessel?.model || ""} ${dataTransaction?.t_vessel?.length || ""}`;
  const uniqueTransactionId = dataTransaction?.unique_transaction_id ? `LA-${dataTransaction?.unique_transaction_id}` : '';

  return (
    <FormPage links={links} title="Toys & Tenders" vesselName={vesselName} uniqueId={uniqueTransactionId}>
      <ToyTable />
      <FormRow>
        <Column size="two">
          <label className="toys">Any Other Registered Vessel Included?</label>
          <div className="radio-toolbar">
            <input
              type="radio"
              id="radioToysYes"
              name="radioToys"
              checked={isAdd}
              onClick={(e) => setIsAdd(true)}
              onChange={() => { }}
            />
            <label className="uk-form-label radio">Yes</label>
            <input
              type="radio"
              id="radioToysNo"
              name="radioToys"
              checked={!isAdd}
              onClick={(e) => {
                clearErrors();
                setIsAdd(false)
              }}
              onChange={() => { }}
            />
            <label className="uk-form-label radio">No</label>
          </div>
        </Column>
      </FormRow>
      {isAdd && (
        <FormRow>
          <AutoCompleteSearch options={optionsForAutocomplete} onSelect={handleSelect} />
        </FormRow>
      )}
      {isAdd && (
        <Form>
          <FormRow>
            <FormInputWrapper label="Hull Number *">
              <input
                className={clsx("uk-input", { "uk-form-danger": requiredFields.includes('hull_number') && !isOld })}
                type="text" placeholder="Hull number must be 12 digits"
                {...register("hull_number", {
                  onChange: e => {
                    manageRequireFields('hull_number', e.target.value);
                  }
                })}
              />
            </FormInputWrapper>
            <FormInputWrapper label="HIN is not available or is not 12 character USCG compliant.">
              <input
                className="uk-checkbox"
                type="checkbox"
                checked={isOld}
                onClick={() => {
                  setOld(!isOld);
                  setValue('is_old_built', !isOld);
                }}
                onChange={() => { }}
              />
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="vessel_name">
                Vessel Name
              </label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.vessel_name?.message,
                })}
                type="text"
                {...register("vessel_name")}
              />
            </div>

            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="stock">
                Stock #
              </label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.stock?.message,
                })}
                type="text"
                {...register("stock")}
              />
            </div>
            <FormInputWrapper label="USCG/Official #">
              <input
                className="uk-input"
                type="text"
                {...register("uscg")}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Length *">
              <input
                className={clsx("uk-input", { "uk-form-danger": requiredFields.includes('length') })}
                type="text"
                {...register("length", {
                  onChange: e => {
                    manageRequireFields('length', e.target.value);
                  }
                })}
                onBlur={handleLengthBlur}
                onFocus={handleLengthFocus}
              />
            </FormInputWrapper>

            <FormInputWrapper label="Length Unit">
              <input
                type="radio"
                name="length_unit"
                value="ft"
                onClick={() => setLengthUnit("ft")}
                onChange={() => { }}
                checked={lengthUnit === "ft"}
              />
              <label className="uk-form-label radio">Feet</label>
              <input
                type="radio"
                name="length_unit"
                value="m"
                onClick={() => setLengthUnit("m")}
                onChange={() => { }}
                checked={lengthUnit === "m"}
              />
              <label className="uk-form-label ratio">Meters</label>
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Category">
              <select
                className="uk-select"
                {...register("boat_category")}
                disabled={!boatType}
              >
                <option key={`vessel_boat_category_default`} value=""></option>
                {boatCategories.map((boatCategoryKey, idx) => (
                  <option
                    key={`vessel_boat_category_${idx}`}
                    value={boatCategoryKey}
                  >
                    {boatCategoryKey}
                  </option>
                ))}
              </select>
            </FormInputWrapper>

            <FormInputWrapper label="Type">
              <input
                type="radio"
                name="boat_type"
                value="Power"
                onClick={() => {
                  setBoatType("Power");
                  setValue("boat_category", "");
                  // setBoatCategories(dataPowerCategories);
                }}
                onChange={() => { }}
                checked={boatType === "Power"}
              />
              <label className="uk-form-label radio">Power</label>
              <input
                type="radio"
                name="boat_type"
                value="Sail"
                onClick={() => {
                  setBoatType("Sail");
                  setValue("boat_category", "");
                  // setBoatCategories(dataSailCategories);
                }}
                onChange={() => { }}
                checked={boatType === "Sail"}
              />
              <label className="uk-form-label ratio">Sail</label>
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Manufacturer/Builder *">
              <Autocomplete
                style={{ borderLeft: 'none' }}
                className={clsx({ "uk-form-danger": requiredFields.includes('make') && !makeValue })}
                id="size-small-outlined"
                size="small"
                isOptionEqualToValue={(option, value) => option.label === value.label}
                value={makeValue}
                onChange={(event, newValue) => {
                  setValue('make', newValue?.label || '');
                  setMakeValue(newValue);
                  manageRequireFields('make', newValue?.label);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={dataManuFecturers}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormInputWrapper>

            <FormInputWrapper label="Model">
              <input
                className="uk-input"
                type="text"
                {...register("model")}
              />
            </FormInputWrapper>

            {/* <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="model">
                Model*
              </label>

            </div> */}

            <FormInputWrapper label="Year *">
              <input
                className={clsx("uk-input", { "uk-form-danger": requiredFields.includes('year') })}
                type="text"
                {...register("year", {
                  onChange: e => {
                    manageRequireFields('year', e.target.value);
                  }
                })}
              />
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Number of Engines">
              <select className="uk-select" {...register("number_of_engines")}>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
              </select>
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Engine Make">
              <input className="uk-input" type="text" {...register("engine_make")} />
              {/* <select className="uk-select" {...register("engine_make")}>
                {engineMakes.map((engineMake, idx) => (
                  <option key={`engine_make_${idx}`} value={engineMake}>
                    {engineMake}
                  </option>
                ))}
              </select> */}
            </FormInputWrapper>
            <FormInputWrapper label="Engine Model">
              <input
                className="uk-input"
                type="text"
                {...register("engine_model")}
              />
            </FormInputWrapper>
            <FormInputWrapper label="Engine HP">
              <input
                className="uk-input"
                type="text"
                {...register("engine_hp")}
              />
            </FormInputWrapper>
          </FormRow>
          {Array.from({ length: numberOfEngines }).map((_, index) => {
            const realIndex = index + 1;

            return (
              <FormRow key={`engine_${index}`}>
                <FormInputWrapper label={`Engine ${realIndex} Year`}>
                  <input
                    className="uk-input"
                    type="text"
                    {...register(`engine_year_${realIndex}`)}
                  />
                </FormInputWrapper>
                <FormInputWrapper
                  label={`Engine ${realIndex} Serial`}
                >
                  <input
                    className="uk-input"
                    type="text"
                    {...register(`engine_serial_${realIndex}`)}
                  />
                </FormInputWrapper>
                <FormInputWrapper label={`Engine ${realIndex} Hours`}>
                  <input
                    className="uk-input"
                    type="text"
                    {...register(`engine_hours_${realIndex}`)}
                  />
                </FormInputWrapper>
              </FormRow>
            );
          })}
          <FormRow>
            <FormInputWrapper label="Flag">
              <input className="uk-input" type="text" {...register("flag")} />
            </FormInputWrapper>
            <FormInputWrapper size="one" label="Foreign Built Vessel">
              <input
                type="radio"
                name="foreign_built_vessel"
                value={true}
                onClick={() => setIsForeignBuiltVessel(true)}
                onChange={() => { }}
                checked={isForeignBuiltVessel}
              />
              <label className="uk-form-label radio">Yes</label>
              <input
                type="radio"
                name="foreign_built_vessel"
                value={false}
                onClick={() => setIsForeignBuiltVessel(false)}
                onChange={() => { }}
                checked={!isForeignBuiltVessel}
              />
              <label className="uk-form-label radio">No</label>
            </FormInputWrapper>
            <FormInputWrapper label="Place of Registration">
              {/* place_of_registration */}
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.place_of_registration,
                })}
                type="text"
                {...register("place_of_registration")}
              />
            </FormInputWrapper>
          </FormRow>

          <FormRow>
            <p
              style={{ color: "#5479A3" }}
              className={clsx("", {
                "uk-text-danger uk-text-bold":
                  errors.doc_no?.message ||
                  errors.reg_no?.message ||
                  errors.title_no?.message,
              })}
            >
              Please input at least one: Doc No., Reg No., or Title No.
            </p>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Doc No.">
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.doc_no,
                })}
                type="text"
                {...register("doc_no")}
                onKeyUp={() => {
                  trigger(["reg_no", "title_no"]);
                }}
              />
            </FormInputWrapper>
            <FormInputWrapper label="Reg No.">
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.reg_no,
                })}
                type="text"
                {...register("reg_no")}
                onKeyUp={() => {
                  trigger(["doc_no", "title_no"]);
                }}
              />
            </FormInputWrapper>
            <FormInputWrapper label="Title No.">
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.title_no,
                })}
                type="text"
                {...register("title_no")}
                onKeyUp={() => {
                  trigger(["reg_no", "doc_no"]);
                }}
              />
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Doc No. Issuing Entity">
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.doc_no_issuer,
                })}
                type="text"
                {...register("doc_no_issuer")}
              />
            </FormInputWrapper>
            <FormInputWrapper label="Reg No. Issuing Entity">
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.reg_no_issuer,
                })}
                type="text"
                {...register("reg_no_issuer")}
              />
            </FormInputWrapper>
            <FormInputWrapper label="Title No. Issuing Entity">
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.title_no_issuer,
                })}
                type="text"
                {...register("title_no_issuer")}
              />
            </FormInputWrapper>
          </FormRow>

          <FormRow>
            <FormInputWrapper size="one" label="Duty Paid">
              <input
                type="radio"
                name="duty_paid"
                value={true}
                onClick={() => setIsDutyPaid(true)}
                onChange={() => { }}
                checked={isDutyPaid != null && isDutyPaid}
              />
              <label className="uk-form-label radio">Yes</label>
              <input
                type="radio"
                name="duty_paid"
                value={false}
                onClick={() => setIsDutyPaid(false)}
                onChange={() => { }}
                checked={isDutyPaid != null && !isDutyPaid}
              />
              <label className="uk-form-label radio">No</label>
              <input
                type="radio"
                name="duty_paid"
                value={false}
                onClick={() => setIsDutyPaid(null)}
                onChange={() => { }}
                checked={isDutyPaid == null}
              />
              <label className="uk-form-label radio">Unknown</label>
            </FormInputWrapper>
            <FormInputWrapper label="Sales/Use Tax Paid">
              <input
                type="radio"
                name="sales_use_tax_paid"
                value={true}
                onClick={() => setIsSalesPaid(true)}
                onChange={() => { }}
                checked={isSalesPaid != null && isSalesPaid}
              />
              <label className="uk-form-label radio">Yes</label>
              <input
                type="radio"
                name="sales_use_tax_paid"
                value={false}
                onClick={() => setIsSalesPaid(false)}
                onChange={() => { }}
                checked={isSalesPaid != null && !isSalesPaid}
              />
              <label className="uk-form-label radio">No</label>
              <input
                type="radio"
                name="sales_use_tax_paid"
                value={false}
                onClick={() => setIsSalesPaid(null)}
                onChange={() => { }}
                checked={isSalesPaid == null}
              />
              <label className="uk-form-label radio">Unknown</label>
            </FormInputWrapper>
            {/* lienholder */}
            <FormInputWrapper label="Lienholder Name">
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.lienholder,
                })}
                type="text"
                {...register("lienholder")}
              />
            </FormInputWrapper>
          </FormRow>

          <FormRow>
            {/* <SubmitButton>Save and Add Another</SubmitButton> */}
            <ThemeButton type="button" theme="primary" onClick={async () => {
              await trigger(Object.keys(getValues()));
              const isValid = schema.isValidSync(getValues());
              if (isValid) {
                await handleRegistration(getValues(), false, true);
                let obj = {};
                Object.keys(getValues()).map((key) => (obj[key] = null));
                reset(obj);
              }
            }}>
              Save and Add Another
            </ThemeButton>
            <ThemeButton onClick={() => {
              clearErrors();
              let obj = {};
              Object.keys(getValues()).map((key) => (obj[key] = null));
              reset(obj);
              setIsAdd(false);
            }}
            >Cancel</ThemeButton>
          </FormRow>
        </Form>
      )}
      {isAdd && (
        <FormRow style={{ marginTop: "20px" }}>
          <ThemeButton
            theme="primary"
            onClick={async () => {
              await trigger(Object.keys(getValues()));
              const isValid = schema.isValidSync(getValues());
              if (isValid) {
                await handleRegistration(getValues(), false);
                let obj = {};
                Object.keys(getValues()).map((key) => (obj[key] = null));
                reset(obj);
                setStep(step + 1);
              }
            }}
          >
            Save & Continue
          </ThemeButton>
          <ThemeButton
            theme="primary"
            onClick={async () => {
              await trigger(Object.keys(getValues()));
              const isValid = schema.isValidSync(getValues());
              if (isValid) {
                await handleRegistration(getValues(), false);
                let obj = {};
                Object.keys(getValues()).map((key) => (obj[key] = null));
                reset(obj);
                setStep(step + 1);
              }
            }}
          >
            Continue
          </ThemeButton>
          <ThemeButton
            onClick={async () => {
              await trigger(Object.keys(getValues()));
              const isValid = schema.isValidSync(getValues());
              if (isValid) {
                await handleRegistration(getValues(), true);
                let obj = {};
                Object.keys(getValues()).map((key) => (obj[key] = null));
                reset(obj);
              }
            }}
          >
            Save & Finish Later
          </ThemeButton>
        </FormRow>
      )}
      {!isAdd && (
        <FormRow>
          <ThemeButton theme="primary" onClick={() => setStep(step + 1)}>
            Continue
          </ThemeButton>
          <LinkButton to="/listing-agreement">Finish Later</LinkButton>
        </FormRow>
      )}
      <AsterixTypo isVisible={false} />
    </FormPage>
  );
};

export default AddToys;
