import http, { setHeaders } from '../http-common';
import Auth from '../utils/auth';

setHeaders(Auth.getInstance().getAuthToken());

const get = (id) => {
  return http.get(`/esign-templates/${id}`);
};

const create = (data) => {
  return http.post("/esign-templates", data);
};

const update = (id, data) => {
  return http.put(`/esign-templates/${id}`, data);
}

const remove = (id) => {
  return http.delete(`/esign-templates/${id}`);
};

const findByName = (name) => {
  return http.post('/esign-templates/search-by-name', { name: name });
}

const parseTemplate = (file_name) => {
  return http.get(`/esign-templates/parse-pdf?file_name=${file_name}`);
}

const getEsignTemplateByIybaDocument = (iybaDocumentId) => {
  return http.get(`/esign-templates/getEsignTemplateByIybaDocument/${iybaDocumentId}`);
}

const addSubtype = (data) => {
  return http.post(`/esign-templates/sub-type`, data);
}

const updateEsignOrder = (data) => {
  return http.post(`/esign-templates/updateOrder`, data);
}

const getCompanyTemplate = (id,type,search) => {
  const searchQuery = new URLSearchParams(search).toString();
  return http.get(`companies/esignTemplates/${id}?type=${type}&${searchQuery}`, );
}

const updateCompanyTemplate = (id,data) => {
  return http.put(`companies/esignTemplates/${id}`,data );
}

const updateCompanyFormOrder = (data) => {
  return http.post(`companies/updateFormsSortOrder`, data);
}

const TemplatesService = {
  getEsignTemplateByIybaDocument,
  updateEsignOrder,
  addSubtype,
  get,
  create,
  update,
  remove,
  findByName,
  parseTemplate,
  getCompanyTemplate,
  updateCompanyTemplate,
  updateCompanyFormOrder
};

export default TemplatesService;
