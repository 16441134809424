import { Redirect, Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import AddBuyerClosingDetails from "./steps/AddBuyerClosingDetails";
import EsignBuyerClosing from "./steps/EsignBuyerClosing";
import BuyerDetailStepBar from "./stepbars/BuyerClosingStep";
import AddBuyerExpenses from "./steps/AddBuyerExpenses";
import AddBuyerCredits from "./steps/AddBuyerCredits";
import AddBuyerDeposits from "./steps/AddBuyerDeposits";
import AddBuyerNote from "./steps/AddBuyerNote";

const BuyerDetailOption = () => {
  let { path } = useRouteMatch();
  let { id } = useParams();

  return (
    <div>
      <BuyerDetailStepBar />
      <Switch>
        <Route exact path={path} render={() => <AddBuyerExpenses transactionId={id} />} />
        <Route path={`${path}/credits`} render={() => <AddBuyerCredits transactionId={id} />} />
        <Route path={`${path}/deposits`} render={() => <AddBuyerDeposits transactionId={id} />} />
        <Route path={`${path}/notes`} render={() => <AddBuyerNote transactionId={id} />} />
        <Route exact path={`${path}/detail`} render={() => <AddBuyerClosingDetails transactionId={id} />} />
        <Route path={`${path}/send_to_signer`} render={() => <EsignBuyerClosing transactionId={id} />} />
        <Redirect to={path} />
      </Switch>
    </div>
  );
};

export default BuyerDetailOption;
