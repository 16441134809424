import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Helmet } from "react-helmet";
import UserForm from "./Form";
import UsersList from "./List";
import UsersView from "./View";
import InviteForm from "./Invite";

const UsersPage = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <Helmet>
        <title>Users - BoatDox</title>
      </Helmet>
      <Switch>
        <Route exact path={path} component={UsersList} />
        <Route path={`${path}/invite`} component={InviteForm} />
        <Route path={`${path}/:id/edit`} component={UserForm} />
        <Route path={`${path}/view`} component={UsersView} />
      </Switch>
    </>
  );
};

export default UsersPage;
