import clsx from "clsx";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { LEGAL_AND_OR_ASSIGN, LEGAL_OR_ASSIGN, LEGAL_OWNER } from "../../../../utils/signer";

const StepBar = ({ setStep, stepNum, asterix, setAsterix }) => {
  const dataRequiredFields = useSelector((state) => state.transactions.requiredFields);
  const dataTransaction = useSelector((state) => state.transactions.transaction || {});
  const dataTradeIn = dataTransaction.t_trade_in_vessel || {};

  let params = useParams();
  let { id } = params;
  const history = useHistory();

  const steps = [
    "vessel",
    "buyer",
    "co_buyer",
    "seller",
    "co_seller",
    "toys",
    "trailer",
    "trade-in-vessel",
    "offer",
    "terms",
    "exclusions",
    // "addendum",
    "co_broker",
    // "send_to_signer",
  ];

  const tradeRequiredFields = {
    hull_number: "Hull No.",
    length: "Length",
    make: "Make",
    model: "Model",
    boat_type: "Boat Type",
    year: "Year",
    accept_reject_date: "Trade Vessel Accept/Reject Date",
    delivery_location: "Trade Vessel Delivery Location",
    allowance: "Allowance",
    exclusion_list: "Trade Vessel Exclusion List",
    doc_no: "Please input at least one: Doc No., Reg No., or Title No.",
    engine_make: "Engine Make",
    engine_model: "Engine Model",
    engine_hp: "Engine HP"
  }

  const page = window.location.pathname.split("/")

  const step =
    page[page.length - 2] == 'toys'
      ? 5
      : page[page.length - 2] == 'trailer'
        ? 6
        : steps.indexOf(page.pop());

  const handleJumpStep = (step) => {
    history.push(`/deals/add/${id}/${steps[step]}`);
    setStep(step);
  };

  useEffect(() => {
    const step = steps.indexOf(window.location.pathname.split("/").pop());
    setStep(steps.indexOf(step) != -1 ? steps.indexOf(step) : 0);
    // history.push(`/deals/add/${id}/${steps[step]}`);
  }, [window.location.pathname]);

  useEffect(() => {
    const vesselRequireds = dataRequiredFields.vessel;
    const buyerRequireds = dataRequiredFields.buyer;
    const coBuyerRequireds = dataRequiredFields.co_buyer;
    const offerRequireds = dataRequiredFields.offer;
    const sellerRequireds = dataRequiredFields.seller;
    const coSellerRequireds = dataRequiredFields.co_seller;
    const termsRequireds = dataRequiredFields.terms;
    const coBrokerRequireds = dataRequiredFields.co_broker;
    const trailerRequireds = dataRequiredFields.trailer;

    let vesselAsterix = "empty";
    let buyerAsterix = "empty";
    let coBuyerAsterix = "empty";
    let offerAsterix = "empty";
    let sellerAsterix = "empty";
    let coSellerAsterix = "empty";
    let termsAsterix = "empty";
    let coBrokerAsterix = "empty";
    let trailerAsterix = "empty";

    if (vesselRequireds.length > 0 && vesselRequireds.length < 5) vesselAsterix = "notFulfill";
    if (buyerRequireds.length > 0 && buyerRequireds.length < ((dataTransaction.buyer_type === LEGAL_OWNER || dataTransaction.buyer_type === LEGAL_OR_ASSIGN || dataTransaction.buyer_type === LEGAL_AND_OR_ASSIGN) ? 4 : 8)) buyerAsterix = "notFulfill";
    if (buyerRequireds.length === 1 && buyerRequireds[0]['title']) buyerAsterix = "empty";
    if (coBuyerRequireds.length > 0 && coBuyerRequireds.length < 4) coBuyerAsterix = "notFulfill";
    if (offerRequireds.length > 0 && offerRequireds.length < 5) offerAsterix = "notFulfill";
    if (sellerRequireds.length > 0 && sellerRequireds.length < (dataTransaction.seller_type === LEGAL_OWNER ? 4 : 8)) sellerAsterix = "notFulfill";
    if (sellerRequireds.length === 1 && sellerRequireds[0]['title']) sellerAsterix = "empty";
    if (coSellerRequireds.length > 0 && coSellerRequireds.length < 4)
      coSellerAsterix = "notFulfill";
    if (termsRequireds.length > 0 && termsRequireds.length < 2) termsAsterix = "notFulfill";
    if (coBrokerRequireds.length > 0 && coBrokerRequireds.length < 6) coBrokerAsterix = "notFulfill";

    if (vesselRequireds.length === 0) vesselAsterix = "fulfill";
    if (buyerRequireds.length === 0) buyerAsterix = "fulfill";
    if (coBuyerRequireds.length === 0) coBuyerAsterix = "fulfill";
    if (offerRequireds.length === 0) offerAsterix = "fulfill";
    if (sellerRequireds.length === 0) sellerAsterix = "fulfill";
    if (coSellerRequireds.length === 0) coSellerAsterix = "fulfill";
    if (termsRequireds.length === 0) termsAsterix = "fulfill";
    if (coBrokerRequireds.length === 0) coBrokerAsterix = "fulfill";

    if (dataTransaction?.owner_or_legal === 2) sellerAsterix = "fulfill";
    if (trailerRequireds.length === 0) trailerAsterix = "fulfill";

    const val = {
      vessel: vesselAsterix,
      buyer: buyerAsterix,
      co_buyer: coBuyerAsterix,
      offer: offerAsterix,
      seller: sellerAsterix,
      co_seller: coSellerAsterix,
      terms: termsAsterix,
      co_broker: coBrokerAsterix,
      trailer: trailerAsterix
    };

    if (dataTransaction.t_toys?.length > 0) {
      val.toys = "fulfill";
    } else {
      val.toys = "empty";
    }
    if (dataTransaction.t_exclusion?.length > 0) {
      val.exclusions = "fulfill";
    } else {
      val.exclusions = "empty";
    }

    let requiredFields = [];
    if (dataTradeIn && dataTradeIn.id) {
      Object.keys(tradeRequiredFields).forEach(key => {
        if (key == "doc_no") {
          if (!dataTradeIn["doc_no"] && !dataTradeIn["reg_no"] && !dataTradeIn["title_no"]) {
            requiredFields.push({ "": "Please input at least one: Doc No., Reg No., or Title No." });
            return
          }
        }
        if (!dataTradeIn[key]) {
          requiredFields.push({ [key]: tradeRequiredFields[key] });
        }
      });

      if (requiredFields.length === 0) {
        val.tradeIn = "fulfill";
      } else if (requiredFields.length === tradeRequiredFields.length) {
        val.tradeIn = "empty";
      } else {
        val.tradeIn = "notFulfill";
      }
    } else {
      Object.keys(tradeRequiredFields).forEach(key => {
        requiredFields.push({ [key]: tradeRequiredFields[key] });
      });
      val.tradeIn = "empty";
    }

    // if (dataTransaction.t_addendum) {
    //   if (dataTransaction.t_addendum.ammendment === "/://://://:/") {
    //     val.addendum = "empty";
    //   } else {
    //     val.addendum = "fulfill";
    //   }
    // } else {
    //   val.addendum = "empty";
    // }
    setAsterix(val);
  }, [dataRequiredFields, dataTransaction]);

  return (
    <div className="tm-sidebar-left uk-visible@m add-deal">
      <div className="sidebar-container add-deal">
        <ul className="uk-nav uk-nav-default tm-nav">
          <li
            name="vessel"
            id="tab-vessel"
            className={clsx("add-deal chain-step", { current: step == -1 || step == 0 })}
            onClick={() => handleJumpStep(0)}
          >
            <i
              className={clsx(
                "fa",
                { "fa-check-circle stepbar": asterix.vessel === "fulfill" },
                { "fa-circle-o": asterix.vessel === "empty" || !asterix.vessel },
                { "fa-circle": asterix.vessel === "notFulfill" }
              )}
            ></i>
            <span className="add-deal-step">Vessel</span>
          </li>
          <li
            name="buyer"
            id="tab-buyer"
            className={clsx("add-deal chain-step", { current: step === 1 })}
            onClick={() => handleJumpStep(1)}
          >
            <i
              className={clsx(
                "fa",
                { "fa-check-circle stepbar": asterix.buyer === "fulfill" },
                { "fa-circle-o": asterix.buyer === "empty" || !asterix.buyer },
                { "fa-circle": asterix.buyer === "notFulfill" }
              )}
            ></i>
            <span className="add-deal-step">Buyer</span>
          </li>
          <li
            name="co-buyer"
            id="tab-co-buyer"
            className={clsx("add-deal chain-step", { current: step === 2 })}
            onClick={() => handleJumpStep(2)}
          >
            <i
              className={clsx(
                "fa",
                { "fa-check-circle stepbar": asterix.co_buyer === "fulfill" },
                { "fa-circle-o": asterix.co_buyer === "empty" || !asterix.co_buyer },
                { "fa-circle": asterix.co_buyer === "notFulfill" }
              )}
            ></i>
            <span className="add-deal-step">Co-Buyer</span>
          </li>
          <li
            name="seller"
            id="tab-seller"
            className={clsx("add-deal chain-step", { current: step === 3 })}
            onClick={() => handleJumpStep(3)}
          >
            <i
              className={clsx(
                "fa",
                { "fa-check-circle stepbar": asterix.seller === "fulfill" },
                { "fa-circle-o": asterix.seller === "empty" || !asterix.seller },
                { "fa-circle": asterix.seller === "notFulfill" }
              )}
            ></i>
            <span className="add-deal-step">Seller</span>
          </li>
          <li
            name="co-seller"
            id="tab-co-seller"
            className={clsx("add-deal chain-step", { current: step === 4 })}
            onClick={() => handleJumpStep(4)}
          >
            <i
              className={clsx(
                "fa",
                { "fa-check-circle stepbar": asterix.co_seller === "fulfill" },
                { "fa-circle-o": asterix.co_seller === "empty" || !asterix.co_seller },
                { "fa-circle": asterix.co_seller === "notFulfill" }
              )}
            ></i>
            <span className="add-deal-step">Co-Seller</span>
          </li>
          <li
            name="toys"
            id="tab-toys"
            className={clsx("add-deal chain-step", { current: step === 5 })}
            onClick={() => handleJumpStep(5)}
          >
            <i
              className={clsx(
                "fa",
                { "fa-check-circle stepbar": asterix.toys === "fulfill" },
                { "fa-circle-o": asterix.toys === "empty" || !asterix.toys },
                { "fa-circle": asterix.toys === "notFulfill" }
              )}
            ></i>
            <span className="add-deal-step">Toys & Tenders</span>
          </li>
          <li
            name="trailer"
            id="trailer"
            className={clsx("add-deal chain-step", { current: step === 6 })}
            onClick={() => handleJumpStep(6)}
          >
            <i
              className={clsx(
                "fa",
                { "fa-check-circle stepbar": asterix.trailer === "fulfill" },
                { "fa-circle-o": asterix.trailer === "empty" || !asterix.trailer },
                { "fa-circle": asterix.trailer === "notFulfill" }
              )}
            ></i>
            <span className="add-deal-step">Trailer</span>
          </li>
          <li
            className={clsx("add-deal chain-step", { current: step === 7 })}
            onClick={() => handleJumpStep(7)}
          >
            <i
              className={clsx(
                "fa",
                { "fa-check-circle stepbar": asterix.tradeIn === "fulfill" },
                { "fa-circle-o": asterix.tradeIn === "empty" || !asterix.tradeIn },
                { "fa-circle": asterix.tradeIn === "notFulfill" }
              )}
            />
            <span className="add-deal-step">Trade In</span>
          </li>
          <li
            name="offer"
            id="tab-offer"
            className={clsx("add-deal chain-step", { current: step === 8 })}
            onClick={() => handleJumpStep(8)}
          >
            <i
              className={clsx(
                "fa",
                { "fa-check-circle stepbar": asterix.offer === "fulfill" },
                { "fa-circle-o": asterix.offer === "empty" || !asterix.offer },
                { "fa-circle": asterix.offer === "notFulfill" }
              )}
            ></i>
            <span className="add-deal-step">Offer</span>
          </li>
          <li
            name="terms"
            id="tab-terms"
            className={clsx("add-deal chain-step", { current: step === 9 })}
            onClick={() => handleJumpStep(9)}
          >
            <i
              className={clsx(
                "fa",
                { "fa-check-circle stepbar": asterix.terms === "fulfill" },
                { "fa-circle-o": asterix.terms === "empty" || !asterix.terms },
                { "fa-circle": asterix.terms === "notFulfill" }
              )}
            />
            <span className="add-deal-step">Terms</span>
          </li>
          <li
            name="exclusions"
            id="tab-exclusions"
            className={clsx("add-deal chain-step", { current: step === 10 })}
            onClick={() => handleJumpStep(10)}
          >
            <i
              className={clsx(
                "fa",
                { "fa-check-circle stepbar": asterix.exclusions === "fulfill" },
                { "fa-circle-o": asterix.exclusions === "empty" || !asterix.exclusions },
                { "fa-circle": asterix.exclusions === "notFulfill" }
              )}
            ></i>
            <span className="add-deal-step">Exclusions</span>
          </li>
          {/* <li
            name="addendum"
            id="tab-addendum"
            className={clsx("add-deal chain-step", { current: step === 9 })}
            onClick={() => handleJumpStep(9)}
          >
            <i
              className={clsx(
                "fa",
                { "fa-check-circle stepbar": asterix.addendum === "fulfill" },
                { "fa-circle-o": asterix.addendum === "empty" || !asterix.addendum },
                { "fa-circle": asterix.addendum === "notFulfill" }
              )}
            ></i>
            <span className="add-deal-step">Addendum</span>
          </li> */}
          <li
            name="co-broker"
            id="tab-co-broker"
            className={clsx("add-deal chain-step", { current: step === 11 })}
            onClick={() => handleJumpStep(11)}
          >
            <i
              className={clsx(
                "fa",
                { "fa-check-circle stepbar": asterix.co_broker === "fulfill" },
                { "fa-circle-o": asterix.co_broker === "empty" || !asterix.co_broker },
                { "fa-circle": asterix.co_broker === "notFulfill" }
              )}
            ></i>
            <span className="add-deal-step">Co-Broker & Commission</span>
          </li>
          {/* <li
            name="send-to-buyer"
            id="tab-send-to-buyer"
            className={clsx("add-deal chain-step", { current: step === 11 })}
            onClick={() => handleJumpStep(11)}
          >
            <i className="fa fa-circle-o"></i>
            <span className="add-deal-step">Send for eSign</span>
          </li> */}

          {
            dataTransaction?.id &&
            // <li
            //   name="back-to-eDocuments"
            //   id="tab-back-to-eDocuments"
            //   className={clsx("add-deal chain-step")}
            // >
            //   <Link to={`/deals/view/${dataTransaction.id}/main#tab=%22edocuments%22`}> <span uk-icon="chevron-double-left"></span> Back to eDocuments</Link>
            // </li>
            <button className="uk-button uk-button-primary mt-2" onClick={() => history.push(`/deals/view/${dataTransaction.id}/main#tab=%22edocuments%22`)}>
              <span uk-icon="chevron-double-left"></span> Back to eDocuments
            </button>

          }
        </ul>
      </div>
    </div>
  );
};

export default StepBar;
