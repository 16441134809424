import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Buffer } from 'buffer';
import { makeStyles } from '@mui/styles';
import {
  Alert, Button, CssBaseline, TextField,
  FormControlLabel, Checkbox, Link, Grid, Box, Typography, Container, checkboxClasses
} from '@mui/material';
import { createTheme } from '@mui/material/styles';

import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import Auth from '../../../utils/auth';
import { resetRequired } from "../../../redux/actions/auth";
import { setLoadingStatus } from "../../../redux/actions/loading";
import { getProfile, setAWSUser } from "../../../redux/actions/users";
import UserService from "../../../services/UserService";
import { isGuestAgent, isParticipant } from "../../../utils/permission";
// import { manageAppVersionWithRefresh } from "../../../utils/functions";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://boatdox.org/">
        BoatDox
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required("Email is a required field"),
  password: yup
    .string()
    .required("Please enter your password")
    .matches(
      /^.*(?=.{8,})((?=.*\d){1})((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, and one number"
    )
});

const theme = createTheme();
const useStyles = makeStyles({
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#0f294b !important'
  },
  alert: {
    width: '100%',
    margin: 'auto'
  },
  typo: {
    color: '#0f294b !important'
  },
  logo: {
    maxWidth: '50%',
    maxHeight: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

const LoginForm = (props) => {

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['login-check-privacy']);

  const checkPrivacy = localStorage.getItem('login-check-privacy') === 'true';

  const [isAgreePolicy, setAgreePolicy] = useState(false)
  const [error, setError] = useState('');

  const [csrfToken, setCsrfToken] = useState(null);

  useEffect(() => {
    setAgreePolicy(checkPrivacy || false);
  }, []);

  useEffect(() => {
    UserService.trackUserToken('login')
      .then(response => {
        setCsrfToken(response.data.xt);
      }).catch(error => {
        console.log(error);
        setCsrfToken(null);
      });
  }, []);

  const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const handleAuthError = (err) => {
    dispatch(setLoadingStatus(false));
    switch (err.code) {
      case 'NotAuthorizedException':
        setError('Invalid credentials provided');
        break;
      case 'UserNotFoundException':
        setError('You are not member of this platform');
        break;
      case 'CodeMismatchException':
        setError('Code does not exist');
        break;
      case 'LimitExceededException':
        setError('You tried so many times to log in. Try again later');
        break;
      case 'InvalidPasswordException':
        setError('Your password is not valid');
        break;
      case 'InvalidParameterException':
        setError('Your email is not valid');
        break;
      case 'TokenNotAuthorized':
        setError('User Token is not authorized');
        break;
      case 'UserInactiveException':
        setError('Access Denied! Please contact your administrator.');
        break;
      default:
        break;
    }
  }

  const handleNewPasswordRequired = () => {
    dispatch(resetRequired(getValues()));
    dispatch(setLoadingStatus(false));
    history.push("/auth/accept-invite");
  }

  const onSubmit = (data) => {
    dispatch(setLoadingStatus(true));

    const config = {
      headers: {
        xt: csrfToken
      }
    };
    UserService.login({
      username: data.email.toLowerCase(),
      password: data.password
    }, config).then(result => {
      try {
        const loginResponse = result.data;
        if (result.status === 200 && result.data.accessToken) {
          console.log("===================== Login successful =======================");
          Auth.getInstance().setAuthToken(loginResponse.accessToken.jwtToken);
          Auth.getInstance().setIDToken(loginResponse.idToken.jwtToken);
          let token = loginResponse.idToken.jwtToken.split('.')[1];
          token = Buffer.from(token, 'base64').toString();
          // eslint-disable-next-line no-undef
          const userId = JSON.parse(token)['cognito:username'];
          localStorage.setItem('userId', userId);

          dispatch(setAWSUser(loginResponse.idToken.payload));
          dispatch(getProfile(userId)).then(response => {
            dispatch(setLoadingStatus(false));
            if (response.status === 401) {
              history.push("/auth/login");
            }

            Auth.getInstance().setUserData(response);
            // manageAppVersionWithRefresh();

            // setCookie('login-check-privacy', true, { path: "/" });
            localStorage.setItem('login-check-privacy', 'true');
            if (isParticipant()) {
              history.push("/deals");
            } else if (isGuestAgent()) {
              history.push("/invitation-deals");
            } else {
              history.push("/");
            }
          }).catch(err => {
            history.push("/auth/login");
          });
        } else if (loginResponse.isNewPasswordRequired) {
          handleNewPasswordRequired();
          return;
        } else {
          handleAuthError(loginResponse);
        }
      } catch (error) {
        dispatch(setLoadingStatus(false));
        console.log(error);
      }
    }).catch(error => {
      dispatch(setLoadingStatus(false));
      console.log(error);
      handleAuthError({ code: 'TokenNotAuthorized' });
    })
  };

  return (
    <Container component="main" maxWidth="xs">
      {/* <CircularProgress /> */}
      <CssBaseline />
      <div className={classes.paper}>
        <Box component="a" m={2} className={classes.logo} >
          <img src={process.env.PUBLIC_URL + "/images/boatdox-logo-blue.png"} alt="logo" className="h-75 w-75" />
        </Box>
        <Typography component="h4" variant="h6" gutterBottom>
          Sign In
        </Typography>

        {error && (
          <Alert variant="outlined" severity="error" className={classes.alert}>
            {error}
          </Alert>
        )}
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            autoFocus
            {...register("email")}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            id="password"
            {...register("password")}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" sx={{
              [`&, &.${checkboxClasses.checked}`]: {
                color: '#0f294b',
              },
            }} />}
            label="Remember me"
          />
          {/* {checkPrivacy !== true && ( */}
          <FormControlLabel
            control={
              <Checkbox color="primary" onChange={(e) => setAgreePolicy(e.target.checked)}
                checked={isAgreePolicy ? true : false}
                sx={{
                  [`&, &.${checkboxClasses.checked}`]: {
                    color: '#0f294b',
                  },
                }}
              />
            }
            label={
              <span>I agree to the
                <Link href="/terms" target="_blank"> Terms of Service </Link>
                and
                <Link href="/privacy" target="_blank"> Privacy Policy</Link>
              </span>
            }
          />
          {/* )} */}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isAgreePolicy ? false : true}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/auth/forgot-password" variant="body2" className={classes.typo}>
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="https://boatdox.org/contact/" target="_blank" variant="body2" className={classes.typo}>
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

export default LoginForm;