import { RETRIEVE_ESIGN_TEMPLATES } from "../actions/types";

const initialState = {
  all: [],
  current: {
    file_name: null,
    elements: [],
    roles: [],
  }
};

function templatesState(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_ESIGN_TEMPLATES:
      return {
        ...state,
        all: payload
      };
    // case UPDATE_ESIGN_STEP:
    //   return {
    //     ...state,
    //     current: {
    //       ...state.current,
    //       [payload['key']]: payload['data']
    //     }
    //   };
    default:
      return state;
  }
}

export default templatesState;
