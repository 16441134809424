import clsx from "clsx";
import DataTable from "react-data-table-component";
import Moment from "react-moment";
import datatableStyles from "../../../../../utils/datatable-style";

import { useDispatch, useSelector } from "react-redux";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import { getEdocCompanyTaskList } from "../../../../../redux/actions/transaction";
import { useEffect, useState } from "react";
import { ViewDocumentsModal } from "../../../../common/ViewDocumentsModal";

const UploadViewTable = ({
  data,
  show,
  setPredocumentType,
  setShowModal,
  setStatusShowModal,
  setUpdatingTask,
  companyId,
  transactionId,
  getTransactionEdoc,
}) => {
  const dispatch = useDispatch();

  const [showDocument, setShowDocument] = useState(false);
  const [document, setDocument] = useState(null);
  const isLoading = useSelector((state) => state.loading.status);
  const [documentTaskId, setDocumentTaskId] = useState(null);
  //Make a state for search
  const [search, setSearch] = useState({});

  useEffect(() => {
    getCompanyTaskList(search);
  }, [search]);

  const removeNullValues = (obj) => {
    for (const key in obj) {
      if (!obj[key]) {
        delete obj[key];
      }
    }
    return obj;
  };
  const getCompanyTaskList = (search) => {
    dispatch(setLoadingStatus(true));
    if (transactionId) {
      dispatch(
        getEdocCompanyTaskList(
          companyId,
          transactionId,
          removeNullValues(search)
        )
      )
        .then((response) => {
          dispatch(setLoadingStatus(false));
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
        });
    }
  };

  const handleOpenViewDocumentModal = (document) => {
    if (document.transaction_task_documents.length > 0) {
      setDocument(document.transaction_task_documents);
      setDocumentTaskId(document.master_task_id);
      setShowDocument(true);
    }
  };

  //Function for handle sort
  const handleSort = (column, sortDirection) => {
    setSearch((search) => {
      return {
        ...search,
        sortOrder: sortDirection,
        sortField: column.selector,
      };
    });
  };

  const columns = [
    {
      name: "",
      width: "80px",
      cell: (row) => (
        <div>
          <span>
            <i
              className={clsx(
                "fa",
                { "fa-check-circle": row.status === "received" },
                { "fa-circle": row.status === "Draft" },
                { "fa-circle text-danger": row.status === "required" },
                {
                  "fa-circle-o":
                    row.status === "open" ||
                    row.status === "not_available" ||
                    !row.status,
                }
              )}
            />
          </span>
        </div>
      ),
    },
    {
      name: "Order",
      selector: "sort_order",
      width: "120px",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row?.sort_order + 1 || " "
            }
          </div>
        </div>
      ),
    },
    {
      name: "Title",
      sortable: true,
      cell: (row) => <div>{row.company_tasks?.title || ""}</div>,
    },
    {
      name: "Status",
      center: true,
      cell: (row) => (
        <button
          onClick={() => {
            setUpdatingTask({ ...row, ...search });
            setStatusShowModal(true);
          }}
          className={`uk-button ${row.status == "required"
              ? "uk-button-dark"
              : row.status == "received"
                ? "uk-button-green"
                : row.status == "not_available"
                  ? "uk-button-grey"
                  : "uk-button-status"
            }`}
        >
          {row.status == "not_available" ? "N/A" : row.status || "Open"}
        </button>
      ),
    },
    {
      name: "Tag",
      width: "20rem",
      cell: (row) => (
        <div>
          {row.tags?.map(
            (tag, index) =>
              tag && (
                <small key={tag.id}>
                  {tag.tag}
                  {index !== row.tags?.length - 1 && ", "}
                </small>
              )
          )}
        </div>
      ),
    },
    {
      name: "Updated At",
      center: true,
      cell: (row) => {
        return new Date(row.updatedAt) > new Date(row.createdAt) ? (
          <Moment date={row.updatedAt} format="MM/DD/YYYY hh:mm A" />
        ) : (
          <>-</>
        );
      },
    },
    {
      name: "Documents",
      cell: (row) => (
        <div>
          <a
            className="badge badge-light"
            style={{ fontSize: "1rem" }}
            data-uk-tooltip="View Documents"
            onClick={(e) => handleOpenViewDocumentModal(row)}
          >
            {row.transaction_task_documents?.length}
          </a>
          <a
            className="ml-2"
            onClick={() => {
              setPredocumentType({ ...row, ...search });
              setShowModal(true);
            }}
          >
            <img
              src="/icons/upload.svg"
              className="svg"
              data-uk-tooltip="Upload Document"
            />
          </a>
        </div>
      ),
    },
  ];

  const handleCloseModal = () => {
    setShowDocument(false);
  };

  const onRefreshData = () => {
    dispatch(
      getEdocCompanyTaskList(companyId, transactionId, removeNullValues(search))
    );
    getTransactionEdoc();
  };

  if (!show) {
    return <></>;
  }
  return (
    <>
      {!data.length && isLoading
        ? <div className="no-data-table"><div className="p-4">Fetching Details...</div></div>
        : <DataTable
          columns={columns}
          data={data}
          highlightOnHover={true}
          striped={true}
          noHeader={true}
          customStyles={datatableStyles}
          onSort={handleSort}
        // loading={isLoading}
        />}

      {showDocument && (
        <ViewDocumentsModal
          documentTask={document}
          transactionId={transactionId}
          onClose={handleCloseModal}
          updateDocument={onRefreshData}
          taskMasterId={documentTaskId}
        />
      )}
    </>
  );
};

export default UploadViewTable;
