import { Helmet } from "react-helmet";

import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";

import PageMainContainer from "../common/PageMainContainer";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { clearAllTransactions, getAllByFilterOptions } from "../../redux/actions/transaction";
import { retrieveOffices } from "../../redux/actions/offices";
import { retrieveRelated } from "../../redux/actions/users";
import datatableStyles from "../../utils/datatable-style";
import ThemeButton from "../common/buttons/ThemeButton";
import { getPrice } from "../../utils/currency";
import { Pagination } from "@mui/material";
import Auth from "../../utils/auth"
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { setLoadingStatus } from "../../redux/actions/loading";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { makeStyles } from "@mui/styles";
import { DatePicker } from 'antd';
import dayjs from "dayjs";
import { dateRangePickerPresets } from "../../utils/AppConstants";

const { RangePicker } = DatePicker;

const useStyles = makeStyles({
  back_icon: {
    marginBottom: "3px",
    marginRight: "1px"
  },
  back_icon_span: {
    marginRight: "75px",
    fontSize: "larger"
  }
});


const ReportsListingAgreementsPage = () => {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataDeals = useSelector((state) => state.transactions.all);
  const dataOffices = useSelector((state) => state.offices);
  const dataBrokers = useSelector((state) => state.users.related);
  const filter = useSelector(state => state.report.filter);
  const isLoading = useSelector((state) => state.loading.status);

  const loggedUser = Auth.getInstance().getUserData();

  const [options, setOptions] = useState({
    office: "all",
    broker: "all",
    status: "all",
    dateRange: "this month",
    realRange: [
      moment().startOf("month").format("MM-DD-YYYY"),
      moment().format("MM-DD-YYYY"),
    ],
  });
  const [count, setCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);

  const [loadCount, setLoadCount] = useState(0);
  useEffect(() => {
    if (loadCount === 0) {
      dispatch(setLoadingStatus(false));
      setLoadCount(0);
    }
  }, [loadCount]);

  useEffect(() => {
    dispatch(setLoadingStatus(true));
    setLoadCount((prev) => prev + 1);
    dispatch(retrieveOffices());
    dispatch(retrieveRelated(loggedUser.id)).then(() => {
      // dispatch(setLoadingStatus(false));
      setLoadCount((prev) => prev - 1);
    }).catch(() => {
      dispatch(setLoadingStatus(false));
      setLoadCount((prev) => prev - 1);
    });

    return () => {
      dispatch(clearAllTransactions());
    };
  }, []);

  useEffect(() => {
    dispatch(setLoadingStatus(true));
    setLoadCount((prev) => prev + 1);
    dispatch(getAllByFilterOptions("listing", options, pageNo, 10)).then((res) => {
      setCount(res.count);
      // dispatch(setLoadingStatus(false));
      setLoadCount((prev) => prev - 1);
    }).catch((err) => {
      dispatch(setLoadingStatus(false));
      setLoadCount((prev) => prev - 1);
    });
  }, [options, pageNo]);

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      ...filter
    }));
  }, [filter])

  // function for change realRange of date in payload for api calling
  const handleDateRange = (dateRange) => {
    setOptions((prev) => {
      return {
        ...prev,
        realRange: [dateRange[0], dateRange[1]]
      }
    })
  }

  const handleSetOptions = (field) => (e) => {
    let value = e.target.value;
    if (field === "dateRange") {
      let range = [];
      switch (value) {
        case "this month": {
          range = [
            moment().startOf("month").format("MM-DD-YYYY"),
            moment().format("MM-DD-YYYY"),
          ];
          setOptions((prev) => {
            return {
              ...prev,
              dateRange: "this month",
              realRange: range,
            };
          });
          break;
        }
        case "last month": {
          range = [
            moment().subtract(1, "months").startOf("month").format("MM-DD-YYYY"),
            moment().subtract(1, "months").endOf("month").format("MM-DD-YYYY"),
          ];
          setOptions((prev) => {
            return {
              ...prev,
              dateRange: "last month",
              realRange: range,
            };
          });
          break;
        }
        case "this quarter": {
          range = [
            moment().startOf('quarter').format('MM-DD-YYYY'),
            moment().format('MM-DD-YYYY')
          ];
          setOptions((prev) => {
            return {
              ...prev,
              dateRange: "this quarter",
              realRange: range,
            };
          });
          break;
        }
        case "last quarter": {
          range = [
            moment().subtract(1, 'quarter').startOf('quarter').format('MM-DD-YYYY'),
            moment().subtract(1, 'quarter').endOf('quarter').format('MM-DD-YYYY'),
          ];
          setOptions((prev) => {
            return {
              ...prev,
              dateRange: "last quarter",
              realRange: range,
            };
          });
          break;
        }
        case "year to date": {
          range = [
            moment().startOf("year").format("MM-DD-YYYY"),
            moment().format("MM-DD-YYYY"),
          ];
          setOptions((prev) => {
            return {
              ...prev,
              dateRange: "year to date",
              realRange: range,
            };
          });
          break;
        }
        case "this year": {
          range = [
            moment().startOf("year").format("MM-DD-YYYY"),
            moment().endOf('year').format("MM-DD-YYYY"),
          ];
          setOptions((prev) => {
            return {
              ...prev,
              dateRange: "this year",
              realRange: range,
            };
          });
          break;
        }
        case "last year": {
          range = [
            moment().subtract(1, "years").startOf("year").format("MM-DD-YYYY"),
            moment().subtract(1, "years").endOf("year").format("MM-DD-YYYY"),
          ];
          setOptions((prev) => {
            return {
              ...prev,
              dateRange: "last year",
              realRange: range,
            };
          });
          break;
        }
        case "custom": {
          range = [moment().format("MM-DD-YYYY"), moment().format("MM-DD-YYYY")];
          setOptions((prev) => {
            return {
              ...prev,
              dateRange: "custom",
              realRange: range,
            };
          });
          break;
        }
        default:
          break;
      }
    } else {
      setOptions((prev) => {
        return {
          ...prev,
          [field]: value,
        };
      });
    }
  };

  const statusToShow = {
    progress: "In process",
    cancelled: "Cancelled",
    draft: "Draft",
    completed: "Sold",
    active: "Active",
  };

  const columns = [
    {
      name: "Vessel",
      selector: "vessel",
      cell: (row) => {
        let vessel = row.t_vessel || {};
        let vessel_title = `${vessel.length || ""} ${vessel.make || ""} ${vessel.model || ""} ${vessel.year || ""
          }`;
        vessel_title = vessel_title === "   " ? "N/A" : vessel_title;
        return <div>{vessel_title}</div>;
      },
    },
    {
      name: "Asking Price",
      selector: "asking_price",
      cell: (row) => {
        let terms = row.t_terms || {};
        return <div>{getPrice(terms.asking_price) || "N/A"}</div>;
      },
    },
    {
      name: "Commission",
      selector: "commission",
      cell: (row) => {
        let terms = row.t_terms || {};
        let gross = terms.commission_amount;
        if (!gross) {
          return "N/A";
        }
        return <div>{getPrice(gross)}</div>;
      },
    },
    {
      name: "Listed On",
      selector: "contract_date",
      cell: (row) => {
        let terms = row.t_terms || {};
        return (
          <div>
            <Moment date={terms.contract_date || ""} format="MM-DD-YYYY" />
          </div>
        );
      },
    },
    // {
    //   name: "Closing Date",
    //   selector: "closing_date",
    //   cell: (row) => {
    //     let offer = row.t_offers || {};
    //     return (
    //       <div>
    //         <Moment date={offer.closing_date || ""} format="MM-DD-YYYY" />
    //       </div>
    //     );
    //   },
    // },
    {
      name: "Status",
      selector: "status",
      cell: (row) => {
        let status = row.status || "draft";
        return <div>{statusToShow[status]}</div>;
      },
    },
    {
      name: "Broker",
      selector: "broker",
      cell: (row) => {
        let broker = row.broker || {};
        let name = `${broker.first_name || ""} ${broker.last_name || ""}`;
        name = name === " " ? "N/A" : name;
        return <div>{name}</div>;
      },
    },
    {
      name: "Office",
      selector: "office",
      cell: (row) => {
        let office = row.t_company_office || {};
        return <div>{office.office_name || "N/A"}</div>;
      },
    },
  ];

  const downloadExcel = () => {
    var exportData = [["Vessel", "Asking Price", "Possible Commission", "Listed On", "Status", "Broker", "Office"]]

    const getVessel = (data) => {
      const vessel = data?.t_vessel || {};
      let vessel_title = `${vessel.length || ""} ${vessel.make || ""} ${vessel.model || ""
        } ${vessel.year || ""}`;
      vessel_title = vessel_title === "   " ? "N/A" : vessel_title;
      return vessel_title;
    }

    const getAskPrice = (data) => {
      const terms = data?.t_terms || {};
      return getPrice(terms.asking_price) || "N/A";
    }

    const getCommissions = (data) => {
      const terms = data?.t_terms || {};
      return getPrice(terms.commission_amount) || "N/A";
    }

    const getListOn = (data) => {
      const terms = data?.t_terms || {};
      return terms.contract_date || "N/A";
    }

    const getStatus = (data) => {
      const status = data?.status || "draft";
      return statusToShow[status];
    }
    const getBroker = (data) => {
      const broker = data?.broker || {};
      let name = `${broker.first_name || ""} ${broker.last_name || ""}`;
      name = name === " " ? "N/A" : name;
      return name;
    }
    const getOffice = (data) => {
      const office = data?.t_company_office || {};
      return office.office_name || "N/A";
    }

    dataDeals?.map((data) => {
      exportData = [...exportData, [getVessel(data),
      getAskPrice(data),
      getCommissions(data),
      getListOn(data),
      getStatus(data),
      getBroker(data),
      getOffice(data)]]
    }
    )
    const ws = XLSX.utils.aoa_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAsExcel(excelBuffer, 'Listing-Report.xlsx');
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  };
  return (
    <PageMainContainer type="view-report">
      <Helmet>
        <title>Report Listing Agreements - BoatDox</title>
      </Helmet>
      <div className="d-flex flex-wrap justify-content-between  align-items-center ">
        <div>
          <ul className="uk-breadcrumb">
            <li>
              <Link to={"/"}>Reports</Link>
            </li>
          </ul>
          <h1>Listing Agreements</h1>
        </div>
        <div>
          <button className='uk-button uk-button-primary mb-0 mt-0 pl-2 text-capitalize' onClick={(e) => {
            e.preventDefault();
            history.push("/")
          }}>
            <FirstPageIcon className={classes.back_icon}></FirstPageIcon>
            <span>Back</span>
          </button>
        </div>
      </div>
      <div className="reports-filter d-flex justify-content-between align-items-center flex-wrap">
        <div className="w-75 w-sm-50">
          <select className="uk-select my-1" value={options.office} onChange={handleSetOptions("office")}>
            <option value="all">All Offices</option>
            {dataOffices.map((office) => {
              return (
                <option key={office.id} value={office.id}>
                  {office.office_name}
                </option>
              );
            })}
          </select>

          <select className="uk-select my-1" value={options.broker} onChange={handleSetOptions("broker")}>
            <option value="all">All Brokers</option>
            {dataBrokers.map((client) => {
              return (
                <option key={client.id} value={client.id}>
                  {`${client.first_name || ""} ${client.last_name || ""}`}
                </option>
              );
            })}
          </select>

          <select className="uk-select my-1" value={options.status} onChange={handleSetOptions("status")}>
            <option value="all">All Stages</option>
            <option value="active">Active</option>
            <option value="progress">In-Process</option>
            <option value="draft">Draft</option>
            <option value="completed">Sold</option>
            <option value="cancelled">Cancelled</option>
          </select>

          <select
            className="uk-select my-1"
            value={options.dateRange}
            onChange={handleSetOptions("dateRange")}
          >
            <option value="this month">This Month</option>
            <option value="last month">Last Month</option>
            <option value="this quarter">This Quarter</option>
            <option value="last quarter">Last Quarter</option>
            <option value="this year">This Year</option>
            <option value="year to date">Year To Date</option>
            <option value="last year">Last Year</option>
            <option value="custom">Custom</option>
          </select>

          {options.dateRange === "custom" && (
            <RangePicker
              style={{ height: "40px", width: "220px" }}
              format="MM-DD-YYYY"
              allowClear={true}
              presets={dateRangePickerPresets}
              onChange={(value, dateString) => {
                handleDateRange(dateString);
              }}
            />
          )} </div>
        <div>

          <button className='btn btn-previous btn-sm' onClick={downloadExcel}>
            <div> <CloudDownloadIcon />  Download</div>
          </button>
        </div>
      </div>
      {!dataDeals.length && isLoading
        ? <div className="no-data-table"><div className="p-4">Fetching Details...</div></div>
        : <DataTable noHeader customStyles={datatableStyles} columns={columns} data={dataDeals} />
      }
      <Pagination
        count={Math.ceil(count / 10)}
        variant="outlined"
        shape="rounded"
        page={pageNo}
        onChange={(e, page) => setPageNo(page)}
      />
    </PageMainContainer>
  );
};

export default ReportsListingAgreementsPage;
