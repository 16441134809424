import ModalBody from "../../../../common/ModalBody";
import OffCanvasContainer from "../../../../common/OffCanvasContainer";
import DetailOfToys from "../details/DetailOfToys";

const ViewToysForm = ({ toys }) => {
  return (
    <OffCanvasContainer id="modal-toys">
      <ModalBody title="Toys & Tenders">
        <DetailOfToys toys={toys} />
      </ModalBody>
    </OffCanvasContainer>
  );
};

export default ViewToysForm;
