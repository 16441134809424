import React, { useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from "@hookform/resolvers/yup";

import Form from '../../../../../common/Form';
import FormRow from '../../../../../common/FormRow';
import FormInputWrapper from '../../../../../common/FormInputWapper';
import { getFigure, getPrice } from '../../../../../../utils/currency';
import { setLoadingStatus } from '../../../../../../redux/actions/loading';
import { getFormattedData, getPlainTransactionData } from '../../../../../../utils/stepFilter';
import { buyerSellerTransaction, getTransactionEsignById, upsertTransaction } from '../../../../../../redux/actions/transaction';

import moment from 'moment';
import * as yup from "yup";
import dayjs from 'dayjs';
import { DatePicker } from 'antd';

const TabFormDeposits = ({ closePanel }) => {

    const schema = yup.object().shape({});

    const { register, handleSubmit, setValue, reset, getValues, watch, formState: { isDirty, errors }, control } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: false,
    });

    const dispatch = useDispatch();

    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataDeal = dataTransaction || {};
    const offers = dataDeal.t_offers || {};
    const dataDetail = dataDeal.t_details || [];

    useEffect(() => {

        // need to do clean all deposits before fill updated values
        for (let i = 0; i < 5; i++) {
            setValue(`deposit${i + 1}`, { date: null, amount: null, description: null });
        }

        const deposits = dataDetail.filter((d) => d.type === "deposits" && d.document_type === "buyer");

        if (deposits.length > 0) {
            deposits.forEach((deposit, idx) => {
                deposit.date = deposit.date ? moment(deposit.date).format("yyyy-MM-DD") : null;
                deposit.amount = deposit.amount !== null ? getPrice(deposit.amount) : null;
                setValue(`deposit${idx + 1}`, deposit);
            });
            return;
        }
    }, [dataTransaction]);


    useEffect(() => {
        if (offers.less_deposit) {
            setValue('deposit1.description', 'Initial Deposit');
            setValue('deposit1.amount', getPrice(offers.less_deposit));
        }
        const initialDetails = dataDetail.filter((detail) => detail?.type === "deposits" && detail?.description === "Initial Deposit" && detail?.document_type === "buyer")[0]
        if (initialDetails?.amount !== getPrice(offers.less_deposit) && offers.less_deposit) {
            initialSubmit(getValues())
        }
    }, [])


    const getPayload = (data) => {
        let deposits = [];
        Object.values(data).forEach((val) => {
            const { date, description, amount } = val;
            if (!!date || !!description || !!amount) {
                val.amount = getFigure(val.amount);
                val.date = val.date ? moment(val.date).format() : null;
                val.type = "deposits";
                val.document_type = "buyer";
                val = getFormattedData(val);
                deposits.push(val);
            }
        });
        return deposits
    }

    const initialSubmit = (data) => {
        let deposits = getPayload(data);

        dispatch(setLoadingStatus(true));
        dispatch(buyerSellerTransaction({
            step: {
                db: "details",
                main: deposits,
                detailType: "deposits",
                documentType: "buyer"
            },
            transaction: {
                isBelonged: false,
                main: { ...getPlainTransactionData(dataDeal), task_type: "deal" },
            },
        }, "Deposit's")
        ).then((data) => {
            dispatch(setLoadingStatus(false));
            dispatch(getTransactionEsignById(data.id));
        }).catch((err) => {
            dispatch(setLoadingStatus(false));
        })
    }


    const handleBlur = (ref) => {
        const price = getPrice(getValues(`deposit${ref}.amount`));
        setValue(`deposit${ref}.amount`, price);
    };

    const handleFocus = (ref) => {
        const figure = getFigure(getValues(`deposit${ref}.amount`));
        setValue(`deposit${ref}.amount`, figure);
    };

    const handleRegistration = (data, isFinishLater = false) => {
        let deposits = getPayload(data);

        dispatch(setLoadingStatus(true));
        dispatch(upsertTransaction({
            step: {
                db: "details",
                main: deposits,
                detailType: "deposits",
                documentType: "buyer"
            },
            transaction: {
                isBelonged: false,
                main: { ...getPlainTransactionData(dataDeal), task_type: "deal" },
            },
        }, "Deposit's")
        ).then((data) => {
            dispatch(setLoadingStatus(false));
            dispatch(getTransactionEsignById(data.id));
        }).catch((error) => {
            console.log(error);
            dispatch(setLoadingStatus(false));
        });
    };

    const handleError = (error) => {
        console.log(error);
    };

    return (
        <div className="form-container panel-form">
            <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                <FormRow>
                    <FormInputWrapper label="Deposit Dates">
                        <Controller
                            control={control}
                            name="deposit1.date"
                            render={({ field }) => (
                                <DatePicker
                                    className="uk-input"
                                    style={{ cursor: "pointer", color: "#000" }}
                                    format={'MM/DD/YYYY'}
                                    value={field.value && dayjs(field.value)}
                                    onChange={(date, dateString) => {
                                        field.onChange(dateString);
                                    }}
                                />
                            )}
                        />
                    </FormInputWrapper>
                    <FormInputWrapper label="Deposit Description">
                        <input type="text" className="uk-input" {...register("deposit1.description")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Deposit Amount">
                        <input type="text" className="uk-input" {...register("deposit1.amount")}
                            onBlur={() => handleBlur(1)} onFocus={() => handleFocus(1)} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="">
                        <Controller
                            control={control}
                            name="deposit2.date"
                            render={({ field }) => (
                                <DatePicker
                                    className="uk-input"
                                    style={{ cursor: "pointer", color: "#000" }}
                                    format={'MM/DD/YYYY'}
                                    value={field.value && dayjs(field.value)}
                                    onChange={(date, dateString) => {
                                        field.onChange(dateString);
                                    }}
                                />
                            )}
                        />
                    </FormInputWrapper>
                    <FormInputWrapper label="">
                        <input type="text" className="uk-input" {...register("deposit2.description")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="">
                        <input type="text" className="uk-input" {...register("deposit2.amount")}
                            onBlur={() => handleBlur(2)} onFocus={() => handleFocus(2)} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="">
                        <Controller
                            control={control}
                            name="deposit3.date"
                            render={({ field }) => (
                                <DatePicker
                                    className="uk-input"
                                    style={{ cursor: "pointer", color: "#000" }}
                                    format={'MM/DD/YYYY'}
                                    value={field.value && dayjs(field.value)}
                                    onChange={(date, dateString) => {
                                        field.onChange(dateString);
                                    }}
                                />
                            )}
                        />
                    </FormInputWrapper>
                    <FormInputWrapper label="">
                        <input type="text" className="uk-input" {...register("deposit3.description")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="">
                        <input type="text" className="uk-input" {...register("deposit3.amount")}
                            onBlur={() => handleBlur(3)} onFocus={() => handleFocus(3)} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="">
                        <Controller
                            control={control}
                            name="deposit4.date"
                            render={({ field }) => (
                                <DatePicker
                                    className="uk-input"
                                    style={{ cursor: "pointer", color: "#000" }}
                                    format={'MM/DD/YYYY'}
                                    value={field.value && dayjs(field.value)}
                                    onChange={(date, dateString) => {
                                        field.onChange(dateString);
                                    }}
                                />
                            )}
                        />
                    </FormInputWrapper>
                    <FormInputWrapper label="">
                        <input type="text" className="uk-input" {...register("deposit4.description")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="">
                        <input type="text" className="uk-input" {...register("deposit4.amount")}
                            onBlur={() => handleBlur(4)} onFocus={() => handleFocus(4)} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="">
                        <Controller
                            control={control}
                            name="deposit5.date"
                            render={({ field }) => (
                                <DatePicker
                                    className="uk-input"
                                    style={{ cursor: "pointer", color: "#000" }}
                                    format={'MM/DD/YYYY'}
                                    value={field.value && dayjs(field.value)}
                                    onChange={(date, dateString) => {
                                        field.onChange(dateString);
                                    }}
                                />
                            )}
                        />
                    </FormInputWrapper>
                    <FormInputWrapper label="">
                        <input type="text" className="uk-input" {...register("deposit5.description")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="">
                        <input type="text" className="uk-input" {...register("deposit5.amount")}
                            onBlur={() => handleBlur(5)} onFocus={() => handleFocus(5)} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <div className="text-right pt-3">
                        <button type="button" className='uk-button uk-button-orange' onClick={() => closePanel(true)}>Close</button>
                        <button type="submit" className='uk-button uk-button-primary'>Save</button>
                    </div>
                </FormRow>
            </Form>
        </div>
    )
}

export default TabFormDeposits;