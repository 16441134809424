export const SOLE_OWNER = "SOLE_OWNER";
export const JOINT_OWNER = "JOINT_OWNER";

export const LEGAL_OWNER = "LEGAL_OWNER";
export const OWNER_OF_RECORD = "OWNER_OF_RECORD";
export const LEGAL_OR_ASSIGN = "LEGAL_OR_ASSIGN";
export const LEGAL_AND_OR_ASSIGN = "LEGAL_AND_OR_ASSIGN";
export const ENTITY_AND_OR_ASSIGN = "ENTITY_AND_OR_ASSIGN";
export const RECORD_OWNER = "OWNER_OF_RECORD";

export const TRUST_OWNED = "TRUST_OWNED";
export const LLC_OWNED = "LLC_OWNED";
export const CORPORATE_OWNED = "CORPORATE_OWNED";

export const CLIENT_REQUIRED_FIELDS = ['first_name', 'last_name', 'email', 'mobile_phone'];

export const getSigner = (signers, role) => {
  return signers.find(signer => signer.request_signer.role === role)
}