import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { DELETE_MODAL_MESSAGE, DELETE_MODAL_TITLE } from "../../constants/Alert";
import { getTaskByChecklistId, removeTask } from "../../redux/actions/document_tasks";
import datatableStyles from "../../utils/datatable-style";
import clsx from "clsx";
import DocumentEditTable from "./DocumentEditTable";
import { toast } from "react-toastify";
import { setLoadingStatus } from "../../redux/actions/loading";
import { Tooltip } from "@mui/material";
import DocumentAndTaskService from "../../services/DocumentAndTaskService";


const DocumentTaskListTable = (props) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);

  const { checklist_id, path, breadcrumb, model, tab } = props.location.state;
  const dataTasks = useSelector((state) => state.documents.tasks);
  const [searchName, setSearchName] = useState("");
  const [activeTasks, setActiveTasks] = useState(dataTasks.filter((task) => task.active));
  const [inactiveTasks, setInactiveTasks] = useState(dataTasks.filter((task) => !task.active));
  const [currentTab, setCurrentTab] = useState(tab || "document");

  const isLoading = useSelector((state) => state.loading.status);
  // dataForUpdateListOrder State Will Contain Updated Data With Updated SortOrder Which Will Use In updateOrder's Api Payload
  const [dataForUpdateListOrder, setDataForUpdateListOrder] = useState([]);

  const user = JSON.parse(localStorage.getItem("user"));
  const company_id = user.company_id;
  const prefix = path.includes("iyba") ? "BOATDOX ADMIN" : "Company";

  const titles = {
    "document": "Uploads List",
    "task": "Tasks List",
    "form": "Forms List"
  }

  // added currentTab's dependancy for get recent data everytime
  useEffect(() => {
    getAllTasksByChecklistId();
    setIsEdit(false)
  }, [currentTab]);

  // function for get tasks by checklist id
  async function getAllTasksByChecklistId() {
    dispatch(setLoadingStatus(true));
    await dispatch(getTaskByChecklistId(checklist_id, model, company_id));
    dispatch(setLoadingStatus(false));
  }

  useEffect(() => {
    setActiveTasks(dataTasks.filter((task) => task.active && task.title.indexOf(searchName) > -1));
    setInactiveTasks(
      dataTasks.filter((task) => !task.active && task.title.indexOf(searchName) > -1)
    );
  }, [searchName, dataTasks]);

  // updateListOrder function made for call updateListOrder's api which will update sortOrder as per change
  const updateListOrder = async (model, data) => {
    dispatch(setLoadingStatus(true));
    try {
      await DocumentAndTaskService.updateListOrder(model, data);
      await getAllTasksByChecklistId();
      dispatch(setLoadingStatus(false));
    } catch (error) {
      dispatch(setLoadingStatus(false));
      toast.error("Error, While update sort order", {
        autoClose: 2000
      })
    }
  }

  // function for handle click of updateListOrder
  const handleUpdateListOrder = async (isCancel = false) => {
    // if there is any changes in sort order then only call updateListOrder's Api
    if ((!isCancel) && isEdit && dataForUpdateListOrder.length > 0) {
      await updateListOrder(dataForUpdateListOrder[0], dataForUpdateListOrder[1]);
    } else {
      getAllTasksByChecklistId();
    }
    setIsEdit(!isEdit);
    setDataForUpdateListOrder([]);
  }

  const columns = [
    {
      name: "Order",
      selector: "sort_order",
      width: "110px",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row?.sort_order + 1 || " "
            }
          </div>
        </div>
      ),
    },
    {
      name: "Title",
      selector: "title",
      sortable: true,
      cell: (row) => <span>{row.title}</span>,
    },
    {
      name: "Type",
      selector: "type",
      center: true,
      width: "12rem",
      cell: (row) => <span style={{ textTransform: 'capitalize' }}>{row.task_type}</span>,
    },
    {
      name: "Tags",
      selector: "tags",
      width: "20rem",
      cell: (row) => <div className="">{(row.tags_name?.split(',') || []).map((tag, index) => <small key={tag}>{tag}{index !== row.tags_name.split(',').length - 1 && ', '}</small>)}</div>,
    },
    {
      name: "Action",
      selector: "operation",
      width: "12rem",
      cell: (row) => {
        return (
          <div>
            <Link
              to={{
                pathname: `${path}/edit`,
                state: {
                  task: row,
                  checklist_id: checklist_id,
                  breadcrumb: breadcrumb,
                  path: path,
                  model: model,
                  tab: currentTab,
                  company_id
                },
              }}
            >
              <Tooltip title="Edit" placement="left">
                <img src="/icons/edit.svg" className="svg uk-margin-right" alt="" />
              </Tooltip>
            </Link>
            <Tooltip title="Delete " placement="right">
              <img
                src="/icons/delete.svg"
                className="svg uk-margin-right"
                onClick={() => onDelete(user?.UserInRole?.UserRole?.name == 'Boatdox Admin'
                  ? row.iyba_task_id
                  : row.company_task_id)
                }
                alt=""
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  if (currentTab == 'document' && user?.UserInRole?.UserRole?.name != 'Boatdox Admin') {
    const actions = columns.pop();
    columns.splice(columns.length, 1);
    columns.push({
      name: "Status",
      center: true,
      cell: (row) => <span style={{ textTransform: 'capitalize' }}>{row.status}</span>
    }, actions)
  }

  const onDelete = (id) => {
    confirmAlert({
      title: DELETE_MODAL_TITLE,
      message: DELETE_MODAL_MESSAGE,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(setLoadingStatus(true));
            dispatch(removeTask(id, model))
              .then((res) => {
                dispatch(setLoadingStatus(false));
                toast.success('Task removed successfully.');
              })
              .catch(error => {
                dispatch(setLoadingStatus(false));
                toast.error('Task not removed.');
              })
            dispatch(getTaskByChecklistId(checklist_id, model, company_id));
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  let activeTask = activeTasks.filter((task) => task.task_type === currentTab)
  activeTask.sort((a, b) => (b.sort_order > a.sort_order ? -1 : 1))

  return (
    <div className="uk-container uk-container-expand uk-position-relative">
      <ul className="uk-breadcrumb">
        <li>
          <Link to={{ pathname: path, state: { model: model } }}>Uploads List & Tasks</Link>
        </li>
        <li className="uk-disabled">
          <a>{breadcrumb}</a>
        </li>
      </ul>
      <div className="content">
        <h1>{titles[currentTab]}</h1>
        <div className="uk-column-1-2  uk-margin-small  ">
          <Link
            to={{
              pathname: `${path}/add`,
              state: {
                checklist_id: checklist_id,
                path: path,
                breadcrumb: breadcrumb,
                model: model,
                tab: currentTab,
                company_id
              },
            }}
          >
            <div className="uk-button uk-button-default add-deal my-2">
              <span className="uk-margin-small-right uk-icon" data-uk-icon="plus"></span> Add
            </div>
          </Link>
          {!isEdit && <div className="list-page-search-container my-2">
            <form className="uk-search uk-search-default search-top-margin">
              <input
                className="uk-search-input"
                type="search"
                placeholder="Search"
                id="search"
                onChange={(e) => setSearchName(e.target.value)}
                value={searchName}
              />
              {searchName === "" ? (
                <span className="bd-form-icon">
                  <i className="fa fa-search"></i>
                </span>
              ) : (
                <span className="bd-form-icon" onClick={() => setSearchName("")}>
                  <i className="fa fa-times"></i>
                </span>
              )}
            </form>
          </div>}
        </div>
        <div className="uk-position-relative uk-margin-small">
          <ul data-uk-tab="swiping: false" className="uk-tab">
            <li className={clsx("tasks-tab", currentTab === "document" ? "uk-active" : null)} onClick={() => {
              setCurrentTab("document")
            }}>
              <a aria-expanded="true">Uploads List</a>
            </li>
            <li className={clsx("tasks-tab", currentTab === "task" ? "uk-active" : null)} onClick={() => setCurrentTab("task")}>
              <a aria-expanded="false">Tasks</a>
            </li>
            {prefix === 'BOATDOX ADMIN' && (
              <li className={clsx("tasks-tab", currentTab === "form" ? "uk-active" : null)} onClick={() => setCurrentTab("form")}>
                <a aria-expanded="false">Forms</a>
              </li>
            )}
          </ul>
        </div>
        <div className="nav-operation-buttons">
          <button className={`uk-button ${isEdit ? "uk-button-primary" : "uk-button-default"}`} disabled={searchName} onClick={() => handleUpdateListOrder()}>
            {isEdit ? "Save Sort" : "Edit Sort"}
          </button>
          {isEdit && <button className="uk-button uk-button-orange" onClick={() => handleUpdateListOrder(true)}>
            Cancel
          </button>}
        </div>
        {
          isEdit &&
          <DocumentEditTable model={model} data={activeTasks.filter((task) => task.task_type === currentTab)} show={isEdit} setDataForUpdateListOrder={setDataForUpdateListOrder} />
        }
        {
          (!isEdit) &&
          <>
            {!activeTask?.length && isLoading
              ? <div className="no-data-table"><div className="p-4">Fetching Details...</div></div>
              : <DataTable
                title="Active tasks"
                columns={columns}
                // data={activeTasks.filter((task) => task.task_type === currentTab)}
                data={activeTask}
                striped={true}
                highlightOnHover={true}
                pointerOnHover={true}
                customStyles={datatableStyles}
                noHeader={true}
              />}
            <br />
            <br />
            <h3>Inactive Tasks</h3>
            {!inactiveTasks?.length && isLoading
              ? <div className="no-data-table"><div className="p-4">Fetching Details...</div></div>
              : <DataTable
                title="Inactive tasks"
                columns={columns}
                data={inactiveTasks.filter((task) => task.task_type === currentTab)}
                striped={true}
                highlightOnHover={true}
                pointerOnHover={true}
                customStyles={datatableStyles}
                noHeader={true}
              />}
          </>

        }
      </div>
    </div>
  );
};

export default DocumentTaskListTable;
