import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setLoadingStatus } from '../../redux/actions/loading';
import { fileUploadWithServer, uploadForms } from '../../utils/s3';
import { convertUnderscoreSeparateLetter } from '../../utils/functions';
import { phoneNumber } from '../../utils/phonenumber';

import PercentageLoading from './PercentageLoading';
import DataTable from 'react-data-table-component';
import TransactionService from '../../services/TransactionService';
import datatableStyles from '../../utils/datatable-style';
import { CORPORATE_OWNED, ENTITY_AND_OR_ASSIGN, LLC_OWNED, OWNER_OF_RECORD, TRUST_OWNED } from '../../utils/signer';
import { useRouteMatch } from 'react-router-dom';
import Auth from '../../utils/auth';

const HandSignUploadModal = ({ show, onClose, signData }) => {

    const dispatch = useDispatch();
    const dataTransaction = useSelector((state) => state.transactions.transaction);
    let { path } = useRouteMatch();
    const loginEmail = Auth.getInstance().getUserData().email;

    const [files, setFiles] = useState([]);
    const [validationMessage, setValidationMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loadingState, setLoadingState] = useState(0);

    const [senderRole, setSenderRole] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isFullyHandSigned, setIsFullyHandSigned] = useState(false)
    const [transactionData, setTransactionData] = useState({})

    const fetchParticipants = async () => {
        dispatch(setLoadingStatus(true));
        await TransactionService.getAllParticipants(dataTransaction.id).then(async (response) => {
            const transactionParticipants = response.data?.transaction;
            setTransactionData(transactionParticipants)
            const customParticipants = response.data?.participants;
            const participantEmailList = customParticipants.map((participant) => participant.t_participant?.user?.email);
            let participantData = [];
            for (const index in transactionParticipants) {
                if (Object.hasOwnProperty.call(transactionParticipants, index)) {
                    const element = transactionParticipants[index];
                    if (element) {
                        if (index == 'broker') {
                            if (transactionParticipants.task_type == 'listing') {
                                await prepareParticipantList(index, element, participantData, participantEmailList, transactionParticipants);
                            }
                        } else {
                            await prepareParticipantList(index, element, participantData, participantEmailList, transactionParticipants);
                        }
                    }
                }
            }
            customParticipants.map((participant) => {
                participantData.push({
                    participantId: participant.id,
                    id: `${participant.t_participant?.user?.id}`,
                    title: `${participant.t_participant?.user?.title || 'N/A'}`,
                    first_name: participant.t_participant?.user?.first_name,
                    last_name: participant.t_participant?.user?.last_name,
                    participant_company_name: participant.t_participant?.user?.participant_company_name,
                    mobile_phone: participant.t_participant?.user?.mobile_phone,
                    name: `${(participant.t_participant?.user?.first_name || participant.t_participant?.user?.last_name) ? ((participant.t_participant?.user?.first_name || '') + ' ' + (participant.t_participant?.user?.last_name || '')) : 'N/A'}`,
                    email: `${participant.t_participant?.user?.email || 'N/A'}`,
                    mobile: `${participant.t_participant?.user?.mobile_phone || ''}`,
                    company: `${participant.t_participant?.user?.participant_company_name || 'N/A'}`,
                    participant_auth_docs: participant.participant_auth_docs || [],
                    isCustom: true,
                    status: participant.status,
                    userType: participant.user_type || ""
                });
            });
            let selectedRecipientList = [];
            const clientRole = signData?.role?.replace('-', ' ');
            const currentRole = clientRole === "Owner"
                ? "Seller"
                : clientRole === "Co Owner"
                    ? "Co Seller"
                    : clientRole
            setSenderRole(currentRole);
            if (path.includes("invitation")) {
                if (dataTransaction?.co_broker?.listing_broker_email === loginEmail) {
                    participantData = participantData.filter((participant) => !['Buyer', 'Co Buyer', 'Selling Broker'].includes(participant.title));
                } else if (dataTransaction?.co_broker?.selling_broker_email === loginEmail) {
                    participantData = participantData.filter((participant) => !['Seller', 'Co Seller', 'Listing Broker'].includes(participant.title));
                }
            }
            if (!path.includes("invitation") && dataTransaction.is_co_seller_hide_by_invited_broker) {
                participantData = participantData.filter((participant) => !['Co Seller'].includes(participant.title));
            }
            if (!path.includes("invitation") && dataTransaction.is_seller_hide_by_invited_broker) {
                participantData = participantData.filter((participant) => !['Seller'].includes(participant.title));
            }
            if (!path.includes("invitation") && dataTransaction.is_co_buyer_hide_by_invited_broker) {
                participantData = participantData.filter((participant) => !['Co Buyer'].includes(participant.title));
            }
            if (!path.includes("invitation") && dataTransaction.is_buyer_hide_by_invited_broker) {
                participantData = participantData.filter((participant) => !['Buyer'].includes(participant.title));
            }
            // Check if selected the recipients previously for send email
            if (signData.selectedRecipients?.length) {
                const recipientEmails = signData.selectedRecipients.map((participant) => participant.match(/<([^>]+)>/)[1]);
                selectedRecipientList = participantData.filter((participant) => recipientEmails.includes(participant.email));
            } else {
                // if no recipients selected previously then select the role as default selected
                selectedRecipientList = participantData.filter((participant) => participant.title == currentRole) || [];
            }

            setSelectedUsers(selectedRecipientList);
            setParticipants(moveUserToFront(participantData, currentRole));
            dispatch(setLoadingStatus(false));
        }).catch((error) => {
            dispatch(setLoadingStatus(false));
            console.log(error);
        });
    }

    /**
     * set the selected user at the top of the list by matching with email address
     * @param {*} users 
     * @param {*} targetRole 
     * @returns List of users with selected email at the top of list
    */
    function moveUserToFront(users, targetRole) {
        const index = users.findIndex(user => user.title === targetRole);
        if (index > -1) {
            const [user] = users.splice(index, 1); // Remove the user from the array
            users.unshift(user); // Add the user to the front of the array
        }
        return users;
    }

    const prepareParticipantList = async (type, element, participantData, participantEmailList = [], transactionParticipants) => {
        let participantArray = participantData;
        switch (type) {
            case 'buyer':
                participantArray.push({
                    title: convertUnderscoreSeparateLetter(type),
                    name: ` ${[ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(transactionParticipants?.buyer_type) > -1 ? element?.trust_signer_name : (element.first_name || element.last_name) ? ((element.first_name || '') + ' ' + (element.middle_name || '') + ' ' + (element.last_name || '')) : 'N/A'}`,
                    email: `${[ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(transactionParticipants?.buyer_type) > -1 ? element?.trust_signer_email || 'N/A' : element.email || 'N/A'}`,
                    mobile: `${[ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(transactionParticipants?.buyer_type) > -1 ? element?.trust_signer_phone || 'N/A' : element.mobile_phone || 'N/A'}`,
                    company: `${element.c_company?.company_name || 'N/A'}`,
                    isFormData: true,
                });
                break;
            case 'co_buyer':
            case 'co_seller':
                participantArray.push({
                    title: convertUnderscoreSeparateLetter(type),
                    name: ` ${(element.first_name || element.last_name) ? ((element.first_name || '') + ' ' + (element.middle_name || '') + ' ' + (element.last_name || '')) : 'N/A'}`,
                    email: `${element.email || 'N/A'}`,
                    mobile: `${element.mobile_phone || ''}`,
                    company: `${element.c_company?.company_name || 'N/A'}`,
                    isFormData: true,
                });
                break;
            case 'seller':
                participantArray.push({
                    title: convertUnderscoreSeparateLetter(type),
                    name: ` ${(element.first_name || element.last_name) ? ((element.first_name || '') + ' ' + (element.middle_name || '') + ' ' + (element.last_name || '')) : [OWNER_OF_RECORD].indexOf(transactionParticipants?.seller_type) > -1 ? "Owner of Record" : 'N/A'}`,
                    email: `${element.email || 'N/A'}`,
                    mobile: `${element.mobile_phone || ''}`,
                    company: `${element.c_company?.company_name || 'N/A'}`,
                    isFormData: true,
                });
                break;
            case 'co_broker':
                if (element.selling_broker_email && !participantEmailList.includes(element.selling_broker_email)) {
                    participantArray.push({
                        title: `Selling Broker`,
                        name: `${element.selling_brokerage_broker || 'N/A'}`,
                        email: `${element.selling_broker_email || 'N/A'}`,
                        mobile: `${element.selling_broker_phone_number || ''}`,
                        company: `${element.selling_brokerage || 'N/A'}`,
                        is_selling_broker_from_other_company: element.is_selling_broker_from_other_company,
                    });
                } if (element.listing_broker_email && !participantEmailList.includes(element.listing_broker_email)) {
                    participantArray.push({
                        title: `Listing Broker`,
                        name: `${element.listing_brokerage_broker || 'N/A'}`,
                        email: `${element.listing_broker_email || 'N/A'}`,
                        mobile: `${element.listing_broker_phone_number || ''}`,
                        company: `${element.listing_brokerage || 'N/A'}`,
                        is_listing_broker_from_other_company: element.is_listing_broker_from_other_company,
                    });
                }
                break;

            case 'employing_broker':
            case 'broker':
                participantArray.push({
                    title: convertUnderscoreSeparateLetter(type),
                    name: `${(element.first_name || element.last_name) ? ((element.first_name || '') + ' ' + (element.last_name || '')) : 'N/A'}`,
                    email: `${element.email || 'N/A'}`,
                    mobile: `${element.mobile_phone || ''}`,
                    company: `${element.company?.company_name || 'N/A'}`
                });
                break;

            default:
                break;
        }
        return participantArray;
    }

    useEffect(() => {
        setFiles([]);
        setValidationMessage(``);
        setIsLoading(false);
        setLoadingState(0);
        if (show) {
            fetchParticipants();
        }
    }, [show]);

    const handleChange = (e) => {
        if (e.target?.files?.length) {
            setValidationMessage(``);
        }
        for (let index = 0; index < e.target.files.length; index++) {
            setFiles(files =>
                [
                    ...files,
                    e.target.files[index]
                ]
            )
        }
    };

    const uploadFile = async (file) => {
        // Create a new File object with the new name
        let newFile = new File([file], file.name.replace(/[$#&%]/g, "_"), { type: file.type });

        try {
            const filename = await uploadForms(newFile, 'hand-signed-documents', `PS-${dataTransaction.unique_transaction_id?.split('-')[1] || ""}`, `${dataTransaction.unique_transaction_id?.split('-')[0] || ""}`);
            return {
                ...newFile,
                file_name: filename,
                original_file_name: newFile.name,
            };
        } catch (error) {
            console.error(error);
            toast.error(error.message);
            dispatch(setLoadingStatus(false));
            return file;
        }
    }

    const handleUpload = async () => {
        if (files.length) {
            dispatch(setLoadingStatus(true));
            try {
                setIsLoading(true);
                let filesToUpload = [];
                let serverUploadFiles = [];
                for (const index in files) {
                    if (Object.hasOwnProperty.call(files, index)) {
                        const element = files[index];
                        const documentFile = await uploadFile(element);
                        filesToUpload[index] = documentFile;
                        serverUploadFiles.push(new File([element], documentFile.file_name, {
                            type: element.type,
                            lastModified: element.lastModified,
                        }));
                        setLoadingState((((parseInt(index) + 1) * 100) / files.length).toFixed(0));
                    }
                }
                setTimeout(() => {
                    onClose(true, filesToUpload, selectedUsers.map(user => `${user.name} <${user.email}>`), isFullyHandSigned || false);
                    setIsFullyHandSigned(false);

                }, 1000);
            } catch (error) {
                console.error(error);
                dispatch(setLoadingStatus(false));
            }
        } else {
            setValidationMessage(`Please select hand signed documents`);
        }
    }

    const openPreview = async (file) => {
        const url = URL.createObjectURL(file);
        window.open(url, "_blank");
    }

    const handleRemoveDocument = (file) => {
        setFiles(files.filter(doc => doc.name != file.name));
    }

    const handleCloseModal = (isFiles = false) => {
        onClose(false);
        setFiles([]);
    }

    const isValidEmail = () => {
        let validEmail = false;
        switch (signData?.role) {
            case 'Listing-Broker':
                validEmail = !!signData?.client?.listing_broker_email;
                break;

            case 'Selling-Broker':
                validEmail = !!signData?.client?.selling_broker_email;
                break;

            default:
                validEmail = !!signData?.client?.email;
                break;
        }
        return validEmail;

    }

    const displayUserNameEmail = () => {
        let user = '';
        let email = '';
        if (signData?.client) {
            switch (signData?.role) {
                case 'Listing-Broker':
                    user = signData?.client?.listing_brokerage_broker || `${signData?.client?.first_name} ${signData?.client?.last_name}`;
                    email = signData?.client?.listing_broker_email;
                    break;

                case 'Selling-Broker':
                    user = signData?.client?.selling_brokerage_broker;
                    email = signData?.client?.selling_broker_email;
                    break;
                case 'Seller':
                    user = transactionData.seller_type == OWNER_OF_RECORD ? "Owner of Record" : `${signData?.client?.first_name || ""} ${signData?.client?.last_name || ""}`;
                    email = signData?.client?.selling_broker_email;
                    break;
                default:
                    user = `${signData?.client?.first_name} ${signData?.client?.last_name}`;
                    email = signData?.client?.email;
                    break;
            }
            return <>
                <p>
                    <b>{user} ({signData?.role})</b>
                    <br />
                    {email}
                </p>
            </>;
        } else {
            return <></>;
        }
    }
    const handelSelect = (check, selectedUser) => {
        if (check) {

            setSelectedUsers(prev => ([...prev, selectedUser]));
        } else {
            setSelectedUsers(prev => prev.filter(user => user.email !== selectedUser.email))
        }
    }
    const columns = [
        {
            width: "50px",
            center: true,
            padding: 0,
            cell: (row) => (
                <div className={senderRole == row.title && 'selected-row'}>
                    <input type="checkbox" className="uk-checkbox" style={{ border: row.email != "N/A" ? '1.75px solid #1ea2d5' : '1.75px solid #adadad', cursor: row.email != "N/A" ? "pointer" : "not-allowed" }} checked={row.email != "N/A" && selectedUsers?.filter(user => user.email == row.email).length || null} onClick={(e) => { handelSelect(e.target.checked, row) }}
                        disabled={row.email == "N/A" ? true : false} />
                </div>
            ),
        },
        {
            name: "title",
            padding: 0,
            cell: (row) => (
                <div data-tag="allowRowEvents" className={senderRole == row.title && 'selected-row'}>
                    {
                        row && row?.title
                    }
                </div>
            ),
        },
        {
            name: "Full Name",
            selector: "name",
            cell: (row) => (
                <div className={senderRole == row.title && 'selected-row'}>
                    {row.name}
                </div>
            ),
        },
        {
            name: "Email",
            selector: "email",
            width: '240px',
            fontSize: "12px",
            cell: (row) => (
                <div className={senderRole == row.title && 'selected-row'}>
                    {row.email}
                </div>
            ),
        },
        {
            name: "Mobile Phone",
            selector: "mobile_phone",
            width: '150px',
            cell: (row) => (
                <div className={senderRole == row.title && 'selected-row'}>
                    {row.mobile
                        ? phoneNumber(row.mobile)
                        : 'N/A'}
                </div>
            ),
        },
        {
            name: "company",
            padding: 0,
            cell: (row) => (
                <div data-tag="allowRowEvents" className={senderRole == row.title && 'selected-row'}>
                    {
                        row && row?.company
                    }
                </div>
            ),
        },
    ];

    const handleFullyHandSigned = (check) => {
        setIsFullyHandSigned(check);
    }

    return (
        <Modal
            show={show}
            onHide={() => handleCloseModal(false)}
            centered={true}
            size="lg">
            <Modal.Header closeButton>
                <h3>Upload Hand Signed Documents</h3>
            </Modal.Header>
            <Modal.Body>
                <form style={{ padding: '10px' }}>
                    <div className="row">
                        <div className="col-md-12">
                            <h4>
                                <b>{signData?.documentName} {signData?.section && `(Section ${signData?.section})`}</b>
                            </h4>
                        </div>
                        <div className="col-md-6">
                            <div className="uk-padding-small">
                                {displayUserNameEmail()}
                            </div>
                            <div className="uk-padding-small">
                                <div className="js-upload" data-uk-form-custom="true">
                                    <input type="file" id="upload-file" onChange={handleChange} multiple accept=".pdf, .doc, .docx, .xls, .xlsx, .png, .jpg, .jpeg" />
                                    <button className="uk-button uk-button-default">Upload Documents</button>
                                </div>
                            </div>
                            {validationMessage &&
                                <span className='text-danger'>{validationMessage}</span>
                            }

                            {signData?.section && <div> <input id="is_fully_handsigned" type="checkbox" name="is_fully_handsigned" className="uk-checkbox" onClick={(e) => { handleFullyHandSigned(e.target.checked) }} />
                                <label className={`uk-form-label radio`} htmlFor="is_fully_handsigned" >Fully Hand signed</label> </div>}
                            <div className="form-row file-format-size mt-3">File formats supported: pdf, doc, docx, xls, xlsx, png, jpg. Maximum file size: 10 MB.</div>
                        </div>
                        <div className="col-md-6">
                            <div className="uk-padding-small text-center">
                                <h3 style={{ color: '#534343' }}>WARNING</h3>
                                <p className='text-danger'>After saving as "Hand Signed" date you
                                    will no longer be able to edit this and
                                    it will become permanently recorded as
                                    "Signature Hand Signed"!</p>
                            </div>
                            {!isValidEmail() &&
                                <div className="uk-padding-small text-center">
                                    <h5 className='text-danger mt-2'>NOTE: Email is require to Hand Sign this document</h5>
                                </div>
                            }
                        </div>
                    </div>
                    {files.length > 0 &&
                        <>
                            <h6>Selected Documents</h6>
                            {files.map((file, index) => (
                                <div className="form-row mt-2" key={`${file.name}_${index}`}>
                                    <div>

                                        <a style={{ marginLeft: "11px" }} onClick={() => openPreview(file)}>
                                            <img src="/icons/view.svg" className="svg" data-uk-tooltip="Preview Document" />
                                        </a>
                                        <span>{file.name}</span>
                                        <a data-uk-tooltip="remove-document" style={{ marginLeft: '5px' }} onClick={() => handleRemoveDocument(file)}>
                                            <img src="https://cdn.hellosign.com/1.116.0/build/ae9ae8331a142bc9ce60901d10a41fc6.svg" />
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </>
                    }
                </form>
                {isLoading &&
                    <div className="row">
                        <div className="col-md-11">
                            <PercentageLoading state={loadingState} />
                        </div>
                        <div className="col-md-1">
                            <h4 className='text-info'>{`${loadingState}%`}</h4>
                        </div>
                    </div>
                }


                <div className="col-md-12 pt-3 px-0">
                    <h4>
                        <b className='text-size-h3 uk-form-label  text-danger'>Select Whom To Notify:</b>
                    </h4>
                    <div className='modal-table'>
                        <DataTable
                            columns={columns}
                            data={participants}
                            highlightOnHover={true}
                            striped={true}
                            noHeader={true}
                            customStyles={datatableStyles}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="uk-button uk-button-default uk-modal-close small" type="button" onClick={(e) => handleCloseModal(false)}>
                    Cancel
                </button>
                <button
                    className="uk-button uk-button-primary small"
                    type="button" onClick={handleUpload}>Done</button>
            </Modal.Footer>
        </Modal>
    )
}

export default HandSignUploadModal;
