import { useState } from 'react';
import VesselAndToyService from '../../services/VesselAndToyService';
import "./searchMLSVessel.css";

const SearchMLSVessel = ({ userId, onSelect, from }) => {
    const [vesselList, setVesselList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isEmpty, setIsEmpty] = useState(false);
    const [isSelectVessel, setIsSelectVessel] = useState(false);
    const [From, setFrom] = useState(from);

    const handleSearchVessel = async (searchText) => {
        setSearchValue(searchText);
        setIsSelectVessel(false);
        setVesselList([]);
        if (searchText && searchText.length > 2) {
            setIsLoading(true);
            try {
                const { data } = await (from !== "deal" ? VesselAndToyService.retrieveMlsVessel(userId, searchText) : VesselAndToyService.retrieveMlsVesselWithoutBroker(userId, searchText));
                setIsLoading(false);
                if (data?.length == 0) {
                    setIsEmpty(true);
                    return;
                }
                setIsEmpty(false);
                setVesselList(data);
            } catch (error) {
                setIsLoading(false);
                setIsEmpty(true);
                console.log(error);
            }
        }

    }

    const handleVesselClick = (vessel) => {
        setTimeout(() => {
            setIsSelectVessel(true);
            setSearchValue("");
        }, 1500);
        onSelect(vessel)
    }

    const handleBlur = () => {
        setTimeout(() => {
            setIsSelectVessel(true);
            setSearchValue("");
        }, 800);
    }

    const displayImage = (vessel) => {
        return <img itemprop="image" src={vessel?.displayImageLink} class="img-fluid" alt="vessel-img" />;
    }

    return (
        <div className="one-column uk-padding-small custom-drop">
            <div class="col-12 m-0 p-0">
                <label className="uk-form-label" htmlFor="search">Search Yachtbroker.org MLS</label>
                <div className="vessel-input input-group">
                    <input
                        aria-label="Search placeholder"
                        value={searchValue}
                        class="rounded-pill-left form-control border border-right-0 font-size"
                        //   style={{fontSize:12.5}}
                        type="text"
                        name="search"
                        placeholder="Enter Vessel ID, HIN, Make, Model or Name to search MLS"
                        autocomplete="off"
                        onChange={(e) => handleSearchVessel(e.target.value)}
                        onBlur={handleBlur}
                    />
                    <button
                        class="btn searchBtn rounded-pill-right btn-outline-secondary border border-left-0 mytooltip"
                        type="button">
                        <i class="fa fa-search" aria-label="Search Icon" role="search"></i>
                    </button>
                </div>

                {isLoading && !isSelectVessel &&
                    <div class="dropdown-menu mt-2 loader-div show show-dropdown" id="yacht_list_search_div">
                        <div id="yacht_search_list">
                            <a data-href="/yacht-details?id=84416&amp;vessel=2796732&amp;title=73-ft-Custom-2005-True-Love-Deltaville-Virginia-United-States-$999500" class="dropdown-item search_vessel_detail_page_btn" href="javascript:;">
                                <div class="row m-1">
                                    Loading...
                                </div>
                            </a>
                        </div>
                    </div>
                }

                {isEmpty && !isLoading && !isSelectVessel &&
                    <div class="dropdown-menu mt-2 loader-div show show-dropdown" id="yacht_list_search_div">
                        <div id="yacht_search_list">
                            <a data-href="/yacht-details?id=84416&amp;vessel=2796732&amp;title=73-ft-Custom-2005-True-Love-Deltaville-Virginia-United-States-$999500" class="dropdown-item search_vessel_detail_page_btn" href="javascript:;">
                                <div class="row m-1">
                                    No Data Found.
                                </div>
                            </a>
                        </div>
                    </div>
                }

                {!isLoading && vesselList?.length > 0 && !isSelectVessel &&
                    <div class="dropdown-menu mt-2 show show-dropdown" id="yacht_list_search_div">
                        <div id="yacht_search_list">
                            {
                                vesselList?.map((vessel) => (
                                    <div class="dropdown-item search_vessel_detail_page_btn" onClick={() => handleVesselClick(vessel)}>
                                        <div class="row m-1">
                                            <div class="col-3 d-sm-block d-sm-none d-md-block ml-0 pl-0">
                                                {displayImage(vessel)}
                                            </div>
                                            <div class="col-9">
                                                <p><strong>{`${vessel?.displayLengthFeet} ${vessel?.manufacturer} ${vessel?.year}`}</strong><span className='pl-3'>(ID: {vessel?.id})</span>
                                                    <br />{vessel?.model} - <i>{vessel?.vesselName}</i>
                                                    <br />{vessel?.listingOwnerName} - <i>{vessel?.listingOwnerBrokerageName}</i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default SearchMLSVessel;
