
import ClearIcon from '@mui/icons-material/Clear';
import React from 'react';
import { Modal } from 'react-bootstrap';
import ReactPlayer from "react-player";
const VideoPlayerModal = ({ show, onClose, videoTitle }) => {

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered={true}
      size='xl'

      className='video-modal'
    >
     <div style={{position:"absolute" ,right:"-30px",top:"-30px",cursor:"pointer"}} onClick={()=>onClose()}><ClearIcon sx={{ fontSize: 35 }} /></div> 
      {videoTitle && <div style={{
        minHeight: "650px"
      }}>
        <ReactPlayer
          className="react-player"
          url={`${videoTitle}`}
          width="100%"
          height="650px"
          controls={true}
        />
      </div>}
    </Modal>
  )
}

export default VideoPlayerModal