import {
  CREATE_VESSEL_OR_TOY,
  RETRIEVE_VESSEL_OR_TOYS,
  GET_VESSEL_OR_TOY,
  UPDATE_VESSEL_OR_TOY,
  DELETE_VESSEL_OR_TOY,
  FIND_VESSEL_OR_TOY_BY_NAME,
  CLEAR_VESSEL,
  FIND_VESSEL_OR_TOY_BY_HULLNUM,
  RETRIEVE_TOYS_BY_DEAL,
  CLEAR_LIST,
  GET_MLS_FILTERS
} from "../actions/types";

const initialState = {
  list: [],
  current: null,
  mlsFilters: {
    PowerCategories: [],
    SailCategories: [],
    manufacturers: []
  }
};

function vesselReducer(vessels = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_VESSEL_OR_TOY:
      return Object.assign({}, {}, vessels, { list: [...vessels.list, payload] });
    case RETRIEVE_VESSEL_OR_TOYS:
      return Object.assign({}, vessels, { list: payload });
    case GET_VESSEL_OR_TOY:
      let current = {};
      Object.keys(payload["vessel"]).map(key => {
        return current[key] = payload.vessel[key];
      })
      return Object.assign({}, vessels, { current });
    case UPDATE_VESSEL_OR_TOY:
      return vessels;
    case DELETE_VESSEL_OR_TOY:
      return vessels.filter(({ id }) => id !== payload.id);
    case FIND_VESSEL_OR_TOY_BY_NAME:
      return Object.assign({}, vessels, { list: payload });
    case FIND_VESSEL_OR_TOY_BY_HULLNUM:
      return Object.assign({}, vessels, { current: payload });
    case CLEAR_VESSEL:
      return Object.assign({}, vessels, { current: null });
    case RETRIEVE_TOYS_BY_DEAL:
      return Object.assign({}, vessels, { list: payload });
    case CLEAR_LIST:
      return Object.assign({}, vessels, { list: [] });
    case GET_MLS_FILTERS:
      return Object.assign({}, vessels, { mlsFilters: { PowerCategories: payload['PowerCategories'], SailCategories: payload['SailCategories'], manufacturers: payload['Manufacturers'], state: payload["State"], manufacturers: payload["Manufacturers"], countries: payload["Countries"] } });
    default:
      return vessels;
  }

}

export default vesselReducer;
