import moment from "moment";

import { SET_DATE_FILTER, SET_OFFICE_FILTER, SET_BROKER_FILTER, SET_SORTBY_FILTER, CLEAR_FILTER } from '../actions/types';
import { THIS_MONTH } from '../actions/report';

const initialState = {
  filter: {
    rangeType: THIS_MONTH,
    range: [
      moment().startOf("month").format("MM-DD-YYYY"),
      moment().format("MM-DD-YYYY"),
    ]
  }
};

const reportFilterReducer = (states = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_DATE_FILTER:
    case SET_OFFICE_FILTER:
    case SET_BROKER_FILTER:
    case SET_SORTBY_FILTER:
      return {
        ...states,
        filter: {
          ...payload
        }
      };
    case CLEAR_FILTER:
      return initialState;
    default:
      return states;
  }
}

export default reportFilterReducer;
