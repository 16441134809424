import { yupResolver } from '@hookform/resolvers/yup';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Form from './Form';
import Column from './Column';
import FormRow from './FormRow';

import PhoneInput from "react-phone-input-2";
import SearchMLSVessel from './searchMLSVessel';
import Auth from '../../utils/auth';
import { useDispatch, useSelector } from 'react-redux';
import { clearVessel, getMLSVessel, retrieveVesselOrToys } from '../../redux/actions/vessels_toys';
import FormInputWrapper from './FormInputWapper';
import { upsertTransaction } from '../../redux/actions/transaction';
import { setLoadingStatus } from '../../redux/actions/loading';
import { getFormattedData, getPlainTransactionData } from '../../utils/stepFilter';
import { makeStyles } from '@mui/styles';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import clsx from 'clsx';

import * as yup from "yup";
import SearchListingAgreementVessels from './SearchListingAgreementVessels';
import { LEGAL_OWNER, SOLE_OWNER } from '../../utils/signer';
import TransactionService from '../../services/TransactionService';
import { getChecklist } from '../../redux/actions/document_tasks';
import { getFigure, getPrice } from '../../utils/currency';
import UserService from '../../services/UserService';
import CompaniesService from '../../services/CompanyService';
import BrokerageService from '../../services/BrokerageService';
import { testCompanyId } from '../../utils/AppConstants';

const useStyles = makeStyles({
    divider: {
        marginBottom: "15px",
    },
    is_broker_div: {
        width: "31% !important",
    },
    show_broker_label: {
        marginRight: "9px !important",
    },
    phone_input: {
        padding: '12px 12px 12px 50px !important',
        width: '100% !important'
    },
    phoneInputDiv: {
        padding: "8px",
        paddingTop: "0px",
        marginLeft: "-7px",
        marginRight: "7px"
    }
});

const AddDealCoBrokerMlsModal = ({ show, onClose }) => {

    const schema = yup.object().shape({
        broker_type: yup.string().required(),
        selling_brokerage: yup.string(),
        selling_brokerage_broker: yup.string(),
        selling_broker_email: yup.string(),
        selling_broker_phone_number: yup.string(),
        listing_brokerage: yup.string(),
        listing_brokerage_broker: yup.string(),
        listing_broker_email: yup.string(),
        listing_broker_phone_number: yup.string(),
        purchase_price: yup.string().required(),



        gross_commission_amount: yup.string().required(),
        user_from_company: yup.boolean().nullable(true),
        // is_selling_broker_from_other_company: yup.boolean(false).nullable(true),
        // is_listing_broker_from_other_company: yup.boolean(false).nullable(true),
        is_broker_from_other_company: yup.boolean(false).nullable(true),

        account_owner_brokerage: yup.string().nullable(true),
        account_owner_brokerage_broker: yup.string().nullable(true),
        account_owner_email: yup.string().nullable(true),
        account_owner_phone_number: yup.string().nullable(true),
        account_owner_percent: yup.string().nullable(true),
        account_owner_amount: yup.string().nullable(true),
        other_broker_brokerage: yup.string().nullable(true),
        other_broker_brokerage_broker: yup.string().nullable(true),
        other_broker_email: yup.string().nullable(true),
        other_broker_phone_number: yup.string().nullable(true),
        other_broker_percent: yup.string().nullable(true),
        other_broker_amount: yup.string().nullable(true),
        additional_terms_comments: yup.string().nullable(true)
    });

    /**
     * Register Form for add Co-Brokerage details
     */
    const { register, reset, handleSubmit, setValue, getValues, watch, clearErrors, formState: { errors } } = useForm({
        defaultValues: {
            user_from_company: false,
            broker_type: 'selling',
            account_owner_percent: 50,
            other_broker_percent: 50
        },
        resolver: yupResolver(schema)
    });

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const [selectedFromLA, setSelectedFromLA] = useState(false);
    const [selectedTransactionLA, setSelectedTransactionLA] = useState(null);
    const [selectedUploadsFromLA, setSelectedUploadsFromLA] = useState([]);
    const [ownerType, setOwnerType] = useState("selling")
    const [otherBroker, setOtherBroker] = useState("listing")
    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);
    const dataCobroker = dataTransaction?.co_broker || null;
    const transactionBroker = dataTransaction?.broker || null;


    const [companies, setCompanies] = useState([]);
    const [selectedCompanyForBroker, setSelectedCompanyForBroker] = useState(null);
    const [brokerListOfCompany, setBrokerListOfCompany] = useState([]);
    const [selectedBrokerFromCompany, setSelectedBrokerFromCompany] = useState(null);

    const [ebSignUsers, setEbSignUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [commission, setCommission] = useState("percentage");
    const [brokerType, setBrokerType] = useState("selling");
    const [brokerList, setBrokerList] = useState([]);
    const [isVerified, setIsVerified] = useState(false);
    const [selectedOtherBrokerType, setSelectedOtherBrokerType] = useState('manual');

    //Store current transaction
    const dataDeal = dataTransaction || {};

    // get data of logged user
    const logged_user = Auth.getInstance().getUserData();

    const isLoggedUserNoBroker = (logged_user.UserInRole?.UserRole?.name == 'Company Admin' && logged_user.is_broker == false);

    // get vessel detail from mls filter selected vessel
    let dataVessels = useSelector((state) => state.vessel_toys.current);

    const phoneKeys = [
        "listing_broker_phone_number",
        "selling_broker_phone_number"
    ];

    const selling_broker_keys = [
        "selling_brokerage",
        "selling_brokerage_broker",
        "selling_broker_email",
        "selling_broker_phone_number",
    ];

    const listing_broker_keys = [
        "listing_brokerage",
        "listing_brokerage_broker",
        "listing_broker_email",
        "listing_broker_phone_number",
    ];

    useEffect(() => {
        dispatch(retrieveVesselOrToys("vessel"));
        return () => {
            dispatch(clearVessel());
        };
    }, []);

    useEffect(() => {
        const brokerId = getValues("eb_user");
        if (brokerId) {
            getBrokerData(brokerId);
            return;
        }
        setValue("listing_broker_phone_number", "+1");
    }, [watch('eb_user')]);

    useEffect(() => {
        let coBrokerRequired = [];
        const sellingCoBrokerFields = {
            selling_broker_email: "Selling Broker Email",
            selling_brokerage_broker: "Selling Broker Name",
            selling_broker_phone_number: "Selling Broker Phone Number",
        }
        const listingCoBrokerFields = {
            listing_broker_email: "Listing Broker Email",
            listing_brokerage_broker: "Listing Broker Name",
            listing_broker_phone_number: "Listing Broker Phone Number",
        }

        if (["selling", "both"].includes(dataCobroker?.broker_type)) {
            Object.keys(sellingCoBrokerFields).forEach(key => {
                if (!dataCobroker[key]) {
                    coBrokerRequired.push({ [key]: sellingCoBrokerFields[key] });
                }
            });
        }

        if (["listing", "both"].includes(dataCobroker?.broker_type)) {
            Object.keys(listingCoBrokerFields).forEach(key => {
                if (!dataCobroker[key]) {
                    coBrokerRequired.push({ [key]: listingCoBrokerFields[key] });
                }
            });
        }

        if (!dataCobroker?.selling_brokerage_percent && !dataCobroker?.listing_brokerage_percent) {
            if (getValues("broker_type") == 'both') {
                setValue('account_owner_percent', 100);
                onChangeBrokerValues('account_owner_percent', 100);
            } else {
                setValue('account_owner_percent', 50);
                setValue('other_broker_percent', 50);
                onChangeBrokerValues('account_owner_percent', 50);
            }
        } else {
            if (['selling', 'listing'].includes(dataCobroker.broker_type) && getValues("broker_type") == 'both') {
                setValue('account_owner_percent', 100);
                onChangeBrokerValues('account_owner_percent', 100);
            } else if (dataCobroker.broker_type == 'both' && ['selling', 'listing'].includes(getValues("broker_type"))) {
                setValue('account_owner_percent', 50);
                setValue('other_broker_percent', 50);
                onChangeBrokerValues('account_owner_percent', 50);
            } else {
                setValue('account_owner_percent', dataCobroker["broker_type"] == 'selling' ? dataCobroker.selling_brokerage_percent : dataCobroker.listing_brokerage_percent);
                setValue('other_broker_percent', dataCobroker["broker_type"] == 'selling' ? dataCobroker.listing_brokerage_percent : dataCobroker.selling_brokerage_percent);
                onChangeBrokerValues('account_owner_percent', dataCobroker["broker_type"] == 'selling' ? dataCobroker.selling_brokerage_percent : dataCobroker.listing_brokerage_percent);
            }
        }

    }, [dataCobroker, getValues("broker_type")]);

    useEffect(() => {
        if (show) {
            if (isLoggedUserNoBroker) {
                getCompanyBrokerList();
            }
            getCompaniesList();
            if (dataBroker && !isLoggedUserNoBroker) {
                setValue("selling_brokerage", dataBroker.company.company_name);
                setValue("selling_brokerage_broker", dataBroker.first_name + ' ' + dataBroker.last_name);
                setValue("selling_broker_email", dataBroker.email);
                setValue("selling_broker_phone_number", dataBroker.mobile_phone ? dataBroker.mobile_phone?.substring(dataBroker.mobile_phone.length - 11) : "+1");

                setValue("account_owner_brokerage", dataBroker.company?.company_name);
                setValue("account_owner_brokerage_broker", dataBroker.first_name + ' ' + dataBroker.last_name);
                setValue("account_owner_email", dataBroker.email);
                setValue("account_owner_phone_number", dataBroker.mobile_phone ? dataBroker.mobile_phone?.substring(dataBroker.mobile_phone.length - 11) : "+1");
            }
        }

        setSelectedFromLA(false);
        setSelectedTransactionLA(null);
        // Need to get the default co-brokerage percentage from broker company


        setCommissionPercent()
    }, [dataBroker, show]);

    const setCommissionPercent = async () => {

        const { data: brokerageData } = await BrokerageService.get(logged_user.company_id);

        const grossCommission = dataBroker?.company?.brokerage?.default_co_brokerage || brokerageData?.default_co_brokerage || 10;
        setValue('gross_commission_percent', grossCommission);
    }

    useEffect(() => {
        if (!getValues('is_broker_from_other_company')) {
            setSelectedCompanyForBroker(null);
            setSelectedBrokerFromCompany(null);
            setValue('previous_company_id', null);
            setValue('previous_company_broker_id', null);
        }
    }, [watch('is_broker_from_other_company')]);

    useEffect(() => {
        const purchasePrice = getFigure(getValues('purchase_price'));
        const commissionPercentage = getValues('gross_commission_percent');
        setValue('gross_commission_amount', getPrice((purchasePrice * commissionPercentage) / 100));

        setTimeout(() => {
            let gca = getFigure(getValues("gross_commission_amount"));
            let sbp = parseInt(getValues("account_owner_percent"));
            if (gca && sbp !== NaN) {
                let lbp = 100 - sbp;
                let sba = parseFloat((gca * sbp) / 100).toFixed(2);
                let lba = gca - sba;
                setValue("account_owner_amount", getPrice(sba));
                setValue("other_broker_percent", lbp);
                setValue("other_broker_amount", getPrice(lba));
            }
        })
    }, [watch('purchase_price')]);


    /**
     * On change Process Commission Amount value
     * Set values for Selling/Listing broker amount and percentage
     * Set value for Gross Commission Percentage after calculation
    */
    useEffect(() => {
        if (commission !== "percentage") {
            if (getValues("gross_commission_amount") && getValues('purchase_price')) {
                setValue(
                    "gross_commission_percent",
                    ((parseFloat(getFigure(getValues("gross_commission_amount"))) / getFigure(getValues('purchase_price'))) * 100).toFixed(2)
                );
                if (!dataCobroker?.selling_brokerage_percent) {
                    setValue("account_owner_percent", 50);
                }

                setTimeout(() => {
                    let gca = getFigure(getValues("gross_commission_amount"));
                    let sbp = parseInt(getValues("account_owner_percent"));
                    if (gca && sbp !== NaN) {
                        let lbp = 100 - sbp;
                        let sba = parseFloat((gca * sbp) / 100).toFixed(2);
                        let lba = gca - sba;
                        setValue("account_owner_amount", getPrice(sba));
                        setValue("other_broker_percent", lbp);
                        setValue("other_broker_amount", getPrice(lba));
                    }
                })
            }
        }
    }, [watch("gross_commission_amount")]);

    /**
     * On change Process Commission Percentage value
     * Set values for Selling/Listing broker amount and percentage
     * Set value for Gross Commission Amount after calculation
    */
    useEffect(() => {
        if (commission === "percentage") {
            if (getValues("gross_commission_percent") && getValues('purchase_price')) {
                setValue(
                    "gross_commission_amount",
                    getPrice(
                        ((getFigure(getValues('purchase_price')) * parseFloat(getValues("gross_commission_percent"))) / 100).toFixed(2)
                    )
                );
                setTimeout(() => {
                    let gca = getFigure(getValues("gross_commission_amount"));
                    let sbp = parseInt(getValues("account_owner_percent"));
                    if (gca && sbp && sbp != NaN) {
                        let lbp = 100 - sbp;
                        let sba = parseFloat((gca * sbp) / 100).toFixed(2) || 0;
                        let lba = gca - sba;
                        setValue("account_owner_amount", getPrice(sba));
                        setValue("other_broker_percent", lbp);
                        setValue("other_broker_amount", getPrice(lba));
                    }
                })
            }
        }
    }, [watch("gross_commission_percent")]);

    useEffect(() => {
        if (getValues("user_from_company")) {
            dispatch(setLoadingStatus(true));
            TransactionService.getEbUsers(logged_user.company_id, logged_user.UserInRole?.UserRole?.name == 'Agent')
                .then(({ data }) => {
                    dispatch(setLoadingStatus(false));
                    setEbSignUsers(data);
                })
                .catch((err) => {
                    dispatch(setLoadingStatus(false));
                    console.log(err);
                    setEbSignUsers([]);
                })
        } else {
            setValue("eb_user", null)
        }
    }, [watch('user_from_company')]);

    useEffect(async () => {
        if (dataVessels) {
            setValue(`other_broker_brokerage`, dataVessels.listing_brokerage?.brokerage_name);
            setValue(`other_broker_brokerage_broker`, dataVessels.listing_brokerage?.brokerage_broker_name);
            setValue(`other_broker_email`, dataVessels.listing_brokerage?.brokerage_email);
            setValue(`other_broker_phone_number`, getFormattedPhone(dataVessels.listing_brokerage?.brokerage_phone));
        }
        if (dataVessels && watch("broker_type") == "selling") {
            setValue("listing_brokerage", dataVessels.listing_brokerage?.brokerage_name);
            setValue("listing_brokerage_broker", dataVessels.listing_brokerage?.brokerage_broker_name);
            setValue("listing_broker_email", dataVessels.listing_brokerage?.brokerage_email);
            setValue("listing_broker_phone_number", getFormattedPhone(dataVessels.listing_brokerage?.brokerage_phone));
        } else if (dataVessels) {
            setValue("selling_brokerage", dataVessels.listing_brokerage?.brokerage_name);
            setValue("selling_brokerage_broker", dataVessels.listing_brokerage?.brokerage_broker_name);
            setValue("selling_broker_email", dataVessels.listing_brokerage?.brokerage_email);
            setValue("selling_broker_phone_number", getFormattedPhone(dataVessels.listing_brokerage?.brokerage_phone));
        } else {
            setValue("listing_broker_phone_number", '+1');
        }

        if (dataVessels && dataVessels?.company) {
            const companyFoundForOtherBroker = companies.find((company) => { return company.id == dataVessels.company });
            if (companyFoundForOtherBroker) {
                setValue('is_broker_from_other_company', true);
                await handleAutoSelectCompanyAndBroker(companyFoundForOtherBroker);
            }
        }
    }, [dataVessels]);

    const handleAutoSelectCompanyAndBroker = async (companyFoundForOtherBroker) => {
        dispatch(setLoadingStatus(true));
        setValue('user_from_company', false);
        setSelectedOtherBrokerType('is_broker_from_other_company');
        onSelectCompanyGetBrokers(companyFoundForOtherBroker.id);

        setValue('previous_company_id', companyFoundForOtherBroker.id);
        getCompanyBrokers(companyFoundForOtherBroker.id).then((brokers) => {
            setBrokerListOfCompany(brokers.companyBrokers);
            const brokerFound = brokers.companyBrokers?.filter(
                (broker) => { return [`${broker.first_name} ${broker.last_name}`, `${broker.last_name} ${broker.first_name}`].includes(dataVessels.listing_brokerage?.brokerage_broker_name) });
            setSelectedBrokerFromCompany(brokerFound[0]?.id);
            setValue('previous_company_broker_id', brokerFound[0]?.id);
            checkEmail(brokerFound[0]?.email)
            dispatch(setLoadingStatus(false));
        }).catch((error) => {
            dispatch(setLoadingStatus(false));
            console.log(error);
        });
        setSelectedCompanyForBroker(companyFoundForOtherBroker.id);
    }

    useEffect(() => {
        dispatch(setLoadingStatus(true))
        setOwnerType(watch("broker_type"))
        setOtherBroker(watch("broker_type") === "listing" ? "selling" : "listing");
        setDefaultBrokerValue(selectedFromLA ? selectedTransactionLA.broker : null);
        dispatch(setLoadingStatus(false));
    }, [watch("broker_type")]);

    const getFormattedPhone = (phone = null) => {
        if (!phone) {
            return "+1";
        }

        if ((phone.charAt(0) == '+' && phone.charAt(1) != '1') || phone.charAt(0) != '1') {
            return `+1${phone}`;
        }

        return phone;
    }

    /**
     * Set value on Listing/Selling Broker form data
     * @param {*} brokerType 
     * @param {*} brokerData 
     */
    const setBrokerValue = (brokerType, brokerData) => {
        setValue(`${brokerType}_brokerage`, brokerData?.company?.company_name || '');
        setValue(`${brokerType}_brokerage_broker`, (brokerData.first_name || '') + ' ' + (brokerData.last_name || ''));
        setValue(`${brokerType}_broker_email`, brokerData?.email || '');
        setValue(`${brokerType}_broker_phone_number`, brokerData?.mobile_phone?.substring(brokerData?.mobile_phone?.length - 11) || '+1');
    }

    /**
     * On change the radio selection for broker type
     */
    const setDefaultBrokerValue = (brokerLA = null) => {
        const broker_type = getValues('broker_type');
        switch (broker_type) {
            case 'both':
                setTimeout(() => {
                    if (dataCobroker && (dataCobroker?.selling_brokerage_broker && dataCobroker?.listing_brokerage_broker)) {
                        selling_broker_keys.forEach((key) => {
                            if (phoneKeys.indexOf(key) > -1) {
                                setValue(key, dataCobroker[key] ? dataCobroker[key] : +1);
                            } else {
                                setValue(key, dataCobroker[key] || null);
                            }
                        });
                    } else if (transactionBroker && !isLoggedUserNoBroker) {
                        setTimeout(() => {
                            setBrokerValue('selling', transactionBroker);
                            setBrokerValue('listing', transactionBroker);
                        });
                    } else if (dataBroker && !isLoggedUserNoBroker) {
                        setTimeout(() => {
                            setBrokerValue('selling', dataBroker);
                            setBrokerValue('listing', dataBroker);
                        });
                    }
                    if (brokerLA) {
                        setTimeout(() => {
                            setBrokerValue('selling', brokerLA);
                            setBrokerValue('listing', brokerLA);
                        });
                    }
                }, 1000);
                break;
            case 'selling':
                const selling_broker = transactionBroker || dataBroker;
                setBrokerValue('selling', !isLoggedUserNoBroker ? selling_broker : {});
                if (brokerLA) {
                    setTimeout(() => {
                        setBrokerValue('selling', brokerLA);
                    });
                }
                listing_broker_keys.forEach((key) => setValue(key, ''));
                setValue("listing_broker_phone_number", "+1");
                break;
            case 'listing':
                const listing_broker = transactionBroker || dataBroker;
                setBrokerValue('listing', !isLoggedUserNoBroker ? listing_broker : {});
                if (brokerLA) {
                    setTimeout(() => {
                        setBrokerValue('listing', brokerLA);
                    });
                }
                selling_broker_keys.forEach((key) => setValue(key, ''));
                setValue("selling_broker_phone_number", "+1");
                break;
            default:
                break;
        }
    }

    /**
     * On Select vessel from MLS filter options
     * @param {*} vessel 
     */
    const handleVesselSelect = (vessel) => {
        if (vessel?.id && logged_user?.id) {
            dispatch(getMLSVessel(logged_user?.id, vessel?.id));
        }
    }

    const handleListingAgreementVesselSelection = (vessel) => {
        if (vessel && vessel?.t_vessel) {
            // dispatch(setLoadingStatus(true));
            setSelectedFromLA(true);
            setSelectedTransactionLA(vessel);
            dataVessels = vessel?.t_vessel;
            if (vessel?.broker) {
                setDefaultBrokerValue(vessel?.broker);
            }

            //     TransactionService.getListingAgreemenetUploads(vessel.id, vessel.company_id).then((resposne) => {
            dispatch(setLoadingStatus(false));
            //         const uploadsList = resposne.data;
            //         dispatch(getChecklist())
            //             .then((res) => {
            //                 const checklist = res;
            //                 const checklist_id = checklist.filter((c) => c.short_name === "deal")[0].id;

            //                 setSelectedUploadsFromLA(
            //                     uploadsList.filter((task) => task.task_type == "document")
            //                         .filter((doc) => doc.transaction_task_documents?.length > 0)
            //                         .map((document) => {
            //                             return {
            //                                 ...document,
            //                                 title: `${document.company_tasks?.title} (Listing Agreement)`,
            //                                 checklist_id: checklist_id,
            //                                 documents: document.transaction_task_documents
            //                             }
            //                         })
            //                 );
            //             }).catch((error) => {
            //                 dispatch(setLoadingStatus(false));
            //                 console.log(error);
            //             });
            //     });
        }
    }

    /**
     * Close the modal and also redirect to the create transaction process view
     */
    const closeModalAfterSubmission = (transactionId) => {
        closeModal();
        dispatch(setLoadingStatus(false));
        history.push(`/deals/add/${transactionId}/vessel`);
    }

    const submitUploadsFromLA = (transactionId, uploadsData) => {
        TransactionService.submitImportUploads(transactionId, uploadsData).then((response) => {
            closeModalAfterSubmission(transactionId);
            toast.success('Uploads data successfully submitted.');
        }).catch((error) => {
            toast.error(error);
            dispatch(setLoadingStatus(false));
        });
    }

    const submitCoSellerFromLA = (transactionId, selectedCoBroker) => {
        dispatch(upsertTransaction({
            step: {
                db: "coseller",
                main: {
                    ...selectedTransactionLA.co_seller,
                    broker_user_id: selectedCoBroker?.id,
                    company_id: selectedCoBroker?.company_id,
                    company_office_id: selectedCoBroker?.company_office_id,
                    createdBy: logged_user?.id,
                    updatedBy: logged_user?.id
                },
            },
            transaction: {
                isBelonged: true,
                main: {
                    id: transactionId,
                    task_type: "deal",
                    broker_user_id: selectedCoBroker?.id,
                    company_id: selectedCoBroker?.company_id,
                    company_office_id: selectedCoBroker?.company_office_id,
                },
                foreignkeyName: "co_seller_contact_id",
            },
        }, "Co-Seller's")).then((data) => {
            if (selectedTransactionLA.t_toys && selectedTransactionLA.t_toys?.length) {
                submitToysAndTenders(transactionId, selectedCoBroker);
            } else {
                submitUploadsFromLA(transactionId, { company_id: selectedCoBroker.company_id, listing_agreement_id: selectedTransactionLA.id });
            }
        }).catch((error) => {
            toast.error(error);
            dispatch(setLoadingStatus(false));
        });
    }

    const addToyToTransaction = async (transactionId, toy) => {
        return new Promise((resolve, reject) => {
            const toyData = getFormattedData({ ...toy, ...toy.t_vessel });

            //Call api to store or update the toy
            dispatch(upsertTransaction({
                step: {
                    db: "toy",
                    main: toyData,
                },
                transaction: {
                    isBelonged: false,
                    main: {
                        id: transactionId,
                        task_type: "deal",
                        broker_user_id: dataBroker?.id,
                        company_id: dataBroker?.company_id,
                        company_office_id: dataBroker?.company_office_id,
                    },
                },
                third: {
                    main: {
                        item_value: getFigure(toyData.item_value),
                        item_type: toyData.item_type,
                    },
                    db: "transaction_toy",
                    stepKey: "transaction_vessel_id",
                },
            }, "Toy & Tender's")
            ).then((data) => {
                resolve();
            }).catch((error) => {
                toast.error(error);
                reject(error);
            });
        })
    }

    const submitToysAndTenders = async (transactionId, selectedCoBroker) => {
        for (let toy of selectedTransactionLA.t_toys) {
            const toyData = getFormattedData({ ...toy, ...toy.t_vessel });

            await addToyToTransaction(transactionId, toyData);
        }
        submitUploadsFromLA(transactionId, { company_id: selectedCoBroker.company_id, listing_agreement_id: selectedTransactionLA.id });
    }

    /**
     * set the form fields as per the data getting from API
     * @param {*} fieldName 
     * @param {*} valueFieldName 
    */
    const setBrokerFieldsFromFormData = (fieldName, valueFieldName, coBroker) => {
        return {
            [`${fieldName}_brokerage`]: coBroker[`${valueFieldName}_brokerage`] || null,
            [`${fieldName}_brokerage_broker`]: coBroker[`${valueFieldName}_brokerage_broker`] || null,
            [`${fieldName}_broker_email`]: coBroker[`${valueFieldName}_email`] || null,
            [`${fieldName}_broker_phone_number`]: (coBroker[`${valueFieldName}_phone_number`]),
            [`${fieldName}_brokerage_percent`]: coBroker[`${valueFieldName}_percent`],
            [`${fieldName}_brokerage_amount`]: (getPrice(coBroker[`${valueFieldName}_amount`]) || null),
        }
    }

    /**
     * On submit form for add Co-Broker detail with vessel details
     * @param {*} formData 
     */
    const handleRegistration = (formData) => {
        if (dataVessels || (selectedFromLA && selectedTransactionLA)) {
            dispatch(setLoadingStatus(true));
            const vesselDetail = dataVessels || selectedTransactionLA.t_vessel;
            delete vesselDetail.listing_brokerage;
            let vessel = getFormattedData(dataVessels || selectedTransactionLA.t_vessel);
            dataDeal.task_type = "deal";
            vessel['vessel_type'] = "vessel";
            delete vessel['id'];
            delete vessel['deleted'];
            vessel['is_vessel_exist'] = false;
            const selectedCoBroker = selectedFromLA ? selectedTransactionLA.broker : dataBroker;
            vessel['is_vessel_exist'] = false;
            dispatch(upsertTransaction({
                step: {
                    db: "vessel",
                    main: {
                        ...vessel,
                        broker_user_id: selectedCoBroker?.id,
                        company_id: selectedCoBroker?.company_id,
                        company_office_id: selectedCoBroker?.company_office_id,
                        createdBy: logged_user?.id,
                        updatedBy: logged_user?.id
                    },
                },
                transaction: {
                    main: {
                        ...getPlainTransactionData(dataDeal),
                        task_type: "deal",
                        broker_user_id: selectedCoBroker?.id,
                        company_id: selectedCoBroker?.company_id,
                        company_office_id: selectedCoBroker?.company_office_id,
                    },
                    isBelonged: true,
                    foreignkeyName: "transaction_vessel_id",
                },
            }, "Vessel's")).then((data) => {
                const transactionId = data.id;

                formData['purchase_price'] = getFigure(formData['purchase_price']);
                formData['gross_commission_amount'] = getFigure(formData['gross_commission_amount']);

                const total_purchase_price = formData.purchase_price || 0
                const less_deposit = total_purchase_price / 10 || 0
                const balance_due = total_purchase_price - less_deposit || 0;

                dispatch(upsertTransaction({
                    step: {
                        db: "offer",
                        main: {
                            purchase_price: formData.purchase_price,
                            total_purchase_price: total_purchase_price,
                            less_deposit: less_deposit,
                            balance_due: balance_due
                        },
                    },
                    transaction: {
                        isBelonged: false,
                        main: {
                            id: transactionId,
                            task_type: "deal",
                            broker_user_id: dataBroker?.id,
                            company_id: dataBroker?.company_id,
                            company_office_id: dataBroker?.company_office_id,
                        }
                    }
                }, "Offer's")).then((data) => {

                    formData = {
                        ...formData,
                        ...setBrokerFieldsFromFormData('selling', ['selling', 'both'].includes(brokerType) ? 'account_owner' : 'other_broker', formData),
                        ...setBrokerFieldsFromFormData('listing', ['listing', 'both'].includes(brokerType) ? 'account_owner' : 'other_broker', formData)
                    }

                    if (isLoggedUserNoBroker) {
                        formData['isBrokerSelected'] = true;
                        formData['selected_broker_user_id'] = formData['selling_brokerage_id'] || formData['listing_brokerage_id'];
                    }

                    if (selectedCompanyForBroker && selectedBrokerFromCompany) {
                        if (getValues('is_broker_from_other_company')) {
                            formData['is_selling_broker_from_other_company'] = !['selling', 'both'].includes(brokerType);
                            formData['is_listing_broker_from_other_company'] = brokerType != 'listing';
                            formData['user_from_company'] = false;
                        } else {
                            formData['is_selling_broker_from_other_company'] = false;
                            formData['is_listing_broker_from_other_company'] = false;
                        }
                        formData['company_id'] = logged_user.company_id;
                        formData['transaction_id'] = transactionId;
                        formData['user_type'] = 'broker';
                    } else {
                        formData['is_selling_broker_from_other_company'] = false;
                        formData['is_listing_broker_from_other_company'] = false;
                        formData['transaction_id'] = transactionId;
                        formData['user_from_company'] = false;
                    }

                    formData.user_from_company = from_my_office_checked || false;
                    formData['gross_commission_type'] = commission;

                    // Add Broker into created transaction by vessel details
                    formData['selling_broker_email'] = formData.selling_broker_email ? formData.selling_broker_email.trim() : '';
                    formData['listing_broker_email'] = formData.listing_broker_email ? formData.listing_broker_email.trim() : '';

                    if (dataCobroker && dataCobroker.id) {
                        formData['id'] = dataCobroker.id;
                    }

                    dispatch(upsertTransaction({
                        step: {
                            db: "cobroker",
                            main: formData,
                        },
                        transaction: {
                            isBelonged: true,
                            foreignkeyName: "co_broker_contact_id",
                            main: {
                                id: transactionId,
                                task_type: "deal",
                                broker_user_id: selectedCoBroker?.id,
                                company_id: selectedCoBroker?.company_id,
                                company_office_id: selectedCoBroker?.company_office_id,
                            }
                        }
                    }, "Co-broker's")).then((data) => {
                        // Add Seller when search from Listing Agreement into created transaction by vessel details
                        if (selectedFromLA) {
                            if (selectedTransactionLA.seller) {
                                dispatch(upsertTransaction({
                                    step: {
                                        db: "seller",
                                        main: {
                                            ...selectedTransactionLA.seller,
                                            broker_user_id: selectedCoBroker.id,
                                            company_id: selectedCoBroker.company_id,
                                            company_office_id: selectedCoBroker.company_office_id,
                                            createdBy: logged_user?.id,
                                            updatedBy: logged_user?.id
                                        },
                                    },
                                    transaction: {
                                        isBelonged: true,
                                        foreignkeyName: "seller_contact_id",
                                        main: {
                                            id: transactionId,
                                            task_type: "deal",
                                            owner_or_legal: selectedTransactionLA.owner_or_legal,
                                            seller_type: selectedTransactionLA.seller_type == SOLE_OWNER ? LEGAL_OWNER : selectedTransactionLA.seller_type,
                                            broker_user_id: selectedCoBroker.id,
                                            company_id: selectedCoBroker.company_id,
                                            company_office_id: selectedCoBroker.company_office_id,
                                        },
                                    }
                                }, "Seller's")).then((data) => {
                                    if (selectedFromLA && selectedTransactionLA.co_seller) {
                                        submitCoSellerFromLA(transactionId, selectedCoBroker);
                                    } else if (selectedTransactionLA.t_toys && selectedTransactionLA.t_toys?.length) {
                                        submitToysAndTenders(transactionId, selectedCoBroker);
                                    } else {
                                        submitUploadsFromLA(transactionId, { company_id: selectedCoBroker.company_id, listing_agreement_id: selectedTransactionLA.id });
                                    }
                                }).catch((error) => {
                                    toast.error(error);
                                    dispatch(setLoadingStatus(false));
                                });
                            } else if (selectedTransactionLA.t_toys && selectedTransactionLA.t_toys?.length) {
                                submitToysAndTenders(transactionId, selectedCoBroker)
                            } else {
                                submitUploadsFromLA(transactionId, { company_id: selectedCoBroker.company_id, listing_agreement_id: selectedTransactionLA.id });
                            }
                        } else {
                            closeModalAfterSubmission(transactionId);
                        }
                    }).catch((error) => {
                        toast.error(error);
                        dispatch(setLoadingStatus(false));
                    });
                    dispatch(clearVessel());
                }).catch((err) => {
                    toast.error(err.response.data.message);
                    dispatch(setLoadingStatus(false));
                });

            }).catch((err) => {
                toast.error(err.response.data.message);
                dispatch(setLoadingStatus(false));
            });
        } else {
            if (formData.selling_broker_email || formData.listing_broker_email) {

                formData['purchase_price'] = getFigure(formData['purchase_price']);
                formData['gross_commission_amount'] = getFigure(formData['gross_commission_amount']);
                dispatch(setLoadingStatus(true));

                formData = {
                    ...formData,
                    ...setBrokerFieldsFromFormData('selling', ['selling', 'both'].includes(brokerType) ? 'account_owner' : 'other_broker', formData),
                    ...setBrokerFieldsFromFormData('listing', ['listing', 'both'].includes(brokerType) ? 'account_owner' : 'other_broker', formData)
                }

                // Add Broker into created transaction by vessel details
                formData['selling_broker_email'] = formData.selling_broker_email ? formData.selling_broker_email.trim() : '';
                formData['listing_broker_email'] = formData.listing_broker_email ? formData.listing_broker_email.trim() : '';

                if (dataCobroker && dataCobroker.id) {
                    formData['id'] = dataCobroker.id;
                }

                const total_purchase_price = formData.purchase_price || 0
                const less_deposit = total_purchase_price / 10 || 0
                const balance_due = total_purchase_price - less_deposit || 0;
                dispatch(upsertTransaction({
                    step: {
                        db: "offer",
                        main: {
                            purchase_price: formData.purchase_price,
                            total_purchase_price: total_purchase_price,
                            less_deposit: less_deposit,
                            balance_due: balance_due
                        },
                    },
                    transaction: {
                        isBelonged: false,
                        // foreignkeyName: "co_broker_contact_id",
                        main: {
                            task_type: "deal",
                            broker_user_id: dataBroker?.id,
                            company_id: dataBroker?.company_id,
                            company_office_id: dataBroker?.company_office_id,
                        }
                    }
                }, "Offer's")).then((data) => {
                    formData['transaction_id'] = data.id;

                    if (selectedCompanyForBroker && selectedBrokerFromCompany) {
                        if (getValues('is_broker_from_other_company')) {
                            formData['is_selling_broker_from_other_company'] = !['selling', 'both'].includes(brokerType);
                            formData['is_listing_broker_from_other_company'] = brokerType != 'listing';
                            formData['user_from_company'] = false;
                        } else {
                            formData['is_selling_broker_from_other_company'] = false;
                            formData['is_listing_broker_from_other_company'] = false;
                        }
                        formData['company_id'] = logged_user.company_id;
                        formData['transaction_id'] = data.id;
                        formData['user_type'] = 'broker';
                    } else {
                        formData['is_selling_broker_from_other_company'] = false;
                        formData['is_listing_broker_from_other_company'] = false;
                        formData['transaction_id'] = data.id;
                        formData['user_from_company'] = false;
                    }

                    dispatch(upsertTransaction({
                        step: {
                            db: "cobroker",
                            main: formData,
                        },
                        transaction: {
                            isBelonged: true,
                            foreignkeyName: "co_broker_contact_id",
                            main: {
                                task_type: "deal",
                                broker_user_id: dataBroker?.id,
                                company_id: dataBroker?.company_id,
                                company_office_id: dataBroker?.company_office_id,
                                id: data.id
                            }
                        }
                    }, "Co-broker's")).then((data) => {
                        closeModalAfterSubmission(data.id);
                    }).catch((error) => {
                        dispatch(setLoadingStatus(false));
                        console.log(error);
                    });
                    // closeModalAfterSubmission(data.id);

                }).catch((error) => {
                    dispatch(setLoadingStatus(false));
                    console.log(error);
                });
            } else {
                closeModal();
                history.push("/deals/add/new/vessel");
            }
        }
    }

    /**
     * Handle Error for Submitted Form
     * @param {*} errors 
     */
    const handleError = (errors) => {
        console.log("Errors = ", errors);
    };

    const handleBlur = (ref) => {
        const price = getPrice(getValues(ref));
        setValue(ref, price);
    };

    const handleFocus = (ref) => {
        const figure = getFigure(getValues(ref));
        setValue(ref, figure);
    };


    /**
     * Get All Broker List of Selected Company
    */
    const getCompanyBrokerList = async () => {
        try {
            const companyId = logged_user.company_id;
            dispatch(setLoadingStatus(true));
            if (companyId) {
                const brokers = await getCompanyBrokers(companyId);
                setBrokerList(brokers.companyBrokers);
                if (dataCobroker) {
                    const typeSelected = dataCobroker.broker_type == 'both' ? 'selling' : dataCobroker.broker_type;
                    const selectedBroker = brokers.companyBrokers?.find(broker => { return dataCobroker[`${typeSelected}_brokerage_broker`] == `${broker.first_name} ${broker.last_name}` });
                    if (selectedBroker && selectedBroker.id) {
                        setValue(`${typeSelected}_brokerage_id`, selectedBroker.id);
                    }
                }
            }
            dispatch(setLoadingStatus(false));
        } catch (error) {
            dispatch(setLoadingStatus(false));
            console.log(error);
        }
    }

    const onSelectBrokerByNoBroker = (id, brokers = null) => {
        if (id) {
            setSelectedBrokerFromCompany(id);
            const selectedBroker = brokers.find(broker => broker.id == id);
            setValue(`account_owner_brokerage_broker`, `${selectedBroker.first_name} ${selectedBroker.last_name}`);
            setValue(`account_owner_email`, `${selectedBroker.email}`);
            setValue(`account_owner_phone_number`, selectedBroker.mobile_phone ? selectedBroker.mobile_phone?.substring(selectedBroker.mobile_phone.length - 11) : "+1");
            setValue(`account_owner_brokerage`, logged_user.company?.company_name);
        } else {
            setSelectedBrokerFromCompany(null);
            setValue(`account_owner_brokerage_broker`, '');
            setValue(`account_owner_email`, '');
            setValue(`account_owner_phone_number`, "+1");
            setValue(`account_owner_brokerage`, '');
        }
    }

    const getBrokerFromId = (id, brokers = null) => {
        // const typeSelected = brokerType == 'both' ? 'selling' : brokerType;
        if (id) {
            setSelectedBrokerFromCompany(id);
            setValue('previous_company_broker_id', id);
            const selectedBroker = brokers.find(broker => broker.id == id);
            const selectedCompay = companies.find(comp => comp.id == selectedCompanyForBroker);
            setValue(`other_broker_brokerage`, selectedCompay.company_name);
            setValue(`other_broker_brokerage_broker`, `${selectedBroker.first_name} ${selectedBroker.last_name}`);
            setValue(`other_broker_email`, `${selectedBroker.email}`);
            setValue(`other_broker_phone_number`, selectedBroker.mobile_phone ? selectedBroker.mobile_phone?.substring(selectedBroker.mobile_phone.length - 11) : '+1');
            setIsVerified(true); setValue('is_email_exist_on_boatdox', true);
        } else {
            setSelectedBrokerFromCompany(null);
            setValue('previous_company_broker_id', null);
            setValue(`other_broker_brokerage`, '');
            setValue(`other_broker_brokerage_broker`, '');
            setValue(`other_broker_email`, '');
            setValue(`other_broker_phone_number`, '+1');
        }
    }

    /**
     * Get list of all brokers assoticated from a company
     * @param {*} companyId 
     * @returns Broker list from the API
    */
    const getCompanyBrokers = async (companyId) => {
        return (await UserService.getBrokerList(companyId)).data || {};
    }

    /**
     * Get the list of all companies to be displayed for selection
    */
    const getCompaniesList = async () => {
        try {
            dispatch(setLoadingStatus(true));
            const response = await CompaniesService.getAll();
            // filter the companies list by discarding the test company on production server comparing with company_id
            setCompanies(response.data.filter(company => company.id !== testCompanyId));
            if (dataCobroker && dataCobroker.previous_company_id && dataCobroker.previous_company_broker_id) {
                setValue('previous_company_id', dataCobroker.previous_company_broker_id);
                setSelectedCompanyForBroker(dataCobroker.previous_company_id);
                onSelectCompanyGetBrokers(dataCobroker.previous_company_id)
            }
            dispatch(setLoadingStatus(false));
        } catch (error) {
            dispatch(setLoadingStatus(false));
            console.log(error);
        }
    }

    /**
     * On Select the company from list and call API for get list of brokers for company
     * @param {*} event 
    */
    const onSelectCompanyGetBrokers = async (event) => {
        try {
            const companyId = event;
            if (companyId) {
                setValue('previous_company_id', companyId);
                setSelectedCompanyForBroker(companyId);
                dispatch(setLoadingStatus(true));
                const brokers = await getCompanyBrokers(companyId);
                setBrokerListOfCompany(brokers.companyBrokers);
                if (dataCobroker && dataCobroker.previous_company_id && dataCobroker.previous_company_broker_id) {
                    setValue('previous_company_broker_id', dataCobroker.previous_company_broker_id);
                    setSelectedBrokerFromCompany(dataCobroker.previous_company_broker_id);
                }
                dispatch(setLoadingStatus(false));
            } else {
                setValue('previous_company_id', null);
                setSelectedCompanyForBroker(null);
                setBrokerListOfCompany([]);
                getBrokerFromId(null);
            }
        } catch (error) {
            console.log(error);
            dispatch(setLoadingStatus(false));
        }
    }

    const onChangeBrokerValues = (field) => {
        const gca = getFigure(getValues("gross_commission_amount"));

        if (field.includes('percent')) {
            const percentageValue = parseInt(getValues(field) || 0);
            if (gca && percentageValue !== NaN) {
                switch (field) {
                    case 'account_owner_percent':
                        {
                            let lbp = (100 - percentageValue) || 0;
                            let sba = parseFloat((gca * percentageValue) / 100).toFixed(2);
                            let lba = gca - sba;

                            setValue("other_broker_percent", lbp);
                            setValue("account_owner_amount", getPrice(sba));
                            setValue("other_broker_amount", getPrice(lba));
                        }
                        break;

                    case 'other_broker_percent':
                        {
                            let sbp = (100 - percentageValue) || 0;
                            let lba = parseFloat((gca * percentageValue) / 100).toFixed(2);
                            let sba = gca - lba;
                            setValue("account_owner_percent", sbp);
                            setValue("account_owner_amount", getPrice(sba));
                            setValue("other_broker_amount", getPrice(lba));
                        }
                        break;
                    default:
                        return false;
                }
            }
        } else {
            const amountValue = getFigure(getValues(field) || 0);
            if (gca && amountValue !== NaN) {
                switch (field) {
                    case 'account_owner_amount':
                        {
                            let lba = gca - amountValue;
                            let sbp = parseFloat((amountValue / gca) * 100).toFixed(2);
                            let lbp = (100 - sbp).toFixed(2);

                            setValue("account_owner_percent", sbp);
                            setValue("other_broker_percent", lbp);
                            setValue("other_broker_amount", getPrice(lba));
                        }
                        break;

                    case 'other_broker_amount':
                        {
                            let sba = gca - amountValue;
                            let lbp = parseInt((amountValue / gca) * 100).toFixed(2);
                            let sbp = (100 - lbp).toFixed(2);

                            setValue("account_owner_percent", sbp);
                            setValue("other_broker_percent", lbp);
                            setValue("account_owner_amount", getPrice(sba));
                        }
                        break;
                    default:
                        return false;
                }
            }
        }
    }

    const getBrokerData = async (id) => {
        const { data } = await UserService.get(id);
        setValue('previous_company_id', dataBroker.company.id);
        setSelectedCompanyForBroker(dataBroker.company.id);
        setValue('previous_company_broker_id', data.id);
        setSelectedBrokerFromCompany(data.id);

        setValue("other_broker_brokerage", dataBroker.company.company_name);
        setValue("other_broker_brokerage_broker", `${data.first_name} ${data.last_name}`);
        setValue("other_broker_email", data.email);
        setValue("other_broker_phone_number", data?.mobile_phone || "+1");
    }

    const brokerTypeHandler = (e) => {
        setBrokerType(e.target.value);
        setValue("broker_type", e.target.value);
    }

    const checkEmail = (email) => {
        if (email) {
            dispatch(setLoadingStatus(true));
            UserService.checkEmail({
                email: email,
            }).then((res) => {
                dispatch(setLoadingStatus(false));
                //Check whether email is exists or not.
                if (res.data.isExists) {
                    setIsVerified(true);
                    setValue('is_email_exist_on_boatdox', true)
                } else {
                    setIsVerified(false);
                    setValue('is_email_exist_on_boatdox', false)
                }
            }).catch(() => {
                dispatch(setLoadingStatus(false));
            })
        }
    }

    /**
     * Render the Company selection
     * @returns HTML content for company selection
    */
    const renderCompanySelection = () => {
        return (
            <FormInputWrapper label="Select Company">
                <select
                    className="uk-select"
                    value={selectedCompanyForBroker}
                    onChange={(e) => onSelectCompanyGetBrokers(e.target.value)} >
                    <option value="">Select Company</option>
                    {companies.map((company) => (
                        <option key={`broker_${company.id}`} value={company.id}>{`${company?.company_name}`}</option>
                    ))}
                </select>
            </FormInputWrapper>
        )
    }

    const closeModal = () => {
        reset();
        dispatch(clearVessel());
        onClose(false);
    }

    const getVesselName = (vessel) => {
        return `${vessel?.year || ""} ${vessel?.make || ""} ${vessel?.vessel_name || ""}`;
    }

    let others_mobile_phone = useMemo(() => getValues(`${otherBroker}_broker_phone_number`), [watch(`${otherBroker}_broker_phone_number`)]);

    const account_owner_mobile_phone = useMemo(() => getValues("account_owner_phone_number"), [watch('account_owner_phone_number')]);
    const other_broker_mobile_phone = useMemo(() => getValues("other_broker_phone_number"), [watch('other_broker_phone_number')]);

    //Get the value of user_from_company
    const from_my_office_checked = useMemo(() => getValues("user_from_company"), [watch('user_from_company')]);

    //Get the value of is_broker_from_other_company
    const from_other_company_checked = useMemo(() => getValues("is_broker_from_other_company"), [watch('is_broker_from_other_company')]);

    /**
    * Render the Broker selection as per broker selected
    * @param {*} brokerType 
    * @returns 
    */
    const renderBrokerSelectionFromCompany = () => {
        return (
            <div className="col-4">
                <FormInputWrapper label="Select Broker">
                    <select
                        className="uk-select"
                        value={selectedBrokerFromCompany}
                        onChange={(event) => { getBrokerFromId(event.target.value, brokerListOfCompany); }} >
                        <option value="">Select Broker</option>
                        {brokerListOfCompany.map((broker) => (
                            <option key={`broker_${broker.id}`} value={broker.id}>{`${broker?.first_name} ${broker?.last_name}`}</option>
                        ))}
                    </select>
                </FormInputWrapper>
            </div>
        )
    }

    const renderSellingBroker = () => {
        return (
            <>
                <FormRow>
                    <Column size="three">
                        <h3>Deal Broker</h3>
                    </Column>
                </FormRow>
                <div className='row col-md-12 p-0'>
                    <div className="col-md-6 mb-3">
                        <label className="toys">Are you</label>
                        <div className="radio-toolbar">
                            <input id='selling' type="radio" value="selling" {...register('broker_type')} onChange={(e) => brokerTypeHandler(e)} />
                            <label htmlFor='selling' className="uk-form-label radio">Selling Broker</label>
                            <input id='listing' type="radio" value="listing" {...register('broker_type')} onChange={(e) => brokerTypeHandler(e)} />
                            <label htmlFor='listing' className="uk-form-label radio">Listing Broker</label>
                            <input id='both' type="radio" value="both" {...register('broker_type')} onChange={(e) => brokerTypeHandler(e)} />
                            <label htmlFor='both' className="uk-form-label radio">Both</label>
                        </div>
                    </div>
                </div>
                <div className='row col-md-12 p-0'>
                    <div className="col-md-3">
                        <FormInputWrapper label="Brokerage">
                            <input className="uk-input required" type="text" {...register("account_owner_brokerage")} />
                        </FormInputWrapper>
                    </div>
                    <div className="col-md-3">
                        {isLoggedUserNoBroker
                            ? <FormInputWrapper label="Broker *">
                                <select
                                    {...register(`${brokerType}_brokerage_id`)}
                                    className="uk-select"
                                    onChange={(event) => { onSelectBrokerByNoBroker(event.target.value, brokerList); }} >
                                    <option value="">Select Broker</option>
                                    {brokerList.map((broker) => (
                                        <option key={`broker_${broker.id}`} value={broker.id}>{`${broker?.first_name} ${broker?.last_name}`}</option>
                                    ))}
                                </select>
                            </FormInputWrapper>
                            : <FormInputWrapper label="Broker *">
                                <input
                                    className="uk-input required"
                                    type="text"
                                    autoComplete="off"
                                    {...register("account_owner_brokerage_broker")}
                                />
                            </FormInputWrapper>
                        }
                    </div>
                    <div className="col-md-3">
                        <FormInputWrapper label="Email *">
                            <input autoComplete="false" name="hidden" type="text" className="autocomplete-off" />
                            <input
                                className="uk-input required"
                                type="text"
                                autoComplete="off"
                                {...register("account_owner_email")}
                            />
                        </FormInputWrapper>
                    </div>
                    <div className="col-md-3">
                        <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                            <label className="uk-form-label" htmlFor="name">Mobile Phone*</label>
                            <input type="hidden" required name="account_owner_phone_number"  {...register("account_owner_phone_number")} />
                            <PhoneInput
                                country={'us'}
                                placeholder='+1'
                                inputClass={classes.phone_input}
                                containerClass={classes.phone_container}
                                autoFocus={false}
                                onFocus={() => { }}
                                value={account_owner_mobile_phone}
                                onChange={phone => { setValue('account_owner_phone_number', phone, { shouldValidate: true }) }}
                            />
                        </div>
                    </div>
                </div>

                <div className='row col-md-12 p-0 mt-2'>
                    <div className="col-md-3">
                        <FormInputWrapper label="Deal Broker Percent % *">
                            <input
                                className="uk-input required"
                                type="text"
                                {...register('account_owner_percent', {
                                    onChange: e => {
                                        onChangeBrokerValues('account_owner_percent', e.target.value);
                                    }
                                })}
                            />
                        </FormInputWrapper>
                    </div>
                    <div className="col-md-3">
                        <FormInputWrapper label="Deal Broker Amount $ *">
                            <input
                                className="uk-input required"
                                type="text"
                                {...register('account_owner_amount', {
                                    onChange: e => {
                                        onChangeBrokerValues('account_owner_amount', e.target.value);
                                    }
                                })}
                                onBlur={() => handleBlur("account_owner_amount")}
                                onFocus={() => handleFocus("account_owner_amount")}
                            />
                        </FormInputWrapper>

                    </div>
                </div>
            </>
        );
    };

    const renderListingBroker = () => {
        return (
            <>
                <FormRow>
                    <Column size="three">
                        <h3>Other Side's Broker</h3>
                    </Column>
                </FormRow>

                <div className='row col-md-12 p-0'>
                    <div className="col-md-6 mb-3">
                        <div className="radio-toolbar">
                            <input id='manual' type="radio" value="manual" checked={selectedOtherBrokerType == 'manual'} onChange={(e) => { setValue('is_broker_from_other_company', false); setValue('user_from_company', false); setSelectedOtherBrokerType(e.target.value); }} />
                            <label htmlFor='manual' className="uk-form-label radio">Manual Entry</label>
                            <input id='user_from_company' type="radio" value="user_from_company" checked={selectedOtherBrokerType == 'user_from_company'} onChange={(e) => { setValue('is_broker_from_other_company', false); setValue('user_from_company', true); setSelectedOtherBrokerType(e.target.value); }} />
                            <label htmlFor='user_from_company' className="uk-form-label radio">Select from My Company</label>
                            <input id='is_broker_from_other_company' type="radio" value="is_broker_from_other_company" checked={selectedOtherBrokerType == 'is_broker_from_other_company'} onChange={(e) => { setValue('user_from_company', false); setValue('is_broker_from_other_company', true); setSelectedOtherBrokerType(e.target.value); }} />
                            <label htmlFor='is_broker_from_other_company' className="uk-form-label radio">Select From Other Company</label>
                        </div>
                    </div>
                </div>
                <div className='row col-md-12 p-0'>
                    <div className="col-4">
                        {from_other_company_checked
                            ? renderCompanySelection()
                            : <FormInputWrapper label="Brokerage">
                                <input className="uk-input required" type="text" {...register("other_broker_brokerage")} />
                            </FormInputWrapper>
                        }
                    </div>
                    {from_my_office_checked && ebSignUsers && ebSignUsers.length > 0 &&
                        <div className="col-4">
                            <FormInputWrapper label="Select Broker *">
                                <select
                                    value={selectedUser}
                                    className="uk-select"
                                    {...register('eb_user')}
                                    onChange={(event) => {
                                        setValue("eb_user", event.target.value);
                                        setSelectedUser(event.target.value);
                                        setValue('is_email_exist_on_boatdox', true); setIsVerified(true)
                                    }}>
                                    <option key={`eb_user_default`} value=""></option>
                                    {ebSignUsers.map((user, idx) => (
                                        <option key={`eb_user_${idx}`} value={user.id}>{`${user?.first_name} ${user?.last_name}`}</option>
                                    ))}
                                </select>
                            </FormInputWrapper>
                        </div>
                    }
                    {(from_other_company_checked) && selectedCompanyForBroker &&
                        (renderBrokerSelectionFromCompany())
                    }
                </div>
                <div className='row col-md-12 p-0 mt-2'>
                    <div className="col-4">
                        <FormInputWrapper label="Broker *">
                            <input
                                className="uk-input required"
                                type="text"
                                autoComplete="off"
                                readOnly={from_my_office_checked ? true : false}
                                {...register("other_broker_brokerage_broker")}
                            />
                        </FormInputWrapper>
                    </div>
                    <div className="col-4">
                        <FormInputWrapper label="Email *">
                            <input
                                className="uk-input required"
                                type="text"
                                autoComplete="off"
                                readOnly={from_my_office_checked ? true : false}
                                {...register("other_broker_email")}
                                onBlur={(e) => checkEmail(e.target.value)}
                            />
                        </FormInputWrapper>
                    </div>
                    <div className="col-4">
                        <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                            <label className="uk-form-label" htmlFor="name">Mobile Phone*</label>
                            <input type="hidden" required name="other_broker_phone_number"  {...register("other_broker_phone_number")} />
                            <PhoneInput
                                country={'us'}
                                placeholder='+1'
                                inputClass={classes.phone_input}
                                containerClass={classes.phone_container}
                                readOnly={from_my_office_checked ? true : false}
                                autoFocus={false}
                                onFocus={() => { }}
                                value={other_broker_mobile_phone}
                                onChange={phone => { setValue('other_broker_phone_number', phone, { shouldValidate: true }) }}
                            />
                        </div>
                    </div>
                </div>
                <div className='row col-md-12 p-0 mt-2'>
                    <div className="col-4">
                        <FormInputWrapper label="Other Side's Broker Percent % *">
                            <input
                                className="uk-input required"
                                type="text"
                                {...register('other_broker_percent', {
                                    onChange: e => {
                                        onChangeBrokerValues('other_broker_percent', e.target.value);
                                    }
                                })}
                            />
                        </FormInputWrapper>
                    </div>
                    <div className="col-4">
                        <FormInputWrapper label="Other Side's Broker Amount $ % *">
                            <input
                                className="uk-input required"
                                type="text"
                                {...register('other_broker_amount', {
                                    onChange: e => {
                                        onChangeBrokerValues('other_broker_amount', e.target.value);
                                    }
                                })}
                                onBlur={() => handleBlur("other_broker_amount")}
                                onFocus={() => handleFocus("other_broker_amount")}
                            />
                        </FormInputWrapper>
                    </div>
                    <div className="col-4 pt-3">
                        {isVerified && <img src={process.env.PUBLIC_URL + "/images/verified-boatdox-logo.png"} alt="logo" style={{ height: "45px" }} />}
                    </div>
                </div>
            </>
        );
    };

    return (
        <Modal
            show={show}
            onHide={() => closeModal()}
            centered={true}
            size='xl'>
            <Modal.Header closeButton>
                <h3>Create Deal</h3>
            </Modal.Header>
            <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                <Modal.Body>
                    <div style={{ padding: '10px' }}>
                        <FormRow>
                            <h4>Add Listing (Leave blank to enter manually)</h4>
                            <Column size="half">
                                <div>
                                    <SearchListingAgreementVessels companyId={dataBroker?.company_id} onSelect={handleListingAgreementVesselSelection} isFullWidth={true} />
                                </div>
                                {selectedFromLA &&
                                    <div style={{ paddingLeft: '5px', fontSize: '16px' }}>
                                        <label className="text-success">{getVesselName(selectedTransactionLA.t_vessel)}</label>
                                    </div>
                                }
                            </Column>
                            <Column size="half">
                                <div>
                                    <SearchMLSVessel userId={logged_user?.id} onSelect={handleVesselSelect} from={"deal"} />
                                </div>
                                {(!selectedFromLA && dataVessels) &&
                                    <div style={{ paddingLeft: '60px', fontSize: '16px' }}>
                                        <label className="text-success">{getVesselName(dataVessels)}</label>
                                    </div>
                                }
                            </Column>
                        </FormRow>
                        <FormRow><hr /></FormRow>
                        <div>
                            <FormRow>
                                <Column size="three">
                                    <h3>Commission</h3>
                                </Column>
                            </FormRow>
                            <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                                <div className='row col-md-12 p-0'>
                                    <div className="col-md-3">
                                        <FormInputWrapper label="Selling Price">
                                            <input className={clsx("uk-input required", { "uk-form-danger": errors.purchase_price })} type="text" {...register("purchase_price")}
                                                onBlur={() => handleBlur("purchase_price")}
                                                onFocus={() => handleFocus("purchase_price")} />
                                        </FormInputWrapper>
                                    </div>
                                    <div className="col-md-3">
                                        <FormInputWrapper label="Commission">
                                            <div className="radio-toolbar pt-3">
                                                <input id='percentage' type="radio" name="radioToys" value="percentage"
                                                    checked={commission === "percentage"} onChange={() => setCommission("percentage")}
                                                />
                                                <label htmlFor='percentage' className="uk-form-label radio">Percentage</label>

                                                <input id='amount' type="radio" name="radioToys" value="amount"
                                                    checked={commission === "amount"} onChange={() => setCommission("amount")}
                                                />
                                                <label htmlFor='amount' className="uk-form-label radio">Amount</label>
                                            </div>
                                        </FormInputWrapper>
                                    </div>
                                    <div className="col-md-3">
                                        <FormInputWrapper label="Gross Commission %">
                                            <input
                                                className={clsx("uk-input", {
                                                    "uk-form-danger": commission === "percentage" && errors.gross_commission_percent,
                                                })}
                                                type="text"
                                                {...register("gross_commission_percent")}
                                                readOnly={commission !== "percentage"}
                                            />
                                        </FormInputWrapper>
                                    </div>
                                    <div className="col-md-3">
                                        <FormInputWrapper label="Gross Commission $ *">
                                            <input
                                                className={clsx("uk-input", {
                                                    "uk-form-danger": commission === "amount" && errors.gross_commission_amount,
                                                })}
                                                type="text"
                                                {...register("gross_commission_amount")}
                                                onBlur={() => handleBlur("gross_commission_amount")}
                                                onFocus={() => handleFocus("gross_commission_amount")}
                                                readOnly={commission !== "amount"}
                                            />
                                        </FormInputWrapper>
                                    </div>
                                </div>

                                <FormRow>
                                    <hr />
                                </FormRow>

                                {renderSellingBroker()}

                                <FormRow>
                                    <hr />
                                </FormRow>

                                {brokerType != "both" &&
                                    <>
                                        {renderListingBroker()}
                                        <FormRow>
                                            <hr />
                                        </FormRow>
                                    </>
                                }
                                <FormRow>
                                    <FormInputWrapper size="three" label="Additional Terms/Comments">
                                        <textarea className="uk-textarea" rows="4" cols="50" {...register("additional_terms_comments")} />
                                    </FormInputWrapper>
                                </FormRow>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="uk-button uk-button-default uk-modal-close small" type="button" onClick={closeModal}>
                        Cancel
                    </button>
                    <button
                        className="uk-button uk-button-primary small"
                        type="submit">Save & Continue</button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default AddDealCoBrokerMlsModal;
