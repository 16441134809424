import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import TransactionService from "../../../../../services/TransactionService";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import { getFormattedData, getPlainTransactionData } from "../../../../../utils/stepFilter";
import { getTransactionEdocById, getTransactionEsignById, upsertTransaction } from "../../../../../redux/actions/transaction";

import { toast } from "react-toastify";
import * as yup from "yup";

const PanelFormIYBAPurchaseAndSaleAgreementExclusion = ({ closePanel, exclusionId }) => {

    const schema = yup.object().shape({});

    const { register, handleSubmit, setValue, getValues } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    const dispatch = useDispatch();
    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const doc_id = exclusionId;

    useEffect(() => {
        doc_id && fetchExclusionData(doc_id);
    }, [doc_id]);

    const fetchExclusionData = async (doc_id) => {
        try {
            dispatch(setLoadingStatus(true));
            const { data } = await TransactionService.getExclusionDoc(doc_id);
            const exclusion = data?.data?.exclusion || "";
            if (exclusion) {
                exclusion.split("/:/").forEach((str, index) => {
                    setValue(`exclusion${index + 1}`, str);
                });
            }
            dispatch(setLoadingStatus(false));
        } catch (error) {
            dispatch(setLoadingStatus(false));
            toast.error("Error occurs, While fetching data.");
        }
    }

    const handleRegistration = (exclusions, isContinue = true) => {

        //Define plain string
        let dataStr = "";

        //Format the data
        const formattedExclusions = getFormattedData(exclusions);

        const totalExclusion = document.getElementsByClassName("exclusion")?.length || 0;
        const exclusionArr = Array(totalExclusion).fill().map((v, i) => `exclusion${i + 1}`);
        const blankExclusionStr = Array(totalExclusion).fill().map((v, i) => "/:/").join("").slice(0, -3);

        /**
         * Make a string of all exclusions as below
         * <Value1>/:/<Value2>/:/<Value3>
        */
        exclusionArr.forEach((key) => {
            dataStr = dataStr.concat(formattedExclusions[key] || "", "/:/");
        });

        //Remove last sperator(/:/) from string
        dataStr = dataStr.slice(0, -3);

        //Add data string in our payload
        formattedExclusions.exclusion = dataStr;

        //If docId is available then send it by payload
        if (doc_id) {
            formattedExclusions["id"] = doc_id;
        }

        //Showing the loader
        dispatch(setLoadingStatus(true));

        //API call to update exclusion data
        dispatch(upsertTransaction({
            step: {
                db: "purchase_esign_exclusion",
                main: formattedExclusions,
            },
            transaction: {
                isBelonged: false,
                main: {
                    ...getPlainTransactionData(dataTransaction),
                    task_type: "deal",
                },
            },
        },"Purchase Esign Exclusion")
        ).then((data) => {
            dispatch(getTransactionEsignById(data.id));
            dispatch(getTransactionEdocById(data.id))
            dispatch(setLoadingStatus(false));
            closePanel(true);
        }).catch((err) => {
            dispatch(setLoadingStatus(false));
            toast.error("Error occurs, please try again.");
        });
    };

    const handleError = (error) => {
        console.log(error);
    };

    return (
        <div className="form-container panel-form">
            <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                <FormRow>
                    <FormInputWrapper label="Exclusion #1" size="two">
                        <input {...register("exclusion1")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #2" size="two">
                        <input {...register("exclusion2")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #3" size="two">
                        <input {...register("exclusion3")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #4" size="two">
                        <input {...register("exclusion4")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #5" size="two">
                        <input {...register("exclusion5")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #6" size="two">
                        <input {...register("exclusion6")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #7" size="two">
                        <input {...register("exclusion7")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #8" size="two">
                        <input {...register("exclusion8")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #9" size="two">
                        <input {...register("exclusion9")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #10" size="two">
                        <input {...register("exclusion10")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #11" size="two">
                        <input {...register("exclusion11")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #12" size="two">
                        <input {...register("exclusion12")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #13" size="two">
                        <input {...register("exclusion13")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #14" size="two">
                        <input {...register("exclusion14")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #15" size="two">
                        <input {...register("exclusion15")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #16" size="two">
                        <input {...register("exclusion16")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #17" size="two">
                        <input {...register("exclusion17")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #18" size="two">
                        <input {...register("exclusion18")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #19" size="two">
                        <input {...register("exclusion19")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #20" size="two">
                        <input {...register("exclusion20")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                </FormRow>
                <FormRow style={{ marginTop: "16px" }}>
                    <button type="submit" className='uk-button uk-button-primary pull-right'>Save</button>
                    <button type="button" className='uk-button uk-button-orange pull-right' onClick={() => closePanel(false)}>Close</button>
                </FormRow>
            </Form>
        </div>
    )
}

export default PanelFormIYBAPurchaseAndSaleAgreementExclusion;