import React, { useEffect, useState } from 'react';

import DescriptionIcon from '@mui/icons-material/Description';
import AuthorizeDocSelectionModal from '../../../../common/AuthorizeDocSelectionModal';
import { Chip, Stack, Tooltip } from '@mui/material';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { setLoadingStatus } from '../../../../../redux/actions/loading';
import TransactionService from '../../../../../services/TransactionService';
import datatableStyles from "../../../../../utils/datatable-style";
import { convertUnderscoreSeparateLetter } from '../../../../../utils/functions';
import { phoneNumber } from '../../../../../utils/phonenumber';
import AddParticipantModal from '../../../../common/AddParticipantModal';
import ThemeButton from '../../../../common/buttons/ThemeButton';
import GroupAddSharpIcon from '@mui/icons-material/GroupAddSharp';
import UserService from '../../../../../services/UserService';
import { CORPORATE_OWNED, ENTITY_AND_OR_ASSIGN, LLC_OWNED, TRUST_OWNED } from '../../../../../utils/signer';
const titles = [
    "Acceptance of Vessel",
    "Conditional Acceptance of Vessel",
    "Conditional Acceptance with Survey Allowance of Vessel",
    "Rejection of Vessel",
];
const ParticipantsTable = ({ eDocuments, onOpenParticipantForm, closeParticipantForm }) => {

    const dispatch = useDispatch();
    const checkEmail = async (email) => {
        try {
            dispatch(setLoadingStatus(true));
            const res = await UserService.checkEmail({ email });
            // dispatch(setLoadingStatus(false));
            // Check whether email exists or not.
            return res.data.isExists;
        } catch (error) {
            dispatch(setLoadingStatus(false)); // Ensure loading status is reset even if there's an error
            console.error('Error checking email:', error);
            return false;
        }
    };


    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataAcceptance = useSelector((state) => state.transactions.transaction?.t_acceptance);

    const isLoading = useSelector((state) => state.loading.status);
    const [participantData, setParticipantData] = useState(null);
    const [participants, setParticipants] = useState([]);
    const [show, setShow] = useState(false);
    const [showAuthDoc, setShowAuthDoc] = useState(false);
    const [updateParticipant, setUpdateParticipant] = useState(null);

    useEffect(() => {
        if (dataTransaction && dataTransaction.id) {
            fetchParticipants();
        }
    }, []);

    useEffect(() => {
        if (closeParticipantForm) {
            fetchParticipants();
        }
    }, [closeParticipantForm]);

    /**
     * On click Form Data button for render form of particular participant
     * @param {*} role 
     */
    const onOpenFormData = (role) => {
        onOpenParticipantForm(role.replace(' ', '-'));
    }

    /**
     * On click Edit button from Action and open participant modal form with existing details
     * @param {*} participant participant detail for edit
     */
    const onClickEditParticipant = (participant) => {
        let names = participant.name.split(' ');
        let first_name = names[0];
        let last_name = names.slice(1).join(' ');
        setUpdateParticipant(() => ({
            ...participant,
            is_email_exist: false, first_name: first_name, last_name: last_name, participant_company_name: participant.company, mobile_phone: participant.mobile, is_listing_broker_from_other_company: false,
        }));
        setShow(() => true);
    }

    /**
     * On close the participant modal opened fpr update participant
     * @param {*} flag indicating whether to refresh the list or not
    */
    const onCloseParticipantEditModal = (flag) => {
        setShow(false);
        setUpdateParticipant(null);
        if (flag) {
            fetchParticipants();
        }
    }

    /**
     * On click Authorize doc selection for particular participant 
     * @param {*} participant participant detail for edit
    */
    const onAuthorizeDocSelection = (participant) => {
        setUpdateParticipant(participant);
        setShowAuthDoc(true);
    }

    /**
     * On close the select authorize document and reset necessary states
     * @param {*} flag indicating whether to refresh the list or not
    */
    const onCloseDocumentSelectionModal = (flag) => {
        setShowAuthDoc(false);
        setUpdateParticipant(null);
        if (flag) {
            fetchParticipants();
        }

    }

    /**
     * get participants data and prepare list to display in table
     */
    const fetchParticipants = async () => {
        dispatch(setLoadingStatus(true));
        await TransactionService.getAllParticipants(dataTransaction.id).then(async (response) => {
            setParticipantData(response.data);
            const transactionParticipants = response.data?.transaction;
            const customParticipants = response.data?.participants;
            const participantEmailList = customParticipants.map((participant) => participant.t_participant?.user?.email);
            let participantData = [];
            for (const index in transactionParticipants) {
                if (Object.hasOwnProperty.call(transactionParticipants, index)) {
                    const element = transactionParticipants[index];
                    if (element) {
                        await prepareParticipantList(index, element, participantData, participantEmailList, transactionParticipants);
                    }
                }
            }
            customParticipants.map((participant) => {
                participantData.push({
                    participantId: participant.id,
                    id: `${participant.t_participant?.user?.id}`,
                    title: ` ${(transactionParticipants.co_broker?.listing_broker_email == participant.t_participant?.user?.email)
                        ? 'Listing Broker'
                        : (transactionParticipants.co_broker?.selling_broker_email == participant.t_participant?.user?.email)
                            ? 'Selling Broker'
                            : participant.t_participant?.user?.title || 'N/A'}`,
                    first_name: participant.t_participant?.user?.first_name,
                    last_name: participant.t_participant?.user?.last_name,
                    participant_company_name: participant.t_participant?.user?.participant_company_name,
                    mobile_phone: participant.t_participant?.user?.mobile_phone,
                    name: `${(participant.t_participant?.user?.first_name || participant.t_participant?.user?.last_name) ? ((participant.t_participant?.user?.first_name || '') + ' ' + (participant.t_participant?.user?.last_name || '')) : 'N/A'}`,
                    email: `${participant.t_participant?.user?.email || 'N/A'}`,
                    mobile: `${participant.t_participant?.user?.mobile_phone || ''}`,
                    // company: `${participant.t_participant?.user?.participant_company_name || 'N/A'}`,
                    company: `${participant?.user_type === "broker" ? participant.t_participant?.user?.company?.company_name || 'N/A' : participant.t_participant?.user?.participant_company_name || 'N/A'}`,
                    participant_auth_docs: participant.participant_auth_docs || [],
                    isCustom: true,
                    status: participant.status,
                    userType: participant.user_type || ""
                });
            });
            setParticipants(participantData);
            dispatch(setLoadingStatus(false));
        }).catch((error) => {
            dispatch(setLoadingStatus(false));
            console.log(error);
        });
    }

    const prepareParticipantList = async (type, element, participantData, participantEmailList = [], transactionParticipants) => {
        let participantArray = participantData;
        switch (type) {
            case 'buyer':
                participantArray.push({
                    title: convertUnderscoreSeparateLetter(type),
                    name: ` ${[ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(transactionParticipants?.buyer_type) > -1 ? element?.trust_signer_name : (element.first_name || element.last_name) ? ((element.first_name || '') + ' ' + (element.middle_name || '') + ' ' + (element.last_name || '')) : 'N/A'}`,
                    email: `${[ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(transactionParticipants?.buyer_type) > -1 ? element?.trust_signer_email || 'N/A' : element.email || 'N/A'}`,
                    mobile: `${[ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(transactionParticipants?.buyer_type) > -1 ? element?.trust_signer_phone || 'N/A' : element.mobile_phone || 'N/A'}`,
                    company: `${element.c_company?.company_name || 'N/A'}`,
                    isFormData: true,
                });
                break;
            case 'co_buyer':
            case 'seller':
            case 'co_seller':
                participantArray.push({
                    title: convertUnderscoreSeparateLetter(type),
                    name: ` ${(element.first_name || element.last_name) ? ((element.first_name || '') + ' ' + (element.middle_name || '') + ' ' + (element.last_name || '')) : 'N/A'}`,
                    email: `${element.email || 'N/A'}`,
                    mobile: `${element.mobile_phone || ''}`,
                    company: `${element.c_company?.company_name || 'N/A'}`,
                    isFormData: true,
                });
                break;

            case 'co_broker':
                if (element.selling_broker_email && !participantEmailList.includes(element.selling_broker_email)) {
                    participantArray.push({
                        title: `Selling Broker`,
                        name: `${element.selling_brokerage_broker || 'N/A'}`,
                        email: `${element.selling_broker_email || 'N/A'}`,
                        mobile: `${element.selling_broker_phone_number || ''}`,
                        company: `${element.selling_brokerage || 'N/A'}`,
                        is_selling_broker_from_other_company: element.is_selling_broker_from_other_company,
                        isVerified: await checkEmail(element.selling_broker_email)
                    });
                } if (element.listing_broker_email && !participantEmailList.includes(element.listing_broker_email)) {
                    participantArray.push({
                        title: `Listing Broker`,
                        name: `${element.listing_brokerage_broker || 'N/A'}`,
                        email: `${element.listing_broker_email || 'N/A'}`,
                        mobile: `${element.listing_broker_phone_number || ''}`,
                        company: `${element.listing_brokerage || 'N/A'}`,
                        is_listing_broker_from_other_company: element.is_listing_broker_from_other_company,
                        isVerified: await checkEmail(element.listing_broker_email)
                    });
                }
                break;

            case 'employing_broker':
                participantArray.push({
                    title: convertUnderscoreSeparateLetter(type),
                    name: `${(element.first_name || element.last_name) ? ((element.first_name || '') + ' ' + (element.last_name || '')) : 'N/A'}`,
                    email: `${element.email || 'N/A'}`,
                    mobile: `${element.mobile_phone || ''}`,
                    company: `${element.company?.company_name || 'N/A'}`
                });
                break;

            default:
                break;
        }
        return participantArray;
    }

    const renderDocSelection = (row) => {

        const documentsList = row?.participant_auth_docs?.filter(doc => doc?.transaction_edoc)
        return (['Employing Broker', 'Seller', 'Co Seller', 'Buyer', 'Co Buyer'].includes(row.title)
            || (row.title == 'Listing Broker' && participantData.transaction?.co_broker?.broker_type == 'listing')
            || (row.title == 'Selling Broker' && participantData.transaction?.co_broker?.broker_type == 'selling')) || !row.isCustom
            ? <></>
            : <>
                <OverlayTrigger
                    rootClose
                    trigger={['click']}
                    placement="left"
                    overlay={
                        <Popover style={{ maxHeight: "600px", overflowY: "auto", overflowX: "hidden" }}>
                            <Popover.Title as="h6">Selected Documents</Popover.Title>
                            <Popover.Content ><Stack direction="column" spacing={1}>{documentsList?.map((doc, index) => doc?.transaction_edoc && <span>{index + 1}. {getTitle(doc.transaction_edoc)}</span>)}</Stack></Popover.Content>
                        </Popover>
                    }>
                    <Tooltip title="Click to view list" placement='top'>
                        <a className="badge badge-light mt-1 mr-1"
                            style={{ fontSize: "1rem" }}>
                            {documentsList?.length || ""}
                        </a>
                    </Tooltip>
                </OverlayTrigger>

                <Tooltip title="Select Authorize Documents" placement='top'>
                    <span onClick={() => onAuthorizeDocSelection(row)} style={{ cursor: 'pointer' }}>
                        <DescriptionIcon color='info' />
                    </span>
                </Tooltip>
            </>
    }

    const getTitle = (doc) => {
        const acceptance = dataAcceptance?.find(el => el.id == doc.id);
        let title = acceptance?.status ? `${titles[acceptance.status]}` : doc?.title
        const docTitle = doc?.duplicate_occurrence ? `${title} #${doc?.duplicate_occurrence}` : title
        return `${docTitle}`
    }

    const documentsToDisplay = eDocuments?.map(doc => {

        return {
            value: doc.id,
            label: getTitle(doc),
        }
    });

    const columns = [
        {
            name: "Title",
            selector: "title",
            cell: (row) => (
                row.title
            ),
        },
        {
            name: "Full Name",
            selector: "name",
            cell: (row) => (
                row.name
            ),
        },
        {
            name: "Email",
            selector: "email",
            width: '300px',
            cell: (row) => (
                row.email
            ),
        },
        {
            name: "Mobile Phone",
            selector: "mobile_phone",
            cell: (row) => (
                row.mobile
                    ? phoneNumber(row.mobile)
                    : 'N/A'
            ),
        },
        {
            name: "Company",
            selector: "company",
            cell: (row) => (
                !row.isFormData ? row.company : <></>
            ),
        },
        {
            name: "Status",
            center: true,
            selector: "status",
            cell: (row) => (
                row.title != 'Employing Broker' &&
                <>
                    {((row.title == 'Listing Broker' && !row.is_listing_broker_from_other_company) ||
                        (row.title == 'Selling Broker' && !row.is_selling_broker_from_other_company))
                        ? (row.status !== "sent" && row.status !== "completed") && !row.isVerified ? <Tooltip title={`Add ${row.title}`} placement='right'>
                            <button className="uk-button uk-button-primary m-0" onClick={() => onClickEditParticipant(row)}>
                                Send BoatDox Invite
                            </button>
                        </Tooltip>
                            : row.status === "sent" ? <Chip label="Invite Sent" variant='filled' color='primary' />
                                : row.status === "completed" ? <Chip label="BoatDox Account" variant='filled' color='info' /> : <></>
                        : row?.isCustom ? <Chip label="BoatDox Account" variant='filled' color='info' /> : <></>
                    }
                </>
            ),
        },
        {
            name: "Authorize Docs",
            center: true,
            selector: "authorize_docs",
            cell: (row) => (
                renderDocSelection(row)
            ),
        },
        {
            name: "Action",
            selector: "action",
            center: true,
            cell: (row) => (
                row.title != 'Employing Broker' &&
                <>
                    {row.isCustom && !(row.userType === "broker" || row.userType === "Guest Agent") &&
                        <Tooltip title="Delete Participant" placement='left'>
                            <a>
                                <img
                                    src="/icons/delete.svg"
                                    className="svg"
                                    onClick={() => { handelDelete(row?.participantId) }}
                                    alt=""
                                />
                            </a>
                        </Tooltip>
                    }
                    {(!row.isCustom || (row.userType === "broker" || row.userType === "Guest Agent"))
                        ?
                        <ThemeButton theme="primary" onClick={() => onOpenFormData(row.title)}>
                            <img src="/icons/edit.svg" class="svg"></img>
                            Form Data
                        </ThemeButton>
                        : <></>
                    }
                </>
            ),
        },
    ];

    const handelDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to remove this Participant.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(setLoadingStatus(true));
                TransactionService.deleteParticipant(id).then(response => {
                    toast.success('Participant delete successfully');
                    dispatch(setLoadingStatus(false));
                    fetchParticipants();
                }
                ).catch(error => {
                    dispatch(setLoadingStatus(false));
                    console.error(error);
                });
                // dispatch(removeUploadDocument(document.id, {
                //     transaction_id: transactionId,
                //     actionBy: logged_user.id,
                //     remaining_length: taskDocument.length - 1,
                //     transaction_task_master_id: taskMasterId
                // })
                // ).then(response => {
                //     toast.success('Document removed successfully');
                //     documentTask = taskDocument.filter(doc => doc.id != document.id);
                //     setTaskDocument((state) => state.filter(doc => doc.id != document.id));
                //     dispatch(setLoadingStatus(false));
                //     updateDocument();
                //     if (documentTask.length == 0) {
                //         onClose();
                //     }
                // }
                // ).catch(error => {
                //     dispatch(setLoadingStatus(false));
                //     console.error(error);
                // });
            }
        })
    }
    return (
        <>
        {    !participants?.length && isLoading
        ? <div className="no-data-table"><div className="p-4">Fetching Details...</div></div>
        : <DataTable
                columns={columns}
                data={participants}
                highlightOnHover={true}
                striped={true}
                noHeader={true}
                // onSort={handleSort}
                customStyles={datatableStyles}
            />}
            {show && <AddParticipantModal participant={updateParticipant} show={show} onClose={onCloseParticipantEditModal} />}
            {showAuthDoc && <AuthorizeDocSelectionModal participant={updateParticipant} transactionId={dataTransaction.id} documentsToDisplay={documentsToDisplay} show={showAuthDoc} onClose={onCloseDocumentSelectionModal} />}

        </>
    )
}

export default ParticipantsTable;