import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Fragment } from 'react';

import { useEffect } from 'react';
import MainAuth from '../components/auth';
import Auth from '../utils/auth';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const AuthLayout = () => {
  let { path } = useRouteMatch();
  const history = useHistory();
  useEffect(() => {
    if (Auth.getInstance().getUserData()) {
      history.push("/");
    }
  }, [Auth.getInstance().getUserData()])
  useEffect(() => {
    document.body.style.setProperty('display', 'flex');
    document.body.style.setProperty('justify-content', 'center');
    document.body.style.setProperty('align-items', 'center');
    return () => {
      document.body.style.removeProperty('display');
      document.body.style.removeProperty('justify-content');
      document.body.style.removeProperty('align-items');
    }
  }, []);

  return (
    <Fragment>
      <Switch>
        <Route path={path} component={MainAuth} />
      </Switch>
    </Fragment>
  );
}

export default AuthLayout;