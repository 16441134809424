import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";

import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import TrailerService from '../../../../../services/trailer';
import TrailerTable from '../../../Edit/layouts/table/TrailerTable';
import dropdownLists from '../../../../../constants/dropdownLists';
import SubmitButton from '../../../../common/buttons/SubmitButton';
import { getPlainTransactionData } from "../../../../../utils/stepFilter";
import { getFigure, getPrice } from "../../../../../utils/currency";
import { setLoadingStatus } from '../../../../../redux/actions/loading';
import { getTransactionInitialById, upsertTransaction } from '../../../../../redux/actions/transaction';
import { toast } from 'react-toastify';


import clsx from "clsx";
import * as yup from "yup";

const PanelFormTrailer = ({ closePanel }) => {

    //Define schema for form
    const schema = yup.object().shape({
        year: yup.string().required(),
        make: yup.string().required(),
        model: yup.string().nullable(true),
        type: yup.string().nullable(true),
        weight: yup.string().nullable(true),
        vin: yup.string().required(),
        plate_no: yup.string().nullable(true),
        reg_no: yup.string().nullable(true),
        title_no: yup.string().nullable(true),
        plate_reg_issuing_entity: yup.string().nullable(true),
        title_issuing_entity: yup.string().nullable(true),
        price: yup.string().required(),
        sales_tax_collected: yup.string().nullable(true),
    });

    const { register, handleSubmit, setValue, reset, trigger, clearErrors, getValues, watch, formState: { errors } } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    const dispatch = useDispatch();

    //Define flag to check it is a add form page or edit form.
    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [updateId, setUpdateId] = useState(null);

    const [requiredFields, setRequiredFields] = useState(['vin', 'make', 'year', 'price']);

    //State for storing current transaction
    const dataTransaction = useSelector((state) => state.transactions.transaction);

    //State for storing current transaction broker
    const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);

    //Store current transaction
    const dataDeal = dataTransaction || {};

    //Store trailer data
    const dataTrailer = dataDeal.t_trailer || [];


    //Fetch dropdown list option
    const { trailerTypes } = dropdownLists;

    //useEffect for autofill trailer form
    useEffect(() => {
        if (isEdit && updateId) {
            //Fetch trailer data from ID
            TrailerService.getTrailerById(updateId).then(
                (res) => {
                    let trailer = res.data;
                    if (trailer) {
                        //Auto fill data
                        Object.keys(trailer).forEach((key) => {
                            clearErrors(key);
                            setValue(key, trailer[key] ? trailer[key] : null);
                        });
                        setValue("price", getPrice(trailer.price || 0));
                        setValue("sales_tax_collected", getPrice(trailer.sales_tax_collected || 0));
                        requiredFields.map((value) => {
                            manageRequireFields(value, getValues(value));
                        });
                    } else {
                        Object.keys(trailer).forEach((key) => setValue(key, null));
                    }
                })
        }
    }, [isEdit]);

    //useEffect to set all_trailer_purchase_price field
    useEffect(() => {
        const additional_price = dataTrailer.reduce(
            (total, trailer) => total + parseFloat(trailer.price),
            0
        );
        setValue("all_trailer_purchase_price", getPrice(additional_price));
    }, [dataTrailer, isEdit]);


    ///Define function for update or store the data of trailer
    const handleRegistration = (data) => {
        //Convert price into number
        if (data && data.price) {
            data.price = getFigure(data.price);
        }

        //Convert sales tax into number
        if (data && data.sales_tax_collected) {
            data.sales_tax_collected = getFigure(data.sales_tax_collected);
        }

        dispatch(setLoadingStatus(true));
        //if it is a edit form and form is valid then update the data of trailer.
        if (isEdit) {
            TrailerService.updateTrailerById(updateId, data)
                .then((res) => {
                    dispatch(setLoadingStatus(false));
                    //Fetch latest transaction by ID
                    dispatch(getTransactionInitialById(dataDeal.id));
                    toast.success('Data updated successfully.')
                    handleCancelForm();
                })
                .catch((e) => {
                    dispatch(setLoadingStatus(false));
                    toast.error("Error occur. Please try again", {
                        autoClose: 2000,
                    });
                });
            return;
        }

        //If it is add and data is not null
        if (Object.keys(data).length > 0 && isAdd) {
            //send trailer data to the server
            dispatch(upsertTransaction({
                step: {
                    db: "trailer",
                    main: data,
                },
                transaction: {
                    isBelonged: false,
                    main: {
                        ...getPlainTransactionData(dataDeal),
                        task_type: "deal",
                        broker_user_id: dataBroker?.id,
                        company_id: dataBroker?.company_id,
                        company_office_id: dataBroker?.company_office_id,
                    },
                },
            },"Trailer's")
            ).then((data) => {
                //Get updated transaction
                dispatch(getTransactionInitialById(data.id));
                dispatch(setLoadingStatus(false));
                handleCancelForm();
            }).catch((e) => {
                dispatch(setLoadingStatus(false));
                toast.error("Error occur. Please try again", {
                    autoClose: 2000,
                });
            });
        } else {
            dispatch(setLoadingStatus(false));
        }
    };

    //Define function to handle form validations errors.
    const handleError = err => {
        console.log('Error = ', err);
    };

    //Function to convert price into `$xxx` format
    const handleBlur = (fieldName) => {
        if (fieldName) {
            let value = getValues(fieldName);
            let price = getPrice(value);
            setValue(fieldName, price);
        }
    };

    //Function to convert price into number
    const handleFocus = (fieldName) => {
        if (fieldName) {
            let value = getValues(fieldName);
            let price = getPrice(value);
            setValue(fieldName, getFigure(price));
        }
    };

    const handleCancelForm = () => {
        clearErrors();
        let obj = {}
        Object.keys(getValues()).map((key) => obj[key] = null);
        reset(obj);
        setIsAdd(false);
        setIsEdit(false);
    }

    const handleEditForm = (id) => {
        if (id) {
            setUpdateId(id);
            setIsEdit(true);
        }
    }

    const manageRequireFields = (fieldName, fieldValue) => {
        setRequiredFields(state => {
            if (fieldValue) {
                return state.filter(field => field != fieldName);
            }
            return state.includes(fieldName) ? state : [...state, fieldName];
        })
    }

    return (
        <div className="form-container panel-form">
            {(!isEdit && !isAdd) &&
                <FormRow>
                    <div>
                        <button onClick={() => { setIsAdd(true); }}
                            className="uk-button uk-button-primary pull-right mb-2">
                            <span className="uk-margin-small-right uk-icon" data-uk-icon="plus"></span> Add Trailer
                        </button>
                        <TrailerTable taskType="listing-agreement" fromPanel={true} onEdit={handleEditForm} />
                    </div>
                </FormRow>
            }
            <Form style={{ marginTop: "10px" }} onSubmit={handleSubmit(handleRegistration, handleError)}>
                {(isAdd || isEdit)
                    ? <>
                        <FormRow>
                            <h3 className='mb-4'>{isEdit ? 'Update' : 'Add'} Trailer</h3>
                        </FormRow>
                        <FormRow>
                            <FormInputWrapper label="Year *">
                                <input className={clsx("uk-input", {
                                    "uk-form-danger": requiredFields.includes('year')
                                })} type="text" {...register("year", {
                                    onChange: e => {
                                        manageRequireFields('year', e.target.value);
                                    }
                                })} />
                            </FormInputWrapper>
                            <FormInputWrapper label="Make *">
                                <input type="text"
                                    className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('make') })}
                                    {...register("make", {
                                        onChange: e => {
                                            manageRequireFields('make', e.target.value);
                                        }
                                    })} />
                            </FormInputWrapper>
                            <FormInputWrapper label="Model">
                                <input type="text"
                                    className={clsx("uk-input", { "uk-form-danger": errors.model, })}
                                    {...register("model")} />
                            </FormInputWrapper>
                        </FormRow>
                        <FormRow>
                            <FormInputWrapper label="Type">
                                <select
                                    className={clsx("uk-select", { 'uk-form-danger': errors.type })}
                                    {...register("type")}>
                                    {trailerTypes.map((trailerType, idx) => (
                                        <option key={`trailer_type_${idx}`} value={trailerType}>{trailerType}</option>
                                    ))}
                                </select>
                            </FormInputWrapper>
                            <FormInputWrapper label="Weight">
                                <input className={clsx("uk-input", { 'uk-form-danger': errors.weight })} type="text" {...register("weight")} />
                            </FormInputWrapper>
                            <FormInputWrapper label="VIN *">
                                <input
                                    type="text"
                                    className={clsx("uk-input", { "uk-form-danger": requiredFields.includes('vin') })}
                                    {...register("vin", {
                                        onChange: e => {
                                            manageRequireFields('vin', e.target.value);
                                        }
                                    })}
                                />
                            </FormInputWrapper>
                        </FormRow>
                        <FormRow>
                            <FormInputWrapper label="Plate No.">
                                <input className={clsx("uk-input", { 'uk-form-danger': errors.plate_no })} type="text" {...register("plate_no")} />
                            </FormInputWrapper>
                            <FormInputWrapper label="Reg No.">
                                <input className={clsx("uk-input", { 'uk-form-danger': errors.reg_no })} type="text" {...register("reg_no")} />
                            </FormInputWrapper>
                            <FormInputWrapper label="Plate/Reg Issuing Entity">
                                <input className={clsx("uk-input", { 'uk-form-danger': errors.plate_reg_issuing_entity })} type="text" {...register("plate_reg_issuing_entity")} />
                            </FormInputWrapper>
                        </FormRow>
                        <FormRow>
                            <FormInputWrapper label="Title No.">
                                <input
                                    type="text"
                                    className={clsx("uk-input", { 'uk-form-danger': errors.title_no })}
                                    {...register("title_no")}
                                />
                            </FormInputWrapper>
                            <FormInputWrapper label="Title Issuing Entity">
                                <input className={clsx("uk-input", { 'uk-form-danger': errors.title_issuing_entity })} type="text" {...register("title_issuing_entity")} />
                            </FormInputWrapper>
                        </FormRow>
                        <FormRow>
                            <FormInputWrapper label="Price *">
                                <input
                                    className={clsx("uk-input", { "uk-form-danger": requiredFields.includes('price') })}
                                    {...register("price", {
                                        onChange: e => {
                                            manageRequireFields('price', e.target.value);
                                        }
                                    })}
                                    type="text"
                                    onBlur={() => handleBlur("price")}
                                    onFocus={() => handleFocus("price")}
                                />
                            </FormInputWrapper>
                        </FormRow>
                        <FormRow>
                            <FormInputWrapper label="Sales Tax Collected">
                                <input
                                    className={clsx("uk-input", { 'uk-form-danger': errors.sales_tax_collected })}
                                    type="text"
                                    {...register("sales_tax_collected")}
                                    onBlur={() => handleBlur("sales_tax_collected")}
                                    onFocus={() => handleFocus("sales_tax_collected")}
                                />
                            </FormInputWrapper>
                        </FormRow>
                        <FormRow>
                            <SubmitButton theme={'primary pull-right'}>{!isEdit ? "Submit" : "Update"}</SubmitButton>
                            <button type="button" className='uk-button uk-button-default pull-right' onClick={handleCancelForm}>Cancel</button>
                        </FormRow>
                    </>
                    : <div className="text-right">
                        <button type="button" className='uk-button uk-button-orange' onClick={() => closePanel(true)}>Close</button>
                    </div>
                }
            </Form>
        </div>
    )
}

export default PanelFormTrailer;