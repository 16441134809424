import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import Country from "../../../../../constants/Country";
import State from "../../../../../constants/StatesForUSA";
import { phoneNumber } from "../../../../../utils/phonenumber";

const DetailOfCoSeller = ({ coseller }) => {
  let cosel = coseller ? coseller : {};

  let name = `${cosel.first_name || ""} ${cosel.last_name || ""}`;
  let address = `${cosel.address_1 || ""} ${cosel.address_2 || ""} ${cosel.address_3 || ""}`;
  address = address.replace(/ /g, "") ? address : "N/A";

  let unit = cosel.unit_apt || "N/A";
  let city_state_zip = `${cosel.city || ""} ${
    cosel.state ? State.find((s) => s.code === cosel.state)?.name || "" : ""
  } ${cosel.postal_code || ""}`;
  city_state_zip = city_state_zip.replace(/ /g, "") ? city_state_zip : "N/A";
  let country = cosel.country ? Country.find((c) => c.code === cosel.country)?.name || "" : "";

  return (
    <>
      <FormRow>
        <FormInputWrapper label="Name">
          <div className="input-view">{name}</div>
        </FormInputWrapper>
        <FormInputWrapper label="Entity Name">
          <div className="input-view">{cosel.entity_name || "N/A"}</div>
        </FormInputWrapper>
      </FormRow>
      <FormRow>
        <FormInputWrapper size="two" label="Email *">
          <div className="input-view">
            {
              cosel 
              ? (
                cosel.email 
                ? <a href={`mailto:${cosel.email}`} target="_blank">{cosel.email}</a>
                : "N/A"
              ) 
              : "N/A"
            }
          </div>
        </FormInputWrapper>
        <FormInputWrapper label="Mobile Phone">
          <div className="input-view">
            {cosel.mobile_phone ? phoneNumber(cosel.mobile_phone) : "N/A"}
          </div>
        </FormInputWrapper>
        <FormInputWrapper label="Citizen Of">
          <div className="input-view">{cosel.nationality || "N/A"}</div>
        </FormInputWrapper>
      </FormRow>
      <FormRow>
        <FormInputWrapper size="three" label="Address">
          <div className="input-view">{address}</div>
          <div className="input-view">{unit}</div>
          <div className="input-view">{city_state_zip}</div>
          <div className="input-view">{country}</div>
        </FormInputWrapper>
      </FormRow>
    </>
  );
};

export default DetailOfCoSeller;
