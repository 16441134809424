import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Helmet } from "react-helmet";
import OfficeForm from "./Form";
import OfficeList from "./List";
import OfficeView from "./View";

const OfficePage = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <Helmet>
        <title>Settings Offices - BoatDox</title>
      </Helmet>
      <Switch>
        <Route exact path={path} component={OfficeList} />
        <Route path={`${path}/add`} component={OfficeForm} />
        <Route path={`${path}/edit`} component={OfficeForm} />
        <Route path={`${path}/view`} component={OfficeView} />
      </Switch>
    </>
  );
};

export default OfficePage;
