import { useEffect, useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";

import FormPage from "../../../../common/FormPage";
import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import AsterixTypo from "../../../../common/AsterixTypo";
import SubmitButton from "../../../../common/buttons/SubmitButton";
import LinkButton from "../../../../common/buttons/LinkButton";
import "./addVessel.css"

import {
  getTransactionInitialById,
  upsertTransaction,
} from "../../../../../redux/actions/transaction";
import {
  clearVessel,
  getGlobalVesselOrToy,
  getMLSVessel,
  getVesselOrToy,
  retrieveVesselOrToys,
} from "../../../../../redux/actions/vessels_toys";
import { getLength, getOrigin } from "../../../../../utils/length";
import AutoCompleteSearch from "../../../../common/Autocomplete";
import { getFormattedData, getPlainTransactionData } from "../../../../../utils/stepFilter";
import dropdownLists from '../../../../../constants/dropdownLists';
import { setLoadingStatus } from "../../../../../redux/actions/loading";

import VesselAndToyService from '../../../../../services/VesselAndToyService';
import SearchMLSVessel from "../../../../common/searchMLSVessel";
import Auth from "../../../../../utils/auth";
import { Autocomplete, TextField, Tooltip } from "@mui/material";
import UploadVesselPdf from "../../../../deals/View/layouts/forms/UploadVesselPdf";
import Swal from "sweetalert2";
import ThemeButton from "../../../../common/buttons/ThemeButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import vessels from "../../../../../constants/dropdownLists/vessel-manufacturer";
import { getFileExtension } from "../../../../../utils/functions";
import { uploadCopyToServer, uploadDocument } from "../../../../../utils/s3";
import { toast } from "react-toastify";

// const schema = yup.object().shape({
//   hull_number: yup.string().when(['is_old_built'], {
//     is: (is_old_built) => is_old_built !== true,
//     then: yup.string().required().min(12),
//     otherwise: yup.string().required().min(1)
//   }),
//   model: yup.string().required(),
//   make: yup.string().min(1).required(),
//   boat_type: yup.string().min(1).required(),
//   doc_no: yup.string().when(['reg_no', 'title_no'], {
//     is: (reg_no, title_no) => (!reg_no || reg_no.length === 0) && (!title_no || title_no.length === 0),
//     then: yup.string().required(),
//     otherwise: yup.string().nullable()
//   }),
//   reg_no: yup.string().when(['doc_no', 'title_no'], {
//     is: (doc_no, title_no) => (!doc_no || doc_no.length === 0) && (!title_no || title_no.length === 0),
//     then: yup.string().required(),
//     otherwise: yup.string().nullable()
//   }),
//   title_no: yup.string().when(['doc_no', 'reg_no'], {
//     is: (doc_no, reg_no) => (!doc_no || doc_no.length === 0) && (!reg_no || reg_no.length === 0),
//     then: yup.string().required(),
//     otherwise: yup.string().nullable()
//   })
// },
//   [['doc_no', 'reg_no'], ['doc_no', 'title_no'], ['reg_no', 'title_no']]
// );

const schema = yup.object().shape({

});

const MAX_ENGINE_COUNT = 6;
const BOATDOX = "BoatDox";
const YACHTBROKER = "YachtBroker";

//Get values of all driopdowns
const { boatMakes, boatTypes, engineMakes } = dropdownLists

const AddVessel = ({ step, setStep, asterix }) => {
  let { id } = useParams();

  //Define state for dutyPaid radio button
  const [isdutypaid, setIsdutypaid] = useState(null);

  //Define state for salesPaid radio button
  const [issalespaid, setIssalespaid] = useState(null);

  //State for number of engines
  const [numberOfEngines, setNumberOfEngines] = useState(1);

  //Store boat type 
  const [boatType, setBoatType] = useState("");

  //State for foreign vessel
  const [isForeignBuiltVessel, setIsForeignBuiltVessel] = useState(false);

  //State for storing boat manufactures dropdown
  const [boatManufacturers, setBoatManufacturers] = useState([]);

  //State for storing boat category dropdown
  const [boatCategories, setBoatCategories] = useState([]);

  //State for storing validation error
  const [error, setError] = useState("");

  //State to convert length unit into foot or meter.
  const [lengthUnit, setLengthUnit] = useState("");

  //State for old hullnumber checkbox
  const [isOld, setOld] = useState(false);

  // State for upload vessel
  const [file, setFile] = useState(null);


  //Variable for storing current transaction
  const dataTransaction = useSelector((state) => state.transactions.transaction);

  //Variable for storing current broker of transaction
  const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);

  //Variable for store list of vessels
  const dataVessels = useSelector((state) => state.vessel_toys.list);

  //Variable for store the current value of vessel
  const dataVessel = useSelector((state) => state.vessel_toys.current);

  //get data of logged user
  const logged_user = Auth.getInstance().getUserData();

  // State for save MLS checkbox
  const [isGlobal, setIsGlobal] = useState(false);

  const [requiredFields, setRequiredFields] = useState(['hull_number', 'length', 'make', 'year']);

  // const dataManuFecturers = vessels.sort();

  //Remove null manufactures from all data manufactures
  let dataManuFecturers = useSelector((state) => {
    return state.vessel_toys.mlsFilters.manufacturers || [];
  });

  dataManuFecturers = dataManuFecturers.filter((item, i, ar) => ar.indexOf(item) === i).map(mfr => { return { label: mfr } });

  //Separate power category of boat from all category of boat
  const dataPowerCategories = useSelector((state) => {
    if (state.vessel_toys.mlsFilters.PowerCategories) {
      return state.vessel_toys.mlsFilters.PowerCategories || [];
    } else {
      return [];
    }
  });

  //Separate sail category of boat from all category of boat
  const dataSailCategories = useSelector((state) => {
    if (state.vessel_toys.mlsFilters.SailCategories) {
      return state.vessel_toys.mlsFilters.SailCategories || [];
    } else {
      return [];
    }
  });

  //Store current transaction
  const dataListing = dataTransaction || {};

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uplodedPdf, setUplodedPdf] = useState(null);

  //If search method is boatdox then fill vessels fields
  const optionsForAutocomplete = useMemo(() => dataVessels
    .map((vessel) => {
      let vessel_title = `${vessel.length || ""} ${vessel.make || ""} ${vessel.model || ""} ${vessel.year || ""
        }`;
      vessel_title = vessel_title === "   " ? "N/A" : vessel.stock_number ? `${vessel_title} (${vessel.stock_number})` : vessel_title;
      vessel_title = vessel_title === "   " ? "N/A" : vessel.vessel_name ? `${vessel_title} (${vessel.vessel_name})` : vessel_title;
      return { id: vessel.id, value: vessel_title };
    })
    .filter(option => option.value !== 'N/A')
    .sort((prev, next) => prev.value.localeCompare(next.value))
    .reduce((newArray, cur) => newArray.length && newArray.at(-1).value === cur.value ? newArray : [...newArray, cur], [])
    , [dataVessels]
  );

  const dispatch = useDispatch();
  const history = useHistory();

  //Define fields of form
  const keys = [
    "lienholder",
    "hull_number",
    "is_old_built",
    "vessel_name",
    "stock_number",
    "length",
    "model",
    "make",
    "vessel_type",
    "year",
    "flag",
    "description",
    "doc_no",
    "doc_no_issuer",
    "reg_no",
    "reg_no_issuer",
    "title_no",
    "title_no_issuer",
    "place_of_registration",
    "is_duty_paid",
    "sales_use_tax_paid",
    "number_of_engines",
    "engine_make",
    "engine_model",
    "engine_hp",
    "boat_type",
    "uscg"
  ];

  //Push engine fields as per number of engine fields
  Array.from({ length: MAX_ENGINE_COUNT }).forEach((_, index) => {
    const realIndex = index + 1;
    keys.push(`engine_serial_${realIndex}`);
    keys.push(`engine_year_${realIndex}`);
    keys.push(`engine_hours_${realIndex}`);
  })

  //It is edit form then fetch data of vessel.
  useEffect(() => {
    if (dataListing && dataListing.transaction_vessel_id) {
      dispatch(getVesselOrToy(dataListing.transaction_vessel_id));
    }
  }, []);

  //Fetch all vessels
  useEffect(() => {
    dispatch(retrieveVesselOrToys("vessel"));
    return () => {
      dispatch(clearVessel());
    };
  }, []);

  const [makeValue, setMakeValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  //If vessel data is found then set value of form
  useEffect(() => {
    if (dataVessel) {
      keys.forEach((key) => {
        setValue(key, dataVessel[key] ? `${dataVessel[key]}` : null);
      });

      //Set value of dutyPaid
      setIsdutypaid(dataVessel["is_duty_paid"]);

      //Set value of sales_use_tax_paid
      setIssalespaid(dataVessel["sales_use_tax_paid"]);

      //Set value of forigen built vessels
      setIsForeignBuiltVessel(dataVessel["foreign_built_vessel"]);

      setMakeValue(dataVessel['make'] ? { label: dataVessel['make'] } : null);

      //Set value of boat type
      setBoatType(dataVessel?.boat_type || "Power");
      if (dataVessel.length) {
        setValue("length", getLength(dataVessel.length));
      }
      requiredFields.map((value) => {
        manageRequireFields(value, getValues(value));
      });
    } else {
      keys.forEach((key) => setValue(key, null));
      setValue("flag", "US");
    }

    //Set the value of length unit
    setLengthUnit(dataVessel?.length_unit || "ft");

    //Set the value of number of engines
    setNumberOfEngines(dataVessel?.number_of_engines || 1);
    setValue("length", getLength(dataVessel?.length, lengthUnit));
    setBoatType(dataVessel?.boat_type || "Power");
    setOld(dataVessel?.is_old_built || false);
    setUplodedPdf(dataVessel?.upload_listing_pdf || null);
  }, [dataVessel]);

  //UseEffect for setBoatCategories with sorting
  useEffect(() => {
    const categories = boatType == "Power" ? dataPowerCategories : dataSailCategories;
    setBoatCategories(categories.sort());
  }, [boatType]);

  useEffect(() => {
    setTimeout(() => {
      setValue("boat_category", dataVessel?.boat_category || "");
    }, 1000);
  }, [dataVessel])

  //UseEffect for storing value of length into form
  useEffect(() => {
    let figure = getOrigin(getValues("length"));
    setValue("length", getLength(figure, lengthUnit));
  }, [lengthUnit]);

  //Get in built function from useForm
  const { register, handleSubmit, setValue, getValues, watch, formState: { errors } } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  //Function for add ft. or mt. for length
  const handleBlur = () => {
    let figure = getOrigin(getValues("length"));
    setValue("length", getLength(figure, lengthUnit));
  };

  //Function for remove ft. or mt. for length
  const handleFocus = () => {
    let symbolitic = getValues("length");
    setValue("length", getOrigin(symbolitic, lengthUnit));
  };

  //Define function for update or store the data of toys
  const handleRegistration = async (vessel, isFinishLater = false) => {
    dispatch(setLoadingStatus(true));
    // if logo already uploded then fileName will consider last one
    let fileName = uplodedPdf;
    // if new file select then only this api will call
    if (file) {
      dispatch(setLoadingStatus(true));
      try {
        fileName = await uploadDocument(file, 'vessel-pdf', dataTransaction?.id, dataListing?.unique_transaction_id && `LA-${dataListing.unique_transaction_id?.split('-')[1] || ""}`, false, false);
        // dispatch(setLoadingStatus(false));
        // toast.success('Upload PDF successfully');
      } catch (error) {
        console.error("ERROR:", error)
        dispatch(setLoadingStatus(false));
        toast.error('Could not upload PDF.');
      }
    }
    vessel = getFormattedData(vessel);
    vessel.vessel_type = "vessel";
    vessel.is_duty_paid = isdutypaid;
    vessel.sales_use_tax_paid = issalespaid;
    vessel.length_unit = lengthUnit;
    vessel.boat_type = boatType;
    vessel.length = getOrigin(vessel.length, lengthUnit);
    vessel.is_old_built = isOld;
    vessel.source_type = BOATDOX;
    vessel.foreign_built_vessel = isForeignBuiltVessel;
    vessel.number_of_engines = numberOfEngines || 1;
    vessel.make = makeValue?.label || '';
    vessel['is_vessel_exist'] = (dataListing.transaction_vessel_id) ? true : false;

    dispatch(
      upsertTransaction({
        step: {
          db: "vessel",
          isGlobal: isGlobal,
          main: {
            ...vessel,
            upload_listing_pdf: fileName,
            broker_user_id: dataBroker?.id,
            company_id: dataBroker?.company_id,
            company_office_id: dataBroker?.company_office_id,
            createdBy: logged_user?.id,
            updatedBy: logged_user?.id
          },
        },
        transaction: {
          main: {
            ...getPlainTransactionData(dataListing),
            task_type: "listing",
            broker_user_id: dataBroker?.id,
            company_id: dataBroker?.company_id,
            company_office_id: dataBroker?.company_office_id,
          },
          isBelonged: true,
          foreignkeyName: "transaction_vessel_id",
        },
      }, "Vessel's")
    ).then((data) => {
      if (file) {
        const originalFile = file.name.split('.');
        const ext = getFileExtension(originalFile)
        const uniqueId = data?.transactionUniqId && `LA-${data.transactionUniqId?.split('-')[1] || ""}`
        const companyId = data?.transactionUniqId && `${data.transactionUniqId?.split('-')[1] || ""}`
        uploadCopyToServer(file, `${originalFile[0]}(${fileName}).${ext.toLowerCase()}`, 'vessel-pdf', uniqueId, companyId, false);
      }
      if (isFinishLater) {
        dispatch(setLoadingStatus(false));
        history.push(`/listing-agreement/view/${data.id}/main`);
      } else {
        dispatch(getTransactionInitialById(data.id)).then((data) => {
          dispatch(setLoadingStatus(false));
        }).catch((err) => {
          dispatch(setLoadingStatus(false));
        });
        setStep((step || 0) + 1);
      }
    }).catch((err) => {
      setError(err.response.data.message);
      dispatch(setLoadingStatus(false));
      setTimeout(() => {
        setError("");
      }, 5000);
    });
  };

  //UseEffect for add fields as per number of field is selected.
  useEffect(() => {
    setNumberOfEngines(getValues('number_of_engines'));
  }, [watch('number_of_engines')]);

  //Define function to handle form validations errors.
  const handleError = errors => {
    console.log('Errors = ', errors);
    // setStep((step || 0) + 1);
  };

  const handleSelect = (value, option) => {
    if (option?.id) {
      dispatch(getGlobalVesselOrToy(option.id));
    }
  };

  const links = [
    { label: "Listing Agreements", link: `/listing-agreement` },
    { label: "Add a Listing Agreement" },
  ];

  const handleVesselSelect = (vessel) => {
    if (vessel?.id && logged_user?.id) {
      dispatch(getMLSVessel(logged_user?.id, vessel?.id, dataTransaction?.id));
    }
  }

  const onRemovePdf = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to remove upload listing pdf.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: "No"
    }).
      then((result) => {
        // after remove logo , clear all states for new one logo
        if (result.isConfirmed) {
          setUplodedPdf(null);
          setFile(null);
        }
      });
  }

  const manageRequireFields = (fieldName, fieldValue) => {
    setRequiredFields(state => {
      if (fieldValue) {
        if (fieldName == 'hull_number' && getValues('hull_number').length < 12) {
          return state.includes(fieldName) ? state : [...state, fieldName];
        }
        return state.filter(field => field != fieldName);
      }
      return state.includes(fieldName) ? state : [...state, fieldName];
    })
  }

  const vesselName = `${dataTransaction?.t_vessel?.year || ""} ${dataTransaction?.t_vessel?.make || ""} ${dataTransaction?.t_vessel?.model || ""} ${dataTransaction?.t_vessel?.length || ""}`;
  const uniqueTransactionId = dataTransaction?.unique_transaction_id ? `LA-${dataTransaction?.unique_transaction_id}` : '';

  return (
    <>
      <FormPage links={links} title="Vessel" vesselName={vesselName} uniqueId={uniqueTransactionId}>
        <div className="row align-items-center h-100" >
          <div className="col">
            <div style={{ marginTop: "10px" }}>
              <label className="uk-form-label" htmlFor="search">Search Your BoatDox Vessels</label>
              <AutoCompleteSearch options={optionsForAutocomplete} onSelect={handleSelect} />
            </div>
          </div>
          <div className="col"><div className="searchMlsVesselDiv">
            <SearchMLSVessel userId={logged_user?.id} onSelect={handleVesselSelect} from={"listing"} />
          </div>
          </div>
        </div>
        {error ? (
          <div className="uk-alert uk-alert-danger" data-uk-alert>
            <p>{error}</p>
          </div>
        ) : undefined}
        <Form>
          <FormRow>
            <FormInputWrapper label="Hull Identification Number (HIN) *">
              <input className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('hull_number') && !isOld })}
                type="text" placeholder="Hull number must be 12 digits"
                {...register("hull_number", {
                  onChange: e => {
                    manageRequireFields('hull_number', e.target.value);
                  }
                })} />
            </FormInputWrapper>

            <div className='one-column uk-padding-small d-flex align-items-center'>
              <input className="uk-checkbox mt-4 " type="checkbox" checked={isOld} onClick={() => { setOld(!isOld); setValue('is_old_built', !isOld); }} onChange={() => { }} />
              <label className='uk-form-label mt-4 ' htmlFor="isOldHIN" style={{ whiteSpace: 'break-spaces', cursor: 'pointer' }}>
                HIN is not available or is not 12 character USCG compliant.
              </label>
            </div>

            <div className="text-right">
              <span style={{ color: '#5479A3' }}>Upload Listing PDF&nbsp;</span>
              {/* if logo is there by selection or previous it will preview as per below condition  */}
              {(uplodedPdf || file)
                ? <>
                  <Tooltip className='ml-1' title="View Listing PDF" placement="top">
                    <a target="blank"
                      href={uplodedPdf ? `https://${process.env.REACT_APP_UPLOADS_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}vessel-pdf/${uplodedPdf}` : file && URL.createObjectURL(file)}>
                      <img src="/icons/view.svg" className="svg" />
                    </a>
                  </Tooltip>
                  <Tooltip title="Remove Listing PDF" placement="top">
                    <a className='ml-1' onClick={() => onRemovePdf()}>
                      <img src="/icons/delete.svg" className="svg text-danger" />
                    </a>
                  </Tooltip>
                </>
                : <a className="ml-1" onClick={() => setShowUploadModal(true)}>
                  <img src="/icons/upload.svg" className="svg" data-uk-tooltip="Upload Listing PDF" />
                </a>
              }
            </div>

            {/*
          <Column>
            <ThemeButton style={{ marginTop: "19px" }}>Get Boat History Report</ThemeButton>
          </Column>
          <Column>
            <span className="uk-form-label boat-history-report-label">
              <a href="#">Learn More</a>
            </span>
          </Column>
        */}
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Vessel Name">
              <input className="uk-input" type="text" {...register("vessel_name")} />
            </FormInputWrapper>
            <FormInputWrapper label="Stock Number">
              <input className="uk-input" type="text" {...register("stock_number")} />
            </FormInputWrapper>
            <FormInputWrapper label="USCG/Official #">
              <input className="uk-input" type="text" {...register("uscg")} />
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Length *">
              <input
                className={clsx("uk-input", { "uk-form-danger": requiredFields.includes('length') })}
                type="text"
                {...register("length", {
                  onChange: e => {
                    manageRequireFields('length', e.target.value);
                  }
                })}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
            </FormInputWrapper>

            <FormInputWrapper label="Length Unit">
              <input
                type="radio"
                name="length_unit"
                value="ft"
                onClick={() => setLengthUnit("ft")}
                onChange={() => { }}
                checked={lengthUnit === "ft"}
              />
              <label className="uk-form-label radio">Feet</label>
              <input
                type="radio"
                name="length_unit"
                value="m"
                onClick={() => setLengthUnit("m")}
                onChange={() => { }}
                checked={lengthUnit === "m"}
              />
              <label className="uk-form-label ratio">Meters</label>
            </FormInputWrapper>
          </FormRow>

          <FormRow>

            <FormInputWrapper label="Category">
              <select
                className="uk-select"
                {...register('boat_category')}
                disabled={!boatType}
              >
                <option key={`vessel_boat_category_default`} value=""></option>
                {boatCategories.map((boatCategoryKey, idx) => (
                  <option key={`vessel_boat_category_${idx}`} value={boatCategoryKey}>{boatCategoryKey}</option>
                ))}
              </select>
            </FormInputWrapper>


            <FormInputWrapper label="Type">
              <input
                type="radio"
                name="boat_type"
                value="Power"
                onClick={() => {
                  setBoatType("Power");
                  setValue("boat_category", "")
                  // setBoatCategories(dataPowerCategories);
                }}
                onChange={() => { }}
                checked={boatType === "Power"}
              />
              <label className="uk-form-label radio">Power</label>
              <input
                type="radio"
                name="boat_type"
                value="Sail"
                onClick={() => {
                  setBoatType("Sail");
                  setValue("boat_category", "")
                  // setBoatCategories(dataSailCategories);
                }}
                onChange={() => { }}
                checked={boatType === "Sail"}
              />
              <label className="uk-form-label ratio">Sail</label>
            </FormInputWrapper>

          </FormRow>

          <FormRow>
            <FormInputWrapper label="Manufacturer/Builder *">
              <Autocomplete
                id="size-small-outlined"
                size="small"
                isOptionEqualToValue={(option, value) => option.label === value.label}
                value={makeValue}
                style={{ background: `${requiredFields.includes('make') ? '#fff7f8' : ''}` }}
                onChange={(event, newValue) => {
                  setMakeValue(newValue);
                  manageRequireFields('make', newValue?.label);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={dataManuFecturers}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormInputWrapper>

            <FormInputWrapper label="Model">
              <input
                className="uk-input"
                type="text"
                {...register("model")}
              />
            </FormInputWrapper>

            {/* <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="model">
                Model*
              </label>

            </div> */}

            <FormInputWrapper label="Year *">
              <input className={clsx("uk-input", { "uk-form-danger": requiredFields.includes('year') })}
                {...register("year", {
                  onChange: e => {
                    manageRequireFields('year', e.target.value);
                  }
                })}
                type="text" />
            </FormInputWrapper>

          </FormRow>
          <FormRow>
            <FormInputWrapper label="Number of Engines">
              <select
                className="uk-select"
                defaultValue={1}
                value={numberOfEngines || 1}
                {...register('number_of_engines')}
              >
                <option value={1} key={1}>1</option>
                <option value={2} key={2}>2</option>
                <option value={3} key={3}>3</option>
                <option value={4} key={4}>4</option>
                <option value={5} key={5}>5</option>
                <option value={6} key={6}>6</option>
              </select>
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Engine Make">
              <input className="uk-input" type="text" {...register("engine_make")} />
              {/* <select
                className={clsx("uk-select", { 'uk-form-danger': errors.engine_make })}
                {...register("engine_make")}
              >
                {engineMakes.map(engineMake => (
                  <option key={engineMake} value={engineMake}>{engineMake}</option>
                ))}
              </select> */}
            </FormInputWrapper>
            <FormInputWrapper label="Engine Model">
              <input className={clsx("uk-input", { 'uk-form-danger': errors.engine_model })} type="text" {...register("engine_model")} />
            </FormInputWrapper>
            <FormInputWrapper label="Engine HP">
              <input className={clsx("uk-input", { 'uk-form-danger': errors.engine_hp })} type="text" {...register("engine_hp")} />
            </FormInputWrapper>
          </FormRow>
          {Array.from({ length: numberOfEngines }).map((_, index) => {
            const realIndex = index + 1;

            return (
              <FormRow key={index}>
                <FormInputWrapper label={`Engine ${realIndex} Year`}>
                  <input className="uk-input" type="text" {...register(`engine_year_${realIndex}`)} />
                </FormInputWrapper>
                <FormInputWrapper label={`Engine ${realIndex} Serial`}>
                  <input className="uk-input" type="text" {...register(`engine_serial_${realIndex}`)} />
                </FormInputWrapper>
                <FormInputWrapper label={`Engine ${realIndex} Hours`}>
                  <input className="uk-input" type="text" {...register(`engine_hours_${realIndex}`)} />
                </FormInputWrapper>
              </FormRow>
            );
          })}
          <FormRow>
            <FormInputWrapper label="Flag">
              <input className="uk-input" type="text" {...register("flag")} />
            </FormInputWrapper>
            <FormInputWrapper size="one" label="Foreign Built Vessel">
              <input
                type="radio"
                name="foreign_built_vessel"
                value={true}
                onClick={() => setIsForeignBuiltVessel(true)}
                onChange={() => { }}
                checked={isForeignBuiltVessel}
              />
              <label className="uk-form-label radio">Yes</label>
              <input
                type="radio"
                name="foreign_built_vessel"
                value={false}
                onClick={() => setIsForeignBuiltVessel(false)}
                onChange={() => { }}
                checked={!isForeignBuiltVessel}
              />
              <label className="uk-form-label radio">No</label>
            </FormInputWrapper>
            <FormInputWrapper label="Place of Registration">
              {/* place_of_registration */}
              <input className={clsx("uk-input", { 'uk-form-danger': errors.place_of_registration })} type="text" {...register("place_of_registration")} />
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <p style={{ color: '#5479A3' }}>Please input at least one* : Doc No., Reg No., or Title No.</p>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Doc No.">
              <input className={clsx("uk-input", { 'uk-form-danger': errors.doc_no })} type="text" {...register("doc_no")} />
            </FormInputWrapper>
            <FormInputWrapper label="Reg No.">
              <input className={clsx("uk-input", { 'uk-form-danger': errors.reg_no })} type="text" {...register("reg_no")} />
            </FormInputWrapper>
            <FormInputWrapper label="Title No.">
              <input className={clsx("uk-input", { 'uk-form-danger': errors.title_no })} type="text" {...register("title_no")} />
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Doc No. Issuing Entity">
              <input className={clsx("uk-input", { 'uk-form-danger': errors.doc_no_issuer })} type="text" {...register("doc_no_issuer")} />
            </FormInputWrapper>
            <FormInputWrapper label="Reg No. Issuing Entity">
              <input className={clsx("uk-input", { 'uk-form-danger': errors.reg_no_issuer })} type="text" {...register("reg_no_issuer")} />
            </FormInputWrapper>
            <FormInputWrapper label="Title No. Issuing Entity">
              <input className={clsx("uk-input", { 'uk-form-danger': errors.title_no_issuer })} type="text" {...register("title_no_issuer")} />
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Duty Paid">
              <input
                type="radio"
                name="duty_paid"
                value={true}
                onClick={() => setIsdutypaid(true)}
                onChange={() => { }}
                checked={isdutypaid != null && isdutypaid}
              />
              <label className="uk-form-label radio">Yes</label>
              <input
                type="radio"
                name="duty_paid"
                value={false}
                onClick={() => setIsdutypaid(false)}
                onChange={() => { }}
                checked={isdutypaid != null && !isdutypaid}
              />
              <label className="uk-form-label radio">No</label>
              <input
                type="radio"
                name="duty_paid"
                value={false}
                onClick={() => setIsdutypaid(null)}
                onChange={() => { }}
                checked={isdutypaid == null}
              />
              <label className="uk-form-label radio">Unknown</label>
            </FormInputWrapper>
            <FormInputWrapper label="Sales/Use Tax Paid">
              <input
                type="radio"
                name="sales_use_tax_paid"
                value={true}
                onClick={() => setIssalespaid(true)}
                onChange={() => { }}
                checked={issalespaid != null && issalespaid}
              />
              <label className="uk-form-label radio">Yes</label>
              <input
                type="radio"
                name="sales_use_tax_paid"
                value={false}
                onClick={() => setIssalespaid(false)}
                onChange={() => { }}
                checked={issalespaid != null && !issalespaid}
              />
              <label className="uk-form-label radio">No</label>
              <input
                type="radio"
                name="sales_use_tax_paid"
                value={false}
                onClick={() => setIssalespaid(null)}
                onChange={() => { }}
                checked={issalespaid == null}
              />
              <label className="uk-form-label radio">Unknown</label>
            </FormInputWrapper>
            {/* <FormInputWrapper label="Lienholder Name">
            <input className={clsx("uk-input", { 'uk-form-danger': errors.lienholder })} type="text" {...register("lienholder")} />
          </FormInputWrapper> */}
          </FormRow>
          <FormRow>
            <div className={`one-column mt-3 mb-3`}>
              <input className="uk-checkbox " type="checkbox" checked={isGlobal} onChange={() => setIsGlobal(!isGlobal)} />
              <label className={`uk-form-label font-weight-bold`}>Save MLS Vessel?</label>
            </div>
          </FormRow>
          <FormRow>
            <button type="button" className='uk-button uk-button-primary' onClick={() => handleRegistration(getValues(), false)}>Save & Continue</button>
            <button type="button" className='uk-button uk-button-default' onClick={() => handleRegistration(getValues(), true)}>
              Save & Finish Later
            </button>
          </FormRow>
          <AsterixTypo isVisible={!!asterix} />
        </Form>
      </FormPage>
      <UploadVesselPdf
        show={showUploadModal}
        onClose={(file) => { setShowUploadModal(false); setUplodedPdf(file || null) }}
        transactionId={dataTransaction?.id}
        uniqueId={dataListing?.unique_transaction_id && `LA-${dataListing.unique_transaction_id?.split('-')[1] || ""}`}
        fileProps={{ file, setFile }}
      />
    </>
  );
};

export default AddVessel;
