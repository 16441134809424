import { Card, CardHeader, Grid, IconButton, Paper } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setLoadingStatus } from '../../redux/actions/loading';
import S3Service from '../../services/S3Service';
import { convertUnderscoreSeparateLetter } from '../../utils/functions';

const ListFolder = ({ folderProps }) => {
  const { folder, setFolder } = folderProps
  const isLoading = useSelector((state) => state.loading.status);

  const dispatch = useDispatch();
  const [listFolder, setListFolder] = useState([]);

  const fetchFolderList = () => {
    dispatch(setLoadingStatus(true));
    S3Service.getUploadFolders().then((res) => {
      dispatch(setLoadingStatus(false));

      setListFolder(res.data.data);

    }).catch(err => {
      dispatch(setLoadingStatus(false));
    })
  };

  useEffect(() => {
    fetchFolderList()
  }, [])

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  return (
    <div className="content">
      <ul className="uk-breadcrumb m-0">
        <li>
          <Link to={'upload-file-manager'}>All folders</Link>
        </li>
      </ul>
      <h1 className="d-flex align-items-center gap-1">Upload File Manager</h1>
      {!listFolder.length && isLoading
        ? <div className="text-center" style={{ fontSize: "18px" }}>Fetching Library...</div>
        :
        listFolder.length ? <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {listFolder.map((fl, index) => (
            <Grid item xs={2} sm={4} md={3} key={index}>
              <Card sx={{ cursor: 'pointer' }} onClick={() => {
                setFolder(fl.name)
              }} >
                <CardHeader
                  avatar={
                    <img
                      src={"/icons/upload-folder.svg"}
                      className=""
                      alt=""
                      style={{ width: "30px", height: "30px" }}
                    />
                  }
                  action={
                    <IconButton aria-label="settings" sx={{ pointerEvents: "none" }}>
                      {fl.totalFiles}
                    </IconButton>
                  }
                  title={convertUnderscoreSeparateLetter(fl.name, '-')}
                />
              </Card>
            </Grid>
          ))}
        </Grid> : <div className='text-center text-large' style={{ fontSize: "18px" }}>No Folder To Display</div>
      }
    </div>
  )
}

export default ListFolder