import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { getAllTransactions } from "../../redux/actions/transaction";
import datatableStyles from "../../utils/datatable-style";
import PageMainContainer from "../common/PageMainContainer";
import Moment from "react-moment";
import moment from "moment";
import { retrieveOffices } from "../../redux/actions/offices";
import { retrieveUsers } from "../../redux/actions/users";
import { getPrice } from "../../utils/currency";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ReportsBrokersPage = () => {
  const dispatch = useDispatch();
  const dataDeals = useSelector((state) => state.transactions.all);
  const dataOffices = useSelector((state) => state.offices);
  const dataBrokers = useSelector((state) => state.users.users);

  const [options, setOptions] = useState({
    office: "all",
    broker: "all",
    status: "all",
    dateRange: "this month",
    realRange: [
      moment().startOf("month").format("MM-DD-YYYY"),
      moment().endOf("month").format("MM-DD-YYYY"),
    ],
  });
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    dispatch(getAllTransactions("all"));
    dispatch(retrieveOffices());
    dispatch(retrieveUsers());
  }, []);

  useEffect(() => {
    let data = dataDeals.filter((d) => d.task_type === "deal");
    data = data.filter((d) => {
      let officeFilter = true;
      let brokerFilter = true;
      let statusFilter = true;
      let dateRangeFilter = true;

      if (options.office !== "all") {
        officeFilter = d.t_company_office?.id === options.office;
      }
      if (options.broker !== "all") {
        brokerFilter = d.broker?.id === options.broker;
      }
      if (options.status !== "all") {
        statusFilter = d.status === options.status;
      }
      if (!d.t_offers?.offer_date) {
        dateRangeFilter = false;
      } else {
        dateRangeFilter =
          new Date(options.realRange[0]) <= new Date(d.t_offers.offer_date) &&
          new Date(options.realRange[1]) >= new Date(d.t_offers.offer_date);
      }
      return officeFilter && brokerFilter && statusFilter && dateRangeFilter;
    });
    setFiltered(data);
  }, [options, dataDeals]);

  const handleSetOptions = (field) => (e) => {
    let value = e.target.value;

    if (field === "realRangeStart" || field === "realRangeEnd") {
      setOptions((prev) => {
        let range =
          field === "realRangeStart"
            ? [value, prev.realRange[1]]
            : [prev.realRange[0], value];

        return {
          ...prev,
          realRange: range,
        };
      });
    } else if (field === "dateRange") {
      let range = [];
      switch (value) {
        case "this month":
          range = [
            moment().startOf("month").format("MM-DD-YYYY"),
            moment().endOf("month").format("MM-DD-YYYY"),
          ];
          setOptions((prev) => {
            return {
              ...prev,
              dateRange: "this month",
              realRange: range,
            };
          });
          break;
        case "last month":
          range = [
            moment()
              .subtract(1, "months")
              .startOf("month")
              .format("MM-DD-YYYY"),
            moment().subtract(1, "months").endOf("month").format("MM-DD-YYYY"),
          ];
          setOptions((prev) => {
            return {
              ...prev,
              dateRange: "last month",
              realRange: range,
            };
          });
          break;
        case "this quarter": {
          let quarter = moment().quarter();
          let year = moment().year();
          let smonth =
            quarter * 3 - 2 < 10 ? `0${quarter * 3 - 2}` : quarter * 3 - 2;
          let emonth = quarter * 3 < 10 ? `0${quarter * 3}` : quarter * 3;
          range = [`01-${smonth}-${year}`, `01-${emonth}-${year}`];
          setOptions((prev) => {
            return {
              ...prev,
              dateRange: "this quarter",
              realRange: range,
            };
          });
          break;
        }
        case "last quarter": {
          let quarter = moment().quarter() - 1;
          let year = moment().year();
          if (quarter === 0) {
            quarter = 4;
            year = year - 1;
          }
          let smonth =
            quarter * 3 - 2 < 10 ? `0${quarter * 3 - 2}` : quarter * 3 - 2;
          let emonth = quarter * 3 < 10 ? `0${quarter * 3}` : quarter * 3;
          range = [`01-${smonth}-${year}`, `01-${emonth}-${year}`];
          setOptions((prev) => {
            return {
              ...prev,
              dateRange: "last quarter",
              realRange: range,
            };
          });
          break;
        }
        case "quarter-to-date":
          range = [
            moment().subtract(3, "months").format("MM-DD-YYYY"),
            moment().format("MM-DD-YYYY"),
          ];
          setOptions((prev) => {
            return {
              ...prev,
              dateRange: "quater-to-date",
              realRange: range,
            };
          });
          break;
        case "year-to-date":
          range = [
            moment().subtract(1, "year").format("MM-DD-YYYY"),
            moment().format("MM-DD-YYYY"),
          ];
          setOptions((prev) => {
            return {
              ...prev,
              dateRange: "year-to-date",
              realRange: range,
            };
          });
          break;
        case "this year":
          range = [
            moment().startOf("year").format("MM-DD-YYYY"),
            moment().endOf("year").format("MM-DD-YYYY"),
          ];
          setOptions((prev) => {
            return {
              ...prev,
              dateRange: "last year",
              realRange: range,
            };
          });
          break;
        case "last year":
          range = [
            moment().subtract(1, "years").startOf("year").format("MM-DD-YYYY"),
            moment().subtract(1, "years").endOf("year").format("MM-DD-YYYY"),
          ];
          setOptions((prev) => {
            return {
              ...prev,
              dateRange: "last year",
              realRange: range,
            };
          });
          break;
        case "custom":
          range = [
            moment().format("MM-DD-YYYY"),
            moment().format("MM-DD-YYYY"),
          ];
          setOptions((prev) => {
            return {
              ...prev,
              dateRange: "custom",
              realRange: range,
            };
          });
          break;
        default:
          break;
      }
    } else {
      setOptions((prev) => {
        return {
          ...prev,
          [field]: value,
        };
      });
    }
  };

  const statusToShow = {
    progress: "In process",
    cancelled: "Cancelled",
    draft: "Draft",
    completed: "Sold",
    active: "Active",
  };

  const columns = [
    {
      name: "Vessel",
      selector: "vessel",
      cell: (row) => {
        let vessel = row.t_vessel || {};
        let vessel_title = `${vessel.length || ""} ${vessel.make || ""} ${
          vessel.model || ""
        } ${vessel.year || ""}`;
        vessel_title = vessel_title === "   " ? "N/A" : vessel_title;
        return <div>{vessel_title}</div>;
      },
    },
    {
      name: "Purchase Price",
      selector: "purchase_price",
      cell: (row) => {
        let offer = row.t_offers || {};
        return <div>{getPrice(offer.purchase_price) || "N/A"}</div>;
      },
    },
    {
      name: "Gross Commission",
      selector: "gross_commission",
      cell: (row) => {
        let cobroker = row.co_broker || {};
        let offer = row.t_offers || {};
        let gross =
          offer && cobroker && cobroker.gross_commission_amount && cobroker.gross_commission_percent
            ? `${getPrice(cobroker.gross_commission_amount)} (${cobroker.gross_commission_percent}%)`
            : "N/A";
        return <div>{getPrice(gross)}</div>;
      },
    },
    {
      name: "Offer Date",
      selector: "offer_date",
      cell: (row) => {
        let offer = row.t_offers || {};
        return <div><Moment date={offer.offer_date || ""} format="MM-DD-YYYY" /></div>;
      },
    },
    {
      name: "Closing Date",
      selector: "closing_date",
      cell: (row) => {
        let offer = row.t_offers || {};
        return <div><Moment date={offer.closing_date || ""} format="MM-DD-YYYY" /></div>;
      },
    },
    {
      name: "Status",
      selector: "status",
      cell: (row) => {
        let status = row.status || "draft";
        return <div>{statusToShow[status]}</div>;
      },
    },
    {
      name: "Broker",
      selector: "broker",
      cell: (row) => {
        let broker = row.broker || {};
        let name = `${broker.first_name || ""} ${broker.last_name || ""}`;
        name = name === " " ? "N/A" : name;
        return <div>{name}</div>;
      },
    },
    {
      name: "Office",
      selector: "purchase_price",
      cell: (row) => {
        let office = row.t_company_office || {};
        return <div>{office.office_name || "N/A"}</div>;
      },
    },
  ];

  return (
    <PageMainContainer type="view-report">
      <Helmet>
        <title>Report Brokers - BoatDox</title>
      </Helmet>
      <ul className="uk-breadcrumb"><li><a href="/">Reports</a></li></ul>
      <h1>Brokers</h1>
      <div className="reports-filter">
        <select className="uk-select" value={options.office} onChange={handleSetOptions("office")}>
          <option value="all">All Offices</option>
          {dataOffices.map((office) => {
            return (
              <option key={office.id} value={office.id}>
                {office.office_name}
              </option>
            );
          })}
        </select>

        <select className="uk-select" value={options.broker} onChange={handleSetOptions("broker")}>
          <option value="all">All Brokers</option>
          {dataBrokers.map((client) => {
            return (
              <option key={client.id} value={client.id}>
                {`${client.first_name || ""} ${client.last_name || ""}`}
              </option>
            );
          })}
        </select>

        <select className="uk-select" value={options.status} onChange={handleSetOptions("status")}>
          <option value="all">All Stages</option>
          <option value="progress">In-Process</option>
          <option value="draft">Draft</option>
          <option value="completed">Closed</option>
          <option value="cancelled">Cancelled</option>
        </select>

        <select className="uk-select"
          value={options.dateRange}
          onChange={handleSetOptions("dateRange")}
        >
          <option value="this month">This Month</option>
          <option value="last month">Last Month</option>
          <option value="this quarter">This Quarter</option>
          <option value="last quarter">Last Quarter</option>
          <option value="quarter-to-date">Quarter-to-date</option>
          <option value="year-to-date">Year-to-date</option>
          <option value="last year">Last Year</option>
          <option value="custom">Custom</option>
        </select>
        {options.dateRange === "custom" && (
          <>
            <input
              type="date"
              className="uk-input input-date"
              value={options.realRange[0]}
              onChange={handleSetOptions("realRangeStart")}
            />
            <input
              type="date"
              className="uk-input input-date"
              value={options.realRange[1]}
              onChange={handleSetOptions("realRangeEnd")}
            />
          </>
        )}

      </div>
      <DataTable
        noHeader
        pagination
        customStyles={datatableStyles}
        columns={columns}
        data={filtered}
      />
    </PageMainContainer>
  );
};

export default ReportsBrokersPage;
