import Auth from '../../utils/auth';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  boatdox_footer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
});

const Footer = (props) => {
  const loggedUser = Auth.getInstance().getUserData();
  const { email, UserInRole } = loggedUser;
  const { UserRole } = UserInRole;
  const { user_role_name } = UserRole;
  const classes = useStyles();

  return (
    <div className={`footer ${classes.boatdox_footer}`}>
      <div>
        <span>Signed in as:</span> <span>{email} ({user_role_name})</span> <span  className="support-email">Need help? Contact <a href="mailto:support@boatdox.org">support@boatdox.org</a></span>
      </div>
      <a></a>
      <Link to="/privacy" target={"_blank"}>
        Privacy Policy
      </Link>
    </div>
  );
};

export default Footer;
