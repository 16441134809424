import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactDragListView from "react-drag-listview";
import { confirmAlert } from "react-confirm-alert";
import { Table } from "antd";

import { DELETE_MODAL_MESSAGE, DELETE_MODAL_TITLE } from "../../constants/Alert";
import DocumentService from "../../redux/actions/document_tasks"
import { removeTask, updateListOrder } from "../../redux/actions/document_tasks";
import { reOrderTasks } from "../../redux/actions/transaction";
import DocumentAndTaskService from "../../services/DocumentAndTaskService";
import { setLoadingStatus } from "../../redux/actions/loading";

const DocumentEditTable = ({ data, show, model, setDataForUpdateListOrder }) => {
    const [tblData, setTblData] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        data.sort((a, b) => (b.sort_order > a.sort_order ? -1 : 1))
        setTblData(data);
    }, [data]);

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const tbl = [...tblData];
            const item = tbl.splice(fromIndex, 1)[0];
            tbl.splice(toIndex, 0, item);
            tbl.forEach((t, index) => {
                t.sort_order = index;
            });
            setTblData(tbl);
            // store updated data of sortOrder in state for every drag change 
            setDataForUpdateListOrder([model, tbl]);
        },
        handleSelector: "img",
    };

    const columns = [
        {
            title: "",
            width: 80,
            key: "operate",
            render: (task) => <img src="/icons/move.svg" className="move-svg drag-handle" />,
        },
        {
            title: "Order",
            width: 110,
            render: (row) => (
                <div>
                    {
                        row?.sort_order + 1 || " "
                    }
                </div>
            ),
        },
        {
            title: "Title",
            key: "title",
            render: (task) => <span>{task.title}</span>,
        },
        {
            title: "Type",
            key: "type",
            render: (task) => <span>{task.task_type}</span>,
        },
        {
            title: "Tags",
            key: "tags",
            render: (task) => <span>{task.tags}</span>,
        },
        {
            title: "",
            key: "operation",
            render: (task) => {
                return (
                    <div>
                        <img
                            src="/icons/delete.svg"
                            className="svg uk-margin-right"
                            onClick={() => onDelete(task.id)}
                            alt=""
                        />
                    </div>
                );
            },
        },
    ];

    const onDelete = (id) => {
        confirmAlert({
            title: DELETE_MODAL_TITLE,
            message: DELETE_MODAL_MESSAGE,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        dispatch(removeTask(id, model));
                    },
                },
                {
                    label: "No",
                    onClick: () => { },
                },
            ],
        });
    };

    if (!show) {
        return <></>;
    }
    return (
        <div className="drag_list_view">
            <ReactDragListView {...dragProps}>
                <Table columns={columns} dataSource={tblData} pagination={false} />
            </ReactDragListView>
        </div>
    );
};

export default DocumentEditTable;
