import { useEffect, useCallback } from "react";
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import { getListingsByStatus } from "../../../redux/actions/dashboard";
import { getPrice } from "../../../utils/currency";

const AnalyticListings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const stats = useSelector((state) => state.dashboard.listingsByStatus);

  const titles = {
    progress: "IN-PROCESS",
    cancelled: "CANCELLED",
    draft: "DRAFT",
    completed: "SOLD",
    active: "ACTIVE",
  };

  useEffect(() => {
    dispatch(getListingsByStatus());
  }, []);

  const handleClickViewAll = useCallback(() => history.push('/listing-agreement'), []);

  return (
    <>
      <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
        <thead>
          <tr>
            <th>Status</th>
            <th>Deals</th>
            <th>Value</th>
            <th className="value-commision">Potential Commission</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(stats).filter(key => key !== 'completed').map((key) => {
            return (
              <tr key={key}>
                <td>{titles[key]}</td>
                <td>{stats[key].count}</td>
                <td>{getPrice(stats[key].value)}</td>
                <td>{getPrice(stats[key].commission)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {Object.keys(stats).length !== 0 && (
        <div className="view-all">
          <a href="#" onClick={handleClickViewAll}>View All</a>
        </div>
      )}
    </>
  );
};

export default AnalyticListings;
