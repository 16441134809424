import React, { Fragment } from 'react';

import { Button, Drawer, Space } from 'antd';
import PanelFormVessel from '../forms/PanelFormVessel';
import PanelFormSeller from '../forms/PanelFormSeller';
import PanelFormCoSeller from '../forms/PanelFormCoSeller';
import PanelFormTrailer from '../forms/PanelFormTrailer';
import PanelFormTerms from '../forms/PanelFormTerms';
import PanelFormToysAndTenders from '../forms/PanelFormToysAndTenders';
import PanelFormExclusions from '../forms/PanelFormExclusions';
import PanelFormMaterialDamageDisclosure from '../forms/PanelFormMaterialDamageDisclosure';
import PanelFormListingAgreementExclusion from '../forms/PanelFormListingAgreementExclusion';
import PanelFormListingAgreementAddendum from '../forms/PanelFormListingAgreementAddendum';

const FormDataDrawer = ({ show, onClose, formType, multiFormId = null }) => {

    const renderForms = () => {
        if (show) {
            switch (formType) {
                case 'Vessel':
                    return <PanelFormVessel closePanel={onClose} />

                case 'Seller':
                    return <PanelFormSeller closePanel={onClose} />

                case 'Co-Seller':
                    return <PanelFormCoSeller closePanel={onClose} />

                case 'Toys & Tenders':
                    return <PanelFormToysAndTenders closePanel={onClose} />

                case 'Trailer':
                    return <PanelFormTrailer closePanel={onClose} />

                case 'Terms':
                    return <PanelFormTerms closePanel={onClose} />

                case 'Exclusions':
                    return <PanelFormExclusions closePanel={onClose} />

                case 'Material Damage Disclosure':
                    return <PanelFormMaterialDamageDisclosure closePanel={onClose} />

                case 'IYBA Listing Agreement Exclusion':
                    return <PanelFormListingAgreementExclusion closePanel={onClose} exlusionId={multiFormId} />

                case 'IYBA Listing Agreement Addendum':
                    return <PanelFormListingAgreementAddendum closePanel={onClose} addendumId={multiFormId} />

                default:
                    return <></>;
            }
        }
    }

    return (
        <Drawer
            rootClassName="form-drawer"
            keyboard={false}
            maskClosable={false}
            title={`${formType} Form`}
            placement="left"
            size='large'
            onClose={() => onClose(false)}
            closable={false}
            open={show}
            extra={
                <Space>
                    <Button type='text' onClick={() => onClose(false)}><i className="fa fa-times-circle fa-lg"></i></Button>
                </Space>
            } >
            <Fragment>
                {renderForms()}
            </Fragment>
        </Drawer>
    )
}

export default FormDataDrawer;