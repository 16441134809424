import React, { Fragment, useEffect, useState } from 'react';
import { makeStyles } from "@mui/styles";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from 'react-phone-input-2';

import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import Country from "../../../../../constants/Country";
import StatesForUSA from "../../../../../constants/StatesForUSA";
import FormInputWrapper from "../../../../common/FormInputWapper";
import { CLIENT_REQUIRED_FIELDS } from '../../../../../utils/signer';
import { clearClient, deleteClient, findClientByName, getClient, getTransactionClient } from '../../../../../redux/actions/clients';
import { getTransactionInitialById, updateTransaction, upsertTransaction } from '../../../../../redux/actions/transaction';
import { setLoadingStatus } from '../../../../../redux/actions/loading';
import { getFormattedData, getPlainTransactionData } from "../../../../../utils/stepFilter";
import AutoCompleteSearch from '../../../../common/Autocomplete';

import * as yup from "yup";
import Auth from '../../../../../utils/auth';
import clsx from 'clsx';
import { Checkbox, FormControlLabel } from '@mui/material';

const useStyles = makeStyles({
    divider: {
        marginBottom: "15px",
    },
    phone_input: {
        padding: '12px 12px 12px 50px !important',
        width: '100% !important'
    },
    phoneInputDiv: {
        padding: "8px",
        marginLeft: "-7px",
        marginRight: "7px",
        marginTop: "-7px"
    }
});

const PanelFormCoBuyer = ({ closePanel, menuType }) => {

    const schema = yup.object().shape({});
    const { register, handleSubmit, setValue, getValues, reset, watch } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: false,
    });

    const dispatch = useDispatch();

    const classes = useStyles();
    const logged_user = Auth.getInstance().getUserData();

    const [isCobuyer, setIsCobuyer] = useState(false);
    const [isSelectedUSA, setSelectedUSA] = useState(true);
    const [isSameAddress, setIsSameAddress] = useState(false);
    const [isEditCoBuyer, setIsEditCoBuyer] = useState(false);
    const [requiredFields, setRequiredFields] = useState(CLIENT_REQUIRED_FIELDS);
    const [isHideFromOtherBroker, setIsHideFromOtherBroker] = useState(false);

    /* Get Datas from Store State */
    const dataClient = useSelector((state) => state.clients.client);
    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);
    const dataClients = useSelector((state) => state.clients.list);
    const dataDeal = dataTransaction || {};

    const optionsForAutocomplete = dataClients
        .map((client) => {
            return { id: client.id, value: `${client.first_name} ${client.last_name}` };
        })
        .sort((prev, next) => prev.value.localeCompare(next.value))
        .reduce((newArray, cur) => newArray.length && newArray.at(-1).value === cur.value ? newArray : [...newArray, cur], []);

    useEffect(() => {
        if (dataDeal && dataDeal.co_buyer_contact_id) {
            dispatch(getTransactionClient(dataDeal.co_buyer_contact_id));
            setIsCobuyer(true);
            setIsEditCoBuyer(true);
        } else {
            setValue("country", "USA");
            setSelectedUSA(true);
            setIsEditCoBuyer(false);
        }
    }, []);

    useEffect(() => {
        if (isSameAddress && dataDeal.buyer_contact_id) {
            const buyer = dataDeal.buyer;
            if (buyer.country === "USA") {
                setSelectedUSA(true);
            }
            setValue("address_1", buyer.address_1);
            setValue("address_2", buyer.address_2);
            setValue("address_3", buyer.address_3);
            setValue("city", buyer.city);
            setValue("state", buyer.state);
            setValue("country", buyer.country);
            setValue("postal_code", buyer.postal_code);
        }
        if (!isSameAddress) {
            setValue("address_1", null);
            setValue("address_2", null);
            setValue("address_3", null);
            setValue("city", null);
            setValue("state", null);
            setValue("country", "USA");
            setValue("postal_code", null);
        }
    }, [isSameAddress]);

    useEffect(() => {
        dispatch(findClientByName());

        return () => {
            dispatch(clearClient());
        };
    }, []);

    useEffect(() => {
        if (dataClient) {
            Object.keys(dataClient).forEach((key) => setValue(key, dataClient[key]));
            requiredFields.map((value) => {
                manageRequireFields(value, getValues(value));
            });
        } else {
            reset({});
            setSelectedUSA(true);
            setValue("country", "USA");
        }
    }, [dataClient]);

    useEffect(() => {
        setIsSameAddress(dataDeal?.is_buyer_same_address || false);
        setIsHideFromOtherBroker(dataDeal.is_co_buyer_hide_by_invited_broker || false);
    }, [dataDeal]);

    useEffect(() => {
        if (getValues("country") === "USA") {
            setSelectedUSA(true);
        } else {
            setSelectedUSA(false);
        }
    }, [watch("country")]);

    /**
     * On Submit co-buyer form with co-buyer details
     * @param {*} co_buyer 
     */
    const handleRegistration = (co_buyer) => {
        dispatch(setLoadingStatus(true));
        co_buyer = getFormattedData(co_buyer);
        if (dataDeal && dataDeal.co_buyer_contact_id) {
            co_buyer.id = dataDeal.co_buyer_contact_id;
        }

        if (!isEditCoBuyer) {
            delete co_buyer.id;
        }

        dispatch(upsertTransaction({
            step: {
                db: "cobuyer",
                main: {
                    ...co_buyer,
                    broker_user_id: dataBroker?.id,
                    company_id: dataBroker?.company_id,
                    company_office_id: dataBroker?.company_office_id,
                    createdBy: logged_user?.id,
                    updatedBy: logged_user?.id
                },
            },
            transaction: {
                isBelonged: true,
                main: {
                    ...getPlainTransactionData(dataDeal),
                    task_type: "deal",
                    is_buyer_same_address: isSameAddress,
                    broker_user_id: dataBroker?.id,
                    company_id: dataBroker?.company_id,
                    company_office_id: dataBroker?.company_office_id,
                    is_co_buyer_hide_by_invited_broker: isHideFromOtherBroker
                },
                foreignkeyName: "co_buyer_contact_id",
            },
        }, "Co-Buyer's")
        ).then((data) => {
            dispatch(setLoadingStatus(false));
            dispatch(getTransactionInitialById(data.id));
            dispatch(clearClient());
            closePanel(true);
        }).catch((err) => {
            console.error('ERROR::', err);
            dispatch(setLoadingStatus(false));
        });
    };

    /**
     * on Submit if any validation occurred
     * @param {*} error 
     */
    const handleError = (error) => {
        console.log(error);
    }

    /**
     * when if there is no co-buyer then remove the existing details
     */
    const removeCoBuyer = () => {
        if (dataDeal && dataDeal.co_buyer_contact_id) {
            dispatch(deleteClient(dataDeal.co_buyer_contact_id)).finally(() => {
                dispatch(updateTransaction(dataDeal.id, { co_buyer_contact_id: null })).finally(() => {
                    dispatch(updateTransaction(dataDeal.id, { is_co_buyer_hide_by_invited_broker: false })).then(() => {
                        closePanel(true);
                    }).catch((error) => {
                        console.log(error);
                    });
                });
            });
        }
    };

    /**
     * on search and select co-buyer from the suggested client list
     * @param {*} value 
     * @param {*} option 
     */
    const handleSelect = (value, option) => {
        dispatch(getClient(option.id));
    };

    /**
     * manage the required field display red color in input background
     * @param {*} fieldName 
     * @param {*} fieldValue 
     */
    const manageRequireFields = (fieldName, fieldValue) => {
        setRequiredFields(state => {
            if (fieldValue) {
                return state.filter(field => field != fieldName);
            }
            return state.includes(fieldName) ? state : [...state, fieldName];
        })
    }

    return (
        <Fragment>
            <div className="form-container panel-form">
                <FormRow>
                    <FormInputWrapper label="Is there a Co-Buyer/Joint Owner?">
                        <div className="radio-toolbar">
                            <input type="radio" id="radioToysYes" name="radioToys" checked={isCobuyer}
                                onClick={() => setIsCobuyer(!isCobuyer)} />
                            <label htmlFor='radioToysYes' className="uk-form-label radio">Yes</label>
                            <input type="radio" id="radioToysNo" name="radioToys" checked={!isCobuyer}
                                onClick={() => setIsCobuyer(!isCobuyer)} />
                            <label htmlFor='radioToysNo' className="uk-form-label radio">No</label>
                        </div>
                    </FormInputWrapper>
                </FormRow>
                {isCobuyer &&
                    <>
                        {menuType == 'invitation' &&
                            <FormRow>
                                <FormControlLabel
                                    style={{ display: 'flex', justifyContent: 'end' }}
                                    value="end"
                                    control={
                                        <Checkbox
                                            checked={isHideFromOtherBroker}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                            onChange={() => { setIsHideFromOtherBroker(!isHideFromOtherBroker) }}
                                        />
                                    }
                                    label="Hide info from other broker?"
                                />
                            </FormRow>
                        }
                        <FormRow>
                            <AutoCompleteSearch options={optionsForAutocomplete} onSelect={handleSelect} placeholder="Search Your Client List" />
                        </FormRow>
                        <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                            <FormRow>
                                <FormInputWrapper label="First Name *">
                                    <input value={getValues('first_name')}
                                        className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('first_name') })}
                                        {...register('first_name', {
                                            onChange: e => {
                                                manageRequireFields('first_name', e.target.value);
                                            }
                                        })} type="text" />
                                </FormInputWrapper>
                                <FormInputWrapper label="Middle Name">
                                    <input className="uk-input succeed" type="text" {...register("middle_name")} />
                                </FormInputWrapper>
                                <FormInputWrapper label="Last Name *">
                                    <input value={getValues('last_name')}
                                        className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('last_name') })}
                                        {...register('last_name', {
                                            onChange: e => {
                                                manageRequireFields('last_name', e.target.value);
                                            }
                                        })} type="text" />
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="Email *">
                                    <input value={getValues('email')}
                                        className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('email') })}
                                        {...register('email', {
                                            onChange: e => {
                                                manageRequireFields('email', e.target.value);
                                            }
                                        })} type="email" />
                                </FormInputWrapper>
                                <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                                    <label className="uk-form-label" htmlFor="name">Mobile Phone *</label>
                                    <input type="hidden" required name="mobile_phone"  {...register("mobile_phone")} />
                                    <PhoneInput
                                        placeholder='+'
                                        country={'us'}
                                        inputClass={classes.phone_input}
                                        containerClass={classes.phone_container}
                                        autoFocus={false}
                                        onFocus={() => { }}
                                        inputStyle={{
                                            background: `${requiredFields.includes('mobile_phone') ? '#fff7f8' : ''}`,
                                            borderColor: `${requiredFields.includes('mobile_phone') ? '#dc8d99' : ''}`
                                        }}
                                        buttonStyle={{ borderColor: `${requiredFields.includes('mobile_phone') ? '#dc8d99' : ''}` }}
                                        value={getValues("mobile_phone")}
                                        onChange={phone => {
                                            setValue('mobile_phone', phone, { shouldValidate: true })
                                            manageRequireFields('mobile_phone', phone);
                                        }} />
                                </div>
                                <FormInputWrapper label="Citizen Of">
                                    <input className="uk-input succeed" type="text" {...register("nationality")} />
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="">
                                    <input type="checkbox" checked={isSameAddress} onClick={() => setIsSameAddress(!isSameAddress)} />
                                    <span className="checkmark">Address same as buyer</span>
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="Address">
                                    <input className="uk-input succeed" type="text" {...register("address_1")} />
                                </FormInputWrapper>
                                <FormInputWrapper label="Address 2">
                                    <input className="uk-input" type="text" {...register("address_2")} />
                                </FormInputWrapper>
                                <FormInputWrapper label="Address 3">
                                    <input className="uk-input" type="text" {...register("address_3")} />
                                </FormInputWrapper>
                                <FormInputWrapper label="Country">
                                    <select className="uk-select" {...register("country")} defaultValue="USA">
                                        {Country.map((country) => {
                                            return (
                                                <option value={country.code} key={country.code}>
                                                    {country.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <FormInputWrapper label="City">
                                    <input className="uk-input succeed" type="text" {...register("city")} />
                                </FormInputWrapper>
                                <FormInputWrapper label="State">
                                    {isSelectedUSA
                                        ? <select className="uk-select" {...register("state")}>
                                            <option defaultValue={undefined}></option>
                                            {StatesForUSA.map((state) => {
                                                return (
                                                    <option value={state.code} key={state.code}>
                                                        {state.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        : <input className="uk-input" type="text" {...register("state")} />
                                    }
                                </FormInputWrapper>
                                <FormInputWrapper label="Zip/Postal Code">
                                    <input className="uk-input succeed" type="text" {...register("postal_code")} />
                                </FormInputWrapper>
                            </FormRow>
                            <FormRow>
                                <button type="submit" className='uk-button uk-button-primary pull-right'>Save</button>
                                <button type="button" className='uk-button uk-button-orange pull-right' onClick={() => closePanel(false)}>Close</button>
                            </FormRow>
                        </Form>
                    </>
                }
                {!isCobuyer && (
                    <FormRow>
                        <button type="button" className='uk-button uk-button-primary pull-right' onClick={removeCoBuyer}>Save</button>
                        <button type="button" className='uk-button uk-button-orange pull-right' onClick={() => closePanel(false)}>Close</button>
                    </FormRow>
                )}
            </div>
        </Fragment>
    )
}

export default PanelFormCoBuyer;