import OffCanvasContainer from "../../../../common/OffCanvasContainer";
import FormRow from "../../../../common/FormRow";
import ModalBody from "../../../../common/ModalBody";
import DetailOfAddendum from "../details/DetailOfAddendum";

const ViewAddendumForm = ({ addendum }) => {
  return (
    <OffCanvasContainer id="modal-addendum">
      <ModalBody title="Addendum">
        <DetailOfAddendum addendum={addendum} />
      </ModalBody>
    </OffCanvasContainer>
  );
};

export default ViewAddendumForm;
