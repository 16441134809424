import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { getPrice } from "../../../../utils/currency";
import { addVesselImage, getAllTransactions, updateTransaction } from "../../../../redux/actions/transaction";
import SoldDateModel from "../../../common/SoldDateModel";
import { setLoadingStatus } from "../../../../redux/actions/loading";
import UploadVesselImage from "../../../common/UploadVesselImage";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import ChangeBrokerModal from "../../../common/ChangeBrokerModal";
import ChangeTransactionStatusModal from "../../../common/ChangeTransactionStatusModal";

const ListItem = ({ listing, onDelete, user, onRefreshList }) => {

  const dispatch = useDispatch();

  const dataListing = listing;
  const dataVessel = dataListing.t_vessel;
  const dataSeller = dataListing.seller;
  const dataBroker = dataListing.broker;
  const dataTerms = dataListing.t_terms;
  const dataTasks = dataListing.t_task_master || [];
  const edocs = dataListing?.t_edocuments?.filter((doc) => doc != null);

  const getFilteredDataTasks = (data, filter) => {
    return data?.filter((task) => {
      if (task.status !== filter) { return false };
      if (task.company_tasks?.create_type === 'global' && dataListing.applied_filter_tag_id) {
        return !!task.tags?.find(tag => tag?.id == dataListing.applied_filter_tag_id);
      } else {
        return true
      }
    });
  }

  const uploads = dataTasks?.filter((upload) => upload.task_type === "document");
  const uploadsNumCompleted = getFilteredDataTasks(uploads, 'received')?.length || 0;
  const uploadsNumNotStarted = getFilteredDataTasks(uploads, 'open')?.length || 0;
  const uploadsNumInProcess = getFilteredDataTasks(uploads, 'required')?.length || 0;

  const tasks = dataTasks?.filter((upload) => upload.task_type === "task");
  const taskNumCompleted = getFilteredDataTasks(tasks, 'completed')?.length || 0;
  const taskNumNotStarted = getFilteredDataTasks(tasks, 'not_started')?.length || 0;
  const taskNumInProcess = getFilteredDataTasks(tasks, 'in_process')?.length || 0;

  const totalEdocuments = edocs?.length || 0;
  const eDocNumCompleted = edocs?.filter((doc) => doc === "Completed")?.length || 0;

  const titles = {
    progress: "IN-PROCESS",
    cancelled: "CANCELLED",
    draft: "DRAFT",
    completed: "SOLD",
    active: "ACTIVE",
    archived: "ARCHIVED"
  };

  let vesselTitle = `${dataVessel?.year || ""} ${dataVessel?.make || ""} ${dataVessel?.model || ""
    } ${dataVessel?.length || ""}`;
  let asking_price = `${dataTerms?.asking_price ? getPrice(dataTerms?.asking_price) : "N/A"}`;
  let sellerName =
    `${dataSeller?.first_name || ""} ${dataSeller?.last_name || ""}` !== " "
      ? `${dataSeller?.first_name || ""} ${dataSeller?.last_name || ""}`
      : "N/A";
  let brokerName =
    `${dataBroker?.first_name || ""} ${dataBroker?.last_name || ""}` !== " "
      ? `${dataBroker?.first_name || ""} ${dataBroker?.last_name || ""}`
      : "N/A";
  const association = dataListing?.association?.toUpperCase();

  const completedEdocs = edocs?.filter((doc) => doc === "Completed").sort((a, b) => b.updatedAt - a.updatedAt);
  // const nextEdocs = edocs
  //   .filter((doc) => doc.status !== "Completed")
  //   .sort((a, b) => a.sort_order - b.sort_order);

  const [isOpen, setIsOpen] = useState(false);

  const [show, setShow] = useState(false);
  const [imageTransaction, setImageTransaction] = useState(null);
  const [showBrokerModal, setShowBrokerModal] = useState(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  }

  const handleOpen = () => {
    setIsOpen(true);
  }

  const handleImageUpload = (transaction) => {
    if (transaction) {
      setImageTransaction(transaction);
      setShow(true);
    }
  }

  const handleCloseModal = (isLoad = false) => {
    setShow(false);
    setImageTransaction(null);
    if (isLoad) {
      onRefreshList();
    }
  }

  const handleCloseBrokerModal = (isLoad = false) => {
    setShowBrokerModal(false);
    if (isLoad) {
      onRefreshList();
    }
  }

  const handleRemoveVesselImage = (transactionId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to remove vessel image.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: "No"
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(setLoadingStatus(true));
        try {
          dispatch(addVesselImage(transactionId, { imageUrl: null })).then(
            response => {
              dispatch(setLoadingStatus(false));
              toast.success('Image removed successfully!');
              onRefreshList();
            }
          ).catch(error => {
            toast.error('Could not remove image!');
            dispatch(setLoadingStatus(false));
          })
        } catch (error) {
          dispatch(setLoadingStatus(false));
          console.error(error)
        }
      }
    })
  }

  const handleTransactionStatusChange = () => {
    setShowChangeStatusModal(true);
  }

  const handleTransactionStatusChangeModalClose = (doRefresh = false) => {
    setShowChangeStatusModal(false);
    if (doRefresh) {
      onRefreshList();
    }
  }

  return (
    <>
      <tr>
        <td className={`stylus-td-${dataListing.status || "draft"}`} onClick={handleTransactionStatusChange}>
          <span className="primary">
            <span className="status-rotate">
              {
                completedEdocs?.length > 0 && completedEdocs[0]?.title === 'Listing Agreement' ?
                  titles["active"] :
                  titles[dataListing?.status || "draft"]
              }
            </span>
          </span>
        </td>
        <td>
          <div className="uk-column-1-1">
            <span className="uk-text-emphasis title">
              {vesselTitle === "   " ? "N/A" : vesselTitle}
            </span>
            <br />
            <i className="uk-text-emphasis vessel_title">
              {dataVessel?.vessel_name === "   " ? "" : dataVessel?.vessel_name}
            </i>
            <br />
            <div className="uk-column-2-2">
              <div className="uk-padding-small">
                <label className="uk-form-label uk-text-muted">Asking Price</label>
                <div className="uk-text-emphasis">{asking_price}</div>
              </div>
            </div>
            <div className="uk-column-2-2">
              {dataListing.status == 'completed'
                ? <div className="uk-padding-small">
                  <label className="uk-form-label uk-text-muted">Date Sold</label>
                  <span className="uk-text-emphasis">
                    {dataListing?.sold_date ? (
                      <Moment date={dataListing?.sold_date} format="MMMM DD, YYYY" />
                    ) : (
                      "N/A"
                    )}
                  </span>
                </div>
                : <div className="uk-padding-small">
                  <label className="uk-form-label uk-text-muted">Listed On</label>
                  <span className="uk-text-emphasis">
                    {dataTerms?.contract_date ? (
                      <Moment date={dataTerms?.contract_date} format="MMMM DD, YYYY" />
                    ) : (
                      "N/A"
                    )}
                  </span>
                  <br />
                  {dataTerms?.contract_date ? (
                    <span className="uk-text-emphasis">
                      (
                      <Moment fromNow ago date={dataTerms?.contract_date} />)
                    </span>
                  ) : (
                    ""
                  )}
                </div>}
            </div>
            <div className="uk-column-1-2">
              <div className="uk-padding-small">
                <span className="uk-text-small uk-text-muted label"></span>
              </div>
              <div className="uk-padding-small"></div>
            </div>
          </div>
        </td>
        <td>
          {/*
        <img className="broker-img" src="/photos/1.png" />
        */}
          <div className="broker-img-right">
            <label className="uk-form-label uk-text-muted">Broker</label>
            {user?.UserInRole?.UserRole?.name == 'Company Admin'
              ? <Tooltip title="Update Broker" placement="right">
                <a onClick={() => setShowBrokerModal(true)}>
                  <span className="uk-text-emphasis text-primary">{brokerName}</span>
                </a>
              </Tooltip>
              : <span className="uk-text-emphasis">{brokerName}</span>
            }
            {listing?.employing_broker?.id != null &&
              <>
                <label className="uk-form-label uk-text-muted pt-2">Employing Broker</label>
                <span className="uk-text-emphasis">{`${listing?.employing_broker?.first_name} ${listing?.employing_broker?.last_name}`}</span>
              </>
            }
            <br />
            <br />
            <label className="uk-form-label uk-text-muted">Seller</label>
            <span className="uk-text-emphasis">{sellerName}</span>
            <br />
            <br />
            <label className="uk-form-label uk-text-muted">Association</label>
            <span className="uk-text-emphasis">{association}</span>
          </div>
        </td>
        {/* <td>
        <span className="uk-text-small uk-text-muted label-full">Up Next</span>
        <br />
        <span className="uk-text-emphasis">
          {dataListing.status === "draft" && nextEdocs.length === 0
            ? "Listing Agreement"
            : nextEdocs.length > 0
              ? nextEdocs[0].title
              : null}
        </span>
        <br />

        <label className="uk-text-small uk-text-muted label-full">Last Completed</label>
        <br />
        <span className="uk-text-emphasis">
          {completedEdocs.length > 0 ? completedEdocs[0].title : null}
        </span>
        <br />
      </td> */}

        {/* Uploads */}
        <td>
          <label className="uk-form-label uk-text-muted">Open :  </label>
          <span className="uk-text-emphasis">{isFinite(uploadsNumNotStarted) ? uploadsNumNotStarted : "N/A"}</span>
          <br />
          <br />
          <label className="uk-form-label uk-text-muted">Required : </label>
          <span className="uk-text-emphasis">{isFinite(uploadsNumInProcess) ? uploadsNumInProcess : "N/A"}</span>
          <br />
          <br />
          <label className="uk-form-label uk-text-muted">Received : </label>
          <span className="uk-text-emphasis">{isFinite(uploadsNumCompleted) ? uploadsNumCompleted : "N/A"}</span>
        </td>

        {/* Tasks */}
        <td>
          <label className="uk-form-label uk-text-muted">Not Started :  </label>
          <span className="uk-text-emphasis">{isFinite(taskNumNotStarted) ? taskNumNotStarted : "N/A"}</span>
          <br />
          <br />
          <label className="uk-form-label uk-text-muted">In Process : </label>
          <span className="uk-text-emphasis">{isFinite(taskNumInProcess) ? taskNumInProcess : "N/A"}</span>
          <br />
          <br />
          <label className="uk-form-label uk-text-muted">Completed : </label>
          <span className="uk-text-emphasis">{isFinite(taskNumCompleted) ? taskNumCompleted : "N/A"}</span>
        </td>

        {/* EDocuments */}
        <td>
          <label className="uk-form-label uk-text-muted">Total :  </label>
          <span className="uk-text-emphasis">{isFinite(totalEdocuments) ? totalEdocuments : "N/A"}</span>
          <br />
          <br />
          <label className="uk-form-label uk-text-muted">Completed : </label>
          <span className="uk-text-emphasis">{isFinite(eDocNumCompleted) ? eDocNumCompleted : "N/A"}</span>
        </td>


        <td className="actions">
          <div style={{ width: "100px", display: '-webkit-box', flexDirection: 'row' }}>
            <div className="uk-text-emphasis"> <span className="uk-text-muted">UID:</span> <span style={{ color: '#004D89' }}>LA-{dataListing.unique_transaction_id}</span></div>
          </div>
          <div style={{ display: '-webkit-box', flexDirection: 'row' }}>
            <div className="uk-text-emphasis"> <span className="uk-text-muted">CREATED:</span> <span style={{ color: '#004D89' }}><Moment date={dataListing.createdAt || "N/A"} format="MM/DD/YYYY" /></span></div>
          </div>
          <div className="mb-4" style={{ display: '-webkit-box', flexDirection: 'row' }}>
            <div className="uk-text-emphasis"> <span className="uk-text-muted">UPDATED:</span> <span style={{ color: '#004D89' }}><Moment date={dataListing.updatedAt || "N/A"} format="MM/DD/YYYY" /></span></div>
          </div>
          <div style={{ width: "100px", display: '-webkit-box', flexDirection: 'row' }}>
            <Link
              to={`/listing-agreement/view/${dataListing.id}/main`}
              style={{ display: "block", marginBottom: "20px" }}
            >
              <img src="/icons/edit.svg" className="svg" />
            </Link>
            {/* <div className="uk-inline">
              <button className="uk-button uk-button-default list-status" style={{ marginRight: 0 }} type="button"></button>
              <div uk-dropdown="pos: bottom-right;" style={{ marginTop: 0, padding: 5 }}>
                <ul className="uk-list uk-list-divider">
                  <li className="" onClick={() => changeStatus("progress")}>
                    <a className="">In-Process</a>
                  </li>
                  <li className="" onClick={() => changeStatus("active")}>
                    <a className="">Active</a>
                  </li>
                  <li className="">
                    <a className="" onClick={handleOpen}>Sold</a>
                    <SoldDateModel type="listing" data={listing} isOpen={isOpen} onClose={handleClose} componentsId={listing.id} user={user} search={search} />
                  </li>
                  <li className="" onClick={() => changeStatus("cancelled")}>
                    <a className="">Cancelled</a>
                  </li>
                  <li className="" onClick={() => changeStatus("archived")}>
                    <a className="">Archived</a>
                  </li>
                </ul>
              </div>
            </div> */}
            {
              (user && ["Company Admin", "Boatdox Admin"].includes(user.UserInRole.UserRole.name)) &&
              <>
                <a
                  onClick={() => onDelete(dataListing.id)}
                  style={{ display: "block", marginBottom: "20px" }}
                >
                  <img src="/icons/delete.svg" className="svg" style={{ marginLeft: "3px" }} />
                </a>
              </>
            }
          </div>
          <div className="d-flex justify-content-start">
            {listing.image_url
              ?
              <div className="transaction-vessel-img">
                <img className={`stylus-border-${listing.status || "draft"}`} src={`https://${process.env.REACT_APP_UPLOADS_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}vessel-image/${listing.image_url}`} alt="Vessel Img" height={'100px'} width={'100px'} />
                <a className="edit-vessel-img" onClick={() => handleImageUpload(listing)}>
                  <i style={{ marginRight: '12px', color: '#fff' }} className="vessel-img-button fa fa-edit fa-lg"></i>
                </a>
                <a className="delete-vessel-img" onClick={() => handleRemoveVesselImage(listing.id)}>
                  <i style={{ marginLeft: '12px', color: '#fff' }} className="vessel-img-button fa fa-trash fa-lg" ></i>
                </a>
              </div>
              : <div className="pt-4"><button className="uk-button uk-button-primary" type="button" onClick={() => handleImageUpload(listing)}>Add Image</button></div>
            }
          </div>
        </td>
      </tr>
      {show && <UploadVesselImage show={show} onClose={handleCloseModal} transaction={imageTransaction} imgUrl={listing.image_url} uniqueId={imageTransaction?.unique_transaction_id && `LA-${imageTransaction.unique_transaction_id?.split('-')[1] || ""}`} companyUId={imageTransaction?.unique_transaction_id && `${imageTransaction.unique_transaction_id?.split('-')[0] || ""}`} />}
      <ChangeBrokerModal show={showBrokerModal} broker={dataBroker} user={user} transactionId={dataListing.id} onClose={handleCloseBrokerModal} />
      <ChangeTransactionStatusModal show={showChangeStatusModal} transaction={dataListing} onClose={handleTransactionStatusChangeModalClose} />
    </>
  );
};

export default ListItem;
