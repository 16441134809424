import clsx from 'clsx'
import React from 'react'

const TutorialInput = ({tutorial,register,setValue}) => {
    return (
        <div className="input-group uk-padding-small ">
            <input className={clsx("uk-input rounded-pill-left form-control border border-right-0 shadow-none")} {...register(`${tutorial}`)} />
            <button
                onClick={() => { setValue(`${tutorial}`, '') }}
                className="btn btn-outline-secondary rounded-pill-right border border-left-0 shadow-none"
                type="button">
                <i class={'fa fa-times'} aria-label="Search Icon" role="search"></i>
            </button>
        </div>
    )
}

export default TutorialInput