const AccordionItem = ({ title, children }) => {
  return (
    <li>
      <a className="uk-accordion-title">{title}</a>
      <div className="uk-accordion-content">{children}</div>
    </li>
  );
};

export default AccordionItem;
