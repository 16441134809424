import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography, tooltipClasses } from '@mui/material';

import { getTransactionEdocById, getTransactionEsignById, getTransactionInitialById } from '../../../../redux/actions/transaction';
import { LEGAL_AND_OR_ASSIGN, LEGAL_OR_ASSIGN, LEGAL_OWNER } from '../../../../utils/signer';

import './formDataSidebar.css';
import clsx from "clsx";
import FormDataDrawer from './accordions/FormDataDrawer';
import SurveyWaiverFormDataModal from '../../../common/SurveyWaiverFormDataModal';
import Auth from '../../../../utils/auth';

const titles = [
    "Acceptance of Vessel",
    "Conditional Acceptance of Vessel",
    "Conditional Acceptance with Survey Allowance of Vessel",
    "Rejection of Vessel",
];

const customTooltip = {
    [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
    {
        marginLeft: '-65px',
    },
}
const customTooltipSmall = {
    [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
    {
        marginLeft: '-90px',
    },
}
const customTooltipLarge = {
    [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
    {
        marginLeft: '-40px',
    },
}

const FormDataSideBar = ({ transactionId, sliderFor, reloadPdf = null, participantForm = null, onPanelClose = null, menuType, isPdfEdit }) => {

    const dispatch = useDispatch();

    const dataRequiredFields = useSelector((state) => state.transactions.requiredFields);
    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const loginEmail = Auth.getInstance().getUserData().email;
    const dataDeal = dataTransaction || {};
    const dataEdocuments = dataDeal.t_edocuments || [];
    const dataExclusion = dataDeal.t_purchase_exclusion || [];
    const dataAddendum = dataDeal.t_purchase_addendum || [];
    const dataTradeIn = dataDeal.t_trade_in_vessel || {};

    const dataSellerClosing = dataDeal.t_seller_closing;
    const dataBuyerClosing = dataDeal.t_buyer_closing;

    const tradeRequiredFields = {
        hull_number: "Hull No.",
        length: "Length",
        make: "Make",
        model: "Model",
        boat_type: "Boat Type",
        year: "Year",
        accept_reject_date: "Trade Vessel Accept/Reject Date",
        delivery_location: "Trade Vessel Delivery Location",
        allowance: "Allowance",
        exclusion_list: "Trade Vessel Exclusion List",
        doc_no: "Please input at least one: Doc No., Reg No., or Title No.",
        engine_make: "Engine Make",
        engine_model: "Engine Model",
        engine_hp: "Engine HP"
    }

    // const tradeInVesselDocument = dataEdocuments.find(doc => doc.task_type == 'trade-in-vessel') || null;
    const surveyWaiverDocument = dataEdocuments.find(doc => doc.task_type == 'survey-waiver') || null;

    const buyerClosingDcument = dataEdocuments.find(doc => doc.task_type == 'buyer-closing-statement') || null;
    const sellerClosingDcument = (dataEdocuments.find(doc => doc.task_type == 'seller-closing-statement') || dataEdocuments.find(doc => doc.task_type == 'disbursement-of-funds---seller')) || dataEdocuments.find(doc => doc.task_type == 'escrow-agreement') || null;

    const acceptanceOfVesselDocuments = dataEdocuments.filter(doc => doc.task_type == 'acceptance-of-vessel').sort((a, b) => {
        if (a.sort_order == b.sort_order) {
            return a.duplicate_occurrence > b.duplicate_occurrence ? 1 : -1
        }
        return a.sort_order > b.sort_order ? 1 : -1
    }) || [];

    const exclusionDocuments = dataEdocuments.filter(doc => doc.task_type == 'purchase-&-sale-agreement-exclusion').sort((a, b) => {
        if (a.sort_order == b.sort_order) {
            return a.duplicate_occurrence > b.duplicate_occurrence ? 1 : -1
        }
        return a.sort_order > b.sort_order ? 1 : -1
    }) || [];

    const addendumDocuments = dataEdocuments.filter(doc => doc.task_type == 'purchase-&-sale-agreement-addendum').sort((a, b) => {
        if (a.sort_order == b.sort_order) {
            return a.duplicate_occurrence > b.duplicate_occurrence ? 1 : -1
        }
        return a.sort_order > b.sort_order ? 1 : -1
    }) || [];

    const [asterix, setAsterix] = useState({});
    const [show, setShow] = useState(false);
    const [surveyWaiverFormShow, setSurveyWaiverFormShow] = useState(false);
    const [selectedForm, setSelectedForm] = useState(null);
    const [multiFormId, setMultiFormId] = useState(null)
    const [open, setOpen] = useState({
        formData: false,
        exclusion: false,
        addendum: false,
    });

    useEffect(() => {
        if (transactionId && sliderFor == 'editor') {
            dispatch(getTransactionInitialById(transactionId)).then((data) => {
                dispatch(getTransactionEdocById(transactionId, menuType === 'invitation', dataDeal.broker?.company_id)).then((edoc) => {
                    dispatch(getTransactionEsignById(transactionId));
                });
            });
        }
    }, []);

    useEffect(() => {
        const vesselRequireds = dataRequiredFields.vessel;
        const buyerRequireds = dataRequiredFields.buyer;
        const coBuyerRequireds = dataRequiredFields.co_buyer;
        const offerRequireds = dataRequiredFields.offer;
        const sellerRequireds = dataRequiredFields.seller;
        const coSellerRequireds = dataRequiredFields.co_seller;
        const termsRequireds = dataRequiredFields.terms;
        const coBrokerRequireds = dataRequiredFields.co_broker;
        const trailerRequireds = dataRequiredFields.trailer;

        let vesselAsterix = "empty";
        let buyerAsterix = "empty";
        let coBuyerAsterix = "empty";
        let offerAsterix = "empty";
        let sellerAsterix = "empty";
        let coSellerAsterix = "empty";
        let termsAsterix = "empty";
        let coBrokerAsterix = "empty";
        let trailerAsterix = "empty";

        if (vesselRequireds.length > 0 && vesselRequireds.length < 5) vesselAsterix = "notFulfill";
        if (buyerRequireds.length > 0 && buyerRequireds.length < ((dataTransaction?.buyer_type === LEGAL_OWNER || dataTransaction?.buyer_type === LEGAL_OR_ASSIGN || dataTransaction?.buyer_type === LEGAL_AND_OR_ASSIGN) ? 4 : 8)) buyerAsterix = "notFulfill";
        if (buyerRequireds.length === 1 && buyerRequireds[0]['title']) buyerAsterix = "empty";
        if (coBuyerRequireds.length > 0 && coBuyerRequireds.length < 4) coBuyerAsterix = "notFulfill";
        if (offerRequireds.length > 0 && offerRequireds.length < 5) offerAsterix = "notFulfill";
        if (sellerRequireds.length > 0 && sellerRequireds.length < (dataTransaction?.seller_type === LEGAL_OWNER ? 4 : 8)) sellerAsterix = "notFulfill";
        if (sellerRequireds.length === 1 && sellerRequireds[0]['title']) sellerAsterix = "empty";
        if (coSellerRequireds.length > 0 && coSellerRequireds.length < 4)
            coSellerAsterix = "notFulfill";
        if (termsRequireds.length > 0 && termsRequireds.length < 2) termsAsterix = "notFulfill";
        if (coBrokerRequireds.length > 0 && coBrokerRequireds.length < 6) coBrokerAsterix = "notFulfill";

        if (vesselRequireds.length === 0) vesselAsterix = "fulfill";
        if (buyerRequireds.length === 0) buyerAsterix = "fulfill";
        if (coBuyerRequireds.length === 0) coBuyerAsterix = "fulfill";
        if (offerRequireds.length === 0) offerAsterix = "fulfill";
        if (sellerRequireds.length === 0) sellerAsterix = "fulfill";
        if (coSellerRequireds.length === 0) coSellerAsterix = "fulfill";
        if (termsRequireds.length === 0) termsAsterix = "fulfill";
        if (coBrokerRequireds.length === 0) coBrokerAsterix = "fulfill";

        if (dataTransaction?.owner_or_legal === 2) sellerAsterix = "fulfill";
        if (trailerRequireds.length === 0) trailerAsterix = "fulfill";

        const val = {
            vessel: vesselAsterix,
            buyer: buyerAsterix,
            co_buyer: coBuyerAsterix,
            offer: offerAsterix,
            seller: sellerAsterix,
            co_seller: coSellerAsterix,
            terms: termsAsterix,
            co_broker: coBrokerAsterix,
            trailer: trailerAsterix
        };

        if (dataTransaction?.t_toys?.length > 0) {
            val.toys = "fulfill";
        } else {
            val.toys = "empty";
        }
        if (dataTransaction?.t_exclusion?.length > 0) {
            val.exclusions = "fulfill";
        } else {
            val.exclusions = "empty";
        }

        if (dataTradeIn && dataTradeIn.id) {
            let tradeInRequiredFields = [];
            Object.keys(tradeRequiredFields).forEach(key => {
                if (key == "doc_no") {
                    if (!dataTradeIn["doc_no"] && !dataTradeIn["reg_no"] && !dataTradeIn["title_no"]) {
                        tradeInRequiredFields.push({ "": "Please input at least one: Doc No., Reg No., or Title No." });
                        return
                    }
                }
                if (!dataTradeIn[key]) {
                    tradeInRequiredFields.push({ [key]: tradeRequiredFields[key] });
                }
            });

            if (tradeInRequiredFields.length === 0) {
                val.tradeInVessel = "fulfill";
            } else if (tradeInRequiredFields.length === tradeRequiredFields.length) {
                val.tradeInVessel = "empty";
            } else {
                val.tradeInVessel = "notFulfill";
            }
        } else {
            Object.keys(tradeRequiredFields).forEach(key => {
                requiredFields.push({ [key]: tradeRequiredFields[key] });
            });
            val.tradeInVessel = "empty";
        }

        if (dataSellerClosing) {
            const { gross_sales_commission } = dataSellerClosing;
            if (gross_sales_commission) {
                val.sellerClosingStatement = "fulfill";
            } else {
                val.sellerClosingStatement = "empty";
            }
        } else {
            val.sellerClosingStatement = "empty";
        }

        if (dataBuyerClosing) {
            const { sales_price_total, sub_total, sales_tax_vessel, is_sales_tax } = dataBuyerClosing;
            if (is_sales_tax) {
                if (sales_price_total && sub_total && sales_tax_vessel >= 0) {
                    val.buyerClosingStatement = "fulfill";
                } else if (sales_price_total || sub_total || sales_tax_vessel >= 0) {
                    val.buyerClosingStatement = "notFulfill";
                } else {
                    val.buyerClosingStatement = "empty";
                }
            } else {
                if (sales_price_total && sub_total) {
                    val.buyerClosingStatement = "fulfill";
                } else if (sales_price_total || sub_total) {
                    val.buyerClosingStatement = "notFulfill";
                } else {
                    val.buyerClosingStatement = "empty";
                }
            }
        } else {
            val.buyerClosingStatement = "empty";
        }

        setAsterix(val);
    }, [dataRequiredFields, dataTransaction]);

    useEffect(() => {
        if (participantForm) {
            participantForm = participantForm.includes('Listing') || participantForm.includes('Selling') ? 'Co-Broker & Commission' : participantForm;
            handleOpenPanel(participantForm);
        }
    }, [participantForm])

    const handleOpenPanel = (formType, dataId = null) => {
        setSelectedForm(formType);
        setMultiFormId(dataId || null)
        setShow(true);
    }

    const handleClick = (type) => {
        setOpen({
            ...open,
            [type]: !open[type]
        });
    };

    const acceptanceOfVesselDataStatus = (acceptanceId) => {
        const dataAcceptance = dataDeal.t_acceptance?.find(el => el.id === acceptanceId) || {};
        let status = 'empty';

        if (dataAcceptance) {
            switch (dataAcceptance.status) {
                case 0:
                    status = 'fulfill';
                    break;
                case 1: {
                    if (!!dataAcceptance.days_to_complete_conditions && !!dataAcceptance.condition_1 || !!dataAcceptance.condition_2) {
                        status = 'fulfill';
                    } else {
                        status = 'empty';
                    }
                    break;
                }
                case 2: {
                    if (!!dataAcceptance.adjustment_amount &&
                        (!!dataAcceptance.condition_1 || !!dataAcceptance.condition_2)
                    ) {
                        status = 'fulfill';
                    } else if (
                        !dataAcceptance.adjustment_amount &&
                        !dataAcceptance.condition_1 &&
                        !dataAcceptance.condition_2
                    ) {
                        status = 'empty';
                    } else {
                        status = 'notFulfill';
                    }
                    break;
                }
                case 3: {
                    if (!!dataAcceptance.reason_for_rejection) {
                        status = 'fulfill';
                    } else {
                        status = 'empty';
                    }
                    break;
                }
                default:
                    status = 'empty';
                    break;
            }
        }
        return status;
    }

    const exclusionDataStatus = (exclusionId) => {
        const exclusion = dataExclusion.find(el => el.id === exclusionId);
        const blankExclusionStr = Array(20).fill().map((v, i) => "/:/").join("").slice(0, -3);
        return (exclusion?.exclusion && !["", blankExclusionStr].includes(exclusion?.exclusion)) ? "fulfill" : "empty";
    }

    const requiredFields = [
        { addendum_no: "Addendum Number" },
        { ammendment: "Provision #1" },
    ];

    const addendumDataStatus = (addendumId) => {
        let status = 'empty';
        let notfilledFields = [];
        const addendum = dataAddendum.find(el => el.t_edocument_id === addendumId);
        if (addendum) {
            requiredFields.forEach((rf) => {
                const field_key = Object.keys(rf)[0];
                if (field_key === 'ammendment') {
                    if (addendum[field_key] && addendum[field_key] === "/://://://:/") {
                        notfilledFields.push(rf);
                    }
                } else {
                    if (!addendum[field_key]) {
                        notfilledFields.push(rf);
                    }
                }
            });
            status = notfilledFields.length === 2
                ? "empty"
                : notfilledFields.length === 0
                    ? "fulfill"
                    : "notFulfill";
        } else {
            notfilledFields = requiredFields
            status = "empty";
        }
        return status;
    }

    useEffect(() => {
        if (show && selectedForm) {
            const alertUser = (e) => {
                if (e) {
                    e.preventDefault();
                    e.returnValue = "";
                }
            };

            window.addEventListener("beforeunload", alertUser);
            return () => {
                window.removeEventListener("beforeunload", alertUser);
            };
        }
    }, [show, selectedForm]);

    const onClosePanel = (isPDFReload) => {
        // if (isPDFReload) {
        setSelectedForm(null);
        // if (participantForm) {
        if (!isPdfEdit) {
            onPanelClose(true);
        }
        // }

        setShow(false);
        if (reloadPdf != null) {
            reloadPdf(isPDFReload);
        }
        //     return;
        // }

        // Swal.fire({
        //     title: 'Close Form!',
        //     text: "Changes that you made may not be saved.",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Yes',
        //     cancelButtonText: "No"
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         setSelectedForm(null);
        //         setShow(false);
        //     }
        // });
    }

    return (
        <>
            <div className="tm-sidebar-left uk-visible@m py-0">
                <div className="sidebar-container add-deal">
                    <Box role="presentation">
                        <List className='py-0'>
                            <div className="my-1">
                                <ListItemButton onClick={() => handleClick('formData')} sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }} >
                                    <ListItemText><span>Form Data</span></ListItemText>
                                    {open['formData'] ? <i class="fa fa-chevron-up"></i> : <i class="fa fa-chevron-down"></i>}
                                </ListItemButton>
                                <Collapse in={open['formData']} timeout="auto" unmountOnExit>
                                    <ListItemButton onClick={() => handleOpenPanel('Vessel')}>
                                        <ListItemIcon>
                                            <i
                                                className={clsx(
                                                    "fa",
                                                    { "fa-check-circle stepbar": asterix.vessel === "fulfill" },
                                                    { "fa-circle-o": asterix.vessel === "empty" || !asterix.vessel },
                                                    { "fa-circle": asterix.vessel === "notFulfill" }
                                                )}
                                            ></i>
                                        </ListItemIcon>
                                        <ListItemText primary={'Vessel'} />
                                    </ListItemButton>
                                    <Divider />
                                    <Tooltip title={`${((menuType === "invitation" && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_buyer_hide_by_invited_broker)) ? "Not Accessible " : ""}`} arrow placement='right' slotProps={{ popper: { sx: customTooltipSmall }, }} >
                                        <span>
                                            <ListItemButton
                                                disabled={(menuType === "invitation" && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_buyer_hide_by_invited_broker)}
                                                onClick={() => handleOpenPanel('Buyer')}
                                                style={((menuType === "invitation" && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_buyer_hide_by_invited_broker))
                                                    ? { color: "red", opacity: 1, pointerEvents: "none" }
                                                    : {}}>
                                                <ListItemIcon>
                                                    <i
                                                        className={clsx(
                                                            "fa",
                                                            { "fa-check-circle stepbar": asterix.buyer === "fulfill" },
                                                            { "fa-circle-o": asterix.buyer === "empty" || !asterix.buyer },
                                                            { "fa-circle": asterix.buyer === "notFulfill" }
                                                        )}
                                                    ></i>
                                                </ListItemIcon>
                                                <ListItemText primary={'Buyer'} />
                                            </ListItemButton></span>
                                    </Tooltip>
                                    <Divider />
                                    <Tooltip title={`${((menuType === "invitation" && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_co_buyer_hide_by_invited_broker)) ? "Not Accessible " : ""}`} arrow placement='right' slotProps={{ popper: { sx: customTooltip }, }} >
                                        <span>
                                            <ListItemButton
                                                disabled={(menuType === "invitation" && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_co_buyer_hide_by_invited_broker)}
                                                onClick={() => handleOpenPanel('Co-Buyer')}
                                                style={((menuType === "invitation" && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_co_buyer_hide_by_invited_broker)) ? { color: "red", opacity: 1 } : {}}>
                                                <ListItemIcon>
                                                    <i
                                                        className={clsx(
                                                            "fa",
                                                            { "fa-check-circle stepbar": asterix.co_buyer === "fulfill" },
                                                            { "fa-circle-o": asterix.co_buyer === "empty" || !asterix.co_buyer },
                                                            { "fa-circle": asterix.co_buyer === "notFulfill" }
                                                        )}
                                                    ></i>
                                                </ListItemIcon>
                                                <ListItemText primary={'Co-Buyer'} />
                                            </ListItemButton>
                                        </span>
                                    </Tooltip>
                                    <Divider />
                                    <Tooltip title={`${((menuType === "invitation" && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_seller_hide_by_invited_broker)) ? "Not Accessible " : ""}`} arrow placement='right' slotProps={{ popper: { sx: customTooltipSmall }, }}  >
                                        <span>
                                            <ListItemButton
                                                disabled={(menuType === "invitation" && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_seller_hide_by_invited_broker)}
                                                onClick={() => handleOpenPanel('Seller')}
                                                style={((menuType === "invitation" && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_seller_hide_by_invited_broker))
                                                    ? { color: "red", opacity: 1 }
                                                    : {}}>
                                                <ListItemIcon>
                                                    <i
                                                        className={clsx(
                                                            "fa",
                                                            { "fa-check-circle stepbar": asterix.seller === "fulfill" },
                                                            { "fa-circle-o": asterix.seller === "empty" || !asterix.seller },
                                                            { "fa-circle": asterix.seller === "notFulfill" }
                                                        )}
                                                    ></i>
                                                </ListItemIcon>
                                                <ListItemText primary={'Seller'} />
                                            </ListItemButton></span>
                                    </Tooltip>
                                    <Divider />
                                    <Tooltip title={`${((menuType === "invitation" && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_co_seller_hide_by_invited_broker)) ? "Not Accessible " : ""}`} arrow placement='right' slotProps={{ popper: { sx: customTooltip }, }} >
                                        <span>
                                            <ListItemButton
                                                disabled={(menuType === "invitation" && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_co_seller_hide_by_invited_broker)}
                                                onClick={() => handleOpenPanel('Co-Seller')}
                                                style={((menuType === "invitation" && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_co_seller_hide_by_invited_broker)) ? { color: "red", opacity: 1 } : {}}
                                            >
                                                <ListItemIcon>
                                                    <i
                                                        className={clsx(
                                                            "fa",
                                                            { "fa-check-circle stepbar": asterix.co_seller === "fulfill" },
                                                            { "fa-circle-o": asterix.co_seller === "empty" || !asterix.co_seller },
                                                            { "fa-circle": asterix.co_seller === "notFulfill" }
                                                        )}
                                                    ></i>
                                                </ListItemIcon>
                                                <ListItemText primary={'Co-Seller'} />
                                            </ListItemButton>
                                        </span>
                                    </Tooltip>
                                    <Divider />
                                    <ListItemButton onClick={() => handleOpenPanel('Toys & Tenders')}>
                                        <ListItemIcon>
                                            <i
                                                className={clsx(
                                                    "fa",
                                                    { "fa-check-circle stepbar": asterix.toys === "fulfill" },
                                                    { "fa-circle-o": asterix.toys === "empty" || !asterix.toys },
                                                    { "fa-circle": asterix.toys === "notFulfill" }
                                                )}
                                            ></i>
                                        </ListItemIcon>
                                        <ListItemText primary={'Toys & Tenders'} />
                                    </ListItemButton>
                                    <Divider />
                                    <ListItemButton onClick={() => handleOpenPanel('Trailer')}>
                                        <ListItemIcon>
                                            <i
                                                className={clsx(
                                                    "fa",
                                                    { "fa-check-circle stepbar": asterix.trailer === "fulfill" },
                                                    { "fa-circle-o": asterix.trailer === "empty" || !asterix.trailer },
                                                    { "fa-circle": asterix.trailer === "notFulfill" }
                                                )}
                                            ></i>
                                        </ListItemIcon>
                                        <ListItemText primary={'Trailer'} />
                                    </ListItemButton>
                                    <Divider />
                                    <ListItemButton onClick={() => handleOpenPanel('Trade-In Vessel')}>
                                        <ListItemIcon>
                                            <i
                                                className={clsx(
                                                    "fa",
                                                    { "fa-check-circle stepbar": asterix.tradeInVessel === "fulfill" },
                                                    { "fa-circle-o": asterix.tradeInVessel === "empty" || !asterix.tradeInVessel },
                                                    { "fa-circle": asterix.tradeInVessel === "notFulfill" }
                                                )}
                                            ></i>
                                        </ListItemIcon>
                                        <ListItemText primary={'Trade In'} />
                                    </ListItemButton>
                                    <Divider />
                                    <ListItemButton onClick={() => handleOpenPanel('Offer')}>
                                        <ListItemIcon>
                                            <i
                                                className={clsx(
                                                    "fa",
                                                    { "fa-check-circle stepbar": asterix.offer === "fulfill" },
                                                    { "fa-circle-o": asterix.offer === "empty" || !asterix.offer },
                                                    { "fa-circle": asterix.offer === "notFulfill" }
                                                )}
                                            ></i>
                                        </ListItemIcon>
                                        <ListItemText primary={'Offer'} />
                                    </ListItemButton>
                                    <Divider />
                                    <ListItemButton onClick={() => handleOpenPanel('Terms')}>
                                        <ListItemIcon>
                                            <i
                                                className={clsx(
                                                    "fa",
                                                    { "fa-check-circle stepbar": asterix.terms === "fulfill" },
                                                    { "fa-circle-o": asterix.terms === "empty" || !asterix.terms },
                                                    { "fa-circle": asterix.terms === "notFulfill" }
                                                )}
                                            ></i>
                                        </ListItemIcon>
                                        <ListItemText primary={'Terms'} />
                                    </ListItemButton>
                                    <Divider />
                                    <ListItemButton onClick={() => handleOpenPanel('Exclusions')}>
                                        <ListItemIcon>
                                            <i
                                                className={clsx(
                                                    "fa",
                                                    { "fa-check-circle stepbar": asterix.exclusions === "fulfill" },
                                                    { "fa-circle-o": asterix.exclusions === "empty" || !asterix.exclusions },
                                                    { "fa-circle": asterix.exclusions === "notFulfill" }
                                                )}
                                            ></i>
                                        </ListItemIcon>
                                        <ListItemText primary={'Exclusions'} />
                                    </ListItemButton>
                                    <Divider />
                                    <Tooltip title={`${menuType === "invitation" ? "Not Accessible " : ""}`} arrow placement='right' slotProps={{ popper: { sx: customTooltipLarge }, }} >
                                        <span>
                                            <ListItemButton onClick={() => handleOpenPanel('Co-Broker & Commission')}
                                                disabled={menuType === "invitation"}
                                                style={menuType === "invitation" ? { color: "red", opacity: 1 } : {}}>
                                                <ListItemIcon>
                                                    <i
                                                        className={clsx(
                                                            "fa",
                                                            { "fa-check-circle stepbar": asterix.co_broker === "fulfill" },
                                                            { "fa-circle-o": asterix.co_broker === "empty" || !asterix.co_broker },
                                                            { "fa-circle": asterix.co_broker === "notFulfill" }
                                                        )}
                                                    ></i>
                                                </ListItemIcon>
                                                <ListItemText primary={'Co-Broker & Commission'} />
                                            </ListItemButton>
                                        </span>
                                    </Tooltip>
                                    {/* <Divider textAlign="center" className='my-3 border-5' >
                                        <Typography variant="button" gutterBottom >Form Data</Typography>
                                    </Divider> */}
                                    <Divider sx={{ borderBottomWidth: 6 }} className='my-2' />
                                </Collapse>
                                {/* {!open['formData'] && <Divider />} */}
                            </div>

                            {/* Form Data Button for Trade-In Vessel */}
                            {/* {tradeInVesselDocument &&
                                <Fragment>
                                    <ListItemButton onClick={() => handleOpenPanel('Trade-In Vessel')}>
                                        <ListItemIcon>
                                            <i
                                                className={clsx(
                                                    "fa",
                                                    { "fa-check-circle stepbar": asterix.tradeInVessel === "fulfill" },
                                                    { "fa-circle-o": asterix.tradeInVessel === "empty" || !asterix.tradeInVessel },
                                                    { "fa-circle": asterix.tradeInVessel === "notFulfill" }
                                                )}
                                            ></i>
                                        </ListItemIcon>
                                        <ListItemText primary={'Trade-In Vessel'} />
                                    </ListItemButton>
                                    <Divider />
                                </Fragment>
                            } */}

                            {/* Form Data Button for Survey Waiver */}
                            {surveyWaiverDocument &&
                                <Fragment>
                                    <ListItemButton onClick={() => setSurveyWaiverFormShow(true)}>
                                        <ListItemIcon>
                                            <i
                                                className={clsx(
                                                    "fa fa-check-circle stepbar",
                                                    // { "fa-check-circle stepbar": asterix.tradeInVessel === "fulfill" },
                                                    // { "fa-circle-o": asterix.tradeInVessel === "empty" || !asterix.tradeInVessel },
                                                    // { "fa-circle": asterix.tradeInVessel === "notFulfill" }
                                                )}
                                            ></i>
                                        </ListItemIcon>
                                        <ListItemText primary={'Survey Waiver'} />
                                    </ListItemButton>
                                    <Divider />
                                </Fragment>
                            }

                            {/* Form Data Button for Seller Closing Statement */}
                            {sellerClosingDcument &&
                                <Fragment>
                                    <ListItemButton onClick={() => handleOpenPanel('Seller Closing')}>
                                        <ListItemIcon>
                                            <i
                                                className={clsx(
                                                    "fa",
                                                    { "fa-check-circle stepbar": asterix.sellerClosingStatement === "fulfill" },
                                                    { "fa-circle-o": asterix.sellerClosingStatement === "empty" || !asterix.sellerClosingStatement },
                                                    { "fa-circle": asterix.sellerClosingStatement === "notFulfill" }
                                                )}
                                            ></i>
                                        </ListItemIcon>
                                        <ListItemText primary={'Seller Closing'} />
                                    </ListItemButton>
                                    <Divider />
                                </Fragment>
                            }

                            {/* Form Data Button for Buyer Closing Statement */}
                            {buyerClosingDcument &&
                                <Fragment>
                                    <ListItemButton onClick={() => handleOpenPanel('Buyer Closing')}>
                                        <ListItemIcon>
                                            <i
                                                className={clsx(
                                                    "fa",
                                                    { "fa-check-circle stepbar": asterix.buyerClosingStatement === "fulfill" },
                                                    { "fa-circle-o": asterix.buyerClosingStatement === "empty" || !asterix.buyerClosingStatement },
                                                    { "fa-circle": asterix.buyerClosingStatement === "notFulfill" }
                                                )}
                                            ></i>
                                        </ListItemIcon>
                                        <ListItemText primary={'Buyer Closing'} />
                                    </ListItemButton>
                                    <Divider />
                                </Fragment>
                            }

                            {/* Form Data Button for IYBA Purchase & Sale Addendum */}
                            {addendumDocuments.length > 0 &&
                                <div className="my-1">
                                    <ListItemButton onClick={() => handleClick('addendum')} sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }}>
                                        <ListItemText primary="IYBA Purchase & Sale Addendum" />
                                        {open['addendum'] ? <i class="fa fa-chevron-up"></i> : <i class="fa fa-chevron-down"></i>}
                                    </ListItemButton>
                                    <Collapse in={open['addendum']} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {addendumDocuments.map((addendum, ind) => (
                                                <ListItemButton sx={{ pl: 4 }} key={addendum.id} onClick={() => handleOpenPanel('IYBA Purchase & Sale Addendum', addendum.id)} >
                                                    <ListItemIcon>
                                                        <i
                                                            className={clsx(
                                                                "fa",
                                                                { "fa-check-circle stepbar": addendumDataStatus(addendum.id) === "fulfill" },
                                                                { "fa-circle-o": addendumDataStatus(addendum.id) === "empty" || !addendumDataStatus(addendum.id) },
                                                                { "fa-circle": addendumDataStatus(addendum.id) === "notFulfill" }
                                                            )}
                                                        ></i>
                                                    </ListItemIcon>
                                                    <Tooltip title={`${addendum.title} ${ind > 0 ? '#' + ind : ''}`} placement='right'>
                                                        <ListItemText primary={`Form ${ind > 0 ? '#' + ind : ''}`} />
                                                    </Tooltip>
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Collapse>
                                    <Divider />
                                </div>
                            }

                            {/* Form Data Button for IYBA Purchase & Sale Agreement Exclusion */}
                            {exclusionDocuments.length > 0 &&
                                <div className="my-1">
                                    <ListItemButton onClick={() => handleClick('exclusion')} sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }}>
                                        <ListItemText primary="IYBA Purchase & Sale Agreement Exclusion" />
                                        {open['exclusion'] ? <i class="fa fa-chevron-up"></i> : <i class="fa fa-chevron-down"></i>}
                                    </ListItemButton>
                                    <Collapse in={open['exclusion']} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {exclusionDocuments.map((exclusion, ind) => (
                                                <ListItemButton sx={{ pl: 4 }} key={exclusion.id} onClick={() => handleOpenPanel('IYBA Purchase & Sale Agreement Exclusion', exclusion.id)}>
                                                    <ListItemIcon>
                                                        <i
                                                            className={clsx(
                                                                "fa",
                                                                { "fa-check-circle stepbar": exclusionDataStatus(exclusion.id) === "fulfill" },
                                                                { "fa-circle-o": exclusionDataStatus(exclusion.id) === "empty" || !exclusionDataStatus(exclusion.id) },
                                                                { "fa-circle": exclusionDataStatus(exclusion.id) === "notFulfill" }
                                                            )}
                                                        ></i>
                                                    </ListItemIcon>
                                                    <Tooltip title={`${exclusion.title} ${ind > 0 ? '#' + ind : ''}`} placement='right'>
                                                        <ListItemText primary={`Form ${ind > 0 ? '#' + ind : ''}`} />
                                                    </Tooltip>
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Collapse>
                                    <Divider />
                                </div>
                            }

                            {/* Form Data Button for Acceptance of Vessel */}
                            {acceptanceOfVesselDocuments.length > 0 &&
                                <div className="my-1">
                                    <ListItemButton onClick={() => handleClick('acceptance')} sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }}>
                                        <ListItemText primary="Acceptance of Vessel" />
                                        {open['acceptance'] ? <i class="fa fa-chevron-up"></i> : <i class="fa fa-chevron-down"></i>}
                                    </ListItemButton>
                                    <Collapse in={open['acceptance']} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {acceptanceOfVesselDocuments.map((acceptance, ind) => (
                                                <ListItemButton sx={{ pl: 4 }} key={acceptance.id} onClick={() => handleOpenPanel('IYBA Acceptance of Vessel', acceptance.id)}>
                                                    <ListItemIcon>
                                                        <i
                                                            className={clsx(
                                                                "fa",
                                                                { "fa-check-circle stepbar": acceptanceOfVesselDataStatus(acceptance.id) === "fulfill" },
                                                                { "fa-circle-o": acceptanceOfVesselDataStatus(acceptance.id) === "empty" || !acceptanceOfVesselDataStatus(acceptance.id) },
                                                                { "fa-circle": acceptanceOfVesselDataStatus(acceptance.id) === "notFulfill" }
                                                            )}
                                                        ></i>
                                                    </ListItemIcon>
                                                    <Tooltip title={`${acceptance && acceptance.status
                                                        ? titles[acceptance.duplicate_occurrence]
                                                        : acceptance?.is_duplicate ? acceptance?.duplicate_title : acceptance?.t_esign_template?.template?.template_name}`} placement='right'>
                                                        <ListItemText primary={`Form ${ind > 0 ? '#' + ind : ''}`} />
                                                    </Tooltip>
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Collapse>
                                    <Divider />
                                </div>
                            }

                        </List>
                    </Box>
                </div>
            </div>
            <FormDataDrawer show={show} onClose={onClosePanel} formType={selectedForm} multiFormId={multiFormId} menuType={menuType} />
            <SurveyWaiverFormDataModal show={surveyWaiverFormShow} onClose={(isRefresh = false) => { setSurveyWaiverFormShow(false); if (isRefresh && reloadPdf != null) { reloadPdf(true); } }} vesselId={dataTransaction?.t_vessel?.id} />
        </>
    )
}

export default FormDataSideBar;