import { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import FormPage from "../../../../common/FormPage";
import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import AsterixTypo from "../../../../common/AsterixTypo";
import SubmitButton from "../../../../common/buttons/SubmitButton";
import Column from "../../../../common/Column";
import {
  upsertTransaction,
  eSignCoBroker,
  getTransactionInitialById,
  updateRequiredFields,
} from "../../../../../redux/actions/transaction";
import { resetEdoc } from "../../../../../redux/actions/transaction";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import { getPlainTransactionData } from "../../../../../utils/stepFilter";
import { getFigure, getPrice } from "../../../../../utils/currency";
import { makeStyles } from "@mui/styles";
import PhoneInput from "react-phone-input-2";
import Auth from "../../../../../utils/auth";
import TransactionService from "../../../../../services/TransactionService";
import UserService from "../../../../../services/UserService";
import clsx from "clsx";
import CompaniesService from "../../../../../services/CompanyService";
import { testCompanyId } from "../../../../../utils/AppConstants";

const schema = yup.object().shape({
  gross_commission_amount: yup.string().required(),
  user_from_company: yup.boolean().nullable(true),
  is_selling_broker_from_other_company: yup.boolean(false).nullable(true),
  is_listing_broker_from_other_company: yup.boolean(false).nullable(true),
});

const useStyles = makeStyles({
  divider: {
    marginBottom: "15px",
  },
  is_broker_div: {
    width: "31% !important",
  },
  show_broker_label: {
    marginRight: "9px !important",
  },
  phone_input: {
    padding: '12px 12px 12px 50px !important',
    width: '100% !important'
  },
  phoneInputDiv: {
    padding: "8px",
    paddingTop: "0px",
    marginLeft: "-7px",
    marginRight: "7px"
  }
});

const AddCoBroker = ({ step, setStep }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const logged_user = Auth.getInstance().getUserData();
  // const [selectFromMyCompany, setSelectFromMyCompany] = useState(false);
  const [ebSignUsers, setEbSignUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const [commission, setCommission] = useState("");

  const [brokerType, setBrokerType] = useState("selling");

  // const [isLoggedUserNoBroker, setIsLoggedUserNoBroker] = useState(false);
  const isLoggedUserNoBroker = (logged_user.UserInRole?.UserRole?.name == 'Company Admin' && logged_user.is_broker == false);
  const [brokerList, setBrokerList] = useState([]);
  const [isVerified, setIsVerified] = useState(false);

  const [companies, setCompanies] = useState([]);
  const [selectedCompanyForBroker, setSelectedCompanyForBroker] = useState(null);
  const [brokerListOfCompany, setBrokerListOfCompany] = useState([]);
  const [selectedBrokerFromCompany, setSelectedBrokerFromCompany] = useState(null);

  const [selectedOtherBrokerType, setSelectedOtherBrokerType] = useState('manual');

  const required = useSelector((state) => state.transactions.requiredFields);
  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);
  const dataDeal = dataTransaction || {};
  const dataCobroker = dataDeal && dataDeal?.co_broker || null;
  const transactionBroker = dataDeal?.broker || null;
  const transactionEmpBroker = dataDeal?.employing_broker || null;
  const eDoc = dataDeal && dataDeal.t_edocuments
    ? dataDeal.t_edocuments.find(doc => doc.task_type === "co-broker-commission-agreement")
    : null;
  const signers = eDoc && eDoc.request_signature && eDoc.request_signature.signers ? eDoc.request_signature.signers : null;
  const purchase_price = dataDeal.t_offers ? dataDeal.t_offers.total_purchase_price : "";

  const keys = [
    "broker_type",
    "user_from_company",
    "gross_commission_percent",
    "gross_commission_amount",
    "gross_commission_type",
    "listing_brokerage",
    "listing_broker_email",
    "listing_broker_phone_number",
    "listing_brokerage_broker",
    "listing_brokerage_percent",
    "listing_brokerage_amount",
    "is_listing_broker_from_other_company",
    "selling_brokerage",
    "selling_brokerage_broker",
    "selling_brokerage_percent",
    "selling_brokerage_amount",
    "selling_broker_email",
    "selling_broker_phone_number",
    "is_selling_broker_from_other_company",
    "additional_terms_comments",
    "eb_user",
    "previous_company_id",
    "previous_company_broker_id",
  ];

  const amountKeys = [
    "gross_commission_amount",
    "listing_brokerage_amount",
    "selling_brokerage_amount",
  ];

  const phoneKeys = [
    "listing_broker_phone_number",
    "selling_broker_phone_number"
  ];

  const emailKeys = [
    "listing_broker_email",
    "selling_broker_email",
  ];

  const typeKeys = [
    "broker_type"
  ];

  const selling_broker_keys = [
    "selling_brokerage",
    "selling_brokerage_broker",
    "selling_brokerage_percent",
    "selling_brokerage_amount",
    "selling_broker_email",
    "selling_broker_phone_number",
  ];

  const listing_broker_keys = [
    "listing_brokerage",
    "listing_broker_email",
    "listing_broker_phone_number",
    "listing_brokerage_broker",
    "listing_brokerage_percent",
    "listing_brokerage_amount",
  ];


  useEffect(() => {
    if (isLoggedUserNoBroker) {
      getCompanyBrokerList();
    }
    getCompaniesList();
  }, []);

  /**
   * Get All Broker List of Selected Company
  */
  const getCompanyBrokerList = async () => {
    try {
      const companyId = logged_user.company_id;
      dispatch(setLoadingStatus(true));
      if (companyId) {
        const brokers = await getCompanyBrokers(companyId);
        setBrokerList(brokers.companyBrokers);
        if (dataCobroker) {
          const typeSelected = dataCobroker.broker_type == 'both' ? 'selling' : dataCobroker.broker_type;
          const selectedBroker = brokers.companyBrokers?.find(broker => { return dataCobroker[`${typeSelected}_brokerage_broker`] == `${broker.first_name} ${broker.last_name}` });
          if (selectedBroker && selectedBroker.id) {
            setValue(`${typeSelected}_brokerage_id`, selectedBroker.id);
          }
        }
      }
      dispatch(setLoadingStatus(false));
    } catch (error) {
      dispatch(setLoadingStatus(false));
      console.log(error);
    }
  }

  /**
   * Get list of all brokers assoticated from a company
   * @param {*} companyId 
   * @returns Broker list from the API
   */
  const getCompanyBrokers = async (companyId) => {
    return (await UserService.getBrokerList(companyId)).data || {};
  }

  /**
   * Get the list of all companies to be displayed for selection
   */
  const getCompaniesList = async () => {
    try {
      dispatch(setLoadingStatus(true));
      const response = await CompaniesService.getAll();
      // filter the companies list by discarding the test company on production server comparing with company_id
      setCompanies(response.data.filter(company => company.id !== testCompanyId));
      if (dataCobroker && dataCobroker.previous_company_id && dataCobroker.previous_company_broker_id) {
        setValue('previous_company_id', dataCobroker.previous_company_broker_id);
        setSelectedCompanyForBroker(dataCobroker.previous_company_id);
        onSelectCompanyGetBrokers(dataCobroker.previous_company_id)
      }
      dispatch(setLoadingStatus(false));
    } catch (error) {
      dispatch(setLoadingStatus(false));
      console.log(error);
    }
  }

  /**
   * On Select the company from list and call API for get list of brokers for company
   * @param {*} event 
   */
  const onSelectCompanyGetBrokers = async (event) => {
    try {
      const companyId = event;
      if (companyId) {
        setValue('previous_company_id', companyId);
        setSelectedCompanyForBroker(companyId);
        dispatch(setLoadingStatus(true));
        const brokers = await getCompanyBrokers(companyId);
        setBrokerListOfCompany(brokers.companyBrokers);
        if (dataCobroker && dataCobroker.previous_company_id && dataCobroker.previous_company_broker_id) {
          setValue('previous_company_broker_id', dataCobroker.previous_company_broker_id);
          setSelectedBrokerFromCompany(dataCobroker.previous_company_broker_id);
        }
        dispatch(setLoadingStatus(false));
      } else {
        setValue('previous_company_id', null);
        setSelectedCompanyForBroker(null);
        setBrokerListOfCompany([]);
        getBrokerFromId(null);
      }
    } catch (error) {
      console.log(error);
      dispatch(setLoadingStatus(false));
    }
  }
  const { register, handleSubmit, setValue, getValues, watch, formState: { isDirty, errors } } = useForm({
    mode: "all",
    defaultValues: {
      user_from_company: false
    },
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });


  const onChangeBrokerValues = (field) => {
    const gca = getFigure(getValues("gross_commission_amount"));

    if (field.includes('percent')) {
      const percentageValue = parseInt(getValues(field) || 0);
      if (gca && percentageValue !== NaN) {
        switch (field) {
          case 'account_owner_percent':
            {
              let lbp = (100 - percentageValue) || 0;
              let sba = parseFloat((gca * percentageValue) / 100).toFixed(2);
              let lba = gca - sba;

              setValue("other_broker_percent", lbp);
              setValue("account_owner_amount", getPrice(sba));
              setValue("other_broker_amount", getPrice(lba));
            }
            break;

          case 'other_broker_percent':
            {
              let sbp = (100 - percentageValue) || 0;
              let lba = parseFloat((gca * percentageValue) / 100).toFixed(2);
              let sba = gca - lba;
              setValue("account_owner_percent", sbp);
              setValue("account_owner_amount", getPrice(sba));
              setValue("other_broker_amount", getPrice(lba));
            }
            break;
          default:
            return false;
        }
      }
    } else {
      const amountValue = getFigure(getValues(field) || 0);
      if (gca && amountValue !== NaN) {
        switch (field) {
          case 'account_owner_amount':
            {
              let lba = gca - amountValue;
              let sbp = parseFloat((amountValue / gca) * 100).toFixed(2);
              let lbp = (100 - sbp).toFixed(2);

              setValue("account_owner_percent", sbp);
              setValue("other_broker_percent", lbp);
              setValue("other_broker_amount", getPrice(lba));
            }
            break;

          case 'other_broker_amount':
            {
              let sba = gca - amountValue;
              let lbp = parseInt((amountValue / gca) * 100).toFixed(2);
              let sbp = (100 - lbp).toFixed(2);

              setValue("account_owner_percent", sbp);
              setValue("other_broker_percent", lbp);
              setValue("account_owner_amount", getPrice(sba));
            }
            break;
          default:
            return false;
        }
      }
    }
  }

  /**
   * set the form fields as per the data getting from API
   * @param {*} fieldName 
   * @param {*} valueFieldName 
   */
  const setOwnerAndOtherBroker = (fieldName, valueFieldName) => {
    setValue(`${fieldName}_brokerage`, dataCobroker[`${valueFieldName}_brokerage`] || null);
    setValue(`${fieldName}_brokerage_broker`, dataCobroker[`${valueFieldName}_brokerage_broker`] || null);
    setValue(`${fieldName}_email`, dataCobroker[`${valueFieldName}_broker_email`] || null);
    setValue(`${fieldName}_phone_number`, (dataCobroker[`${valueFieldName}_broker_phone_number`]));
    setValue(`${fieldName}_percent`, dataCobroker[`${valueFieldName}_brokerage_percent`]);
    setValue(`${fieldName}_amount`, (getPrice(dataCobroker[`${valueFieldName}_brokerage_amount`]) || null));
    setSelectedUser(() => dataCobroker.previous_company_broker_id || null)
    if (fieldName === "other_broker") {
      checkEmail(dataCobroker[`${valueFieldName}_broker_email`] || null);
    }
  }

  useEffect(() => {
    if (dataCobroker) {
      setValue('broker_type', dataCobroker['broker_type'] || "selling");
      setBrokerType(dataCobroker['broker_type'] || "selling");

      setOwnerAndOtherBroker('account_owner', dataCobroker['broker_type'] == 'both' ? 'selling' : dataCobroker['broker_type']);
      setOwnerAndOtherBroker('other_broker', ['selling', 'both'].includes(dataCobroker['broker_type']) ? 'listing' : 'selling');

      keys.forEach((key) => {
        if (amountKeys.indexOf(key) > -1) {
          setValue(key, dataCobroker[key] ? getPrice(dataCobroker[key]) : null);
        } else if (phoneKeys.indexOf(key) > -1) {
          setValue(key, dataCobroker[key] ? dataCobroker[key] : "+1");
        } else if (typeKeys.indexOf(key) > -1) {
          setValue(key, dataCobroker[key] || "selling");
          setBrokerType(dataCobroker[key] || "selling");
        } if (key == "eb_user" && dataCobroker["eb_user"]) {
          setValue("eb_user", dataCobroker["eb_user"]);
          setSelectedUser(dataCobroker[key] ? dataCobroker[key] : null);
        } else {
          setValue(key, dataCobroker[key] || null);
        }
      });

      setValue('is_broker_from_other_company', dataCobroker['is_listing_broker_from_other_company'] || dataCobroker['is_selling_broker_from_other_company']);


      setSelectedOtherBrokerType((dataCobroker['is_listing_broker_from_other_company'] || dataCobroker['is_selling_broker_from_other_company'])
        ? 'is_broker_from_other_company'
        : dataBroker.user_from_company
          ? 'user_from_company'
          : 'manual');

      setCommission(dataCobroker['gross_commission_type']);
      handleBlur("gross_commission_amount");
      if (!dataCobroker.selling_brokerage_percent) {
        setValue("account_owner_percent", 50);
      }
      if (!getValues('gross_commission_percent')) {
        const grossCommision = transactionEmpBroker ?
          transactionEmpBroker?.company?.brokerage?.default_co_brokerage :
          transactionBroker?.company?.brokerage?.default_co_brokerage || null;
        setValue('gross_commission_percent', grossCommision);
      }
    } else {
      keys.forEach((key) => {
        if (typeKeys.includes(key)) {
          setValue("broker_type", "selling");
          return;
        }
        if (key == "gross_commission_percent") {
          const grossCommision = transactionEmpBroker ?
            transactionEmpBroker?.company?.brokerage?.default_co_brokerage :
            transactionBroker?.company?.brokerage?.default_co_brokerage || null;
          setValue(key, grossCommision);
          return;
        }
        setValue(key, null);
      });
      setCommission('percentage')
      setValue("listing_broker_phone_number", "+1");
      if (!isLoggedUserNoBroker && dataBroker) {
        setValue("selling_brokerage", dataBroker.company.company_name);
        setValue("selling_brokerage_broker", dataBroker.first_name + ' ' + dataBroker.last_name);
        setValue("selling_broker_email", dataBroker.email);
        setValue("selling_broker_phone_number", dataBroker.mobile_phone ? dataBroker.mobile_phone?.substring(dataBroker.mobile_phone.length - 11) : "+1");

        setValue("account_owner_brokerage", dataBroker.company?.company_name);
        setValue("account_owner_brokerage_broker", dataBroker.first_name + ' ' + dataBroker.last_name);
        setValue("account_owner_email", dataBroker.email);
        setValue("account_owner_phone_number", dataBroker.mobile_phone ? dataBroker.mobile_phone?.substring(dataBroker.mobile_phone.length - 11) : "+1");
      }
    }
  }, [dataCobroker]);

  // useEffect(() => {
  //   setDefaltBrokerValue();
  // }, [watch("broker_type"), brokerType]);

  /**
   * On change Grocess Commission Percentage value
   * Set values for Selling/Listing broker amount and percentage
   * Set value for Gross Commission Amount after calculation
  */
  useEffect(() => {
    if (commission === "percentage") {
      if (getValues("gross_commission_percent") && purchase_price) {
        setValue(
          "gross_commission_amount",
          getPrice(
            ((purchase_price * parseFloat(getValues("gross_commission_percent"))) / 100).toFixed(2)
          )
        );
        setTimeout(() => {
          let gca = getFigure(getValues("gross_commission_amount"));
          let sbp = parseInt(getValues("account_owner_percent"));
          if (gca && sbp && sbp != NaN) {
            let lbp = 100 - sbp;
            let sba = parseFloat((gca * sbp) / 100).toFixed(2) || 0;
            let lba = gca - sba;
            setValue("account_owner_amount", getPrice(sba));
            setValue("other_broker_percent", lbp);
            setValue("other_broker_amount", getPrice(lba));
          }
        })
      }
    }
  }, [watch("gross_commission_percent")]);

  /**
   * On change Grocess Commission Amount value
   * Set values for Selling/Listing broker amount and percentage
   * Set value for Gross Commission Percentage after calculation
  */
  useEffect(() => {
    if (commission !== "percentage") {
      if (getValues("gross_commission_amount") && purchase_price) {
        setValue(
          "gross_commission_percent",
          (
            (parseFloat(getFigure(getValues("gross_commission_amount"))) / purchase_price) *
            100
          ).toFixed(2)
        );
        if (!dataCobroker?.selling_brokerage_percent) {
          setValue("account_owner_percent", 50);
        }

        setTimeout(() => {
          let gca = getFigure(getValues("gross_commission_amount"));
          let sbp = parseInt(getValues("account_owner_percent"));
          if (gca && sbp !== NaN) {
            let lbp = 100 - sbp;
            let sba = parseFloat((gca * sbp) / 100).toFixed(2);
            let lba = gca - sba;
            setValue("account_owner_amount", getPrice(sba));
            setValue("other_broker_percent", lbp);
            setValue("other_broker_amount", getPrice(lba));
          }
        })
      }
    }
  }, [watch("gross_commission_amount")]);


  useEffect(() => {
    if (getValues("user_from_company")) {
      dispatch(setLoadingStatus(true));
      TransactionService.getEbUsers(logged_user.company_id, logged_user.UserInRole?.UserRole?.name == 'Agent')
        .then(({ data }) => {
          dispatch(setLoadingStatus(false));
          setEbSignUsers(data);
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          console.log(err);
          setEbSignUsers([]);
        })
    } else {
      setValue("eb_user", null)
    }
  }, [watch('user_from_company')]);

  useEffect(() => {
    const brokerId = getValues("eb_user");
    if (brokerId) {
      getBrokerData(brokerId);
      return;
    }
    setValue("listing_broker_phone_number", "+1");
  }, [watch('eb_user')]);

  useEffect(() => {
    let coBrokerRequired = [];
    const sellingCoBrokerFields = {
      selling_broker_email: "Selling Broker Email",
      selling_brokerage_broker: "Selling Broker Name",
      selling_broker_phone_number: "Selling Broker Phone Number",
    }
    const listingCoBrokerFields = {
      listing_broker_email: "Listing Broker Email",
      listing_brokerage_broker: "Listing Broker Name",
      listing_broker_phone_number: "Listing Broker Phone Number",
    }

    if (["selling", "both"].includes(dataCobroker?.broker_type)) {
      Object.keys(sellingCoBrokerFields).forEach(key => {
        if (!dataCobroker[key]) {
          coBrokerRequired.push({ [key]: sellingCoBrokerFields[key] });
        }
      });
    }

    if (["listing", "both"].includes(dataCobroker?.broker_type)) {
      Object.keys(listingCoBrokerFields).forEach(key => {
        if (!dataCobroker[key]) {
          coBrokerRequired.push({ [key]: listingCoBrokerFields[key] });
        }
      });
    }

    if (!dataCobroker?.selling_brokerage_percent && !dataCobroker?.listing_brokerage_percent) {
      if (getValues("broker_type") == 'both') {
        setValue('account_owner_percent', 100);
        onChangeBrokerValues('account_owner_percent', 100);
      } else {
        setValue('account_owner_percent', 50);
        setValue('other_broker_percent', 50);
        onChangeBrokerValues('account_owner_percent', 50);
      }
    } else {
      if (['selling', 'listing'].includes(dataCobroker.broker_type) && getValues("broker_type") == 'both') {
        setValue('account_owner_percent', 100);
        onChangeBrokerValues('account_owner_percent', 100);
      } else if (dataCobroker.broker_type == 'both' && ['selling', 'listing'].includes(getValues("broker_type"))) {
        setValue('account_owner_percent', 50);
        setValue('other_broker_percent', 50);
        onChangeBrokerValues('account_owner_percent', 50);
      } else {
        setValue('account_owner_percent', dataCobroker["broker_type"] == 'selling' ? dataCobroker.selling_brokerage_percent : dataCobroker.listing_brokerage_percent);
        setValue('other_broker_percent', dataCobroker["broker_type"] == 'selling' ? dataCobroker.listing_brokerage_percent : dataCobroker.selling_brokerage_percent);
        onChangeBrokerValues('account_owner_percent', dataCobroker["broker_type"] == 'selling' ? dataCobroker.selling_brokerage_percent : dataCobroker.listing_brokerage_percent);
      }
    }

    dispatch(updateRequiredFields(
      {
        co_broker: coBrokerRequired,
      }
    ));

  }, [dataCobroker, getValues("broker_type")])

  const updateTransaction = (cobroker) => {
    Object.keys(cobroker).forEach((key) => {
      if (!cobroker[key]) {
        cobroker[key] = null;
      }
      if (amountKeys.indexOf(key) > -1) {
        cobroker[key] = getFigure(cobroker[key]);
      }
      if (phoneKeys.indexOf(key) > -1) {
        cobroker[key] = cobroker[key];
      }
      if (emailKeys.indexOf(key) > -1) {
        cobroker[key] = cobroker[key] ? cobroker[key].trim() : null;
      }
    });

    if (isLoggedUserNoBroker) {
      cobroker['isBrokerSelected'] = true;
      cobroker['selected_broker_user_id'] = cobroker['selling_brokerage_id'] || cobroker['listing_brokerage_id'];
    }

    if (selectedCompanyForBroker && selectedBrokerFromCompany) {
      if (getValues('is_broker_from_other_company')) {
        cobroker['is_selling_broker_from_other_company'] = !['selling', 'both'].includes(brokerType);
        cobroker['is_listing_broker_from_other_company'] = brokerType != 'listing';
        cobroker['user_from_company'] = false;
      } else {
        cobroker['is_selling_broker_from_other_company'] = false;
        cobroker['is_listing_broker_from_other_company'] = false;
      }
      cobroker['company_id'] = logged_user.company_id;
      cobroker['transaction_id'] = dataDeal.id;
      cobroker['user_type'] = 'broker';
    } else {
      cobroker['is_selling_broker_from_other_company'] = false;
      cobroker['is_listing_broker_from_other_company'] = false;
      cobroker['transaction_id'] = dataDeal.id;
      cobroker['user_from_company'] = false;
    }

    if (dataCobroker && dataCobroker.id) {
      cobroker.id = dataCobroker.id;
    }

    return dispatch(upsertTransaction({
      step: {
        db: "cobroker",
        main: cobroker,
      },
      transaction: {
        isBelonged: true,
        foreignkeyName: "co_broker_contact_id",
        main: {
          ...getPlainTransactionData(dataDeal),
          task_type: "deal",
          broker_user_id: dataBroker?.id,
          company_id: dataBroker?.company_id,
          company_office_id: dataBroker?.company_office_id,
        },
      },
    }, "Co-Broker's"));
  }

  /**
 * set the form fields as per the data getting from API
 * @param {*} fieldName 
 * @param {*} valueFieldName 
*/
  const setBrokerFieldsFromFormData = (fieldName, valueFieldName, coBroker) => {
    return {
      [`${fieldName}_brokerage`]: coBroker[`${valueFieldName}_brokerage`] || null,
      [`${fieldName}_brokerage_broker`]: coBroker[`${valueFieldName}_brokerage_broker`] || null,
      [`${fieldName}_broker_email`]: coBroker[`${valueFieldName}_email`] || null,
      [`${fieldName}_broker_phone_number`]: (coBroker[`${valueFieldName}_phone_number`]),
      [`${fieldName}_brokerage_percent`]: coBroker[`${valueFieldName}_percent`],
      [`${fieldName}_brokerage_amount`]: (getPrice(coBroker[`${valueFieldName}_amount`]) || null),
    }
  }


  const handleRegistration = (cobroker) => {
    cobroker = {
      ...cobroker,
      ...setBrokerFieldsFromFormData('selling', ['selling', 'both'].includes(brokerType) ? 'account_owner' : 'other_broker', cobroker),
      ...setBrokerFieldsFromFormData('listing', ['listing', 'both'].includes(brokerType) ? 'account_owner' : 'other_broker', cobroker)
    }

    cobroker.user_from_company = from_my_office_checked || false;
    cobroker['gross_commission_type'] = commission;
    dispatch(setLoadingStatus(true));
    updateTransaction(cobroker).then(data => {
      dispatch(getTransactionInitialById(data.id)).then(data => {
        dispatch(setLoadingStatus(false));
      }).catch((error) => {
        dispatch(setLoadingStatus(false));
        console.log(error);
      });
    }).catch((error) => {
      dispatch(setLoadingStatus(false));
      console.log(error);
    });
  };


  const handleError = (error) => {
    console.log(error);
  };

  const handleBlur = (ref) => {
    const price = getPrice(getValues(ref));
    setValue(ref, price);
  };

  const handleFocus = (ref) => {
    const figure = getFigure(getValues(ref));
    setValue(ref, figure);
  };

  const onSelectBrokerByNoBroker = (id, brokers = null) => {
    if (id) {
      setSelectedBrokerFromCompany(id);
      const selectedBroker = brokers.find(broker => broker.id == id);
      setValue(`account_owner_brokerage_broker`, `${selectedBroker.first_name} ${selectedBroker.last_name}`);
      setValue(`account_owner_email`, `${selectedBroker.email}`);
      setValue(`account_owner_phone_number`, selectedBroker.mobile_phone ? selectedBroker.mobile_phone?.substring(selectedBroker.mobile_phone.length - 11) : "+1");
      setValue(`account_owner_brokerage`, logged_user.company?.company_name);
    } else {
      setSelectedBrokerFromCompany(null);
      setValue(`account_owner_brokerage_broker`, '');
      setValue(`account_owner_email`, '');
      setValue(`account_owner_phone_number`, "+1");
      setValue(`account_owner_brokerage`, '');
    }
  }

  const getBrokerFromId = (id, brokers = null) => {
    // const typeSelected = brokerType == 'both' ? 'selling' : brokerType;
    if (id) {
      setSelectedBrokerFromCompany(id);
      setValue('previous_company_broker_id', id);
      const selectedBroker = brokers.find(broker => broker.id == id);
      const selectedCompay = companies.find(comp => comp.id == selectedCompanyForBroker);
      setValue(`other_broker_brokerage`, selectedCompay.company_name);
      setValue(`other_broker_brokerage_broker`, `${selectedBroker.first_name} ${selectedBroker.last_name}`);
      setValue(`other_broker_email`, `${selectedBroker.email}`);
      setValue(`other_broker_phone_number`, selectedBroker.mobile_phone ? selectedBroker.mobile_phone?.substring(selectedBroker.mobile_phone.length - 11) : '+1');
      setIsVerified(true); setValue('is_email_exist_on_boatdox', true);
    } else {
      setSelectedBrokerFromCompany(null);
      setValue('previous_company_broker_id', null);
      setValue(`other_broker_brokerage`, '');
      setValue(`other_broker_brokerage_broker`, '');
      setValue(`other_broker_email`, '');
      setValue(`other_broker_phone_number`, '+1');
    }
  }
  useEffect(() => {
    setSelectedCompanyForBroker(null);
    setSelectedBrokerFromCompany(null);
    setValue('previous_company_id', null);
    setValue('previous_company_broker_id', null);
  }, [watch('is_broker_from_other_company')]);

  const checkEmail = (email) => {
    if (email) {
      dispatch(setLoadingStatus(true));
      UserService.checkEmail({
        email: email,
      }).then((res) => {

        dispatch(setLoadingStatus(false));
        //Check whether email is exists or not.
        if (res.data.isExists) {
          setIsVerified(true);
          setValue('is_email_exist_on_boatdox', true)
        } else {
          setIsVerified(false);
          setValue('is_email_exist_on_boatdox', false)
        }
      }).catch(() => {

        dispatch(setLoadingStatus(false));
      })
    }
  }

  const getBrokerData = async (id) => {
    const { data } = await UserService.get(id);
    setValue('previous_company_id', dataBroker.company.id);
    setSelectedCompanyForBroker(dataBroker.company.id);
    setValue('previous_company_broker_id', data.id);
    setSelectedBrokerFromCompany(data.id);

    setValue("other_broker_brokerage", dataBroker.company.company_name);
    setValue("other_broker_brokerage_broker", `${data.first_name} ${data.last_name}`);
    setValue("other_broker_email", data.email);
    setValue("other_broker_phone_number", data?.mobile_phone || "+1");
  }

  /**
    * Render the Company selection
    * @returns HTML content for company selection
  */
  const renderCompanySelection = () => {
    return (
      <FormInputWrapper label="Select Company">
        <select
          className="uk-select"
          value={selectedCompanyForBroker}
          onChange={(e) => onSelectCompanyGetBrokers(e.target.value)} >
          <option value="">Select Company</option>
          {companies.map((company) => (
            <option key={`broker_${company.id}`} value={company.id}>{`${company?.company_name}`}</option>
          ))}
        </select>
      </FormInputWrapper>
    )
  }

  /**
  * Render the Broker selection as per broker selected
  * @param {*} brokerType 
  * @returns 
  */
  const renderBrokerSelectionFromCompany = () => {
    return (
      <FormInputWrapper label="Select Broker">
        <select
          className="uk-select"
          value={selectedBrokerFromCompany}
          onChange={(event) => { getBrokerFromId(event.target.value, brokerListOfCompany); }} >
          <option value="">Select Broker</option>
          {brokerListOfCompany.map((broker) => (
            <option key={`broker_${broker.id}`} value={broker.id}>{`${broker?.first_name} ${broker?.last_name}`}</option>
          ))}
        </select>
      </FormInputWrapper>
    )
  }

  const renderSellingBorker = () => {
    return (
      <>
        <FormRow>
          <Column size="three">
            {/* <h3>Selling Broker</h3> */}
            <h3>Deal Broker</h3>
          </Column>
        </FormRow>
        <FormRow>
          <Column size="two">
            <label className="toys">Are you</label>
            <div className="radio-toolbar">
              <input id='selling' type="radio" value="selling" {...register('broker_type')} onChange={(e) => boatTypeHandler(e)} />
              <label htmlFor='selling' className="uk-form-label radio">Selling Broker</label>
              <input id='listing' type="radio" value="listing" {...register('broker_type')} onChange={(e) => boatTypeHandler(e)} />
              <label htmlFor='listing' className="uk-form-label radio">Listing Broker</label>
              <input id='both' type="radio" value="both" {...register('broker_type')} onChange={(e) => boatTypeHandler(e)} />
              <label htmlFor='both' className="uk-form-label radio">Both</label>
            </div>
          </Column>
        </FormRow>
        <FormRow>
          {/* {(["selling", "both"].includes(brokerType) && from_other_company_selling_checked)
                    ? renderCompanySelection()
                    : <FormInputWrapper label="Brokerage">
                        <input className="uk-input required" type="text" {...register("selling_brokerage")} />
                    </FormInputWrapper>
                } */}
          <FormInputWrapper label="Brokerage">
            <input className="uk-input required" type="text" {...register("account_owner_brokerage")} />
          </FormInputWrapper>
          {/* {!isLoggedUserNoBroker && ["selling", "both"].includes(brokerType) &&
                    <div className={`one-column uk-padding-small ${classes.is_broker_div}`} style={{ marginTop: "20px" }}>
                        <Fragment key={Math.random()}>
                            <input id="is_selling_broker_from_other_company" type="checkbox" name="is_selling_broker_from_other_company" {...register("is_selling_broker_from_other_company")} />
                            <label className={`uk-form-label radio ${classes.show_broker_label}`} htmlFor="is_selling_broker_from_other_company">Select From Other Company</label>
                        </Fragment>
                    </div>
                } */}
          {/* {(["selling"].includes(brokerType) && from_other_company_selling_checked) && selectedCompanyForBroker &&
                    (renderBrokerSelectionFromCompany())
                } */}
        </FormRow>
        <FormRow>
          {/* {isLoggedUserNoBroker && ["selling", "both"].includes(brokerType)
                    ? <FormInputWrapper label="Broker *">
                        <select
                            {...register("selling_brokerage_id")}
                            className="uk-select"
                            onChange={(event) => { getBrokerFromId(event.target.value, brokerList); }} >
                            <option value="">Select Selling Broker</option>
                            {brokerList.map((broker) => (
                                <option key={`broker_${broker.id}`} value={broker.id}>{`${broker?.first_name} ${broker?.last_name}`}</option>
                            ))}
                        </select>
                    </FormInputWrapper>
                    :
                    <FormInputWrapper label="Broker *">
                        <input
                            className="uk-input required"
                            type="text"
                            autoComplete="off"
                            {...register("selling_brokerage_broker")}
                        />
                    </FormInputWrapper>
                } */}
          {isLoggedUserNoBroker
            ? <FormInputWrapper label="Broker *">
              <select
                {...register(`${brokerType}_brokerage_id`)}
                className="uk-select"
                onChange={(event) => { onSelectBrokerByNoBroker(event.target.value, brokerList); }} >
                <option value="">Select Broker</option>
                {brokerList.map((broker) => (
                  <option key={`broker_${broker.id}`} value={broker.id}>{`${broker?.first_name} ${broker?.last_name}`}</option>
                ))}
              </select>
            </FormInputWrapper>
            : <FormInputWrapper label="Broker *">
              <input
                className="uk-input required"
                type="text"
                autoComplete="off"
                {...register("account_owner_brokerage_broker")}
              />
            </FormInputWrapper>
          }
          <FormInputWrapper label="Email *">
            <input autoComplete="false" name="hidden" type="text" className="autocomplete-off" />
            <input
              className="uk-input required"
              type="text"
              autoComplete="off"
              {...register("account_owner_email")}
            />
          </FormInputWrapper>
          <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
            <label className="uk-form-label" htmlFor="name">Mobile Phone*</label>
            <input type="hidden" required name="account_owner_phone_number"  {...register("account_owner_phone_number")} />
            <PhoneInput
              country={'us'}
              placeholder='+1'
              inputClass={classes.phone_input}
              containerClass={classes.phone_container}
              autoFocus={false}
              onFocus={() => { }}
              value={account_owner_mobile_phone}
              onChange={phone => { setValue('account_owner_phone_number', phone, { shouldValidate: true }) }}
            />
          </div>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Deal Broker Percent % *">
            <input
              className="uk-input required"
              type="text"
              {...register('account_owner_percent', {
                onChange: e => {
                  onChangeBrokerValues('account_owner_percent', e.target.value);
                }
              })}
            />
          </FormInputWrapper>
          <FormInputWrapper label="Deal Broker Amount $ *">
            <input
              className="uk-input required"
              type="text"
              {...register('account_owner_amount', {
                onChange: e => {
                  onChangeBrokerValues('account_owner_amount', e.target.value);
                }
              })}
              onBlur={() => handleBlur("account_owner_amount")}
              onFocus={() => handleFocus("account_owner_amount")}
            />
          </FormInputWrapper>
        </FormRow>
      </>
    );
  };

  const renderListingBroker = () => {
    return (
      <>
        <FormRow>
          <Column size="three">
            {/* <h3>Listing Broker</h3> */}
            <h3>Other Side's Broker</h3>
          </Column>
        </FormRow>
        <FormRow>
          <div className="radio-toolbar pb-2">
            <input id='manual' type="radio" value="manual" checked={selectedOtherBrokerType == 'manual'} onChange={(e) => { setValue('is_broker_from_other_company', false); setValue('user_from_company', false); setSelectedOtherBrokerType(e.target.value); }} />
            <label htmlFor='manual' className="uk-form-label radio">Manual Entry</label>
            <input id='user_from_company' type="radio" value="user_from_company" checked={selectedOtherBrokerType == 'user_from_company'} onChange={(e) => { setValue('is_broker_from_other_company', false); setValue('user_from_company', true); setSelectedOtherBrokerType(e.target.value); }} />
            <label htmlFor='user_from_company' className="uk-form-label radio">Select from My Company</label>
            <input id='is_broker_from_other_company' type="radio" value="is_broker_from_other_company" checked={selectedOtherBrokerType == 'is_broker_from_other_company'} onChange={(e) => { setValue('user_from_company', false); setValue('is_broker_from_other_company', true); setSelectedOtherBrokerType(e.target.value); }} />
            <label htmlFor='is_broker_from_other_company' className="uk-form-label radio">Select From Other Company</label>
          </div>
        </FormRow>
        <FormRow>
          {from_other_company_checked
            ? renderCompanySelection()
            : <FormInputWrapper label="Brokerage">
              <input className="uk-input required" type="text" {...register("other_broker_brokerage")} />
            </FormInputWrapper>
          }
          {/* <div className={`one-column uk-padding-small d-flex flex-column`} style={{ marginTop: "20px", }}>
            <Fragment key={Math.random()}>
              <div>
                <input id="user_from_company" type="checkbox" name="user_from_company" {...register("user_from_company")} onChange={() => { setValue('is_broker_from_other_company', false); setValue('user_from_company', !getValues('user_from_company')); }} />
                <label className={`uk-form-label m-0  radio ${classes.show_broker_label}`} htmlFor="user_from_company">Select from My Company</label>
              </div>
              <div className='mt-2'>
                <input id="is_broker_from_other_company" type="checkbox" name="is_broker_from_other_company" {...register("is_broker_from_other_company")} onChange={() => { setValue('user_from_company', false); setValue('is_broker_from_other_company', !getValues('is_broker_from_other_company')); }} />
                <label className={`uk-form-label radio m-0 ${classes.show_broker_label}`} htmlFor="is_broker_from_other_company">Select From Other Company</label>
              </div>
            </Fragment>
          </div> */}
          {from_my_office_checked && ebSignUsers && ebSignUsers.length > 0 &&
            <FormInputWrapper label="Select Broker *">
              <select
                value={selectedUser}
                className="uk-select"
                {...register('eb_user')}
                onChange={(event) => {
                  setValue("eb_user", event.target.value);
                  setSelectedUser(event.target.value);
                  setValue('is_email_exist_on_boatdox', true); setIsVerified(true)
                }}>
                <option key={`eb_user_default`} value=""></option>
                {ebSignUsers.map((user, idx) => (
                  <option key={`eb_user_${idx}`} value={user.id}>{`${user?.first_name} ${user?.last_name}`}</option>
                ))}
              </select>
            </FormInputWrapper>
          }
          {(from_other_company_checked) && selectedCompanyForBroker &&
            (renderBrokerSelectionFromCompany())
          }
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Broker *">
            <input
              className="uk-input required"
              type="text"
              autoComplete="off"
              readOnly={from_my_office_checked ? true : false}
              {...register("other_broker_brokerage_broker")}
            />
          </FormInputWrapper>
          <FormInputWrapper label="Email *">
            <input
              className="uk-input required"
              type="text"
              autoComplete="off"
              readOnly={from_my_office_checked ? true : false}
              {...register("other_broker_email")}
              onBlur={(e) => checkEmail(e.target.value)}
            />
          </FormInputWrapper>
          <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
            <label className="uk-form-label" htmlFor="name">Mobile Phone*</label>
            <input type="hidden" required name="other_broker_phone_number"  {...register("other_broker_phone_number")} />
            <PhoneInput
              country={'us'}
              placeholder='+1'
              inputClass={classes.phone_input}
              containerClass={classes.phone_container}
              readOnly={from_my_office_checked ? true : false}
              autoFocus={false}
              onFocus={() => { }}
              value={other_broker_mobile_phone}
              onChange={phone => { setValue('other_broker_phone_number', phone, { shouldValidate: true }) }}
            />
          </div>
        </FormRow>
        <FormRow>
          <FormInputWrapper>
            <div className='uk-padding-small pr-0'>
              <label className={`uk-form-label`}>{"Other Side's Broker Percent % *"}</label>
              <input
                className="uk-input  required"
                type="text"
                {...register('other_broker_percent', {
                  onChange: e => {
                    onChangeBrokerValues('other_broker_percent', e.target.value);
                  }
                })}
              />

            </div>
          </FormInputWrapper>
          <FormInputWrapper>
            <div className='uk-padding-small pr-0'>
              <label className={`uk-form-label`}>{"Other Side's Broker Amount $ % *"}</label>
              <input
                className="uk-input  required"
                type="text"
                {...register('other_broker_amount', {
                  onChange: e => {
                    onChangeBrokerValues('other_broker_amount', e.target.value);
                  }
                })}
                onBlur={() => handleBlur("other_broker_amount")}
                onFocus={() => handleFocus("other_broker_amount")}
              />
            </div>
          </FormInputWrapper>
          <FormInputWrapper className='pt-3'>
            {isVerified && <img src={process.env.PUBLIC_URL + "/images/verified-boatdox-logo.png"} alt="logo" style={{ height: "45px" }} />}
          </FormInputWrapper>
        </FormRow>
      </>
    );
  };
  const renderMe = () => {
    return (
      <FormRow>
        <Column>
          <label className="toys">Commission</label>
          <div className="radio-toolbar">
            <input id='percentage' type="radio" name="radioToys" value="percentage"
              checked={commission === "percentage"} onChange={() => setCommission("percentage")}
            />
            <label htmlFor='percentage' className="uk-form-label radio">Percentage</label>

            <input id='amount' type="radio" name="radioToys" value="amount"
              checked={commission === "amount"} onChange={() => setCommission("amount")}
            />
            <label htmlFor='amount' className="uk-form-label radio">Amount</label>
          </div>
        </Column>
        <FormInputWrapper label="Gross Commission %">
          <input
            className={clsx("uk-input", {
              "uk-form-danger": commission === "percentage" && errors.gross_commission_percent,
            })}
            type="text"
            {...register("gross_commission_percent")}
            readOnly={commission !== "percentage"}
          />
        </FormInputWrapper>
        <FormInputWrapper label="Gross Commission $ *">
          <input
            className={clsx("uk-input", {
              "uk-form-danger": commission === "amount" && errors.gross_commission_amount,
            })}
            type="text"
            {...register("gross_commission_amount")}
            onBlur={() => handleBlur("gross_commission_amount")}
            onFocus={() => handleFocus("gross_commission_amount")}
            readOnly={commission !== "amount"}
          />
        </FormInputWrapper>
      </FormRow>
    );
  };



  // useEffect(() => {
  //   setSelectedCompanyForBroker(null);
  //   setSelectedBrokerFromCompany(null);
  //   setValue('previous_company_id', null);
  //   setValue('previous_company_broker_id', null);
  // }, [watch('is_selling_broker_from_other_company'), watch('is_listing_broker_from_other_company')]);

  const links = [
    { label: "Deals", link: "/deals" },
    { label: "Add a Deal", link: null },
  ];

  const boatTypeHandler = (e) => {
    setBrokerType(e.target.value);
    setValue("broker_type", e.target.value);
  }

  const vesselName = `${dataTransaction?.t_vessel?.year || ""} ${dataTransaction?.t_vessel?.make || ""} ${dataTransaction?.t_vessel?.model || ""} ${dataTransaction?.t_vessel?.length || ""}`;
  const uniqueTransactionId = dataTransaction?.unique_transaction_id ? `PS-${dataTransaction?.unique_transaction_id}` : '';


  const account_owner_mobile_phone = useMemo(() => getValues("account_owner_phone_number"), [watch('account_owner_phone_number')]);
  const other_broker_mobile_phone = useMemo(() => getValues("other_broker_phone_number"), [watch('other_broker_phone_number')]);

  //Get the value of user_from_company
  const from_my_office_checked = useMemo(() => getValues("user_from_company"), [watch('user_from_company')]);

  //Get the value of is_broker_from_other_company
  const from_other_company_checked = useMemo(() => getValues("is_broker_from_other_company"), [watch('is_broker_from_other_company')]);

  return (
    <FormPage links={links} title="Co-Broker" vesselName={vesselName} uniqueId={uniqueTransactionId}>
      <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
        <FormRow>
          <FormInputWrapper label="Selling Price">
            <input className="uk-input required" type="text" defaultValue={getPrice(purchase_price)} readOnly />
          </FormInputWrapper>
        </FormRow>

        {renderMe()}

        <FormRow>
          <Column size="three">
            <hr />
          </Column>
        </FormRow>

        {renderSellingBorker()}
        <FormRow>
          <Column size="three">
            <hr />
          </Column>
        </FormRow>

        {brokerType != "both" &&
          <>
            {renderListingBroker()}
            <FormRow>
              <Column size="three">
                <hr />
              </Column>
            </FormRow>
          </>

        }

        <FormRow>
          <FormInputWrapper size="three" label="Additional Terms/Comments">
            <textarea className="uk-textarea" rows="4" cols="50" {...register("additional_terms_comments")} />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <SubmitButton>Save & Continue</SubmitButton>
        </FormRow>
      </Form>
      <AsterixTypo isVisible={false} />
    </FormPage>
  );
};

export default AddCoBroker;
