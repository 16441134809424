import React from 'react';
import { Box } from '@mui/material';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`} {...other}>
            {value === index && (
                <Box pt={1}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default TabPanel;
