import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import HelperTerms from "../../../../common/HelperTerms";
import { getFormattedData, getPlainTransactionData } from "../../../../../utils/stepFilter";
import { getTransactionInitialById, upsertTransaction } from "../../../../../redux/actions/transaction";

import moment from "moment";
import * as yup from "yup";
import { setLoadingStatus } from '../../../../../redux/actions/loading';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { dateFormatter } from '../../../../../utils/common';

const PanelFormTerms = ({ closePanel }) => {

    const schema = yup.object().shape({});

    const { register, handleSubmit, setValue, reset, getValues, watch, formState } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    const { isDirty, errors } = formState;

    const dispatch = useDispatch();

    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);

    // const dataDeal = dataTransaction || {};
    const dataDeal = useMemo(() => dataTransaction || {}, [dataTransaction]);
    // const dataTerms = dataDeal.t_terms || {};
    const dataTerms = useMemo(() => dataDeal.t_terms || {}, [dataDeal]);

    const [boolIsYBAA, setIsYBAA] = useState(true);
    const [materialDamaged, setMaterialDamaged] = useState(null);
    const [ruleOfBinding, setRulOfBinding] = useState(dataTerms.dispute_location ? dataTerms.dispute_location : "A");
    const [ruleOfLocationCity, setRuleOfLocationCity] = useState(dataTerms.dispute_location_city ? dataTerms.dispute_location_city : "Fort Lauderdale");
    const [ruleOfLocationLaw, setRuleOfLocationLaw] = useState(dataTerms.dispute_location_law ? dataTerms.dispute_location_law : "Florida Law");
    const [additionalProvisions, SetAdditionalProvisions] = useState("");
    const [customAdditionalProvisions, SetCustomAdditionalProvisions] = useState("");
    const [finalAdditionalProvisions, SetFinalAdditionalProvisions] = useState("");

    const [subjectTo, SetSubjectTo] = useState({
        financing: false,
        financing_date: null,
        trial_run: false,
        trial_run_date: null,
        marine_survey: false,
        marine_survey_date: null,
        mechanical_inspection: false,
        mechanical_inspection_date: null,
        insurance: false,
        insurance_date: null,
        see_addendum: false,
    });

    const subjectToFields = [
        { key: "Financing", val: "financing", date: true },
        { key: "Trial Run", val: "trial_run", date: true },
        { key: "Marine Survey", val: "marine_survey", date: true },
        { key: "Mechanical Inspection", val: "mechanical_inspection", date: true },
        { key: "Insurance", val: "insurance", date: true },
        { key: "See Addendum", val: "see_addendum", date: false },
    ];

    useEffect(() => {
        if (dataTerms) {
            Object.keys(dataTerms).forEach((key) => {
                if (!Object.values(subjectToFields).includes(key)) {
                    if (key === "additional_provisions") {
                        setValue("additional_provisions", dataTerms?.custom_additional_terms_provisions || "");
                    } else {
                        setValue(key, dataTerms[key])
                    }
                }
            });
            setMaterialDamaged(dataTerms.materially_damaged || null);
        } else {
            [
                "dispute_location_state",
                "dispute_location_city",
                "dispute_location_law",
                "other_rules_apply",
                "material_damage_detail",
            ].forEach((key) => setValue(key, null));
        }
    }, [dataTerms, setMaterialDamaged, setValue]);

    // this useEffect is mainly used for pre fill data fetched from api
    useEffect(() => {
        if (dataTerms && Object.keys(dataTerms).length) {
            subjectToFields.forEach((field) => {
                if (dataTerms[field.val]) {
                    SetSubjectTo((state) => {
                        return {
                            ...state,
                            [field.val]: dataTerms[field.val] ? dataTerms[field.val] : false,
                            [`${field.val}_date`]: dataTerms[`${field.val}_date`]
                                ? dataTerms[`${field.val}_date`]?.split('T')[0]
                                : null,
                        };
                    });
                }
            });
        }
    }, [dataTerms, SetSubjectTo]);

    // this useEffect is mainly used for generate Additional Terms/Provisions text from subjectTo state
    useEffect(() => {
        const defaultPrefix = "Subject to: "
        let stringToBeSet = "";

        subjectToFields.forEach((field) => {
            if (subjectTo[field.val] && subjectTo[`${field.val}_date`]) {
                if (!stringToBeSet.length) {
                    stringToBeSet = defaultPrefix;
                }
                const str = `${field.key} by ${moment(subjectTo[`${field.val}_date`]).format("MM/DD/YY")};`;
                stringToBeSet += `${str} `;
            }
        });

        subjectToFields.forEach((field) => {
            if (subjectTo[field.val] && !subjectTo[`${field.val}_date`]) {
                if (!stringToBeSet.length) {
                    stringToBeSet = defaultPrefix;
                }
                stringToBeSet += `${field.key}; `;
            }
        });
        stringToBeSet = stringToBeSet.trim();
        if (stringToBeSet.trim().length) {
            stringToBeSet = stringToBeSet.trim().replace(/.$/, ".");
        }
        SetAdditionalProvisions(stringToBeSet || "");
    }, [subjectTo]);

    useEffect(() => {
        SetCustomAdditionalProvisions(getValues("additional_provisions") || "");
    }, [watch("additional_provisions")]);

    useEffect(() => {
        if (additionalProvisions?.length || customAdditionalProvisions?.length) {
            SetFinalAdditionalProvisions(`${additionalProvisions} ${customAdditionalProvisions}`);
        }
    }, [additionalProvisions, customAdditionalProvisions]);

    useEffect(() => {
        setRulOfBinding(getValues("dispute_location"));
    }, [watch("dispute_location")]);

    useEffect(() => {
        setRuleOfLocationCity(getValues("dispute_location_city"));
    }, [watch("dispute_location_city")]);

    useEffect(() => {
        setRuleOfLocationLaw(getValues("dispute_location_law"));
    }, [watch("dispute_location_law")]);

    const handleRegistration = (term) => {
        if (dataTerms) {
            term.id = dataTerms.id;
        }
        dispatch(setLoadingStatus(true));
        term.materially_damaged = materialDamaged;
        getFormattedData(term);
        term = { ...term, ...subjectTo, additional_provisions: finalAdditionalProvisions, custom_additional_terms_provisions: customAdditionalProvisions };
        dispatch(upsertTransaction({
            step: {
                db: "terms",
                main: term,
            },
            transaction: {
                isBelonged: false,
                main: {
                    ...getPlainTransactionData(dataDeal),
                    task_type: "deal",
                    broker_user_id: dataBroker?.id,
                    company_id: dataBroker?.company_id,
                    company_office_id: dataBroker?.company_office_id,
                },
            },
        }, "Terms")).then((data) => {
            dispatch(getTransactionInitialById(data.id));
            dispatch(setLoadingStatus(false));
            closePanel(true);
        }).catch((error) => {
            dispatch(setLoadingStatus(false));
            console.log(error);
        });
    };

    const handleError = (error) => {
        console.log(error);
    };

    // function for handle date change and set it
    const handleDateChange = async (dateString, fieldName) => {
        SetSubjectTo((state) => {
            return {
                ...state,
                [`${fieldName}_date`]: dateString ? dateFormatter(dateString) : null
            };
        });
    }

    return (
        <div className="form-container panel-form">
            <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                <div className="row">
                    <div className="col-md-6 pl-0 pr-0">
                        <FormInputWrapper size="two" label="Additional Terms/Provisions">
                            <textarea className="uk-textarea" rows="3" {...register("additional_provisions")} />
                            <div className="text-right">
                                <small className="text-danger">Watch term text length so it's not cut off on contract.</small>
                            </div>
                        </FormInputWrapper>

                        {additionalProvisions.length
                            ? <FormInputWrapper size="two" label="Terms/Provisions Preview">
                                <p>{additionalProvisions} {customAdditionalProvisions}</p>
                            </FormInputWrapper>
                            : <></>
                        }

                        <h3>Governing Law and Dispute Resolution{" "}
                            <span data-uk-icon="icon: info" data-uk-toggle="target: #modal-helper-term" className="info-icon" ></span>
                        </h3>
                        <HelperTerms />

                        {materialDamaged && (
                            <FormRow>
                                <FormInputWrapper size="two" label="Please explain material damage" >
                                    <textarea className="uk-textarea" rows="3" {...register("material_damage_detail")} />
                                </FormInputWrapper>
                            </FormRow>
                        )}

                        <FormInputWrapper label="Rules of Binding Arbitration *">
                            <div className="radio-toolbar">
                                <input id='rule_a' type="radio" {...register("dispute_location")} value="A" checked={ruleOfBinding === "A"} />
                                <label htmlFor='rule_a' className="uk-form-label radio">A - IYAC</label>
                                <input id='rule_b' type="radio" {...register("dispute_location")} value="B" checked={ruleOfBinding === "B"} />
                                <label htmlFor='rule_b' className="uk-form-label radio">B - LMAA</label>
                            </div>
                        </FormInputWrapper>

                        {ruleOfBinding === "A" && (
                            <>
                                {/* <FormRow> */}
                                <FormInputWrapper size="two">
                                    <div className="radio-toolbar">
                                        <input id='fort_lauderdale' type="radio" value="Fort Lauderdale" {...register("dispute_location_city")}
                                            checked={ruleOfLocationCity === "Fort Lauderdale"} />
                                        <label htmlFor='fort_lauderdale' className="uk-form-label radio">Fort Lauderdale</label>
                                        <input id='london' type="radio" value="London" {...register("dispute_location_city")}
                                            checked={ruleOfLocationCity === "London"} />
                                        <label htmlFor='london' className="uk-form-label radio">London</label>
                                        <input id='monaco' type="radio" value="Monaco" {...register("dispute_location_city")}
                                            checked={ruleOfLocationCity === "Monaco"} />
                                        <label htmlFor='monaco' className="uk-form-label radio">Monaco</label>
                                    </div>
                                </FormInputWrapper>
                                {/* </FormRow> */}
                                {/* <FormRow> */}
                                <FormInputWrapper size="two">
                                    <div className="radio-toolbar">
                                        <input id='fl_law' type="radio" value="Florida Law" {...register("dispute_location_law")}
                                            checked={ruleOfLocationLaw === "Florida Law"} />
                                        <label htmlFor='fl_law' className="uk-form-label radio">Florida Law</label>
                                        <input id='eng_law' type="radio" value="English Law" {...register("dispute_location_law")}
                                            checked={ruleOfLocationLaw === "English Law"} />
                                        <label htmlFor='eng_law' className="uk-form-label radio">English Law</label>
                                    </div>
                                </FormInputWrapper>
                                {/* </FormRow> */}
                            </>
                        )}
                    </div>
                    <div className="col-md-6 pr-0">
                        <h4>Subject To:</h4>
                        <table className="table table-borderless">
                            <tbody>
                                {subjectToFields.map((field, index) => {
                                    return (
                                        <tr id={index}>
                                            <th className='p-0 pb-2' scope="row">{field.key}</th>
                                            <td className='p-0 pb-2'>
                                                <div className="radio-toolbar">
                                                    <input id={`${field?.val}_yes`} type="radio" value={true} checked={subjectTo[field.val]}
                                                        onChange={() =>
                                                            SetSubjectTo((state) => {
                                                                return {
                                                                    ...state,
                                                                    [field.val]: !state[field.val],
                                                                };
                                                            })
                                                        }
                                                    />
                                                    <label htmlFor={`${field?.val}_yes`} className="uk-form-label radio">Yes</label>
                                                    <input id={`${field?.val}_no`} type="radio" value={false} checked={!subjectTo[field.val]}
                                                        onChange={() =>
                                                            SetSubjectTo((state) => {
                                                                return {
                                                                    ...state,
                                                                    [field.val]: !state[field.val],
                                                                    [`${field.val}_date`]: null,
                                                                };
                                                            })
                                                        }
                                                    />
                                                    <label htmlFor={`${field?.val}_no`} className="uk-form-label radio">No</label>
                                                </div>
                                            </td>
                                            <td className='p-0 pb-2'>
                                                {field.date ? (
                                                    <DatePicker
                                                        className="uk-input uk-form-width-small"
                                                        style={{ cursor: subjectTo[`${field.val}_date`] && 'pointer', color: '#000' }}
                                                        value={subjectTo[`${field.val}_date`] && dayjs(subjectTo[`${field.val}_date`])}
                                                        disabled={!subjectTo[field.val]}
                                                        format={'MM/DD/YYYY'}
                                                        onChange={(date, dateString) => handleDateChange(dateString, field?.val)}
                                                    />
                                                ) : (
                                                    <></>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <FormRow>
                    <button type="submit" className='uk-button uk-button-primary pull-right'>Save</button>
                    <button type="button" className='uk-button uk-button-orange pull-right' onClick={() => closePanel(false)}>Close</button>
                </FormRow>
            </Form>
        </div>
    )
}

export default PanelFormTerms;