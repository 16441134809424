import React, { useEffect, useState } from "react";
import ReactDragListView from "react-drag-listview";
import { Table } from "antd";
import { useDispatch } from "react-redux";

import { reOrderTasks } from "../../../../../redux/actions/transaction";
import Auth from "../../../../../utils/auth";
import { useParams } from "react-router-dom";
import { setLoadingStatus } from "../../../../../redux/actions/loading";

import "antd/dist/reset.css";
import TransactionService from "../../../../../services/TransactionService";

const TaskEditTable = ({ show, transactionId, companyId, setDataForUpdateTaskListOrder }) => {

  const dispatch = useDispatch();
  const logged_user = Auth.getInstance().getUserData();
  const urlParams = useParams();
  const [tblData, setTblData] = useState([]);

  useEffect(() => {
    if (show) {
      getCompanyTagList();
    }
  }, [show]);

  const getCompanyTagList = async () => {
    dispatch(setLoadingStatus(true));
    const dataTasks = (await TransactionService.getEdocCompanyTaskById(companyId, transactionId)).data;
    const dataDocument = await dataTasks.filter((task) => {
      return task.task_type === "task";
    });
    setTblData(dataDocument);
    dispatch(setLoadingStatus(false));
  }

  const showStatus = (status) => {
    if (status === "completed") {
      return "Completed";
    } else if (status === "in_process") {
      return "In Process";
    } else if (status === "not_started") {
      return "Not Started";
    } {
      return status || "Not Started";
    }
  };

  const columns = [
    {
      title: "",
      width: 80,
      key: "operate",
      render: () => <img src="/icons/move.svg" className="move-svg" />,
    },
    {
      title: "Order",
      width: 100,
      render: (row) => (
        <div>
          {
            row?.sort_order + 1 || " "
          }
        </div>
      ),
    },
    {
      title: "Task",
      key: "task",
      render: (data, record, index) => {
        const task = tblData[index];
        return (
          <div>{task.company_tasks?.title || ""}</div>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      render: (data) => {
        return showStatus(data.status);
      },
    },
  ];

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const tbl = [...tblData];
      const item = tbl.splice(fromIndex, 1)[0];
      tbl.splice(toIndex, 0, item);
      tbl.forEach((t, index) => {
        t.sort_order = index;
      });
      const data = tbl.filter((t) => (t.master_task_id ? true : false));
      setTblData(data);

      // store updated data of sortOrder in state for every drag change 
      setDataForUpdateTaskListOrder(data);
    },
    handleSelector: "img",
  };

  if (!show) {
    return <></>;
  }
  return (
    <>
      <div className="drag_list_view">
        <ReactDragListView {...dragProps}>
          <Table columns={columns} pagination={false} dataSource={tblData} bordered />
        </ReactDragListView>
      </div>
    </>
  );
};

export default TaskEditTable;
