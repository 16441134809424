import React, { useEffect, useState, useRef, useCallback } from "react";

import { Document, Page, pdfjs } from "react-pdf";
// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
// import "./PDFPrefillViewer.scss";

import { DocPanel } from "./drag-and-drop/DocPanel";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFPrefillViewer = (props) => {
  const [numPages, setNumPages] = useState(null);
  const viewport = { width: 856, height: props.height * (856 / props.width) };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const onLoadError = (error) => {
    console.log("Error while loading document!", error.message)
  }

  const handleDrop = useCallback((item, monitor) => {
    const docPosition = currentRef.current.getBoundingClientRect();
    const monitorPosition = monitor.getClientOffset();
    const pageId = parseInt(currentRef.current.getAttribute('id'));
    const targetPosition = { x: monitorPosition.x - docPosition.x, y: monitorPosition.y - docPosition.y };

    let newItem = {
      coord: {
        x: targetPosition.x / viewport.width * props.width - 70,
        y: props.height - targetPosition.y / viewport.height * props.height - 13,
        width: 140,
        height: 26
      },
      page_number: pageId
    };

    switch (item.name) {
      // case 'Signature':
      //   newItem = {
      //     key: `Signature_${Date.now()}`,
      //     value: `Signature_${Date.now()}`,
      //     type: 'PDFSignature',
      //     ...newItem
      //   };
      //   break;
      // case 'Initials':
      //   newItem = {
      //     ...newItem,
      //     key: `Initials_${Date.now()}`,
      //     value: '',
      //     type: 'PDFInitial',
      //     coord: {
      //       x: targetPosition.x / viewport.width * props.width - 40,
      //       y: props.height - targetPosition.y / viewport.height * props.height - 13,
      //       width: 80,
      //       height: 26
      //     },
      //   };
      //   break;
      // case 'Date Signed':
      //   newItem = {
      //     key: `DateSignature_${Date.now()}`,
      //     value: '',
      //     type: 'PDFDateSigned',
      //     ...newItem
      //   };
      //   break;
      case 'Name':
        newItem = {
          key: `Name_${Date.now()}`,
          value: 'Name',
          type: 'PDFName',
          ...newItem
        };
        break;
      case 'Email':
        newItem = {
          key: `Email_${Date.now()}`,
          value: 'Email',
          type: 'PDFEmail',
          ...newItem
        };
        break;
      case 'Company':
        newItem = {
          key: `Company_${Date.now()}`,
          value: 'Company',
          type: 'PDFCompany',
          ...newItem
        };
        break;
      case 'Tile':
        newItem = {
          key: `Tile_${Date.now()}`,
          value: 'Tile',
          type: 'PDFTile',
          ...newItem
        };
        break;
      case 'Textbox':
        newItem = {
          key: `Textbox_${Date.now()}`,
          value: `Textbox_${Date.now()}`,
          type: 'PDFTextField',
          ...newItem
        };
        break;
      case 'Checkbox':
        newItem = {
          ...newItem,
          key: `Checkbox_${Date.now()}`,
          value: '',
          type: 'PDFCheckBox',
          coord: {
            x: targetPosition.x / viewport.width * props.width - 10,
            y: props.height - targetPosition.y / viewport.height * props.height - 10,
            width: 20,
            height: 20
          }
        };
        break;
      default:
        break;
    }

    props.handleAddElement(newItem);
  });

  const accept = [
    'signature', 'initial', 'signature_date', 'name', 'email', 'company', 'title', 'textbox', 'checkbox'
  ];

  const { pdf } = props;

  const [currentRef, setCurrentRef] = useState(null);

  return (
    <Document
      file={pdf}
      options={{
        cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
        cMapPacked: true,
      }}
      onLoadSuccess={onDocumentLoadSuccess}
      onLoadError={onLoadError}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} width={viewport.width} >
          <DocPanel accept={accept} handleDrop={handleDrop} parentProps={props} pageId={index} updateRef={setCurrentRef} />
        </Page>
      ))}
    </Document>
  );
}

export default PDFPrefillViewer;