import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import OfficeService from "../../services/OfficeService";

import { phoneNumber } from "../../utils/phonenumber";

const OfficeView = (props) => {
  const office_id = props.location.state ? props.location.state.id : null;

  const [office, setOffice] = useState({});

  useEffect(() => {
    OfficeService.get(office_id).then((office) => {
      setOffice(office.data);
    });
  }, []);

  return (
    <div className="uk-container page">
      <ul className="uk-breadcrumb">
        <li>
          <Link to="/offices">offices</Link>
        </li>
        <li className="uk-disabled">
          <a>View Office</a>
        </li>
      </ul>

      <h1>{office.office_name}</h1>

      <div className="form-container">
        <form className="uk-grid-small form" data-uk-grid>
          <div className="uk-width-1-3@s  uk-margin mt20">
            <label className="uk-form-label" htmlFor="name">
              Office Name
            </label>
            <div className="uk-text uk-text-emphasis">{office.office_name}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Address 1
            </label>
            <div className="uk-text uk-text-emphasis">{office.address_1}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Address 2
            </label>
            <div className="uk-text uk-text-emphasis">{office.address_2}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Address 3
            </label>
            <div className="uk-text uk-text-emphasis">{office.address_3}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              City
            </label>
            <div className="uk-text uk-text-emphasis">{office.city}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              State
            </label>
            <div className="uk-text uk-text-emphasis">{office.state}</div>
          </div>

          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Country
            </label>
            <div className="uk-text uk-text-emphasis">{office.country}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Postal Code
            </label>
            <div className="uk-text uk-text-emphasis">{office.postal_code}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Office Phone
            </label>
            <div className="uk-text uk-text-emphasis">{phoneNumber(office.office_phone)}</div>
          </div>
          <div className="uk-width-1-3@s  uk-margin">
            <label className="uk-form-label" htmlFor="name">
              Timezone
            </label>
            <div className="uk-text uk-text-emphasis">{office.timezone}</div>
          </div>
        </form>
      </div>

      <div className="uk-column-1-1  uk-margin-small">
        <Link to={"/offices"}>
          <button className="uk-button iy-button-primary">
            Done
          </button>
        </Link>
      </div>
    </div>
  );
};

export default OfficeView;
