import Column from "./Column";

const FormInputWrapper = ({ label, size, children, className, ...props }) => {
  if (!size) {
    size = "one";
  }

  return (
    <Column size={size} {...props}>
      <label className={`uk-form-label ${className ? className : ""}`}>{label}</label>
      {children}
    </Column>
  );
};

export default FormInputWrapper;
