const asyncActionType = (type) => ({
  pending: `${type}_PENDING`,
  success: `${type}_SUCCESS`,
  error: `${type}_ERROR`,
});

/* Types for Clients */
export const CREATE_CLIENT = "CREATE_CLIENT";
export const RETRIEVE_CLIENTS = "RETRIEVE_CLIENTS";
export const GET_CLIENT = "GET_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const DELETE_ALL_CLIENTS = "DELETE_ALL_CLIENTS";
export const FIND_CLIENT_BY_NAME = "FIND_CLIENT_BY_NAME";
export const FIND_ONE_BY_NAME = "FIND_ONE_BY_NAME";
export const CLEAR_CLIENT = "CLEAR_CLIENT";

/* Types for Countries */
export const RETRIEVE_COUNTRIES = "RETRIEVE_COUNTRIES";

/* Types for States */
export const RETRIEVE_STATES = "RETRIEVE_STATES";

/* Types for Vessels */
export const CREATE_VESSEL_OR_TOY = "CREATE_VESSEL_OR_TOY";
export const RETRIEVE_VESSEL_OR_TOYS = "RETRIEVE_VESSEL_OR_TOYS";
export const GET_VESSEL_OR_TOY = "GET_VESSEL_OR_TOY";
export const UPDATE_VESSEL_OR_TOY = "UPDATE_VESSEL_OR_TOY";
export const DELETE_VESSEL_OR_TOY = "DELETE_VESSEL_OR_TOY";
export const DELETE_ALL_VESSEL_OR_TOYS = "DELETE_ALL_VESSEL_OR_TOYS";
export const FIND_VESSEL_OR_TOY_BY_NAME = "FIND_VESSEL_OR_TOY_BY_NAME";
export const FIND_VESSEL_OR_TOY_BY_HULLNUM = "FIND_VESSEL_OR_TOY_BY_HULLNUM";
export const CLEAR_VESSEL = "CLEAR_VESSEL";
export const RETRIEVE_TOYS_BY_DEAL = "RETRIEVE_TOYS_BY_DEAL";
export const CLEAR_LIST = "CLEAR_LIST";
export const GET_MLS_FILTERS = 'GET_MLS_FILTERS';

/* Types for Offices */
export const CREATE_OFFICE = "CREATE_OFFICE";
export const RETRIEVE_OFFICES = "RETRIEVE_OFFICES";
// RETRIEVE_MLS_OFFICES
export const RETRIEVE_MLS_OFFICES = asyncActionType("RETRIEVE_MLS_OFFICES");

export const GET_OFFICE = "GET_OFFICE";
export const UPDATE_OFFICE = "UPDATE_OFFICE";
export const DELETE_OFFICE = "DELETE_OFFICE";
export const DELETE_ALL_OFFICES = "DELETE_ALL_OFFICES";
export const FIND_OFFICE_BY_NAME = "FIND_OFFICE_BY_NAME";
export const FIND_BY_COMPANY = "FIND_BY_COMPANY";
export const FIND_BY_OFFICE_ID = "FIND_BY_OFFICE_ID";

/* Types for Companies */
export const CREATE_COMPANY = "CREATE_COMPANY";
export const RETRIEVE_COMPANIES = "RETRIEVE_COMPANIES";
export const GET_COMPANY = "GET_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_ALL_COMPANIES = "DELETE_ALL_COMPANIES";
export const FIND_COMPANY_BY_NAME = "FIND_COMPANY_BY_NAME";

/* Types for Associations */
export const CREATE_ASSOCIATION = "CREATE_ASSOCIATION";
export const RETRIEVE_ASSOCIATIONS = "RETRIEVE_ASSOCIATIONS";
export const GET_ASSOCIATION = "GET_ASSOCIATION";
export const UPDATE_ASSOCIATION = "UPDATE_ASSOCIATION";
export const DELETE_ASSOCIATION = "DELETE_ASSOCIATION";
export const DELETE_ALL_ASSOCIATIONS = "DELETE_ALL_ASSOCIATIONS";
export const FIND_ASSOCIATION_BY_NAME = "FIND_ASSOCIATION_BY_NAME";

/* Types for Deals */
export const GET_DEALS = "GET_ALL_DEALS";
export const CREATE_ESIGN = "CREATE_ESIGN";
export const UPDATE_FIELD_OF_DEAL = "UPDATE_FIELD_OF_DEAL";
export const UPSERT_DEAL = "UPSERT_DEAL";
export const CLEAR_DEAL = "CLEAR_DEAL";
export const GET_OFFER = "GET_OFFER";
export const GET_ADDENDUM = "GET_ADDENDUM";
export const GET_EXCLUSION = "GET_EXCLUSION";
export const GET_CO_BROKER = "GET_CO_BROKER";
export const GET_DEAL_BY_ID = "GET_DEAL_BY_ID";
export const ESIGN_REQUEST = asyncActionType("ESIGN_REQUEST");
export const ESIGN_REQUEST_UPDATE = asyncActionType("ESIGN_REQUEST_UPDATE");
export const GET_ESIGN_URL = asyncActionType("GET_ESIGN_URL");
export const GET_ESIGN_DOC = asyncActionType("GET_ESIGN_DOC");
export const RESET_EDOC = "RESET_EDOC";
export const UPDATE_REQUIRED_FIELDS = "UPDATE_REQUIRED_FIELDS";
export const DELETE_COBROKER = "DELETE_COBROKER";

/* Types for Transactions */
export const GET_TRANSACTIONS = "GET_ALL_TRANSACTIONS";
export const UPDATE_FIELD_OF_TRANSACTION = "UPDATE_FIELD_OF_TRANSACTION";
export const UPSERT_TRANSACTION = "UPSERT_TRANSACTION";
export const CLEAR_TRANSACTION = "CLEAR_TRANSACTION";
export const CLEAR_TRANSACTIONS = "CLEAR_TRANSACTIONS";
export const GET_OFFER_FOR_TRANSACTION = "GET_OFFER_FOR_TRANSACTION";
export const GET_ADDENDUM_FOR_TRANSACTION = "GET_ADDENDUM_FOR_TRANSACTION";
export const GET_EXCLUSION_FOR_TRANSACTION = "GET_EXCLUSION_FOR_TRANSACTION";
export const GET_CO_BROKER_FOR_TRANSACTION = "GET_CO_BROKER_FOR_TRANSACTION";
export const GET_PARTICIPANTS_LIST = "GET_PARTICIPANTS_LIST";
export const GET_TRANSACTION_BY_ID = "GET_TRANSACTION_BY_ID";
export const GET_TRANSACTION_INITIAL_BY_ID = "GET_TRANSACTION_INITIAL_BY_ID";
export const GET_TRANSACTION_EDOC_BY_ID = "GET_TRANSACTION_EDOC_BY_ID";
export const GET_TRANSACTION_ESIGN_BY_ID = "GET_TRANSACTION_ESIGN_BY_ID";
export const GET_TRANSACTION_COMPANY_BY_ID = "GET_TRANSACTION_COMPANY_BY_ID";
export const UPDATE_REQUIRED_FIELDS_FOR_TRANSACTION =
  "UPDATE_REQUIRED_FIELDS_FOR_TRANSACTION";
export const UPDATE_TOYS_FOR_TRANSACTION = "UPDATE_TOYS_FOR_TRANSACTION";
export const DELETE_TOY_FROM_TRANSACTION = "DELETE_TOY_FROM_TRANSACTION";
export const UPDATE_OPTION_REQUIRED_FIELDS = "UPDATE_OPTION_REQUIRED_FIELDS";
export const PUT_ASSOCIATION_FOR_TRANSACTION = "PUT_ASSOCIATION_FOR_TRANSACTION";
export const SEND_EMAIL_TO_SIGNERS = "SEND_EMAIL_TO_SIGNERS";
export const GET_COMPANY_EDOC_TASK_LIST = "GET_COMPANY_EDOC_TASK_LIST";

/* Types for Document And Task */
export const GET_CHECK_LIST = "GET_CHECK_LIST";
export const GET_TASK_BY_CHECK_LIST = "GET_TASK_BY_CHECK_LIST";
export const CREATE_TASK = "CREATE_TASK";
export const UPDATE_TASK = "UPDATE_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const UPDATE_TASK_ORDER = "UPDATE_TASK_ORDER";
export const INSERT_TASK_TO_DEAL = "INSERT_TASK_TO_DEAL";
export const UPDATE_TASK_FOR_DEAL = "UPDATE_TASK_FOR_DEAL";
export const DELETE_TASK_FOR_DEAL = "DELETE_TASK_FOR_DEAL";
// UPDATE_ORDER_TASK_LIST
export const UPDATE_ORDER_TASK_LIST = "UPDATE_ORDER_TASK_LIST";
export const UPDATE_TASK_ORDER_FOR_LISTING_AGREEMENT =
  "UPDATE_TASK_ORDER_FOR_LISTING_AGREEMENT";
export const INSERT_TASK_TO_LISTING_AGREEMENT =
  "INSERT_TASK_TO_LISTING_AGREEMENT";
export const UPDATE_TASK_FOR_LISTING_AGREEMENT =
  "UPDATE_TASK_FOR_DEAL_FOR_LISTING_AGREEMENT";
export const DELETE_TASK_FOR_LISTING_AGREEMENT =
  "DELETE_TASK_FOR_DEAL_FOR_LISTING_AGREEMENT";
export const UPDATE_TASK_ORDER_FOR_TRANSACTION =
  "UPDATE_TASK_ORDER_FOR_TRANSACTION";
export const INSERT_TASK_TO_TRANSACTION = "INSERT_TASK_TO_TRANSACTION";
export const UPDATE_TASK_FOR_TRANSACTION =
  "UPDATE_TASK_FOR_DEAL_FOR_TRANSACTION";
export const DELETE_TASK_FOR_TRANSACTION =
  "DELETE_TASK_FOR_DEAL_FOR_TRANSACTION";
export const GET_IYBA_DOCS_BY_CHECKLIST_ID = asyncActionType("GET_IYBA_DOCS_BY_CHECKLIST_ID");
export const GET_IYBA_PREFILL_DOCS_BY_CHECKLIST_ID = asyncActionType("GET_IYBA_PREFILL_DOCS_BY_CHECKLIST_ID");

/* Types for User */
export const CREATE_USER = asyncActionType("CREATE_USER");
export const RETRIEVE_USERS = asyncActionType("RETRIEVE_USERS");
export const GET_USER = asyncActionType("GET_USER");
export const UPDATE_USER = asyncActionType("UPDATE_USER");
export const UPDATE_USER_PASSWORD = asyncActionType("UPDATE_USER_PASSWORD");
export const DELETE_USER = asyncActionType("DELETE_USER");
export const DELETE_ALL_USERS = asyncActionType("DELETE_ALL_USERS");
export const FIND_USER_BY_NAME = asyncActionType("FIND_USER_BY_NAME");
export const CLEAR_USER = asyncActionType("CLEAR_USER");
export const CLEAR_BROKERS = asyncActionType("CLEAR_BROKERS");
export const RETRIEVE_USER_ROLES = asyncActionType("RETRIEVE_USER_ROLES");
export const RETRIEVE_USER_RELATED = asyncActionType("RETRIEVE_USER_RELATED");
// ALL_ACCESSIBLE_BROKERS
export const ALL_ACCESSIBLE_BROKERS = "ALL_ACCESSIBLE_BROKERS";
export const RETRIEVE_USER_OFFICES = asyncActionType("RETRIEVE_USER_OFFICES");
export const RETRIEVE_BROKERS = asyncActionType("RETRIEVE_BROKERS");
export const RETRIEVE_OFFICE_USERS = asyncActionType("RETRIEVE_OFFICE_USERS");
export const CLEAR_OFFICE_USERS = asyncActionType("CLEAR_OFFICE_USERS");
export const INVITE_USER = asyncActionType("INVITE_USER");
export const RESEND_INVITATION = asyncActionType("RESEND_INVITATION");
export const COMPLETE_INVITATION = asyncActionType("COMPLETE_INVITATION");
export const UPDATE_PROFILE = asyncActionType("UPDATE_PROFILE");
export const PASSWORD_RESET_REQUIRED = "PASSWORD_RESET_REQUIRED";
export const SET_OFFICE_WITH_BROKER = "SET_OFFICE_WITH_BROKER";

/* Types for eDocument Templates */
export const CREATE_EDOC = asyncActionType("CREATE_EDOC");
export const RETRIEVE_EDOCS = asyncActionType("RETRIEVE_EDOCS");
export const GET_EDOC = asyncActionType("GET_EDOC");
export const UPDATE_EDOC = asyncActionType("UPDATE_EDOC");
export const DELETE_EDOC = asyncActionType("DELETE_EDOC");
export const DELETE_ALL_EDOCS = asyncActionType("DELETE_ALL_EDOCS");
export const FIND_EDOC_BY_NAME = asyncActionType("FIND_EDOC_BY_NAME");
export const CLEAR_EDOC = asyncActionType("CLEAR_EDOC");

/* Types for loading status */
export const SET_LOADING_STATUS = "SET_LOADING_STATUS";

export const SET_USER = "SET_USER";
export const SET_BROKER = "SET_BROKER";
export const GET_LISTING_SELLER = "GET_LISTING_SELLER";
export const UPDATE_VERIFICATION_STATUS = "UPDATE_VERIFICATION_STATUS";

/* Types for Dashboard */
export const GET_DEALS_BY_STATUS = "GET_DEALS_BY_STATUS";
export const GET_DEALS_BY_UPCOMING_CLOSINGS = "GET_DEALS_BY_UPCOMING_CLOSINGS";
export const GET_DEALS_BY_CLOSED_YEAR_TO_DATE =
  "GET_DEALS_BY_CLOSED_YEAR_TO_DATE";
export const GET_DEALS_BY_OFFICE = "GET_DEALS_BY_OFFICE";
export const GET_DEALS_BY_BROKER = "GET_DEALS_BY_BROKER";
export const GET_LISTINGS_BY_STATUS = "GET_LISTINGS_BY_STATUS";
export const GET_LISTINGS_BY_OFFICE = "GET_LISTINGS_BY_OFFICE";
export const GET_LISTINGS_BY_BROKER = "GET_LISTINGS_BY_BROKER";

/* Types for Log out */
export const USER_LOGOUT = "USER_LOGOUT";

/* Types for ESign Templates */
export const CREATE_ESIGN_TEMPLATE = "CREATE_ESIGN_TEMPLATE";
export const UPDATE_ESIGN_TEMPLATE = "UPDATE_ESIGN_TEMPLATE";
export const GET_ESIGN_TEMPLATE = "GET_ESIGN_TEMPLATE";
export const DELETE_ESIGN_TEMPLATE = "DELETE_ESIGN_TEMPLATE";
// export const FIND_ESIGN_TEMPLATE_BY_NAME = "FIND_ESIGN_TEMPLATE_BY_NAME";
export const RETRIEVE_ESIGN_TEMPLATES = "RETRIEVE_ESIGN_TEMPLATES";
export const PARSE_ESIGN_TEMPLATE = "PARSE_ESIGN_TEMPLATE";

/* Types for Document Templates */
export const CREATE_DOCUMENT_TEMPLATE = "CREATE_DOCUMENT_TEMPLATE";
export const UPDATE_DOCUMENT_TEMPLATE = "UPDATE_DOCUMENT_TEMPLATE";
export const GET_DOCUMENT_TEMPLATE = "GET_DOCUMENT_TEMPLATE";
export const DELETE_DOCUMENT_TEMPLATE = "DELETE_DOCUMENT_TEMPLATE";
// export const FIND_ESIGN_TEMPLATE_BY_NAME = "FIND_ESIGN_TEMPLATE_BY_NAME";
export const RETRIEVE_DOCUMENT_TEMPLATES = "RETRIEVE_DOCUMENT_TEMPLATES";
export const PARSE_DOCUMENT_TEMPLATE = "PARSE_DOCUMENT_TEMPLATE";
export const PREFILL_DOCUMENT = "PREFILL_DOCUMENT";

export const GET_SIGNATURE_DATA = asyncActionType("GET_SIGNATURE_DATA");
export const SIGN_DOCUMENT = asyncActionType("SIGN_DOCUMENT");
export const REJECT_DOCUMENT = asyncActionType("REJECT_DOCUMENT");

/* Types for report */
export const SET_DATE_FILTER = 'SET_DATE_FILTER';
export const SET_OFFICE_FILTER = 'SET_OFFICE_FILTER';
export const SET_BROKER_FILTER = 'SET_BROKER_FILTER';
export const SET_SORTBY_FILTER = 'SET_SORTBY_FILTER';
export const CLEAR_FILTER = 'CLEAR_FILTER';


/* Types for report */
export const GET_TUTORIALS = 'GET_TUTORIALS';