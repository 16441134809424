import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import "./esign-list.css"

import {
  deleteTemplate,
  findTemplateByName,
} from "../../../redux/actions/templates";

import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import datatableStyles from "../../../utils/datatable-style";
import { DebounceInput } from "react-debounce-input";
import { DELETE_MODAL_MESSAGE, DELETE_MODAL_TITLE } from "../../../constants/Alert";
import moment from 'moment';
import { setLoadingStatus } from "../../../redux/actions/loading";
import EditEsignList from "./EditEsignList";
import TransactionService from "../../../services/TransactionService";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import TemplatesService from "../../../services/TemplatesService";

const ListTemplates = () => {
  const [searchName, setSearchName] = useState("");
  const isLoading = useSelector((state) => state.loading.status);
  const dataTemplates = useSelector((state) => state.templates.all);
  const [isEditMode, setIsEditMode] = useState(false);

  // dataForUpdateEsignOrder State Will Contain Updated Data With Updated SortOrder Which Will Use In updateOrder's Api Payload
  const [dataForUpdateEsignOrder, setDataForUpdateEsignOrder] = useState([]);

  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  useEffect(() => {
    // In editSort , there is already called api for fetchTemplates so no need to do it two times
    if (searchName || (!isEditMode)) {
      dispatch(setLoadingStatus(true));
      dispatch(findTemplateByName(searchName))
        .then((res) => dispatch(setLoadingStatus(false)))
        .catch((err) => dispatch(setLoadingStatus(false)))
    }
  }, [searchName, isEditMode]);

  // updateListOrder function made for call updateEsignOrder's api which will update sortOrder as per change
  const updateListOrder = async (data) => {
    dispatch(setLoadingStatus(true));
    try {
      await TemplatesService.updateEsignOrder(data);
      // dispatch(setLoadingStatus(false));
    } catch (error) {
      dispatch(setLoadingStatus(false));
      toast.error("Error, While update sort order", {
        autoClose: 2000
      })
    }
  }

  // function for handle click of updateEsign sort Order
  const handleUpdateEsignOrder = async (isCancel = false) => {
    // if there is any changes in sort order then only call updateListOrder's Api
    if ((!isCancel) && isEditMode && dataForUpdateEsignOrder.length > 0) {
      await updateListOrder(dataForUpdateEsignOrder);
    }
    setIsEditMode(!isEditMode);
    setDataForUpdateEsignOrder([]);
  }

  const onDelete = (id) => {
    confirmAlert({
      title: DELETE_MODAL_TITLE,
      message: DELETE_MODAL_MESSAGE,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const data = {
              deleted: true,
              deleted_at: new Date(),
            };
            dispatch(deleteTemplate(id))
              .then((response) => {
                dispatch(findTemplateByName(searchName));
              })
              .catch((e) => {
                alert("Error = ", e);
              });
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const obj = {
    "single": "Single Use",
    "multi_select": "Multi Select",
    "multiple": "Multi Use"
  }

  const columns = [
    {
      name: "Order",
      selector: "sort_order",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row?.sort_order + 1 || " "
            }
          </div>
        </div>
      ),
    },
    {
      name: "Template Name",
      selector: "template_name",
      grow: 2,
      sortable: true,
      minWidth: "300px",
      cell: (row) => {
        return <div data-tag="allowRowEvents">
          <div>
            <div className="uk-link" onClick={(() => {
              dispatch(setLoadingStatus(true));
              TransactionService.getPdfLink(row.id)
                .then((res) => {
                  dispatch(setLoadingStatus(false));
                  if (res?.data?.url) {
                    window.open(`${res.data?.url}`, '_blank');
                  } else {
                    toast.error("Error occurs, please try again.", {
                      autoClose: 2000
                    })
                  }
                }).catch((err) => {
                  dispatch(setLoadingStatus(false));
                })
            })}>{row.template_name}</div>
          </div>
        </div>
      }
      ,
    },
    {
      name: "Association",
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.association || "N/A"
            }
          </div>
        </div>
      ),
    },
    {
      name: "Type",
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.checklist?.name || "N/A"
            }
          </div>
        </div>
      ),
    },
    {
      name: "Use Type",
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.use_type && obj[row?.use_type] ? obj[row?.use_type] : ""
            }
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.status ? "Active" : "In-Active" || "N/A"
            }
          </div>
        </div>
      ),
    },
    {
      name: "Company",
      grow: 2,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.company?.company_name || ""
            }
          </div>
        </div>
      ),
    },
    {
      name: "Hand Signed Only",
      grow: 2,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.is_hand_signed ? "Yes" : "No"
            }
          </div>
        </div>
      ),
    },
    {
      name: "Updated",
      selector: "updatedAt",
      sortable: true,
      cell: (row) => (
        <div>
          {moment(row.updatedAt).format('MMM DD')}
        </div>
      ),
    },
    // { name: "Office Phone", selector: "office_phone", sortable: true },
    // { name: "Timezone", selector: "timezone", sortable: true },
    {
      name: "",
      cell: (row) => (
        <div>
          <Link to={{ pathname: `${path}/edit`, state: { id: row.id } }}>
            <Tooltip title="Edit" placement="left">
              <img src="/icons/edit.svg" className="svg uk-margin-right" alt="" />
            </Tooltip>
          </Link>
          <Tooltip title="Delete" placement="right">
            <img src="/icons/delete.svg" className="svg" onClick={() => onDelete(row.id)} alt="" />
          </Tooltip>
        </div>
      ),
    },
  ];

  const sortedData = dataTemplates.sort((a, b) => (a.sort_order === b.sort_order ? 0 : a.sort_order > b.sort_order ? 1 : -1));

  return (
    <div className="uk-container uk-container-expand uk-position-relative">
      <div className="content">
        <h1>E-Sign Templates</h1>

        <div className="uk-column-1-2  uk-margin-small ">
          <div className="my-1">
            {(!isEditMode) && <Link to={`${path}/add`}>
              <div className="uk-button uk-button-default add-deal  align-items-center">
                <span className="uk-margin-small-right uk-icon" data-uk-icon="plus"></span> <span>Add</span>
              </div>
            </Link>}

            {/* if there is input in search then disable editSort button */}
            <button className={`uk-button ${isEditMode ? "uk-button-primary" : "uk-button-default"}`} disabled={searchName} onClick={() => handleUpdateEsignOrder()}>
              {isEditMode ? "Save Sort" : "Edit Sort"}
            </button>

            {isEditMode && <button className="uk-button uk-button-orange" onClick={() => handleUpdateEsignOrder(true)}>
              Cancel
            </button>}

          </div>

          {/* if editSort mode is active then no need for searchBox */}
          {!isEditMode && <div className="list-page-search-container m-1 sm-full-width">
            <form className="uk-search uk-search-default">
              <DebounceInput
                className="uk-search-input"
                type="search"
                placeholder="Search"
                onChange={(e) => setSearchName(e.target.value)}
                value={searchName}
                debounceTimeout={500}
                minLength={1}
              />
              {searchName === "" ? (
                <span className="bd-form-icon">
                  <i className="fa fa-search"></i>
                </span>
              ) : (
                <span className="bd-form-icon" onClick={() => setSearchName("")}>
                  <i className="fa fa-times"></i>
                </span>
              )}
            </form>
          </div>}
        </div>

        {
          isEditMode && <EditEsignList show={isEditMode} setDataForUpdateEsignOrder={setDataForUpdateEsignOrder} />
        }

        {
          !isEditMode &&
          ((!sortedData.length && isLoading)
            ? <div className="no-data-table"><div className="p-4">Fetching Details...</div></div> :
            <DataTable
              title="Offices Table"
              columns={columns}
              data={sortedData}
              striped={true}
              highlightOnHover={true}
              pointerOnHover={true}
              noHeader={true}
              fixedHeader={true}
              // pagination={sortedData.length > 10}
              // paginationPerPage={10}
              // paginationRowsPerPageOptions={[10, 20, 50, 100]}
              customStyles={datatableStyles}
            />)
        }
      </div>
    </div>
  );
};

export default ListTemplates;
