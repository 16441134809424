import { GET_ESIGN_URL } from "../actions/types";

const initialState = {};

function eSignReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ESIGN_URL.pending:
      return state;
    case GET_ESIGN_URL.success:
      return payload;
    case GET_ESIGN_URL.error:
      return payload;
    default:
      return state;
  }
}

export default eSignReducer;
