import { Switch, Route, Redirect, useRouteMatch, useParams } from "react-router-dom";
import YBAAListingAgreementStep from "./stepbars/YBAAListingAgreement";
import EsignYBAAListingAgreement from "./steps/EsignYBAAListingAgreement";

const OptionYBAAListingAgreement = () => {
  let { path } = useRouteMatch();
  let { id } = useParams();

  return (
    <div>
      <YBAAListingAgreementStep />
      <Switch>
        <Route
          path={`${path}/send_to_signer`}
          render={() => <EsignYBAAListingAgreement transactionId={id} />}
        />
        <Redirect to={path} />
      </Switch>
    </div>
  );
};

export default OptionYBAAListingAgreement;
