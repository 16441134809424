import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import { setLoadingStatus } from '../../../../../redux/actions/loading';
import {
    upsertTransaction,
    getExclusions,
    getTransactionInitialById,
} from "../../../../../redux/actions/transaction";
import { getPlainTransactionData } from "../../../../../utils/stepFilter";
import * as yup from "yup";

const PanelFormExclusions = ({ closePanel }) => {

    const schema = yup.object().shape({});

    const { register, handleSubmit, setValue, getValues } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: false,
    });

    const dispatch = useDispatch();

    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);
    const dataListing = dataTransaction || {};
    const dataExclusions = dataListing.t_exclusion || [];

    useEffect(() => {
        if (dataTransaction) {
            dispatch(getExclusions(dataListing.id));
        }
    }, []);

    useEffect(() => {
        if (dataExclusions.length > 0) {
            dataExclusions.map((exclusion, index) => {
                setValue(exclusion.exclusion_index ? `exclusion${exclusion.exclusion_index}` : `exclusion${index + 1}`, exclusion.exclusion);
            });
        }
    }, [dataExclusions]);

    const handleRegistration = (exclusions) => {
        let data = Object.keys(exclusions).map((key) => {
            if (exclusions[key]) return exclusions[key];
        });
        data = data.filter((d) => d);
        if (data) {
            dispatch(setLoadingStatus(true));
            dispatch(upsertTransaction({
                step: {
                    db: "exclusion",
                    main: {
                        data: data,
                    },
                },
                transaction: {
                    isBelonged: false,
                    main: {
                        ...getPlainTransactionData(dataListing),
                        task_type: "listing",
                        broker_user_id: dataBroker?.id,
                        company_id: dataBroker?.company_id,
                        company_office_id: dataBroker?.company_office_id,
                    },
                },
            },"Exclusion")
            ).then((data) => {
                dispatch(getTransactionInitialById(dataListing.id));
                dispatch(setLoadingStatus(false));
                closePanel(true);
            }).catch((error) => {
                dispatch(setLoadingStatus(false));
                console.log(error);
            });
        }
    };

    const handleError = (error) => {
        console.log(error);
    };

    return (
        <div className="form-container panel-form">
            <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                <FormRow>
                    <p>If you have more than 4 exclusions, skip this section and add it from the detail page.</p>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Exclusion #1" size="two">
                        <input {...register("exclusion1")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #2" size="two">
                        <input {...register("exclusion2")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #3" size="two">
                        <input {...register("exclusion3")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                    <FormInputWrapper label="Exclusion #4" size="two">
                        <input {...register("exclusion4")} className="uk-input exclusion" type="text" />
                    </FormInputWrapper>
                </FormRow>
                <FormRow style={{ marginTop: "16px" }}>
                    <button type="submit" className='uk-button uk-button-primary pull-right'>Save</button>
                    <button type="button" className='uk-button uk-button-orange pull-right' onClick={() => closePanel(false)}>Close</button>
                </FormRow>
            </Form>
        </div>
    )
}

export default PanelFormExclusions;