import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  retrieveEDocs,
  findByName,
  deleteEDoc,
} from "../../redux/actions/edoc_templates";
import { Link, useRouteMatch } from "react-router-dom";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import datatableStyles from "../../utils/datatable-style";
import { DebounceInput } from "react-debounce-input";
import { toast } from "react-toastify";

const EDocsList = () => {
  const [searchName, setSearchName] = useState("");
  const dataEDocs = useSelector((state) => state.edocs.list);
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  useEffect(() => {
    dispatch(findByName(searchName));
  }, [searchName]);

  const onDelete = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const data = {
              deleted: true,
              deleted_at: new Date(),
            };
            dispatch(deleteEDoc(id))
              .then((response) => {
                dispatch(retrieveEDocs());
                toast.info("Delete successfully", {
                  autoClose: 2000,
                });
              })
              .catch((e) => {
                toast.error("Error has occured.", {
                  autoClose: 2000,
                });
              });
          },
        },
        {
          label: "No",
          onClick: () => {
            toast.error("Canceled", {
              autoClose: 2000,
            });
          },
        },
      ],
    });
  };

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>
              <Link to={{ pathname: `${path}/view`, state: { id: row.id } }}>
                {row.name}
              </Link>
            </div>
          </div>
        );
      },
    },
    {
      name: "Type",
      selector: "document_type",
      sortable: true,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>{row.document_type}</div>
          </div>
        );
      },
    },
    { name: "HelloSign ID", selector: "hellosign_template_id", sortable: true, center: false },
    {
      name: "",
      cell: (row) => {
        return (
          <div>
            <Link
              to={{ pathname: "/edoc-templates/edit", state: { doc_id: row.id } }}
            >
              <img
                src="/icons/edit.svg"
                className="uk-margin-right svg"
                alt=""
              />
            </Link>
            <img
              src="/icons/delete.svg"
              className="svg"
              onClick={() => onDelete(row.id)}
              alt=""
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="uk-container uk-container-expand uk-position-relative">
      <div className="content">
        <h1>eDocument Templates</h1>
        <div className="uk-column-1-2  uk-margin-small">
          <Link to={{ pathname: `${path}/add`, state: { doc_id: null } }}>
            <div className="uk-button uk-button-default add-deal">
              <span
                className="uk-margin-small-right uk-icon"
                data-uk-icon="plus"
              ></span>{" "}
              Add
            </div>
          </Link>
          <div className="list-page-search-container">
            <form className="uk-search uk-search-default">
              <DebounceInput
                className="uk-search-input"
                type="search"
                placeholder="Search"
                onChange={(e) => setSearchName(e.target.value)}
                value={searchName}
                debounceTimeout={500}
                minLength={1}
              />
              {searchName === "" ? (
                <span className="bd-form-icon">
                  <i className="fa fa-search"></i>
                </span>
              ) : (
                <span
                  className="bd-form-icon"
                  onClick={() => setSearchName("")}
                >
                  <i className="fa fa-times"></i>
                </span>
              )}
            </form>
          </div>
        </div>

        {/* EDocs List Table */}

        <DataTable
          title="Clients Table"
          columns={columns}
          data={dataEDocs}
          striped={true}
          highlightOnHover={true}
          pointerOnHover={true}
          center={true}
          noHeader={true}
          fixedHeader={true}
          pagination={dataEDocs.length > 10}
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 20, 50, 100]}
          customStyles={datatableStyles}
        />
      </div>
    </div>
  );
};

export default EDocsList;
