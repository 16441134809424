import React from 'react'
import { Helmet } from 'react-helmet'
import { Route, Switch ,useRouteMatch} from 'react-router-dom/cjs/react-router-dom.min'
import MainActivity from './MainActivity'

const ActivityLog = () => {
  let { path } = useRouteMatch();
  return (
    <>
    <Helmet><title>Activity Log - BoatDox</title></Helmet>
    <Switch>
      <Route exact path={path} component={MainActivity} />
    </Switch>
    </>
  )
}

export default ActivityLog