import { Link } from 'react-router-dom';
import ThemeButton from "./ThemeButton"

const LinkButton = ({ theme, to, children, ...props }) => {
  return (
    <Link to={to}>
      <ThemeButton theme={theme} {...props} >
        {children}
      </ThemeButton>
    </Link>
  );
}

export default LinkButton;
