import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { setLoadingStatus } from '../../redux/actions/loading';
import { tutorialsArray, tutorialsArrayDeals } from '../../utils/tutorials';
import { useDispatch } from 'react-redux';
import TutorialService from '../../services/TutorialService';
import { toast } from 'react-toastify';
import TutorialInput from './TutorialInput';

const schema = yup.object().shape(tutorialsArray.reduce((o, key) => Object.assign(o, { [key.value]: yup.string() }), {}));
const TutorialForm = () => {
    const dispatch = useDispatch()
    const { register, handleSubmit, reset, setValue } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true
    });

    useEffect(() => {
        dispatch(setLoadingStatus(true));
        TutorialService.getAll().then((res) => {
            dispatch(setLoadingStatus(false));
            for (const key in res.data) {
                if (Object.hasOwnProperty.call(res.data, key)) {
                    const element = res.data[key];
                    setValue(key, element || '');
                }
            }
        }).catch(err => {
            dispatch(setLoadingStatus(false));
        })

    }, [])


    const handleUpdate = (payload) => {
        dispatch(setLoadingStatus(true));

        TutorialService.updateTutorials(payload).then(response => {
            //   if (response.status === 401) {
            dispatch(setLoadingStatus(false));
            toast.success('Tutorial updated successfully.')
            return;
        }).catch((err) => {
            toast.error('Error while updating tutorial')
            dispatch(setLoadingStatus(false));
        })

    };
    const handleError = (errors) => {
        console.log("Errors = ", errors);
    };

    return (

        <div className="tm-main sign-in">
            <div className="uk-container uk-container-expand uk-position-relative">
                <div className="form-container uk-margin-small mt-3 ">
                    <form onSubmit={handleSubmit(handleUpdate, handleError)} className="uk-grid-small form" data-uk-grid id="addform" method="POST" onReset={reset}>
                        <h2 className=" uk-padding-small ml-4" >Tutorials</h2>

                        {tutorialsArray.map((tutorial) => {
                            return <div className=" row w-100">
                                <div className="px-3 uk-padding-small col-12 col-sm-4 col-md-3 col-xl-2">
                                    <label className="uk-form-label" htmlFor="name">{tutorial.title}</label>
                                </div>
                                <div className=" col-12 col-sm-8 col-md-9 col-xl-10">
                                    <TutorialInput tutorial={tutorial.value} register={register} setValue={setValue} />
                                </div>
                            </div>
                        })}
                        <div className="px-3 uk-padding-small col-12 col-sm-4 col-md-3 col-xl-2">
                            <label className="uk-form-label" htmlFor="name"></label>
                        </div>
                        <div className=" col-12 col-sm-8 col-md-9 col-xl-10 row">
                            <div className='col'>  <h4 className=" text-center" >Deal Module</h4></div>
                            <div className='col'><h4 className=" text-center" >Listing Agreement Module</h4></div>
                        </div>
                        {tutorialsArrayDeals.map((tutorial) => {
                            return <div className=" row w-100">
                                <div className="px-3 uk-padding-small col-12 col-sm-4 col-md-3 col-xl-2">
                                    <label className="uk-form-label" htmlFor="name">{tutorial.title}</label>
                                </div>
                                <div className="col-12 col-sm-8 col-md-9 col-xl-10 row">

                                    <div className='col'><TutorialInput tutorial={tutorial.deal_value} register={register} setValue={setValue} /> </div>
                                    <div className='col'><TutorialInput tutorial={tutorial.listing_agreements_value} register={register} setValue={setValue} /></div>


                                </div>

                            </div>
                        })}
                        <div className="row w-100 justify-content-end">
                            <div className="three-column uk-padding-small">
                                <input type="submit" className="uk-button iy-button-primary" value="Save" />
                            </div>
                        </div>

                    </form>
                </div>

            </div>
        </div >

    )
}

export default TutorialForm