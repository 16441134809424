import { useSelector } from "react-redux";
import Accordion from "../../../../common/Accordion";
import AccordionItem from "../../../../common/AccordionItem";
import ModalBody from "../../../../common/ModalBody";
import OffCanvasContainer from "../../../../common/OffCanvasContainer";
import DetailOfSeller from "../details/DetailOfSeller";
import DetailOfVessel from "../details/DetailOfVessel";
import DetailOfBuyer from "../details/DetailOfBuyer";
import DetailOfCoBuyer from "../details/DetailOfCoBuyer";
import DetailOfCoSeller from "../details/DetailOfCoSeller";
import DetailOfToys from "../details/DetailOfToys";
import DetailOfOffer from "../details/DetailOfOffer";
import DetailOfExclusions from "../details/DetailOfExclusions";
import DetailOfAddendum from "../details/DetailOfAddendum";
import DetailOfCoBroker from "../details/DetailOfCoBroker";
import DetailOfActivities from "../details/DetailOfActivities";

const ViewPurchaseAndSale = () => {
  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const dataDeal = dataTransaction || {};
  const dataOffer = dataDeal.t_offers;
  const dataExclusions = dataDeal.t_exclusion;
  const dataAmmendment = dataDeal.t_addendum;
  
  let activity = ((dataTransaction || {}).t_edocuments || [])
    .filter((t) => t.task_type === 'purchase-&-sale-agreement')
    .map((t) => {
      let signature = t.signature || {},
        template = t.template || {},
        mappings = signature.activity_mapping || [],
        activity = mappings.map((m) => m.activity || null).filter((a) => !!a);

      return activity.map((a) => {
        return {
          id: a.id,
          updated_at: a.updatedAt,
          event_type: a.event_type,
          names: (a.signatures || []).map((s) => `${s.signer_name} (${s.signer_role})`) || [],
          document: t.title || "",
        };
      });
    })
    .flat()
    .sort((a, b) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    });

  return (
    <OffCanvasContainer id="modal-view-purchase-sale-agreement">
      <h2>Purchase and Sale Agreement</h2>
      <ModalBody title="">
        <Accordion>
          <AccordionItem title="Vessel">
            <DetailOfVessel vessel={dataDeal.t_vessel} />
          </AccordionItem>
          <AccordionItem title="Buyer">
            <DetailOfBuyer buyer={dataDeal.buyer} />
          </AccordionItem>
          <AccordionItem title="Co-Buyer">
            <DetailOfCoBuyer cobuyer={dataDeal.co_buyer} />
          </AccordionItem>
          <AccordionItem title="Seller">
            <DetailOfSeller seller={dataDeal.seller} />
          </AccordionItem>
          <AccordionItem title="Co-Seller">
            <DetailOfCoSeller coseller={dataDeal.co_seller} />
          </AccordionItem>
          <AccordionItem title="Toys & Tenders">
            <DetailOfToys />
          </AccordionItem>
          <AccordionItem title="Offer">
            <DetailOfOffer offer={dataOffer} />
          </AccordionItem>
          <AccordionItem title="Exclusions">
            <DetailOfExclusions exclusions={dataExclusions} />
          </AccordionItem>
          <AccordionItem title="Addendum">
            <DetailOfAddendum addendum={dataAmmendment} />
          </AccordionItem>
          <AccordionItem title="Co-Broker & Commission">
            <DetailOfCoBroker cobroker={dataDeal.co_broker} />
          </AccordionItem>
          <AccordionItem title="E-Sign Activities">
            <DetailOfActivities activity={activity} />
          </AccordionItem>
        </Accordion>
      </ModalBody>
    </OffCanvasContainer>
  );
};

export default ViewPurchaseAndSale;
