import { useEffect, useCallback } from "react";
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { getDealsByStatus } from "../../../redux/actions/dashboard";
import { getPrice } from "../../../utils/currency";

const AnalyticDeals = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const stats = useSelector((state) => state.dashboard.dealsByStatus);

  const titles = {
    draft: "DRAFT",
    progress: "IN-PROCESS",
    cancelled: "CANCELLED",
    completed: "CLOSED",
    active: 'ACTIVE',
    rejected: "REJECTED",
  };

  useEffect(() => {
    dispatch(getDealsByStatus());
  }, []);

  const handleClickViewAll = useCallback(() => history.push('/deals'), []);

  return (
    <>
    <div className="uk-overflow-auto">
      <table className=" uk-table uk-table-small uk-table-divider">
        <thead>
          <tr>
            <th rowSpan="2">Status</th>
            <th rowSpan="2">Deals</th>
            <th rowSpan="2">Value</th>
            <th colSpan="2" className="value-commision">
              Commission
            </th>
          </tr>
          <tr>
            <th className="in-house">In-House</th>
            <th>Co-Brokerage</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(stats).map((key) => {
            return (
              <tr key={key}>
                <td>
                  <Link to={{pathname: "/deals", status: key}}>
                    {titles[key]}
                  </Link>
                </td>
                <td>{stats[key].count}</td>
                <td>{getPrice(stats[key].value)}</td>
                <td>{getPrice(stats[key].in_house)}</td>
                <td>{getPrice(stats[key].co_brokerage)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
      {Object.keys(stats).length !== 0 && (
        <div className="view-all">
          <a href="#" onClick={handleClickViewAll}>View All</a>
        </div>
      )}
    </>
  );
};

export default AnalyticDeals;
