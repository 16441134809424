import UserService from "../services/UserService";
import Auth from "./auth";
import { isParticipant } from "./permission";

/**
 * call API to create a log when the logged in user company does not match with any user from list
 * @param {*} data
 */
export const logUserCompanyMismatch = (data, moduleName, history) => {
  const logged_user = Auth.getInstance().getUserData();

  const logData = {
    loggedInUser: {
      user_id: logged_user.id,
      company_id: logged_user.company_id,
    },
    mismatchUser: {
      user_id: data.id,
      company_id: data.company_id,
    },
    module: moduleName,
  };

  !isParticipant() && UserService.addSecurityLog(logData)
    .then((response) => {
      Auth.getInstance().logout();
      history.push("/login");
    })
    .catch((error) => {
      console.log(error);
    });
};
