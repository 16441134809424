import ModalPortal from "./HelperTermModalPortal";

const HelperTerms = () => {
  return (
    <ModalPortal>
      <div className="uk-modal-container uk-modal-body uk-margin-auto-vertical">
        <button className="uk-modal-close-default" type="button" data-uk-close="true"></button>
        <p style={{ textAlign: "center" }}>
          <img src="/images/terms_section_17.png" />
        </p>
      </div>
    </ModalPortal>
  );
};

export default HelperTerms;
