import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from "react-redux";
import {
  Divider,
  Grid,
  TextField,
  Typography,
  createTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { switchUnstyledClasses } from '@mui/core/SwitchUnstyled';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {
  faCalendarDay,
  faUser,
  faAt,
  faBuilding,
  faBriefcase,
} from '@fortawesome/free-solid-svg-icons';

import { setLoadingStatus } from "../../../../redux/actions/loading";
import { parseDocumentTemplate } from '../../../../redux/actions/document_tasks';

import PDFViewer from '../../../common/PDFPrefillViewer/PDFPrefillViewer';
import { DocItem } from '../../../common/PDFPrefillViewer/drag-and-drop/DocItem';

const theme = createTheme({
  components: {
    // Name of the component
    MuiSelect: {
      styleOverrides: {
        // Name of the slot
        select: {
          // Some CSS
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
      },
    },
  },
});

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
  },
  pdf_box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
    position: 'relative',
    height: '600px',
    overflow: 'scroll'
  },
  box_button: {
    lineHeight: 1.25
  },
  left_bar: {
    padding: '15px'
  },
  right_bar: {
    padding: '15px'
  },
});

const Switch = styled('span')`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
  margin-top: 10px;
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: #b3c3d3;
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 14px;
    height: 14px;
    top: 3px;
    left: 3px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 14px;
      top: 3px;
      background-color: #fff;
    }

    .${switchUnstyledClasses.track} {
      background: #007fff;
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;

export const PlaceFieldsStep = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { file_name } = props.form;
  const file_link = `https://${process.env.REACT_APP_BUCKET_NAME}.s3.${process.env.REACT_APP_REGION}.amazonaws.com/additional-templates/${file_name}`;
  const label = { componentsProps: { input: { 'aria-label': 'switch' } } };

  const [viewMode, setViewMode] = useState('edit');
  const [mergeField, setMergeField] = useState('');
  const [isRequired, setRequired] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [currentElem, setCurrentElem] = useState(null);
  const [fieldData, setFieldData] = useState(props.form.elements);

  useEffect(() => {
    if (!props.form.template_id && !props.form.elements.length) {
      dispatch(setLoadingStatus(true));
      dispatch(parseDocumentTemplate(file_name)).then(res => {
        props.update('elements', res.fields);
        props.update('width', res.width);
        props.update('height', res.height);
        props.update('file_link', file_link);
        setFieldData(res.fields);
        dispatch(setLoadingStatus(false));
      }).catch(err => {
        dispatch(setLoadingStatus(false));
        console.log("Errors = ", err);
      });
    }
  }, []);

  useEffect(() => {
    if (currentId) {
      const element = fieldData.find(element => element.key === currentId);
      setCurrentElem(element);
      setMergeField(element.value);
      // setAssigner(element.assigner);

      if (element.required) {
        setRequired(element.required);
      }
    } else {
      setCurrentElem(null);
    }
  }, [currentId]);

  const handleChangeCoordination = (position) => {
    if (currentId) {
      const new_fields = fieldData.map(element => {
        if (element.key === currentId) {
          element.coord = position;
        }
        return element;
      });
      setFieldData(new_fields);
      props.update('elements', new_fields)
    }
  }

  const handleChangeViewMode = (event, newMode) => {
    setViewMode(newMode);
  };

  const handleChangeMergeField = (event) => {
    if (currentId) {
      setMergeField(event.target.value);
      const new_fields = fieldData.map(element => {
        if (element.key === currentId) {
          element.value = event.target.value;
        }
        return element;
      });
      setFieldData(new_fields);
      props.update('elements', new_fields);
    }
  }

  const handleFocusField = (event) => {
    setCurrentId(event.target.id);
  }

  const handleBlurField = (event) => {
    setCurrentId(null);
  }

  const handleKey = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.key === "Delete") {
      const new_fields = fieldData.filter(element => element.key !== currentId);
      setCurrentId(null);
      setFieldData(new_fields);
      props.update('elements', new_fields);
    } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      console.log(event);
    }
  }

  // const handleDrag = (monitor) => {
  //   if (monitor.getClientOffset() && monitor.getSourceClientOffset()) {
  //     console.log({
  //       x: monitor.getClientOffset().x - monitor.getSourceClientOffset().x,
  //       y: monitor.getClientOffset().y - monitor.getSourceClientOffset().y
  //     });
  //   }
  // }

  const handleAddElement = useCallback((element) => {
    const new_data = fieldData ? [...fieldData, element] : [element];
    setFieldData(new_data);
    props.update('elements', new_data);
  }, [fieldData]);

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={2}>
          <Box className={classes.box}>
            <ToggleButtonGroup
              color="primary"
              size="small"
              value={viewMode}
              exclusive
              onChange={handleChangeViewMode}
            >
              <ToggleButton value="edit">Edit</ToggleButton>
              <ToggleButton value="preview">Preview</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box className={classes.box}>
            Toolbar
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.box}>
            Toolbar
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={2}>
          <Box className={classes.left_bar}>
            {/* <Typography mb={1} mt={2}>Signature fields</Typography>
            <Box mb={1}>
              <DocItem
                name="Signature"
                type="signature"
                icon={faFileSignature}
              // handleDrag={(monitor) => handleDrag(monitor)}
              />
            </Box>
            <Box mb={1}>
              <DocItem
                name="Initials"
                type="initial"
                icon={faSignature}
              // handleDrag={(monitor) => handleDrag(monitor)}
              />
            </Box> */}

            <Typography mb={1} mt={2}>Auto-fill fields</Typography>
            {/* <Box mb={1}>
              <DocItem
                name="Date Signed"
                type="signature_date"
                icon={faCalendarDay}
              // handleDrag={(monitor) => handleDrag(monitor)}
              />
            </Box> */}
            <Box mb={1}>
              <DocItem
                name="Name"
                type="name"
                icon={faUser}
              // handleDrag={(monitor) => handleDrag(monitor)}
              />
            </Box>
            <Box mb={1}>
              <DocItem
                name="Email"
                type="email"
                icon={faAt}
              // handleDrag={(monitor) => handleDrag(monitor)}
              />
            </Box>
            <Box mb={1}>
              <DocItem
                name="Company"
                type="company"
                icon={faBuilding}
              // handleDrag={(monitor) => handleDrag(monitor)}
              />
            </Box>
            <Box mb={1}>
              <DocItem
                name="Tile"
                type="title"
                icon={faBriefcase}
              // handleDrag={(monitor) => handleDrag(monitor)}
              />
            </Box>

            <Typography mb={1} mt={2}>Standard fields</Typography>
            <Box mb={1}>
              <DocItem
                name="Textbox"
                type="textbox"
                icon={faCalendarDay}
              // handleDrag={(monitor) => handleDrag(monitor)}
              />
            </Box>
            <Box mb={1}>
              <DocItem
                name="Checkbox"
                type="checkbox"
                icon={faUser}
              // handleDrag={(monitor) => handleDrag(monitor)}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={8} sx={{ minWidth: '900px' }}>
          <Box className={classes.pdf_box}>
            <PDFViewer
              pdf={file_link}
              data={fieldData}
              current={currentElem}
              handleFocusField={handleFocusField}
              handleBlurField={handleBlurField}
              handleChangeCoordination={handleChangeCoordination}
              handleAddElement={handleAddElement}
              handleKey={handleKey}
              // roles={roles}
              // currentSigner={signer}
              width={props.form.width}
              height={props.form.height}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.left_bar}>
            {currentElem && (
              <>
                <Typography mb={2}>{currentElem.type}</Typography>
                <Divider sx={{ margin: '10px 0px 10px 0px' }} />
                <Typography mb={2}>Merge field *</Typography>
                <TextField size="small" onChange={handleChangeMergeField} value={mergeField} label="Field Name" variant="outlined" />
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </div >
  );
};