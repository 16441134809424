import {
  GET_DEALS_BY_BROKER,
  GET_DEALS_BY_CLOSED_YEAR_TO_DATE,
  GET_DEALS_BY_OFFICE,
  GET_DEALS_BY_STATUS,
  GET_DEALS_BY_UPCOMING_CLOSINGS,
  GET_LISTINGS_BY_BROKER,
  GET_LISTINGS_BY_OFFICE,
  GET_LISTINGS_BY_STATUS,
} from "../actions/types";

const initialState = {
  dealsByStatus: {},
  dealsByUpcomingClosings: {},
  dealsByClosedYearToDate: {},
  dealsByOffice: {},
  dealsByBroker: {},
  listingsByStatus: {},
  listingsByOffice: {},
  listingsByBroker: {},
};

const dashbaordReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DEALS_BY_STATUS:
      return { ...state, dealsByStatus: payload };
    case GET_DEALS_BY_UPCOMING_CLOSINGS:
      return { ...state, dealsByUpcomingClosings: payload };
    case GET_DEALS_BY_OFFICE:
      return { ...state, dealsByOffice: payload };
    case GET_DEALS_BY_BROKER:
      return { ...state, dealsByBroker: payload };
    case GET_DEALS_BY_CLOSED_YEAR_TO_DATE:
      return { ...state, dealsByClosedYearToDate: payload };
    case GET_LISTINGS_BY_STATUS:
      return { ...state, listingsByStatus: payload };
    case GET_LISTINGS_BY_OFFICE:
      return { ...state, listingsByOffice: payload };
    case GET_LISTINGS_BY_BROKER:
      return { ...state, listingsByBroker: payload };
    default:
      return state;
  }
};

export default dashbaordReducer;
