import TutorialService from "../../services/TutorialService";
import { GET_TUTORIALS } from "./types";


export const getTutorials = () => async (dispatch) => {
  try {
    const res = await TutorialService.getAll();
    dispatch({
      type: GET_TUTORIALS,
      payload: res.data,
    });
  } catch (err) {
    console.log("Error = ", err);
  }
};
