import {
  CREATE_ASSOCIATION,
  RETRIEVE_ASSOCIATIONS,
  GET_ASSOCIATION,
  UPDATE_ASSOCIATION,
  DELETE_ASSOCIATION,
  DELETE_ALL_ASSOCIATIONS,
  FIND_ASSOCIATION_BY_NAME,
} from "../actions/types";

const initialState = [];

function associationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_ASSOCIATION:
      return [...state, payload];
    case RETRIEVE_ASSOCIATIONS:
      return payload;
    case GET_ASSOCIATION:
      return payload;
    case UPDATE_ASSOCIATION:
      return state.map((association) => {
        if (association.id === payload.id) {
          return {
            ...association,
            ...payload,
          };
        } else {
          return association;
        }
      });
    case DELETE_ASSOCIATION:
      return state.filter(({ id }) => id !== payload.id);
    case DELETE_ALL_ASSOCIATIONS:
      return [];
    case FIND_ASSOCIATION_BY_NAME:
      return payload;
    default:
      return state;
  }
}

export default associationReducer;
