import ModalBody from "../../../../common/ModalBody";
import OffCanvasContainer from "../../../../common/OffCanvasContainer";
import DetailOfOffer from "../details/DetailOfOffer";

const ViewOfferForm = ({ offer }) => {
  return (
    <OffCanvasContainer id="modal-offer">
      <ModalBody title="Offer">
        <DetailOfOffer offer={offer} />
      </ModalBody>
    </OffCanvasContainer>
  );
};

export default ViewOfferForm;
