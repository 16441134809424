import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getTransactionEdocById, insertTaskToTransaction } from "../../../../../redux/actions/transaction";
import Auth from "../../../../../utils/auth";

import ModalPortal from "./AddFormTaskModalPortal";

const AddFormTask = ({ transactionId, checklistId, formList, data }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const logged_user = Auth.getInstance().getUserData();
  const [formId, setFormId] = useState(null);

  const handleAddForm = () => {
    if (formId) {
      const task = formList.find(form => form.id === formId);

      let newTask = {
        ...task,
        checklist_id: checklistId,
        transaction_id: transactionId,
        actionBy: logged_user.id,
        task_type: "form"
      };
      delete newTask.id;
      dispatch(insertTaskToTransaction(newTask))
        .then(() => {
          dispatch(getTransactionEdocById(id))
        })
      setFormId(null);
    }
  }

  return (
    <ModalPortal modalId="modal-add-form-task">
      <div className="uk-modal-dialog">
        <button className="uk-modal-close-default" type="button" data-uk-close="true"></button>
        <div className="uk-modal-header">
          <h3>Add Prefill Form</h3>
        </div>
        <div className="uk-modal-body">
          <div className="form-row">
            <div className="two-column uk-padding-small">
              <label className="uk-form-label">Document Name</label>
              <select className="uk-select" onChange={(evt) => setFormId(evt.target.value)}>
                <option defaultValue={null}>Select a prefill document</option>
                {formList.map(form => {
                  return (
                    <option value={form.id} key={form.id}>
                      {form.title}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="uk-modal-footer uk-text-right">
          <button className="uk-button uk-button-default uk-modal-close small" type="button">
            Cancel
          </button>
          <button
            className="uk-button uk-button-primary uk-modal-close small"
            type="button"
            onClick={handleAddForm}
            disabled={formId === null}
          >
            Done
          </button>
        </div>
      </div>
    </ModalPortal>
  );
};

export default AddFormTask;
