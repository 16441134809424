
import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import Auth from '../../utils/auth';

const PermissionRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={ props => {
      const logged_user = Auth.getInstance().getUserData();
      if (!logged_user) {
        return <Redirect to={{ pathname: '/auth/login', state: { from: props.location } }} />
      }

      if (roles && roles.indexOf(logged_user.UserInRole.UserRole.name) === -1) {
        return <Redirect to={{pathname: '/'}} />
      }

      return <Component {...props} />
    }}
  />
);


export default PermissionRoute;
