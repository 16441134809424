import { Switch, Route, Redirect, useRouteMatch, useParams } from "react-router-dom";
import AddTradeInVessel from "./steps/AddTradeInVessel";
import AddTradeInVesselEngine from "./steps/EsignTradeInVessel";
import TradeInStep from "./stepbars/TradeInStep";

const TradeInVesselOption = () => {
  let { path } = useRouteMatch();
  let { id } = useParams();

  return (
    <div>
      <TradeInStep />
      <Switch>
        <Route exact path={path} render={() => <AddTradeInVessel transactionId={id} />} />
        <Route path={`${path}/send_to_signer`} render={() => <AddTradeInVesselEngine transactionId={id} />} />
        <Redirect to={path} />
      </Switch>
    </div>
  );
};

export default TradeInVesselOption;
