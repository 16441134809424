import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { range } from "lodash";
import { DebounceInput } from "react-debounce-input";
import ListItem from "./layouts/ListItem";
import { DELETE_MODAL_MESSAGE, DELETE_MODAL_TITLE } from "../../../constants/Alert";
import {
  clearAllTransactions,
  getAllTransactions,
  updateTransaction,
  putAssociationForTransaction,
  getTransactionTasks
} from "../../../redux/actions/transaction";
import ThemeButton from "../../common/buttons/ThemeButton";
import { statusList } from "../../../constants/status";
import Select from 'react-select';

import { setLoadingStatus } from "../../../redux/actions/loading";
import { getUser, retrieveRelated } from "../../../redux/actions/users";
import Auth from "../../../utils/auth";
import "./listView.css";
import FormRow from "../../common/FormRow";
import { logUserCompanyMismatch } from "../../../utils/access";
import { findClientByName } from "../../../redux/actions/clients";
import TransactionService from "../../../services/TransactionService";
import VideoPlayerModal from "../../common/videoPlayerModal"
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Tooltip } from "@mui/material";
import TutorialService from "../../../services/TutorialService";
import { toast } from "react-toastify";
import { isCompanyAdmin } from "../../../utils/permission";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { getPrice } from "../../../utils/currency";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import moment from "moment";


const ListingAgreementListView = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null)
  const tutorials = useSelector((state) => state?.tutorials) || null;

  const dataTransaction = useSelector((state) => state.transactions.all);
  const isLoading = useSelector((state) => state.loading.status);
  const dataBroker = useSelector((state) => state.transactions.broker);
  const [pageNo, setPageNo] = useState(1);
  const [count, setCount] = useState(0);
  //State for storing boat manufactures dropdown
  const [boatManufacturers, setBoatManufacturers] = useState([]);
  const [boatClient, setBoatClient] = useState([]);
  const [search, setSearch] = useState({
    status: "all",
    sort: 'updatedAt'
  });
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedBrokers, setSelectedBrokers] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState([]);
  const [selectedBuyerSeller, setSelectedBuyerSeller] = useState([]);

  const logged_user = Auth.getInstance().getUserData();
  const user = useSelector((state) => state.users.logged_by);
  const dataClients = useSelector((state) => state.clients.list);

  useEffect(() => {
    dispatch(getUser(logged_user.id))
  }, [])

  //Remove null manufactures from all data manufactures
  const dataManuFecturers = useSelector((state) => {
    if (state.vessel_toys.mlsFilters.manufacturers) {
      return state.vessel_toys.mlsFilters.manufacturers || {}
    } else {
      return {}
    }
  });


  //UseEffect for setBoatManufactures with sorting
  useEffect(() => {
    if (dataManuFecturers) {
      setBoatManufacturers(Object.values(dataManuFecturers).sort().map((mfr) => { return { label: mfr, value: mfr } }));
    }
  }, [dataManuFecturers]);

  useEffect(() => {
    dispatch(setLoadingStatus(true));
    TransactionService.getCompanyClients(logged_user.company_id).then((response) => {
      const dataClients = response.data;
      setBoatClient(dataClients?.map(seller => {
        return {
          label: `${seller?.first_name} ${seller?.last_name}`,
          value: `${seller?.email}`
        }
      }));
      // dispatch(setLoadingStatus(false));
    }).catch((error) => {
      console.log(error);
      setBoatClient([]);
      dispatch(setLoadingStatus(false));
    })
  }, []);

  useEffect(() => {
    dispatch(retrieveRelated(user.id));
    dispatch(findClientByName());
  }, []);

  let brokerList = useSelector(state => state.users.related)
    .filter(item => item.company_id !== null)
    .filter(item => item.company_id == logged_user.company_id && item.is_broker == true)
    .filter(item => (
      ((item.UserInRole || {}).UserRole || {}).name || "" === "Agent" ||
      ((item.UserInRole || {}).UserRole || {}).name || "" === "Company Admin")
    )

  brokerList = brokerList.sort((a, b) => a.last_name < b.last_name ? -1 : 1).map((broker) => { return { label: `${broker.last_name} ${broker.first_name}`, value: broker.id } });

  const associationFieldNames = ['iyba', 'ybaa', 'cyba', 'myba', 'uscg', 'custom'];

  const dataListingAgreements = dataTransaction;
  // .sort((a, b) => {
  //   let a_date = a.t_terms?.contract_date;
  //   let b_date = b.t_terms?.contract_date;

  //   if (!a_date && !b_date) {
  //     return 1;
  //   }
  //   if (!a_date || !b_date) {
  //     return a_date ? 1 : -1;
  //   }
  //   if (a_date && b_date) {
  //     return new Date(a_date) > new Date(b_date) ? 1 : -1;
  //   }
  // });

  const [dataListing, setDataListing] = useState(dataListingAgreements);

  // useEffect(() => {
  //   setDataListing(dataListingAgreements);
  //   if (dataListingAgreements.length > 0) {
  //     getTransactionTaskList();
  //   }
  // }, [dataTransaction])

  const getTransactionTaskList = (transactionsData) => {
    dispatch(setLoadingStatus(true));
    const transactionIdList = transactionsData.map(deal => { return { transactionId: deal.id } });
    dispatch(getTransactionTasks({ transactions: transactionIdList })).then(
      response => {
        dispatch(setLoadingStatus(true));
        const tasks = response;
        const newListings = transactionsData.map(deal => {
          return {
            ...deal,
            t_task_master: tasks.filter(task => task.transaction_id == deal.id)[0].tasks
          }
        });
        setDataListing(newListings)
        dispatch(setLoadingStatus(false));
      }
    ).catch(() => {
      dispatch(setLoadingStatus(false));
    })
  }

  useEffect(() => {
    return () => {
      dispatch(clearAllTransactions());
    };
  }, []);

  const removeNullValues = (obj) => {
    for (const key in obj) {
      if (!obj[key]) {
        delete obj[key]
      }
    }
    return obj
  }

  useEffect(() => {
    getTransactions();
  }, [pageNo, search, dataBroker]);

  const getTransactions = () => {
    dispatch(setLoadingStatus(true));
    dispatch(getAllTransactions({ offset: (pageNo - 1) * 10, limit: 10, type: "listing", ...search }, dataBroker)).then((data) => {
      const transactions = data.data;
      for (let index = 0; index < transactions.length; index++) {
        const element = transactions[index];
        if (logged_user.UserInRole?.UserRole?.name != "Boatdox Admin" && logged_user.company_id != element.company_id) {
          logUserCompanyMismatch(element, 'Listing Agreements', history);
          break;
        }
      }
      if (data.count > 0) {
        getTransactionTaskList(transactions);
      } else {
        setDataListing(transactions);
        dispatch(setLoadingStatus(false));
      }
      setCount(data.count);
    });
  }

  const handleDelete = (id) => {
    const data = {
      deleted: true,
      deleted_at: new Date(),
    };
    dispatch(updateTransaction(id, data)).then(() => {
      getTransactions();
      toast.success('Listing-Agreement deleted successfully');
    }).catch((err) => {
      toast.error('Something went wrong while deleting');
    });
  };

  const onDelete = (id) => {
    confirmAlert({
      title: DELETE_MODAL_TITLE,
      message: DELETE_MODAL_MESSAGE,
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(id),
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const handleAdd = (association = defaultAssociation) => {
    dispatch(putAssociationForTransaction(association));
    setTimeout(() => {
      history.push("/listing-agreement/add/new/vessel");
    }, 200);
  };

  const handlePrev = () => {
    if (pageNo !== 1) {
      setPageNo(pageNo - 1);
    }
  };

  const handleNext = () => {
    if (pageNo !== Math.ceil(count / 10)) {
      setPageNo(pageNo + 1);
    }
  };

  const handleStatus = (statusList) => {
    setSelectedStatus(statusList)
    const statuses = `(${statusList.map(stat => `'${stat.value}'`).map(String).join(',')})`;
    setSearch((state) => {
      return {
        ...state,
        status: statusList.length ? statuses : 'all'
      }
    });
    setPageNo(1);
  }

  const handleSorting = (event) => {
    const sortField = event.target.value;
    setSearch((state) => {
      return {
        ...state,
        sort: sortField || ''
      }
    });
    setPageNo(1);
  }

  const handleBroker = (brokers) => {
    setSelectedBrokers(brokers);
    const brokerList = `(${brokers.map(stat => `'${stat.value}'`).map(String).join(',')})`;
    setSearch((state) => {
      return {
        ...state,
        broker: brokers.length ? brokerList : null
      }
    });
    setPageNo(1);
  }

  const handleManufacturer = (manufacturers) => {
    setSelectedManufacturer(manufacturers);
    const manufacturerList = `(${manufacturers.map(stat => `'${stat.value}'`).map(String).join(',')})`;
    setSearch((state) => {
      return {
        ...state,
        manufacturer: manufacturers.length ? manufacturerList : null
      }
    });
    setPageNo(1);
  }

  const handleSeller = (signers) => {
    setSelectedBuyerSeller(signers)
    const signerList = `(${signers.map(stat => `'${stat.value}'`).map(String).join(',')})`;
    setSearch((state) => {
      return {
        ...state,
        transaction_client_email: signers.length ? signerList : null
      }
    });
    setPageNo(1);
  }

  const handleYear = (inputYear) => {
    const year = inputYear.length >= 4 ? parseInt(inputYear) : null;
    setSearch((state) => {
      return {
        ...state,
        year: year
      }
    });
    setPageNo(1);
  }

  const handleUidSearch = (inputId) => {
    let uniqueId = inputId.replace(/([a-zA-Z]*)(\/|-)/, '');
    if (uniqueId && !uniqueId.includes('-')) {
      uniqueId = `${logged_user.company?.unique_company_id}-${uniqueId}`;
    }
    setSearch((state) => {
      return {
        ...state,
        'unique_transaction_id': uniqueId
      }
    });
    setPageNo(1);
  }

  var delay = (function () {
    var timer = 0;
    return function (callback, ms) {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };
  })();

  const hasUniqueAssociation = !dataBroker || (dataBroker && associationFieldNames.filter(fieldName => dataBroker.company[fieldName]).length === 1);
  const defaultAssociation = dataBroker && associationFieldNames.find(fieldName => dataBroker.company[fieldName])?.toUpperCase();

  useEffect(() => {
    setCurrentVideo(tutorials?.listing_agreements);
  }, [tutorials]);

  const onCloseVideoPlayerModal = () => {
    setShow(() => false);
  }



  const downloadExcel = () => {
    var exportData = [["Make", "Model", "Year", "Length (LOA)", "Vessel Name", "Final Price", "Sold Date", "Seller Name", "Seller Email", "Seller Phone"]];

    const getVessel = (data) => {
      const vessel = data.t_vessel
      return vessel;
    }

    const getAskPrice = (data) => {
      const offer = data.t_offers || {};
      return offer.purchase_price || "N/A";
    }


    const getSoldDate = (data) => {
      return moment(data.sold_date).format('MM/DD/YYYY') || "N/A";
    }


    const getSellerName = (data) => {
      const seller = data.seller
      let name = `${seller.first_name || ""} ${seller.last_name || ""
        }`;
      name = name === " " ? "N/A" : name;
      return name;
    }
    const getSellerEmail = (data) => {
      const seller = data.seller
      let email = `${seller.email || ""}`;
      email = email === " " ? "N/A" : email;
      return email;
    }

    const getSellerPhone = (data) => {
      const seller = data.seller
      let phone = `${seller.mobile_phone || ""}`;
      phone = phone === "" ? "N/A" : phone;
      return phone;
    }

    dispatch(setLoadingStatus(true))
    dispatch(getAllTransactions({ ...search, offset: 0, limit: count, type: "listing" }, dataBroker)).then((res) => {
      res?.data?.map((data) => {
        exportData = [...exportData, [
          getVessel(data).make,
          getVessel(data).model,
          getVessel(data).year,
          getVessel(data).length,
          getVessel(data).vessel_name,
          getAskPrice(data),
          getSoldDate(data),
          getSellerName(data),
          getSellerEmail(data),
          getSellerPhone(data),
        ]]
      }
      )
      const ws = XLSX.utils.aoa_to_sheet(exportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      saveAsExcel(excelBuffer, 'All-Listing-Agreements-Details.xlsx');
      dispatch(setLoadingStatus(false))
    }).catch(err => {
      dispatch(setLoadingStatus(false))
    });;
  };

  const saveAsExcel = (buffer, fileName) => {
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  };
  return (
    <div className="uk-container uk-container-expand uk-position-relative">
      <div className="content">
        <div className="d-flex align-items-center  justify-content-between">

          <h1 className="d-flex align-items-center gap-1">
            {currentVideo && <Tooltip title="Play Tutorial" arrow placement='top'  >
              <PlayCircleOutlineIcon fontSize="large" style={{ cursor: "pointer" }} className="mr-1" onClick={() => setShow(true)} />
            </Tooltip>}
            Listing Agreements
          </h1>

          {isCompanyAdmin() ? <div>
            <button className='btn btn-previous btn-sm' onClick={downloadExcel}>
              <div> <CloudDownloadIcon />  Export</div>
            </button>
          </div> : <></>}
        </div>
        <div className="uk-position-relative tab-nav">
          <div className="list-page-search-container listPageSearch">
            <form className="uk-search uk-search-default formUkSearch">
              <FormRow>
                <div className="d-flex flex-wrap ">
                  <div className="one-column uk-padding-small m-2 form_drop_down">
                    {/* <select className="uk-select" value={search.status} onChange={handleStatus}>
                        <option key={`status_default`} value="">Select a Status</option>
                        {statusList.map((status, idx) => (
                          <option key={`status_${idx}`} value={status.value}>{status.name}</option>
                        ))}
                      </select> */}
                    <Select
                      isMulti
                      isClearable={false}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                      maxMenuHeight={250}
                      options={statusList}
                      closeMenuOnSelect={false}
                      classNamePrefix="select"
                      placeholder="Select Status"
                      value={selectedStatus}
                      onChange={(value) => { handleStatus(value); }}
                    />
                  </div>
                  <div className="one-column uk-padding-small m-2 form_drop_down">
                    {/* <select className="uk-select" value={search.broker} onChange={handleBroker}>
                        <option key={`broker_default`} value="">Select a Broker</option>
                        <option key={Math.random()} value={user.id}>{`${user.last_name} ${user.first_name}`}</option>
                        {
                          brokerList.length > 0 &&
                          brokerList.map((data, idx) => (
                            <option key={`user_${idx}`} value={data.id}>
                              {`${data.last_name} ${data.first_name}`}
                            </option>
                          ))
                        }
                      </select> */}

                    <Select
                      isMulti
                      isClearable={false}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                      maxMenuHeight={300}
                      options={brokerList}
                      closeMenuOnSelect={false}
                      classNamePrefix="select"
                      placeholder="Select Brokers"
                      value={selectedBrokers}
                      onChange={(value) => { handleBroker(value); }}
                    />
                  </div>
                  <div className="one-column uk-padding-small m-2 form_drop_down">
                    {/* <select
                        className="uk-select"
                        onChange={handleManufacturer}
                        value={search.manufacturer}
                      >
                        <option key={`vessel_boat_category_default`} value="">Select a Manufacturer</option>
                        {boatManufacturers.map((boatMakeKey, idx) => (
                          <option key={`vessel_make_${idx}`} value={boatMakeKey}>{boatMakeKey}</option>
                        ))}
                      </select> */}

                    <Select
                      isMulti
                      isClearable={false}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                      maxMenuHeight={300}
                      options={boatManufacturers}
                      closeMenuOnSelect={false}
                      classNamePrefix="select"
                      placeholder="Select Manufacturers"
                      value={selectedManufacturer}
                      onChange={(value) => { handleManufacturer(value); }}
                    />
                  </div>
                  <div className="one-column uk-padding-small m-2 form_drop_down">
                    {/* <select
                        className="uk-select"
                        onChange={handleSeller}
                        value={search.seller}>
                        <option key={`default_client_type`} value="">Select a Seller/Buyer</option>
                        {boatClient?.map((seller, idx) => (
                          <option key={`seller_${idx}`} value={seller?.value}>{seller?.name}</option>
                        ))}
                      </select>
                      {search.transaction_client_email && <small className="text-success ml-2">{search.transaction_client_email}</small>} */}

                    <Select
                      isMulti
                      isClearable={false}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                      maxMenuHeight={300}
                      options={boatClient}
                      closeMenuOnSelect={false}
                      classNamePrefix="select"
                      placeholder="Select Seller/Buyer"
                      value={selectedBuyerSeller}
                      onChange={(value) => { handleSeller(value); }}
                    />
                  </div>

                  <div className="one-column uk-padding-small m-2 form_drop_down">
                    <input className="uk-input" type="text" onInput={(e) => e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')} onChange={(e) => {
                      delay(function () {
                        handleYear(e.target.value)
                      }, 1500);
                    }} placeholder="Enter Year" />
                  </div>
                  <div className="one-column uk-padding-small m-2 form_drop_down">
                    <input className="uk-input" type="text" onChange={(e) => {
                      delay(function () {
                        handleUidSearch(e.target.value)
                      }, 2000);
                    }} placeholder="Enter UID" />
                    <span style={{ fontSize: '11px', fontStyle: 'italic', fontWeight: 'lighter' }} className="ml-2 uk-text-muted">Ex: LA-0-00</span>
                  </div>
                </div>
              </FormRow>
            </form>
          </div>
          {(user && (user.access_type && user.access_type == "full") || ["Company Admin", "Boatdox Admin"].includes(user.UserInRole.UserRole.name)) &&
            <div className="add-button">
              <button
                className={`uk-button uk-button-${!dataBroker ? "default" : "primary"}  align-items-center d-flex gap-1 my-2`}
                disabled={!dataBroker}
                onClick={() => handleAdd()}
              >
                <span className="uk-margin-small-right uk-icon" data-uk-icon="plus"></span>&nbsp;<span>Add</span>
              </button>
            </div>
          }
        </div>

        <div className="uk-overflow-auto mb-4">
          {dataListing.length > 0 && (
            <table className="uk-table listing-agreements">
              <thead>
                <tr>
                  <th></th>
                  <th>Vessel</th>
                  <th>Parties</th>
                  {/* <th>e-Sign Status</th> */}
                  <th>Uploads</th>
                  <th>Tasks</th>
                  <th className="listingEdocTableHeader">eDOCUMENTS</th>
                  <th style={{ paddingBottom: "4px" }}>
                    <select className="uk-select" style={{ height: '30px' }} value={search.sort} onChange={handleSorting}>
                      <option value="updatedAt">UPDATED</option>
                      <option value="createdAt">CREATED</option>
                    </select>
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataListing
                  .filter(indexListingAgreement => {
                    if (!dataBroker) return true;
                    if (['Company Admin', 'Boatdox Admin'].indexOf(dataBroker.UserInRole.UserRole.name) > -1) return true;
                    if (logged_user.id == dataBroker.id) {
                      return true;
                    }
                    return indexListingAgreement && indexListingAgreement.broker && (indexListingAgreement.broker.first_name === dataBroker.first_name) && (indexListingAgreement.broker.last_name === dataBroker.last_name);
                  })
                  .map((listing) => (
                    <ListItem
                      key={listing.id}
                      user={user}
                      listing={listing}
                      onDelete={onDelete}
                      onRefreshList={() => getTransactions()}
                    />
                  ))}
              </tbody>
            </table>
          )}
          {dataListing.length === 0 && (
            <div className="no-record-container">
              {isLoading
                ? <p className="no-record">Fetching Records...</p>
                : <p className="no-record">There are no records to display.</p>}
            </div>
          )}
          {count > 10 && (
            <div
              style={{
                marginBottom: "24px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ThemeButton onClick={handlePrev}>Prev</ThemeButton>
              <select value={pageNo} onChange={(e) => setPageNo(parseInt(e.target.value))}>
                {range(1, Math.ceil(count / 10) + 1).map((no) => {
                  return (
                    <option value={no} key={no}>
                      {no}/{Math.ceil(count / 10)}
                    </option>
                  );
                })}
              </select>
              <ThemeButton onClick={handleNext}>Next</ThemeButton>
            </div>
          )}
        </div>
      </div>
      {show && <VideoPlayerModal show={show} onClose={onCloseVideoPlayerModal} videoTitle={currentVideo} />}
    </div>
  );
};

export default ListingAgreementListView;
