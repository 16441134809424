import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { object } from "yup";

import Form from "../../../../common/Form";
import FormPage from "../../../../common/FormPage";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import Column from "../../../../common/Column";
import SubmitButton from "../../../../common/buttons/SubmitButton";
import LinkButton from "../../../../common/buttons/LinkButton";

import {
  getTransactionEsignById,
  upsertTransaction,
} from "../../../../../redux/actions/transaction";
import { getFigure, getPrice } from "../../../../../utils/currency";
import { getPlainTransactionData } from "../../../../../utils/stepFilter";
import { useQueryParams } from "../../../../../utils/query-params";
import TransactionService from "../../../../../services/TransactionService";
import { toast } from "react-toastify";
import { setLoadingStatus } from "../../../../../redux/actions/loading";

const schema = object().shape({});

const AddVesselAcceptance = ({ transactionId }) => {

  const dispatch = useDispatch();
  let history = useHistory();
  const query = useQueryParams();

  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const dataDeal = dataTransaction || {};
  let dataAcceptance = {};

  const doc_id = query.get('doc_id');

  useEffect(() => {
    if (doc_id) {
      fetchAcceptanceData(doc_id);
    }
  }, [doc_id]);

  const fetchAcceptanceData = async (doc_id) => {
    try {
      dispatch(setLoadingStatus(true));
      const { data } = await TransactionService.getAcceptanceDoc(doc_id);
      dataAcceptance = data?.data || {};
      Object.keys(dataAcceptance).forEach((key) => {
        if (key === "adjustment_amount") {
          setValue(key, getPrice(dataAcceptance[key]));
        } else {
          setValue(key, dataAcceptance[key]);
        }
      });

      setValue('status', dataAcceptance['status']?.toString());
      dispatch(setLoadingStatus(false));
    } catch (error) {
      dispatch(setLoadingStatus(false));
      toast.error("Error occurs, While fetching data.", {
        autoClose: 2000
      })
    }
  }

  const [disableList, setDisableList] = useState({
    days_to_complete_conditions: true,
    adjustment_amount: true,
    condition_1: true,
    condition_2: true,
    reason_for_rejection: true,
  });
  const [status, setStatus] = useState(null);
  const [boolIsYBAA, setIsYBAA] = useState(true);

  const titles = [
    "Acceptance of Vessel",
    "Conditional Acceptance of Vessel",
    "Conditional Acceptance with Survey Allowance of Vessel",
    "Rejection of Vessel",
  ];

  const { register, handleSubmit, setValue, getValues, watch } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  // useEffect(() => {
  //   if (dataAcceptance) {
  //     Object.keys(dataAcceptance).forEach((key) => {
  //       if (key === "adjustment_amount") {
  //         setValue(key, getPrice(dataAcceptance[key]));
  //       } else {
  //         setValue(key, dataAcceptance[key]);
  //       }
  //     });

  //     setValue('status', dataAcceptance['status']?.toString());
  //   }
  // }, [dataAcceptance]);

  const keys = [
    "acceptace_date",
    "days_to_complete_conditions",
    "adjustment_amount",
    "condition_1",
    "condition_2",
    "reason_for_rejection",
    "additional_terms",
  ];

  const formatter = () => {
    if (!dataAcceptance) {
      keys.forEach((key) => setValue(key, null));
    }
  };

  useEffect(() => {
    formatter();
    const status = parseInt(getValues("status"));
    setStatus(status);
    switch (status) {
      case 0:
        setDisableList({
          days_to_complete_conditions: true,
          adjustment_amount: true,
          condition_1: true,
          condition_2: true,
          reason_for_rejection: true,
          additional_terms: true,
        });
        break;
      case 1:
        setDisableList({
          days_to_complete_conditions: false,
          adjustment_amount: true,
          condition_1: false,
          condition_2: false,
          reason_for_rejection: true,
          additional_terms: true,
        });
        break;
      case 2:
        setDisableList({
          days_to_complete_conditions: false,
          adjustment_amount: false,
          condition_1: false,
          condition_2: false,
          reason_for_rejection: true,
          additional_terms: true,
        });
        break;
      case 3:
        setDisableList({
          days_to_complete_conditions: true,
          adjustment_amount: true,
          condition_1: true,
          condition_2: true,
          reason_for_rejection: false,
          additional_terms: false,
        });
      default:
        break;
    }
  }, [watch("status")]);

  const handleBlur = () => {
    const price = getPrice(getValues("adjustment_amount"));
    setValue("adjustment_amount", price);
  };

  const handleFocus = () => {
    const price = getFigure(getValues("adjustment_amount"));
    setValue("adjustment_amount", price);
  };

  const handleRegistration = (acceptance) => {
    if (acceptance.status == 0) {
      acceptance.acceptace_date = new Date();
    }
    acceptance.adjustment_amount = getFigure(acceptance.adjustment_amount);
    Object.keys(acceptance).forEach((key) => {
      if (disableList[key]) {
        acceptance[key] = null;
      }
      if (!acceptance[key]) {
        acceptance[key] = null;
      }
    });

    if (doc_id) {
      acceptance['id'] = doc_id;
    }

    dispatch(
      upsertTransaction({
        step: {
          db: "acceptance",
          main: acceptance,
        },
        transaction: {
          isBelonged: false,
          main: { ...getPlainTransactionData(dataDeal), task_type: "deal" },
        },
      },"Acceptance's")
    ).then((data) => {
      dispatch(getTransactionEsignById(data.id));
    });
    history.push(`/deals/view/${transactionId}/acceptance-of-vessel?doc_id=${query.get('doc_id')}`);
  };

  const handleError = () => { };

  const links = [
    { label: "Deals", link: `/deals/view/${transactionId}/main` },
    { label: status ? titles[status] : "Acceptance of Vessel" },
  ];

  const vesselName = `${dataTransaction?.t_vessel?.year || ""} ${dataTransaction?.t_vessel?.make || ""} ${dataTransaction?.t_vessel?.model || ""} ${dataTransaction?.t_vessel?.length || ""}`;
  const uniqueTransactionId = dataTransaction?.unique_transaction_id ? `PS-${dataTransaction?.unique_transaction_id}` : '';

  return (
    <FormPage links={links} title={status ? titles[status] : "Acceptance of Vessel"} vesselName={vesselName} uniqueId={uniqueTransactionId}>
      <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
        <FormRow>
          <Column size="three">
            <div className="radio-toolbar">
              <input type="radio" value={0} {...register("status")} />
              <label className="uk-form-label radio">Accept</label>
              <input type="radio" value={1} {...register("status")} />
              <label className="uk-form-label radio">Conditional Accept</label>
              <input type="radio" value={2} {...register("status")} />
              <label className="uk-form-label radio">Conditional Acceptance with Survey Allowance</label>
              <input type="radio" value={3} {...register("status")} />
              <label className="uk-form-label radio">Reject</label>
            </div>
          </Column>
        </FormRow>
        {(!disableList.days_to_complete_conditions && status !== 2) && (
          <FormRow>
            <FormInputWrapper label="Days to complete conditions">
              <input
                className="uk-input small"
                type="text"
                {...register("days_to_complete_conditions")}
              />
            </FormInputWrapper>
          </FormRow>
        )}
        {!disableList.adjustment_amount && (
          <FormRow>
            <FormInputWrapper label={status === 2 ? "Adjustment Amount $ *" : "Adjustment Amount $"}>
              <input
                className="uk-input small"
                type="text"
                {...register("adjustment_amount")}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
            </FormInputWrapper>
          </FormRow>
        )}
        {!disableList.condition_1 && (
          <FormRow>
            <FormInputWrapper size="two" label={status === 2 ? "Condition 1 *" : "Condition 1"}>
              <textarea
                className="uk-textarea"
                {...register("condition_1")}
                rows="4"
                cols="50"
              ></textarea>
            </FormInputWrapper>
          </FormRow>
        )}
        {!disableList.condition_2 && (
          <FormRow>
            <FormInputWrapper size="two" label="Condition 2">
              <textarea
                className="uk-textarea"
                {...register("condition_2")}
                rows="4"
                cols="50"
              ></textarea>
            </FormInputWrapper>
          </FormRow>
        )}
        {!disableList.reason_for_rejection && (
          <>
            <FormRow>
              <FormInputWrapper size="two" label="Reason for Rejection *">
                <textarea
                  className="uk-textarea"
                  {...register("reason_for_rejection")}
                  rows="4"
                  cols="50"
                ></textarea>
              </FormInputWrapper>
            </FormRow>
            <FormRow>
              <FormInputWrapper size="two" label="Additional Terms">
                <textarea
                  className="uk-textarea"
                  {...register("additional_terms")}
                  rows="4"
                  cols="50"
                />
              </FormInputWrapper>
            </FormRow>
          </>
        )}
        <FormRow>
          <SubmitButton>Save & Continue</SubmitButton>
          <LinkButton
            to={`/deals/view/${transactionId}/main`}
            onClick={() => handleRegistration(getValues())}
          >
            Save & Finish Later
          </LinkButton>
        </FormRow>
      </Form>
    </FormPage>
  );
};

export default AddVesselAcceptance;
