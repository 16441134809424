import { useRef, useState } from "react";
import { Events } from "../../../utils/events";
import datatableStyles from "../../../utils/datatable-style";
import DataTable from "react-data-table-component";
import { useEffect } from "react";
import { Tooltip } from "@mui/material";
import TransactionService from "../../../services/TransactionService";
import { useSelector } from "react-redux";
import moment from "moment";
import Popover from "react-bootstrap/Popover"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import { getPrice } from "../../../utils/currency";

const Activities = ({ transactionId }) => {

  const deal = useSelector((state) => state.transactions.transaction);
  const [activities, setActivities] = useState([]);
  const [isAccordionOpen, setIsAccordionOpen] = useState(true)

  const getTransactionActivities = async () => {
    let activity = [];
    let signerActivity = [];
    let documentActivities = {};

    documentActivities = await TransactionService.getSignerActivities(transactionId).then(
      (response) => {
        return response.data;
      }).catch((error) => {
        console.log(error);
        return [];
      });

    for (const activityObj of documentActivities.t_activity || []) {
      if (activityObj.user_event) {
        activity.push({
          id: activityObj.id,
          updated_at: activityObj.updatedAt,
          event_type: activityObj.activity_type,
          name: `${activityObj.user_event?.first_name} ${activityObj.user_event?.last_name}`,
          document: activityObj.document_title || "",
          document_link: activityObj.document_link,
          note: activityObj.note || null
        });
      }
    }

    signerActivity = await (documentActivities?.t_edocuments || [])
      .filter(t => t.request_signature)
      .map(t => {
        const signers = t.request_signature.signers || [],
          activities = signers.map(s => {
            const signer_activities = s.activities || [];
            return signer_activities.map(a => {
              return {
                id: a.id,
                updated_at: a.updatedAt,
                event_type: a.activity_type,
                name: s.request_signer.name == "  " ? s.request_signer.email_address : s.request_signer.name,
                document: t.title || "",
                document_link: a.document_link,
                note: a.note
              }
            })
          });
        return activities;
      })
      .flat(2)
      .sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });

    setActivities([...signerActivity, ...activity])
  }

  useEffect(() => {
    if (transactionId) {
      getTransactionActivities();
    }
  }, [deal]);

  const sortedActivity = activities.flat(2)
    .sort((a, b) => {
      return new Date(b.isPrefillDocument ? b.updatedAt : b.updated_at) - new Date(a.isPrefillDocument ? a.updatedAt : a.updated_at);
    });

  /**
   * get content of note and return the parsed price and terms
   * @param {*} note 
   * @returns 
   */
  const getNoteContent = (note) => {
    if (note && typeof note == "string") {
      note = JSON.parse(note);
      return <>
        <p className="mb-0"><b>Purchase Price changed to:</b> </p>
        <p>{getPrice(note.price)}</p>
        <p className="mb-0"><b>Additional Terms changed to:</b> </p>
        <p>{note.terms ? '"' + note.terms + '"' : ''}</p>
      </>
    }
    return <p>Data not available</p>;
  }

  const columns = [
    {
      name: "Date",
      selector: "updatedAt",
      sortable: true,
      center: false,
      cell: (row) => {
        const timezone = moment.tz(moment.tz.guess()).zoneAbbr();
        const logDate = `${moment(row.updated_at).format('MM-DD-YYYY hh:mm A')} ${timezone}`;
        return (
          <div data-tag="allowRowEvents">
            <div>
              {logDate}
            </div>
          </div>
        );
      },
    },
    {
      name: "Action",
      selector: "event_type",
      sortable: true,
      center: false,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>{row.isPrefillDocument ? Events[row.activity_type] : Events[row.event_type]}</div>
          </div>
        );
      },
    },
    {
      name: "Activity",
      selector: "document",
      sortable: true,
      center: false,
      width: '25rem',
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>
              {row.isPrefillDocument ? row.document_title : row.document}
              {row.document_link &&
                <Tooltip className='ml-2' title="View Document" placement="top">
                  <a target="blank"
                    href={['reset_document', 'reset_and_counter_offer'].includes(row.event_type)
                      ? `https://${process.env.REACT_APP_FORMS_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}reset-documents/${row.document_link}`
                      : `https://${process.env.REACT_APP_PDF_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}pdf-documents/${row.document_link}`
                    } >
                    <img src="/icons/view.svg" className="svg" />
                  </a>
                </Tooltip>
              }
              {row.event_type == 'signature_request_declined' &&
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="right"
                  overlay={
                    <Popover id={`popover-positioned-`}>
                      <Popover.Title as="h6">Reason</Popover.Title>
                      <Popover.Content>{row.note}</Popover.Content>
                    </Popover>
                  }>
                  <img src="/icons/comment.svg" alt="comment" className="ml-1" />
                </OverlayTrigger>
              }
              {(['IYBA Purchase & Sale - Make Offer'].includes(row.document) && row.event_type == 'signature_request_signed') &&
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="right"
                  overlay={
                    <Popover id={`popover-positioned-`}>
                      <Popover.Content>{getNoteContent(row.note)}</Popover.Content>
                    </Popover>
                  }>
                  <img src="/icons/comment.svg" alt="comment" className="ml-1" />
                </OverlayTrigger>
              }
            </div>
          </div>
        );
      },
    },
    {
      name: "User / Signee",
      // center: false,
      // textAlign: "right",
      selector: "name",
      sortable: true,
      center: false,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>{row.isPrefillDocument && row.user_event ? `${row.user_event.first_name} ${row.user_event.last_name}` : row.name}</div>
          </div>
        );
      },
    },
  ];


  return (
    <ul data-uk-accordion="true">
      <li className='uk-open mb-4'>
        <a className="uk-accordion-title activities" onClick={() => { setIsAccordionOpen(prev => !prev) }}> <span>Activities ({(sortedActivity || []).length})</span> <span className="pull-right font-weight-normal text-uppercase " style={{ paddingTop: '4px', cursor: 'pointer' }}>{isAccordionOpen ? 'Close' : 'Open'}</span></a>
        <div className="uk-accordion-content">

          <DataTable
            columns={columns}
            data={sortedActivity}
            striped={true}
            highlightOnHover={true}
            pointerOnHover={true}
            noHeader={true}
            fixedHeader={true}
            pagination={sortedActivity.length > 10}
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 20, 50, 100]}
            customStyles={datatableStyles}
          />

          {/* <table className="uk-table uk-table-hover uk-table-middle uk-table-divider">
            <thead>
              <tr>
                <th>Date</th>
                <th>Action</th>
                <th>EDocument</th>
                <th>Signee</th>
              </tr>
            </thead>
            <tbody>
              {props.activity.map((activity) => {
                return (
                  <tr key={activity.id}>
                    <td><Moment date={activity.updated_at} format="MM-DD-YYYY hh:mm A" /></td>
                    <td>{Events[activity.event_type]}</td>
                    <td>{activity.document}</td>
                    <td>{activity.name}</td>
                  </tr>
                )
              })}
            </tbody>
          </table> */}
        </div>
      </li>
    </ul>
  )
}

export default Activities;
