import React, { useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";

import { setLoadingStatus } from '../../redux/actions/loading';
import UserService from '../../services/UserService';
import { Tooltip } from '@mui/material';
import datatableStyles from '../../utils/datatable-style';
import { toast } from 'react-toastify';

const AssociateCompanyForParticipantModal = ({ show, onClose, participant }) => {

    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.loading.status);

    const [associateCompanyList, setAssociateCompanyList] = useState([]);

    useEffect(() => {
        if (show) {
            fetchAssociatedCompanies();
        }
    }, [show]);

    const fetchAssociatedCompanies = () => {
        dispatch(setLoadingStatus(true));
        UserService.getParticipantCompanies(participant.id).then((response) => {
            dispatch(setLoadingStatus(false));
            setAssociateCompanyList(response.data);
        }).catch((error) => {
            console.log(error);
            toast.error('Something went wrong while fetching associated companies.')
            dispatch(setLoadingStatus(false));
        });
    }


    /**
     * Delete a Participant user
     * @param {*} id 
     */
    const handelDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to remove this Participant.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(setLoadingStatus(true));
                UserService.deleteParticipant(id).then(response => {
                    toast.success('Participant successfully deleted.');
                    dispatch(setLoadingStatus(false));
                    fetchAssociatedCompanies();
                }
                ).catch(error => {
                    dispatch(setLoadingStatus(false));
                    console.error(error);
                });
            }
        })
    }

    const columns = [
        {
            name: "Company Name",
            padding: 0,
            cell: (row) => (
                row.company?.company_name
            ),
        },
        {
            name: "Associate Deals",
            center: true,
            cell: (row) => (
                row.company?.transaction_participants?.length || 0
            ),
        },
        {
            name: "Action",
            center: true,
            cell: (row) => (
                <Tooltip title="Delete Participant" placement="right">
                    <a onClick={() => { handelDelete(row.id) }}>
                        <img
                            src="/icons/delete.svg"
                            className="svg"
                            alt=""
                        />
                    </a>
                </Tooltip>
            ),
        }
    ]

    return (
        <Modal
            show={show}
            onHide={() => onClose()}
            centered={true}
            size='lg'>
            <Modal.Header closeButton>
                <h3>Associated Companies</h3>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <h5>
                            <b><img height='25px' width='25px' src="/icons/user.svg" alt="" />&nbsp;{(participant?.first_name || '') + ' ' + (participant?.last_name || '')}</b>
                        </h5>
                        <div className="uk-padding-small">
                            <p>
                                <span style={{ width: '21px', paddingLeft: '3px' }}><img height='20px' width='20px' src="/icons/email.svg" alt="" /></span>&nbsp;{participant.email}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-12 p-3 px-0">
                        <h2>
                            <span className='uk-form-label'>Associated Companies</span>
                        </h2>
                        <div className='modal-table'>
                            {!associateCompanyList.length && isLoading
                                ? <div className="no-data-table"><div className="p-4">Fetching Details...</div></div>
                                : <DataTable
                                    columns={columns}
                                    data={associateCompanyList}
                                    highlightOnHover={true}
                                    striped={true}
                                    noHeader={true}
                                    customStyles={datatableStyles}
                                />
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="uk-button uk-button-default uk-modal-close small" type="button" onClick={onClose}>
                    Cancel
                </button>
                <button className="uk-button uk-button-primary small" type="submit">Save</button>
            </Modal.Footer>
        </Modal>
    )
}

export default AssociateCompanyForParticipantModal;