import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { confirmAlert } from "react-confirm-alert";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { makeStyles } from "@mui/styles";
import { Divider } from "@mui/material";

import FormPage from "../../../../common/FormPage";
import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import AsterixTypo from "../../../../common/AsterixTypo";
import Country from "../../../../../constants/Country";
import StatesForUSA from "../../../../../constants/StatesForUSA";
import {
  getListingSeller,
  getTransactionInitialById,
  upsertTransaction,
} from "../../../../../redux/actions/transaction";
import PhoneInput from "react-phone-input-2";
import { getClient, clearClient, findClientByName, getTransactionClient } from "../../../../../redux/actions/clients";
import { getFormattedData, getPlainTransactionData } from "../../../../../utils/stepFilter";
import AutoCompleteSearch from "../../../../common/Autocomplete";

// import { phoneFigure, phoneNumber } from "../../../../../utils/phonenumber";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import { JOINT_OWNER, SOLE_OWNER, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED, CLIENT_REQUIRED_FIELDS } from "../../../../../utils/signer";
import { getFigure, getPrice } from "../../../../../utils/currency";
import Auth from "../../../../../utils/auth";
import clsx from "clsx";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const MAX_JOINT_OWNERS = 4;
const MAX_BENEFICIAL_OWNERS = 4;


const useStyles = makeStyles({
  divider: {
    marginBottom: "15px",
  },
  phone_input: {
    padding: '12px 12px 12px 50px !important',
    width: '100% !important'
  },
  phoneInputDiv: {
    padding: "8px",
    marginLeft: "-7px",
    marginRight: "7px",
    marginTop: "-7px"
  }
});

const schema = yup.object().shape({
  // trust: yup.object().when(['seller_type'], ownerType => {
  //   if (ownerType && ownerType !== 'none') {
  //     return yup.object({
  //       trust_name: yup.string().required(),
  //       trust_tax_id: yup.string().required(),
  //       trust_address: yup.string().required(),
  //       trust_city: yup.string().required(),
  //       trust_state: yup.string().required(),
  //       trust_signer_name: yup.string().required(),
  //       trust_signer_email: yup.string().email().required(),
  //       trust_signer_title: yup.string().required(),
  //     }).required()
  //   }
  // })
});

const AddSeller = ({ step, setStep, asterix }) => {
  const classes = useStyles();
  const { id } = useParams();

  const [isSelectedUSA, setSelectedUSA] = useState(true);
  const [isSelectedUSA2, setSelectedUSA2] = useState(true);

  const [_client, _setClient] = useState({});
  const [_seller, _setSeller] = useState({});

  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);
  const dataListing = dataTransaction || {};
  const dataClient = useSelector((state) => state.clients.client);
  const listingSeller = useSelector(
    (state) => (state.transactions.transaction || {}).listing_seller || {}
  );
  const dataClients = useSelector((state) => state.clients.list);

  // const [numberOfJointOwners, setNumberOfJointOwners] = useState(1);
  const [numberOfBeneficialOwners, setNumberOfBeneficialOwners] = useState(1);

  const [isEditSeller, setIsEditSeller] = useState(false);
  const logged_user = Auth.getInstance().getUserData();

  // State for save MLS checkbox
  const [isGlobal, setIsGlobal] = useState(false);

  const [requiredFields, setRequiredFields] = useState(CLIENT_REQUIRED_FIELDS);

  const optionsForAutocomplete = dataClients
    .map((client) => {
      return { id: client.id, value: `${client.first_name} ${client.last_name}` };
    })
    .sort((prev, next) => prev.value.localeCompare(next.value))
    .reduce((newArray, cur) => newArray.length && newArray.at(-1).value === cur.value ? newArray : [...newArray, cur], []);

  const [isPoa, setIsPoa] = useState(listingSeller.is_poa_received || false);
  const [isGood, setIsGood] = useState(listingSeller.is_good_standing || false);
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, setValue, getValues, reset, watch } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  useEffect(() => {
    if (dataListing && dataListing.seller_contact_id) {
      dispatch(getTransactionClient(dataListing.seller_contact_id));
      dispatch(getListingSeller(dataListing.id, dataListing.seller_contact_id));
      setIsEditSeller(true);
    } else {
      setValue("client.country", "USA");
      setSelectedUSA(true);
      setIsEditSeller(false);
    }
  }, []);

  useEffect(() => {
    setValue('seller_type', dataTransaction?.seller_type || SOLE_OWNER);
  }, [dataTransaction]);

  useEffect(() => {
    dispatch(findClientByName());

    return () => {
      dispatch(clearClient());
    };
  }, []);

  useEffect(() => {
    if (!!dataClient) {

      // setNumberOfJointOwners(Number(dataClient["number_of_joint_owners"]) || 1);
      setNumberOfBeneficialOwners(Number(dataClient["number_of_beneficial_owners"]) || 1);

      // setValue("client.number_of_joint_owners", Number(dataClient["number_of_joint_owners"]) || 1);
      setValue("client.number_of_beneficial_owners", Number(dataClient["number_of_beneficial_owners"]) || 1);


      Object.keys(dataClient).forEach((key) => {
        if (key.indexOf('trust') === 0) {
          setValue(`trust.${key}`, dataClient[key]);
        }
        // else if (key === "joint_owners") {
        //   let jointOwners = JSON.parse(dataClient[key] || "{}");
        //   Object.keys(jointOwners).forEach((key, index) => {
        //     //fill up only fields equalt to count stored in database
        //     let jointOwnerNumber = Number(key.split("_").pop());
        //     if (jointOwnerNumber <= Number(dataClient["number_of_joint_owners"])) {
        //       setValue(`client.joint_owners.${key}`, jointOwners[key]);
        //     }
        //   });
        // }
        else if (key === "beneficial_owners") {
          let beneficialOwners = JSON.parse(dataClient[key] || "{}");
          Object.keys(beneficialOwners).forEach((key, index) => {
            //fill up only fields equalt to count stored in database
            let beneficialOwnerNumber = Number(key.split("_").pop());
            if (beneficialOwnerNumber <= Number(dataClient["number_of_beneficial_owners"])) {
              setValue(`client.beneficial_owners.${key}`, beneficialOwners[key]);
            }
          });
          requiredFields.map((value) => {
            manageRequireFields(value, getValues(`client.${value}`));
          });
        } else {
          setValue(`client.${key}`, dataClient[key]);
        }
      });


    }

    if (!dataClient) {
      setValue("client.country", "USA");
      setSelectedUSA(true);
    }
  }, [dataClient]);

  const handleBlur = (ref) => (e) => {
    if (isNaN(getValues(ref))) {
      setValue(ref, "$0");
      return
    }
    const price = getPrice(getValues(ref));
    setValue(ref, price);
  };

  const handleFocus = (ref) => (e) => {
    const price = getFigure(getValues(ref));
    setValue(ref, price);
  };

  useEffect(() => {
    if (!!listingSeller) {
      Object.keys(listingSeller).forEach((key) => {
        if (["amount_of_lien"].includes(key)) {
          setValue(`seller.${key}`, listingSeller[key] ? getPrice(listingSeller[key]) : "$0")
          return;
        }
        setValue(`seller.${key}`, listingSeller[key])
      });
    }
    setValue("seller.sales_executive", (dataBroker?.first_name || '') + ' ' + (dataBroker?.last_name || ''));
  }, [listingSeller]);

  useEffect(() => {
    if (getValues("client.country") === "USA") {
      setSelectedUSA(true);
    } else {
      setSelectedUSA(false);
    }
  }, [watch("client.country")]);


  useEffect(() => {
    if (getValues("trust.trust_country") === "USA") {
      setSelectedUSA2(true);
    } else {
      setSelectedUSA2(false);
    }
  }, [watch("trust.trust_country")]);

  const handleRegistration = (data, isFinishLater = false) => {
    dispatch(setLoadingStatus(true));
    let client = getFormattedData({
      ...data.client,
      ...data.trust
    });

    // client['joint_owners'] = JSON.stringify(client['joint_owners']);
    client['beneficial_owners'] = JSON.stringify(client['beneficial_owners']);

    if (dataListing && dataListing.seller_contact_id) {
      client.id = dataListing.seller_contact_id;
    }

    if (!isEditSeller) {
      delete client.id;
    }

    if ([LLC_OWNED, TRUST_OWNED, CORPORATE_OWNED].indexOf(ownerType) > -1) {
      client['first_name'] = data.trust?.trust_name;
      client['mobile_phone'] = data.trust?.trust_signer_phone;
      client['email'] = data.trust?.trust_signer_email;

    }

    if (data && data.seller && data.seller.amount_of_lien) {
      data.seller.amount_of_lien = getFigure(data.seller.amount_of_lien);
    }

    dispatch(
      upsertTransaction({
        step: {
          db: "seller",
          isGlobal: isGlobal,
          main: {
            ...client,
            broker_user_id: dataBroker?.id,
            company_id: dataBroker?.company_id,
            company_office_id: dataBroker?.company_office_id,
            createdBy: logged_user?.id,
            updatedBy: logged_user?.id
          },
        },
        transaction: {
          isBelonged: true,
          main: {
            ...getPlainTransactionData(dataListing),
            task_type: "listing",
            seller_type: ownerType,
            broker_user_id: dataBroker?.id,
            company_id: dataBroker?.company_id,
            company_office_id: dataBroker?.company_office_id,
          },
          foreignkeyName: "seller_contact_id",
        },
        third: {
          main: {
            ...data.seller,
            is_poa_received: isPoa,
            is_good_standing: isGood,
          },
          db: "listing_sellers",
          stepKey: "transaction_client_id",
        },
      }, "Seller's")
    ).then((data) => {
      dispatch(setLoadingStatus(false));
      if (isFinishLater) {
        history.push(`/listing-agreement/view/${data.id}/main`);
      } else {
        dispatch(getTransactionInitialById(data.id));
        dispatch(clearClient());
        setStep((step || 0) + 1);
      }
    }).catch((err) => {
      setError(err.response.data.message);
      dispatch(setLoadingStatus(false));
      setTimeout(() => {
        setError("");
      }, 5000);
    });
  };

  const handleError = () => {
    setStep(step + 1);
  };

  const handleOwner = (status) => {
    if ([TRUST_OWNED, CORPORATE_OWNED, LLC_OWNED, JOINT_OWNER].indexOf(status) > -1) {
      confirmAlert({
        title: "Set Title",
        message:
          "You have chosen a title other than Legal Name for the owner of this vessel. Confirm you have a Power of Attorney or other legal instrument allowing this person to sell this vessel",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              setValue('seller_type', status);
              // if ([JOINT_OWNER].indexOf(status) > -1) {
              //   setNumberOfJointOwners(numberOfJointOwners || 1);
              //   setValue("client.number_of_joint_owners", numberOfJointOwners || 1);
              // }
              if ([LLC_OWNED, TRUST_OWNED, CORPORATE_OWNED].indexOf(status) > -1) {
                setNumberOfBeneficialOwners(numberOfBeneficialOwners || 1);
                setValue("client.number_of_beneficial_owners", numberOfBeneficialOwners || 1);
                ['trust_name', 'trust_signer_phone', 'trust_signer_email'].map((value) => {
                  manageRequireFields(value, getValues(`trust.${value}`));
                });
              } else {
                CLIENT_REQUIRED_FIELDS.map((value) => {
                  manageRequireFields(value, getValues(`client.${value}`));
                });
              }
              setValue("trust.trust_country", "USA");

            },
          },
          {
            label: "No",
            onClick: () => { },
          },
        ],
      });
    } else {
      setValue('seller_type', status);
      // if ([JOINT_OWNER].indexOf(status) > -1) {
      //   setNumberOfJointOwners(numberOfJointOwners || 1);
      //   setValue("client.number_of_joint_owners", numberOfJointOwners || 1);
      // }
      if ([LLC_OWNED, TRUST_OWNED, CORPORATE_OWNED].indexOf(status) > -1) {
        setNumberOfBeneficialOwners(numberOfBeneficialOwners || 1);
        setValue("client.number_of_beneficial_owners", numberOfBeneficialOwners || 1);
      }
      setValue("trust.trust_country", "USA");
      CLIENT_REQUIRED_FIELDS.map((value) => {
        manageRequireFields(value, getValues(`client.${value}`));
      });
    }
  }

  const handleSelect = (value, option) => {
    dispatch(getClient(option.id));
  };

  const links = [
    { label: "Listing Agreements", link: `/listing-agreement` },
    { label: "Add a Listing Agreement", link: null },
  ];

  const isOwnerInGoodStanding = useMemo(() => getValues('trust.trust_is_good_standing'), [watch('trust.trust_is_good_standing')]);
  const ownerType = useMemo(() => getValues('seller_type'), [watch('seller_type')]);

  const manageRequireFields = (fieldName, fieldValue) => {
    setRequiredFields(state => {
      if (fieldValue) {
        return state.filter(field => field != fieldName);
      }
      return state.includes(fieldName) ? state : [...state, fieldName];
    })
  }

  const vesselName = `${dataTransaction?.t_vessel?.year || ""} ${dataTransaction?.t_vessel?.make || ""} ${dataTransaction?.t_vessel?.model || ""} ${dataTransaction?.t_vessel?.length || ""}`;
  const uniqueTransactionId = dataTransaction?.unique_transaction_id ? `LA-${dataTransaction?.unique_transaction_id}` : '';

  const RenderLienholderFelid = () => [SOLE_OWNER, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(ownerType) > -1 &&
    <>
      <FormRow>
        <input type="hidden" {...register("seller.id")} />
        <FormInputWrapper label="Lienholder's Bank">
          <input className="uk-input" type="text" {...register("seller.owner_bank")} />
        </FormInputWrapper>
        <FormInputWrapper label="Lienholder">
          <input type="text" className="uk-input" {...register("seller.lienholder")} />
        </FormInputWrapper>
        <FormInputWrapper label="Amount of Lien">
          <input
            className="uk-input"
            type="text"
            {...register("seller.amount_of_lien")}
            onFocus={handleFocus("seller.amount_of_lien")}
            onBlur={handleBlur("seller.amount_of_lien")}
          />
        </FormInputWrapper>
      </FormRow>
      <FormRow>
        <FormInputWrapper label="Insurer">
          <input className="uk-input" type="text" {...register("seller.insurer")} />
        </FormInputWrapper>
        <FormInputWrapper label="Policy Number">
          <input className="uk-input" type="text" {...register("seller.policy_number")} />
        </FormInputWrapper>
        <FormInputWrapper label="Jurisdiction of Owner">
          <input className="uk-input" type="text" {...register("seller.jurisdiction_of_owner")} />
        </FormInputWrapper>
      </FormRow>
      <FormRow>
        <FormInputWrapper label="Capt. Name">
          <input className="uk-input" type="text" {...register("seller.capt_name")} />
        </FormInputWrapper>
        <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
          <label className="uk-form-label" htmlFor="name">Capt. Phone</label>
          <input type="hidden" required name="seller.capt_phone"  {...register("seller.capt_phone")} />
          <PhoneInput
            placeholder='+'
            country={'us'}
            inputClass={classes.phone_input}
            containerClass={classes.phone_container}
            autoFocus={false}
            onFocus={() => { }}
            value={getValues("seller.capt_phone")}
            onChange={phone => { setValue("seller.capt_phone", phone, { shouldValidate: true }) }}
          />
        </div>
        <FormInputWrapper label="Sales Executive (Broker) *">
          <input className="uk-input" type="text" {...register("seller.sales_executive")} readOnly />
        </FormInputWrapper>
      </FormRow>
    </>

  return (
    <FormPage links={links} title="Seller" vesselName={vesselName} uniqueId={uniqueTransactionId}>
      <FormRow>
        <AutoCompleteSearch options={optionsForAutocomplete} onSelect={handleSelect} placeholder="Search Your Client List" />
      </FormRow>

      {error ? (
        <div className="uk-alert uk-alert-danger" data-uk-alert>
          <p>{error}</p>
        </div>
      ) : undefined}

      <Form>

        <FormRow>
          <div className="radio-toolbar">
            <FormInputWrapper>
              <input
                type="radio"
                name="trust_owner"
                id="trust_owner"
                checked={ownerType === TRUST_OWNED}
                onChange={(e) => handleOwner(TRUST_OWNED)}
              />
              <label htmlFor="trust_owner" className="uk-form-label radio">Trust Owned</label>
            </FormInputWrapper>
            <FormInputWrapper>
              <input
                type="radio"
                name="llc_owned"
                id="llc_owned"
                checked={ownerType === LLC_OWNED}
                onChange={(e) => handleOwner(LLC_OWNED)}
              />
              <label htmlFor="llc_owned" className="uk-form-label radio">LLC Owned</label>
            </FormInputWrapper>
            <FormInputWrapper>
              <input
                type="radio"
                name="corporate_owned"
                id="corporate_owned"
                checked={ownerType === CORPORATE_OWNED}
                onChange={(e) => handleOwner(CORPORATE_OWNED)}
              />
              <label htmlFor="corporate_owned" className="uk-form-label radio">Corporate Owner</label>
            </FormInputWrapper>
          </div>
        </FormRow>

        <FormRow>
          <label className="uk-form-label">Title*</label>
          <div className="radio-toolbar">
            <FormInputWrapper>
              <input
                type="radio"
                name="sole_owner"
                id="sole_owner"
                checked={ownerType === SOLE_OWNER}
                onChange={(e) => handleOwner(SOLE_OWNER)}
              />
              <label htmlFor="sole_owner" className="uk-form-label radio">Sole Owner</label>
            </FormInputWrapper>
            <FormInputWrapper>
              <input
                type="radio"
                name="joint_owner"
                id="joint_owner"
                checked={ownerType === JOINT_OWNER}
                onChange={(e) => handleOwner(JOINT_OWNER)}
              />
              <label htmlFor="joint_owner" className="uk-form-label radio">Joint Owners</label>
            </FormInputWrapper>


            {/* {[JOINT_OWNER].indexOf(ownerType) > -1 && (<FormInputWrapper label="Number of Joint Owner">
              <select
                className="uk-select"
                {...register("client.number_of_joint_owners")}
                onChange={e => setNumberOfJointOwners(Number(e.target.value))}
              >
                {Array.from({ length: MAX_JOINT_OWNERS }).map((_, index) => {
                  const ownerIndex = index + 1;
                  return (
                    <option value={ownerIndex} key={ownerIndex}>
                      {ownerIndex}
                    </option>
                  );
                })}
              </select>
            </FormInputWrapper>)} */}

          </div>
        </FormRow>

        {[SOLE_OWNER, JOINT_OWNER].indexOf(ownerType) > -1 &&
          <>
            <FormRow>
              <input type="hidden" {...register("client.id")} />
              <FormInputWrapper label="First Name *">
                <input className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('first_name') })}
                  type="text"
                  {...register("client.first_name", {
                    onChange: e => {
                      manageRequireFields('first_name', e.target.value);
                    }
                  })} />
              </FormInputWrapper>

              <FormInputWrapper label="Middle Name">
                <input className="uk-input" type="text" {...register("client.middle_name")} />
              </FormInputWrapper>

              <FormInputWrapper label="Last Name *">
                <input className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('last_name') })}
                  type="text"
                  {...register("client.last_name", {
                    onChange: e => {
                      manageRequireFields('last_name', e.target.value);
                    }
                  })} />
              </FormInputWrapper>
            </FormRow>
            <FormRow>
              <FormInputWrapper label="Email *">
                <input
                  className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('email') })}
                  type="email"
                  {...register("client.email", {
                    onChange: e => {
                      manageRequireFields('email', e.target.value);
                    }
                  })}
                />
              </FormInputWrapper>
              <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                <label className="uk-form-label" htmlFor="name">Mobile Phone *</label>
                <input type="hidden" required name="client.mobile_phone"  {...register("client.mobile_phone")} />
                <PhoneInput
                  placeholder='+'
                  country={'us'}
                  inputClass={classes.phone_input}
                  containerClass={classes.phone_container}
                  autoFocus={false}
                  onFocus={() => { }}
                  inputStyle={{
                    background: `${requiredFields.includes('mobile_phone') ? '#fff7f8' : ''}`,
                    borderColor: `${requiredFields.includes('mobile_phone') ? '#dc8d99' : ''}`
                  }}
                  buttonStyle={{ borderColor: `${requiredFields.includes('mobile_phone') ? '#dc8d99' : ''}` }}
                  value={getValues("client.mobile_phone")}
                  onChange={phone => {
                    setValue("client.mobile_phone", phone, { shouldValidate: true });
                    manageRequireFields('mobile_phone', phone);
                  }}
                />
              </div>
              <FormInputWrapper label="Citizen Of">
                <input className="uk-input" type="text" {...register("client.nationality")} />
              </FormInputWrapper>
            </FormRow>
            <FormRow>
              <FormInputWrapper label="Address">
                <input className="uk-input" type="text" {...register("client.address_1")} />
              </FormInputWrapper>
              <FormInputWrapper label="Address 2">
                <input className="uk-input" type="text" {...register("client.address_2")} />
              </FormInputWrapper>
              <FormInputWrapper label="Address 3">
                <input className="uk-input" type="text" {...register("client.address_3")} />
              </FormInputWrapper>
              <FormInputWrapper label="City">
                <input className="uk-input" type="text" {...register("client.city")} />
              </FormInputWrapper>
            </FormRow>
            <FormRow>
              <FormInputWrapper label="Country">
                <select className="uk-select" {...register("client.country")} defaultValue="USA">
                  {Country.map((country) => {
                    return (
                      <option value={country.code} key={country.code}>
                        {country.name}
                      </option>
                    );
                  })}
                </select>
              </FormInputWrapper>
              <FormInputWrapper label="State">
                {isSelectedUSA ? (
                  <select className="uk-select" {...register("client.state")}>
                    <option defaultValue={null}></option>
                    {StatesForUSA.map((state) => {
                      return (
                        <option value={state.code} key={state.code}>
                          {state.name}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <input className="uk-input" type="text" {...register("client.state")} />
                )}
              </FormInputWrapper>
              <FormInputWrapper label="Zip/Postal Code">
                <input className="uk-input" type="text" {...register("client.postal_code")} />
              </FormInputWrapper>
            </FormRow>

            <Divider className={classes.divider} />


            {/* <FormRow>
            <FormInputWrapper label="POA received?">
            <div className="radio-toolbar">
              <input
                type="radio"
                {...register("seller.is_poa_received")}
                checked={isPoa}
                onClick={() => setIsPoa(true)}
                readOnly
              />
              <label className="uk-form-label radio">Yes</label>
              <input
                type="radio"
                name="material_damaged"
                {...register("seller.is_poa_received")}
                checked={!isPoa}
                onClick={() => setIsPoa(false)}
                readOnly
              />
              <label className="uk-form-label radio">No</label>
            </div>
          </FormInputWrapper>

          <FormInputWrapper label="Owner in Good Standing?">
            <div className="radio-toolbar">
              <input
                type="radio"
                {...register("seller.is_good_standing")}
                checked={isGood}
                onClick={() => setIsGood(true)}
                readOnly
              />
              <label className="uk-form-label radio">Yes</label>
              <input
                type="radio"
                name="material_damaged"
                {...register("seller.is_good_standing")}
                checked={!isGood}
                onClick={() => setIsGood(false)}
                readOnly
              />
              <label className="uk-form-label radio">No</label>
            </div>
          </FormInputWrapper>
        </FormRow> */}


            {[JOINT_OWNER].indexOf(ownerType) > -1 && (
              <>
                <FormRow className='mb-3'>
                  <FormInputWrapper size="four">
                    <h3>Joint Owner&nbsp;<small>(to enable “Joint Owner” to sign, please add to “Co-Seller” page)</small></h3>
                  </FormInputWrapper>
                </FormRow>
                {/* {numberOfJointOwners == 1
              ? (
                <>
                  <FormRow key={`joint_owner_1`} size="four">
                    <FormInputWrapper label={`Joint Owner First Name *`} size="one">
                      <input className="uk-input" type="text" {...register(`client.joint_owners.first_name_1`)} />
                    </FormInputWrapper>
                    <FormInputWrapper label={`Joint Owner Last Name *`} size="one">
                      <input className="uk-input" type="text" {...register(`client.joint_owners.last_name_1`)} />
                    </FormInputWrapper>
                    <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                      <label className="uk-form-label" htmlFor="name">Joint Owner Phone *</label>
                      <input type="hidden" required name="client.joint_owners.phone_1"  {...register("client.joint_owners.phone_1")} />
                      <PhoneInput
                        placeholder='+'
                        country={'us'}
                        inputClass={classes.phone_input}
                        containerClass={classes.phone_container}
                        autoFocus={false}
                        onFocus={() => { }}
                        value={getValues("client.joint_owners.phone_1")}
                        onChange={phone => { setValue("client.joint_owners.phone_1", phone, { shouldValidate: true }) }}
                      />
                    </div>
                  </FormRow>
                  <FormRow>
                    <FormInputWrapper label={`Joint Owner Email *`} size="one">
                      <input className="uk-input" type="text" {...register(`client.joint_owners.email_1`)} />
                    </FormInputWrapper>
                  </FormRow>
                </>
              )
              : (
                Array.from({ length: numberOfJointOwners }).map((_, index) => {
                  const realIndex = index + 1;
                  return (
                    <>
                      <FormRow key={`joint_owner_${index}`} size="four">
                        <FormInputWrapper label={`Joint Owner ${realIndex} First Name *`} size="one">
                          <input className="uk-input" type="text" {...register(`client.joint_owners.first_name_${realIndex}`)} />
                        </FormInputWrapper>
                        <FormInputWrapper label={`Joint Owner ${realIndex} Last Name *`} size="one">
                          <input className="uk-input" type="text" {...register(`client.joint_owners.last_name_${realIndex}`)} />
                        </FormInputWrapper>
                        <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                          <label className="uk-form-label" htmlFor="name">{`Joint Owner ${realIndex} Phone *`}</label>
                          <input type="hidden" required name={`client.joint_owners.phone_${realIndex}`}  {...register(`client.joint_owners.phone_${realIndex}`)} />
                          <PhoneInput
                            placeholder='+'
                            country={'us'}
                            inputClass={classes.phone_input}
                            containerClass={classes.phone_container}
                            autoFocus={false}
                            onFocus={() => { }}
                            value={getValues(`client.joint_owners.phone_${realIndex}`)}
                            onChange={phone => { setValue(`client.joint_owners.phone_${realIndex}`, phone, { shouldValidate: true }) }}
                          />
                        </div>
                      </FormRow>
                      <FormRow>
                        <FormInputWrapper label={`Joint Owner ${realIndex} Email *`} size="one">
                          <input className="uk-input" type="text" {...register(`client.joint_owners.email_${realIndex}`)} />
                        </FormInputWrapper>
                      </FormRow>
                    </>
                  );
                })
              )
            } */}
              </>
            )}

            <RenderLienholderFelid />

            <Divider className={classes.divider} />
            <FormRow>
              <FormInputWrapper size="three">
                <h3>Additional Contact</h3>
              </FormInputWrapper>
            </FormRow>
            <FormRow>
              <FormInputWrapper label="Name">
                <input className="uk-input" type="text" {...register("client.additional_name")} />
              </FormInputWrapper>
              <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                <label className="uk-form-label" htmlFor="name">Phone</label>
                <input type="hidden" required name="client.additional_phone"  {...register("client.additional_phone")} />
                <PhoneInput
                  placeholder='+'
                  country={'us'}
                  inputClass={classes.phone_input}
                  containerClass={classes.phone_container}
                  autoFocus={false}
                  onFocus={() => { }}
                  value={getValues("client.additional_phone")}
                  onChange={phone => { setValue("client.additional_phone", phone, { shouldValidate: true }) }}
                />
              </div>
              <FormInputWrapper label="Email">
                <input className="uk-input" type="text" {...register("client.additional_email")} />
              </FormInputWrapper>
            </FormRow>
            <FormRow>
              <FormInputWrapper label="Title">
                <input className="uk-input" type="text" {...register("client.additional_title")} />
              </FormInputWrapper>
            </FormRow>

          </>
        }
        <Divider className={classes.divider} />
        {[TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(ownerType) > -1 && (
          <>
            <FormRow>
              <FormInputWrapper size="three">
                <h3>Corporation/LLC/Trust Owned</h3>
              </FormInputWrapper>
            </FormRow>

            <FormRow>
              <FormInputWrapper size="one" label="Number of Beneficial Owner">
                <select
                  className="uk-select"
                  {...register("client.number_of_beneficial_owners")}
                  onChange={e => setNumberOfBeneficialOwners(Number(e.target.value))}
                >
                  {Array.from({ length: MAX_BENEFICIAL_OWNERS }).map((_, index) => {
                    const ownerIndex = index + 1;
                    return (
                      <option value={ownerIndex} key={ownerIndex}>
                        {ownerIndex}
                      </option>
                    );
                  })}
                </select>
              </FormInputWrapper>
            </FormRow>

            <FormRow>
              <FormInputWrapper size="two" label="Corporation/LLC/Trust Name *">
                <input className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('trust_name') })}
                  type="text"
                  {...register("trust.trust_name", {
                    onChange: e => {
                      manageRequireFields('trust_name', e.target.value);
                    },
                    required: true
                  })} />
              </FormInputWrapper>
              <FormInputWrapper label="Tax ID">
                <input className="uk-input" type="text" {...register("trust.trust_tax_id")} />
              </FormInputWrapper>
            </FormRow>
            <FormRow><FormInputWrapper label="Address ">
              <input className="uk-input" type="text" {...register("trust.trust_address")} />
            </FormInputWrapper>
              <FormInputWrapper label="Unit/Apt">
                <input className="uk-input" type="text" {...register("trust.trust_unit_apt")} />
              </FormInputWrapper>
              <FormInputWrapper label="Country">
                <select className="uk-select" {...register("trust.trust_country")} defaultValue="USA">
                  {Country.map((country) => {
                    return (
                      <option value={country.code} key={country.code}>
                        {country.name}
                      </option>
                    );
                  })}
                </select>
                {/* <input className="uk-input" type="text" {...register("trust.trust_country")} /> */}
              </FormInputWrapper>
            </FormRow>
            <FormRow>
              <FormInputWrapper label="City ">
                <input className="uk-input" type="text" {...register("trust.trust_city")} />
              </FormInputWrapper>
              <FormInputWrapper label="State ">
                {isSelectedUSA2 ? (
                  <select className="uk-select" {...register("trust.trust_state")}>
                    <option defaultValue={null}></option>
                    {StatesForUSA.map((state) => {
                      return (
                        <option value={state.code} key={state.code}>
                          {state.name}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <input className="uk-input" type="text" {...register("trust.trust_state")} />
                )}
              </FormInputWrapper>
              <FormInputWrapper label="Zip">
                <input className="uk-input" type="text" {...register("trust.trust_zip")} />
              </FormInputWrapper>
            </FormRow>
            <FormRow>
              <FormInputWrapper label="Owner in good standing">
                <div className="radio-toolbar">
                  <input
                    type="radio"
                    id="radioStandingYes"
                    name="radioStanding"
                    checked={isOwnerInGoodStanding}
                    onChange={(e) => setValue('trust.trust_is_good_standing', e.target.checked)}
                  />
                  <label className="uk-form-label radio">Yes</label>
                  <input
                    type="radio"
                    id="radioStandingNo"
                    name="radioStanding"
                    checked={!isOwnerInGoodStanding}
                    onChange={(e) => setValue('trust.trust_is_good_standing', !e.target.checked)}
                  />
                  <label className="uk-form-label radio">No</label>
                </div>
              </FormInputWrapper>
              <FormInputWrapper label="Jurisdiction of Owner">
                <input className="uk-input" type="text" {...register("seller.jurisdiction_of_owner")} />
              </FormInputWrapper>
              <FormInputWrapper label="Capt. Name">
                <input className="uk-input" type="text" {...register("seller.capt_name")} />
              </FormInputWrapper>
            </FormRow>
            <FormRow>
              <FormInputWrapper label="Name of Signer *">
                <input className="uk-input" type="text" {...register("trust.trust_signer_name")} />
              </FormInputWrapper>
              <FormInputWrapper label="Title of Signer *">
                <input className="uk-input" type="text" {...register("trust.trust_signer_title")} />
              </FormInputWrapper>
              <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                <label className="uk-form-label" htmlFor="name">Capt. Phone</label>
                <input type="hidden" required name="seller.capt_phone"  {...register("seller.capt_phone")} />
                <PhoneInput
                  placeholder='+'
                  country={'us'}
                  inputClass={classes.phone_input}
                  containerClass={classes.phone_container}
                  autoFocus={false}
                  onFocus={() => { }}
                  value={getValues("seller.capt_phone")}
                  onChange={phone => { setValue("seller.capt_phone", phone, { shouldValidate: true }) }}
                />
              </div>
            </FormRow>
            <FormRow>
              <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                <label className="uk-form-label" htmlFor="name">Phone</label>
                <input type="hidden" required name="trust.trust_signer_phone"  {...register("trust.trust_signer_phone")} />
                <PhoneInput
                  placeholder='+'
                  country={'us'}
                  inputClass={classes.phone_input}
                  containerClass={classes.phone_container}
                  autoFocus={false}
                  onFocus={() => { }}
                  inputStyle={{
                    background: `${requiredFields.includes('trust_signer_phone') ? '#fff7f8' : ''}`,
                    borderColor: `${requiredFields.includes('trust_signer_phone') ? '#dc8d99' : ''}`
                  }}
                  buttonStyle={{ borderColor: `${requiredFields.includes('trust_signer_phone') ? '#dc8d99' : ''}` }}
                  value={getValues("trust.trust_signer_phone")}
                  onChange={phone => {
                    manageRequireFields('trust_signer_phone', phone);
                    setValue("trust.trust_signer_phone", phone, { shouldValidate: true })
                  }}
                />
              </div>
              <FormInputWrapper label="Email *">
                <input className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('trust_signer_email') })}
                  type="text"
                  //  {...register("client.email", {
                  //     onChange: e => {
                  //       manageRequireFields('email', e.target.value);
                  //     }
                  //   })}
                  {...register("trust.trust_signer_email", {
                    onChange: e => {
                      manageRequireFields('trust_signer_email', e.target.value);
                    }
                  })} />
              </FormInputWrapper>
            </FormRow>


            <FormRow>
              <FormInputWrapper size="four">
                <h3>Additional Beneficial Owner{numberOfBeneficialOwners > 1 ? "(s)" : ""}</h3>
              </FormInputWrapper>
            </FormRow>

            {
              numberOfBeneficialOwners == 1 ? (
                <FormRow key={`beneficial_owner_1`} size="four">
                  <FormInputWrapper label={`Beneficial Owner Name `} size="one">
                    <input className="uk-input" type="text" {...register(`client.beneficial_owners.name_1`)} />
                  </FormInputWrapper>
                  <FormInputWrapper label={`Beneficial Owner Title `} size="one">
                    <input className="uk-input" type="text" {...register(`client.beneficial_owners.title_1`)} />
                  </FormInputWrapper>
                  <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                    <label className="uk-form-label" htmlFor="name">{`Beneficial Owner Phone `}</label>
                    <input type="hidden" required name={`client.beneficial_owners.phone_1`}  {...register(`client.beneficial_owners.phone_1`)} />
                    <PhoneInput
                      placeholder='+'
                      country={'us'}
                      inputClass={classes.phone_input}
                      containerClass={classes.phone_container}
                      autoFocus={false}
                      onFocus={() => { }}
                      value={getValues(`client.beneficial_owners.phone_1`)}
                      onChange={phone => { setValue(`client.beneficial_owners.phone_1`, phone, { shouldValidate: true }) }}
                    />
                  </div>

                  <FormRow>
                    <FormInputWrapper label={`Beneficial Owner Email `} size="one">
                      <input className="uk-input" type="text" {...register(`client.beneficial_owners.email_1`)} />
                    </FormInputWrapper>
                    <FormInputWrapper label={`Beneficial Owner Address `} size="one">
                      <input className="uk-input" type="text" {...register(`client.beneficial_owners.address_1`)} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Beneficial Owner Unit/Apt " size="one">
                      <input className="uk-input" type="text" {...register("client.beneficial_owners.unit_apt_1")} />
                    </FormInputWrapper>
                  </FormRow>

                  <FormRow>
                    <FormInputWrapper label="Beneficial Owner City " size="one">
                      <input className="uk-input" type="text" {...register("client.beneficial_owners.city_1")} />
                    </FormInputWrapper>

                    <FormInputWrapper label="Beneficial Owner State " size="one">
                      <input className="uk-input" type="text" {...register("client.beneficial_owners.state_1")} />
                    </FormInputWrapper>

                    <FormInputWrapper label="Beneficial Owner Country " size="one">
                      <input className="uk-input" type="text" {...register("client.beneficial_owners.country_1")} />
                    </FormInputWrapper>
                  </FormRow>

                  <FormRow>
                    <FormInputWrapper label="Beneficial Owner Zip Code " size="one">
                      <input className="uk-input" type="text" {...register("client.beneficial_owners.postal_code_1")} />
                    </FormInputWrapper>
                  </FormRow>
                </FormRow>
              ) : (
                Array.from({ length: numberOfBeneficialOwners }).map((_, index) => {
                  const realIndex = index + 1;
                  return (
                    <>
                      <FormRow key={`beneficial_owner_${index}`} size="four">
                        <FormInputWrapper label={`Beneficial Owner ${realIndex} Name `} size="one">
                          <input className="uk-input" type="text" {...register(`client.beneficial_owners.name_${realIndex}`)} />
                        </FormInputWrapper>
                        <FormInputWrapper label={`Beneficial Owner ${realIndex} Title `} size="one">
                          <input className="uk-input" type="text" {...register(`client.beneficial_owners.title_${realIndex}`)} />
                        </FormInputWrapper>
                        <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                          <label className="uk-form-label" htmlFor="name">{`Beneficial Owner ${realIndex} Phone `}</label>
                          <input type="hidden" required name={`client.beneficial_owners.phone_${realIndex}`}  {...register(`client.beneficial_owners.phone_${realIndex}`)} />
                          <PhoneInput
                            placeholder='+'
                            country={'us'}
                            inputClass={classes.phone_input}
                            containerClass={classes.phone_container}
                            autoFocus={false}
                            onFocus={() => { }}
                            value={getValues(`client.beneficial_owners.phone_${realIndex}`)}
                            onChange={phone => { setValue(`client.beneficial_owners.phone_${realIndex}`, phone, { shouldValidate: true }) }}
                          />
                        </div>

                        <FormRow>
                          <FormInputWrapper label={`Beneficial Owner ${realIndex} Email `} size="one">
                            <input className="uk-input" type="text" {...register(`client.beneficial_owners.email_${realIndex}`)} />
                          </FormInputWrapper>
                          <FormInputWrapper label={`Beneficial Owner ${realIndex} Address `} size="one">
                            <input className="uk-input" type="text" {...register(`client.beneficial_owners.address_${realIndex}`)} />
                          </FormInputWrapper>

                          <FormInputWrapper label={`Beneficial Owner ${realIndex} Unit/Apt `} size="one">
                            <input className="uk-input" type="text" {...register(`client.beneficial_owners.unit_apt_${realIndex}`)} />
                          </FormInputWrapper>
                        </FormRow>

                        <FormRow>
                          <FormInputWrapper label={`Beneficial Owner ${realIndex} City `} size="one">
                            <input className="uk-input" type="text" {...register(`client.beneficial_owners.city_${realIndex}`)} />
                          </FormInputWrapper>

                          <FormInputWrapper label={`Beneficial Owner ${realIndex} State `} size="one">
                            <input className="uk-input" type="text" {...register(`client.beneficial_owners.state_${realIndex}`)} />
                          </FormInputWrapper>

                          <FormInputWrapper label={`Beneficial Owner ${realIndex} Country `} size="one">
                            <input className="uk-input" type="text" {...register(`client.beneficial_owners.country_${realIndex}`)} />
                          </FormInputWrapper>
                        </FormRow>
                        <FormRow>
                          <FormInputWrapper label={`Beneficial Owner ${realIndex} Zip Code `} size="one">
                            <input className="uk-input" type="text" {...register(`client.beneficial_owners.postal_code_${realIndex}`)} />
                          </FormInputWrapper>
                        </FormRow>
                      </FormRow>
                      <Divider className={classes.divider} />
                    </>

                  );
                }))
            }
          </>
        )}

        <FormRow>
          <div className={`one-column mt-3 mb-3`}>
            <input className="uk-checkbox " type="checkbox" checked={isGlobal} onChange={() => setIsGlobal(!isGlobal)} />
            <label className={`uk-form-label font-weight-bold`}>Save CRM Client?</label>
          </div>
        </FormRow>
        <FormRow>
          <button type="button" className='uk-button uk-button-primary' onClick={() => handleRegistration(getValues(), false)}>Save & Continue</button>
          <button type="button" className='uk-button uk-button-default' onClick={() => handleRegistration(getValues(), true)}>
            Save & Finish Later
          </button>
        </FormRow>
        <AsterixTypo isVisible={!!asterix} />
      </Form>
    </FormPage >
  );
};

export default AddSeller;
