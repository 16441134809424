import { memo } from 'react';
import { useDrag } from 'react-dnd';

import {
  Button,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const style = {
  border: '1px solid gray',
  backgroundColor: 'white',
  padding: '3px 10px',
  color: '#212529 !important',
  marginBottom: '0.5rem',
  cursor: 'move',
  float: 'left',
  width: '100%',
  opacity: 'transparent'
};

export const DocItem = memo(function DocItem({ name, type, icon, handleDrag }) {
  const [{ opacity }, drag] = useDrag(() => ({
    type,
    item: { name },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  }), [name, type]);
  return (
    <div ref={drag} role="DocItem" style={{ ...style, opacity }}>
      <FontAwesomeIcon icon={icon} /> {name}
    </div>
  );
});
