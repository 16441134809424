import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { object } from "yup";
import {
  getTransactionEsignById,
  upsertTransaction,
} from "../../../../../redux/actions/transaction";
import { getFormattedData, getPlainTransactionData } from "../../../../../utils/stepFilter";
import LinkButton from "../../../../common/buttons/LinkButton";
import SubmitButton from "../../../../common/buttons/SubmitButton";
import Form from "../../../../common/Form";
import FormInputWrapper from "../../../../common/FormInputWapper";
import FormPage from "../../../../common/FormPage";
import FormRow from "../../../../common/FormRow";
import { setLoadingStatus } from "../../../../../redux/actions/loading";

const schema = object().shape({});

const AddSellerNote = ({ transactionId }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const dataDeal = dataTransaction || {};
  const dataDetail = dataDeal.t_details || [];

  const links = [
    { label: "Deals", link: `/deals/view/${transactionId}/main` },
    { label: "Seller Closing Statement" },
  ];

  const { handleSubmit, setValue, getValues, register } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  useEffect(() => {
    let notes = dataDetail.filter((d) => d.type === "notes" && d.document_type === "seller");
    setValue("description", notes[notes.length - 1]?.description || null)
  }, [dataTransaction]);

  const handleRegistration = (data, isFinishLater = false) => {
    data.type = "notes";
    data.document_type = "seller";

    if (data) {
      dispatch(setLoadingStatus(true));
      dispatch(
        upsertTransaction({
          step: {
            db: "details",
            detailType: "notes",
            main: data,
          },
          transaction: {
            isBelonged: false,
            main: { ...getPlainTransactionData(dataDeal), task_type: "deal" },
          },
        },"Note's")
      ).then((data) => {
        dispatch(setLoadingStatus(false));
        if (isFinishLater) {
          history.push(`/deals/view/${data.id}/main`);
        } else {
          dispatch(getTransactionEsignById(data.id));
          history.push(`/deals/view/${transactionId}/seller-closing-statement/detail`);
        }
      }).catch(() => {
        dispatch(setLoadingStatus(false));
      });
    }
  };

  const handleError = () => { };

  const vesselName = `${dataTransaction?.t_vessel?.year || ""} ${dataTransaction?.t_vessel?.make || ""} ${dataTransaction?.t_vessel?.model || ""} ${dataTransaction?.t_vessel?.length || ""}`;
  const uniqueTransactionId = dataTransaction?.unique_transaction_id ? `PS-${dataTransaction?.unique_transaction_id}` : '';

  return (
    <FormPage links={links} vesselName={vesselName} uniqueId={uniqueTransactionId}>
      <Form>
        <FormRow>
          <FormInputWrapper size="three" label="Notes">
            <textarea class="uk-textarea" rows="5" cols="10" {...register("description")} placeholder="Notes" aria-label="Notes"></textarea>
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <button type="button" className='uk-button uk-button-primary' onClick={() => handleRegistration(getValues(), false)}>Save & Continue</button>
          <button type="button" className='uk-button uk-button-default' onClick={() => handleRegistration(getValues(), true)}>
            Save & Finish Later
          </button>
        </FormRow>
      </Form>
    </FormPage>
  );
};

export default AddSellerNote;
