import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';

import { getChecklist, getTaskByChecklistId } from '../../../../redux/actions/document_tasks';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  hand_signed_only_div: {
    width: "21% !important"
  },
  hand_signed_only_h3: {
    marginTop: "19px"
  },
  radio_div: {
    marginTop: "25px",
    marginRight: "272px"
  }
});

export const UploadDocumentStep = props => {
  const dispatch = useDispatch();

  const dataChecklist = useSelector((state) => state.documents.checklist);
  const iybaTasks = useSelector((state) => state.documents.tasks.filter(task => task.task_type === 'form') || []);

  const classes = useStyles();

  const [checklistId, setChecklistId] = useState(props.form.checklist_id || "default");
  const [iybaTaskId, setIYBATaskId] = useState(props.form.iyba_task_id || "default");
  const [isHandSigned, setIsHandSigned] = useState(props.form.is_hand_signed || false);
  const [url, setUrl] = useState(null);
  const [file, setFile] = useState(props.form.template_file || null);
  const ref = useRef();

  let user = JSON.parse(localStorage.getItem("user"));
  let company_id = user.company_id;

  useEffect(() => {
    dispatch(getChecklist());
  }, []);

  useEffect(() => {
    if (props.form.file_link && !url ) {
      setUrl(props.form.file_link)
    }

    if (props.form.is_hand_signed) {
      setIsHandSigned(props.form.is_hand_signed)
    }
    
  }, [iybaTasks])

  useEffect(() => {
    setChecklistId(props.form.checklist_id || "default");
    if (props.form.checklist_id) {
      dispatch(getTaskByChecklistId(props.form.checklist_id, 'iyba', company_id));
    }
  }, [props.form.checklist_id]);

  useEffect(() => {
    setIYBATaskId(props.form.iyba_task_id || "default");
  }, [props.form.iyba_task_id]);

  const handleSelectFile = (e) => {
    document.getElementById('file').click();
  }

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const handleFileChange = async (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      const base64 = await toBase64(e.target.files[0]);
      const url = URL.createObjectURL(e.target.files[0]);
      setUrl(url)
      props.update('template_file', e.target.files[0]);
      props.update('file_name', e.target.files[0].name);
    }
  }

  const openPreview = () => {
    window.open(url, "_blank")
  }

  const handleResetFile = (e) => {
    ref.current.value = "";
    setFile(null);
    setUrl(null);
    props.update('template_file', null);
    props.update('file_name', null);
  }

  const handleChangeChecklist = (evt) => {
    props.update("checklist_id", evt.target.value);
  };

  const handleChangeDocument = (evt) => {
    props.update("iyba_task_id", evt.target.value);
  };

  return (
    <div className="container">
      <div className="col-12 container-box">
        <div>
          <h4 style={{ fontWeight: '400', fontSize: '24px', marginTop: '2rem' }}>What needs to be signed?</h4>
          <h5 style={{ marginTop: '2rem' }}>Select Document Type</h5>
          <div className="form-container uk-margin-small">
            <div className="form-row">
              <div className="one-column uk-padding-small">
                <label className="uk-form-label">Type</label>
                <select className="uk-select" onChange={handleChangeChecklist} value={checklistId}>
                  <option disabled value={"default"}>Select a Type</option>
                  {dataChecklist.map((checklist) => {
                    return (
                      <option value={checklist.id} key={checklist.id}>
                        {checklist.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="one-column uk-padding-small">
                <label className="uk-form-label">Sub Type</label>
                <select className="uk-select" onChange={handleChangeDocument} value={iybaTaskId}>
                  <option disabled value={"default"}>Select a sub-type</option>
                  {iybaTasks.map((doc) => {
                    return (
                      <option value={doc.id} key={doc.id}>
                        {doc.title}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className={`one-column uk-padding-small ${classes.hand_signed_only_div}`}>
                <label className="uk-form-label"><h3 className={`${classes.hand_signed_only_h3}`}>Hand Sign Only :</h3></label>
              </div>
              <div className={`one-column uk-padding-small ${classes.radio_div}`}>
                <input type='radio' id="hand_sign_yes" checked={isHandSigned} onChange={(evt) => {
                  setIsHandSigned(true);
                   props.update("is_hand_signed", true);
                }} value={true} />
                <label className='uk-form-label radio' htmlFor="hand_sign_yes">Yes</label>
                <input type='radio' onChange={(evt) => {
                  setIsHandSigned(false);
                   props.update("is_hand_signed", false);
                }} checked={!isHandSigned} value={false} id="hand_sign_no" />
                <label className='uk-form-label radio' htmlFor="hand_sign_no">No</label>
              </div>
            </div>
          </div>
          <h5 style={{ marginTop: '2rem' }}>Select documents</h5>
          <div className="file-uploader-container">
            <div className="box">
              <input id="file" type="file" onChange={handleFileChange} ref={ref} hidden />
              <button className="btn btn-upload" onClick={handleSelectFile}>Upload file</button>
              <button className="btn btn-upload">Add template</button>
            </div>
            <div className="divider"></div>
            <div className="box">
              <div className="online-uploader-container">
                <img src="https://cdn.hellosign.com/1.116.0/build/33bcd013a7a9c3acfb40af4a3ab3098a.png" />
                <div>
                  Drag and drop files here
                </div>
              </div>
            </div>
          </div>
          <h5 style={{ marginTop: '2rem' }}>Documents you've selected</h5>
          <span>Drag and drop documents to reorder them</span>
          {props.form.file_name && (
            <div style={{ display: 'flex', marginTop: '30px' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ visibility: 'visible' }}>
                  <svg width="1em" height="1em" viewBox="0 0 16 24" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7 4.5C7 5.32843 6.32843 6 5.5 6C4.67157 6 4 5.32843 4 4.5C4 3.67157 4.67157 3 5.5 3C6.32843 3 7 3.67157 7 4.5ZM12 4.5C12 5.32843 11.3284 6 10.5 6C9.67157 6 9 5.32843 9 4.5C9 3.67157 9.67157 3 10.5 3C11.3284 3 12 3.67157 12 4.5ZM5.5 11C6.32843 11 7 10.3284 7 9.5C7 8.67157 6.32843 8 5.5 8C4.67157 8 4 8.67157 4 9.5C4 10.3284 4.67157 11 5.5 11ZM12 9.5C12 10.3284 11.3284 11 10.5 11C9.67157 11 9 10.3284 9 9.5C9 8.67157 9.67157 8 10.5 8C11.3284 8 12 8.67157 12 9.5ZM5.5 16C6.32843 16 7 15.3284 7 14.5C7 13.6716 6.32843 13 5.5 13C4.67157 13 4 13.6716 4 14.5C4 15.3284 4.67157 16 5.5 16ZM7 19.5C7 20.3284 6.32843 21 5.5 21C4.67157 21 4 20.3284 4 19.5C4 18.6716 4.67157 18 5.5 18C6.32843 18 7 18.6716 7 19.5ZM10.5 16C11.3284 16 12 15.3284 12 14.5C12 13.6716 11.3284 13 10.5 13C9.67157 13 9 13.6716 9 14.5C9 15.3284 9.67157 16 10.5 16ZM12 19.5C12 20.3284 11.3284 21 10.5 21C9.67157 21 9 20.3284 9 19.5C9 18.6716 9.67157 18 10.5 18C11.3284 18 12 18.6716 12 19.5Z" fill="currentColor"></path>
                  </svg>
                </div>
                <svg data-qa-ref="file-icon" width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor">
                  <path fillRule="evenodd" clipRule="evenodd" d="M6 3a1 1 0 00-1 1v16a1 1 0 001 1h12a1 1 0 001-1V8a1 1 0 00-.293-.707l-2-2-2-2A1 1 0 0014 3H6zm1 16V5h5v3a2 2 0 002 2h3v9H7zm9.586-11l-1.293-1.293L14 5.414V8h2.586z"></path>
                </svg>
                <span data-type="Upload">
                  {props.form.file_name}
                </span>
              </div>
              <a data-uk-tooltip="remove-document" style={{ marginLeft: '5px' }} onClick={handleResetFile}>
                <img src="https://cdn.hellosign.com/1.116.0/build/ae9ae8331a142bc9ce60901d10a41fc6.svg" />
              </a>
              <a style={{marginLeft: "11px"}} onClick={() => openPreview()}>
                <img src="/icons/view.svg" className="svg" data-uk-tooltip="Preview Document" />
              </a>
            </div>
          )}
          <hr />
        </div>
      </div>
    </div>
  );
};