
import DataTable from 'react-data-table-component';
import datatableStyles from '../../utils/datatable-style';
import {  useSelector } from 'react-redux';
import { momentDateWithTimezone } from '../../utils/common';
import { convertUnderscoreSeparateLetter, capitalizeFirstLetter } from '../../utils/functions';
const AllLogsTable = ({ searchProps,allActivities,totalRows }) => {

  const isLoading = useSelector((state) => state.loading.status);

  const { search, setSearch } = searchProps;

  const columns = [
    {
      name: "Date",
      selector: "updatedAt",
      sortable: true,
      cell: (row) => {
        return (
          <div data-tag="">
            <div>{momentDateWithTimezone(row.updatedAt)}</div>
          </div>
        );
      },
    },
    {
      name: "Full Name",
      selector: "first_name",
      sortable: true,
      cell: (row) => {
        return (
          <div data-tag="">
            <div>{(!row?.ua_user?.first_name && !row?.ua_user?.last_name) ? "System Generated " : `${capitalizeFirstLetter(row?.ua_user?.first_name || "")} ${capitalizeFirstLetter(row?.ua_user?.last_name || "") || ""}`}</div>
          </div>
        );
      },
    },
    {
      name: "IP Address",
      selector: "ip_address",

    },
    {
      name: "Type",
      selector: "activity_type",
      sortable: true,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div className='my-3'>{convertUnderscoreSeparateLetter(row.activity_type, "-")}</div>
          </div>
        );
      },
    },
    {
      name: "Module",
      selector: "module_name",
      sortable: true,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div className='my-3'>{convertUnderscoreSeparateLetter(row.module_name, "-")}</div>
          </div>
        );
      },
    },
    {
      name: "Details",
      selector: "description",
      sortable: true,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents" >
            <div dangerouslySetInnerHTML={{ __html: convertUnderscoreSeparateLetter(row?.description) }} className='my-3'></div>
          </div>
        );
      },
      grow: 3,
    },
  ];
  //Function for handle row limit
  const handleRowsPerPage = (numberOfRows, page) => {
    setSearch((search) => {
      return {
        ...search,
        limit: numberOfRows,
        page: page
      }
    })
  }

  //Function for handle page no
  const handlePageChange = page => {
    setSearch((search) => {
      return {
        ...search,
        page: page
      }
    })
  };

  //Function for handle sort
  const handleSort = (column, sortDirection) => {
    // column.selector, sortDirection
    setSearch((search) => {
      return {
        ...search,
        sortOrder: sortDirection,
        sortField: column.selector
      }
    })
  };
  return (
    <>{/* Users List Table */}

      {!allActivities.length && isLoading
        ? <div className="no-data-table"><div className="p-4">Fetching Details...</div></div>
        : <DataTable
          title="Users Table"
          columns={columns}
          data={allActivities}
          striped={true}
          highlightOnHover={true}
          pointerOnHover={true}
          center={true}
          noHeader={true}
          fixedHeader={true}
          pagination
          paginationServer
          dense={true}
          // responsive={true}
          // overflowY={true}
          paginationTotalRows={totalRows}
          paginationRowsPerPageOptions={[10, 20, 50, 100]}
          onChangeRowsPerPage={handleRowsPerPage}
          onChangePage={handlePageChange}
          onSort={handleSort}
          // wrap= {true}
          sortServer
          persistTableHead
          customStyles={{
            ...datatableStyles, rows: {
              style: {
                minHeight: 'fit-content !important', // override the row height
              },
            },
          }}
        />
      }</>
  )
}

export default AllLogsTable