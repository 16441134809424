import DocumentAndTaskService from "../../services/DocumentAndTaskService";
import {
  CREATE_TASK,
  DELETE_TASK,
  GET_CHECK_LIST,
  GET_ESIGN_DOC,
  GET_IYBA_DOCS_BY_CHECKLIST_ID,
  GET_TASK_BY_CHECK_LIST,
  UPDATE_TASK,
  CREATE_DOCUMENT_TEMPLATE,
  GET_DOCUMENT_TEMPLATE,
  UPDATE_DOCUMENT_TEMPLATE,
  DELETE_DOCUMENT_TEMPLATE,
  PARSE_DOCUMENT_TEMPLATE,
  RETRIEVE_DOCUMENT_TEMPLATES,
  PREFILL_DOCUMENT,
  UPDATE_ORDER_TASK_LIST
} from "./types";

export const getChecklist = () => async (dispatch) => {
  try {
    const res = await DocumentAndTaskService.getChecklists();

    dispatch({
      type: GET_CHECK_LIST,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getTaskByChecklistId = (id, model, company_id) => async (dispatch) => {
  try {
    const res = await DocumentAndTaskService.getTaskByChecklistId(model == 'iyba' ? id : `${id}/${company_id}`, model);

    dispatch({
      type: GET_TASK_BY_CHECK_LIST,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const createTask = (data, model) => async (dispatch) => {
  try {
    const res = await DocumentAndTaskService.createTask(data, model);

    dispatch({
      type: CREATE_TASK,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateTask = (id, data, model) => async (dispatch) => {
  try {
    const res = await DocumentAndTaskService.updateTask(id, data, model);

    dispatch({
      type: UPDATE_TASK,
      payload: data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const removeTask = (id, model) => async (dispatch) => {
  try {
    const res = await DocumentAndTaskService.removeTask(id, model);

    dispatch({
      type: DELETE_TASK,
      payload: id,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const uploadDoc = (data) => async (dispatch) => {
  try {
    const res = await DocumentAndTaskService.uploadDoc(data);
    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const eSignDoc = (signature_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ESIGN_DOC.pending,
      payload: "loading",
    });

    const res = await DocumentAndTaskService.eSignDoc(signature_id);

    dispatch({
      type: GET_ESIGN_DOC.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: GET_ESIGN_DOC.error,
      payload: err,
    });

    return err;
  }
};

export const getIYBADocumentsByChecklistId = (checklist_id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_IYBA_DOCS_BY_CHECKLIST_ID.pending,
      payload: "loading"
    });

    const res = await DocumentAndTaskService.getIYBADocumentsByChecklistId(checklist_id);

    dispatch({
      type: GET_IYBA_DOCS_BY_CHECKLIST_ID.success,
      payload: res.data
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: GET_IYBA_DOCS_BY_CHECKLIST_ID.error,
      payload: err
    });

    return Promise.reject(err);
  }
}

export const prefillDocument = (id, mode) => async (dispatch) => {
  try {
    const res = await DocumentAndTaskService.prefillDocument(id, mode);

    dispatch({
      type: PREFILL_DOCUMENT,
      payload: res.data
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

export const findDocumentTemplate = (id) => async (dispatch) => {
  try {
    const res = await DocumentAndTaskService.getTemplate(id);

    dispatch({
      type: GET_DOCUMENT_TEMPLATE,
      payload: res.data
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export const createDocumentTemplate = (data) => async (dispatch) => {
  try {
    const res = await DocumentAndTaskService.createTemplate(data);

    dispatch({
      type: CREATE_DOCUMENT_TEMPLATE,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export const updateDocumentTemplate = (id, data) => async (dispatch) => {
  try {
    const res = await DocumentAndTaskService.updateTemplate(id, data);
    dispatch({
      type: UPDATE_DOCUMENT_TEMPLATE,
      payload: res.data,
    });
    return Promise.resolve(res);
  } catch (err) {
    console.log("Error = ", err);
    return Promise.reject(err);
  }
}

export const findTemplateByName = (name) => async (dispatch) => {
  try {
    const res = await DocumentAndTaskService.findTemplateByName(name);

    dispatch({
      type: RETRIEVE_DOCUMENT_TEMPLATES,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
}

export const updateListOrder = (model, data) => async (dispatch) => {
  try {
    const res = await DocumentAndTaskService.updateListOrder(model, data);

    dispatch({
      type: UPDATE_ORDER_TASK_LIST,
      payload: data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
}


export const removeDocumentTemplate = (id) => async (dispatch) => {
  try {
    const res = await DocumentAndTaskService.getTemplate(id);

    dispatch({
      type: DELETE_DOCUMENT_TEMPLATE,
      payload: res.data
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export const parseDocumentTemplate = (file_name) => async (dispatch) => {
  try {
    const res = await DocumentAndTaskService.parseTemplate(file_name);

    dispatch({
      type: PARSE_DOCUMENT_TEMPLATE,
      payload: { key: 'parsed', data: res.data }
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
}