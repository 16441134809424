import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import DehazeIcon from '@mui/icons-material/Dehaze';
import CloseIcon from '@mui/icons-material/Close';
import Auth from '../../utils/auth';
import { getProfile, retrieveRelated } from '../../redux/actions/users';
import { setBroker } from '../../redux/actions/transaction';
import { resetStore } from '../../redux/actions/auth';
import FirstPageIcon from '@mui/icons-material/FirstPage';

import { toast } from "react-toastify";
import { setLoadingStatus } from '../../redux/actions/loading';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import * as SideBarUri from "../../constants/SideBarUri";
import { isParticipant } from '../../utils/permission';

const useStyles = makeStyles((theme) => ({
  back_icon_div: {
    color: "white",
    marginLeft: "10px",
    padding: "6px",
    display: "flex",
    marginRight: "10px",
    cursor: "pointer"
  },
  back_button_div: {
    marginLeft: "4px",
    padding: "6px",
    marginRight: "-46px"
  },
  back_icon: {
    marginRight: "5px"
  },
  back_icon_span: {
    marginRight: "75px"
  },
  custom_uk_navbar_left_user: {
    padding: '0 !important',
    marginLeft: "auto"
  },
  custom_uk_navbar_left_user_with_back_button: {
    padding: '0 !important',
    marginLeft: "auto",
  },
  custom_navbar_dropdown: {
    margin: '0 !important',
    padding: '10px',
    height: "245px",
    overflowY: "scroll"
  },
  user_dropdown: {
    overflow: "scroll"
  },
  nav_name: {
    textTransform: 'capitalize !important',
  },
  nav_header_link: {
    color: '#fff !important'
  },
  nav_item_link: {
    color: '#000 !important'
  },
  nav_container: {
    position: "fixed",
    top: "0px",
    width: "100%",
    zIndex: "999"
  }
}));

const Header = (props) => {

  let isHidden = useLocation().pathname.split("/").length > 2 ? true : false;
  const url = useLocation().pathname.split("/")[1];
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  if (url === SideBarUri.URI_SIDEBAR_IYBA_DOCUMENT_TASK ||
    url === SideBarUri.URI_SIDEBAR_COMPANY_DOCUMENT_TASK) {
    isHidden = false;
  }
  const loggedUser = Auth.getInstance().getUserData();
  const storage = window.localStorage;
  const selectedUser = useSelector(state => state.transactions.broker);
  const relatedData = useSelector(state => state.users.related)
    .filter(item => item.company_id !== null)
    .filter(item => item.company_id == loggedUser.company_id && item.is_broker == true)
    .filter(item => (
      (
        (item.UserInRole || {}).UserRole || {}).name || "" === "Agent" ||
      ((item.UserInRole || {}).UserRole || {}).name || "" === "Company Admin")
    )

  relatedData.sort((a, b) => a.last_name < b.last_name ? -1 : 1)


  const [displayedUser, setDisplayUser] = useState(selectedUser || loggedUser);

  const user = useSelector((state) => state.users.logged_by);

  useEffect(() => {
    dispatch(retrieveRelated(user.id));
  }, []);

  useEffect(() => {
    if (['Agent', 'Company Admin', 'Boatdox Admin'].indexOf(displayedUser.UserInRole.UserRole.name) > -1) {
      dispatch(setBroker(displayedUser));
    }
  }, [displayedUser]);

  useEffect(() => {
    if (JSON.stringify(displayedUser) !== localStorage.getItem('user')) {
      setDisplayUser(JSON.parse(localStorage.getItem('user')))
    }
  }, [localStorage.getItem('user')])


  const logout = () => {
    Auth.getInstance().logout();
    dispatch(resetStore());
    // window.location.reload();
    history.push("/login")
  }

  const handleChooseBroker = async (broker, isBackButton = false) => {
    try {
      dispatch(setLoadingStatus(true))
      const res = await Auth.getInstance().switchAccount(isBackButton ? broker : broker.id, loggedUser.id);
      let token = res.token;
      Auth.getInstance().setAuthToken(token);
      localStorage.setItem('actual_user_id', loggedUser.id);
      if (isBackButton) {
        localStorage.removeItem("actual_user_id");
      }
      let profile = await dispatch(getProfile(res.user_id))
      Auth.getInstance().setUserData(profile);
      setDisplayUser(profile)
      localStorage.setItem('login-check-privacy', 'true');
      toast.info("Account switched successfully", {
        autoClose: 2000,
      });
      dispatch(retrieveRelated(res.user_id));
      dispatch(setLoadingStatus(false));
      setTimeout(() => {
        toast.dismiss();
        history.push("/dashboard")
        // window.location.reload();
      }, 3000);
    } catch (error) {
      dispatch(setLoadingStatus(false))
      console.log(error);
      toast.error("Error while switching, Please try again.", {
        autoClose: 2000,
      });
    }
    // dispatch(setBroker(broker));
    // setDisplayUser(broker);
  }

  const handleReset = () => {
    dispatch(setBroker(null));
    setDisplayUser(loggedUser);
  }

  const displayDropdown = () => {
    return !actual_user_id && relatedData && relatedData.length > 0 && !isParticipant();
  }

  const actual_user_id = storage.getItem("actual_user_id");

  return (
    <div
      uk-sticky="media: 960"
      className={`${classes.nav_container} uk-navbar-container tm-navbar-container uk-sticky uk-sticky-fixed uk-active uk-sticky-below`}
    >
      <div className="uk-container uk-container-expand">
        <nav className="uk-navbar flex-wrap max-vw-100" uk-navbar="true">
          <div className="uk-navbar-left">
            {!isHidden && <button onClick={() => props.onSidebarToggle()} className={`uk-hidden@m btn-header ${!props.sideBarVisibility ? 'active' : ''}`}>
              {props.sidebarVisibility ? <CloseIcon /> : <DehazeIcon />}
            </button>}
            <div className='d-flex align-items-center gap-2'>
              <Link to={"/"} className="uk-navbar-item uk-logo p-2">
                <img src="/logo.png" alt="" style={{ maxWidth: "45px" }} />
              </Link> <div className='text-white mx-2'>Welcome to BoatDox v1.0 - Please watch tutorials on each page before proceeding to simplify your experience!</div>
            </div>
          </div>
          <div className={`uk-navbar-left-user ${actual_user_id ? classes.custom_uk_navbar_left_user_with_back_button : classes.custom_uk_navbar_left_user}`} >
            <ul className="uk-navbar-nav">
              <li>
                <a href="#" className={classes.nav_header_link}>
                  <img src={displayedUser.profile_photo ? `https://${process.env.REACT_APP_CDN_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}user-avatars/${displayedUser.profile_photo}` : "/biopic.png"} alt="" style={{ width: '50px', height: '50px', padding: '9px', borderRadius: '50%' }} />

                  <span className={`${classes.nav_name} nav-name`}>{displayedUser.first_name + ' ' + displayedUser.last_name}{" "}</span>
                  {displayDropdown() && (
                    <span
                      className="uk-margin-small-right uk-icon"
                      uk-icon="chevron-down"
                    ></span>
                  )}
                </a>
                {displayDropdown() > 0 && (
                  <div className={`uk-navbar-dropdown ${classes.custom_navbar_dropdown}`}>
                    <ul className="uk-nav uk-navbar-dropdown-nav user-dropdown">
                      {displayedUser.id !== loggedUser.id && (
                        <li key={loggedUser.id} onClick={() => { handleReset() }}>
                          <a className={classes.nav_item_link}>
                            {`${loggedUser.first_name} (${loggedUser.UserInRole?.UserRole?.name})`}
                          </a>
                        </li>
                      )}
                      {relatedData.map((data, index) => {
                        return (
                          // Hide current login user in the direct login dropdown as per front (K C) developer
                          loggedUser?.id != data?.id &&
                          <li key={index} onClick={() => { handleChooseBroker(data) }}>
                            <a className={classes.nav_item_link}>
                              {`${data.last_name} ${data.first_name} (${data.is_broker ? "Broker" : data.UserInRole.UserRole.name})`}
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )}

              </li>
            </ul>
          </div>
          <div className={`${classes.back_icon_div}`} >
            {
              actual_user_id &&
              <>
                <div className={`${classes.back_button_div} d-flex align-items-center`} onClick={() => {
                  handleChooseBroker(storage.getItem("actual_user_id"), true)
                }}>
                  <>
                    <FirstPageIcon className={classes.back_icon} >Go back to my account</FirstPageIcon>
                    <span className={classes.back_icon_span}>Go back to my account</span>
                  </>
                </div>
              </>
            }
            <button onClick={() => logout()} className="btn-header">Sign Out</button>
          </div>

        </nav>
      </div>
    </div>
  );
};

export default Header;
