import {
  CREATE_ASSOCIATION,
  RETRIEVE_ASSOCIATIONS,
  GET_ASSOCIATION,
  UPDATE_ASSOCIATION,
  DELETE_ASSOCIATION,
  DELETE_ALL_ASSOCIATIONS,
  FIND_ASSOCIATION_BY_NAME,
} from "./types";

import AssociationsService from "../../services/AssociationService";

export const createAssociation = (association) => async (dispatch) => {
  try {
    const res = await AssociationsService.create(association);

    dispatch({
      type: CREATE_ASSOCIATION,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const retrieveAssociations = () => async (dispatch) => {
  try {
    const res = await AssociationsService.getAll();
    
    dispatch({
      type: RETRIEVE_ASSOCIATIONS,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const updateAssociation = (id, data) => async (dispatch) => {
  try {
    const res = await AssociationsService.update(id, data);

    dispatch({
      type: UPDATE_ASSOCIATION,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const getAssociation = (id) => async (dispatch) => {
  try {
    const res = await AssociationsService.get(id);

    dispatch({
      type: GET_ASSOCIATION,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const deleteAssociation = (id) => async (dispatch) => {
  try {
    const res = await AssociationsService.remove(id);

    dispatch({
      type: DELETE_ASSOCIATION,
      payload: { id },
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const deleteAllAssociations = () => async (dispatch) => {
  try {
    const res = await AssociationsService.removeAll();

    dispatch({
      type: DELETE_ALL_ASSOCIATIONS,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err.response);
  }
};

export const findAssociationByName = (name) => async (dispatch) => {
  try {
    const res = await AssociationsService.findByName(name);

    dispatch({
      type: FIND_ASSOCIATION_BY_NAME,
      payload: res.data,
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err.response);
  }
};
