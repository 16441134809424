import React, { useEffect, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { setLoadingStatus } from '../../redux/actions/loading';
import TransactionService from '../../services/TransactionService';
import { toast } from 'react-toastify';

const AuthorizeDocSelectionModal = ({ participant = null, transactionId, documentsToDisplay, show, onClose }) => {

    const dispatch = useDispatch();

    const [selectedDocumentList, setSelectedDocumentList] = useState([]);
    const [defaultDocuments, setDefaultDocuments] = useState([]);

    useEffect(() => {
        if (show && participant?.participant_auth_docs && participant?.participant_auth_docs?.length) {
            dispatch(setLoadingStatus(true));
            setSelectedDocumentList(participant?.participant_auth_docs.map((eDoc) => {

                if (eDoc?.transaction_edoc) {
                    return {
                        value: eDoc.transaction_edoc?.id,
                        label: eDoc.transaction_edoc?.title,
                        is_deleted: eDoc?.is_deleted
                    }
                }
                
            }));
            setDefaultDocuments(participant?.participant_auth_docs.map((eDoc) => {
                if (eDoc?.transaction_edoc) {
                    return {
                        id: eDoc.id,
                        transaction_edoc_id: eDoc?.transaction_edoc_id,
                        is_deleted: eDoc?.is_deleted
                    }
                }
            }));
            dispatch(setLoadingStatus(false));
        }
    }, [show]);

    /**
     * On Submit the updated list of documents for participant
     */
    const onSubmitAuthDocuments = () => {
        dispatch(setLoadingStatus(true));
        setSelectedDocumentList((prev)=>prev.filter(doc=>doc?.value))
        let documentForSend = [];
        if (participant?.participant_auth_docs && participant?.participant_auth_docs?.length) {
            const updatedIdList = selectedDocumentList.filter(doc=>doc).map((eDoc) => { return eDoc.value });
            const existingList = participant?.participant_auth_docs.map((eDoc) => { return eDoc.transaction_edoc_id })
            documentForSend = [
                ...participant?.participant_auth_docs.map((document) => { return { id: document.id, transaction_edoc_id: document.transaction_edoc_id, is_deleted: updatedIdList.includes(document.transaction_edoc_id) ? false : true } }),
                ...selectedDocumentList.filter(doc=>doc).filter((document) => { return !existingList.includes(document?.value)}).map((document) => { return { transaction_edoc_id: document.value, is_deleted: false } })
            ];
        } else {
            documentForSend = selectedDocumentList.map((document) => { return { transaction_edoc_id: document.value, is_deleted: false } });
        }
        if (!documentForSend?.length) {
            toast.info("Please select a document ");
            dispatch(setLoadingStatus(false));
            return;
        }
        if (JSON.stringify(defaultDocuments) == JSON.stringify(documentForSend)) {
            toast.info("No any changes for authorized documents ")
            dispatch(setLoadingStatus(false));
            return;
        }
        TransactionService.updateSelectedDocuments(participant.participantId, transactionId, documentForSend).then(
            (response) => {
                toast.success('Authorized Documents successfully updated.');
                dispatch(setLoadingStatus(false));
                onClose(true);
                setSelectedDocumentList([]);
            }).catch((error) => {
                dispatch(setLoadingStatus(false));
                console.log(error);
                toast.error('Something went wrong while updating authorize documents.');
            });
    }

    return (
        <Modal
            show={show}
            onHide={onClose}
            centered={true}
            size='lg'>
            <Modal.Header closeButton>
                <h3>Select Authorize Documents</h3>
            </Modal.Header>
            <Modal.Body>
                <div style={{ height: '290px' }}>
                    <label className="uk-form-label">Select Documents for: <b>{participant?.name || ''} ({participant?.title || ''})</b></label>
                    <div className="uk-margin-small multiSelect">
                        <Select
                            isMulti
                            isClearable={false}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            maxMenuHeight={200}
                            options={documentsToDisplay}
                            closeMenuOnSelect={false}
                            classNamePrefix="select"
                            placeholder="Select Documents"
                            value={selectedDocumentList}
                            onChange={(value) => { setSelectedDocumentList(value); }}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="uk-button uk-button-orange uk-modal-close small" type="button" onClick={(e) => onClose(false)}>
                    Cancel
                </button>
                <button className="uk-button uk-button-primary small" type="button" onClick={onSubmitAuthDocuments}>Update</button>
            </Modal.Footer>
        </Modal>
    )
}

export default AuthorizeDocSelectionModal;