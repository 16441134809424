import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import { Alert, Tooltip } from '@mui/material';

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import clsx from "clsx";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import moment from "moment";
import AvatarImageCropper from 'react-avatar-image-cropper';

import Auth from "../../utils/auth";
import { toast } from "react-toastify";

import { updateUser, updatePassword, getUser, getProfile, updateProfile } from "../../redux/actions/users";
import { setLoadingStatus } from "../../redux/actions/loading";
import { fileUploadWithServer, uploadImage } from "../../utils/s3";
import "./Profile.css";
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import { dateFormatter } from "../../utils/common";

const useStyles = makeStyles({
  phone_input: {
    padding: '12px 12px 12px 50px !important',
    width: '100% !important'
  },
  // phone_container: {
  //   marginTop: '1rem'
  // },
  avatar_container: {
    padding: '15px !important',
    textAlign: 'center'
  },
  avatar_edit_cancel: {
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    cursor: 'pointer',
    border: '1px solid rgb(204, 204, 204)',
    borderRadius: '4px',
    margin: '5px',
    color: 'rgb(51, 51, 51)',
    backgroundColor: 'rgb(255, 255, 255)'
  },
  avatar_edit_ok: {
    display: 'inline-block',
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    cursor: 'pointer',
    border: '1px solid rgb(76, 174, 76)',
    borderRadius: '4px',
    margin: '5px',
    color: 'rgb(255, 255, 255)',
    backgroundColor: 'rgb(92, 184, 92)'
  }
});

const schema = yup.object().shape({
  first_name: yup
    .string()
    .required("First name is required field"),
  last_name: yup
    .string()
    .required("Last name is required field"),
  mobile_phone: yup
    .string()
    .required('Phone number is required')
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      'Phone number is not valid'
    ),
  // license_code: yup.string(),
  // expiration_date: yup.date()
});

const pschema = yup.object().shape({
  current_password: yup.string()
    .required("Please enter your password")
    .matches(
      /^.*(?=.{8,})((?=.*\d){1})((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, and one number"
    ),
  new_password: yup.string()
    .required("Please enter your password")
    .matches(
      /^.*(?=.{8,})((?=.*\d){1})((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, and one number"
    ),
  confirm_password: yup.string()
    .required("Please enter your password")
    .matches(
      /^.*(?=.{8,})((?=.*\d){1})((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, and one number"
    ).oneOf([yup.ref('new_password'), null], 'Passwords must match')
});

const ProfileView = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const [error, setError] = useState(undefined);
  const [isFinishSubmit, setFinishSubmit] = useState(false);
  const [avatar, setAvatar] = useState(undefined);
  // uploadAvatarFile state for contain uploaded file
  const [uploadAvatarFile, setUploadAvatarFile] = useState({});
  const [profile, setProfile] = useState(null);

  // const [user, setUser] = useState({});
  const logged_user = Auth.getInstance().getUserData();

  useEffect(() => {
    dispatch(getProfile(logged_user.id)).then(data => {
      setProfile(data);
      const fields = [
        'first_name',
        'last_name',
        'mobile_phone',
        'email',
        'license_code',
        'expiration_date',
        'profile_photo',
      ];
      fields.forEach(key => {
        if (key.indexOf("date") > -1) {
          setValue(
            key,
            data[key] ? moment(data[key]).format("yyyy-MM-DD") : null
          );
        } else if (key === 'mobile_phone') {
          const value = data[key].replace("-", "")
          setValue(key, value);
        } else {
          setValue(key, data[key]);
        }
      });

      setAvatar(data['profile_photo'] ? `https://${process.env.REACT_APP_CDN_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}user-avatars/${data['profile_photo']}` : undefined);
    }).catch((err) => {
      console.log(err);
      if (err.message.includes('401')) {
        toast.error("You are not authorized to access this action.")
      }
    });
  }, []);

  const { register, handleSubmit, reset, setValue, formState: { errors }, control } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true
  });

  const {
    register: pregister,
    handleSubmit: phandleSubmit,
    reset: preset,
    formState: { errors: perrors }
  } = useForm({
    mode: "all",
    resolver: yupResolver(pschema),
    shouldFocusError: true
  });

  const logout = () => {
    Auth.getInstance().logout();
    history.push("/login")
  }

  const setHeaderProfile = (id) => {
    dispatch(getProfile(id)).then((profile) => {
      Auth.getInstance().setUserData(profile)
    })
  }

  const handleUserUpdate = async (user) => {
    dispatch(setLoadingStatus(true));
    // upload avatar process will done here after save button click and if photo is same as prev than no need to upload it again
    if (uploadAvatarFile) {
      let url;
      try {
        const file = new File([uploadAvatarFile], uploadAvatarFile.name, { type: uploadAvatarFile.type });
        const filename = await uploadImage(file, 'user-avatars');
        url = `https://${process.env.REACT_APP_CDN_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}user-avatars/${filename}`;
        setValue('profile_photo', url);
        setUploadAvatarFile(null);
        // dispatch(setLoadingStatus(false));
      } catch (error) {
        dispatch(setLoadingStatus(false));
        console.log(error);
      }
      if (url) {
        user["profile_photo"] = url.split('/')[url.split('/').length - 1];
      }
    }
    dispatch(updateProfile(`${profile.id}`, user)).then(response => {
      if (response.status === 401) {
        dispatch(setLoadingStatus(false));
        toast.error('Your session has been expired, please login again.')
        setTimeout(() => {
          logout();
        }, 1000);
        return;
      }
      dispatch(setLoadingStatus(false));
      if (response.status === 200) {
        Auth.getInstance().setUserData(response.data);
        setError(undefined);
        setFinishSubmit(true);
        setHeaderProfile(response.data.id)

      }
      setTimeout(() => {
        setFinishSubmit(false);
      }, 2000);
    });
  };

  const handlePasswordUpdate = (password) => {
    dispatch(setLoadingStatus(true));
    dispatch(updatePassword(profile.id, password)).then(
      response => {
        dispatch(setLoadingStatus(false));
        setError(undefined);
        setFinishSubmit(true);
        preset();
        setTimeout(() => {
          setFinishSubmit(false);
        }, 2000);
      }).catch(err => {
        dispatch(setLoadingStatus(false));
        setError(err.response.data.message);
      });
  };

  const handleError = (errors) => {
    console.log("Errors = ", errors);
  };

  const handleAvatarChange = async (blob) => {
    // states for display logo and for store uploaded file
    if (blob) {
      setAvatar(URL.createObjectURL(blob));
      setUploadAvatarFile(blob);
    }
  }

  const actions = [
    <button key={0} className={classes.avatar_edit_cancel} type="button">
      <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
        <path d="M0 0h24v24H0z" fill="none"></path>
      </svg>
    </button>,
    <button key={1} className={classes.avatar_edit_ok} type="button">
      <svg fill="#ffffff" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0h24v24H0z" fill="none"></path>
        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
      </svg>
    </button>
  ]

  return (
    <div className="tm-main sign-in">
      <div className="uk-container uk-container-expand uk-position-relative">
        {isFinishSubmit
          ? <div className="uk-alert uk-alert-primary" data-uk-alert><p>Updated Successfully</p></div>
          : undefined
        }

        <h1>Profile</h1>

        <div className="form-container uk-margin-small">
          <form onSubmit={handleSubmit(handleUserUpdate, handleError)} className="uk-grid-small form" data-uk-grid id="addform" method="POST" onReset={reset}>
            <div className="form-row">
              <h2>Personal Information</h2>
            </div>
            <div className="form-row">
              <Tooltip title="Brokerage" arrow placement='right'>
                <span style={{ fontSize: '22px' }}>{profile && profile.company?.company_name || ''}</span>
              </Tooltip>
              <input type="hidden" {...register("profile_photo")} />
              <div className={`three-column ${classes.avatar_container}`}>
                <div style={{ maxWidth: '250px', height: '200px', width: '100%', margin: 'auto', position: 'relative' }}>
                  {avatar && <img style={{ width: '100%', height: '100%', position: 'absolute', left: '0', top: '0' }} src={avatar} />}
                  <AvatarImageCropper apply={handleAvatarChange} isBack={true} actions={actions} />
                </div>
              </div>
            </div>
            <div className=" px-3  row w-100">
              <div className=" uk-padding-small col-12 col-sm-6">
                <label className="uk-form-label" htmlFor="name">First Name*</label>
                <input className={clsx("uk-input", { 'uk-form-danger': errors.first_name })} type="text" {...register("first_name")} />
              </div>
              <div className=" uk-padding-small col-12 col-sm-6">
                <label className="uk-form-label" htmlFor="name">Last Name*</label>
                <input className={clsx("uk-input", { 'uk-form-danger': errors.last_name })} type="text" {...register("last_name")} />
              </div>
            </div>
            <div className=" row w-100">
              <div className=" col-12 col-sm-6 uk-padding-small">
                {/* <label className="uk-form-label" htmlFor="name">Mobile Phone</label>
                <input className={clsx("uk-input", {'uk-form-danger': errors.mobile_phone})} type="text" {...register("mobile_phone")} /> */}

                <label className="uk-form-label" htmlFor="name">Mobile Phone</label>
                <input type="hidden" required name="mobile_phone" {...register("mobile_phone")} />
                <PhoneInput
                  placeholder='+'
                  inputClass={classes.phone_input}
                  containerClass={classes.phone_container}
                  value={logged_user.mobile_phone}
                  onChange={phone => { setValue('mobile_phone', phone, { shouldValidate: true }) }}
                />
                <p style={{ color: "red" }}>{errors.mobile_phone?.message}</p>
              </div>

            </div>

            <div className="row w-100">
              <div className="col-12 col-sm-6  uk-padding-small">
                <label className="uk-form-label" htmlFor="license">License #</label>
                <input className="uk-input" type="text" placeholder="" {...register("license_code")} />
              </div>

              <div className="col-12 col-sm-6  uk-padding-small">
                <label className="uk-form-label" htmlFor="name">License Expiration Date</label>
                <Controller
                  control={control}
                  name="expiration_date"
                  render={({ field }) => (
                    <DatePicker
                      className="uk-input"
                      style={{ cursor: "pointer", color: "#000" }}
                      format={'MM/DD/YYYY'}
                      value={field.value && dayjs(field.value)}
                      onChange={(date, dateString) => {
                        field.onChange(dateFormatter(dateString));
                      }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="row w-100">
              <div className="three-column uk-padding-small">
                {/* <button type="submit" className="uk-button iy-button-primary">Save</button> */}
                <input type="submit" className="uk-button iy-button-primary" value="Save" />
              </div>
            </div>

          </form>
          <div className="form-row"><hr style={{ width: "100%", marginBottom: "14px" }} /></div>
          <form onSubmit={phandleSubmit(handlePasswordUpdate, handleError)} className="uk-grid-small form" data-uk-grid id="addform" method="POST" onReset={preset}>
            <h2>Update Password</h2>

            <div className="row w-100">
              {error && (
                <Alert variant="outlined" severity="error" className={classes.alert}>
                  {error}
                </Alert>
              )}
            </div>
            <div className="row w-100">
              <div className="col-12 col-sm-6  uk-padding-small">
                <label className="uk-form-label" htmlFor="name">Current Password*</label>
                <input className={clsx("uk-input", { 'uk-form-danger': perrors.current_password })} type="password" {...pregister("current_password")} />
              </div>
            </div>

            <div className="row w-100">
              <div className="col-12 col-sm-6  uk-padding-small">
                <label className="uk-form-label" htmlFor="name">Password*</label>
                <input className={clsx("uk-input", { 'uk-form-danger': perrors.new_password })} type="password" {...pregister("new_password")} />
              </div>
              <div className="col-12 col-sm-6  uk-padding-small">
                <div className="password"><label className="uk-form-label" htmlFor="name">Verify Password*</label></div>
                <input className={clsx("uk-input", { 'uk-form-danger': perrors.confirm_password })} type="password" {...pregister("confirm_password")} />
              </div>
            </div>
            <div className="row w-100">
              <div className="col-12 col-sm-6  uk-padding-small">
                <ul>
                  <li>Password must contain numbers</li>
                  <li>Password must contain uppercase letters</li>
                </ul>
              </div>
              <div className="col-12 col-sm-6  uk-padding-small">
                <ul>
                  <li>Length must be greater than 8 characters</li>
                  <li>Password should not contain strings</li>
                  <li>Password must not contain repetitions.</li>
                </ul>
              </div>
            </div>

            <div className="row w-100">
              <div className="three-column uk-padding-small">
                <a href="checklists-tasks.php">
                  <button className="uk-button iy-button-primary">Update</button>
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfileView;
