import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";


const ViewDocument = () => {
  const [searchName, setSearchName] = useState("");
  const dataUsers = useSelector((state) => state.users.users);
  const dispatch = useDispatch();
  const { path } = useRouteMatch();

  return (
    <>View Document Tempaltes</>
  )
};

export default ViewDocument;
