const DeleteConfirm = ({ id }) => {
  return (
    <div id="modal-delete" data-uk-modal="true">
      <div className="uk-modal-dialog">
          <button className="uk-modal-close-default" type="button" data-uk-close="true"></button>
          <div className="uk-modal-header">
              <h2 className="uk-modal-title">Delete</h2>
          </div>
          <div className="uk-modal-body">
              <p>Are you sure?</p>			
          </div>
          <div className="uk-modal-footer uk-text-right">
              <button className="uk-button uk-button-default uk-modal-close" type="button">No</button>
              <button className="uk-button uk-button-primary" type="button">Yes</button>
          </div>
      </div>
    </div>
  )
}

export default DeleteConfirm;
