import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import React from 'react';

import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import {
  Button, CssBaseline, TextField,
  Typography, Container, Alert, Box
} from '@mui/material';

import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CognitoUser,
} from 'amazon-cognito-identity-js';

import Auth from '../../../utils/auth';

import { setLoadingStatus } from "../../../redux/actions/loading";
import { useHistory } from "react-router-dom";
import UserService from "../../../services/UserService";

const theme = createTheme();
const useStyles = makeStyles({
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#0063A6 !important'
  },
  alert: {
    width: '100%',
    margin: 'auto'
  },
  typo: {
    color: '#0063A6 !important'
  },
  logo: {
    maxWidth: '50%'
  }
});

const schema_email = yup.object().shape({
  email: yup
    .string()
    .email()
    .required("Email is a required field"),
});

const schema_reset = yup.object().shape({
  code: yup
    .string()
    .required("Code is a required field"),
  new_password: yup.string()
    .required("Please enter your password")
    .matches(
      /^.*(?=.{8,})((?=.*\d){1})((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, and one number"
    ),
  confirm_password: yup.string()
    .required("Please enter your password")
    .matches(
      /^.*(?=.{8,})((?=.*\d){1})((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      "Password must contain at least 8 characters, one uppercase, one lowercase, and one number"
    ).oneOf([yup.ref('new_password'), null], 'Passwords must match')
})

const ResetForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [step, setStep] = useState('email');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const [csrfToken, setCsrfToken] = useState(null);

  useEffect(() => {
    UserService.trackUserToken('reset-password')
      .then(response => {
        setCsrfToken(response.data.xt);
      }).catch(error => {
        console.log(error);
        setCsrfToken(null);
      });
  }, []);

  const { register: emailRegister, handleSubmit: emailSubmit, formState: { errors: emailErrors } } = useForm({
    resolver: yupResolver(schema_email)
  });

  const { register: passwordRegister, handleSubmit: passwordSubmit, formState: { errors: passwordErrors } } = useForm({
    resolver: yupResolver(schema_reset)
  });

  const handleEmail = (data) => {
    dispatch(setLoadingStatus(true));
    setEmail(data.email);

    const config = getCsrfToken();
    UserService.sendCodeCognitoEmail({ email: data.email.toLowerCase() }, config)
      .then(response => {
        dispatch(setLoadingStatus(false));
        setStep('reset');
      }).catch(error => {
        dispatch(setLoadingStatus(false));
        setError('Something went wrong! Try again later');
      })
  }

  const handlePassword = (data) => {
    dispatch(setLoadingStatus(true));
    const config = getCsrfToken();
    UserService.resetPassword(email, data.code, data.new_password, config).then(res => {
      dispatch(setLoadingStatus(false));
      history.push('/auth/login');
    }).catch(err => {
      dispatch(setLoadingStatus(false));
      setError('Something went wrong! Try again later');
    })
  }

  const getCsrfToken = () => {
    return {
      headers: {
        xt: csrfToken
      }
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Box component="a" m={2} className={classes.logo}>
          <img src={process.env.PUBLIC_URL + "/images/boatdox-logo-blue.png"} alt="logo" />
        </Box>
        <Typography component="h4" variant="h6" gutterBottom>
          Reset Password
        </Typography>

        {error && (
          <Alert variant="outlined" severity="error" className={classes.alert}>
            {error}
          </Alert>
        )}
        {step === 'email' && (
          <form className={classes.form} onSubmit={emailSubmit(handleEmail)}>
            <Typography component="h1" variant="body2" gutterBottom>
              Please enter your email address where you will soon receive code to reset your password.
            </Typography>
            <TextField variant="outlined" margin="normal" required fullWidth id="email" label="Email" autoFocus {...emailRegister("email")} />
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>Send Code</Button>
          </form>
        )}
        {step === 'reset' && (
          <form className={classes.form} onSubmit={passwordSubmit(handlePassword)}>
            <TextField variant="outlined" margin="normal" required fullWidth id="code" label="Code" autoFocus {...passwordRegister("code")} autoComplete="code" />
            <TextField variant="outlined" margin="normal" required fullWidth id="new_password" label="New Password" type="password" {...passwordRegister("new_password")} autoComplete="new-password" />
            <TextField variant="outlined" margin="normal" required fullWidth id="confirm_password" label="Confirm Password" type="password" {...passwordRegister("confirm_password")} />
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>Reset Password</Button>
          </form>
        )}
      </div>
      {/* <Box mt={8}>
        <Copyright />
      </Box> */}
    </Container>
  )
}

export default ResetForm;