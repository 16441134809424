import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import FormRow from './FormRow';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionEdocById } from '../../redux/actions/transaction';
import { capitalizeFirstLetter } from '../../utils/functions';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { setLoadingStatus } from '../../redux/actions/loading';
import TransactionService from '../../services/TransactionService';
import { toast } from 'react-toastify';

const SendPreviewEmailModal = ({ show, onClose, documentList, setAlert }) => {

  const schema = yup.object().shape({
    roleId: yup.string().required(),
    additional_email_content: yup.string(),
    isBuyersHide: yup.boolean(),
    isSellersHide: yup.boolean(),
    role: yup.string()
  });

  const { register, reset, handleSubmit, setValue, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const dispatch = useDispatch();

  const [clientRoles, setClientRoles] = useState([]);

  const deals = useSelector((state) => state.transactions);
  const dataTransaction = deals.transaction || {};
  const dataCoBroker = dataTransaction.co_broker;

  //useEffect to fetch tha data of transaction
  // useEffect(() => {
  //   if (dataTransaction.id) {
  //     dispatch(getTransactionEdocById(dataTransaction.id));
  //   }
  // }, []);

  useEffect(() => {
    reset();
    getClientRoleList();
  }, [show]);

  const getClientRoleList = () => {
    let clientArray = [];

    if (dataTransaction.buyer) {
      clientArray.push({
        role: 'buyer',
        roleId: dataTransaction.buyer?.id,
        email: dataTransaction.buyer?.email
      });
    }
    if (dataTransaction.co_buyer) {
      clientArray.push({
        role: 'co_buyer',
        roleId: dataTransaction.co_buyer?.id,
        email: dataTransaction.co_buyer?.email
      });
    }
    if (dataTransaction.seller) {
      clientArray.push({
        role: 'seller',
        roleId: dataTransaction.seller?.id,
        email: dataTransaction.seller?.email
      });
    }
    if (dataTransaction.co_seller) {
      clientArray.push({
        role: 'co_seller',
        roleId: dataTransaction.co_seller?.id,
        email: dataTransaction.co_seller?.email
      });
    }

    if (dataTransaction?.employing_broker?.email || dataCoBroker?.selling_broker_email) {
      clientArray.push({
        role: dataTransaction?.employing_broker ? 'selling_employing_broker' : 'selling_broker',
        roleId: dataTransaction?.employing_broker ? dataTransaction?.employing_broker?.id : dataCoBroker?.id,
        email: dataTransaction?.employing_broker ? dataTransaction?.employing_broker?.email : dataCoBroker?.selling_broker_email
      });
    }

    if (dataTransaction?.employing_broker?.email || dataCoBroker?.listing_broker_email) {
      clientArray.push({
        role: dataTransaction?.employing_broker ? 'listing_employing_broker' : 'listing_broker',
        roleId: dataTransaction?.employing_broker ? dataTransaction?.employing_broker?.id : dataCoBroker?.id,
        email: dataTransaction?.employing_broker ? dataTransaction?.employing_broker?.email : dataCoBroker?.listing_broker_email
      });
    }

    setClientRoles(clientArray);
  }

  const onSubmitForm = (data) => {
    dispatch(setLoadingStatus(true));

    data['documents'] = documentList;
    TransactionService.sendDocumentPreviewEmail(data)
      .then(response => {
        const documents = response?.data;
        setAlert?.setSuccessDoc(documents?.successDocument)
        setAlert?.setFailedDoc(documents?.failedDocument)

        setTimeout(() => {
          setAlert?.setSuccessDoc([]);
          setAlert?.setFailedDoc([]);
        }, 5000);
        dispatch(setLoadingStatus(false));
        // toast.success('Email Send Successfully');
        // if(documents.successDocument?.length){
        //   toast.success(`Successfully Send: ${documents.successDocument.map(docName => docName)}`);
        // }
        // if(documents.failedDocument?.length){
        //   toast.success(`Successfully Send: ${documents.failedDocument.map(docName => docName)}`);
        // }
        onClose(true);
      }).catch(error => {
        switch (error.status) {
          case 401:
            toast.error("You are not authorized to access this action.")
            break;
          default:
            toast.error('Something went wrong!');
            break;
        }
        dispatch(setLoadingStatus(false));
      })
  }

  const handleError = (error) => {
    console.log(error);
  }

  const handleRoleChange = (event) => {
    const selectedRole = event.target[event.target.selectedIndex].text;
    setValue('role', getRoleName(selectedRole));
  }

  const getRoleName = (text) => {
    const matches = text.match(/\((.*?)\)/g);
    const result = [];
    if (matches) {
      for (let i = 0; i < matches.length; ++i) {
        const match = matches[i];
        result.push(match.substring(1, match.length - 1));  // brackets removing
      }
    }
    return result[0];
  };

  return (
    <Modal
      show={show}
      onHide={() => onClose(false)}
      centered={true}
      size='lg'>
      <Modal.Header closeButton>
        <h3>Send Preview of Selected Documents</h3>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmitForm, handleError)}>
        <Modal.Body>
          <div className='p-2'>
            <div className='row'>
              <div className="col-md-1 text-left pt-2">
                <label className='uk-form-label' style={{ fontSize: '16px', fontWeight: '500' }}>To:</label>
              </div>
              <div className="col-md-7">
                <select className={clsx("uk-select", { "uk-form-danger": errors?.roleId })} name='roleId' {...register('roleId')} onChange={handleRoleChange}>
                  <option value=''>Select Role</option>
                  {clientRoles.map((role, index) => {
                    return (
                      <option value={role.roleId} key={`${role.roleId}_${index}`}>
                        {role.email}&nbsp;({capitalizeFirstLetter(role.role)})
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <FormRow>
              <div className="uk-padding-small mt-3">
                <label className="uk-form-label" style={{ fontSize: '16px', fontWeight: '500' }}>Message:</label>
                <textarea type="text" rows='6' className="uk-textarea" {...register('additional_email_content')} />
              </div>
            </FormRow>
            <div className='row mt-3'>
              <div className="col-md-2">
                <label className='uk-form-label' style={{ fontSize: '16px', fontWeight: '500' }}>Hide:</label>
              </div>
              <div className="col-md-4">
                <div className={`one-column uk-padding-small`}>
                  <input type="checkbox" id="hide_buyer" name="hide_buyer" {...register('isBuyersHide')} style={{ cursor: 'pointer' }} />
                  <label className={`uk-form-label`} htmlFor="hide_buyer">Buyers Contact Info</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className={`one-column uk-padding-small`}>
                  <input type="checkbox" id="hide_seller" name="hide_seller"  {...register('isSellersHide')} style={{ cursor: 'pointer' }} />
                  <label className={`uk-form-label`} htmlFor="hide_seller">Sellers Contact Info</label>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <label className='text-danger'>* If edited via PDF Editor - you must manually remove any information you  wish to hide
                  BEFORE sending!</label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="uk-button uk-button-default uk-modal-close small" type="button" onClick={(e) => onClose(false)}>
            Cancel
          </button>
          <button
            className="uk-button uk-button-primary small"
            type="submit">Send</button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default SendPreviewEmailModal;