import ModalBody from "../../../../common/ModalBody";
import OffCanvasContainer from "../../../../common/OffCanvasContainer";
import DetailOfExclusions from "../details/DetailOfExclusions";

const ViewExclusionsForm = ({ exclusions }) => {
  return (
    <OffCanvasContainer id="modal-exclusions">
      <ModalBody title="Exclusions">
        <DetailOfExclusions exclusions={exclusions} />
      </ModalBody>
    </OffCanvasContainer>
  );
};

export default ViewExclusionsForm;
