import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/reset.css";

import { DELETE_MODAL_MESSAGE, DELETE_MODAL_TITLE } from "../../../../../constants/Alert";
import { getTransactionEdocById, removeTaskFromTransaction, reOrderTasks } from "../../../../../redux/actions/transaction";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import { useParams } from "react-router-dom";
import ReactDragListView from "react-drag-listview";
import Auth from "../../../../../utils/auth";
import { toast } from "react-toastify";
import TransactionService from "../../../../../services/TransactionService";
import { updateListOrder } from "../../../../../redux/actions/document_tasks";

const FormEditTable = ({ setDataForUpdateFormListOrder }) => {

  const dispatch = useDispatch();
  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const logged_user = Auth.getInstance().getUserData();
  const [tblData, setTblData] = useState([]);

  const fetchDocuments = async (transactionId) => {
    dispatch(setLoadingStatus(true));
    try {
      const { data } = await TransactionService.fetchAllDocuments(transactionId);
      setTblData(data.templates);
      dispatch(setLoadingStatus(false));
    } catch (error) {
      dispatch(setLoadingStatus(false));
      toast.error("Error while fetching documents.", {
        autoClose: 2000
      })
    }
  }

  useEffect(() => {
    if (dataTransaction && dataTransaction.id) {
      fetchDocuments(dataTransaction.id)
    }
  }, [])

  const obj = {
    "single": "Single Use",
    "multi_select": "Multi Select",
    "multiple": "Multi Use"
  }

  const columns = [
    {
      title: "",
      width: 80,
      key: "operate",
      render: () => <img src="/icons/move.svg" className="move-svg" />,
    },
    {
      title: "Order",
      width: 100,
      render: (row) => (
        <div>
          {
            row?.sort_order + 1 || " "
          }
        </div>
      ),
    },
    {
      title: "Template Name",
      key: "template_name",
      render: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row?.template?.template_name || "N/A"
            }
          </div>
        </div>
      ),
    },
    {
      title: "Association",
      render: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row?.template?.association || "N/A"
            }
          </div>
        </div>
      ),
    },
    {
      title: "Type",
      render: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.template?.checklist?.name || "N/A"
            }
          </div>
        </div>
      ),
    },
    {
      title: "Use Type",
      render: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              obj[row?.template?.use_type] || ""
            }
          </div>
        </div>
      ),
    },
    {
      title: "Hand Signed Only",
      grow: 2,
      render: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.template?.is_hand_signed ? "Yes" : "No"
            }
          </div>
        </div>
      ),
    },
    {
      title: "Actions",
      render: (row) => (
        <div>
          <a onClick={() => window.open(row?.template?.template_link, "_blank")}>
            <img src="/icons/view.svg" className="svg" data-uk-tooltip="Preview Document" />
          </a>
        </div>
      ),
    },
  ];

  const dragProps = {
    async onDragEnd(fromIndex, toIndex) {
      const tbl = [...tblData];
      const item = tbl.splice(fromIndex, 1)[0];
      tbl.splice(toIndex, 0, item);
      tbl.forEach((t, index) => {
        t.sort_order = index;
      });
      const data = tbl.filter((t) => (t.id ? true : false));
      setTblData(data)

      // store updated data of sortOrder in state for every drag change 
      setDataForUpdateFormListOrder(data);
    },
    handleSelector: "img",
  };

  const onDelete = (id) => {
    confirmAlert({
      title: DELETE_MODAL_TITLE,
      message: DELETE_MODAL_MESSAGE,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(setLoadingStatus(true))
            dispatch(removeTaskFromTransaction(id, logged_user.id))
              .then(() => {
                dispatch(getTransactionEdocById(dataTransaction.id))
                  .then(() => {
                    dispatch(setLoadingStatus(false))
                  })
                  .catch(() => {
                    dispatch(setLoadingStatus(false))
                  })
              })
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  return (
    <>
      <div className="drag_list_view">
        <ReactDragListView {...dragProps}>
          <Table columns={columns} pagination={false} dataSource={tblData} bordered />
        </ReactDragListView>
      </div>
    </>
  );
};

export default FormEditTable;
