import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormPage from "../../../../common/FormPage";
import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import AsterixTypo from "../../../../common/AsterixTypo";
import SubmitButton from "../../../../common/buttons/SubmitButton";
import LinkButton from "../../../../common/buttons/LinkButton";
import {
  getTransactionEdocById,
  getTransactionEsignById,
  upsertTransaction,
} from "../../../../../redux/actions/transaction";
import { getFormattedData, getPlainTransactionData } from "../../../../../utils/stepFilter";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "../../../../../utils/query-params";
import { toast } from "react-toastify";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import TransactionService from "../../../../../services/TransactionService";

const schema = yup.object().shape({});

const AddExclusion = ({ transactionId }) => {
  const dispatch = useDispatch();
  const query = useQueryParams();
  let history = useHistory();

  const totalExclusion = document.getElementsByClassName("exclusion")?.length || 0;
  const exclusionArr = Array(totalExclusion).fill().map((v, i) => `exclusion${i}`);

  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const dataDeal = dataTransaction || {};
  const dataExclusion = dataDeal?.t_purchase_exclusion || [];

  const doc_id = query.get('doc_id');

  useEffect(() => {
    doc_id && fetchExclusionData(doc_id);
  }, [doc_id]);

  const fetchExclusionData = async (doc_id) => {
    try {
      dispatch(setLoadingStatus(true));
      const { data } = await TransactionService.getExclusionDoc(doc_id);
      const exclusion = data?.data?.exclusion || "";
      if (exclusion) {
        exclusion.split("/:/").forEach((str, index) => {
          setValue(`exclusion${index + 1}`, str);
        });
      }
      dispatch(setLoadingStatus(false));
    } catch (error) {
      dispatch(setLoadingStatus(false));
      toast.error("Error occurs, While fetching data.", {
        autoClose: 2000
      })
    }
  }

  /* Form Validation */
  const { register, handleSubmit, setValue, getValues } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const handleRegisteration = (exclusions, isContinue = true) => {

    //Define plain string
    let dataStr = "";

    //Format the data
    const formattedExclusions = getFormattedData(exclusions);

    const totalExclusion = document.getElementsByClassName("exclusion")?.length || 0;
    const exclusionArr = Array(totalExclusion).fill().map((v, i) => `exclusion${i + 1}`);
    const blankExclusionStr = Array(totalExclusion).fill().map((v, i) => "/:/").join("").slice(0, -3);

    /**
     * Make a string of all exclusions as below
     * <Value1>/:/<Value2>/:/<Value3>
    */
    exclusionArr.forEach((key) => {
      dataStr = dataStr.concat(formattedExclusions[key] || "", "/:/");
    });

    //Remove last sperator(/:/) from string
    dataStr = dataStr.slice(0, -3);

    //Add data string in our payload
    formattedExclusions.exclusion = dataStr;

    //If docId is available then send it by payload
    if (doc_id) {
      formattedExclusions["id"] = doc_id;
    }

    if (["", blankExclusionStr].includes(dataStr)) {
      toast.error("Error occurs, please enter a exclusion.", {
        autoClose: 2000
      })
      return;
    }

    //Showing the loader
    dispatch(setLoadingStatus(true));

    //API call to update exclusion data
    dispatch(
      upsertTransaction({
        step: {
          db: "purchase_esign_exclusion",
          main: formattedExclusions,
        },
        transaction: {
          isBelonged: false,
          main: {
            ...getPlainTransactionData(dataTransaction),
            task_type: "deal",
          },
        },
      } ,"Purchase Esign Exclusion")
    ).then((data) => {
      //Fetch transaction data
      dispatch(getTransactionEsignById(data.id));
      dispatch(getTransactionEdocById(data.id)).then(res => {

        //Hide loader
        dispatch(setLoadingStatus(false));

        //Showing toastr message
        toast.success("Data updated successfully", {
          autoClose: 2000
        });

        //If continue is true the redirect to the signers page.
        if (isContinue) {
          history.push(`/deals/view/${transactionId}/purchase-&-sale-agreement-exclusion?doc_id=${query.get('doc_id')}`);
        }
      })
        .catch((err) => {

          //Hide loader
          dispatch(setLoadingStatus(false));
          throw err
        })
    })
      .catch((err) => {
        //Hide loader
        dispatch(setLoadingStatus(false));

        toast.error("Error occurs, please try again.", {
          autoClose: 2000
        })
      })

  };



  const handleError = () => {
    toast.error("Error occurs, please add at least one exclusion.", {
      autoClose: 2000
    })
  };

  const links = [
    { label: "Deals", link: `/deals/view/${transactionId}/main` },
    { label: "Add a Deal", link: null },
  ];

  const vesselName = `${dataTransaction?.t_vessel?.year || ""} ${dataTransaction?.t_vessel?.make || ""} ${dataTransaction?.t_vessel?.model || ""} ${dataTransaction?.t_vessel?.length || ""}`;
  const uniqueTransactionId = dataTransaction?.unique_transaction_id ? `PS-${dataTransaction?.unique_transaction_id}` : '';

  return (
    <FormPage links={links} title="Exclusion" vesselName={vesselName} uniqueId={uniqueTransactionId}>
      <Form onSubmit={handleSubmit(handleRegisteration, handleError)}>
        <FormRow>
          <FormInputWrapper label="Exclusion #1" size="two">
            <input {...register("exclusion1")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #2" size="two">
            <input {...register("exclusion2")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #3" size="two">
            <input {...register("exclusion3")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #4" size="two">
            <input {...register("exclusion4")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #5" size="two">
            <input {...register("exclusion5")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #6" size="two">
            <input {...register("exclusion6")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #7" size="two">
            <input {...register("exclusion7")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #8" size="two">
            <input {...register("exclusion8")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #9" size="two">
            <input {...register("exclusion9")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #10" size="two">
            <input {...register("exclusion10")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #11" size="two">
            <input {...register("exclusion11")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #12" size="two">
            <input {...register("exclusion12")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #13" size="two">
            <input {...register("exclusion13")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #14" size="two">
            <input {...register("exclusion14")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #15" size="two">
            <input {...register("exclusion15")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #16" size="two">
            <input {...register("exclusion16")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #17" size="two">
            <input {...register("exclusion17")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #18" size="two">
            <input {...register("exclusion18")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #19" size="two">
            <input {...register("exclusion19")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
          <FormInputWrapper label="Exclusion #20" size="two">
            <input {...register("exclusion20")} className="uk-input exclusion" type="text" />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <SubmitButton>Save & Continue</SubmitButton>
          <LinkButton
            to={`/deals/view/${transactionId}/main`}
            onClick={() => handleRegisteration(getValues(), false)}
          >
            Save & Finish Later
          </LinkButton>
        </FormRow>
        <AsterixTypo isVisible={false} />
      </Form>
    </FormPage>
  );
};

export default AddExclusion;
