import React, { useEffect, useState } from "react";
import ReactDragListView from "react-drag-listview";
import { Table } from "antd";
import "antd/dist/reset.css";
import { useDispatch } from "react-redux";
import { reOrderTasks } from "../../../../../redux/actions/transaction";
import { useParams } from "react-router-dom";
import Auth from "../../../../../utils/auth";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import TransactionService from "../../../../../services/TransactionService";

const TaskEditTable = ({ show, transactionId, companyId, setDataForUpdateTaskListOrder }) => {

  const dispatch = useDispatch();
  const [tblData, setTblData] = useState([]);
  const logged_user = Auth.getInstance().getUserData();
  const urlParams = useParams();

  useEffect(() => {
    if (show) {
      getCompanyTagList();
    }
  }, [show]);

  const getCompanyTagList = async () => {
    dispatch(setLoadingStatus(true));
    const dataTasks = (await TransactionService.getEdocCompanyTaskById(companyId, transactionId)).data;
    const dataDocument = await dataTasks.filter((task) => {
      return task.task_type === "task";
    });
    setTblData(dataDocument);
    dispatch(setLoadingStatus(false));
  }

  const columns = [
    {
      title: "",
      width: 80,
      key: "operate",
      render: () => <img src="/icons/move.svg" className="move-svg" />,
    },
    {
      title: "Order",
      width: 100,
      render: (row) => (
        <div>
          {
            row?.sort_order + 1 || " "
          }
        </div>
      ),
    },
    {
      title: "Task",
      key: "task",
      render: (data, record, index) => {
        const task = tblData[index];
        return (
          <div>{task.company_tasks?.title || ""}</div>
        );
      },
    },
  ];

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const tbl = [...tblData];
      const item = tbl.splice(fromIndex, 1)[0];
      tbl.splice(toIndex, 0, item);
      tbl.forEach((t, index) => {
        t.sort_order = index;
      });
      const data = tbl.filter((t) => (t.master_task_id ? true : false));
      setTblData(data);

      // store updated data of sortOrder in state for every drag change 
      setDataForUpdateTaskListOrder(data);
    },
    handleSelector: "img",
  };

  // ** Commenting this feature as per client requirement */
  // const handleAdd = (evt) => {
  //   let newTask = tblData.filter((t) => !t.master_task_id)[0];
  //   if (newTask) {
  //     if (newTask.company_tasks?.title !== "") {
  //       dispatch(getChecklist())
  //         .then((res) => {
  //           const checklist = res;
  //           const checklist_id = checklist.filter((c) => c.short_name === "deal")[0].id;
  //           newTask.checklist_id = checklist_id;
  //           newTask.transaction_id = transactionId;
  //           newTask.actionBy = logged_user.id
  //           newTask.task_type = "task";
  //           newTask.file_url = "";
  //           newTask.status = "open";
  //           newTask['company_tasks'] = {
  //             title: newTask.company_tasks?.task || '',
  //             active: true,
  //             create_type: 'custom',
  //             task_type: 'task',
  //             checklist_id: checklist_id,
  //             sort_order: newTask.sort_order,
  //             company_id: companyId
  //           }
  //           dispatch(insertTaskToTransaction(newTask))
  //             .then((res) => {
  //               dispatch(getTransactionEdocById(urlParams.id));
  //             })
  //             .catch((err) => {
  //               dispatch(getTransactionEdocById(urlParams.id));
  //             })
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     }
  //   } else {
  //   }
  //   const tbl = [...tblData, {}];
  //   setTblData(tbl);
  // };

  if (!show) {
    return <></>;
  }
  return (
    <>
      <div className="drag_list_view">
        <ReactDragListView {...dragProps}>
          <Table
            columns={columns}
            pagination={false}
            dataSource={tblData}
            bordered
          // showHeader={false}
          />
        </ReactDragListView>
      </div>

      {/* ** Commenting this feature as per client requirement */}
      {/* <button className="uk-button uk-button-default" style={{ margin: "8px" }} onClick={handleAdd}>
        Add
      </button> */}
    </>
  );
};

export default TaskEditTable;
