import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import StepWizard from "react-step-wizard";

import { Nav } from './steps/Nav';
import { UploadDocumentStep } from './steps/UploadDocumentStep';
import { PlaceFieldsStep } from './steps/PlaceFieldsStep';
import { ReviewStep } from './steps/ReviewStep';

import { findDocumentTemplate } from '../../../redux/actions/document_tasks';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Form.scss';

const DocumentForm = (props) => {
  const dispatch = useDispatch();
  
  const template_id = props.location.state ? props.location.state.id : null;

  useEffect(() => {
    if (!stepState.form.template_id && template_id) {
      dispatch(findDocumentTemplate(template_id)).then(res => {
        const { form } = stepState;
        form['file_name'] = res.template_filename;
        form['file_link'] = res.template_link;
        form['elements'] = res.template_fields;
        form['template_title'] = res.template_name;
        form['template_id'] = template_id;
        form['width'] = res.width;
        form['height'] = res.height;
        form['checklist_id'] = res.checklist_id;
        form['iyba_task_id'] = res.iyba_task_id;
        form['is_hand_signed'] = res?.is_hand_signed || false;

        updateStepState({
          ...stepState,
          form,
        });
      }).catch(err => {
        console.log(err);
      })
    }
  }, []);

  const [stepState, updateStepState] = useState({
    form: {
      file_name: null,
      template_file: null,
      file_link: null,
      elements: [],
      template_title: null,
      template_id: null,
      width: null,
      height: null,
      checklist_id: null,
      iyba_task_id: null,
      is_hand_signed: false
    },
    transitions: {
      enterRight: `animated enterRight`,
      enterLeft: `animated enterLeft`,
      exitRight: `animated exitRight`,
      exitLeft: `animated exitLeft`,
      intro: `animated intro`,
    },
  });

  const updateForm = (key, value) => {
    const { form } = stepState;

    form[key] = value;
    updateStepState({
      ...stepState,
      form,
    });
  };

  // Do something on step change
  const onStepChange = (stats) => {
  };

  return (
    <div className="create-template-container">
      <div className="col-12 rsw-wrapper">
        <StepWizard
          onStepChange={onStepChange}
          isHashEnabled
          transitions={stepState.transitions} // comment out for default transitions
          nav={<Nav form={stepState.form} update={updateForm} />}
          isLazyMount
        >
          <UploadDocumentStep hashKey={'upload-document'} form={stepState.form} update={updateForm} />
          <PlaceFieldsStep hashKey={'place-fields'} form={stepState.form} update={updateForm} />
          <ReviewStep hashKey={'review-and-send'} form={stepState.form} update={updateForm} />
        </StepWizard>
      </div>
    </div>
  );
};

export default DocumentForm;