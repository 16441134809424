import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Switch, Route, useRouteMatch, useHistory, useParams } from "react-router-dom";
import StepBar from "./layouts/StepBar";
import AddVessel from "./layouts/forms/AddVessel";
import AddBuyer from "./layouts/forms/AddBuyer";
import AddCoSeller from "./layouts/forms/AddCoSeller";
import AddCoBuyer from "./layouts/forms/AddCoBuyer";
import AddSeller from "./layouts/forms/AddSeller";
import AddToys from "./layouts/forms/AddToys";
import AddOffer from "./layouts/forms/AddOffer";
import AddExclusions from "./layouts/forms/AddExclusions";
import AddAddendum from "./layouts/forms/AddAddendum";
import AddTradeInVessel from "../View/options/steps/AddTradeInVessel";
import AddCoBroker from "./layouts/forms/AddCoBroker";
import AddEsign from "./layouts/forms/AddEsign";
import EditToy from "./layouts/forms/EditToy";
import {
  clearTransaction,
  getTransactionEdocById,
  getTransactionInitialById,
  updateRequiredFields,
} from "../../../redux/actions/transaction";
import { clearVessel } from "../../../redux/actions/vessels_toys";
import { clearClient } from "../../../redux/actions/clients";
import AddTerms from "./layouts/forms/AddTerms";
import {
  CORPORATE_OWNED,
  ENTITY_AND_OR_ASSIGN,
  JOINT_OWNER,
  LEGAL_AND_OR_ASSIGN,
  LEGAL_OR_ASSIGN,
  LEGAL_OWNER,
  LLC_OWNED,
  RECORD_OWNER,
  SOLE_OWNER,
  TRUST_OWNED,
} from "../../../utils/signer";
import TrailerForm from "./layouts/forms/Trailer";

const DealEditPage = () => {
  let { path, url } = useRouteMatch();
  let params = useParams();

  const dispatch = useDispatch();
  let { id } = params;
  let history = useHistory();


  const [asterixSteps, setAsterixSteps] = useState({});
  const dataDeal = useSelector((state) => state.transactions.transaction || {});

  const steps = [
    "vessel",
    "buyer",
    "co_buyer",
    "seller",
    "co_seller",
    "toys",
    "trailer",
    "trade-in-vessel",
    "offer",
    "terms",
    "exclusions",
    // "addendum",
    "co_broker",
    // "send_to_signer",
  ];

  const step = steps.indexOf(window.location.pathname.split("/").pop());

  const [stepNum, setStepNum] = useState(0);

  const handleContinueStep = (step) => {
    history.push(`/deals/add/${id}/${steps[step]}`);
    setStepNum(step);
  }

  useEffect(() => {
    if (id !== "new") {
      dispatch(getTransactionInitialById(id));
      dispatch(getTransactionEdocById(id));
    } else {
      dispatch(clearVessel());
      dispatch(clearClient());
    }

    return () => {
      dispatch(clearClient());
      dispatch(clearTransaction());
      dispatch(clearVessel());
    };
  }, []);

  useEffect(() => {
    const dataVessel = dataDeal.t_vessel || {};
    const dataOffer = dataDeal.t_offers || {};
    const dataBuyer = dataDeal.buyer || {};
    const dataCoBuyer = dataDeal.co_buyer || {};
    const dataSeller = dataDeal.seller || {};
    const dataCoSeller = dataDeal.co_seller || {};
    const dataCoBroker = dataDeal.co_broker || {};
    const dataTerms = dataDeal.t_terms || {};
    const dataTrailer = dataDeal.t_trailer || []

    const vesselFields = {
      hull_number: "Hull No.",
      length: "Length",
      make: "Make",
      // model: "Model",
      // boat_type: "Boat Type",
      year: "Year",
    };
    const buyerFields = {
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      mobile_phone: "Mobile Phone",
    };
    const jointBuyerFields = {
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      mobile_phone: "Mobile Phone",
    };
    const coBuyerFields = {
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      mobile_phone: "Mobile Phone",
    };
    const offerFields = {
      offer_date: "Offer Date",
      offer_expiration_date: "Offer Expiration Date",
      accept_reject_date: "Accept and Reject Date",
      closing_date: "Closing Date",
      purchase_price: "Purchase Price",
      // less_deposit: "Less Deposit",
    };
    const sellerFields = {
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      mobile_phone: "Mobile Phone",
    };
    const coSellerFields = {
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      mobile_phone: "Mobile Phone",
    };
    const termsFields = {
      dispute_location: "Dispute Location",
      // dispute_location_state: "Dispute Location State",
      dispute_location_city: "Dispute Location City",
    };

    const trailerFields = {
      make: "Make",
      year: "Year",
      vin: "VIN"
    }

    const sellingCoBrokerFields = {
      selling_broker_email: "Selling Broker Email",
      selling_brokerage_broker: "Selling Broker Name",
      selling_broker_phone_number: "Selling Broker Phone Number",
    }

    const listingCoBrokerFields = {
      listing_broker_email: "Listing Broker Email",
      listing_brokerage_broker: "Listing Broker Name",
      listing_broker_phone_number: "Listing Broker Phone Number",
    }

    const groupFields = {
      trust_name: "Corporation/LLC/Trust Name",
      // trust_tax_id: "Corporation/LLC/Trust Tax ID",
      trust_address: "Corporation/LLC/Trust Address",
      trust_city: "Corporation/LLC/Trust City",
      trust_state: "Corporation/LLC/Trust State",
      trust_signer_name: "Corporation/LLC/Trust Name of Signer",
      trust_signer_email: "Corporation/LLC/Trust Email",
      trust_signer_title: "Corporation/LLC/Trust Title of Signer",
    }

    const assigneeFields = {
      // assignee_first_name: "Assignee First Name",
      // assignee_last_name: "Assignee Last Name",
      // assignee_email: "Assignee Email",
    }

    let vesselRequireds = [];
    let buyerRequireds = [];
    let coBuyerRequireds = [];
    let offerRequireds = [];
    let sellerRequireds = [];
    let coSellerRequireds = [];
    let termsRequireds = [];
    let coBrokerRequireds = [];
    let trailerRequireds = [];

    Object.keys(vesselFields).forEach((key) => {
      if (!dataVessel[key]) {
        vesselRequireds.push({ [key]: vesselFields[key] });
      }
    });
    // if (!(dataVessel['doc_no'] || dataVessel['reg_no'] || dataVessel['title_no'])) {
    //   vesselRequireds.push({ 'doc_reg_title_no': 'Doc No, Reg No, Title No' });
    // }
    if (dataVessel.is_old_built == true) {
      vesselRequireds.splice(vesselRequireds.findIndex(field => field['hull_number']), 1);
    }
    if (dataVessel.hull_number?.length < 12 && dataVessel.is_old_built === false) {
      vesselRequireds.push({ 'hull_number': 'Invalid Hull Number' });
    }

    if ([ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataDeal?.buyer_type) > -1) {
      Object.keys(groupFields).forEach((key) => {
        if (!dataBuyer[key]) {
          buyerRequireds.push({ [key]: groupFields[key] });
        }
      });
    } else if ([LEGAL_OWNER, LEGAL_OR_ASSIGN, LEGAL_AND_OR_ASSIGN].indexOf(dataDeal?.buyer_type) > -1) {
      Object.keys(buyerFields).forEach((key) => {
        if (!dataBuyer[key]) {
          buyerRequireds.push({ [key]: buyerFields[key] });
        }
      });
    } else if ([JOINT_OWNER].indexOf(dataDeal?.buyer_type) > -1) {
      Object.keys(buyerFields).forEach((key) => {
        if (!dataBuyer[key]) {
          buyerRequireds.push({ [key]: buyerFields[key] });
        }
      });
    } else {
      buyerRequireds.push({ title: 'Buyer Role' });
    }

    if ([LEGAL_OR_ASSIGN, LEGAL_AND_OR_ASSIGN].indexOf(dataDeal?.buyer_type) > -1) {
      Object.keys(assigneeFields).forEach((key) => {
        if (!dataBuyer[key]) {
          buyerRequireds.push({ [key]: assigneeFields[key] });
        }
      });
    }

    Object.keys(coBuyerFields).forEach((key) => {
      if (!dataCoBuyer[key]) {
        coBuyerRequireds.push({ [key]: coBuyerFields[key] });
      }
    });
    Object.keys(offerFields).forEach((key) => {
      if (!dataOffer[key]) {
        offerRequireds.push({ [key]: offerFields[key] });
      }
    });

    if ([TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].includes(dataDeal?.seller_type)) {
      Object.keys(groupFields).forEach((key) => {
        if (!dataSeller[key]) {
          sellerRequireds.push({ [key]: groupFields[key] });
        }
      });
    } else if ([LEGAL_OWNER].includes(dataDeal?.seller_type)) {
      Object.keys(sellerFields).forEach((key) => {
        if (!dataSeller[key]) {
          sellerRequireds.push({ [key]: sellerFields[key] });
        }
      });
    } else if ([JOINT_OWNER].includes(dataDeal?.seller_type)) {
      Object.keys(sellerFields).forEach((key) => {
        if (!dataSeller[key]) {
          sellerRequireds.push({ [key]: sellerFields[key] });
        }
      });
    } else if ([RECORD_OWNER].includes(dataDeal?.seller_type)) {
      if (!dataDeal['seller_type']) sellerRequireds.push({ title: 'Seller Role' });
    } else {
      sellerRequireds.push({ title: 'Seller Role' });
    }

    Object.keys(coSellerFields).forEach((key) => {
      if (!dataCoSeller[key]) {
        coSellerRequireds.push({ [key]: coSellerFields[key] });
      }
    });
    Object.keys(termsFields).forEach((key) => {
      if (!dataTerms[key]) {
        termsRequireds.push({ [key]: termsFields[key] });
      }
      if (dataTerms['dispute_location'] === 'B') {
        termsRequireds.pop();
      }
    });

    if (["selling", "both"].includes(dataCoBroker?.broker_type)) {
      Object.keys(sellingCoBrokerFields).forEach(key => {
        if (!dataCoBroker[key]) {
          coBrokerRequireds.push({ [key]: sellingCoBrokerFields[key] });
        }
      });
    }

    if (["listing", "both"].includes(dataCoBroker?.broker_type)) {
      Object.keys(listingCoBrokerFields).forEach(key => {
        if (!dataCoBroker[key]) {
          coBrokerRequireds.push({ [key]: listingCoBrokerFields[key] });
        }
      });
    }

    Object.keys(trailerFields).forEach((key) => {
      if (dataTrailer.length == 0) {
        trailerRequireds.push({ [key]: trailerFields[key] });
      }
      dataTrailer.forEach(element => {
        if (!element[key]) {
          trailerRequireds.push({ [key]: trailerFields[key] });
        }
      });
    })

    dispatch(
      updateRequiredFields({
        vessel: vesselRequireds,
        buyer: buyerRequireds,
        co_buyer: coBuyerRequireds,
        offer: offerRequireds,
        seller: sellerRequireds,
        co_seller: coSellerRequireds,
        terms: termsRequireds,
        co_broker: coBrokerRequireds,
        trailer: trailerRequireds
      })
    );
  }, [dataDeal]);

  const addAsterix = (val) => {
    setAsterixSteps(Object.assign(new Object(), asterixSteps, val));
  };

  // useEffect(() => {
  //   console.log("====================== StepNum Use Effect ==============================");
  //   if (stepNum !== undefined) {
  //     console.log("===================== Use Effect ========================");
  //     history.push(`/deals/add/${id}/${steps[stepNum]}`);
  //   }
  // }, [stepNum]);

  return (
    <div>
      <StepBar
        setStep={setStepNum}
        stepNum={stepNum}
        asterix={{ ...asterixSteps }}
        setAsterix={addAsterix}
      />
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/vessel`} />
        </Route>
        <Route
          path={`${path}/vessel`}
          render={() => <AddVessel step={step} setStep={handleContinueStep} setAsterix={addAsterix} />}
        />
        <Route path={`${path}/buyer`} render={() => <AddBuyer step={step} setStep={handleContinueStep} setAsterix={addAsterix} />} />
        <Route
          path={`${path}/co_buyer`}
          render={() => <AddCoBuyer step={step} setStep={handleContinueStep} setAsterix={addAsterix} />}
        />
        <Route
          path={`${path}/seller`}
          render={() => <AddSeller step={step} setStep={handleContinueStep} setAsterix={addAsterix} />}
        />
        <Route
          path={`${path}/co_seller`}
          render={() => <AddCoSeller step={step} setStep={handleContinueStep} setAsterix={addAsterix} />}
        />
        <Route
          exact
          path={`${path}/toys`}
          render={() => <AddToys step={step} setStep={handleContinueStep} setAsterix={addAsterix} />}
        />
        <Route exact path={`${path}/toys/edit`} component={EditToy} />
        <Route
          exact
          path={`${path}/trailer`}
          render={() => (
            <TrailerForm
              step={step}
              setStep={handleContinueStep}
              setAsterix={addAsterix}
            />
          )}
        />
        <Route path={`${path}/trailer/edit`} exact render={(props) => {
          return (
            <TrailerForm id={props.location.state.id} />
          )
        }} />
        <Route
          path={`${path}/trade-in-vessel`}
          render={() => <AddTradeInVessel step={step} setStep={handleContinueStep} setAsterix={addAsterix} />}
        />
        <Route
          path={`${path}/offer`}
          render={() => <AddOffer step={step} setStep={handleContinueStep} setAsterix={addAsterix} />}
        />
        <Route
          path={`${path}/terms`}
          render={() => <AddTerms step={step} setStep={handleContinueStep} setAsterix={addAsterix} />}
        />
        <Route
          path={`${path}/exclusions`}
          render={() => (
            <AddExclusions step={step} setStep={handleContinueStep} setAsterix={addAsterix} />
          )}
        />
        {/* <Route
          path={`${path}/addendum`}
          render={() => <AddAddendum step={step} setStep={handleContinueStep} setAsterix={addAsterix} />}
        /> */}
        <Route
          path={`${path}/co_broker`}
          render={() => <AddCoBroker step={step} setStep={handleContinueStep} setAsterix={addAsterix} />}
        />
        <Route path={`${path}/send_to_signer`} render={() => <AddEsign />} />
        <Redirect to={`${url}/vessel`} />
      </Switch>
    </div>
  );
};

export default DealEditPage;
