const makes = `CAT
Cox
Crusader
Cummins
Detroit Diesel
Evinrude
Honda 
Indmar
Lehman Power
Mann
Mercruiser
Mercury 
MTU
Perkins
Seven Marine
Sterling
Suzuki
Kawasaki
Sea-Doo
Rotax
Tohatsu
Torqeedo
Volvo-Penta
Yamaha
Yanmar
Universal
`;

export default makes;