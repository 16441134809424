import {
  RETRIEVE_EDOCS,
  FIND_EDOC_BY_NAME,
} from "../actions/types";

const initialState = {
  list: [],
  edoc: null,
  error: {}
};

function eDocReducer(state = initialState, action) {

  switch (action.type) {
    case FIND_EDOC_BY_NAME.pending:
      return {
        ...state,
      };
    case FIND_EDOC_BY_NAME.success:
      return {
        ...state,
        list: action.payload
      };
    case FIND_EDOC_BY_NAME.error:
      return {
        ...state,
        error: action.payload
      };
    case RETRIEVE_EDOCS.pending:
      return {
        ...state
      };
    case RETRIEVE_EDOCS.success:
      return {
        ...state,
        list: action.payload
      };
    case RETRIEVE_EDOCS.error:
      return {
        ...state,
        error: action.payload
      }
    default:
      return state;
  }
}

export default eDocReducer;
