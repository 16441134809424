import { Switch, Route, Redirect, useRouteMatch, useParams } from "react-router-dom";
import AddAddendum from "./steps/AddAddendum";
import EsignAddendum from "./steps/EsignAddendum";
import AddendumStepBar from "./stepbars/AddendumStep";

const AddendumOption = () => {
  let { path } = useRouteMatch();
  let { id } = useParams();

  return (
    <div>
      <AddendumStepBar />
      <Switch>
        <Route exact path={path} render={() => <AddAddendum transactionId={id} />} />
        <Route path={`${path}/send_to_signer`} render={() => <EsignAddendum transactionId={id} />} />
        <Redirect to={path} />
      </Switch>
    </div>
  );
};

export default AddendumOption;
