export const tutorialsArray = [
  { title: "Listing Agreements", value: "listing_agreements" },
  { title: "Deals", value: "deals" },
  { title: "Invitation Deals", value: "invitation_deals" },
  { title: "MLS Vessels", value: "mls_vessels" },
  { title: "Client CRM", value: "client_crm" },
  { title: "Uploads & Tasks", value: "uploads_and_tasks" },
  { title: "Offices", value: "offices" },
  { title: "Users", value: "users" },
  { title: "Brokerage", value: "brokerage" },
  { title: "Participants", value: "deal_participants" },
];

export const tutorialsArrayDeals = [

  { title: "Prep Docs", deal_value: "deal_prepDocs", listing_agreements_value: "listing_agreements_prepDocs" },
  { title: "Signatures", deal_value: "deal_signatures", listing_agreements_value: "listing_agreements_signatures" },
  { title: "Uploads", deal_value: "deal_uploads", listing_agreements_value: "listing_agreements_uploads" },
  { title: "Tasks", deal_value: "deal_tasks", listing_agreements_value: "listing_agreements_tasks" },
  { title: "Forms", deal_value: "deal_forms", listing_agreements_value: "listing_agreements_forms" },
  { title: "Main Menu", deal_value: "deal_main_menu", listing_agreements_value: "listing_agreements_main_menu" },
];
