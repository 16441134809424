import React, { useEffect, useState } from 'react';
import UserService from '../../services/UserService';
import { useDispatch } from 'react-redux';
import { setLoadingStatus } from '../../redux/actions/loading';
import { Modal } from 'react-bootstrap';
import TransactionService from '../../services/TransactionService';
import { toast } from 'react-toastify';

const ChangeBrokerModal = ({ show, broker, user, transactionId, onClose, coBroker = null }) => {

    const [brokerList, setBrokerList] = useState([]);
    const [selectedBroker, setSelectedBroker] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        if (show) {
            getCompanyBrokerList();
        }
    }, [show]);

    /**
     * Get All Broker List of Selected Company
     */
    const getCompanyBrokerList = async () => {
        try {
            const companyId = user?.company_id
            dispatch(setLoadingStatus(true));
            let brokers = []
            if (companyId) {
                brokers = (await UserService.getBrokerList(companyId)).data.companyBrokers;
                setBrokerList(brokers);
            }
            if (brokers.filter((brk) => brk.id == broker.id).length > 0) {
                setSelectedBroker(broker.id);
            }
            dispatch(setLoadingStatus(false));
        } catch (error) {
            dispatch(setLoadingStatus(true));
            console.log(error);
        }
    }

    const handleSubmit = () => {
        if (selectedBroker) {
            if (selectedBroker == broker.id) {
                toast.info('Broker already selected.');
                handleCloseModal(false);
                return;
            }
            dispatch(setLoadingStatus(true));
            const brokerToSend = brokerList.find((broker) => broker.id == selectedBroker);
            let dataBody = {
                selectedBroker: brokerToSend
            };
            if (coBroker != null) {
                dataBody = {
                    ...dataBody,
                    coBrokerId: coBroker?.id
                }
            }
            TransactionService.changeTransactionBroker(transactionId, dataBody).then(
                response => {
                    dispatch(setLoadingStatus(false));
                    toast.success('Broker has been updated successfully.');
                    handleCloseModal(true);
                }
            ).catch(error => {
                dispatch(setLoadingStatus(false));
                toast.error('Something went wrong while updating broker!');
                console.log(error);
            })
        } else {
            toast.info('Please select a broker.')
            return false;
        }
    }

    const handleCloseModal = (isRefresh) => {
        onClose(isRefresh);
    }

    return (
        <Modal
            show={show}
            onHide={onClose}
            centered={true}>
            <Modal.Header closeButton>
                <h3>Update Broker</h3>
            </Modal.Header>
            <Modal.Body>
                <form style={{ padding: '10px', height: '150px' }} >
                    <div className="row">
                        <div className="col-md-12">
                            <label className="uk-form-label">Select Broker</label>
                            <select className="uk-select" name="broker" value={selectedBroker} onChange={(e) => { setSelectedBroker(e.target.value) }}>
                                <option value="">Select a broker</option>
                                {
                                    brokerList.map(broker => {
                                        return <option key={broker.id} value={broker.id}>{`${broker.first_name} ${broker.last_name}`}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className="uk-button uk-button-default uk-modal-close small" type="button" onClick={() => { handleCloseModal(false) }}>
                    Cancel
                </button>
                <button
                    className="uk-button uk-button-primary small"
                    type="button" onClick={handleSubmit}>Done</button>
            </Modal.Footer>
        </Modal>
    )
}

export default ChangeBrokerModal;
