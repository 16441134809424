import { phoneNumber } from "./phonenumber";
import { CORPORATE_OWNED, ENTITY_AND_OR_ASSIGN, JOINT_OWNER, LEGAL_AND_OR_ASSIGN, LEGAL_OR_ASSIGN, LEGAL_OWNER, LLC_OWNED, RECORD_OWNER, SOLE_OWNER, TRUST_OWNED } from "./signer";
import Country from "../constants/Country";
import State from "../constants/StatesForUSA";

export function getListingSellerInfo(type, seller) {
  let seller_name, seller_email, seller_phone, seller_address, seller_unit, seller_city_state_zip, seller_country;

  if ([JOINT_OWNER, SOLE_OWNER].indexOf(type) > -1) {
    seller_name = `${seller?.first_name || ""} ${seller?.last_name || ""}`;
    seller_name = seller_name === " " ? "N/A" : seller_name;
    seller_email = seller ? (seller?.email ? seller?.email : "N/A") : "N/A";
    seller_phone = seller ? (seller?.mobile_phone ? phoneNumber(seller?.mobile_phone) : "N/A") : "N/A";
    seller_address = `${seller?.address_1 || ""} ${seller?.address_2 || ""} ${seller?.address_3 || ""}`;
    seller_address = seller_address.replace(/ /g, "") ? seller_address : "N/A";
    seller_unit = seller?.unit_apt || "N/A";
    seller_city_state_zip = `${seller?.city || ""} ${seller?.state ? State.find((s) => s.code === seller?.state)?.name || "" : ""
      } ${seller?.postal_code || ""}`;
    seller_city_state_zip = seller_city_state_zip.replace(/ /g, "") ? seller_city_state_zip : "N/A";
    seller_country = seller?.country ? Country.find((c) => c.code === seller?.country)?.name || "" : "N/A";
  } else if ([CORPORATE_OWNED, TRUST_OWNED, LLC_OWNED].indexOf(type) > -1) {
    seller_name = seller?.trust_name || "N/A";
    seller_email = seller?.trust_signer_email || "N/A";
    seller_phone = phoneNumber(seller?.trust_signer_phone) || "N/A";
    seller_address = seller?.trust_address || "N/A";
    seller_unit = seller?.trust_unit_apt || "N/A";
    seller_city_state_zip = `${seller?.trust_city || ""} ${seller?.trust_state ? State.find(s => s.code === seller?.trust_state)?.name || "" : ""
      } ${seller?.trust_zip || ""}`;
    seller_country = seller?.trust_country || "N/A";
  } else {
    seller_name = "N/A";
    seller_email = "N/A";
    seller_phone = "N/A";
    seller_address = "N/A";
    seller_unit = "N/A";
    seller_city_state_zip = "N/A";
    seller_country = "N/A";
  }

  return {
    name: seller_name,
    email: seller_email,
    phone: seller_phone,
    address: seller_address,
    unit: seller_unit,
    city_state_zip: seller_city_state_zip,
    country: seller_country
  };
}

export function getDealBuyerInfo(type, buyer) {
  let buyer_name, buyer_email, buyer_phone, buyer_address, buyer_unit, buyer_city_state_zip, buyer_country;

  if ([LEGAL_OWNER, LEGAL_OR_ASSIGN, LEGAL_AND_OR_ASSIGN].indexOf(type) > -1) {
    buyer_name = `${buyer?.first_name || ""} ${buyer?.last_name || ""}`;
    buyer_name = buyer_name === " " ? "N/A" : buyer_name;
    buyer_email = buyer ? (buyer?.email ? buyer?.email : "N/A") : "N/A";
    buyer_phone = buyer ? (buyer?.mobile_phone ? phoneNumber(buyer?.mobile_phone) : "N/A") : "N/A";
    buyer_address = `${buyer?.address_1 || ""} ${buyer?.address_2 || ""} ${buyer?.address_3 || ""}`;
    buyer_address = buyer_address.replace(/ /g, "") ? buyer_address : "N/A";
    buyer_unit = buyer?.unit_apt || "N/A";
    buyer_city_state_zip = `${buyer?.city || ""} ${buyer?.state ? State.find((s) => s.code === buyer?.state)?.name || "" : ""
      } ${buyer?.postal_code || ""}`;
    buyer_city_state_zip = buyer_city_state_zip.replace(/ /g, "") ? buyer_city_state_zip : "N/A";
    buyer_country = buyer?.country ? Country.find((c) => c.code === buyer?.country)?.name || "" : "N/A";
  } else if ([CORPORATE_OWNED, TRUST_OWNED, LLC_OWNED, ENTITY_AND_OR_ASSIGN].indexOf(type) > -1) {
    buyer_name = buyer?.trust_name || "N/A";
    buyer_email = buyer?.trust_signer_email || "N/A";
    buyer_phone = phoneNumber(buyer?.trust_signer_phone) || "N/A";
    buyer_address = buyer?.trust_address || "N/A";
    buyer_unit = buyer?.trust_unit_apt || "N/A";
    buyer_city_state_zip = `${buyer?.trust_city || ""} ${buyer?.trust_state ? State.find(s => s.code === buyer?.trust_state)?.name || "" : ""
      } ${buyer?.trust_zip || ""}`;
    buyer_country = buyer?.trust_country || "N/A";
  } else {
    buyer_name = "N/A";
    buyer_email = "N/A";
    buyer_phone = "N/A";
    buyer_address = "N/A";
    buyer_unit = "N/A";
    buyer_city_state_zip = "N/A";
    buyer_country = "N/A";
  }

  return {
    name: buyer_name,
    email: buyer_email,
    phone: buyer_phone,
    address: buyer_address,
    unit: buyer_unit,
    city_state_zip: buyer_city_state_zip,
    country: buyer_country
  };
}

export function getDealSellerInfo(type, seller) {
  let seller_name, seller_email, seller_phone, seller_address, seller_unit, seller_city_state_zip, seller_country;

  if ([LEGAL_OWNER, RECORD_OWNER].indexOf(type) > -1) {
    seller_name = `${seller?.first_name || ""} ${seller?.last_name || ""}`;
    seller_name = seller_name === " " ? "N/A" : seller_name;
    seller_email = seller ? (seller?.email ? seller?.email : "N/A") : "N/A";
    seller_phone = seller ? (seller?.mobile_phone ? phoneNumber(seller?.mobile_phone) : "N/A") : "N/A";
    seller_address = `${seller?.address_1 || ""} ${seller?.address_2 || ""} ${seller?.address_3 || ""}`;
    seller_address = seller_address.replace(/ /g, "") ? seller_address : "N/A";
    seller_unit = seller?.unit_apt || "N/A";
    seller_city_state_zip = `${seller?.city || ""} ${seller?.state ? State.find((s) => s.code === seller?.state)?.name || "" : ""
      } ${seller?.postal_code || ""}`;
    seller_city_state_zip = seller_city_state_zip.replace(/ /g, "") ? seller_city_state_zip : "N/A";
    seller_country = seller?.country ? Country.find((c) => c.code === seller?.country)?.name || "" : "N/A";
  } else if ([CORPORATE_OWNED, TRUST_OWNED, LLC_OWNED].indexOf(type) > -1) {
    seller_name = seller?.trust_name || "N/A";
    seller_email = seller?.trust_signer_email || "N/A";
    seller_phone = phoneNumber(seller?.trust_signer_phone) || "N/A";
    seller_address = seller?.trust_address || "N/A";
    seller_unit = seller?.trust_unit_apt || "N/A";
    seller_city_state_zip = `${seller?.trust_city || ""} ${seller?.trust_state ? State.find(s => s.code === seller?.trust_state)?.name || "" : ""
      } ${seller?.trust_zip || ""}`;
    seller_country = seller?.trust_country || "N/A";
  } else {
    seller_name = "N/A";
    seller_email = "N/A";
    seller_phone = "N/A";
    seller_address = "N/A";
    seller_unit = "N/A";
    seller_city_state_zip = "N/A";
    seller_country = "N/A";
  }

  return {
    name: seller_name,
    email: seller_email,
    phone: seller_phone,
    address: seller_address,
    unit: seller_unit,
    city_state_zip: seller_city_state_zip,
    country: seller_country
  };
}
