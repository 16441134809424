import Portal from "./HelperTermPortal";

const ModalPortal = ({ children }) => {
  return (
    <div>
      <Portal>{children}</Portal>
    </div>
  );
};

export default ModalPortal;
