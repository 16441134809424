import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveClients, updateClient, findClientByName } from "../../redux/actions/clients";
import { Link, useRouteMatch } from "react-router-dom";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import datatableStyles from "../../utils/datatable-style";
import { DebounceInput } from "react-debounce-input";
import { toast } from "react-toastify";

import { phoneNumber } from '../../utils/phonenumber';
import Auth from "../../utils/auth";
import { getUser } from "../../redux/actions/users";
import ClientsService from "../../services/ClientService";
import { setLoadingStatus } from "../../redux/actions/loading";
import UserService from "../../services/UserService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logUserCompanyMismatch } from "../../utils/access";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Tooltip } from "@mui/material";
import VideoPlayerModal from "../common/videoPlayerModal";
import TutorialService from "../../services/TutorialService";


const ClientsList = () => {
  // const dataClients = useSelector((state) => state.clients.list);
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();

  //Make a state for search
  const [search, setSearch] = useState({});

  //Store users data for listing
  const [dataClients, setDataClients] = useState([]);

  //Store count of total rows
  const [totalRows, setTotalRows] = useState(0)

  const logged_user = Auth.getInstance().getUserData();
  const user = useSelector((state) => state.users.logged_by)
  const isLoading = useSelector((state) => state.loading.status);

  const [show, setShow] = useState(false);

  const [currentVideo, setCurrentVideo] = useState(null)

  const tutorials = useSelector((state) => state?.tutorials) || null;
  useEffect(() => {
    dispatch(getUser(logged_user.id))
  }, [])

  const [loadCount, setLoadCount] = useState(0);
  useEffect(() => {
    if (loadCount === 0) {
      dispatch(setLoadingStatus(false));
      setLoadCount(0);
    }
  }, [loadCount]);

  const fetchClientsData = (search) => {
    dispatch(setLoadingStatus(true));
    setLoadCount((prev) => prev + 1);
    ClientsService.findByName(search, logged_user.company_id, logged_user.company_office_id, logged_user.id, logged_user.UserInRole.UserRole.name)
      .then((res) => {
        const clients = res.data.data;
        for (let index = 0; index < clients.length; index++) {
          const element = clients[index];
          if (logged_user.UserInRole?.UserRole?.name != "Boatdox Admin" && logged_user.company_id != element.company_id) {
            logUserCompanyMismatch(element, 'Clients CRM List', history);
            break;
          }
        }
        const totalRecords = res.data.total;
        setDataClients(clients);
        setTotalRows(totalRecords);
        // dispatch(setLoadingStatus(false));
        setLoadCount((prev) => prev - 1);
      })
      .catch((error) => {
        dispatch(setLoadingStatus(false));
        setLoadCount((prev) => prev - 1);
        console.log(error);
        toast.error("Error occurs, please try again.", {
          autoClose: 2000
        })
      })
  }

  useEffect(() => {
    fetchClientsData(search)
  }, [search]);

  const onDelete = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const data = {
              deleted: true,
              deleted_at: new Date(),
            };
            dispatch(updateClient(id, data))
              .then((response) => {
                fetchClientsData(search)
                toast.info("Delete successfully", {
                  autoClose: 2000,
                });
              })
              .catch((e) => {
                toast.error("Error has occured.", {
                  autoClose: 2000,
                });
              });
          },
        },
        {
          label: "No",
          onClick: () => {
            toast.error("Canceled", {
              autoClose: 2000,
            });
          },
        },
      ],
    });
  };

  const columns = [
    {
      name: "Name",
      selector: "last_name",
      sortable: true,
      grow: 2,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>
              <Link to={{ pathname: `${path}/view`, state: { id: row.id } }}>
                {row.last_name + ", " + row.first_name}
              </Link>
            </div>
          </div>
        );
      },
    },
    {
      name: "Location",
      selector: "city",
      sortable: true,
      grow: 2,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>{row.city + ", " + row.country}</div>
          </div>
        );
      },
    },
    { name: "Email", selector: "email", sortable: true, center: false, grow: 2, },
    {
      name: "Mobile Phone",
      selector: "mobile_phone",
      sortable: true,
      center: false,
      grow: 2,
      cell: row => <div>{phoneNumber(row.mobile_phone)}</div>
    },
    {
      name: "Broker",
      selector: "broker_last_name",
      sortable: true,
      grow: 2,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            <div>{row.c_broker ? `${row.c_broker?.last_name} ${row.c_broker?.first_name}` : "N/A"}</div>
          </div>
        );
      },
    },
    {
      name: "",
      cell: (row) => {
        return (
          <div>
            <Link to={{ pathname: "/clients/edit", state: { client_id: row.id } }}>
              <Tooltip title="Edit" placement="left">
                <img src="/icons/edit.svg" className="uk-margin-right svg" alt="" />
              </Tooltip>
            </Link>
            {
              (user && (user.access_type && user.access_type == "full") || ["Company Admin", "Boatdox Admin"].includes(user.UserInRole.UserRole.name)) &&
              <Tooltip title="Delete" placement="right">
                <img src="/icons/delete.svg" className="svg" onClick={() => onDelete(row.id)} alt="" />
              </Tooltip>
            }
          </div>
        );
      },
    },
  ];

  //Function for handle row limit
  const handleRowsPerPage = (numberOfRows, page) => {
    setSearch((search) => {
      return {
        ...search,
        limit: numberOfRows,
        page: page
      }
    })
  }

  //Function for handle page no
  const handlePageChange = page => {
    setSearch((search) => {
      return {
        ...search,
        page: page
      }
    })
  };

  //Function for handle sort
  const handleSort = (column, sortDirection) => {
    // column.selector, sortDirection
    setSearch((search) => {
      return {
        ...search,
        sortOrder: sortDirection,
        sortField: column.selector
      }
    })
  };


  useEffect(() => {
    setCurrentVideo(tutorials.client_crm);
  }, [tutorials]);

  const onCloseVideoPlayerModal = () => {
    setShow(() => false);
  }

  return (
    <div className="uk-container uk-container-expand uk-position-relative">
      <div className="content">
        <h1 className="d-flex align-items-center gap-1">
          {currentVideo && <Tooltip title="Play Tutorial" arrow placement='top'  >
            <PlayCircleOutlineIcon fontSize="large" style={{ cursor: "pointer" }} className="mr-1" onClick={() => setShow(true)} />
          </Tooltip>}Clients CRM</h1>
        <div className="uk-column-1-2  uk-margin-small  ">
          {
            (user && (user.access_type && user.access_type == "full") || ["Company Admin", "Boatdox Admin"].includes(user.UserInRole.UserRole.name)) &&
            <Link to={{ pathname: `${path}/add`, state: { client_id: null } }}>
              <div className="uk-button uk-button-default add-deal my-2">
                <span className="uk-margin-small-right uk-icon" data-uk-icon="plus"></span> Add
              </div>
            </Link>
          }
          <div className="list-page-search-container my-2">
            <form className="uk-search uk-search-default search-top-margin">
              <DebounceInput
                className="uk-search-input"
                type="search"
                placeholder="Search"
                onChange={(e) => setSearch(state => {
                  return {
                    ...state,
                    name: e.target.value
                  }
                })}
                value={search.name ? search.name : ""}
                debounceTimeout={500}
                minLength={1}
              />
              {!search.name ? (
                <span className="bd-form-icon">
                  <i className="fa fa-search"></i>
                </span>
              ) : (
                <span className="bd-form-icon" onClick={() => setSearch(state => {
                  return {
                    ...state,
                    name: ""
                  }
                })}>
                  <i className="fa fa-times"></i>
                </span>
              )}
            </form>
          </div>
        </div>

        {/* Clients List Table */}

        {!dataClients.length && isLoading
          ? <div className="no-data-table"><div className="p-4">Fetching Details...</div></div>
          : <DataTable
            title="Clients Table"
            columns={columns}
            data={dataClients}
            defaultSortField="last_name"
            defaultSortAsc={true}
            striped={true}
            highlightOnHover={true}
            pointerOnHover={true}
            center={true}
            noHeader={true}
            fixedHeader={true}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationRowsPerPageOptions={[10, 20, 50, 100]}
            customStyles={datatableStyles}
            onChangeRowsPerPage={handleRowsPerPage}
            onChangePage={handlePageChange}
            onSort={handleSort}
            sortServer
            persistTableHead
          />
        }
      </div>
      {show && <VideoPlayerModal show={show} onClose={onCloseVideoPlayerModal} videoTitle={currentVideo} />}
    </div>
  );
};

export default ClientsList;
