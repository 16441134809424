import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from "@hookform/resolvers/yup";

import Form from '../../../../../common/Form';
import FormRow from '../../../../../common/FormRow';
import FormInputWrapper from '../../../../../common/FormInputWapper';
import { setLoadingStatus } from '../../../../../../redux/actions/loading';
import { getPlainTransactionData } from '../../../../../../utils/stepFilter';
import { getTransactionEsignById, upsertTransaction } from '../../../../../../redux/actions/transaction';

import * as yup from "yup";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Strikethrough, Heading, Subscript, Superscript, List, TodoList, Underline, ListProperties, Markdown } from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';
import 'ckeditor5-premium-features/ckeditor5-premium-features.css';
import MarkdownEditor from '../../../../../common/CkEditor/MarkdownEditor';

const TabFormNotes = ({ closePanel }) => {

    const schema = yup.object().shape({});

    const [note, setNote] = useState('');

    const { register, handleSubmit, setValue, reset, getValues, watch, formState: { isDirty, errors } } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: false,
    });

    const dispatch = useDispatch();

    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataDeal = dataTransaction || {};
    const dataDetail = dataDeal.t_details || [];

    useEffect(() => {
        const notes = dataDetail.filter((d) => d.type === "notes" && d.document_type === "seller");
        setValue("description", notes[notes.length - 1]?.description || '');
        setNote(notes[notes.length - 1]?.description || '')
    }, [dataTransaction]);

    const handleRegistration = (data) => {
        data.type = "notes";
        data.document_type = "seller";

        if (data) {
            dispatch(setLoadingStatus(true));
            dispatch(upsertTransaction({
                step: {
                    db: "details",
                    detailType: "notes",
                    main: data,
                },
                transaction: {
                    isBelonged: false,
                    main: { ...getPlainTransactionData(dataDeal), task_type: "deal" },
                },
            }, "Note's")
            ).then((data) => {
                dispatch(setLoadingStatus(false));
                dispatch(getTransactionEsignById(data.id));
            }).catch((error) => {
                dispatch(setLoadingStatus(false));
                console.log(error);
            });
        }
    };

    const handleError = (error) => {
        console.log(error);
    };

    return (
        <div className="form-container panel-form">
            <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                <FormRow>
                    <FormInputWrapper size="three" label="Notes">
                        {/* <CKEditor
                            name='description'
                            data={note}
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setNote(data);
                                setValue('description', data);
                            }}
                            config={{
                                toolbar: {
                                    items: [
                                        'heading',
                                        // '|', 'undo', 'redo',
                                        '|', 'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript',
                                        '|', 'bulletedList', 'numberedList'
                                    ],
                                },
                                plugins: [
                                    Markdown, Undo, Bold, Essentials, Italic, Underline, Mention, Paragraph, Heading, Strikethrough, Subscript, Superscript, List
                                ],
                                placeholder: 'Add Notes'
                            }}
                        /> */}
                        <MarkdownEditor noteProps={{ note, setNote }} setEditData={(data) => { setValue('description', data); }} />
                        {/* <textarea class="uk-textarea" rows="5" cols="10" {...register("description")} placeholder="Notes" aria-label="Notes"></textarea> */}
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <div className="text-right pt-3">
                        <button type="button" className='uk-button uk-button-orange' onClick={() => closePanel(true)}>Close</button>
                        <button type="submit" className='uk-button uk-button-primary'>Save</button>
                    </div>
                </FormRow>
            </Form>
        </div>
    )
}

export default TabFormNotes;