import GridForm from "../../../../common/GridForm";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import { getDealBuyerInfo } from "../../../../../utils/customer";

const DetailOfBuyer = ({ buyer, type }) => {
  const buyer_info = getDealBuyerInfo(type, buyer);

  return (
    <GridForm>
      <FormRow>
        <FormInputWrapper label="Name">
          <div className="input-view">{buyer_info.name}</div>
        </FormInputWrapper>
        <FormInputWrapper label="Entity Name">
          <div className="input-view">{buyer_info.entity_name || "N/A"}</div>
        </FormInputWrapper>
        {/* <FormInputWrapper label="And/Or Asssigns">
          <div className="input-view">{assigned ? "Yes" : "No"}</div>
        </FormInputWrapper> */}
      </FormRow>
      <FormRow>
        <FormInputWrapper size="two" label="Email *">
          <div className="input-view">{buyer_info.email}</div>
        </FormInputWrapper>
        <FormInputWrapper label="Mobile Phone">
          <div className="input-view">{buyer_info.phone}</div>
        </FormInputWrapper>
        <FormInputWrapper label="Citizen Of">
          <div className="input-view">{buyer_info.nationality || "N/A"}</div>
        </FormInputWrapper>
      </FormRow>
      <FormRow>
        <FormInputWrapper size="three" label="Address">
          <div className="input-view">{buyer_info.address} {buyer_info.unit || ""}</div>
          <div className="input-view">{buyer_info.city_state_zip}</div>
          <div className="input-view">{buyer_info.country}</div>
        </FormInputWrapper>
      </FormRow>
    </GridForm>
  );
};

export default DetailOfBuyer;
