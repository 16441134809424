import {
  CREATE_VESSEL_OR_TOY,
  RETRIEVE_VESSEL_OR_TOYS,
  GET_VESSEL_OR_TOY,
  UPDATE_VESSEL_OR_TOY,
  DELETE_VESSEL_OR_TOY,
  DELETE_ALL_VESSEL_OR_TOYS,
  FIND_VESSEL_OR_TOY_BY_NAME,
  FIND_VESSEL_OR_TOY_BY_HULLNUM,
  CLEAR_VESSEL,
  RETRIEVE_TOYS_BY_DEAL,
  CLEAR_LIST,
  GET_MLS_FILTERS
} from "./types";

import VesselAndToyService from "../../services/VesselAndToyService";
import { toast } from "react-toastify";

export const createVesselOrToy = (vesselOrToy) => async (dispatch) => {
  try {
    const res = await VesselAndToyService.create(vesselOrToy);

    dispatch({
      type: CREATE_VESSEL_OR_TOY,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const retrieveVesselOrToys = (type,search) => async (dispatch) => {
  try {
    const res = await VesselAndToyService.getByType(type,search);
    dispatch({
      type: RETRIEVE_VESSEL_OR_TOYS,
      payload: res.data,
    });
  } catch (err) {
    console.log("Error = ", err);
  }
};

export const updateVesselOrToy = (id, data) => async (dispatch) => {
  try {
    const res = await VesselAndToyService.update(id, data);

    dispatch({
      type: UPDATE_VESSEL_OR_TOY,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateTransactionVesselOrToy = (id, data) => async (dispatch) => {
  try {
    const res = await VesselAndToyService.updateTransactionVessel(id, data);

    dispatch({
      type: UPDATE_VESSEL_OR_TOY,
      payload: data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getVesselOrToy = (id) => async (dispatch) => {
  try {
    const res = await VesselAndToyService.get(id);
    dispatch({
      type: GET_VESSEL_OR_TOY,
      payload: res.data,
    });
  } catch (err) {
    console.log("Error = ", err);
  }
};

/**
 * Get Vessel from Global(settings) vessel list
 * @param {*} id 
 * @returns 
 */
export const getGlobalVesselOrToy = (id) => async (dispatch) => {
  try {
    const res = await VesselAndToyService.getVessel(id);
    dispatch({
      type: GET_VESSEL_OR_TOY,
      payload: res.data,
    });
  } catch (err) {
    console.log("Error = ", err);
  }
};

/**
 * This fucntion is use to store the current vessel data in redux
 * @param {ObjectId} userId 
 * @param {String} vesselId 
 * @returns 
 */
export const getMLSVessel = (userId, vesselId,transactionId) => async (dispatch) => {
  try {
    const res = await VesselAndToyService.getMLSVessel(userId, vesselId,transactionId);
    dispatch({
      type: GET_VESSEL_OR_TOY,
      payload: { "vessel": res.data },
    });
  } catch (err) {
    console.log("Error = ", err);
    toast.error(err.response.data.message || err.message);
  }
};

export const deleteVesselOrToy = (id) => async (dispatch) => {
  try {
    await VesselAndToyService.remove(id);

    dispatch({
      type: DELETE_VESSEL_OR_TOY,
      payload: { id },
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteAllVesselOrToys = (type) => async (dispatch) => {
  try {
    const res = await VesselAndToyService.removeByType(type);

    dispatch({
      type: DELETE_ALL_VESSEL_OR_TOYS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findVesselOrToyByName = (name, type) => async (dispatch) => {
  try {
    const res = await VesselAndToyService.findByName(name, type);

    dispatch({
      type: FIND_VESSEL_OR_TOY_BY_NAME,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const findVesselOrToyByHullNumber = (hullnum, type) => async (dispatch) => {
  try {
    const res = await VesselAndToyService.findByHullNumber(hullnum, type);

    dispatch({
      type: FIND_VESSEL_OR_TOY_BY_HULLNUM,
      payload: res.data,
    });
    return Promise.resolve(res.data);
  } catch (err) {
    console.log(err);
  }
};

export const clearVessel = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_VESSEL,
    });

    return;
  } catch (err) {
    return err;
  }
};

export const retrieveByDeal = (dealId) => async (dispatch) => {
  try {
    const res = await VesselAndToyService.findToysByDeal(dealId);

    dispatch({
      type: RETRIEVE_TOYS_BY_DEAL,
      payload: res.data,
    });
    Promise.resolve(res.data);
  } catch (err) {
    Promise.reject(err);
  }
};

export const clearList = () => async (dispatch) => {
  dispatch({
    type: CLEAR_LIST,
  });
};


export const getMLSFilters = () => async (dispatch) => {
  try {
    const res = await VesselAndToyService.retrieveMlsFilters();
    dispatch({
      type: GET_MLS_FILTERS,
      payload: res.data,
    });
    Promise.resolve(res.data);
  } catch (err) {
    Promise.reject(err);
  }
};
