
import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import Auth from '../../utils/auth';

const MainRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    Auth.getInstance().isAuthenticated() === true
      ? <Component {...props} /> : <Redirect to={'/auth/login'} />
      // : <ReactLoading type="spikes" height="20%" width="20%" color="#000000" />
  )}
  />
);


export default MainRoute;
