import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { sendEmailVerification } from '../../redux/actions/users';
import Auth from '../../utils/auth';
import { setLoadingStatus } from '../../redux/actions/loading';

const useStyles = makeStyles({
  verify: {
    top: '60px',
    left: '0px',
    color: '#111',
    height: '60px',
    paddingTop: '20px',
    fontSize: '18px',
    backgroundColor: '#D5DDE6',
    textAlign: 'center',
    position: 'fixed',
    width: '100%',
    zIndex: '999'
  }
});

const Verify = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const verifyFlow = () => {
    dispatch(setLoadingStatus(true));
    dispatch(sendEmailVerification(Auth.getInstance().getAuthToken())).then(res => {
      dispatch(setLoadingStatus(false));
      history.push('/auth/verify-email');
    }).catch(err => {
      dispatch(setLoadingStatus(false));
      console.log(err);
    });
  }

  return (
    <div className={classes.verify}>
      <span>Email verification required to send eSignature requests. <a onClick={verifyFlow}>Click here</a> to verify your email address.</span>
    </div>
  );
};

export default Verify;
