import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { getChecklist } from "../../redux/actions/document_tasks";
import datatableStyles from "../../utils/datatable-style";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Tooltip } from "@mui/material";
import VideoPlayerModal from "../common/videoPlayerModal";
import { setLoadingStatus } from "../../redux/actions/loading";
import TutorialService from "../../services/TutorialService";


const DocumentTaskList = (props) => {
  let { path } = useRouteMatch();
  const { model } = props.location.state;
  const dispatch = useDispatch();
  const dataCheckList = useSelector((state) => state.documents.checklist);
  const [show, setShow] = useState(false);

  const [currentVideo, setCurrentVideo] = useState(null)
  const tutorials = useSelector((state) => state?.tutorials) || null;

  useEffect(() => {
    dispatch(getChecklist());
  }, []);

  const columns = [
    {
      name: "Name",
      cell: (row) => {
        return (
          <Link
            to={{
              pathname: `${path}/list`,
              state: {
                checklist_id: row.id,
                path: path,
                breadcrumb: row.name,
                model: model,
              },
            }}
          >
            <span>{row.name}</span>
          </Link>
        );
      },
    },
    { name: "", cell: (row) => <span>{row.count}</span> },
  ];

  useEffect(() => {
    setCurrentVideo(tutorials?.uploads_and_tasks);
  }, [tutorials]);


  const onCloseVideoPlayerModal = () => {
    setShow(() => false);
  }

  return (
    <div className="uk-container uk-container-expand uk-position-relative">
      <div className="content">
        <h1 className="d-flex align-items-center gap-1">
          {currentVideo && <Tooltip title="Play Tutorial" arrow placement='top'  >
            <PlayCircleOutlineIcon fontSize="large" style={{ cursor: "pointer" }} className="mr-1" onClick={() => setShow(true)} />
          </Tooltip>}Uploads List & Tasks</h1>
        <DataTable
          columns={columns}
          data={dataCheckList}
          striped={true}
          highlightOnHover={true}
          noHeader={true}
          fixedHeader={true}
          customStyles={datatableStyles}
        />
      </div>
      {show && <VideoPlayerModal show={show} onClose={onCloseVideoPlayerModal} videoTitle={currentVideo} />}
    </div>
  );
};

export default DocumentTaskList;
