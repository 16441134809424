// System for American Numbering 
const bigNumWords = ['', 'thousand', 'million', 'billion', 'trillion'];
// System for uncomment this line for Number of English 
// const th_val = ['','thousand','million', 'milliard','billion'];
 
const oneWords = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
const tenWords = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
const tenDigitWords = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

export const toWordsConverter = number => {
  if (typeof number !== 'number') {
    return '';
  }
  let string = number.toString();
  string = string.replace(/[, ]/g, '');

  if (string !== '' + parseFloat(string))
    return 'not a number ';

  let numberOfDigits = string.indexOf('.');
  if (numberOfDigits === -1)
    numberOfDigits = string.length;
  if (numberOfDigits > 15)
    return 'too big';
  
  let numberArray = string.split('');
  let result = '';
  let toUpgrade = false;
  for (let i = 0; i < numberOfDigits; i++) {
    if ((numberOfDigits - i) % 3 === 2) {
      if (numberArray[i] === '1') {
        result += tenWords[Number(numberArray[i + 1])] + ' ';

        i++;
        toUpgrade = true;
      } else if (numberArray[i] !== '0') {
        result += tenDigitWords[numberArray[i] - 2] + ' ';

        toUpgrade = true;
      }
    } else if (numberArray[i] !== '0') {
      result += oneWords[numberArray[i]] + ' ';
      if ((numberOfDigits - i) % 3 === 0)
        result += 'hundred ';

      toUpgrade = true;
    }

    if ((numberOfDigits - i) % 3 === 1) {
      if (toUpgrade)
        result += bigNumWords[(numberOfDigits - i - 1) / 3] + ' ';

      toUpgrade = false;
    }
  }

  if (numberOfDigits !== string.length) {
    let y_val = string.length;
    result += 'point ';
    for (let i = numberOfDigits + 1; i < y_val; i++)
      result += oneWords[numberArray[i]] + ' ';
  }

  return toTitlecase(result.replace(/\s+/g, ' '));
}

const price = (price) => {
  if (typeof price !== 'number') {
    return '';
  }

  const dollar = Math.floor(price);
  let result = '';
  
  result = result + `${toWordsConverter(dollar)} dollar${dollar === 1 ? '' : 's'}`;

  if (price !== dollar) {
    const cents = Math.round((price - dollar) * 100);

    result = result + ` and ${toWordsConverter(cents)} cent${cents === 1 ? '' : 's'}`;
  }

  return toTitlecase(result.replace(/\s+/g, ' '));
}

export default price;

const toTitlecase = str => str.split(' ').filter(word => word.length).map(word => word[0].toUpperCase() + word.slice(1)).join(' ');