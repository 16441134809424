import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { fileUploadWithServer, uploadDocument } from '../../../../../utils/s3';
import { setLoadingStatus } from '../../../../../redux/actions/loading';
import { toast } from 'react-toastify';

const UploadVesselPdf = ({ show, onClose, transactionId, uniqueId, fileProps }) => {
    const { file, setFile } = fileProps;
    const dispatch = useDispatch();

    // const [file, setFile] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (show) {
            setFile(null);
        }
        setSubmitted(false);
    }, [show]);

    /**
     * On upload selection of file
     * @param {*} e 
     */
    const handleChange = (e) => {
        setFile(e.target.files[0]);
        setSubmitted(false);
    };

    /**
     * On close modal
     * @param {*} fileUrl 
     */
    const handleCloseModal = (fileUrl = null) => {
        onClose(fileUrl);
    }

    /**
     * on submit pdf file to S3
     * @returns 
     */
    const onUploadPdf = async () => {
        setSubmitted(true);
        handleCloseModal();
        // if (file) {
        //     dispatch(setLoadingStatus(true));
        //     try {
        //         const filename = await uploadDocument(file, 'vessel-pdf', transactionId, uniqueId, false, false);
        //         dispatch(setLoadingStatus(false));
        //         handleCloseModal(filename);
        //         toast.success('Upload PDF successfully');
        //     } catch (error) {
        //         console.error("ERROR:", error)
        //         dispatch(setLoadingStatus(false));
        //         toast.error('Could not upload PDF.');
        //     }
        // } else {
        //     return false;
        // }
    }

    return (
        <Modal
            show={show}
            onHide={handleCloseModal}
            centered={true}
            size="md"
            style={{ zIndex: '1001' }}>
            <Modal.Header closeButton>
                <h3>Upload Listing PDF</h3>
            </Modal.Header>
            <Modal.Body>
                <form style={{ padding: '10px' }}>
                    <div className="form-row mb-2">
                        <div className="uk-padding-small">
                            <div className="js-upload" data-uk-form-custom="true">
                                <input type="file" id="upload-file" onChange={handleChange} accept=".pdf" />
                                <button className="uk-button uk-button-default">Upload PDF</button>
                            </div>
                        </div>
                        {!file && submitted ? <span className="text-danger">Please select pdf to upload</span> : <></>}
                    </div>
                    <div className="form-row file-format-size">File formats supported: pdf only. Maximum file size: 10 MB.</div>
                    {file &&
                        <div className="form-row mt-2">
                            <span className='font-weight-bold'>{file.name}</span>
                            <a data-uk-tooltip="remove-document" style={{ marginLeft: '5px' }} onClick={() => setFile(null)}>
                                <img src="https://cdn.hellosign.com/1.116.0/build/ae9ae8331a142bc9ce60901d10a41fc6.svg" />
                            </a>
                        </div>
                    }
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className="uk-button uk-button-default uk-modal-close small" type="button" onClick={(e) => handleCloseModal()}>
                    Cancel
                </button>
                <button
                    className="uk-button uk-button-primary small"
                    type="button" onClick={onUploadPdf}>Done</button>
            </Modal.Footer>
        </Modal>
    )
}

export default UploadVesselPdf;