import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  useParams,
} from "react-router-dom";

import StepBar from "./layouts/StepBar";
import AddVessel from "./layouts/forms/AddVessel";
import AddCoSeller from "./layouts/forms/AddCoSeller";
import AddSeller from "./layouts/forms/AddSeller";
import AddToys from "./layouts/forms/AddToys";
import AddTerms from "./layouts/forms/AddTerms";
import AddExclusions from "./layouts/forms/AddExclusions";
import AddAddendum from "./layouts/forms/AddAddendum";
import AddEsign from "./layouts/forms/AddEsign";
import {
  clearTransaction,
  getTransactionEdocById,
  getTransactionInitialById,
  updateRequiredFields,
} from "../../../redux/actions/transaction";
import { clearVessel } from "../../../redux/actions/vessels_toys";
import { clearClient } from "../../../redux/actions/clients";
import EditToy from "./layouts/forms/EditToy";
import {
  CORPORATE_OWNED,
  JOINT_OWNER,
  LLC_OWNED,
  SOLE_OWNER,
  TRUST_OWNED
} from "../../../utils/signer";
import TrailerForm from "./layouts/forms/Trailer";

const ListingAgreementEditPage = () => {
  let { path, url } = useRouteMatch();
  let { id } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();
  const [asterixSteps, setAsterixSteps] = useState({});
  const dataListing = useSelector(state => state.transactions.transaction || {});

  const steps = [
    "vessel",
    "seller",
    "co_seller",
    "toys",
    "trailer",
    "terms",
    "exclusions",
    // "addendum",
    // "send_to_signer",
  ];

  const step = steps.indexOf(window.location.pathname.split("/").pop());
  const [stepNum, setStepNum] = useState(0);

  const handleContinueStep = (step) => {
    history.push(`/listing-agreement/add/${id}/${steps[step]}`);
    setStepNum(step);
  }

  useEffect(() => {
    if (id !== "new") {
      dispatch(getTransactionInitialById(id));
      dispatch(getTransactionEdocById(id));
    }
    return () => {
      dispatch(clearTransaction());
      dispatch(clearVessel());
      dispatch(clearClient());
    };
  }, []);

  useEffect(() => {
    const dataVessel = dataListing.t_vessel || {};
    const dataSeller = dataListing.seller || {};
    const dataTerms = dataListing.t_terms || {};
    const dataCoSeller = dataListing.co_seller || {};
    const dataTrailer = dataListing.t_trailer || []

    const vesselFields = {
      hull_number: "Hull No.",
      length: "Length",
      make: "Make",
      // model: "Model",
      // boat_type: "Boat Type",
      year: "Year",
    };

    const sellerFields = {
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      mobile_phone: "Mobile Phone",
    };

    const sellerGroupFields = {
      trust_name: "Corporation/LLC/Trust Name",
      // trust_tax_id: "Corporation/LLC/Trust Tax ID",
      trust_address: "Corporation/LLC/Trust Address",
      trust_city: "Corporation/LLC/Trust City",
      trust_state: "Corporation/LLC/Trust State",
      trust_signer_name: "Corporation/LLC/Trust Name of Signer",
      trust_signer_email: "Corporation/LLC/Trust Email",
      trust_signer_title: "Corporation/LLC/Trust Title of Signer",
    }

    const termsFields = {
      dispute_location: "Dispute Location",
      dispute_location_city: "Dispute Location City",
      asking_price: "Asking Price",
      contract_date: "Contract Date",
    };

    const coSellerFields = {
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      mobile_phone: "Mobile Phone",
    };

    const trailerFields = {
      make: "Make",
      year: "Year",
      vin: "VIN"
    }

    let vesselRequireds = [];
    let sellerRequireds = [];
    let termsRequireds = [];
    let coSellerRequireds = [];
    let trailerRequireds = [];

    Object.keys(vesselFields).forEach((key) => {
      if (!dataVessel[key]) {
        vesselRequireds.push({ [key]: vesselFields[key] });
      }
    });
    // if (!(dataVessel['doc_no'] || dataVessel['reg_no'] || dataVessel['title_no'])) {
    //   vesselRequireds.push({ 'doc_reg_title_no': 'Doc No, Reg No, Title No' });
    // }
    if (dataVessel.is_old_built == true) {
      vesselRequireds.splice(vesselRequireds.findIndex(field => field['hull_number']), 1);
    }
    if (dataVessel.hull_number?.length < 12 && dataVessel.is_old_built === false) {
      vesselRequireds.push({ 'hull_number': 'Invalid Hull Number' });
    }

    if ([TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataListing?.seller_type) > -1) {
      Object.keys(sellerGroupFields).forEach((key) => {
        if (!dataSeller[key]) {
          sellerRequireds.push({ [key]: sellerGroupFields[key] });
        }
      });
    } else if ([SOLE_OWNER, JOINT_OWNER].indexOf(dataListing?.seller_type) > -1) {
      Object.keys(sellerFields).forEach((key) => {
        if (!dataSeller[key]) {
          sellerRequireds.push({ [key]: sellerFields[key] });
        }
      });
    } else {
      sellerRequireds.push({ title: 'Owner Role' });
    }

    Object.keys(termsFields).forEach((key) => {
      if (!dataTerms[key]) {
        if (!((key === 'dispute_location' || key === 'dispute_location_city') && dataListing.association === 'YBAA')) {
          termsRequireds.push({ [key]: termsFields[key] });
        }
      }
    });
    Object.keys(coSellerFields).forEach((key) => {
      if (!dataCoSeller[key]) {
        coSellerRequireds.push({ [key]: coSellerFields[key] });
      }
    });

    Object.keys(trailerFields).forEach((key) => {
      if (dataTrailer.length == 0) {
        trailerRequireds.push({ [key]: trailerFields[key] });
      }
      dataTrailer.forEach(element => {
        if (!element[key]) {
          trailerRequireds.push({ [key]: trailerFields[key] });
        }
      });
    })

    dispatch(
      updateRequiredFields({
        vessel: vesselRequireds,
        seller: sellerRequireds,
        terms: termsRequireds,
        co_seller: coSellerRequireds,
        trailer: trailerRequireds
      })
    );
  }, [dataListing]);


  const addAsterix = (val) => {
    setAsterixSteps(Object.assign(new Object(), asterixSteps, val));
  };

  // useEffect(() => {
  //   if (stepNum !== undefined) {
  //     history.push(`/listing-agreement/add/${id}/${steps[stepNum]}`);
  //   }
  // }, [stepNum]);

  return (
    <div>
      <StepBar
        setStep={setStepNum}
        stepNum={stepNum}
        asterix={{ ...asterixSteps }}
        setAsterix={addAsterix}
      />
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/vessel`}></Redirect>
        </Route>
        <Route
          path={`${path}/vessel`}
          render={() => (
            <AddVessel
              step={step}
              setStep={handleContinueStep}
              setAsterix={addAsterix}
            />
          )}
        />
        <Route path={`${path}/seller`} render={() => <AddSeller step={step} setStep={handleContinueStep} setAsterix={addAsterix} />} />
        <Route
          path={`${path}/co_seller`}
          render={() => (
            <AddCoSeller
              step={step}
              setStep={handleContinueStep}
              setAsterix={addAsterix}
            />
          )}
        />
        <Route
          exact
          path={`${path}/toys`}
          render={() => (
            <AddToys
              step={step}
              setStep={handleContinueStep}
              setAsterix={addAsterix}
            />
          )}
        />
        <Route path={`${path}/toys/edit`} component={EditToy} />
        <Route
          exact
          path={`${path}/trailer`}
          render={() => (
            <TrailerForm
              step={step}
              setStep={handleContinueStep}
              setAsterix={addAsterix}
            />
          )}
        />
        <Route path={`${path}/trailer/edit`} exact render={(props) => {
          return (
            <TrailerForm id={props.location.state.id} />
          )
        }} />
        <Route
          exact
          path={`${path}/terms`}
          render={() => (<AddTerms step={step} setStep={handleContinueStep} setAsterix={addAsterix} />)}
        />
        <Route
          path={`${path}/exclusions`}
          render={() => (
            <AddExclusions
              step={step}
              setStep={handleContinueStep}
              setAsterix={addAsterix}
            />
          )}
        />
        {/* <Route
          path={`${path}/addendum`}
          render={() => (
            <AddAddendum
              step={step}
              setStep={handleContinueStep}
              setAsterix={addAsterix}
            />
          )}
        /> */}
        <Route path={`${path}/send_to_signer`} render={() => <AddEsign />} />
        <Redirect to={`${url}/vessel`} />
      </Switch>
    </div>
  );
};

export default ListingAgreementEditPage;
