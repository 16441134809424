export function phoneNumber(number) {
  if (typeof number === 'string') {
    number = phoneFigure(number);
  }
  if (!number) {
    return null;
  }

  number = parseInt(number, 10);
  number = Math.abs(number);
  number = number.toString();
  return `+${number[0]}${number.substring(1, 4) ? `(${number.substring(1, 4)})` : ''}${number.substring(4, 7) ? ' ' + number.substring(4, 7) : ''}${number.substring(7, 11) ? '-' + number.substring(7, 11) : ''}`;
}

export function phoneFigure(phoneNumber) {
  if (!phoneNumber) {
    return null;
  }

  let number = phoneNumber.replace(/[-\+\(\) ]/g, "");
  return parseInt(number);
}
