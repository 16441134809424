import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import clsx from "clsx";

import FormPage from "../../../../common/FormPage";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import LinkButton from "../../../../common/buttons/LinkButton";
import SubmitButton from "../../../../common/buttons/SubmitButton";
import Form from "../../../../common/Form";
import {
  getTransactionInitialById,
} from "../../../../../redux/actions/transaction";
import { getFormattedData, getPlainTransactionData } from "../../../../../utils/stepFilter";
import { getLength, getOrigin } from "../../../../../utils/length";
import { setLoadingStatus } from "../../../../../redux/actions/loading";

import dropdownLists from '../../../../../constants/dropdownLists';
import Auth from "../../../../../utils/auth";
import VesselAndToyService from "../../../../../services/VesselAndToyService";
import { updateTransactionVesselOrToy, updateVesselOrToy } from "../../../../../redux/actions/vessels_toys";
import { Autocomplete, TextField } from "@mui/material";
import vessels from "../../../../../constants/dropdownLists/vessel-manufacturer";
const MAX_ENGINE_COUNT = 6;

//Define schema for validations
const schema = yup.object().shape({
  // hull_number: yup.string().when(['is_old_built'], {
  //   is: (is_old_built) => is_old_built !== true,
  //   then: yup.string().required().min(12),
  //   otherwise: yup.string().required().min(1)
  // }),
  hull_number: yup.string().when(['is_old_built'], {
    is: (is_old_built) => is_old_built !== true,
    then: yup.string().required().min(12),
    otherwise: yup.string().nullable()
  }),
  length: yup.string().required(),
  make: yup.string().required(),
  model: yup.string().nullable(),
  year: yup.number().required(),
  doc_no: yup.string().when(['reg_no', 'title_no'], {
    is: (reg_no, title_no) => (!reg_no || reg_no.length === 0) && (!title_no || title_no.length === 0),
    then: yup.string().required(),
    otherwise: yup.string().nullable()
  }),
  reg_no: yup.string().when(['doc_no', 'title_no'], {
    is: (doc_no, title_no) => (!doc_no || doc_no.length === 0) && (!title_no || title_no.length === 0),
    then: yup.string().required(),
    otherwise: yup.string().nullable()
  }),
  title_no: yup.string().when(['doc_no', 'reg_no'], {
    is: (doc_no, reg_no) => (!doc_no || doc_no.length === 0) && (!reg_no || reg_no.length === 0),
    then: yup.string().required(),
    otherwise: yup.string().nullable()
  })
}, [['doc_no', 'reg_no'], ['doc_no', 'title_no'], ['reg_no', 'title_no']]);

const EditToy = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const logged_user = Auth.getInstance().getUserData();

  /* Define State Variables */
  const [isFinishSubmit, setFinishSubmit] = useState(false);
  const [err, setErr] = useState(null);

  //State to store number of engines
  const [numberOfEngines, setNumberOfEngines] = useState(1);

  //State to convert length unit into foot or meter.
  const [lengthUnit, setLengthUnit] = useState("");

  //Define state for dutyPaid radio button
  const [isDutyPaid, setIsDutyPaid] = useState(null);

  //Define state for salesPaid radio button
  const [isSalesPaid, setIsSalesPaid] = useState(null);

  //State for old hullnumber checkbox
  const [isOld, setOld] = useState(false);

  //State for storing current transaction
  const dataTransaction = useSelector((state) => state.transactions.transaction);

  //State for storing transaction broker
  const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);

  //Variable for storing current transaction
  const dataDeal = dataTransaction || {};
  const toys = dataDeal.t_toys || [];
  const toy = props.location.state ? props.location.state.toy : {};
  const transaction_id = dataDeal.id || "new";

  //Get values of all dropdowns from dropdownList
  const { boatMakes, boatTypes, engineMakes, toyTypes } = dropdownLists;

  //get the toyId from state
  const toy_id = props.location.state ? props.location.state.id : null;

  /* Define State Variables */
  const [boatType, setBoatType] = useState("");

  //State for foreign vessel
  const [isForeignBuiltVessel, setIsForeignBuiltVessel] = useState(false);

  //State for storing boat category dropdown
  const [boatCategories, setBoatCategories] = useState([]);

  //State for storing boat manufactures dropdown
  const [boatManufacturers, setBoatManufacturers] = useState([]);

  // const dataVessel = toy.vessel;
  const allAccessibleBrokers = useSelector((state) => state.users.allAccessibleBrokers);

  const [requiredFields, setRequiredFields] = useState(['hull_number', 'length', 'item_value', 'make', 'year']);

  //useEffect for set the value of toys & tenders form
  useEffect(() => {
    VesselAndToyService.get(toy_id).then((dataToy) => {
      let toy = dataToy.data.vessel;
      if (toy) {
        Object.keys(toy).forEach((key) => {
          if (key == "boat_type") {
            setBoatType(toy[key]);
          }
          setValue(key, toy[key] ? toy[key] : null);
        });
        setValue("boat_category", toy?.boat_category || "");
        setMakeValue(toy.make ? { label: toy.make } : null);
        requiredFields.map((value) => {
          manageRequireFields(value, getValues(value));
        });
      } else {
        Object.keys(toy).forEach((key) => setValue(key, null));
      }

      setValue("length", getLength(toy?.length));
      setIsDutyPaid(toy?.is_duty_paid);
      setIsSalesPaid(toy?.sales_use_tax_paid);
      //Set value of forigen built vessels
      setIsForeignBuiltVessel(toy["foreign_built_vessel"]);
      setOld(toy?.is_old_built || false);
      setLengthUnit(toy?.length_unit || "ft");
    });
  }, []);

  //Separate power category of boat from all category of boat
  const dataPowerCategories = useSelector((state) => {
    if (state.vessel_toys.mlsFilters.PowerCategories) {
      return state.vessel_toys.mlsFilters.PowerCategories || [];
    } else {
      return [];
    }
  });

  //Separate sail category of boat from all category of boat
  const dataSailCategories = useSelector((state) => {
    if (state.vessel_toys.mlsFilters.SailCategories) {
      return state.vessel_toys.mlsFilters.SailCategories || [];
    } else {
      return [];
    }
  });

  // const dataManuFecturers = vessels.sort();

  //Remove null manufactures from all data manufactures
  let dataManuFecturers = useSelector((state) => {
    return state.vessel_toys.mlsFilters.manufacturers || [];
  });

  dataManuFecturers = dataManuFecturers.filter((item, i, ar) => ar.indexOf(item) === i).map(mfr => { return { label: mfr } });

  const [makeValue, setMakeValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  /* Form Validation */
  const { register, handleSubmit, trigger, setValue, getValues, watch, formState: { errors } } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  //Define all keys of form
  const keys = [
    "lienholder",
    "make",
    "hull_number",
    "is_old_built",
    // "make",
    "vessel_name",
    "stock",
    "length",
    "model",
    "boat_category",
    "year",
    "flag",
    "doc_no",
    "doc_no_issuer",
    "reg_no",
    "reg_no_issuer",
    "title_no",
    "title_no_issuer",
    "place_of_registration",
    "is_duty_paid",
    "sales_use_tax_paid",
    "engine_make",
    "engine_model",
    "engine_hp",
  ];

  //Push engine fields as per number of engine fields
  Array.from({ length: MAX_ENGINE_COUNT }).forEach((_, index) => {
    const realIndex = index + 1;
    keys.push(`engine_serial_${realIndex}`);
    keys.push(`engine_year_${realIndex}`);
    keys.push(`engine_hours_${realIndex}`);
  });

  //UseEffect for setBoatCategories with sorting
  useEffect(() => {
    let categories = boatType == "Power" ? dataPowerCategories : dataSailCategories;
    setBoatCategories(categories.sort());
  }, [boatType]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setValue("boat_category", dataVessel?.boat_category || "");
  //   }, 1000);
  // }, [dataVessel])

  //UseEffect for storing value of length into form
  useEffect(() => {
    let figure = getOrigin(getValues("length"));
    setValue("length", getLength(figure, lengthUnit));
  }, [lengthUnit]);

  /* Handle Function for From Inputs */
  const handleRegistration = (toy) => {

    //Show loader
    dispatch(setLoadingStatus(true));

    //add some fields value for toy
    toy.vessel_type = "toy";
    toy = getFormattedData(toy);
    toy.is_duty_paid = isDutyPaid;
    toy.sales_use_tax_paid = isSalesPaid;
    toy.length_unit = lengthUnit;
    toy.length = getOrigin(toy.length, lengthUnit);
    toy.is_old_built = isOld;
    toy.deleted = false;
    toy.boat_type = boatType;
    toy.foreign_built_vessel = isForeignBuiltVessel;

    //Call api to update the toy
    dispatch(updateTransactionVesselOrToy(toy_id, toy))
      .then(() => {
        setFinishSubmit(true);
        setErr(null);

        //Get latest transaction by id.
        dispatch(getTransactionInitialById(transaction_id));

        //Hide loader
        dispatch(setLoadingStatus(false));
        setTimeout(() => {
          history.push(`/listing-agreement/add/${transaction_id}/toys`);
        }, 1000);
      })
      .catch((e) => {
        setErr(e.response ? e.response.data.message : e.message);
        dispatch(setLoadingStatus(false));
        setTimeout(() => {
          setErr(null);
        }, 1000);
      });
  };

  //Define function to handle form validations errors.
  const handleError = (err) => {
    console.log(err)
    // setStep((step || 0) + 1);
  };

  //Function for remove ft. or mt. for length
  const handleLengthBlur = () => {
    let figure = getOrigin(getValues("length"));
    setValue("length", getLength(figure, lengthUnit));
  };

  //Function for remove ft. or mt. for length
  const handleLengthFocus = () => {
    let symbolitic = getValues("length");
    setValue("length", getOrigin(symbolitic, lengthUnit));
  };

  //UseEffect for add fields as per number of field is selected.
  useEffect(() => {
    setNumberOfEngines(getValues('number_of_engines'));
    // setValue("make", dataVessel?.make)
  }, [watch('number_of_engines')]);

  const links = [
    { label: "Listing Agreements", link: `/listing-agreement` },
    { label: "Toys", link: `/listing-agreement/add/${transaction_id}/toys` },
    { label: "Edit a Toy" },
  ];

  const manageRequireFields = (fieldName, fieldValue) => {
    setRequiredFields(state => {
      if (fieldValue) {
        if (fieldName == 'hull_number' && getValues('hull_number').length < 12) {
          return state.includes(fieldName) ? state : [...state, fieldName];
        }
        return state.filter(field => field != fieldName);
      }
      return state.includes(fieldName) ? state : [...state, fieldName];
    })
  }

  const vesselName = `${dataTransaction?.t_vessel?.year || ""} ${dataTransaction?.t_vessel?.make || ""} ${dataTransaction?.t_vessel?.model || ""} ${dataTransaction?.t_vessel?.length || ""}`;
  const uniqueTransactionId = dataTransaction?.unique_transaction_id ? `LA-${dataTransaction?.unique_transaction_id}` : '';

  return (
    <FormPage links={links} title="Edit Toy" vesselName={vesselName} uniqueId={uniqueTransactionId}>
      {isFinishSubmit ? (
        <div className="uk-alert uk-alert-primary" data-uk-alert>
          <p>Updated Successfully</p>
        </div>
      ) : undefined}
      {err ? (
        <div className="uk-alert uk-alert-danger" data-uk-alert>
          <p>{err}</p>
        </div>
      ) : (
        ""
      )}

      <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
        <FormRow>
          <FormInputWrapper label="Hull Number">
            <input className={clsx("uk-input", { "uk-form-danger": requiredFields.includes('hull_number') && !isOld })} type="text" placeholder="Hull number must be 12 digits"
              {...register("hull_number", {
                onChange: e => {
                  manageRequireFields('hull_number', e.target.value);
                }
              })} />
          </FormInputWrapper>
          <FormInputWrapper label="HIN is not available or is not 12 character USCG compliant.">
            <input className="uk-checkbox" type="checkbox" checked={isOld} onClick={() => { setOld(!isOld); setValue('is_old_built', !isOld); }} onChange={() => { }} />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <div className="one-column uk-padding-small">
            <label className="uk-form-label" htmlFor="vessel_name">
              Vessel Name
            </label>
            <input
              className={clsx("uk-input", {
                "uk-form-danger": errors.vessel_name?.message,
              })}
              type="text"
              {...register("vessel_name")}
            />
          </div>

          <div className="one-column uk-padding-small">
            <label className="uk-form-label" htmlFor="stock">
              Stock #
            </label>
            <input
              className={clsx("uk-input", {
                "uk-form-danger": errors.stock?.message,
              })}
              type="text"
              {...register("stock")}
            />
          </div>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Length *">
            <input
              className={clsx("uk-input", { "uk-form-danger": requiredFields.includes('length') })}
              type="text"
              {...register("length", {
                onChange: e => {
                  manageRequireFields('length', e.target.value);
                }
              })}
              onBlur={handleLengthBlur}
              onFocus={handleLengthFocus}
            />
          </FormInputWrapper>

          <FormInputWrapper label="Length Unit">
            <input
              type="radio"
              name="length_unit"
              value="ft"
              onClick={() => setLengthUnit("ft")}
              onChange={() => { }}
              checked={lengthUnit === "ft"}
            />
            <label className="uk-form-label radio">Feet</label>
            <input
              type="radio"
              name="length_unit"
              value="m"
              onClick={() => setLengthUnit("m")}
              onChange={() => { }}
              checked={lengthUnit === "m"}
            />
            <label className="uk-form-label ratio">Meters</label>
          </FormInputWrapper>
        </FormRow>
        <FormRow>

          <FormInputWrapper label="Category">
            <select
              className="uk-select"
              {...register('boat_category')}
              disabled={!boatType}
            >
              <option key={`vessel_boat_category_default`} value=""></option>
              {boatCategories.map((boatCategoryKey, idx) => (
                <option key={`vessel_boat_category_${idx}`} value={boatCategoryKey}>{boatCategoryKey}</option>
              ))}
            </select>
          </FormInputWrapper>


          <FormInputWrapper label="Type">
            <input
              type="radio"
              name="boat_type"
              value="Power"
              onClick={() => {
                setBoatType("Power");
                setValue("boat_category", "")
                // setBoatCategories(dataPowerCategories);
              }}
              onChange={() => { }}
              checked={boatType === "Power"}
            />
            <label className="uk-form-label radio">Power</label>
            <input
              type="radio"
              name="boat_type"
              value="Sail"
              onClick={() => {
                setBoatType("Sail");
                setValue("boat_category", "")
                // setBoatCategories(dataSailCategories);
              }}
              onChange={() => { }}
              checked={boatType === "Sail"}
            />
            <label className="uk-form-label ratio">Sail</label>
          </FormInputWrapper>

        </FormRow>
        <FormRow>
          <FormInputWrapper label="Manufacturer/Builder *">
            <Autocomplete
              style={{ borderLeft: 'none' }}
              className={clsx({ "uk-form-danger": requiredFields.includes('make') && !makeValue })}
              id="size-small-outlined"
              size="small"
              isOptionEqualToValue={(option, value) => option.label === value.label}
              value={makeValue}
              onChange={(event, newValue) => {
                setValue('make', newValue?.label || '');
                setMakeValue(newValue);
                manageRequireFields('make', newValue?.label);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              options={dataManuFecturers}
              renderInput={(params) => <TextField {...params} />}
            />
          </FormInputWrapper>

          <FormInputWrapper label="Model">
            <input
              className="uk-input"
              type="text"
              {...register("model")}
            />
          </FormInputWrapper>

          {/* <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="model">
                Model*
              </label>

            </div> */}

          <FormInputWrapper label="Year *">
            <input className={clsx("uk-input", { "uk-form-danger": requiredFields.includes('year') })} type="text"
              {...register("year", {
                onChange: e => {
                  manageRequireFields('year', e.target.value);
                }
              })} />
          </FormInputWrapper>

        </FormRow>
        <FormRow>
          <FormInputWrapper label="Number of Engines">
            <select
              className="uk-select"
              {...register("number_of_engines")}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
            </select>
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Engine Make">
            <input className="uk-input" type="text" {...register("engine_make")} />
            {/* <select
              className="uk-select"
              {...register('engine_make')}
            >
              {engineMakes.map((engineMake, idx) => (
                <option key={`engine_make_${idx}`} value={engineMake}>{engineMake}</option>
              ))}
            </select> */}
          </FormInputWrapper>
          <FormInputWrapper label="Engine Model">
            <input className="uk-input" type="text" {...register("engine_model")} />
          </FormInputWrapper>
          <FormInputWrapper label="Engine HP">
            <input className="uk-input" type="text" {...register("engine_hp")} />
          </FormInputWrapper>
        </FormRow>
        {Array.from({ length: numberOfEngines }).map((_, index) => {
          const realIndex = index + 1;

          return (
            <FormRow key={`engine_${index}`}>
              <FormInputWrapper label={`Engine ${realIndex} Year`}>
                <input className="uk-input" type="text" {...register(`engine_year_${realIndex}`)} />
              </FormInputWrapper>
              <FormInputWrapper label={`Engine ${realIndex} Serial`}>
                <input className="uk-input" type="text" {...register(`engine_serial_${realIndex}`)} />
              </FormInputWrapper>
              <FormInputWrapper label={`Engine ${realIndex} Hours`}>
                <input className="uk-input" type="text" {...register(`engine_hours_${realIndex}`)} />
              </FormInputWrapper>
            </FormRow>
          );
        })}
        <FormRow>
          <FormInputWrapper label="Flag">
            <input className="uk-input" type="text" {...register("flag")} />
          </FormInputWrapper>
          <FormInputWrapper size="one" label="Foreign Built Vessel">
            <input
              type="radio"
              name="foreign_built_vessel"
              value={true}
              onClick={() => setIsForeignBuiltVessel(true)}
              onChange={() => { }}
              checked={isForeignBuiltVessel}
            />
            <label className="uk-form-label radio">Yes</label>
            <input
              type="radio"
              name="foreign_built_vessel"
              value={false}
              onClick={() => setIsForeignBuiltVessel(false)}
              onChange={() => { }}
              checked={!isForeignBuiltVessel}
            />
            <label className="uk-form-label radio">No</label>
          </FormInputWrapper>
          <FormInputWrapper label="Place of Registration">
            {/* place_of_registration */}
            <input className={clsx("uk-input", { 'uk-form-danger': errors.place_of_registration })} type="text" {...register("place_of_registration")} />
          </FormInputWrapper>
        </FormRow>

        <FormRow>
          <p style={{ color: '#5479A3' }} className={clsx("", { "uk-text-danger uk-text-bold": errors.doc_no?.message || errors.reg_no?.message || errors.title_no?.message })}>Please input at least one: Doc No., Reg No., or Title No.</p>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Doc No.">
            <input className={clsx("uk-input", { 'uk-form-danger': errors.doc_no })} type="text" {...register("doc_no")} onKeyUp={() => {
              trigger(["reg_no", "title_no"]);
            }} />
          </FormInputWrapper>
          <FormInputWrapper label="Reg No.">
            <input className={clsx("uk-input", { 'uk-form-danger': errors.reg_no })} type="text" {...register("reg_no")} onKeyUp={() => {
              trigger(["doc_no", "title_no"]);
            }} />
          </FormInputWrapper>
          <FormInputWrapper label="Title No.">
            <input className={clsx("uk-input", { 'uk-form-danger': errors.title_no })} type="text" {...register("title_no")} onKeyUp={() => {
              trigger(["reg_no", "doc_no"]);
            }} />
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Doc No. Issuing Entity">
            <input className={clsx("uk-input", { 'uk-form-danger': errors.doc_no_issuer })} type="text" {...register("doc_no_issuer")} />
          </FormInputWrapper>
          <FormInputWrapper label="Reg No. Issuing Entity">
            <input className={clsx("uk-input", { 'uk-form-danger': errors.reg_no_issuer })} type="text" {...register("reg_no_issuer")} />
          </FormInputWrapper>
          <FormInputWrapper label="Title No. Issuing Entity">
            <input className={clsx("uk-input", { 'uk-form-danger': errors.title_no_issuer })} type="text" {...register("title_no_issuer")} />
          </FormInputWrapper>
        </FormRow>

        <FormRow>
          <FormInputWrapper size="one" label="Duty Paid">
            <input
              type="radio"
              name="duty_paid"
              value={true}
              onClick={() => setIsDutyPaid(true)}
              onChange={() => { }}
              checked={isDutyPaid != null && isDutyPaid}
            />
            <label className="uk-form-label radio">Yes</label>
            <input
              type="radio"
              name="duty_paid"
              value={false}
              onClick={() => setIsDutyPaid(false)}
              onChange={() => { }}
              checked={isDutyPaid != null && !isDutyPaid}
            />
            <label className="uk-form-label radio">No</label>
            <input
              type="radio"
              name="duty_paid"
              value={false}
              onClick={() => setIsDutyPaid(null)}
              onChange={() => { }}
              checked={isDutyPaid == null}
            />
            <label className="uk-form-label radio">Unknown</label>
          </FormInputWrapper>
          <FormInputWrapper label="Sales/Use Tax Paid">
            <input
              type="radio"
              name="sales_use_tax_paid"
              value={true}
              onClick={() => setIsSalesPaid(true)}
              onChange={() => { }}
              checked={isSalesPaid != null && isSalesPaid}
            />
            <label className="uk-form-label radio">Yes</label>
            <input
              type="radio"
              name="sales_use_tax_paid"
              value={false}
              onClick={() => setIsSalesPaid(false)}
              onChange={() => { }}
              checked={isSalesPaid != null && !isSalesPaid}
            />
            <label className="uk-form-label radio">No</label>
            <input
              type="radio"
              name="sales_use_tax_paid"
              value={false}
              onClick={() => setIsSalesPaid(null)}
              onChange={() => { }}
              checked={isSalesPaid == null}
            />
            <label className="uk-form-label radio">Unknown</label>
          </FormInputWrapper>
          <FormInputWrapper label="Lienholder Name">
            <input className={clsx("uk-input", { 'uk-form-danger': errors.lienholder })} type="text" {...register("lienholder")} />
          </FormInputWrapper>
        </FormRow>
        <SubmitButton>Save</SubmitButton>
        <LinkButton to={`/listing-agreement/add/${transaction_id}/toys`}>Cancel</LinkButton>
      </Form>
    </FormPage >
  );
};

export default EditToy;
