import DataTable from "react-data-table-component";
import datatableStyles from "../../../../../utils/datatable-style";
import Moment from "react-moment";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import { getEdocCompanyTaskList } from "../../../../../redux/actions/transaction";

const TaskViewTable = ({
  data,
  show,
  setStatusShowModal,
  setUpdatingTask,
  companyId,
  transactionId,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading.status);

  //Make a state for search
  const [search, setSearch] = useState({});

  useEffect(() => {
    getCompanyTaskList(search);
  }, [search]);

  const removeNullValues = (obj) => {
    for (const key in obj) {
      if (!obj[key]) {
        delete obj[key];
      }
    }
    return obj;
  };

  const getCompanyTaskList = (search) => {
    dispatch(setLoadingStatus(true));
    if (transactionId) {
      dispatch(
        getEdocCompanyTaskList(
          companyId,
          transactionId,
          removeNullValues(search)
        )
      )
        .then((response) => {
          dispatch(setLoadingStatus(false));
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
        });
    }
  };

  //Function for handle sort
  const handleSort = (column, sortDirection) => {
    // column.selector, sortDirection
    setSearch((search) => {
      return {
        ...search,
        sortOrder: sortDirection,
        sortField: column.selector,
      };
    });
  };

  const columns = [
    {
      name: "",
      width: "80px",
      cell: (row) => (
        <div>
          <span>
            <i
              className={clsx(
                "fa",
                { "fa-check-circle": row.status === "completed" },
                {
                  "fa-circle-o":
                    row.status === "not_started" ||
                    row.status === "not_available" ||
                    !row.status,
                },
                { "fa-circle": row.status === "in_process" }
              )}
            />
          </span>
        </div>
      ),
    },
    {
      name: "Order",
      selector: "sort_order",
      width: "120px",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row?.sort_order + 1 || " "
            }
          </div>
        </div>
      ),
    },
    {
      name: "Title",
      sortable: true,
      cell: (row) => <div>{row.company_tasks?.title || ""}</div>,
    },
    {
      name: "Status",
      center: true,
      cell: (row) => (
        <button
          onClick={() => {
            setUpdatingTask({ ...row, ...search });
            setStatusShowModal(true);
          }}
          className={`uk-button ${
            row.status == "in_process"
              ? "uk-button-dark"
              : row.status == "completed"
              ? "uk-button-green"
              : row.status == "not_available"
              ? "uk-button-grey"
              : "uk-button-status"
          }`}
        >
          {row.status == "not_available" ? "N/A" :row.status ? row.status?.replace("_", " ") : "Not Started"}
        </button>
      ),
    },
    {
      name: "Tag",
      width: "20rem",
      cell: (row) => (
        <div className="">
          {row.tags?.map(
            (tag, index) =>
              tag && (
                <small key={tag.id}>
                  {tag.tag}
                  {index !== row.tags?.length - 1 && ", "}
                </small>
              )
          )}
        </div>
      ),
    },
    {
      name: "Updated At",
      center: true,
      cell: (row) => {
        if (row.status === "completed" || row.status === "in_process") {
          return <Moment date={row.updatedAt} format="MM/DD/YYYY hh:mm A" />;
        } else {
          return <>-</>;
        }
      },
    },
    // {
    //   name: "Actions",
    //   cell: (row) => (
    //     <div>
    //       <a
    //         data-uk-toggle="target: #modal-update-task-status-deal"
    //         onClick={() => setUpdatingTask(row)}
    //       >
    //         <img src="/icons/update-status.svg" className="svg" />
    //       </a>
    //     </div>
    //   ),
    // },
  ];

  if (!show) {
    return <></>;
  }
  return (
    <>
    {!data.length && isLoading
      ? <div className="no-data-table"><div className="p-4">Fetching Details...</div></div>
      :  <DataTable
      columns={columns}
      data={data}
      highlightOnHover={true}
      striped={true}
      noHeader={true}
      customStyles={datatableStyles}
      onSort={handleSort}
      />}
      </>
  );
};

export default TaskViewTable;
