import ModalBody from "../../../../common/ModalBody";
import OffCanvasContainer from "../../../../common/OffCanvasContainer";
import DetailOfCoBroker from "../details/DetailOfCoBroker";

const ViewCoBrokerForm = ({ cobroker, offers }) => {
  return (
    <OffCanvasContainer id="modal-co-broker">
      <ModalBody title="Co-Broker">
        <DetailOfCoBroker cobroker={cobroker} offers={offers}/>
      </ModalBody>
    </OffCanvasContainer>
  );
};

export default ViewCoBrokerForm;
