import http, { setHeaders } from "../http-common";
import Auth from "../utils/auth";

setHeaders(
  Auth.getInstance().getAuthToken() + " " + Auth.getInstance().getIDToken()
);

const getAll = () => {
  return http.get("/edoc-templates");
};

const get = (id) => {
  return http.get(`/edoc-templates/${id}`);
};

const create = (data) => {
  return http.post("/edoc-templates", data);
};

const update = (id, data) => {
  return http.put(`/edoc-templates/${id}`, data);
};

const remove = (id) => {
  return http.delete(`/edoc-templates/${id}`);
};

const removeAll = () => {
  return http.delete("/edoc-templates");
};

const findByName = (name) => {
  return http.post("/edoc-templates/searchByName", { name: name });
};

const updateDocumentPdf = (data = null) => {
  return http.post("/edoc-templates/manualEditEdocuments", data);
};

const updateEditedDocumentPdf = (data = null) => {
  return http.post("/edoc-templates/manualEdit", data);
};

const getEditorDocumentPdf = (documentId) => {
  return http.get(`/edoc-templates/getManualEditEdocument/${documentId}`);
};
 
const EDocService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByName,
  updateDocumentPdf,
  updateEditedDocumentPdf,
  getEditorDocumentPdf,
};

export default EDocService;
