import { useState } from 'react'
import { Helmet } from 'react-helmet';
import { Route, Switch, useRouteMatch, Link, useParams } from 'react-router-dom'
import ListFolder from './ListFolder';
import ListFiles from './ListFiles';

const UploadFileManager = () => {
    const [folder, setFolder] = useState(null);

    return (
        <div className="uk-container uk-container-expand uk-position-relative">
            <>
                <Helmet><title>Upload File Manager - BoatDox</title></Helmet>
                {folder ? <ListFiles folderProps={{ folder, setFolder }} /> : <ListFolder folderProps={{ folder, setFolder }} />}
            </>

        </div>
    )
}

export default UploadFileManager