import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import { setLoadingStatus } from '../../../../../redux/actions/loading';
import { getFormattedData, getPlainTransactionData } from "../../../../../utils/stepFilter";
import { getTransactionEdocById, getTransactionEsignById, upsertTransaction } from "../../../../../redux/actions/transaction";

import clsx from "clsx";
import * as yup from "yup";

const PanelFormIYBAPurchaseAndSaleAddendum = ({ closePanel, addendumId }) => {

    const schema = yup.object().shape({
        ammendment: yup.string(),
        addendum_no: yup.string().required()
    });

    const { register, handleSubmit, setValue, getValues, formState: { isDirty, errors } } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    const dispatch = useDispatch();

    const [addendum, setAddendum] = useState({});

    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataDeal = dataTransaction || {};
    const dataAddendum = dataDeal.t_purchase_addendum;


    const keys = ["ammendment_1", "ammendment_2", "ammendment_3", "ammendment_4", "ammendment_5"];
    const doc_id = addendumId;

    useEffect(() => {
        const ammendment = dataAddendum?.find(el => el.t_edocument_id === doc_id);
        if (ammendment) {
            setAddendum(ammendment);
            ammendment.ammendment.split("/:/").forEach((str, index) => {
                setValue(`ammendment_${index + 1}`, str);
            });
            setValue('addendum_no', ammendment.addendum_no);
        }
    }, [dataAddendum]);

    const handleRegistration = (ammendment) => {
        dispatch(setLoadingStatus(true));
        let dataStr = "";
        ammendment = getFormattedData(ammendment);
        keys.forEach((key) => {
            dataStr = dataStr.concat(ammendment[key] || "", "/:/");
        });
        dataStr = dataStr.slice(0, -3);
        ammendment.ammendment = dataStr;
        ammendment.addendum_no = getValues('addendum_no');
        if (doc_id) {
            ammendment.id = doc_id;
        }
        if (addendum && addendum.id) {
            ammendment.id = addendum.id;
        }
        dispatch(upsertTransaction({
            step: {
                db: "purchase_esign_addendum",
                main: ammendment,
            },
            transaction: {
                isBelonged: false,
                main: { ...getPlainTransactionData(dataDeal), task_type: "deal" },
            },
        },"Purchase Esign Addendum")
        ).then((data) => {
            dispatch(setLoadingStatus(false));
            dispatch(getTransactionEdocById(data.id));
            dispatch(getTransactionEsignById(data.id));
            closePanel(true);
        }).catch((error) => {
            dispatch(setLoadingStatus(false));
            console.log(error);
        });
    };

    const handleError = (error) => {
        console.log(error);
    };

    return (
        <div className="form-container panel-form">
            <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                <FormRow>
                    <FormInputWrapper label='Addendum Number *' size="two">
                        <input type="text" className={clsx("uk-input", {
                            "uk-form-danger": errors.addendum_no,
                        })} {...register("addendum_no")} />
                    </FormInputWrapper>
                    <FormRow></FormRow>
                    <p>This Purchase and Sale Agreement is subject to the following additional provisions:</p>
                    <FormInputWrapper label="Provision #1" size="two">
                        <textarea type="text" className="uk-input" {...register("ammendment_1")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Provision #2" size="two">
                        <textarea type="text" className="uk-input" {...register("ammendment_2")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Provision #3" size="two">
                        <textarea type="text" className="uk-input" {...register("ammendment_3")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Provision #4" size="two">
                        <textarea type="text" className="uk-input" {...register("ammendment_4")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Provision #5" size="two">
                        <textarea type="text" className="uk-input" {...register("ammendment_5")} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow style={{ marginTop: "16px" }}>
                    <button type="submit" className='uk-button uk-button-primary pull-right'>Save</button>
                    <button type="button" className='uk-button uk-button-orange pull-right' onClick={() => closePanel(false)}>Close</button>
                </FormRow>
            </Form>
        </div>
    )
}

export default PanelFormIYBAPurchaseAndSaleAddendum;