import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { makeStyles } from "@mui/styles";
import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import Swal from 'sweetalert2';

import ESignHeader from './partials/Header';
import PDFSignerViewer from '../common/PDFSignerViewer/PDFSignerViewer';

import { setLoadingStatus } from '../../redux/actions/loading';
import { getSignatureData, signDocument, rejectDocument, resetWithCounterOffer } from '../../redux/actions/esign';
import { createFileFromLink } from '../../utils/common';
import { fileUploadWithServer, uploadForms } from '../../utils/s3';

const useStyles = makeStyles({
  '@global': {
    body: {
      overflow: 'hidden'
    }
  },
  esign_form: {
    top: '120px',
    position: 'relative',
    width: '100%',
    height: 'calc(100% - 120px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#a7a7a785',
    overflow: "scroll"
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pdf_box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
    position: 'relative',
    flexDirection: "column",
    height: 'calc(100vh - 122px)',
    flexDirection: "row",
    overflowY: 'scroll',
  },
  abc: {
    height: "100%",
    flexDirection: 'column'
  },
  box_button: {
    lineHeight: 1.25
  },
  left_bar: {
    padding: '15px'
  },
  right_bar: {
    padding: '15px'
  },
});

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

const ESignForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const query = useQueryParams();
  const classes = useStyles();

  const pdfContainerRef = useRef(null);
  const [templateData, setTemplateData] = useState(null);
  const [signerData, setSignerData] = useState([]);
  const [activeField, setActiveField] = useState(null);
  const [touched, setTouched] = useState(false);
  const [resetData, setResetData] = useState({
    isReset: false,
    price: 0,
    terms: ''
  });
  const [reload, setReload] = useState(Date.now());
  const [error, setError] = useState(undefined);
  const requiredFields = useMemo(() => {
    if (templateData) {
      if (templateData.fields[0]?.section) {
        return templateData.fields
          .filter(field => Object.keys(signerData).indexOf(field.key) === -1)
          .filter(signatureElement => signatureElement.section == query.get("section"))
          .sort((a, b) => (a.page_number > b.page_number || a.type === 'PDFSignature' ? 1 : -1));

      } else {
        return templateData.fields
          .filter(field => Object.keys(signerData).indexOf(field.key) === -1)
          .sort((a, b) => (a.page_number > b.page_number || a.type === 'PDFSignature' ? 1 : -1));
      }
      // return templateData.fields
      //   .filter(field => Object.keys(signerData).indexOf(field.key) === -1)
      //   .sort((a, b) => (a.page_number > b.page_number || a.type === 'PDFSignature' ? 1 : -1));
    }
    return [];
  }, [templateData, signerData]);

  useEffect(() => {
    getSignerData();
  }, []);

  /**
   * Get the signer data for signature process
   */
  const getSignerData = () => {
    dispatch(setLoadingStatus(true));
    dispatch(getSignatureData(id, query.get("s"))).then(res => {
      dispatch(setLoadingStatus(false));
      setTemplateData(res.data);
      setReload(Date.now());
    }).catch(err => {
      dispatch(setLoadingStatus(false));
      if (err.response && err.response.data && (err.response.data.msg || err.response.data.message)) {
        err.response.data.msg ? setError(err.response.data.msg) : setError("This signature link has been expired, please contact your broker or customer support at support@boatdox.org.");
      }
    });
  }

  // Function will execute on click of button
  const downloadDocument = () => {
    // using Java Script method to get PDF file
    fetch(templateData.doc_link).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = templateData?.doc_link?.split("/documents/").pop().replaceAll("%20", " ");
        alink.click();
      })
    })
  }

  const updateSignerData = (queue) => {
    let data = {
      ...signerData,
      ...queue
    };

    setSignerData(data);
  }

  const removeSignerData = (key) => {
    let data = {
      ...signerData
    }
    delete data[key]
    setSignerData(data)
  }
  const scrollNext = () => {
    const viewport = { width: 856, height: templateData.height * (856 / templateData.width) };
    const yScroll =
      (viewport.height / 2) * requiredFields[0].page_number + viewport.height -
      (requiredFields[0].coord.y + requiredFields[0].coord.height) * viewport.height / templateData.height - 300;
    let signField = document.getElementById(requiredFields[0].key)
    signField.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center"
    });
    setActiveField(requiredFields[0]);
  }

  const handleComplete = () => {
    dispatch(setLoadingStatus(true));
    dispatch(signDocument(id, query.get("s"), { ...signerData, ...resetData, role: templateData.role, section: query.get("section") })).then(res => {
      dispatch(setLoadingStatus(false));
      if (res.status !== 200) {
        throw res;
      } else {
        history.push({
          pathname: '/e-sign/c/success',
          state: {
            userData: res.data.data,
          }
        });
      }
    }).catch(err => {
      dispatch(setLoadingStatus(false));
      console.log(err);
    })
  }

  const handleReject = async () => {
    // get confirmation and a reason of signature rejection
    const { value: rejectReason } = await Swal.fire({
      title: "Comments",
      icon: "warning",
      input: "textarea",
      allowOutsideClick: false,
      inputLabel: "Please enter a reason for rejecting the signature.",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "Reason is required for rejecting the signature!";
        }
      }
    });
    // call reject API only if user have entered reason for reject siganture
    if (rejectReason) {
      dispatch(setLoadingStatus(true));
      const reqBody = {
        signer: query.get("s"),
        reason: rejectReason
      }
      dispatch(rejectDocument(id, reqBody)).then(res => {
        dispatch(setLoadingStatus(false));
        if (res.status !== 200) {
          throw res;
        } else {
          history.push('/e-sign/c/reject');
        }
      }).catch(err => {
        dispatch(setLoadingStatus(false));
      });
    }
  }

  /**
   * process for upload the current version of document link in S3 bucket
   * @param {*} doc 
   * @returns the uploaded link of current version in S3 bucket
   */
  const uploadDocLink = async (doc, transactionUUID, taskType) => {
    if (doc) {
      try {
        const file = await createFileFromLink(doc, doc.split('/').pop());
        const filename = await uploadForms(file, 'reset-documents', `${taskType === "listing" ? 'LA-' : 'PS-'}${transactionUUID.split('-')[1] || ""}`, `${transactionUUID?.split('-')[0] || ""}`);
        return filename;
      } catch (error) {
        console.error("ERROR:", error)
        return null;
      }
    }
    return null;
  }

  /**
   * On reset the document after confirming the purchase price and terms
   * It will remove all signatures and then reload with latest data in PDF
   * @param {*} resetDetail 
   */
  const handleReset = (resetDetail) => {
    dispatch(setLoadingStatus(true, "Generating new pdf, please be patient as it could take a minute..."));
    const [amount, terms] = resetDetail;
    setResetData({
      isReset: true,
      price: amount,
      terms: terms
    });
    // Upload the current document version in S3 bucket
    uploadDocLink(templateData.doc_link, templateData.unique_transaction_id, templateData.task_type).then(res => {
      const uploadedDoc = res;

      // calculation for Co-Brokerage & Commission
      const grossCommissionAmt = templateData.gross_commission_percent
        ? ((amount * templateData.gross_commission_percent) / 100)
        : null;
      const selllingBrokerAmt = templateData.selling_brokerage_percent
        ? ((grossCommissionAmt * templateData.selling_brokerage_percent) / 100)
        : null;
      const listingBrokerAmt = templateData.listing_brokerage_percent
        ? ((grossCommissionAmt * templateData.listing_brokerage_percent) / 100)
        : null;

      const coBrokerage = {
        gross_commission_percent: templateData.gross_commission_percent,
        gross_commission_amount: grossCommissionAmt,
        selling_brokerage_amount: selllingBrokerAmt,
        listing_brokerage_amount: listingBrokerAmt,
      }

      // API for reset the document and refresh with latest data changes
      dispatch(resetWithCounterOffer(templateData.transaction_edoc_id, {
        transaction_id: templateData.transaction_id,
        document_link: uploadedDoc,
        role: templateData.role,
        template_id: templateData.template_id,
        signer_id: templateData.signer_id,
        purchase_price: amount,
        terms: {
          additional_provisions: terms,
          custom_additional_terms_provisions: terms,
          financing: false,
          financing_date: null,
          trial_run: false,
          trial_run_date: null,
          marine_survey: false,
          marine_survey_date: null,
          mechanical_inspection: false,
          mechanical_inspection_date: null,
          insurance: false,
          insurance_date: null,
          see_addendum: false
        },
        co_brokerage: {
          ...coBrokerage
        }
      })).then(res => {
        getSignerData();
      }).catch(err => {
        dispatch(setLoadingStatus(false));
      });
    }).catch(err => {
      console.log(err);
    });
  }

  return (
    <div>
      {error && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <h4 style={{ textAlign: 'center' }}>{error}</h4>
        </div>
      )}

      {templateData && (
        <div>
          <ESignHeader templateData={templateData} resetData={resetData} reqFields={requiredFields} isHandSigned={templateData?.is_hand_signed} downloadDocument={downloadDocument} scrollNext={scrollNext} handleComplete={handleComplete} handleReject={handleReject} touched={touched} handleReset={handleReset} />
          <div className={classes.esign_form}>
            <Grid container justifyContent="center" alignItems="center" className={classes.abc}>
              <Grid item xs={8} sx={{ minWidth: '900px' }} style={{ backgroundColor: '#ffffff', boxShadow: '5px 5px 10px rgb(0 0 0 / 50%)', minWidth: '900px' }}>
                <Box className={classes.pdf_box} ref={pdfContainerRef} key={reload}>
                  <PDFSignerViewer
                    pdf={templateData?.doc_link}
                    data={templateData?.is_hand_signed ? [] : templateData?.fields}
                    width={templateData?.width || 0}
                    height={templateData?.height || 0}
                    roles={templateData?.roles || []}
                    role_idx={templateData?.role_idx || undefined}  
                    updateSignerData={updateSignerData}
                    removeSignerData={removeSignerData}
                    activeField={activeField}
                    setTouched={setTouched}
                  />
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
};

export default ESignForm;
