import Portal from "./AddFormTaskPortal";

const ModalPortal = ({ children }) => {
  return (
    <div>
      <Portal>
        {children}
      </Portal>
    </div>
  );
}

export default ModalPortal;