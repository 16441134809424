import { Switch, Route, Redirect, useRouteMatch, useParams } from "react-router-dom";
import AddExclusion from "./steps/AddExclusion";
import ExclusionStepBar from "./stepbars/ExclusionStep";
import EsignExclusion from "./steps/EsignExclusion";

const ExclusionOption = () => {
  let { path } = useRouteMatch();
  let { id } = useParams();

  return (
    <div>
      <ExclusionStepBar />
      <Switch>
        <Route exact path={path} render={() => <AddExclusion transactionId={id} />} />
        <Route path={`${path}/send_to_signer`} render={() => <EsignExclusion transactionId={id} />} />
        <Redirect to={path} />
      </Switch>
    </div>
  );
};

export default ExclusionOption;
