import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/reset.css";

import { DELETE_MODAL_MESSAGE, DELETE_MODAL_TITLE } from "../../../constants/Alert";
import { setLoadingStatus } from "../../../redux/actions/loading";
import ReactDragListView from "react-drag-listview";
import { toast } from "react-toastify";
import { deleteTemplate, findTemplateByName } from "../../../redux/actions/templates";
import TemplatesService from "../../../services/TemplatesService";

const EditEsignList = ({ show, setDataForUpdateEsignOrder }) => {
  const dispatch = useDispatch();
  const dataTemplates = useSelector((state) => state.templates.all);
  const [tblData, setTblData] = useState([]);

  const fetchTemplates = () => {
    dispatch(setLoadingStatus(true));
    dispatch(findTemplateByName(""))
    .then((res) => {
      dataTemplates.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
      setTblData(dataTemplates);
      dispatch(setLoadingStatus(false))
    })
    .catch((err) => {
        toast.error("Error while fetching documents.", {
            autoClose: 2000
        })
        dispatch(setLoadingStatus(false))
    })
  }

  useEffect(() => {
    fetchTemplates();
  }, []);

  const obj = {
    "single" : "Single Use",
    "multi_select": "Multi Select",
    "multiple": "Multi Use"
  }

  const columns = [
    {
      title: "",
      width: 80,
      key: "operate",
      render: () => <img src="/icons/move.svg" className="move-svg" />,
    },
    {
      title: "Order",
      render: (row) => (
          <div>
            {
              row?.sort_order + 1 || " "
            }
          </div>
      ),
    },
    {
      title: "Template Name",
      key: "template_name",
      render: (row) => (
        <div data-tag="allowRowEvents">
          <div>{row.template_name}</div>
        </div>
      ),
    },
    {
      title: "Association",
      render: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.association || "N/A"
            }
          </div>
        </div>
      ),
    },
    {
      title: "Type",
      render: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.checklist?.name || "N/A"
            }
          </div>
        </div>
      ),
    },
    {
      title: "Sub Type",
      render: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.use_type && obj[row?.use_type] ? obj[row?.use_type] : ""
            }
          </div>
        </div>
      ),
    },
    {
      title: "Status",
      render: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.status ? "Active" : "In-Active" || "N/A"
            }
          </div>
        </div>
      ),
    },
    {
      title: "Company",
      render: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.company?.company_name || ""
            }
          </div>
        </div>
      ),
    },
    {
      title: "Hand Signed Only",
      render: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.is_hand_signed ? "Yes" : "No"
            }
          </div>
        </div>
      ),
    },
    {
      title: "",
      render: (row) => (
        <div>
            <img src="/icons/delete.svg" className="svg" onClick={() => onDelete(row.id)} alt="" />
        </div>
      ),
    },
  ];

  const dragProps = {
    async onDragEnd(fromIndex, toIndex) {
      const tbl = [...tblData];
      const item = tbl.splice(fromIndex, 1)[0];
      tbl.splice(toIndex, 0, item);
      tbl.forEach((t, index) => {
        t.sort_order = index;
      });
      const data = tbl.filter((t) => (t.id ? true : false));
      setTblData(data)

      // store updated data of sortOrder in state for every drag change 
      setDataForUpdateEsignOrder(data);
    },
    handleSelector: "img",
  };

  const onDelete = (id) => {
    confirmAlert({
      title: DELETE_MODAL_TITLE,
      message: DELETE_MODAL_MESSAGE,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const data = {
              deleted: true,
              deleted_at: new Date(),
            };
            dispatch(deleteTemplate(id))
              .then((response) => {
                dispatch(findTemplateByName(""));
              })
              .catch((e) => {
                alert("Error = ", e);
              });
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  if (!show) {
    return <></>;
  }
  return (
    <>
      <div className="drag_list_view esign_drag_view">
        <ReactDragListView {...dragProps}>
          <Table columns={columns} pagination={false} dataSource={tblData} bordered />
        </ReactDragListView>
      </div>
    </>
  );
};

export default EditEsignList;
