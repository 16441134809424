import FormInputWrapper from "../../../../common/FormInputWapper";
import FormRow from "../../../../common/FormRow";
import ModalBody from "../../../../common/ModalBody";
import OffCanvasContainer from "../../../../common/OffCanvasContainer";
import Country from "../../../../../constants/Country";
import State from "../../../../../constants/StatesForUSA";

import { phoneNumber } from "../../../../../utils/phonenumber";
import { getListingSellerInfo } from "../../../../../utils/customer";

const ViewSellerForm = ({ seller, coseller, type }) => {
  const seller_info = getListingSellerInfo(type, seller);

  let cosel = coseller ? coseller : {};

  let coselname = `${cosel.first_name || ""} ${cosel.last_name || ""}`;
  let coseladdress = `${cosel.address_1 || ""} ${cosel.address_2 || ""} ${cosel.address_3 || ""
    }`;
  coseladdress = coseladdress.replace(/ /g, "") ? coseladdress : "N/A";

  let coselunit = cosel.unit_apt || "N/A";
  let coselcity_state_zip = `${cosel.city || ""} ${cosel.state ? State.find((s) => s.code === cosel.state)?.name || "" : ""
    } ${cosel.postal_code || ""}`;
  coselcity_state_zip = coselcity_state_zip.replace(/ /g, "")
    ? coselcity_state_zip
    : "N/A";
  let coselcountry = cosel.country
    ? Country.find((c) => c.code === cosel.country)?.name || ""
    : "N/A";

  coselname = coselname === " " ? "N/A" : coselname;

  if (!cosel) {
    coselname = "N/A";
    coseladdress = "N/A";
  }

  return (
    <OffCanvasContainer id="modal-seller">
      <ModalBody title="Seller">
        <FormRow>
          <FormInputWrapper label="Name" size='two'>
            <div className="input-view">{seller_info.name}</div>
          </FormInputWrapper>
          <FormInputWrapper label="Entity Name">
            <div className="input-view">{seller_info.entity_name || "N/A"}</div>
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Email" size='two'>
            <div className="input-view">
              {seller_info.email ? <a href={`mailto:${seller_info.email}`} target="_blank">{seller_info.email}</a> : "N/A"}
            </div>
          </FormInputWrapper>
          <FormInputWrapper label="Mobile Phone">
            <div className="input-view">
              {seller_info.phone}
            </div>
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper size="three" label="Address">
            <div className="input-view">{seller_info.address} {seller_info.unit}</div>
            <div className="input-view">{seller_info.city_state_zip}</div>
            <div className="input-view">{seller_info.country}</div>
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Citizen Of">
            <div className="input-view">{seller_info.nationality || "N/A"}</div>
          </FormInputWrapper>
        </FormRow>

        <br />
        <br />
        <h3>Co-Seller</h3>
        <FormRow>
          <FormInputWrapper label="Name" size='two'>
            <div className="input-view">{coselname}</div>
          </FormInputWrapper>
          <FormInputWrapper label="Entity Name">
            <div className="input-view">{cosel.entity_name || "N/A"}</div>
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Email" size='two'>
            <div className="input-view">
              {
                cosel
                  ? (
                    cosel.email
                      ? <a href={`mailto:${cosel.email}`} target="_blank">{cosel.email}</a>
                      : "N/A"
                  )
                  : "N/A"
              }
            </div>
          </FormInputWrapper>
          <FormInputWrapper label="Mobile Phone">
            <div className="input-view">
              {cosel.mobile_phone ? phoneNumber(cosel.mobile_phone) : "N/A"}
            </div>
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper size="three" label="Address">
            <div className="input-view">{coseladdress} {coselunit}</div>
            <div className="input-view">{coselcity_state_zip}</div>
            <div className="input-view">{coselcountry}</div>
          </FormInputWrapper>
        </FormRow>
        <FormRow>
          <FormInputWrapper label="Citizen Of">
            <div className="input-view">{cosel.nationality || "N/A"}</div>
          </FormInputWrapper>
        </FormRow>
      </ModalBody>
    </OffCanvasContainer>
  );
};

export default ViewSellerForm;
