import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { getAllTransactions, updateTransaction } from "../../redux/actions/transaction";
import { toast } from "react-toastify";
import { setLoadingStatus } from "../../redux/actions/loading";
import CreateMLSVesselForm from "./CreateMLSVesselForm";


const CreateMLSVesselModal = ({ vesselId, onClose}) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '80%',
        bgcolor: "#fff",
        border: '2px solid #000',
        boxShadow: 24,
        p: 0,
    };
    return (
        <Modal
            disableEscapeKeyDown
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={vesselId ? true : false}
            onClose={(e, reason) => {
                if (reason === 'backdropClick') {
                    return;
                }
                onClose();
                }
            }
            closeAfterTransition
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Box sx={style}>
                <CreateMLSVesselForm onClose={onClose} vesselId={vesselId}/>
            </Box>
        </Modal>
    );
};

export default CreateMLSVesselModal;