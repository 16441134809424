import {
  CREATE_TASK,
  DELETE_TASK,
  GET_CHECK_LIST,
  GET_ESIGN_DOC,
  GET_IYBA_DOCS_BY_CHECKLIST_ID,
  GET_TASK_BY_CHECK_LIST,
  RETRIEVE_DOCUMENT_TEMPLATES,
  UPDATE_ORDER_TASK_LIST
} from "../actions/types";

const initialState = {
  checklist: [],
  tasks: [],
  esign_doc_types: [],
  eDoc: {},
  templates: [],
  eDocAction: {
    success: undefined,
    loading: undefined,
    message: undefined,
  },
};

const actionForState = (action, state) => ({
  ...action,
  success: state === "success",
  loading: state === "pending",
  message: state,
});

const documentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CHECK_LIST:
      return Object.assign(state, { checklist: payload });
    case GET_TASK_BY_CHECK_LIST:
      return {
        ...state,
        tasks: payload
      }
    case UPDATE_ORDER_TASK_LIST:
      return {
        ...state,
        tasks: payload
      }
    // return Object.assign(state, { tasks: payload });
    case CREATE_TASK:
      return Object.assign(state, { tasks: [...state.tasks, payload] });
    case DELETE_TASK:
      // return Object.assign(state, {
      //   tasks: [...state.tasks.filter((task) => task.id !== payload)],
      // });
      return {
        ...state,
        tasks: [...state.tasks.filter((task) => task.id !== payload)]
      }
    case GET_ESIGN_DOC.pending:
      return {
        ...state,
        eDocAction: actionForState(state.action, "pending"),
      };
    case GET_ESIGN_DOC.success:
      return {
        ...state,
        eDoc: action.payload,
        eDocAction: actionForState(state.action, "success"),
      };
    case GET_ESIGN_DOC.error:
      return {
        ...state,
        eDocAction: actionForState(state.action, "failed"),
      };
    case GET_IYBA_DOCS_BY_CHECKLIST_ID.pending:
      return {
        ...state,
        eDocAction: actionForState(state.action, "pending"),
      };
    case GET_IYBA_DOCS_BY_CHECKLIST_ID.success:
      return {
        ...state,
        esign_doc_types: action.payload,
        eDocAction: actionForState(state.action, "success"),
      };
    case GET_IYBA_DOCS_BY_CHECKLIST_ID.error:
      return {
        ...state,
        eDocAction: actionForState(state.action, "failed"),
      };
    case RETRIEVE_DOCUMENT_TEMPLATES:
      return {
        ...state,
        templates: payload
      };
    default:
      return state;
  }
};

export default documentReducer;
