import { makeStyles } from "@mui/styles";
import Breadcrumb from "./BreadCrumb";
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingStatus } from "../../redux/actions/loading";
import TutorialService from "../../services/TutorialService";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Tooltip } from "@mui/material";
import VideoPlayerModal from "./videoPlayerModal";



const useStyles = makeStyles({
  previewSendDiv: {
    width: "250px !important"
  },
  handSignedDiv: {
    marginTop: "-4px"
  },
  back_button_div: {
    paddingLeft: "37%",
    width: "50% !important"
  },
  arrow_sign: {
    float: "left"
  },
  back_icon: {
    marginBottom: "3px",
    marginRight: "1px"
  },
  back_icon_span: {
    marginRight: "75px",
    fontSize: "larger"
  }
});

// for capitalized strings
function tooltipTitle(str) {
  const words = str.split('_');

  const capitalizedWords = words.map((word, index) => {
    return index === words.length - 1 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : "";
  });

  return capitalizedWords.join(' ');
}


const TransactionDetails = ({ links, title, status, type, typeLink, uniqueId, currentTab }) => {

  let history = useHistory();
  let style = `stylus-bg-${status.toLowerCase()}`;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null)

  const tutorials = useSelector((state) => state?.tutorials) || null;

  useEffect(() => {
    setCurrentVideo(tutorials[currentTab]);
  }, [tutorials, currentTab]);

  const onCloseVideoPlayerModal = () => {
    setShow(() => false);
  }

  return (
    <>
      <div className="uk-container transaction-details uk-container-main-section">
        <Breadcrumb links={links} />
        <div className="d-flex flex-wrap justify-content-between  align-items-center ">
          <div className="d-flex flex-wrap justify-content-between  align-items-center ">
            <h1 className="d-flex align-items-center gap-1 m-0">
              {currentVideo && <Tooltip title={`Play ${tooltipTitle(currentTab)} Tutorial`} arrow placement='top' style={{ textTransform: "capitalize" }}  >
                <PlayCircleOutlineIcon fontSize="large" style={{ cursor: "pointer" }} className="mr-1" onClick={() => setShow(true)} />
              </Tooltip>}{title}</h1>
            <span className={`uk-label mb-2 mt-0 mr-2 ml-2 ${style}`}>{status}</span>
            {uniqueId && <span className="uk-label stylus-bg-in-process mb-2 mt-0 mr-2 ml-2">UID: {uniqueId}</span>}
          </div>
          <div>
            <button className='uk-button uk-button-primary mb-0 mt-0 pl-2 text-capitalize' onClick={(e) => {
              e.preventDefault();
              history.push(typeLink)
            }}>
              <FirstPageIcon className={classes.back_icon}></FirstPageIcon>
              <span>{type}</span>
            </button>
          </div>
        </div>
      </div>
      {show && <VideoPlayerModal show={show} onClose={onCloseVideoPlayerModal} videoTitle={currentVideo} />}
    </>

  );
};

export default TransactionDetails;
