import { Link } from "react-router-dom";
import clsx from "clsx";

const Breadcrumb = ({ links }) => {
  return (
    <ul className="uk-breadcrumb">
      {links.map((link, index) => {
        return (
          <li
            key={`link${index}`}
            className={clsx("text-capitalize", { "uk-disabled": index === links.length - 1 })}
          >
            {index === links.length - 1 && `${link.label}`}
            {index !== links.length - 1 && <Link to={link.link}>{link.label}</Link>}
          </li>
        );
      })}
    </ul>
  );
};

export default Breadcrumb;
