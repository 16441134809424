import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearVessel, createVesselOrToy, getGlobalVesselOrToy, getMLSVessel, getVesselOrToy, updateVesselOrToy } from "../../redux/actions/vessels_toys";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import clsx from "clsx";

import FormPage from "../common/FormPage";
import Form from "../common/Form";
import FormRow from "../common/FormRow";
import "./form.css"
import FormInputWrapper from "../common/FormInputWapper";

import { setLoadingStatus } from "../../redux/actions/loading";

import dropdownLists from '../../constants/dropdownLists';
import { getLength, getOrigin } from "../../utils/length";
import { getFormattedData } from "../../utils/stepFilter";
import { makeStyles } from '@mui/styles';
import { fetchAllAccessibleBroker } from "../../redux/actions/users";
import Auth from "../../utils/auth";
import AutoCompleteSearch from "../common/Autocomplete";
import { useMemo } from "react";
import VesselAndToyService from "../../services/VesselAndToyService";
import SearchMLSVessel from "../common/searchMLSVessel";
import { Autocomplete, TextField } from "@mui/material";
import vessels from "../../constants/dropdownLists/vessel-manufacturer";

const useStyles = makeStyles({
  broker_dropdown: {
    marginTop: "10px",
  },
  broker_div: {
    marginLeft: "-64px",
    marginTop: "18px"
  },
  broker_dropdown_label: {
    marginTop: "31px",
    // marginRight: "-20px",
    // marginLeft: "-14px"
  }
});

const { boatMakes, boatTypes, engineMakes } = dropdownLists;

const schema = yup.object().shape({
  vessel_name: yup.string().required(),
  hull_number: yup.string().when(['is_old_built'], {
    is: (is_old_built) => is_old_built !== true,
    then: yup.string().required().min(12),
    otherwise: yup.string().nullable()
  }),
  length: yup.string().required(),
  make: yup.string().required(),
  model: yup.string(),
  year: yup.number().required(),
  broker_user_id: yup.string().required(),
  boat_category: yup.string(),
  doc_no: yup.string().when(['reg_no', 'title_no'], {
    is: (reg_no, title_no) => (!reg_no || reg_no.length === 0) && (!title_no || title_no.length === 0),
    then: yup.string().required(),
    otherwise: yup.string().nullable()
  }),
  reg_no: yup.string().when(['doc_no', 'title_no'], {
    is: (doc_no, title_no) => (!doc_no || doc_no.length === 0) && (!title_no || title_no.length === 0),
    then: yup.string().required(),
    otherwise: yup.string().nullable()
  }),
  title_no: yup.string().when(['doc_no', 'reg_no'], {
    is: (doc_no, reg_no) => (!doc_no || doc_no.length === 0) && (!reg_no || reg_no.length === 0),
    then: yup.string().required(),
    otherwise: yup.string().nullable()
  })
}, [['reg_no', 'title_no'], ['doc_no', 'title_no'], ['doc_no', 'reg_no']]);

const MAX_ENGINE_COUNT = 6;

const VesselForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();

  const logged_user = Auth.getInstance().getUserData();

  /* Define State Variables */
  const [isFinishSubmit, setFinishSubmit] = useState(false);

  //State for mls vessel list
  const [mlsVesselList, setMlsVesselList] = useState([]);
  const [err, setErr] = useState(null);
  const [lengthUnit, setLengthUnit] = useState("");
  const [boatType, setBoatType] = useState("");
  const [numberOfEngines, setNumberOfEngines] = useState(1);
  const [isDutyPaid, setIsDutyPaid] = useState(null);
  const [isSalesPaid, setIsSalesPaid] = useState(null);
  const [isOld, setOld] = useState(false);
  const [isForeignBuiltVessel, setIsForeignBuiltVessel] = useState(false);

  const [requiredFields, setRequiredFields] = useState(['hull_number', 'length', 'make', 'year']);

  const [boatCategories, setBoatCategories] = useState([]);
  const [boatManufacturers, setBoatManufacturers] = useState([]);

  const dataVessel = useSelector((state) => state.vessel_toys.current);
  const allAccessibleBrokers = useSelector((state) => state.users.allAccessibleBrokers);
  allAccessibleBrokers.sort((a, b) => a.last_name < b.last_name ? -1 : 1)

  useEffect(() => {
    dispatch(fetchAllAccessibleBroker(logged_user.id))
  }, [])


  const dataPowerCategories = useSelector((state) => {
    if (state.vessel_toys.mlsFilters.PowerCategories) {
      return state.vessel_toys.mlsFilters.PowerCategories || [];
    } else {
      return [];
    }
  });

  const dataSailCategories = useSelector((state) => {
    if (state.vessel_toys.mlsFilters.SailCategories) {
      return state.vessel_toys.mlsFilters.SailCategories || [];
    } else {
      return [];
    }
  });

  let dataManuFecturers = useSelector((state) => {
    return state.vessel_toys.mlsFilters.manufacturers || [];
  });

  dataManuFecturers = dataManuFecturers.filter((item, i, ar) => ar.indexOf(item) === i).map(mfr => { return { label: mfr } });

  // const dataManuFecturers = vessels.sort();

  const vessel_id = props.location.state ? props.location.state.id : null;
  const isAddMode = !vessel_id;

  const keys = [
    "lienholder",
    "hull_number",
    "is_old_built",
    "vessel_name",
    "length",
    "model",
    "make",
    "boat_type",
    "boat_category",
    "year",
    "flag",
    "description",
    "doc_no",
    "doc_no_issuer",
    "reg_no",
    "reg_no_issuer",
    "title_no",
    "title_no_issuer",
    "place_of_registration",
    "is_duty_paid",
    "sales_use_tax_paid",
    "number_of_engines",
    "engine_make",
    "engine_model",
    "engine_hp",
    "broker_user_id",
    "company_office_id",
    "company_id",
    "createdBy",
    "stock",
    "uscg"
  ];

  Array.from({ length: MAX_ENGINE_COUNT }).forEach((_, index) => {
    const realIndex = index + 1;
    keys.push(`engine_serial_${realIndex}`);
    keys.push(`engine_year_${realIndex}`);
    keys.push(`engine_hours_${realIndex}`);
  });

  /* Form Validation */
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    trigger,
    setFocus,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  let user = JSON.parse(localStorage.getItem("user"));
  /* If isAddMode=false, get client by id */

  const [makeValue, setMakeValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    let categories = boatType == "Power" ? dataPowerCategories : dataSailCategories;
    setBoatCategories(categories.sort());
  }, [boatType]);

  useEffect(() => {
    return () => {
      dispatch(clearVessel());

    };
  }, []);

  useEffect(() => {
    if (vessel_id) {
      dispatch(getGlobalVesselOrToy(vessel_id));
    }
  }, [isAddMode]);

  useEffect(() => {
    setTimeout(() => {
      setValue("boat_category", dataVessel?.boat_category || "");
    }, 1000);
  }, [dataVessel])

  useEffect(() => {
    if (dataVessel) {
      keys.forEach((key) => {
        setValue(key, dataVessel[key] ? `${dataVessel[key]}` : null);
        if (key == "createdBy") {
          setValue("createdBy", dataVessel[key] ? dataVessel[key] : logged_user.id)
        }
      });

      setIsDutyPaid(dataVessel["is_duty_paid"]);
      setIsSalesPaid(dataVessel["sales_use_tax_paid"]);
      setNumberOfEngines(dataVessel["number_of_engines"] || 1);
      setBoatType(dataVessel?.boat_type || "Power");
      setIsForeignBuiltVessel(dataVessel?.foreign_built_vessel);
      setMakeValue(dataVessel?.make ? { label: dataVessel?.make } : null);

      requiredFields.map((value) => {
        manageRequireFields(value, getValues(value));
      });

    } else {
      keys.forEach((key) => setValue(key, null));
      setValue("broker_user_id", logged_user.id)
      setValue("company_office_id", logged_user.company_office_id)
      setValue("createdBy", logged_user.id)
      setValue("company_id", logged_user.company_id)
      setMakeValue(null);
      setValue("flag", "US");
    }
    setValue("updatedBy", logged_user.id)
    setValue("number_of_engines", dataVessel?.number_of_engines || 1);
    setLengthUnit(dataVessel?.length_unit || "ft");
    setValue("length", getLength(dataVessel?.length));
    setOld(dataVessel?.is_old_built || false);
    setBoatType(dataVessel?.boat_type || "Power");
    setValue("boat_category", dataVessel?.boat_category || "");
  }, [dataVessel]);

  useEffect(() => {
    let figure = getOrigin(getValues("length"));
    setValue("length", getLength(figure, lengthUnit));
  }, [lengthUnit]);

  const handleBlur = () => {
    let figure = getOrigin(getValues("length"));
    setValue("length", getLength(figure, lengthUnit));
  };

  //UseEffect for add fields as per number of field is selected.
  useEffect(() => {
    setNumberOfEngines(getValues('number_of_engines'));
  }, [watch('number_of_engines')]);

  const handleFocus = () => {
    let symbolitic = getValues("length");
    setValue("length", getOrigin(symbolitic, lengthUnit));
  };

  /* Handle Function for From Inputs */
  const handleRegistration = (vessel) => {
    dispatch(setLoadingStatus(true));

    vessel = getFormattedData(vessel);
    vessel.vessel_type = "vessel";
    vessel.is_duty_paid = isDutyPaid;
    vessel.sales_use_tax_paid = isSalesPaid;
    vessel.length_unit = lengthUnit;
    vessel.boat_type = boatType;
    vessel.length = getOrigin(vessel.length, lengthUnit);
    vessel.is_old_built = isOld;
    vessel.foreign_built_vessel = isForeignBuiltVessel;
    vessel.make = makeValue?.label || '';

    if (isAddMode) {
      vessel.length = getOrigin(vessel.length, lengthUnit);
      dispatch(createVesselOrToy(vessel))
        .then((response) => {
          setFinishSubmit(true);
          setErr(null);
          dispatch(setLoadingStatus(false));
          setTimeout(() => {
            history.push("/vessels");
          }, 2000);
        })
        .catch((e) => {
          setErr(e.response ? e.response.data.message : e.message);
          dispatch(setLoadingStatus(false));
          setTimeout(() => {
            setErr(null);
          }, 2000);
        });
    } else {
      dispatch(updateVesselOrToy(vessel_id, vessel))
        .then((response) => {
          setFinishSubmit(true);
          setErr(null);
          dispatch(setLoadingStatus(false));
          setTimeout(() => {
            history.push("/vessels");
          }, 2000);
        })
        .catch((e) => {
          setErr(e.response ? e.response.data.message : e.message);
          dispatch(setLoadingStatus(false));
          setTimeout(() => {
            setErr(null);
          }, 2000);
        });
    }
  };

  const handleError = (errors) => {
    console.log('ERROR=> ', errors)
    Object.keys(errors)[0] && setFocus(Object.keys(errors)[0], { shouldSelect: true });
  };

  const links = [
    { label: "Vessels", link: "/vessels" },
    { label: isAddMode ? "Add a Vessel" : "Edit Vessel", link: null },
  ];

  const handleChangeBrokerDropdown = (event) => {
    const company_id = event.target[event.target.selectedIndex].getAttribute("data-company")
    const office_id = event.target[event.target.selectedIndex].getAttribute("data-office")
    setValue("broker_user_id", event.target.value)
    setValue("company_id", company_id)
    setValue("company_office_id", office_id)
    isAddMode && setValue("createdBy", event.target.value);
  }

  const handleSelect = (value) => {
    if (value?.id && getValues("broker_user_id")) {
      dispatch(getMLSVessel(getValues("broker_user_id"), value?.id));
    }
  };

  const manageRequireFields = (fieldName, fieldValue) => {
    setRequiredFields(state => {
      if (fieldValue) {
        if (fieldName == 'hull_number' && getValues('hull_number').length < 12) {
          return state.includes(fieldName) ? state : [...state, fieldName];
        }
        return state.filter(field => field != fieldName);
      }
      return state.includes(fieldName) ? state : [...state, fieldName];
    });
  }

  return (
    <FormPage links={links}>
      <div className="d-flex justify-content-between align-items-center uk-margin-small ">
        <h1 className="addVesselH1">{isAddMode ? "Add Vessel" : "Edit Vessel"}</h1>
        <div className="d-flex align-items-center drop-down-div">
          <div className="col-4">
            <label className={`uk-form-label`} style={{ "marginRight": "10px" }}>Broker</label>
            <select
              className={clsx(`uk-select `, { "uk-form-danger": errors.broker_user_id?.message })}
              {...register('broker_user_id')}
              onChange={(event) => handleChangeBrokerDropdown(event)}
            >
              <option key={`vessel_broker_user`} data-company={logged_user.company_id} data-office={logged_user.company_office_id} value={logged_user.id}>{logged_user.last_name} {logged_user.first_name} </option>
              {
                allAccessibleBrokers && allAccessibleBrokers.length > 0 && allAccessibleBrokers.map((broker, id) => {
                  return (
                    <option key={id} data-company={broker.company_id} data-office={broker.company_office_id} value={broker.id}>{broker.last_name} {broker.first_name} </option>
                  )
                })
              }
            </select>
          </div>
          <SearchMLSVessel userId={getValues("broker_user_id")} onSelect={handleSelect} from={"vessels"} />
        </div>
      </div>

      {isFinishSubmit ? (
        isAddMode ? (
          <div className="uk-alert uk-alert-primary" data-uk-alert>
            <p>Added Successfully</p>
          </div>
        ) : (
          <div className="uk-alert uk-alert-primary" data-uk-alert>
            <p>Updated Successfully</p>
          </div>
        )
      ) : undefined}
      {err ? (
        <div className="uk-alert uk-alert-danger" data-uk-alert>
          <p>{err}</p>
        </div>
      ) : (
        ""
      )}

      <div className="form-container uk-margin-small" style={{ marginBottom: '36px' }}>
        <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
          <FormRow>
            <FormInputWrapper label="Hull Identification Number (HIN) *">
              <input className={clsx("uk-input", { "uk-form-danger": requiredFields.includes('hull_number') && !isOld })}
                type="text" placeholder="Hull number must be 12 digits"
                {...register("hull_number", {
                  onChange: e => {
                    manageRequireFields('hull_number', e.target.value);
                  }
                })} />
            </FormInputWrapper>
            <div className='one-column uk-padding-small d-flex align-items-center'>
              <input className="mt-4" name='isOldHIN' id='isOldHIN' type="checkbox" checked={isOld} onClick={() => { setOld(!isOld); setValue('is_old_built', !isOld); }} onChange={() => { }} style={{ cursor: "pointer" }} />
              <label className='uk-form-label mt-4' htmlFor="isOldHIN" style={{ whiteSpace: 'break-spaces', cursor: 'pointer' }}>
                HIN is not available or is not 12 character USCG compliant.
              </label>
            </div>
          </FormRow>

          <FormRow>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="vessel_name">
                Vessel Name
              </label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.vessel_name?.message,
                })}
                type="text"
                {...register("vessel_name")}
              />
            </div>

            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="stock">
                Stock #
              </label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.stock?.message,
                })}
                type="text"
                {...register("stock")}
              />
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="uscg">USCG/Official #</label>
              <input className="uk-input" type="text" {...register("uscg")} />
            </div>
          </FormRow>

          <FormRow>
            <FormInputWrapper label="Length *">
              <input
                className={clsx("uk-input", { "uk-form-danger": requiredFields.includes('length') })}
                type="text"
                {...register("length", {
                  onChange: e => {
                    manageRequireFields('length', e.target.value);
                  }
                })}
                onBlur={handleBlur}
                onFocus={handleFocus}
              />
            </FormInputWrapper>

            <FormInputWrapper label="Length Unit">
              <input
                type="radio"
                name="length_unit"
                value="ft"
                onClick={() => setLengthUnit("ft")}
                onChange={() => { }}
                checked={lengthUnit === "ft"}
              />
              <label className="uk-form-label radio">Feet</label>
              <input
                type="radio"
                name="length_unit"
                value="m"
                onClick={() => setLengthUnit("m")}
                onChange={() => { }}
                checked={lengthUnit === "m"}
              />
              <label className="uk-form-label ratio">Meters</label>
            </FormInputWrapper>
          </FormRow>

          <FormRow>
            <FormInputWrapper label="Category">
              <select
                className="uk-select"
                {...register('boat_category')}
                disabled={!boatType}
              >
                <option key={`vessel_boat_category_default`} value=""></option>
                {boatCategories.map((boatCategoryKey, idx) => (
                  <option key={`vessel_boat_category_${idx}`} value={boatCategoryKey}>{boatCategoryKey}</option>
                ))}
              </select>
            </FormInputWrapper>

            <FormInputWrapper label="Type">
              <input
                type="radio"
                name="boat_type"
                value="Power"
                onClick={() => {
                  setBoatType("Power");
                  setValue("boat_category", "")
                  // setBoatCategories(dataPowerCategories);
                }}
                onChange={() => { }}
                checked={boatType === "Power"}
              />
              <label className="uk-form-label radio">Power</label>
              <input
                type="radio"
                name="boat_type"
                value="Sail"
                onClick={() => {
                  setBoatType("Sail");
                  setValue("boat_category", "")
                  // setBoatCategories(dataSailCategories);
                }}
                onChange={() => { }}
                checked={boatType === "Sail"}
              />
              <label className="uk-form-label ratio">Sail</label>
            </FormInputWrapper>

          </FormRow>

          <FormRow>
            <FormInputWrapper label="Manufacturer/Builder *">
              <Autocomplete
                id="size-small-outlined"
                size="small"
                isOptionEqualToValue={(option, value) => option.label === value.label}
                value={makeValue}
                style={{ background: `${requiredFields.includes('make') ? '#fff7f8' : ''}` }}
                onChange={(event, newValue) => {
                  setMakeValue(newValue);
                  manageRequireFields('make', newValue?.label);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                options={dataManuFecturers}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormInputWrapper>

            <FormInputWrapper label="Model">
              <input
                className="uk-input"
                type="text"
                {...register("model")}
              />
            </FormInputWrapper>

            <FormInputWrapper label="Year *">
              <input className={clsx("uk-input", { "uk-form-danger": errors.year?.message })} type="text"
                {...register("year", {
                  onChange: e => {
                    manageRequireFields('year', e.target.value);
                  }
                })} />
            </FormInputWrapper>

          </FormRow>

          <FormRow>
            <FormInputWrapper label="Number of Engines">
              <select
                className="uk-select"
                {...register("number_of_engines")}
              >
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
              </select>
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Engine Make">
              <input className="uk-input" type="text" {...register("engine_make")} />
            </FormInputWrapper>
            <FormInputWrapper label="Engine Model">
              <input className="uk-input" type="text" {...register("engine_model")} />
            </FormInputWrapper>
            <FormInputWrapper label="Engine HP">
              <input className="uk-input" type="text" {...register("engine_hp")} />
            </FormInputWrapper>
          </FormRow>
          {Array.from({ length: numberOfEngines }).map((_, index) => {
            const realIndex = index + 1;

            return (
              <FormRow key={`engine_${index}`}>
                <FormInputWrapper label={`Engine ${realIndex} Year`}>
                  <input className="uk-input" type="text" {...register(`engine_year_${realIndex}`)} />
                </FormInputWrapper>
                <FormInputWrapper label={`Engine ${realIndex} Serial`}>
                  <input className="uk-input" type="text" {...register(`engine_serial_${realIndex}`)} />
                </FormInputWrapper>
                <FormInputWrapper label={`Engine ${realIndex} Hours`}>
                  <input className="uk-input" type="text" {...register(`engine_hours_${realIndex}`)} />
                </FormInputWrapper>
              </FormRow>
            );
          })}
          <FormRow>
            <FormInputWrapper label="Flag">
              <input className="uk-input" type="text" {...register("flag")} />
            </FormInputWrapper>
            <FormInputWrapper size="one" label="Foreign Built Vessel">
              <input
                type="radio"
                name="foreign_built_vessel"
                value={true}
                onClick={() => setIsForeignBuiltVessel(true)}
                onChange={() => { }}
                checked={isForeignBuiltVessel}
              />
              <label className="uk-form-label radio">Yes</label>
              <input
                type="radio"
                name="foreign_built_vessel"
                value={false}
                onClick={() => setIsForeignBuiltVessel(false)}
                onChange={() => { }}
                checked={!isForeignBuiltVessel}
              />
              <label className="uk-form-label radio">No</label>
            </FormInputWrapper>
            <FormInputWrapper label="Place of Registration">
              {/* place_of_registration */}
              <input className={clsx("uk-input", { 'uk-form-danger': errors.place_of_registration })} type="text" {...register("place_of_registration")} />
            </FormInputWrapper>
          </FormRow>

          <FormRow>
            <p style={{ color: '#5479A3' }} className={clsx("", { "uk-text-danger uk-text-bold": errors.doc_no?.message || errors.reg_no?.message || errors.title_no?.message })}>Please input at least one: Doc No., Reg No., or Title No.</p>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Doc No.">
              <input className={clsx("uk-input", { 'uk-form-danger': errors.doc_no })} type="text" {...register("doc_no")} onKeyUp={() => {
                trigger(["reg_no", "title_no"]);
              }} />
            </FormInputWrapper>
            <FormInputWrapper label="Reg No.">
              <input className={clsx("uk-input", { 'uk-form-danger': errors.reg_no })} type="text" {...register("reg_no")} onKeyUp={() => {
                trigger(["doc_no", "title_no"]);
              }} />
            </FormInputWrapper>
            <FormInputWrapper label="Title No.">
              <input className={clsx("uk-input", { 'uk-form-danger': errors.title_no })} type="text" {...register("title_no")} onKeyUp={() => {
                trigger(["reg_no", "doc_no"]);
              }} />
            </FormInputWrapper>
          </FormRow>
          <FormRow>
            <FormInputWrapper label="Doc No. Issuing Entity">
              <input className={clsx("uk-input", { 'uk-form-danger': errors.doc_no_issuer })} type="text" {...register("doc_no_issuer")} />
            </FormInputWrapper>
            <FormInputWrapper label="Reg No. Issuing Entity">
              <input className={clsx("uk-input", { 'uk-form-danger': errors.reg_no_issuer })} type="text" {...register("reg_no_issuer")} />
            </FormInputWrapper>
            <FormInputWrapper label="Title No. Issuing Entity">
              <input className={clsx("uk-input", { 'uk-form-danger': errors.title_no_issuer })} type="text" {...register("title_no_issuer")} />
            </FormInputWrapper>
          </FormRow>

          <FormRow>
            <FormInputWrapper size="one" label="Duty Paid">
              <input
                type="radio"
                name="duty_paid"
                value={true}
                onClick={() => setIsDutyPaid(true)}
                onChange={() => { }}
                checked={isDutyPaid != null && isDutyPaid}
              />
              <label className="uk-form-label radio">Yes</label>
              <input
                type="radio"
                name="duty_paid"
                value={false}
                onClick={() => setIsDutyPaid(false)}
                onChange={() => { }}
                checked={isDutyPaid != null && !isDutyPaid}
              />
              <label className="uk-form-label radio">No</label>
              <input
                type="radio"
                name="duty_paid"
                value={false}
                onClick={() => setIsDutyPaid(null)}
                onChange={() => { }}
                checked={isDutyPaid == null}
              />
              <label className="uk-form-label radio">Unknown</label>
            </FormInputWrapper>
            <FormInputWrapper label="Sales/Use Tax Paid">
              <input
                type="radio"
                name="sales_use_tax_paid"
                value={true}
                onClick={() => setIsSalesPaid(true)}
                onChange={() => { }}
                checked={isSalesPaid != null && isSalesPaid}
              />
              <label className="uk-form-label radio">Yes</label>
              <input
                type="radio"
                name="sales_use_tax_paid"
                value={false}
                onClick={() => setIsSalesPaid(false)}
                onChange={() => { }}
                checked={isSalesPaid != null && !isSalesPaid}
              />
              <label className="uk-form-label radio">No</label>
              <input
                type="radio"
                name="sales_use_tax_paid"
                value={false}
                onClick={() => setIsSalesPaid(null)}
                onChange={() => { }}
                checked={isSalesPaid == null}
              />
              <label className="uk-form-label radio">Unknown</label>
            </FormInputWrapper>
            {/* <FormInputWrapper label="Lienholder Name">
              <input className={clsx("uk-input", { 'uk-form-danger': errors.lienholder })} type="text" {...register("lienholder")} />
            </FormInputWrapper> */}
          </FormRow>

          <FormRow>
            <input
              type="submit"
              className="uk-button iy-button-primary"
              id="add-vessel-save"
              value={isAddMode ? "Add" : "Save"}
            />
            <Link to={"/vessels"}>
              <button className="uk-button uk-button-default" id="add-cancel">
                Cancel
              </button>
            </Link>
          </FormRow>
        </Form>
      </div>
    </FormPage>
  );
};

export default VesselForm;
