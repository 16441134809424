import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Helmet } from "react-helmet";
import VesselForm from "./Form";
import VesselsList from "./List";
import VesselView from "./View";

const VesselPage = () => {
  let { path } = useRouteMatch();
  return (
    <>
      <Helmet>
        <title>Vessels - BoatDox</title>
      </Helmet>
      <Switch>
        <Route exact path={path} component={VesselsList} />
        <Route path={`${path}/add`} component={VesselForm} />
        <Route path={`${path}/edit`} component={VesselForm} />
        <Route path={`${path}/view`} component={VesselView} />
      </Switch>
    </>
  );
};

export default VesselPage;
