


import { makeStyles } from "@mui/styles";
import { convertFirstCharIntoSmallCase } from "../../../../../utils/functions";
import { DatePicker } from "antd";
import moment from "moment";
import { dateFormatter } from "../../../../../utils/common";

const useStyles = makeStyles({
  previewSendDiv: {
    width: "250px !important",
  },
  handSignedDiv: {
    marginTop: "-4px",
  },
});
const CustomActionButtons = ({
  role,
  signers,
  listings,
  isValidSend,
  handleOpenViewDocumentModal,
  previewByStep,
  onAdditionalMessageModalOpen,
  onHandSignUploadModalOpen,
  dataTransaction,
  isValidPreview = null
}) => {

  const classes = useStyles();

  const getSignerByRole = () => {
    switch (role) {
      case 'Selling-Broker':
      case 'Listing-Broker':
      case 'Broker':
        return dataTransaction.broker;

      case 'Owner':
        return dataTransaction.seller;

      case 'Co-Owner':
        return dataTransaction.co_seller;

      default:
        return dataTransaction[convertFirstCharIntoSmallCase(role)];
    }
  }

  return (
    <>
      <div className="status-main-block">
        <div className="signer-status-block"></div>
        <div className="signer-button-block">
          <button
            className="uk-button uk-button-default"
            type="button"
            id="preview-nyba-listing-agreement-for-esign"
            onClick={(evt) => {

              const signed_document = signers.find(
                (signer) => signer.request_signer.role === role
              )?.hand_signed_docs.length
                ? signers.find((signer) => signer.request_signer.role === role)?.hand_signed_docs
                : null;
              signed_document
                ? handleOpenViewDocumentModal(signed_document)
                : previewByStep(evt, role);
            }}
            disabled={!listings.transaction || (isValidPreview && !isValidPreview(listings.requiredFields))}
          >
            <i className="fa fa-cloud-download" area-hidden="true"></i>
            &nbsp;Preview
          </button>
          <div>
            <button
              className="uk-button uk-button-primary"
              id="send-nyba-listing-agreement-for-esign"
              onClick={(evt) => onAdditionalMessageModalOpen("send", role, getSignerByRole())}
              disabled={!listings.transaction || !isValidSend()}
            >
              <i className="fa fa-pencil-square-o" area-hidden="true"></i>
              &nbsp;Send
            </button>
          </div>
          <div className={classes.handSignedDiv}>
            <label className="uk-form-label" htmlFor="buyer">
              Hand Signed?
            </label>
            <DatePicker
              className="uk-input uk-form-width-small"
              style={{ cursor: 'pointer', color: '#000' }}
              format={'MM/DD/YYYY'}
              disabled={!listings.transaction || !isValidSend()}
              onChange={async (date, dateString) => {
                onHandSignUploadModalOpen(role, dateFormatter(dateString));
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomActionButtons;
