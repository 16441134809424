import http from '../http-common';

const getESignURL = (signature_id) => {
  return http.get(`/signer/${signature_id}`);
};

const getSignatureData = (template_id, signer) => {
  return http.get(`/signer/c/${template_id}?s=${signer}`);
};

const signDocument = (template_id, signer, data) => {
  return http.post(`/signer/c/${template_id}?s=${signer}`, data);
}

const resetWithCounterOffer = (edoc_id, data = null) => {
  return http.post(`/signer/c/reset-and-update-counter-offer/${edoc_id}`, data)
}

const rejectDocument = (template_id, data = null) => {
  return http.post(`/signer/c/reject/${template_id}`, data);
}

const ESignService = {
  getESignURL,
  getSignatureData,
  signDocument,
  rejectDocument,
  resetWithCounterOffer
};

export default ESignService;
