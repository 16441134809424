import { Box } from "@mui/material";
import React, { useMemo, useState, useEffect, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocument } from 'pdf-lib';
import { Buffer } from "buffer";
import { useDispatch } from "react-redux";
import { setLoadingStatus } from "../../redux/actions/loading";

pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

const PdfPagination = ({ doc, pageProps, mergeProps, getEditedPdfUrl, undoProps, redoProps , setFieldTrackArr , onSaveDocumentVersion,onMergeDocument}) => {
  const { pageNumber, setPageNumber, numPages, setNumPages } = pageProps;

  const { mergedPdfUrl, setMergedPdfUrl } = mergeProps;

  const { disableUndo, setDisableUndo } = undoProps;
  const { disableRedo, setDisableRedo } = redoProps;

  const [files, setFiles] = useState([])
  const ref = useRef();
  const dispatch = useDispatch();

  // in this useEffect pdf will merge based on edition
  useEffect(() => {

    if (disableUndo) {
      files.length && mergePdfs(doc);
    }
    else {
      files.length && getEditedPdfUrl(doc).then((res) => mergePdfs(res));

      // disable undo redo button and do empty changes tracking array after merge pdf
      setDisableUndo(true);
      setDisableRedo(true);
      setFieldTrackArr([]);
    }

  }, [doc, files]);

  // function for merge two pdf
  const mergePdfs = async (initialUrl) => {

    dispatch(setLoadingStatus(true));

    const mergedPdf = await PDFDocument.create();

    // Load the main doc
    const mainPdf = await PDFDocument.load(await fetch(initialUrl).then(res => res.arrayBuffer()));

    const mainPdfPages = await mergedPdf.copyPages(mainPdf, mainPdf.getPageIndices());
    mainPdfPages.forEach((page) => mergedPdf.addPage(page));

    // Load additional docs and merge
    for (const additionalDoc of files) {

      const additionalPdf = await PDFDocument.load(await additionalDoc.arrayBuffer());
      const additionalPdfPages = await mergedPdf.copyPages(additionalPdf, additionalPdf.getPageIndices());
      additionalPdfPages.forEach((page) => mergedPdf.addPage(page));
    }

    // Serialize the merged PDF to bytes
    const mergedPdfBytes = await mergedPdf.save();
    const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });

    await onMergeDocument(URL.createObjectURL(blob));

    // set mergedPdf url for render it
    // setMergedPdfUrl(URL.createObjectURL(blob));

    dispatch(setLoadingStatus(false));
  };

  const fileOptions = useMemo(() => ({ url: mergedPdfUrl || doc }), [mergedPdfUrl, doc]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleSelectFile = (e) => {
    document.getElementById('file').click();
  }

  const handleFileChange = async (e) => {
    // manage selected file in one state for merging purpose
    setFiles(prev => [...prev, e.target.files[0]]);
  }

  return (
    <Box sx={{ border: 0 }}>
      <Document
        file={fileOptions}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <div key={index} style={{ border: index + 1 === pageNumber ? '2px solid #3e8eff' : 'none' }} className="d-flex flex-column justify-center m-1">
            <div style={{ cursor: 'pointer' }} className="p-2">
              <Page
                scale={0.16}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                style={{ height: 70, width: 55, cursor: 'pointer' }}
                onLoadError={(error) => alert('Error while loading doc! ' + error.message)}
                onClick={() => setPageNumber(index + 1)}
              />
            </div>
            <span style={{ textAlign: "center", fontSize: "15px", background: index + 1 === pageNumber ? '#3e8eff' : 'transparent' }}>
              {index + 1}
            </span>
          </div>
        ))}
        <div className="shadow d-flex align-items-center justify-content-center  add-pdf-button rounded p-1">
          <input id="file" type="file" accept="application/pdf" onChange={handleFileChange} ref={ref} hidden />
          <span className="h-100 w-100 d-flex align-items-center justify-content-center" onClick={handleSelectFile}>+</span>
        </div>
      </Document>
    </Box>
  );
};

export default PdfPagination;
