import {
  CREATE_EDOC,
  RETRIEVE_EDOCS,
  GET_EDOC,
  UPDATE_EDOC,
  DELETE_EDOC,
  DELETE_ALL_EDOCS,
  FIND_EDOC_BY_NAME,
  CLEAR_EDOC,
} from "./types";

import EDocService from "../../services/EDocService";

export const createEDoc = (doc) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_EDOC.pending,
      payload: 'loading'
    });

    const res = await EDocService.create(doc);

    dispatch({
      type: CREATE_EDOC.success,
      payload: res.data,
    });
    
    return res.data;
  } catch (err) {
    dispatch({
      type: CREATE_EDOC.error,
      payload: err,
    });
    return err;
  }
};

export const retrieveEDocs = () => async (dispatch) => {
  try {
    dispatch({
      type: RETRIEVE_EDOCS.pending,
      payload: 'loading'
    });

    const res = await EDocService.getAll();

    dispatch({
      type: RETRIEVE_EDOCS.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: RETRIEVE_EDOCS.error,
      payload: err
    });

    return err;
  }
};

export const updateEDoc = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_EDOC.pending,
      payload: 'loading'
    });

    const res = await EDocService.update(id, data);

    dispatch({
      type: UPDATE_EDOC.success,
      payload: data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: UPDATE_EDOC.error,
      payload: err,
    });

    return err;
  }
};

export const getEDoc = (id) => async (dispatch) => {
  try {
    const res = await EDocService.get(id);
    dispatch({
      type: GET_EDOC,
      payload: res.data,
    });
  } catch (err) {
    console.log("Error = ", err);
  }
};

export const deleteEDoc = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_EDOC.pending,
      payload: 'loading'
    });

    const res = await EDocService.remove(id);

    dispatch({
      type: DELETE_EDOC.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: DELETE_EDOC.error,
      payload: err
    });
  }
};

export const deleteAllEDocs = () => async (dispatch) => {
  try {
    const res = await EDocService.removeAll();

    dispatch({
      type: DELETE_ALL_EDOCS,
      payload: res.data,
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const findByName = (name) => async (dispatch) => {
  try {
    dispatch({
      type: FIND_EDOC_BY_NAME.pending,
      payload: 'loading'
    });

    const res = await EDocService.findByName(name);

    dispatch({
      type: FIND_EDOC_BY_NAME.success,
      payload: res.data,
    });

    return res;
  } catch (err) {
    dispatch({
      type: FIND_EDOC_BY_NAME.pending,
      payload: 'loading'
    });

    return err;
  }
};

export const clearClient = () => async (dispatch) => {

  dispatch({
    type: CLEAR_EDOC,
  });
};
