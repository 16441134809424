import ThemeButton from "./ThemeButton";

const SubmitButton = ({ theme, children, ...props }) => {
  if (!theme) {
    theme = 'primary';
  }

  return (
    <ThemeButton type="submit" theme={theme} {...props}>
      {children}
    </ThemeButton>
  )
}

export default SubmitButton;
